import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_ctx.childTable)
    ? (_openBlock(), _createBlock(_component_q_table, {
        ref: "tableRef",
        style: {"overflow":"auto","width":"100%"},
        class: "sticky-header-table-answer",
        dense: "",
        rows: _ctx.childTable,
        columns: _ctx.columns,
        "row-key": "id",
        "wrap-cells": "",
        selection: "multiple",
        loading: _ctx.isMessageGrpResultBusy,
        pagination: _ctx.resultPagination,
        "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.resultPagination) = $event)),
        "rows-per-page-options": _ctx.rowsPerPageResultOptions,
        onRequest: _ctx.onRequestResult,
        key: _ctx.key,
        "hide-header": "",
        selected: _ctx.selectedRows,
        "onUpdate:selected": [
          _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedRows) = $event)),
          _ctx.checkSelectedChild
        ]
      }, {
        header: _withCtx((props) => [
          _createVNode(_component_q_tr, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_q_th, { style: {"width":"40px"} }),
              _createVNode(_component_q_th, {
                "auto-width": "",
                key: "text",
                props: props,
                class: "text-left"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.cols[0].label), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_th, {
                key: "count",
                props: props,
                class: "text-center",
                style: {"width":"120px"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.cols[1].label), 1)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        body: _withCtx((props) => [
          _createVNode(_component_q_tr, {
            props: props,
            class: "row items-center no-wrap",
            style: {"width":"100%","height":"100%"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_checkbox, {
                modelValue: props.selected,
                "onUpdate:modelValue": ($event: any) => ((props.selected) = $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
              _createVNode(_component_q_td, {
                key: "text",
                props: props,
                style: {"width":"100%","height":"100%"}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(props.row.text), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "count",
                props: props,
                class: "text-center q-ml-auto",
                style: {"width":"120px"}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    style: {"cursor":"pointer"},
                    title: "Исходные ответы",
                    onClick: ($event: any) => (_ctx.onRowShowMessageSource(props.row, false))
                  }, _toDisplayString(props.row.count), 9, _hoisted_1)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "no-data": _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("div", { class: "col-12 text-center" }, "Нет данных", -1)
        ])),
        _: 1
      }, 8, ["rows", "columns", "loading", "pagination", "rows-per-page-options", "onRequest", "selected", "onUpdate:selected"]))
    : _createCommentVNode("", true)
}