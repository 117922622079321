// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "message_src.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { MessageSrcService } from "./message_src";
import type { PersonalListMessageSrcSourceResponse } from "./message_src";
import type { PersonalListMessageSrcSourceRequest } from "./message_src";
import type { PersonalListMessageSrcResponse } from "./message_src";
import type { PersonalListMessageSrcRequest } from "./message_src";
import type { ListMessageSrcSourceResponse } from "./message_src";
import type { ListMessageSrcSourceRequest } from "./message_src";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListMessageSrcResponse } from "./message_src";
import type { ListMessageSrcRequest } from "./message_src";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.MessageSrcService
 */
export interface IMessageSrcServiceClient {
    /**
     * @generated from protobuf rpc: ListMessageSrc(proto.nigmamsg.ListMessageSrcRequest) returns (proto.nigmamsg.ListMessageSrcResponse);
     */
    listMessageSrc(input: ListMessageSrcRequest, options?: RpcOptions): UnaryCall<ListMessageSrcRequest, ListMessageSrcResponse>;
    /**
     * @generated from protobuf rpc: ListMessageSrcSource(proto.nigmamsg.ListMessageSrcSourceRequest) returns (proto.nigmamsg.ListMessageSrcSourceResponse);
     */
    listMessageSrcSource(input: ListMessageSrcSourceRequest, options?: RpcOptions): UnaryCall<ListMessageSrcSourceRequest, ListMessageSrcSourceResponse>;
    /**
     * @generated from protobuf rpc: PersonalListMessageSrc(proto.nigmamsg.PersonalListMessageSrcRequest) returns (proto.nigmamsg.PersonalListMessageSrcResponse);
     */
    personalListMessageSrc(input: PersonalListMessageSrcRequest, options?: RpcOptions): UnaryCall<PersonalListMessageSrcRequest, PersonalListMessageSrcResponse>;
    /**
     * @generated from protobuf rpc: PersonalListMessageSrcSource(proto.nigmamsg.PersonalListMessageSrcSourceRequest) returns (proto.nigmamsg.PersonalListMessageSrcSourceResponse);
     */
    personalListMessageSrcSource(input: PersonalListMessageSrcSourceRequest, options?: RpcOptions): UnaryCall<PersonalListMessageSrcSourceRequest, PersonalListMessageSrcSourceResponse>;
}
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.MessageSrcService
 */
export class MessageSrcServiceClient implements IMessageSrcServiceClient, ServiceInfo {
    typeName = MessageSrcService.typeName;
    methods = MessageSrcService.methods;
    options = MessageSrcService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ListMessageSrc(proto.nigmamsg.ListMessageSrcRequest) returns (proto.nigmamsg.ListMessageSrcResponse);
     */
    listMessageSrc(input: ListMessageSrcRequest, options?: RpcOptions): UnaryCall<ListMessageSrcRequest, ListMessageSrcResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMessageSrcRequest, ListMessageSrcResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListMessageSrcSource(proto.nigmamsg.ListMessageSrcSourceRequest) returns (proto.nigmamsg.ListMessageSrcSourceResponse);
     */
    listMessageSrcSource(input: ListMessageSrcSourceRequest, options?: RpcOptions): UnaryCall<ListMessageSrcSourceRequest, ListMessageSrcSourceResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMessageSrcSourceRequest, ListMessageSrcSourceResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: PersonalListMessageSrc(proto.nigmamsg.PersonalListMessageSrcRequest) returns (proto.nigmamsg.PersonalListMessageSrcResponse);
     */
    personalListMessageSrc(input: PersonalListMessageSrcRequest, options?: RpcOptions): UnaryCall<PersonalListMessageSrcRequest, PersonalListMessageSrcResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<PersonalListMessageSrcRequest, PersonalListMessageSrcResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: PersonalListMessageSrcSource(proto.nigmamsg.PersonalListMessageSrcSourceRequest) returns (proto.nigmamsg.PersonalListMessageSrcSourceResponse);
     */
    personalListMessageSrcSource(input: PersonalListMessageSrcSourceRequest, options?: RpcOptions): UnaryCall<PersonalListMessageSrcSourceRequest, PersonalListMessageSrcSourceResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<PersonalListMessageSrcSourceRequest, PersonalListMessageSrcSourceResponse>("unary", this._transport, method, opt, input);
    }
}
