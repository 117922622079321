import {useGrpcClientWithLogger} from "@/components/modules/rec-system/services/UserGroups/middleware";
import {ClassGroupServiceClient} from "proto/nigma-message-proto/polls/class_group.client";
import {
    ListClassGroupRequest,
    ItemClassGroupRequest,
    ListClassGroupAccountRequest,
    CreateClassGroupRequest,
    UpdateClassGroupRequest,
    DeleteClassGroupRequest,
} from "proto/nigma-message-proto/polls/class_group"

export const useClassGroupApi = () => {
    const client = useGrpcClientWithLogger().create(ClassGroupServiceClient, {baseUrl: window.VUE_APP_GATEWAY_URL})

    const metadata = () => {
        return {
            Authorization: localStorage.getItem('access-key') || ''
        }
    }

    return {
        listClassGroupRequest: (request: ListClassGroupRequest) => {
            return client.listClassGroup(request, {meta: metadata()}).response
        },
        itemClassGroupRequest: (request: ItemClassGroupRequest) => {
            return client.itemClassGroup(request, {meta: metadata()}).response
        },
        listClassGroupAccountRequest: (request: ListClassGroupAccountRequest) => {
            return client.listClassGroupAccount(request, {meta: metadata()}).response
        },
        createClassGroupRequest: (request: CreateClassGroupRequest) => {
            return client.createClassGroup(request, {meta: metadata()}).response
        },
        updateClassGroupRequest: (request: UpdateClassGroupRequest) => {
            return client.updateClassGroup(request, {meta: metadata()}).response
        },
        deleteClassGroupRequest: (request: DeleteClassGroupRequest) => {
            return client.deleteClassGroup(request, {meta: metadata()}).response
        },
    }
}