import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "base-modal__wrapper" }

import { StyleValue } from 'vue';


type Emits = {
  close: [],
  ok: []
}

type Props = {
  noFooter?: boolean
  loading?: boolean
  noOkBtn?: boolean
  noCloseBtn?: boolean
  bodyStyle?: StyleValue
  headerStyle?: StyleValue
  footerStyle?: StyleValue
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseModalCard',
  props: {
    noFooter: { type: Boolean },
    loading: { type: Boolean },
    noOkBtn: { type: Boolean },
    noCloseBtn: { type: Boolean },
    bodyStyle: { type: [Boolean, null, String, Object, Array] },
    headerStyle: { type: [Boolean, null, String, Object, Array] },
    footerStyle: { type: [Boolean, null, String, Object, Array] }
  },
  emits: ["close", "ok"],
  setup(__props: any, { emit: __emit }) {

const emits = __emit


return (_ctx: any,_cache: any) => {
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "base-modal__header",
      style: _normalizeStyle(_ctx.headerStyle)
    }, [
      _renderSlot(_ctx.$slots, "header", {}, () => [
        _createVNode(_component_q_space)
      ]),
      _renderSlot(_ctx.$slots, "closeIcon", {}, () => [
        _withDirectives(_createVNode(_component_q_icon, {
          class: "cursor-pointer",
          name: "close",
          size: "1.5rem",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emits('close')))
        }, null, 512), [
          [_directive_close_popup]
        ])
      ])
    ], 4),
    _createElementVNode("div", {
      class: "base-modal__body",
      style: _normalizeStyle(_ctx.bodyStyle)
    }, [
      _renderSlot(_ctx.$slots, "body")
    ], 4),
    (!_ctx.noFooter)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "base-modal__footer",
          style: _normalizeStyle(_ctx.footerStyle)
        }, [
          _renderSlot(_ctx.$slots, "footer", {}, () => [
            (!_ctx.noCloseBtn)
              ? _withDirectives((_openBlock(), _createBlock(_component_q_btn, {
                  key: 0,
                  color: "grey",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (emits('close')))
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("Закрыть")
                  ])),
                  _: 1
                })), [
                  [_directive_close_popup]
                ])
              : _createCommentVNode("", true),
            (!_ctx.noOkBtn)
              ? (_openBlock(), _createBlock(_component_q_btn, {
                  key: 1,
                  color: "secondary",
                  loading: _ctx.loading,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (emits('ok')))
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("Ок")
                  ])),
                  _: 1
                }, 8, ["loading"]))
              : _createCommentVNode("", true)
          ])
        ], 4))
      : _createCommentVNode("", true)
  ]))
}
}

})