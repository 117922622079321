import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "inter-actions" }
const _hoisted_2 = {
  class: "row",
  style: {"display":"none"}
}
const _hoisted_3 = {
  style: {"gap":"16px"},
  class: "row q-mb-md fit"
}
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "activity__date-select row q-mr-md" }
const _hoisted_6 = { class: "row items-center justify-end" }
const _hoisted_7 = { class: "row items-center justify-end" }
const _hoisted_8 = {
  key: 0,
  class: "tree-input-dropdown",
  style: {"background":"white","max-height":"500px","overflow":"auto","position":"absolute","z-index":"4","width":"100%"}
}
const _hoisted_9 = { class: "row q-mt-xl justify-center" }
const _hoisted_10 = { class: "inter-actions__count-value" }
const _hoisted_11 = { class: "col" }
const _hoisted_12 = { class: "inter-actions__count-value" }
const _hoisted_13 = { class: "col relative-position" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = {
  class: "inter-actions__count",
  style: {"margin-left":"24px","max-width":"unset"}
}
const _hoisted_16 = { class: "inter-actions__count-value" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { class: "row q-mt-lg relative-position" }
const _hoisted_19 = { class: "row q-mt-xl q-mb-xl" }
const _hoisted_20 = { class: "time q-mb-md" }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { key: 2 }

import ActionTable from '@/components/modules/intersection/components/Actions/ActionTable.vue'
import ActionChart from '@/components/modules/intersection/components/Actions/ActionChart.vue'
import MetricComponent from '@/components/modules/intersection/components/Metrics/MetricComponent.vue'
import ExportButtonRegistration from '@/components/modules/accounts/components/ExportButton/ExportButtonRegistration.vue'
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
import ExportButtonAll from "@/components/modules/accounts/components/ExportButton/ExportButtonAll.vue";
import ExportButtonUniq from "@/components/modules/accounts/components/ExportButton/ExportButtonUniq.vue";
import {computed, onBeforeMount, onMounted, ref, toRaw} from "vue";
import store from "@/store";
import {onClickOutside} from "@vueuse/core";
import {useRouter} from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'InterActions',
  setup(__props) {

const router = useRouter()

const sortType = ref('month')
const uniqueCount = ref(0)
const allCount = ref(0)
const pointsCount = ref(0)
const dateMin = ref('')
const dateMax = ref(new Date().toJSON().slice(0, 10))
const qDateProxy1: any = ref()
const qDateProxy2: any = ref()
const metricArr: any = ref([])
const allCheck = ref(false)
const allTable = ref(false)
const uniqTable = ref(false)
const allPagination = ref({
  page: 1,
  rowsPerPage: 5,
  rowsNumber: 0,
  descending: true,
  sortBy: '',
})
const uniqPagination = ref({
  page: 1,
  rowsPerPage: 5,
  rowsNumber: 0,
  descending: true,
  sortBy: '',
})
const isDropdownOpen = ref(false)
const isDropdownOpenActivity = ref(false)
const projectSelectRef = ref()
const filterInput = ref('')
const selectedProjects = ref([])
const activityFilterInput = ref('')
const selectedActions = ref([])
const dropdownRef = ref()
const projectAssociationProj = ref({})
const projectAssociationAct = ref({})
const loader = ref(false)

const allCols: any = [
  {
    name: 'sso_id',
    label: 'SSO ID',
    field: 'id',
    align: 'left',
  },
  {
    name: 'project',
    label: 'Проект',
    field: 'project',
    align: 'left',
  },
  {
    name: 'action',
    label: 'Действие',
    field: 'action',
    align: 'left',
  },
  {
    name: 'date',
    label: 'Дата',
    field: 'date',
    align: 'left',
  },
]
let uniqCols: any = []

const isActiveCount = computed(() => store.getters.isActiveCount)
const isActiveChart = computed(() => store.getters.isActiveChart)
const isAllTable = computed(() => store.getters.isActiveAccount)
const isUniqTable = computed(() => store.getters.isUniqAccount)
const isActiveTable = computed(() => store.getters.isActiveTable)

const metricValues = computed(() => {
  const metricList = store.getters.getMetricChecked

  return toRaw(metricList)
})

const allRows = computed(() => {
  const rows = store.getters.getActiveAccount
  const filteredList = []
  let counter = 0

  if (rows) {
    for (const i of rows) {
      filteredList.push({
        id: i.getSsoId(),
        row_id: counter,
        project: i.getProjectName(),
        action:
            i.getActivComment()
                ? `${i.getActivTextTape()}: "${i.getActivComment()}"`
                : i.getActivTextTape(),
        date: i.getActivDate().split('-').reverse().join('.'),
      })

      counter++
    }
  }

  return filteredList
})

const uniqRows = computed(() => {
  const rows = store.getters.getUniqAccount
  const filteredList = []
  console.log('rows', rows)

  if (rows) {
    for (let i of rows) {
      let uniqArr = JSON.parse(i.getActivYes())
      const uniqObj = Object.fromEntries(uniqArr.map(([v, k]) => [v, k]))
      const ssoId = i.getSsoId()

      Object.assign(uniqObj, {
        id_uniq: ssoId,
      })

      filteredList.push(uniqObj)
    }
  }

  return filteredList
})

const getProjectItems = computed(() => {
  let rawProjects = store.getters.getProjectsItems

  const treeNodes: any = [
    {
      label: 'Все проекты',
      children: [],
    },
  ]

  for (let i in rawProjects) {
    treeNodes[0].children.push({ label: rawProjects[i].getProjectName() })
  }

  return treeNodes
})

const actionsList = computed(() => {
  let rawActions = store.getters.getActiveList
  console.log(rawActions)

  let nodes: any = [
    {
      id: 'allctions',
      label: 'Все действия',
      children: [],
    },
  ]
  let tempObj: any = {}
  for (const i in rawActions) {
    if (!tempObj[rawActions[i].getProjectId()]) {
      tempObj[rawActions[i].getProjectId()] = {
        label:
            'Все действия по проекту "' +
            projectAssociationAct.value[rawActions[i].getProjectId()] +
            '"',
        id:
            rawActions[i].getActivTextTape() + '#' + rawActions[i].getProjectId(),
        children: [],
      }

      tempObj[rawActions[i].getProjectId()].children.push({
        label: rawActions[i].getActivTextTape(),
        id: rawActions[i].getActivId(),
      })
    } else {
      tempObj[rawActions[i].getProjectId()].children.push({
        label: rawActions[i].getActivTextTape(),
        id: rawActions[i].getActivId(),
      })
    }
  }

  for (const i in tempObj) {
    nodes[0].children.push(tempObj[i])
  }

  return nodes
})

const actionsRows = computed(() => {
  const rawActions = store.getters.getActiveList
  const filteredList: any = []

  for (const i of rawActions) {
    filteredList.push({
      id: i.getActivId(),
      text: i.getActivTextTape(),
      label: i.getProjectShortName(),
    })
  }

  return filteredList
})

const dateOptionsStart = (date: any) => {
  return date < dateMax.value.split('-').join('/')
}

const dateOptionsEnd = (date: any) => {
  return date > dateMin.value.split('-').join('/') && date <= new Date().toJSON().slice(0, 10).split('-').join('/')
}

const getProjects = () => {
  store.dispatch('getProjectsItems', {
    filter: [
      {
        field_name: 'is_visible',
        operand: '=',
        value: 'true'
      }
    ],
    sort: {
      name: 'project_id',
      exp: 'asc',
    },
  })
      .then((res) => {
        const filteredAssocProjects = []
        const filteredAssocActions = []

        for (const i of res.items) {
          filteredAssocProjects.push([i.getProjectName(), i.getProjectId()])
          filteredAssocActions.push([i.getProjectId(), i.getProjectName()])
        }

        projectAssociationProj.value = Object.fromEntries(filteredAssocProjects)
        projectAssociationAct.value = Object.fromEntries(filteredAssocActions)
      })
}

const getMinDate = () => {
  const dateSplitter = dateMax.value.split('-')
  const prevYear = Number(dateSplitter[0]) - 1

  dateMin.value = prevYear + '-' + dateSplitter[1] + '-' + dateSplitter[2]
}

const getMetricList = () => {
  store
      .dispatch('getMetricList', {
        filter: {},
        sort: {},
        pagination: {},
      })
      .then((value: any) => {
        for (const i in value) {
          metricArr.value.push(value[i])
        }
      })
}

const getCount = () => {
  store
      .dispatch('getActiveCount', {
        filter: {},
        activity: metricValues.value['Активность'],
        loyalty: metricValues.value['Лояльность'],
        useful: metricValues.value['Полезность'],
        interest: metricValues.value['Заинтересованность'],
        activ_ids: selectedActions.value || [],
        all_active: allCheck.value,
        date_begin: dateMin.value,
        date_end: dateMax.value,
      })
      .then((value) => {
        allCount.value = value.getActivityCount()
        uniqueCount.value = value.getActivityUniq()
        pointsCount.value = value.getActivityPoints()
      })
}

const getActiveChart = () => {
  if (selectedActions?.value.length && selectedActions?.value.length <= 10) {
    if ((new Date(dateMax.value) - new Date(dateMin.value)) / (1000 * 3600 * 24) <= 365 && sortType.value === 'day') {
      store.dispatch('getActiveChart', {
        filter: {},
        activity: metricValues.value['Активность'],
        loyalty: metricValues.value['Лояльность'],
        useful: metricValues.value['Полезность'],
        interest: metricValues.value['Заинтересованность'],
        sort: {},
        pagination: {
          page: 1,
          limit: 365,
          pages: 0,
          cnt: 0,
        },
        activ_ids: selectedActions.value || [],
        interval: sortType.value,
        all_active: allCheck.value,
        date_begin: dateMin.value,
        date_end: dateMax.value,
      })
    }

    if (sortType.value === 'month') {
      store.dispatch('getActiveChart', {
        filter: {},
        activity: metricValues.value['Активность'],
        loyalty: metricValues.value['Лояльность'],
        useful: metricValues.value['Полезность'],
        interest: metricValues.value['Заинтересованность'],
        sort: {},
        pagination: {
          page: 1,
          limit: 1000,
          pages: 0,
          cnt: 0,
        },
        activ_ids: selectedActions.value || [],
        interval: sortType.value,
        all_active: allCheck.value,
        date_begin: dateMin.value,
        date_end: dateMax.value,
      })
    }

    if ((new Date(dateMax.value) - new Date(dateMin.value)) / (1000 * 3600 * 24) >= 365 && sortType.value === 'year') {
      store.dispatch('getActiveChart', {
        filter: {},
        activity: metricValues.value['Активность'],
        loyalty: metricValues.value['Лояльность'],
        useful: metricValues.value['Полезность'],
        interest: metricValues.value['Заинтересованность'],
        sort: {},
        pagination: {
          page: 1,
          limit: 100,
          pages: 0,
          cnt: 0,
        },
        activ_ids: selectedActions.value || [],
        interval: sortType.value,
        all_active: allCheck.value,
        date_begin: dateMin.value,
        date_end: dateMax.value,
      })
    }
  }
}

const showDropDown = (value: boolean) => {
  isDropdownOpen.value = value
}

const showDropDownActivity = (value: boolean) => {
  isDropdownOpenActivity.value = value
}

onClickOutside(projectSelectRef, (e: any) => {
  e.target.closest('.project-select-tree') ? null : showDropDown(false)
})

onClickOutside(dropdownRef, (e: any) => {
  e.target.closest('.tree-input') ? null : showDropDownActivity(false)
})

const onSelectProjects = () => {
  const actionsArr: any = []

  if (selectedProjects.value.length) {
    for (const item of selectedProjects.value) {
      actionsArr.push(projectAssociationProj.value[item])
    }
  }

  const actionList: any = []
  const projectList: any = actionsList.value[0].children

  for (const i of projectList) {
    if (actionsArr.length && actionsArr.includes(Number(i.id.split('#')[1]))) {
      for (const j of i.children) {
        actionList.push(j.id)
      }
    }
  }

  selectedActions.value = actionsArr.length ? actionList : []
}

const getActivityList = () => {
  store.dispatch('getActiveList', {
    filter: {},
    sort: {},
    pagination: {},
    project_ids: [],
  })
}

const getAllTable = () => {
  allTable.value = true

  store
      .dispatch('getActiveAccount', {
        filter: {},
        activity: metricValues.value['Активность'],
        loyalty: metricValues.value['Лояльность'],
        useful: metricValues.value['Полезность'],
        interest: metricValues.value['Заинтересованность'],
        sort: {},
        pagination: {
          page: allPagination.value.page,
          limit: allPagination.value.rowsPerPage,
          pages: 0,
          cnt: 0,
        },
        activ_ids: selectedActions.value,
        all_active: allCheck.value,
        date_begin: dateMin.value,
        date_end: dateMax.value,
      })
      .then(({ params }) => {
        allPagination.value.rowsNumber = params.getPagination().getCnt()
      })
}

const allReq = (props: any) => {
  allPagination.value = props.pagination

  getAllTable()
}

const getUniqTable = () => {
  uniqTable.value = true

  uniqCols = []
  uniqCols.push({
    name: 'sso_id_uniq',
    label: 'SSO ID',
    field: 'id_uniq',
    align: 'left',
  })


  if (actionsRows.value) {
    if (selectedActions.value.length) {
      for (const i of actionsRows.value) {
        for (const j of selectedActions.value) {
          if (j === i.id) {
            uniqCols.push({
              name: i.id,
              label: `${i.text} (${i.label})`,
              field: i.id,
              align: 'left',
            })
          }
        }
      }
    } else {
      for (const i of actionsRows.value) {
        uniqCols.push({
          name: i.id,
          label: `${i.text} (${i.label})`,
          field: i.id,
          align: 'left',
        })
      }
    }
  }

  store
      .dispatch('getUniqAccount', {
        filter: {},
        activity: metricValues.value['Активность'],
        loyalty: metricValues.value['Лояльность'],
        useful: metricValues.value['Полезность'],
        interest: metricValues.value['Заинтересованность'],
        sort: {},
        pagination: {
          page: uniqPagination.value.page,
          limit: uniqPagination.value.rowsPerPage,
          pages: 0,
          cnt: 0,
        },
        activ_ids: selectedActions.value,
        all_active: allCheck.value,
        date_begin: dateMin.value,
        date_end: dateMax.value,
      })
      .then(({ params }) => {
        uniqPagination.value.rowsNumber = params.getPagination().getCnt()
      })
}

const loadPersonalUnique = () => {
  loader.value = true

  store.dispatch('getPersonalUniqAccount', {
    filter: {},
    activity: metricValues.value['Активность'],
    loyalty: metricValues.value['Лояльность'],
    useful: metricValues.value['Полезность'],
    interest: metricValues.value['Заинтересованность'],
    activ_ids: selectedActions.value,
    all_active: allCheck.value,
    date_begin: dateMin.value,
    date_end: dateMax.value,
  })
      .then(() => {
        router.push({
          name: 'UserProjects',
          query: {
            data: 'personal'
          }
        })

        loader.value = false
      })
}

const uniqReq = (props: any) => {
  uniqPagination.value = props.pagination

  getUniqTable()
}

const getActiveTable = () => {
  store.dispatch('getActiveTable', {
    filter: {},
    activity: metricValues.value['Активность'],
    loyalty: metricValues.value['Лояльность'],
    useful: metricValues.value['Полезность'],
    interest: metricValues.value['Заинтересованность'],
    sort: {},
    activ_ids: selectedActions.value,
    all_active: allCheck.value,
    date_begin: dateMin.value,
    date_end: dateMax.value,
  })
}

const applyPeriod = () => {
  getCount()
  getActiveChart()
  getActiveTable()
}

onMounted(() => {
  getProjects()
  getActivityList()
  getMinDate()
  getMetricList()
  applyPeriod()
})

onBeforeMount(() => {
  store.dispatch('clearMetricChecked', [])
})

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_tree = _resolveComponent("q-tree")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(Breadcrumbs),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(metricArr.value, (metricItem) => {
            return (_openBlock(), _createBlock(MetricComponent, {
              options: metricItem,
              key: metricItem,
              style: {"width":"100%"}
            }, null, 8, ["options"]))
          }), 128)),
          _createVNode(_component_q_btn, {
            style: {"height":"56px","margin-left":"8px"},
            color: "primary",
            label: "Применить",
            size: "md",
            onClick: applyPeriod
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_q_input, {
            filled: "",
            modelValue: dateMin.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((dateMin).value = $event)),
            "hide-bottom-space": "",
            "hide-hint": "",
            style: {"width":"165px","cursor":"pointer"},
            "input-style": "cursor:pointer",
            label: "Дата начала",
            mask: "####-##-##",
            readonly: "",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (qDateProxy1.value.show())),
            class: "q-mr-md"
          }, {
            append: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: "event",
                class: "cursor-pointer"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_popup_proxy, {
                    cover: "",
                    "transition-show": "scale",
                    "transition-hide": "scale",
                    ref_key: "qDateProxy1",
                    ref: qDateProxy1
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_date, {
                        modelValue: dateMin.value,
                        "onUpdate:modelValue": [
                          _cache[0] || (_cache[0] = ($event: any) => ((dateMin).value = $event)),
                          _cache[1] || (_cache[1] = ($event: any) => (qDateProxy1.value.hide()))
                        ],
                        mask: "YYYY-MM-DD",
                        "navigation-max-year-month": new Date().toJSON().slice(0, 10).replace(/-/g,'/'),
                        options: dateOptionsStart
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_6, [
                            _withDirectives(_createVNode(_component_q_btn, {
                              label: "Close",
                              color: "primary",
                              flat: ""
                            }, null, 512), [
                              [_directive_close_popup]
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "navigation-max-year-month"])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_q_input, {
            filled: "",
            modelValue: dateMax.value,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((dateMax).value = $event)),
            label: "Дата окончания",
            "hide-bottom-space": "",
            "hide-hint": "",
            mask: "####-##-##",
            readonly: "",
            style: {"width":"165px","cursor":"pointer"},
            "input-style": "cursor:pointer",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (qDateProxy2.value.show()))
          }, {
            append: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: "event",
                class: "cursor-pointer"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_popup_proxy, {
                    cover: "",
                    "transition-show": "scale",
                    "transition-hide": "scale",
                    ref_key: "qDateProxy2",
                    ref: qDateProxy2
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_date, {
                        modelValue: dateMax.value,
                        "onUpdate:modelValue": [
                          _cache[4] || (_cache[4] = ($event: any) => ((dateMax).value = $event)),
                          _cache[5] || (_cache[5] = ($event: any) => (qDateProxy2.value.hide()))
                        ],
                        mask: "YYYY-MM-DD",
                        options: dateOptionsEnd
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_7, [
                            _withDirectives(_createVNode(_component_q_btn, {
                              label: "Close",
                              color: "primary",
                              flat: ""
                            }, null, 512), [
                              [_directive_close_popup]
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _createElementVNode("div", {
          class: "project-select-tree col",
          ref_key: "projectSelectRef",
          ref: projectSelectRef,
          style: {"max-width":"353px"}
        }, [
          _createVNode(_component_q_input, {
            ref: "projectsInputRef",
            modelValue: filterInput.value,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((filterInput).value = $event)),
            filled: "",
            label: selectedProjects.value.length ? 'Выбрано проектов: (' + selectedProjects.value.length + ')' : 'Все проекты',
            "hide-bottom-space": "",
            clearable: "",
            onFocus: _cache[9] || (_cache[9] = ($event: any) => (showDropDown(true)))
          }, {
            append: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: isDropdownOpen.value ? 'fas fa-caret-up' : 'fas fa-caret-down'
              }, null, 8, ["name"])
            ]),
            _: 1
          }, 8, ["modelValue", "label"]),
          (getProjectItems.value && isDropdownOpen.value)
            ? (_openBlock(), _createElementBlock("div", _mergeProps({
                key: 0,
                class: "select-tree-dropdown"
              }, _ctx.$attrs, { style: {"margin-left":"0"} }), [
                _createVNode(_component_q_tree, {
                  nodes: getProjectItems.value,
                  "node-key": "label",
                  "tick-strategy": "leaf",
                  "default-expand-all": "",
                  ticked: selectedProjects.value,
                  "onUpdate:ticked": [
                    _cache[10] || (_cache[10] = ($event: any) => ((selectedProjects).value = $event)),
                    onSelectProjects
                  ],
                  filter: filterInput.value
                }, null, 8, ["nodes", "ticked", "filter"])
              ], 16))
            : _createCommentVNode("", true)
        ], 512),
        _createElementVNode("div", {
          class: "tree-input col",
          ref_key: "dropdownRef",
          ref: dropdownRef,
          style: {"position":"relative","margin-left":"16px"}
        }, [
          _createVNode(_component_q_input, {
            modelValue: activityFilterInput.value,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((activityFilterInput).value = $event)),
            filled: "",
            label: selectedActions.value.length ? 'Выбрано действий: (' + selectedActions.value.length + ')' : 'Все действия',
            clearable: "",
            onFocus: _cache[12] || (_cache[12] = ($event: any) => (showDropDownActivity(true)))
          }, {
            append: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: isDropdownOpenActivity.value ? 'fas fa-caret-up' : 'fas fa-caret-down'
              }, null, 8, ["name"])
            ]),
            _: 1
          }, 8, ["modelValue", "label"]),
          (isDropdownOpenActivity.value && actionsList.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_q_tree, {
                  nodes: actionsList.value,
                  "node-key": "id",
                  "label-key": "label",
                  "children-key": "children",
                  "tick-strategy": "leaf",
                  "default-expand-all": "",
                  ticked: selectedActions.value,
                  "onUpdate:ticked": _cache[13] || (_cache[13] = ($event: any) => ((selectedActions).value = $event)),
                  filter: activityFilterInput.value
                }, null, 8, ["nodes", "ticked", "filter"])
              ]))
            : _createCommentVNode("", true)
        ], 512),
        _createVNode(_component_q_btn, {
          style: {"height":"56px","margin-left":"16px"},
          color: "primary",
          label: "Применить",
          size: "md",
          onClick: applyPeriod
        })
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("p", {
          style: {"cursor":"pointer"},
          class: "inter-actions__count",
          onClick: _cache[16] || (_cache[16] = 
          () => {
            if (!isActiveCount.value) {
              getAllTable()
            }
          }
        )
        }, [
          _cache[24] || (_cache[24] = _createTextVNode(" Количество совершенных действий ")),
          _cache[25] || (_cache[25] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("span", _hoisted_10, _toDisplayString(allCount.value.toLocaleString()), 1),
          _createVNode(_component_q_inner_loading, {
            showing: isActiveCount.value,
            color: "white"
          }, null, 8, ["showing"]),
          _createVNode(_component_q_dialog, {
            modelValue: allTable.value,
            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((allTable).value = $event)),
            "full-width": ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(ExportButtonAll, {
                  "date-max": dateMax.value,
                  "date-min": dateMin.value,
                  "all-chk": allCheck.value,
                  "sel-act": selectedActions.value,
                  style: {"height":"56px","margin-bottom":"16px"},
                  class: "justify-end"
                }, null, 8, ["date-max", "date-min", "all-chk", "sel-act"]),
                _createVNode(_component_q_table, {
                  class: "modal__table",
                  columns: allCols,
                  rows: allRows.value,
                  loading: isAllTable.value,
                  "rows-per-page-options": [5, 10, 15, 20],
                  pagination: allPagination.value,
                  "onUpdate:pagination": _cache[14] || (_cache[14] = ($event: any) => ((allPagination).value = $event)),
                  onRequest: allReq,
                  "row-key": "row_id",
                  "wrap-cells": ""
                }, {
                  pagination: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString((scope.pagination.rowsPerPage * scope.pagination.page - scope.pagination.rowsPerPage + 1).toLocaleString()) + " - " + _toDisplayString(scope.pagination.rowsPerPage * scope.pagination.page > scope.pagination.rowsNumber
                  ? scope.pagination.rowsNumber.toLocaleString()
                  : (scope.pagination.rowsPerPage * scope.pagination.page).toLocaleString()) + " из " + _toDisplayString(scope.pagination.rowsNumber.toLocaleString()) + " ", 1),
                    (scope.pagesNumber > 2)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          icon: "first_page",
                          color: "grey-8",
                          round: "",
                          dense: "",
                          flat: "",
                          disable: scope.isFirstPage,
                          onClick: scope.firstPage,
                          class: "q-ml-md"
                        }, null, 8, ["disable", "onClick"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_q_btn, {
                      icon: "chevron_left",
                      color: "grey-8",
                      round: "",
                      dense: "",
                      flat: "",
                      disable: scope.isFirstPage,
                      onClick: scope.prevPage
                    }, null, 8, ["disable", "onClick"]),
                    _createVNode(_component_q_btn, {
                      icon: "chevron_right",
                      color: "grey-8",
                      round: "",
                      dense: "",
                      flat: "",
                      disable: scope.isLastPage,
                      onClick: scope.nextPage
                    }, null, 8, ["disable", "onClick"]),
                    (scope.pagesNumber > 2)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 1,
                          icon: "last_page",
                          color: "grey-8",
                          round: "",
                          dense: "",
                          flat: "",
                          disable: scope.isLastPage,
                          onClick: scope.lastPage
                        }, null, 8, ["disable", "onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  loading: _withCtx(() => [
                    _createVNode(_component_q_inner_loading, {
                      showing: true,
                      style: {"z-index":"2"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_spinner, {
                          color: "grey",
                          size: "3em"
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["rows", "loading", "pagination"])
              ])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _createElementVNode("p", {
          class: "inter-actions__count",
          style: {"margin-left":"24px","cursor":"pointer"},
          onClick: _cache[19] || (_cache[19] = 
          () => {
            if (!isActiveCount.value) {
              getUniqTable()
            }
          }
        )
        }, [
          _cache[27] || (_cache[27] = _createTextVNode(" Уникальных пользователей, которые совершили действия ")),
          _cache[28] || (_cache[28] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("span", _hoisted_12, _toDisplayString(uniqueCount.value.toLocaleString()), 1),
          _createVNode(_component_q_inner_loading, {
            showing: isActiveCount.value,
            color: "white"
          }, null, 8, ["showing"]),
          _createVNode(_component_q_dialog, {
            modelValue: uniqTable.value,
            "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((uniqTable).value = $event)),
            "full-width": ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_q_btn, {
                    label: "Открыть в ЦП",
                    color: "primary",
                    class: "q-ml-auto q-mr-md",
                    onClick: loadPersonalUnique,
                    style: {"height":"56px"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_tooltip, {
                        anchor: "top middle",
                        self: "bottom middle",
                        offset: [10, 10]
                      }, {
                        default: _withCtx(() => _cache[26] || (_cache[26] = [
                          _createElementVNode("p", {
                            style: {"font-size":"16px"},
                            class: "action-tooltip"
                          }, "Будут переданы первые 300 000 пользователей", -1)
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(ExportButtonUniq, {
                    "date-max": dateMax.value,
                    "date-min": dateMin.value,
                    "all-chk": allCheck.value,
                    "sel-act": selectedActions.value,
                    style: {"height":"56px","margin-bottom":"16px"},
                    class: "justify-end"
                  }, null, 8, ["date-max", "date-min", "all-chk", "sel-act"])
                ]),
                (_unref(uniqCols))
                  ? (_openBlock(), _createBlock(_component_q_table, {
                      key: 0,
                      class: "modal__table",
                      columns: _unref(uniqCols),
                      rows: uniqRows.value,
                      loading: isUniqTable.value,
                      "rows-per-page-options": [5, 10, 15, 25],
                      pagination: uniqPagination.value,
                      "onUpdate:pagination": _cache[17] || (_cache[17] = ($event: any) => ((uniqPagination).value = $event)),
                      onRequest: uniqReq
                    }, {
                      pagination: _withCtx((scope) => [
                        _createTextVNode(_toDisplayString((scope.pagination.rowsPerPage * scope.pagination.page - scope.pagination.rowsPerPage + 1).toLocaleString()) + " - " + _toDisplayString(scope.pagination.rowsPerPage * scope.pagination.page > scope.pagination.rowsNumber
                  ? scope.pagination.rowsNumber.toLocaleString()
                  : (scope.pagination.rowsPerPage * scope.pagination.page).toLocaleString()) + " из " + _toDisplayString(scope.pagination.rowsNumber.toLocaleString()) + " ", 1),
                        (scope.pagesNumber > 2)
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 0,
                              icon: "first_page",
                              color: "grey-8",
                              round: "",
                              dense: "",
                              flat: "",
                              disable: scope.isFirstPage,
                              onClick: scope.firstPage,
                              class: "q-ml-md"
                            }, null, 8, ["disable", "onClick"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_q_btn, {
                          icon: "chevron_left",
                          color: "grey-8",
                          round: "",
                          dense: "",
                          flat: "",
                          disable: scope.isFirstPage,
                          onClick: scope.prevPage
                        }, null, 8, ["disable", "onClick"]),
                        _createVNode(_component_q_btn, {
                          icon: "chevron_right",
                          color: "grey-8",
                          round: "",
                          dense: "",
                          flat: "",
                          disable: scope.isLastPage,
                          onClick: scope.nextPage
                        }, null, 8, ["disable", "onClick"]),
                        (scope.pagesNumber > 2)
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 1,
                              icon: "last_page",
                              color: "grey-8",
                              round: "",
                              dense: "",
                              flat: "",
                              disable: scope.isLastPage,
                              onClick: scope.lastPage
                            }, null, 8, ["disable", "onClick"]))
                          : _createCommentVNode("", true)
                      ]),
                      loading: _withCtx(() => [
                        _createVNode(_component_q_inner_loading, {
                          showing: true,
                          style: {"z-index":"2"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_spinner, {
                              color: "grey",
                              size: "3em"
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["columns", "rows", "loading", "pagination"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", null, [
                  _createVNode(_component_q_inner_loading, {
                    showing: loader.value,
                    color: "grey",
                    label: `Подождите, страница скоро откроется`,
                    style: {"z-index":"100"}
                  }, null, 8, ["showing"])
                ])
              ])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _createElementVNode("p", _hoisted_15, [
          _cache[29] || (_cache[29] = _createElementVNode("span", null, [
            _createTextVNode("Количество начисленных "),
            _createElementVNode("br"),
            _createTextVNode(" баллов")
          ], -1)),
          _cache[30] || (_cache[30] = _createTextVNode()),
          _cache[31] || (_cache[31] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("span", _hoisted_16, _toDisplayString(pointsCount.value.toLocaleString()), 1),
          _createVNode(_component_q_inner_loading, {
            showing: isActiveCount.value,
            color: "white"
          }, null, 8, ["showing"])
        ]),
        _createVNode(_component_q_checkbox, {
          modelValue: allCheck.value,
          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((allCheck).value = $event)),
          label: "Обязательное выполнение ВСЕХ перечисленных действий за период",
          title: "После установки чек-бокса в выборке отобразятся только пользователи, которые совершили все выбранные действия за указанный период. Если чек-бокс не установлен, в выборке отражатся пользователи, которые совершили хотя бы одно из выбранных действий",
          class: "q-ml-xl",
          style: {"font-size":"20px","max-width":"400px"}
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createVNode(ExportButtonRegistration, {
          "date-max": dateMax.value,
          "date-min": dateMin.value,
          "all-chk": allCheck.value,
          "sel-act": selectedActions.value,
          style: {"height":"56px","margin-left":"auto"}
        }, null, 8, ["date-max", "date-min", "all-chk", "sel-act"])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createVNode(ActionTable, {
          "all-check": allCheck.value,
          "full-val": allCount.value,
          "uniq-val": uniqueCount.value,
          onTableAll: getAllTable,
          onTableUniq: getUniqTable,
          "date-min": dateMin.value,
          "date-max": dateMax.value
        }, null, 8, ["all-check", "full-val", "uniq-val", "date-min", "date-max"]),
        _createVNode(_component_q_inner_loading, {
          showing: isActiveTable.value,
          color: "grey"
        }, null, 8, ["showing"])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          (selectedActions.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createVNode(_component_q_btn, {
                  outline: sortType.value !== 'day',
                  style: {"height":"56px","min-width":"100px"},
                  color: "primary",
                  label: "ДНИ",
                  size: "md",
                  onClick: _cache[21] || (_cache[21] = ($event: any) => {sortType.value='day'; getActiveChart()}),
                  disable: (new Date(dateMax.value) - new Date(dateMin.value)) / (1000 * 3600 * 24) > 365 || isActiveChart.value || selectedActions.value.length > 10 || !selectedActions.value.length
                }, null, 8, ["outline", "disable"]),
                ((new Date(dateMax.value) - new Date(dateMin.value)) / (1000 * 3600 * 24) > 365 || !selectedActions.value.length || selectedActions.value.length > 10)
                  ? (_openBlock(), _createBlock(_component_q_tooltip, {
                      key: 0,
                      anchor: "top middle",
                      self: "bottom middle",
                      offset: [10, 10]
                    }, {
                      default: _withCtx(() => _cache[32] || (_cache[32] = [
                        _createElementVNode("p", { class: "action-tooltip" }, [
                          _createTextVNode("График не строится по периодам, превышающим 1 год. "),
                          _createElementVNode("br"),
                          _createTextVNode(" Количество выбранных действий не должно превышать 10.")
                        ], -1)
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (selectedActions.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                _createVNode(_component_q_btn, {
                  outline: sortType.value !== 'month',
                  style: {"height":"56px","min-width":"100px"},
                  color: "primary",
                  label: "МЕСЯЦЫ",
                  size: "md",
                  class: "q-ml-md",
                  onClick: _cache[22] || (_cache[22] = ($event: any) => {sortType.value='month'; getActiveChart()}),
                  disable: isActiveChart.value || !selectedActions.value.length || selectedActions.value.length > 10
                }, null, 8, ["outline", "disable"]),
                (selectedActions.value.length > 10 || !selectedActions.value.length)
                  ? (_openBlock(), _createBlock(_component_q_tooltip, {
                      key: 0,
                      anchor: "top middle",
                      self: "bottom middle",
                      offset: [10, 10]
                    }, {
                      default: _withCtx(() => _cache[33] || (_cache[33] = [
                        _createElementVNode("p", { class: "action-tooltip" }, "Количество выбранных действий не должно превышать 10.", -1)
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (selectedActions.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                _createVNode(_component_q_btn, {
                  outline: sortType.value !== 'year',
                  style: {"height":"56px","min-width":"100px"},
                  color: "primary",
                  label: "ГОДЫ",
                  size: "md",
                  class: "q-ml-md",
                  onClick: _cache[23] || (_cache[23] = ($event: any) => {sortType.value='year'; getActiveChart()}),
                  disable: (new Date(dateMax.value) - new Date(dateMin.value)) / (1000 * 3600 * 24) <= 365 || isActiveChart.value || selectedActions.value.length > 10
                }, null, 8, ["outline", "disable"]),
                ((new Date(dateMax.value) - new Date(dateMin.value)) / (1000 * 3600 * 24) <= 365 || selectedActions.value.length > 10)
                  ? (_openBlock(), _createBlock(_component_q_tooltip, {
                      key: 0,
                      anchor: "top middle",
                      self: "bottom middle",
                      offset: [10, 10]
                    }, {
                      default: _withCtx(() => _cache[34] || (_cache[34] = [
                        _createElementVNode("p", { class: "action-tooltip" }, [
                          _createTextVNode("Минимальный период для построения - 1 год. "),
                          _createElementVNode("br"),
                          _createTextVNode("Количество выбранных действий не должно превышать 10.")
                        ], -1)
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(ActionChart, { "sort-type": sortType.value }, null, 8, ["sort-type"])
      ])
    ])
  ], 64))
}
}

})