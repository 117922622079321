import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-h5" }

import {useClassifierApi} from "@/components/modules/polls/services/useClassifierApi";
import BaseModalCard from "@/components/Base/BaseModalCard.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ClsDelete',
  props: ['classModel'],
  emits: ['afterAction', 'hideModal'],
  setup(__props, { emit: __emit }) {

const { deleteClassifierRequest } = useClassifierApi()

const props = __props
const emit = __emit

const actualDelete = () => {
  deleteClassifierRequest({
    classifierId: props.classModel.classifierId
  })
      .then(() => {
        emit('afterAction', 'delete')
      })
}

const closeClsDelete = () => emit('hideModal')

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(BaseModalCard, { onClose: closeClsDelete }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, " Удаление классификатора «" + _toDisplayString(__props.classModel.classifierName) + "» ", 1)
    ]),
    body: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("p", null, "Вы действительно хотите удалить классификатор?", -1)
    ])),
    footer: _withCtx(() => [
      _createVNode(_component_q_btn, {
        color: "secondary",
        onClick: actualDelete
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode(" Удалить ")
        ])),
        _: 1
      }),
      _createVNode(_component_q_btn, {
        color: "red-5",
        onClick: closeClsDelete
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" Отмена ")
        ])),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})