import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "inter-city" }
const _hoisted_2 = {
  style: {"gap":"16px","display":"none"},
  class: "row q-mb-md"
}
const _hoisted_3 = { class: "custom-row" }
const _hoisted_4 = { class: "relative-position fit" }
const _hoisted_5 = { class: "row items-center justify-end" }
const _hoisted_6 = {
  style: {"bottom":"-25px","white-space":"nowrap"},
  class: "absolute text-grey-7 text-weight-bold"
}
const _hoisted_7 = { class: "row items-center justify-end" }
const _hoisted_8 = {
  style: {"position":"relative","width":"100%","white-space":"nowrap","height":"56px"},
  class: "inter-city__users q-ml-auto"
}
const _hoisted_9 = { class: "row q-mt-xl q-mb-md" }
const _hoisted_10 = {
  key: 0,
  class: "tree-input-dropdown",
  style: {"background":"white","max-height":"500px","overflow":"auto","position":"absolute","z-index":"4","width":"100%"}
}
const _hoisted_11 = { name: "emissia" }
const _hoisted_12 = { class: "row justify-end q-mb-md" }
const _hoisted_13 = { class: "row no-wrap full-width" }

import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
import MetricComponent from "@/components/modules/intersection/components/Metrics/MetricComponent.vue";
import { computed, onMounted, ref, toRaw } from "vue";
import store from "@/store";
import CityProjectChart from "@/components/modules/intersection/components/CityPoints/CityProjectChart.vue";
import CityEarnedChart from "@/components/modules/intersection/components/CityPoints/CityEarnedChart.vue";
import { onClickOutside } from "@vueuse/core";
import CityEarnedTable from "@/components/modules/intersection/components/CityPoints/CityEarnedTable.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InterCity',
  setup(__props) {

const metricArr: any = ref([])
const dateMin = ref('')
const dateMax = ref(new Date().toJSON().slice(0, 10))
const qDateProxy1: any = ref()
const qDateProxy2: any = ref()
const interval = ref({
  day: 0,
  month: 2,
  year: 4,
})
const sortType = ref(interval.value.month)
const tab = ref('emissia')
const allSum = ref(0)
const chartLoader = ref(false)
const filterInput = ref('')
const activityFilterInput = ref('')
const selectedProjects = ref([])
const selectedActions = ref([])
const projectSelectRef = ref()
const dropdownRef = ref()
const isDropdownOpen = ref(false)
const isDropdownOpenActivity = ref(false)
const projectAssociationProj = ref({})
const projectAssociationAct = ref({})
const cityTable = ref()
const minPointDate = ref('')
const fakeLoader = ref(false)

const metricValues = computed(() => {
  const metricList = store.getters.getMetricChecked

  return toRaw(metricList)
})

const getProjectItems = computed(() => {
  let rawProjects = store.getters.getProjectsItems

  const treeNodes: any = [
    {
      label: 'Все проекты',
      children: [],
    },
  ]

  for (let i in rawProjects) {
    treeNodes[0].children.push({ label: rawProjects[i].getProjectName() })
  }

  return treeNodes
})

const actionsList = computed(() => {
  let rawActions = store.getters.getCityActions

  let nodes: any = [
    {
      id: 'allctions',
      label: 'Все действия',
      children: [],
    },
  ]
  let tempObj: any = {}
  for (const i in rawActions) {
    if (!tempObj[rawActions[i].getProjectId()]) {
      tempObj[rawActions[i].getProjectId()] = {
        label:
          'Все действия по проекту "' +
          projectAssociationAct.value[rawActions[i].getProjectId()] +
          '"',
        id:
          rawActions[i].getActivText() + '#' + rawActions[i].getProjectId(),
        children: [],
      }

      tempObj[rawActions[i].getProjectId()].children.push({
        label: rawActions[i].getActivText(),
        id: `${rawActions[i].getActivId()}#${rawActions[i].getActivText()}#${rawActions[i].getProjectId()}`,
      })
    } else {
      tempObj[rawActions[i].getProjectId()].children.push({
        label: rawActions[i].getActivText(),
        id: `${rawActions[i].getActivId()}#${rawActions[i].getActivText()}#${rawActions[i].getProjectId()}`,
      })
    }
  }

  for (const i in tempObj) {
    nodes[0].children.push(tempObj[i])
  }

  return nodes
})

const isEarnedPointsChart = computed(() => store.getters.isEarnedPointsChart)

const dateOptionsStart = (date: any) => {
  return date < dateMax.value.split('-').join('/') && date >= minPointDate.value.split('-').join('/')
}

const dateOptionsEnd = (date: any) => {
  return date > dateMin.value.split('-').join('/') && date <= new Date().toJSON().slice(0, 10).split('-').join('/')
}

const getMinDate = () => {
  const dateSplitter = dateMax.value.split('-')
  const prevYear = Number(dateSplitter[0]) - 1

  dateMin.value = prevYear + '-' + dateSplitter[1] + '-' + dateSplitter[2]
}

const getMinMaxDate = (isButton: boolean) => {
  fakeLoader.value = true

  store
    .dispatch('getMinMaxPointDate', {
      activ_ids: splitActions()
    })
    .then((res) => {
      minPointDate.value = res.getMinDate()

      if (isButton) {
        dateMin.value = minPointDate.value

        applyPeriod()
      } else {
        const minAvailableDate: string[] = minPointDate.value.split('-')
        const newAvailableDate: Date = new Date(Number(minAvailableDate[0]), Number(minAvailableDate[1]) - 1, Number(minAvailableDate[2]))
        const minUsedDate: string[] = dateMin.value.split('-')
        const newUsedDate: Date = new Date(Number(minUsedDate[0]), Number(minUsedDate[1]) - 1, Number(minUsedDate[2]))

        if (newAvailableDate.getTime() > newUsedDate.getTime()) {
          dateMin.value = minPointDate.value
        }
      }

      fakeLoader.value = false
    })
}

const getMetricList = () => {
  store.dispatch('getMetricList', {
    filter: {},
    sort: {},
    pagination: {}
  }).then((value: any) => {
    for (const i in value) {
      metricArr.value.push(value[i])
    }
  })
}

const splitActions = () => {
  let splittedArr = []

  splittedArr = selectedActions.value.map((el: string) => {
    const resArr = el.split('#')

    return {
      activ_id: Number(resArr[0]),
      activ_text: resArr[1],
      project_id: Number(resArr[2]),
    }
  })

  return splittedArr
}

const getPointsByProject = () => {
  chartLoader.value = true

  store
    .dispatch('getPointsByProject', {
      activity: metricValues.value['Активность'],
      loyalty: metricValues.value['Лояльность'],
      useful: metricValues.value['Полезность'],
      interest: metricValues.value['Заинтересованность'],
      activ_ids: splitActions(),
      date_begin: dateMin.value,
      date_end: dateMax.value,
    })
    .then(res => {
      allSum.value = 0

      for (const i of res) {
        allSum.value += i.getValue()
      }

      chartLoader.value = false
    })
}

const getCityEarnedChart = () => {
  store
    .dispatch('getEarnedPointsChart', {
      activity: metricValues.value['Активность'],
      loyalty: metricValues.value['Лояльность'],
      useful: metricValues.value['Полезность'],
      interest: metricValues.value['Заинтересованность'],
      activ_ids: splitActions(),
      date_begin: dateMin.value,
      date_end: dateMax.value,
      interval: sortType.value
    })
}

const getProjects = () => {
  store.dispatch('getProjectsItems', {
    filter: [],
    sort: {
      name: 'project_id',
      exp: 'asc',
    },
  })
    .then((res) => {
      const filteredAssocProjects = []
      const filteredAssocActions = []

      for (const i of res.items) {
        filteredAssocProjects.push([i.getProjectName(), i.getProjectId()])
        filteredAssocActions.push([i.getProjectId(), i.getProjectName()])
      }

      projectAssociationProj.value = Object.fromEntries(filteredAssocProjects)
      projectAssociationAct.value = Object.fromEntries(filteredAssocActions)
    })
}

const showDropDown = (value: boolean) => {
  isDropdownOpen.value = value
}

const showDropDownActivity = (value: boolean) => {
  isDropdownOpenActivity.value = value
}

onClickOutside(projectSelectRef, (e: any) => {
  e.target.closest('.project-select-tree') ? null : showDropDown(false)
})

onClickOutside(dropdownRef, (e: any) => {
  e.target.closest('.tree-input') ? null : showDropDownActivity(false)
})

const onSelectProjects = () => {
  const actionsArr: any = []

  if (selectedProjects.value.length) {
    for (const item of selectedProjects.value) {
      actionsArr.push(projectAssociationProj.value[item])
    }
  }

  const actionList: any = []
  const projectList: any = actionsList.value[0].children

  for (const i of projectList) {
    if (actionsArr.length && actionsArr.includes(Number(i.id.split('#')[1]))) {
      for (const j of i.children) {
        actionList.push(j.id)
      }
    }
  }

  selectedActions.value = actionsArr.length ? actionList : []
}

const getCityActions = () => {
  store.dispatch('getCityActions', {
    project_ids: [],
  })
}

const applyPeriod = () => {
  getPointsByProject()

  if (tab.value === 'emissia') {
    getCityEarnedChart()
  } else {
    if (cityTable.value) {
      if (cityTable.value.groupCurrent.value !== 'no_group') {
        cityTable.value.loadGroupedData()
      } else {
        cityTable.value.getEarnedTable()
      }
    }
  }
}

onMounted(() => {
  getMinDate()
  getMetricList()
  getProjects()
  getCityActions()
  applyPeriod()
  getMinMaxDate(false)
})

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_q_tree = _resolveComponent("q-tree")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(Breadcrumbs),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(metricArr.value, (metricItem) => {
          return (_openBlock(), _createBlock(MetricComponent, {
            options: metricItem,
            key: metricItem
          }, null, 8, ["options"]))
        }), 128)),
        _createVNode(_component_q_btn, {
          style: {"height":"56px"},
          color: "primary",
          label: "Применить",
          size: "md",
          onClick: applyPeriod
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_q_input, {
            filled: "",
            modelValue: dateMin.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((dateMin).value = $event)),
            "hide-bottom-space": "",
            "hide-hint": "",
            style: {"width":"100%","cursor":"pointer"},
            "input-style": "cursor:pointer",
            label: "Дата начала",
            mask: "####-##-##",
            readonly: "",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (qDateProxy1.value.show()))
          }, {
            append: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: "event",
                class: "cursor-pointer"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_popup_proxy, {
                    cover: "",
                    "transition-show": "scale",
                    "transition-hide": "scale",
                    ref_key: "qDateProxy1",
                    ref: qDateProxy1
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_date, {
                        modelValue: dateMin.value,
                        "onUpdate:modelValue": [
                          _cache[0] || (_cache[0] = ($event: any) => ((dateMin).value = $event)),
                          _cache[1] || (_cache[1] = ($event: any) => (qDateProxy1.value.hide()))
                        ],
                        mask: "YYYY-MM-DD",
                        "navigation-max-year-month": new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
                        options: dateOptionsStart
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, [
                            _withDirectives(_createVNode(_component_q_btn, {
                              label: "Close",
                              color: "primary",
                              flat: ""
                            }, null, 512), [
                              [_directive_close_popup]
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "navigation-max-year-month"])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_q_inner_loading, {
            showing: fakeLoader.value,
            color: "grey"
          }, null, 8, ["showing"]),
          _createElementVNode("div", _hoisted_6, " Минимальная дата - " + _toDisplayString(minPointDate.value.split('-').reverse().join('.')), 1)
        ]),
        _createVNode(_component_q_input, {
          filled: "",
          modelValue: dateMax.value,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((dateMax).value = $event)),
          label: "Дата окончания",
          "hide-bottom-space": "",
          "hide-hint": "",
          mask: "####-##-##",
          readonly: "",
          style: {"width":"100%","cursor":"pointer"},
          "input-style": "cursor:pointer",
          onClick: _cache[7] || (_cache[7] = ($event: any) => (qDateProxy2.value.show()))
        }, {
          append: _withCtx(() => [
            _createVNode(_component_q_icon, {
              name: "event",
              class: "cursor-pointer"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_popup_proxy, {
                  cover: "",
                  "transition-show": "scale",
                  "transition-hide": "scale",
                  ref_key: "qDateProxy2",
                  ref: qDateProxy2
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_date, {
                      modelValue: dateMax.value,
                      "onUpdate:modelValue": [
                        _cache[4] || (_cache[4] = ($event: any) => ((dateMax).value = $event)),
                        _cache[5] || (_cache[5] = ($event: any) => (qDateProxy2.value.hide()))
                      ],
                      mask: "YYYY-MM-DD",
                      options: dateOptionsEnd
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_7, [
                          _withDirectives(_createVNode(_component_q_btn, {
                            label: "Close",
                            color: "primary",
                            flat: ""
                          }, null, 512), [
                            [_directive_close_popup]
                          ])
                        ])
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 512)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_q_btn, {
          color: "primary",
          label: "Применить период",
          size: "md",
          onClick: applyPeriod,
          class: "custom-button"
        }),
        _createVNode(_component_q_btn, {
          color: "secondary",
          label: "За всё время",
          title: "При нажатии на кнопку отобразятся регистрации за всё время проектов (с первой по последнюю)",
          size: "md",
          onClick: _cache[8] || (_cache[8] = ($event: any) => (getMinMaxDate(true))),
          class: "custom-button"
        }),
        _createElementVNode("p", _hoisted_8, [
          _cache[21] || (_cache[21] = _createTextVNode(" Всего начислено баллов")),
          _cache[22] || (_cache[22] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("span", null, _toDisplayString(allSum.value.toLocaleString()), 1),
          _createVNode(_component_q_inner_loading, {
            showing: chartLoader.value,
            color: "white"
          }, null, 8, ["showing"])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_q_tabs, {
          modelValue: tab.value,
          "onUpdate:modelValue": [
            _cache[9] || (_cache[9] = ($event: any) => ((tab).value = $event)),
            applyPeriod
          ],
          dense: "",
          class: "text-grey",
          "active-color": "primary",
          "indicator-color": "primary",
          align: "justify",
          "narrow-indicator": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tab, {
              class: "custom-tab",
              name: "emissia",
              label: "Эмиссия гор. баллов по проектам"
            }),
            _createVNode(_component_q_tab, {
              class: "custom-tab",
              name: "table",
              label: "Таблица начислений"
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createElementVNode("div", {
          class: "project-select-tree col",
          ref_key: "projectSelectRef",
          ref: projectSelectRef,
          style: {"max-width":"353px"}
        }, [
          _createVNode(_component_q_input, {
            ref: "projectsInputRef",
            modelValue: filterInput.value,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((filterInput).value = $event)),
            filled: "",
            label: selectedProjects.value.length ? 'Выбрано проектов: (' + selectedProjects.value.length + ')' : 'Все проекты',
            "hide-bottom-space": "",
            clearable: "",
            onFocus: _cache[11] || (_cache[11] = ($event: any) => (showDropDown(true)))
          }, {
            append: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: isDropdownOpen.value ? 'fas fa-caret-up' : 'fas fa-caret-down'
              }, null, 8, ["name"])
            ]),
            _: 1
          }, 8, ["modelValue", "label"]),
          (getProjectItems.value && isDropdownOpen.value)
            ? (_openBlock(), _createElementBlock("div", _mergeProps({
                key: 0,
                class: "select-tree-dropdown"
              }, _ctx.$attrs, { style: {"margin-left":"0"} }), [
                _createVNode(_component_q_tree, {
                  nodes: getProjectItems.value,
                  "node-key": "label",
                  "tick-strategy": "leaf",
                  "default-expand-all": "",
                  ticked: selectedProjects.value,
                  "onUpdate:ticked": [
                    _cache[12] || (_cache[12] = ($event: any) => ((selectedProjects).value = $event)),
                    onSelectProjects
                  ],
                  filter: filterInput.value
                }, null, 8, ["nodes", "ticked", "filter"])
              ], 16))
            : _createCommentVNode("", true)
        ], 512),
        _createElementVNode("div", {
          class: "tree-input col q-ml-md",
          ref_key: "dropdownRef",
          ref: dropdownRef,
          style: {"position":"relative"}
        }, [
          _createVNode(_component_q_input, {
            modelValue: activityFilterInput.value,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((activityFilterInput).value = $event)),
            filled: "",
            label: selectedActions.value.length ? 'Выбрано действий: (' + selectedActions.value.length + ')' : 'Все действия',
            clearable: "",
            onFocus: _cache[14] || (_cache[14] = ($event: any) => (showDropDownActivity(true)))
          }, {
            append: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: isDropdownOpenActivity.value ? 'fas fa-caret-up' : 'fas fa-caret-down'
              }, null, 8, ["name"])
            ]),
            _: 1
          }, 8, ["modelValue", "label"]),
          (isDropdownOpenActivity.value && actionsList.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_q_tree, {
                  nodes: actionsList.value,
                  "node-key": "id",
                  "label-key": "label",
                  "children-key": "children",
                  "tick-strategy": "leaf",
                  "default-expand-all": "",
                  ticked: selectedActions.value,
                  "onUpdate:ticked": [
                    _cache[15] || (_cache[15] = ($event: any) => ((selectedActions).value = $event)),
                    _cache[16] || (_cache[16] = ($event: any) => (getMinMaxDate(false)))
                  ],
                  filter: activityFilterInput.value
                }, null, 8, ["nodes", "ticked", "filter"])
              ]))
            : _createCommentVNode("", true)
        ], 512),
        _createVNode(_component_q_btn, {
          style: {"height":"56px"},
          color: "primary",
          label: "Применить",
          size: "md",
          onClick: applyPeriod,
          class: "q-ml-md"
        })
      ]),
      _createVNode(_component_q_tab_panels, {
        modelValue: tab.value,
        "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((tab).value = $event)),
        animated: "",
        style: {"background":"none"}
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_q_btn, {
                outline: sortType.value !== 0,
                style: {"height":"56px","min-width":"100px"},
                color: "primary",
                label: "ДНИ",
                size: "md",
                onClick: _cache[17] || (_cache[17] = ($event: any) => {sortType.value = 0; getCityEarnedChart()}),
                disable: isEarnedPointsChart.value
              }, null, 8, ["outline", "disable"]),
              _createVNode(_component_q_btn, {
                outline: sortType.value !== 2,
                style: {"height":"56px","min-width":"100px"},
                color: "primary",
                label: "МЕСЯЦЫ",
                size: "md",
                class: "q-ml-md",
                onClick: _cache[18] || (_cache[18] = ($event: any) => {sortType.value = 2; getCityEarnedChart()}),
                disable: isEarnedPointsChart.value
              }, null, 8, ["outline", "disable"]),
              _createVNode(_component_q_btn, {
                outline: sortType.value !== 4,
                style: {"height":"56px","min-width":"100px"},
                color: "primary",
                label: "ГОДЫ",
                size: "md",
                class: "q-ml-md",
                onClick: _cache[19] || (_cache[19] = ($event: any) => {sortType.value = 4; getCityEarnedChart()}),
                disable: isEarnedPointsChart.value
              }, null, 8, ["outline", "disable"])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(CityProjectChart, {
                style: {"width":"50%"},
                "props-style": 700
              }),
              _createVNode(CityEarnedChart, {
                "sort-type": sortType.value,
                style: {"width":"50%"},
                "style-props": 700
              }, null, 8, ["sort-type"])
            ])
          ]),
          (dateMin.value && dateMax.value)
            ? (_openBlock(), _createBlock(CityEarnedTable, {
                key: 0,
                name: "table",
                "date-min": dateMin.value,
                "date-max": dateMax.value,
                "split-action": splitActions(),
                ref_key: "cityTable",
                ref: cityTable
              }, null, 8, ["date-min", "date-max", "split-action"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ], 64))
}
}

})