import {useGrpcClientWithLogger} from "@/components/modules/rec-system/services/UserGroups/middleware";
import {AgQuestionServiceClient} from "proto/nigma-message-proto/polls/ag_question.client";
import {
    ListAgQuestionRequest,
    ListAgQuestionChildsRequest,
    ReduceAgQuestionRequest,
    SeparateAgQuestionRequest,
} from "proto/nigma-message-proto/polls/ag_question"

export const useAgQuestionApi = () => {
    const client = useGrpcClientWithLogger().create(AgQuestionServiceClient, {baseUrl: window.VUE_APP_GATEWAY_URL})

    const metadata = () => {
        return {
            Authorization: localStorage.getItem('access-key') || ''
        }
    }

    return {
        listAgQuestionRequest: (request: ListAgQuestionRequest) => {
            return client.listAgQuestion(request, {meta: metadata()}).response
        },
        listAgQuestionChildsRequest: (request: ListAgQuestionChildsRequest) => {
            return client.listAgQuestionChilds(request, {meta: metadata()}).response
        },
        reduceAgQuestionRequest: (request: ReduceAgQuestionRequest) => {
            return client.reduceAgQuestion(request, {meta: metadata()}).response
        },
        separateAgQuestionRequest: (request: SeparateAgQuestionRequest) => {
            return client.separateAgQuestion(request, {meta: metadata()}).response
        }
    }
}