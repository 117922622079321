// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "ag_poll.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { AgPollService } from "./ag_poll";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListAgPollResponse } from "./ag_poll";
import type { ListAgPollRequest } from "./ag_poll";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.AgPollService
 */
export interface IAgPollServiceClient {
    /**
     * @generated from protobuf rpc: ListAgPoll(proto.nigmamsg.ListAgPollRequest) returns (proto.nigmamsg.ListAgPollResponse);
     */
    listAgPoll(input: ListAgPollRequest, options?: RpcOptions): UnaryCall<ListAgPollRequest, ListAgPollResponse>;
}
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.AgPollService
 */
export class AgPollServiceClient implements IAgPollServiceClient, ServiceInfo {
    typeName = AgPollService.typeName;
    methods = AgPollService.methods;
    options = AgPollService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ListAgPoll(proto.nigmamsg.ListAgPollRequest) returns (proto.nigmamsg.ListAgPollResponse);
     */
    listAgPoll(input: ListAgPollRequest, options?: RpcOptions): UnaryCall<ListAgPollRequest, ListAgPollResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListAgPollRequest, ListAgPollResponse>("unary", this._transport, method, opt, input);
    }
}
