import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withKeys as _withKeys, createBlock as _createBlock, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "q-pt-md q-pb-md" }
const _hoisted_2 = { class: "text-h4 text-weight-bold q-pl-md" }
const _hoisted_3 = {
  class: "row items-start no-wrap q-pl-md",
  style: {"gap":"40px"}
}
const _hoisted_4 = {
  class: "bg-white text-black q-mt-md q-ml-xl q-pa-sm full-width shadow-4",
  style: {"border-radius":"5px","max-width":"900px"}
}
const _hoisted_5 = {
  key: 0,
  class: "row q-pt-lg q-pb-lg q-pl-lg q-pr-lg relative-position"
}
const _hoisted_6 = { class: "row items-center" }
const _hoisted_7 = { class: "row items-center justify-end" }
const _hoisted_8 = { class: "row items-center justify-end" }
const _hoisted_9 = {
  key: 0,
  class: "ag-report bg-white text-black q-pa-md q-mt-md"
}
const _hoisted_10 = { class: "ag-users" }
const _hoisted_11 = { class: "ag-check" }
const _hoisted_12 = { class: "ag-users__left ag-block q-pa-sm" }
const _hoisted_13 = { class: "custom-title" }
const _hoisted_14 = { class: "custom-plus" }
const _hoisted_15 = {
  class: "row no-wrap",
  style: {"gap":"10px"}
}
const _hoisted_16 = { class: "column items-start" }
const _hoisted_17 = { class: "custom-title" }
const _hoisted_18 = { class: "custom-plus" }
const _hoisted_19 = { class: "column items-start" }
const _hoisted_20 = { class: "custom-title" }
const _hoisted_21 = { class: "custom-plus" }
const _hoisted_22 = { class: "column items-start" }
const _hoisted_23 = { class: "custom-title" }
const _hoisted_24 = { class: "custom-plus" }
const _hoisted_25 = { class: "ag-users__right" }
const _hoisted_26 = { class: "ag-users__right-item" }
const _hoisted_27 = { class: "ag-users__right-item_card ag-block q-pa-sm" }
const _hoisted_28 = { class: "custom-title" }
const _hoisted_29 = { class: "ag-users__right-item_card ag-block q-pa-sm" }
const _hoisted_30 = { class: "custom-title" }
const _hoisted_31 = { class: "custom-plus" }
const _hoisted_32 = { class: "ag-users__right-item" }
const _hoisted_33 = { class: "ag-users__right-item_card ag-block q-pa-sm" }
const _hoisted_34 = { class: "custom-title" }
const _hoisted_35 = { class: "custom-plus" }
const _hoisted_36 = { class: "ag-users__right-item_card ag-block q-pa-sm" }
const _hoisted_37 = { class: "custom-title" }
const _hoisted_38 = { class: "custom-plus" }
const _hoisted_39 = { class: "ag-users__right-item" }
const _hoisted_40 = { class: "ag-users__right-item_card ag-block q-pa-sm" }
const _hoisted_41 = { class: "custom-title" }
const _hoisted_42 = { class: "custom-plus" }
const _hoisted_43 = { class: "ag-users__right-item_card ag-block q-pa-sm" }
const _hoisted_44 = { class: "custom-title" }
const _hoisted_45 = { class: "custom-plus" }
const _hoisted_46 = {
  class: "row",
  style: {"gap":"5px","max-width":"667px"}
}
const _hoisted_47 = { class: "ag-block q-pa-sm" }
const _hoisted_48 = { class: "custom-title" }
const _hoisted_49 = { class: "ag-block q-pa-sm" }
const _hoisted_50 = { class: "custom-title" }
const _hoisted_51 = { class: "ag-block q-pa-sm" }
const _hoisted_52 = { class: "custom-title" }
const _hoisted_53 = { class: "custom-plus" }
const _hoisted_54 = { class: "ag-block q-pa-sm" }
const _hoisted_55 = { class: "custom-title" }
const _hoisted_56 = { class: "custom-plus" }
const _hoisted_57 = { class: "row" }
const _hoisted_58 = {
  class: "column",
  style: {"gap":"5px"}
}
const _hoisted_59 = {
  class: "row",
  style: {"gap":"5px"}
}
const _hoisted_60 = {
  class: "ag-block q-pa-sm",
  style: {"width":"136px"}
}
const _hoisted_61 = { class: "custom-title" }
const _hoisted_62 = {
  class: "ag-block q-pa-sm",
  style: {"width":"192px"}
}
const _hoisted_63 = { class: "custom-title" }
const _hoisted_64 = {
  class: "ag-block q-pa-sm",
  style: {"width":"135px"}
}
const _hoisted_65 = { class: "custom-title" }
const _hoisted_66 = {
  class: "row",
  style: {"gap":"5px"}
}
const _hoisted_67 = {
  class: "ag-block q-pa-sm",
  style: {"width":"136px"}
}
const _hoisted_68 = { class: "custom-title" }
const _hoisted_69 = {
  class: "ag-block q-pa-sm",
  style: {"width":"192px"}
}
const _hoisted_70 = { class: "custom-title" }
const _hoisted_71 = {
  class: "ag-block q-pa-sm",
  style: {"width":"135px"}
}
const _hoisted_72 = { class: "custom-title" }
const _hoisted_73 = {
  key: 1,
  class: "ag-report bg-white text-black q-pa-md q-mt-md"
}
const _hoisted_74 = {
  class: "row q-mt-sm",
  style: {"gap":"5px"}
}
const _hoisted_75 = { class: "ag-block ag-block__points q-pa-sm" }
const _hoisted_76 = { class: "custom-title" }
const _hoisted_77 = { class: "custom-plus" }
const _hoisted_78 = { class: "ag-block ag-block__points q-pa-sm" }
const _hoisted_79 = { class: "custom-title" }
const _hoisted_80 = { class: "custom-plus" }
const _hoisted_81 = { class: "ag-block ag-block__points q-pa-sm" }
const _hoisted_82 = { class: "custom-title" }
const _hoisted_83 = { class: "custom-plus" }
const _hoisted_84 = { class: "ag-block ag-block__points q-pa-sm" }
const _hoisted_85 = { class: "custom-title" }
const _hoisted_86 = { class: "custom-plus" }
const _hoisted_87 = { class: "ag-block ag-block__points q-pa-sm" }
const _hoisted_88 = { class: "custom-title" }
const _hoisted_89 = { class: "custom-plus" }
const _hoisted_90 = {
  class: "row",
  style: {"gap":"5px","margin-top":"5px"}
}
const _hoisted_91 = { class: "custom-text" }
const _hoisted_92 = { class: "custom-title" }
const _hoisted_93 = { class: "custom-plus" }
const _hoisted_94 = {
  key: 2,
  class: "ag-report bg-white text-black q-pa-md q-mt-md"
}
const _hoisted_95 = {
  class: "row q-mt-sm",
  style: {"gap":"5px"}
}
const _hoisted_96 = { class: "ag-block q-pa-sm" }
const _hoisted_97 = { class: "custom-plus" }
const _hoisted_98 = { class: "ag-block q-pa-sm" }
const _hoisted_99 = { class: "custom-plus" }
const _hoisted_100 = { class: "ag-block q-pa-sm" }
const _hoisted_101 = { class: "custom-plus" }
const _hoisted_102 = { class: "ag-block q-pa-sm" }
const _hoisted_103 = { class: "custom-plus" }
const _hoisted_104 = {
  key: 3,
  class: "ag-report bg-white text-black q-pa-md q-mt-md"
}
const _hoisted_105 = {
  class: "row q-mt-sm",
  style: {"gap":"5px"}
}
const _hoisted_106 = { class: "ag-block q-pa-sm" }
const _hoisted_107 = { class: "custom-title" }
const _hoisted_108 = { class: "ag-block q-pa-sm" }
const _hoisted_109 = { class: "custom-title" }
const _hoisted_110 = { class: "custom-plus" }
const _hoisted_111 = { class: "ag-block q-pa-sm" }
const _hoisted_112 = { class: "custom-title" }
const _hoisted_113 = { class: "custom-plus" }
const _hoisted_114 = {
  key: 5,
  class: "ag-report bg-white text-black q-pa-md q-mt-md"
}
const _hoisted_115 = { class: "row items-stretch q-mt-sm full-width" }
const _hoisted_116 = {
  class: "row q-mt-sm relative-position",
  style: {"gap":"5px"}
}
const _hoisted_117 = {
  key: 0,
  class: "text-weight-bold text-h5 q-mt-md"
}
const _hoisted_118 = {
  key: 1,
  class: "relative-position full-width q-mt-md shadow-4"
}
const _hoisted_119 = {
  key: 2,
  class: "text-weight-bold text-h5 q-mt-md"
}
const _hoisted_120 = {
  key: 4,
  class: "row q-mt-sm relative-position",
  style: {"gap":"5px"}
}
const _hoisted_121 = {
  key: 5,
  class: "text-weight-bold text-h5 q-mt-md"
}
const _hoisted_122 = {
  key: 6,
  class: "q-mt-sm relative-position"
}
const _hoisted_123 = {
  key: 7,
  class: "text-weight-bold text-h5 q-mt-md"
}
const _hoisted_124 = {
  key: 9,
  class: "q-mt-sm relative-position"
}
const _hoisted_125 = {
  key: 0,
  class: "gi-report bg-white text-black q-pa-md q-mt-md"
}
const _hoisted_126 = { class: "gi-users" }
const _hoisted_127 = { class: "gi-check" }
const _hoisted_128 = { class: "gi-users__left gi-block q-pa-sm" }
const _hoisted_129 = { class: "custom-title" }
const _hoisted_130 = { class: "custom-plus" }
const _hoisted_131 = {
  class: "row no-wrap",
  style: {"gap":"10px"}
}
const _hoisted_132 = { class: "column items-start" }
const _hoisted_133 = { class: "custom-title" }
const _hoisted_134 = { class: "custom-plus" }
const _hoisted_135 = { class: "column items-start" }
const _hoisted_136 = { class: "custom-title" }
const _hoisted_137 = { class: "custom-plus" }
const _hoisted_138 = { class: "column items-start" }
const _hoisted_139 = { class: "custom-title" }
const _hoisted_140 = { class: "custom-plus" }
const _hoisted_141 = { class: "gi-users__right" }
const _hoisted_142 = { class: "gi-users__right-item" }
const _hoisted_143 = { class: "gi-users__right-item_card gi-block q-pa-sm" }
const _hoisted_144 = { class: "custom-title" }
const _hoisted_145 = { class: "gi-users__right-item_card gi-block q-pa-sm" }
const _hoisted_146 = { class: "custom-title" }
const _hoisted_147 = { class: "custom-plus" }
const _hoisted_148 = { class: "gi-users__right-item" }
const _hoisted_149 = { class: "gi-users__right-item_card gi-block q-pa-sm" }
const _hoisted_150 = { class: "custom-title" }
const _hoisted_151 = { class: "custom-plus" }
const _hoisted_152 = { class: "gi-users__right-item" }
const _hoisted_153 = { class: "gi-users__right-item_card gi-block q-pa-sm" }
const _hoisted_154 = { class: "custom-title" }
const _hoisted_155 = { class: "custom-plus" }
const _hoisted_156 = {
  class: "row q-mt-md no-wrap",
  style: {"gap":"5px"}
}
const _hoisted_157 = { class: "column full-width" }
const _hoisted_158 = {
  class: "row no-wrap items-start",
  style: {"gap":"5px","max-width":"667px"}
}
const _hoisted_159 = { class: "gi-block q-pa-sm full-width" }
const _hoisted_160 = { class: "custom-title" }
const _hoisted_161 = { class: "custom-plus" }
const _hoisted_162 = { class: "gi-block q-pa-sm full-width" }
const _hoisted_163 = { class: "custom-title" }
const _hoisted_164 = { class: "custom-plus" }
const _hoisted_165 = {
  class: "gi-block q-pa-sm full-width",
  style: {"max-width":"calc(50% - 2px)","margin":"-12px"}
}
const _hoisted_166 = { class: "custom-title" }
const _hoisted_167 = { class: "custom-plus" }
const _hoisted_168 = { class: "column full-width" }
const _hoisted_169 = {
  class: "row no-wrap items-start",
  style: {"gap":"5px","max-width":"667px"}
}
const _hoisted_170 = { class: "gi-block q-pa-sm full-width" }
const _hoisted_171 = { class: "custom-title" }
const _hoisted_172 = { class: "custom-plus" }
const _hoisted_173 = { class: "gi-block q-pa-sm full-width" }
const _hoisted_174 = { class: "custom-title" }
const _hoisted_175 = { class: "custom-plus" }
const _hoisted_176 = {
  class: "row",
  style: {"gap":"5px"}
}
const _hoisted_177 = { class: "gi-block q-pa-sm" }
const _hoisted_178 = { class: "custom-title" }
const _hoisted_179 = { class: "gi-block q-pa-sm" }
const _hoisted_180 = { class: "custom-title" }
const _hoisted_181 = { class: "gi-block q-pa-sm" }
const _hoisted_182 = { class: "custom-title" }
const _hoisted_183 = { class: "gi-block q-pa-sm" }
const _hoisted_184 = { class: "custom-title" }
const _hoisted_185 = {
  key: 1,
  class: "gi-report bg-white text-black q-pa-md q-mt-md"
}
const _hoisted_186 = {
  class: "row q-mt-sm",
  style: {"gap":"5px"}
}
const _hoisted_187 = { class: "gi-block q-pa-sm" }
const _hoisted_188 = { class: "custom-plus" }
const _hoisted_189 = { class: "gi-block q-pa-sm" }
const _hoisted_190 = { class: "custom-plus" }
const _hoisted_191 = { class: "gi-block q-pa-sm" }
const _hoisted_192 = { class: "custom-plus" }
const _hoisted_193 = { class: "gi-block q-pa-sm" }
const _hoisted_194 = { class: "custom-plus" }
const _hoisted_195 = { class: "gi-block q-pa-sm" }
const _hoisted_196 = { class: "custom-plus" }
const _hoisted_197 = { class: "gi-block q-pa-sm" }
const _hoisted_198 = { class: "custom-plus" }
const _hoisted_199 = {
  key: 0,
  class: "text-h4 text-weight-bold q-mt-md q-ml-md"
}
const _hoisted_200 = {
  key: 1,
  class: "gz-report bg-white text-black fit q-pa-md q-mt-md q-ml-md"
}
const _hoisted_201 = { class: "gz-users" }
const _hoisted_202 = { class: "gz-check row no-wrap" }
const _hoisted_203 = { class: "gz-users__left gz-block q-pa-sm full-width" }
const _hoisted_204 = { class: "custom-title" }
const _hoisted_205 = { class: "custom-plus" }
const _hoisted_206 = {
  class: "row no-wrap",
  style: {"gap":"10px"}
}
const _hoisted_207 = { class: "column items-start" }
const _hoisted_208 = { class: "custom-title" }
const _hoisted_209 = { class: "custom-plus" }
const _hoisted_210 = { class: "column items-start" }
const _hoisted_211 = { class: "custom-title" }
const _hoisted_212 = { class: "custom-plus" }
const _hoisted_213 = { class: "column items-start" }
const _hoisted_214 = { class: "custom-title" }
const _hoisted_215 = { class: "custom-plus" }
const _hoisted_216 = { class: "gz-users__left gz-block q-pa-sm column full-width" }
const _hoisted_217 = { class: "custom-title" }
const _hoisted_218 = {
  class: "row no-wrap",
  style: {"gap":"10px"}
}
const _hoisted_219 = { class: "column items-start" }
const _hoisted_220 = { class: "custom-title" }
const _hoisted_221 = { class: "custom-plus custom-hidden" }
const _hoisted_222 = { class: "column items-start" }
const _hoisted_223 = { class: "custom-title" }
const _hoisted_224 = { class: "custom-plus custom-hidden" }
const _hoisted_225 = { class: "column items-start" }
const _hoisted_226 = { class: "custom-title" }
const _hoisted_227 = { class: "custom-plus custom-hidden" }
const _hoisted_228 = { class: "gz-users__left gz-block q-pa-sm column full-width" }
const _hoisted_229 = { class: "custom-title" }
const _hoisted_230 = {
  class: "row no-wrap",
  style: {"gap":"10px"}
}
const _hoisted_231 = { class: "column items-start" }
const _hoisted_232 = { class: "custom-title" }
const _hoisted_233 = { class: "custom-plus custom-hidden" }
const _hoisted_234 = { class: "column items-start" }
const _hoisted_235 = { class: "custom-title" }
const _hoisted_236 = { class: "custom-plus custom-hidden" }
const _hoisted_237 = { class: "column items-start" }
const _hoisted_238 = { class: "custom-title" }
const _hoisted_239 = { class: "custom-plus custom-hidden" }
const _hoisted_240 = {
  class: "row no-wrap items-start",
  style: {"gap":"5px","max-width":"667px"}
}
const _hoisted_241 = { class: "gz-block q-pa-sm full-width" }
const _hoisted_242 = { class: "custom-title" }
const _hoisted_243 = { class: "gz-block q-pa-sm full-width" }
const _hoisted_244 = { class: "custom-title" }
const _hoisted_245 = { class: "gz-block q-pa-sm full-width" }
const _hoisted_246 = { class: "custom-title" }
const _hoisted_247 = {
  class: "row no-wrap items-start",
  style: {"gap":"5px","max-width":"667px"}
}
const _hoisted_248 = { class: "gz-block q-pa-sm full-width" }
const _hoisted_249 = { class: "custom-title" }
const _hoisted_250 = { class: "gz-block q-pa-sm full-width" }
const _hoisted_251 = { class: "custom-title" }
const _hoisted_252 = { class: "gz-block q-pa-sm full-width" }
const _hoisted_253 = { class: "custom-title" }
const _hoisted_254 = {
  class: "row items-start",
  style: {"gap":"5px","max-width":"667px"}
}
const _hoisted_255 = { class: "gz-block q-pa-sm" }
const _hoisted_256 = { class: "custom-title" }
const _hoisted_257 = { class: "custom-plus" }
const _hoisted_258 = { class: "gz-block q-pa-sm" }
const _hoisted_259 = { class: "custom-title" }
const _hoisted_260 = { class: "custom-plus" }
const _hoisted_261 = { class: "gz-block q-pa-sm" }
const _hoisted_262 = { class: "custom-title" }
const _hoisted_263 = { class: "custom-plus" }
const _hoisted_264 = { class: "gz-block q-pa-sm" }
const _hoisted_265 = { class: "custom-title" }
const _hoisted_266 = { class: "custom-plus" }
const _hoisted_267 = {
  key: 2,
  class: "gz-report bg-white text-black fit q-pa-md q-mt-md q-ml-md"
}
const _hoisted_268 = {
  class: "row q-mt-sm",
  style: {"gap":"5px"}
}
const _hoisted_269 = { class: "gz-block q-pa-sm" }
const _hoisted_270 = { class: "custom-plus" }
const _hoisted_271 = { class: "gz-block q-pa-sm" }
const _hoisted_272 = { class: "custom-plus" }
const _hoisted_273 = { class: "gz-block q-pa-sm" }
const _hoisted_274 = { class: "custom-plus" }
const _hoisted_275 = { class: "gz-block q-pa-sm" }
const _hoisted_276 = { class: "custom-plus" }
const _hoisted_277 = {
  key: 0,
  class: "text-h4 text-weight-bold q-mt-md q-ml-md"
}
const _hoisted_278 = {
  key: 1,
  class: "ed-report bg-white text-black fit q-pa-md q-mt-md q-ml-md"
}
const _hoisted_279 = { class: "ed-users" }
const _hoisted_280 = { class: "ed-check row no-wrap" }
const _hoisted_281 = { class: "ed-users__left ed-block q-pa-sm full-width" }
const _hoisted_282 = { class: "custom-title" }
const _hoisted_283 = { class: "custom-plus" }
const _hoisted_284 = { class: "ed-users__right full-width" }
const _hoisted_285 = { class: "ed-users__right-item full-width" }
const _hoisted_286 = { class: "ed-users__right-item_card ed-block q-pa-sm full-width" }
const _hoisted_287 = { class: "custom-title" }
const _hoisted_288 = { class: "custom-plus" }
const _hoisted_289 = { class: "ed-users__right-item_card ed-block q-pa-sm full-width" }
const _hoisted_290 = { class: "custom-title" }
const _hoisted_291 = { class: "custom-plus" }
const _hoisted_292 = {
  class: "row no-wrap items-stretch",
  style: {"gap":"5px","max-width":"667px"}
}
const _hoisted_293 = { class: "ed-block q-pa-sm full-width" }
const _hoisted_294 = { class: "custom-title" }
const _hoisted_295 = { class: "custom-plus" }
const _hoisted_296 = {
  class: "row no-wrap items-stretch",
  style: {"gap":"5px","max-width":"667px"}
}
const _hoisted_297 = { class: "ed-block q-pa-sm full-width" }
const _hoisted_298 = { class: "custom-title" }
const _hoisted_299 = { class: "custom-plus" }
const _hoisted_300 = {
  class: "row no-wrap items-stretch",
  style: {"gap":"5px","max-width":"667px"}
}
const _hoisted_301 = { class: "ed-block q-pa-sm full-width" }
const _hoisted_302 = { class: "custom-title" }
const _hoisted_303 = { class: "custom-plus" }
const _hoisted_304 = {
  key: 2,
  class: "ed-report bg-white text-black fit q-pa-md q-mt-md q-ml-md"
}
const _hoisted_305 = {
  class: "row no-wrap items-stretch",
  style: {"gap":"5px","max-width":"667px"}
}
const _hoisted_306 = { class: "ed-block q-pa-sm full-width" }
const _hoisted_307 = { class: "custom-title" }
const _hoisted_308 = { class: "custom-plus" }
const _hoisted_309 = {
  class: "row no-wrap items-start",
  style: {"gap":"5px","max-width":"667px"}
}
const _hoisted_310 = { class: "ed-block q-pa-sm full-width" }
const _hoisted_311 = { class: "custom-title" }
const _hoisted_312 = { class: "custom-plus" }
const _hoisted_313 = {
  key: 3,
  class: "ed-report bg-white text-black fit q-pa-md q-mt-md q-ml-md"
}
const _hoisted_314 = {
  class: "row q-mt-sm no-wrap",
  style: {"gap":"5px","max-width":"667px"}
}
const _hoisted_315 = { class: "ed-block q-pa-sm full-width" }
const _hoisted_316 = { class: "custom-plus" }
const _hoisted_317 = { class: "ed-block q-pa-sm full-width" }
const _hoisted_318 = { class: "custom-plus" }

import {onMounted, onBeforeMount, ref, computed, watch} from "vue";
import store from "@/store";
import Vue3Html2pdf from "vue3-html2pdf/src/vue3-html2pdf.vue";
import date from 'quasar/src/utils/date/date.js';;
import {useRoute, useRouter} from "vue-router";
import { LoadPolls } from "@/components/modules/polls/services/api/LoadPolls";
import AgChild from "./AgChild.vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectPage',
  setup(__props) {

const router = useRouter()
const loadPolls = new LoadPolls()

const dateFrom = ref();
const dateTo = ref();
const dateFromAfterLoad = ref("");
const dateToAfterLoad = ref("");
const qDateProxy1: any = ref();
const qDateProxy2: any = ref();
const maxDateLimit = date.formatDate(Date.now(), "YYYY/MM");
const datesForTable = ref<{ startDate: string; endDate: string }[]>([]);
const watchStatus = ref(false);
const isExport = ref(false);
const html2pdf = ref();
const route = useRoute();
const cols = [
  {
    name: 'sso_id',
    label: 'SSO_ID',
    field: 'sso_id',
    align: 'left',
    headerStyle: 'font-weight: bold',
  },
  {
    name: 'poll_title',
    label: 'Название голосования',
    field: 'poll_title',
    align: 'left',
    headerStyle: 'font-weight: bold',
  },
  {
    name: 'answer_src_text',
    label: 'Ответ',
    field: 'answer_src_text',
    align: 'left',
    headerStyle: 'font-weight: bold',
  },
]
const reportPagination = ref({
  sortBy: "sso_id",
  descending: true,
  page: 1,
  rowsPerPage: 5,
  rowsNumber: 0,
});
const exportPagination = ref({
  sortBy: "sso_id",
  descending: true,
  page: 1,
  rowsPerPage: 10000000,
  rowsNumber: 0,
});
const projects = ref([
  {
    name: "ag",
    title: "АГ",
    subtitle: "Активный гражданин",
    reportType: "getAgReportFasterMax",
    className: "Класс для отчета АГ",
  },
  {
    name: "gi",
    title: "ГИ",
    subtitle: "Город идей",
    reportType: "getGiReportFasterMax",
    className: "Класс для отчета АГ",
  },
  {
    name: 'gz',
    title: 'ГЗ',
    subtitle: 'Город заданий',
    reportType: 'getGzReportFasterMax',
    className: 'Класс для отчета АГ',
  },
  {
    name: 'ed',
    title: 'ЭД',
    subtitle: 'Электронный дом',
    reportType: 'getEdReportFasterMax',
    className: 'Класс для отчета АГ',
  },
  {
    name: 'ag-child',
    title: 'АГ детям',
    subtitle: 'Активный гражданин детям',
    reportType: 'getAgChildReportFasterMax',
    className: 'Класс для отчета АГ',
  },
]);
const currentProject = ref({
  name: "",
  title: "",
  subtitle: "",
  reportType: "",
  className: "",
});
const currentProjectStore = ref({
  name: "",
  reportList: "",
  reportListBusy: true,
});
const voteCols = ref([
  {
    label: 'ID',
    name: 'poll_id',
    field: 'poll_id',
    align: 'center',
    type: 'number',
    headerStyle: 'font-weight: 700',
  },
  {
    label: 'Название',
    name: 'poll_title',
    field: 'title',
    align: 'left',
    style: 'width: 350px',
    headerStyle: 'font-weight: 700',
  },
  {
    label: 'Статус',
    name: 'poll_status',
    field: 'poll_status',
    align: 'center',
    type: 'number',
    headerStyle: 'font-weight: 700',
  },
  {
    label: 'Тип',
    name: 'poll_target',
    field: 'poll_target',
    align: 'center',
    type: 'number',
    headerStyle: 'font-weight: 700',
  },
  {
    label: 'Дата начала',
    name: 'poll_date_begin',
    field: 'poll_date_begin',
    align: 'center',
    headerStyle: 'font-weight: 700',
  },
  {
    label: 'Дата окончания',
    name: 'poll_date_end',
    field: 'poll_date_end',
    align: 'center',
    headerStyle: 'font-weight: 700',
  },
  {
    label: 'Кол-во участников',
    name: 'poll_user_count',
    field: 'poll_user_count',
    sortable: true,
    align: 'center',
    headerStyle: 'font-weight: 700',
  },
])
const loadPollsPagination = ref({
  sortBy: 'poll_loading_flag',
  descending: true,
  page: 1,
  rowsPerPage: 10,
  rowsNumber: 0,
})
const selectedVote = ref([])
const chartOptions = ref({
  chart: {
    type: 'line',
    zoomType: 'x',
  },

  xAxis: {
    type: 'datetime',
  },

  yAxis: {
    title: {
      text: 'Кол-во голосов',
    },
  },

  title: {
    text: ''
  },

  series: [
    {
      type: 'line',
      color: '#3d5afe',
      name: 'Всего голосов',
      data: [],
      dataLabels: {
        enabled: true,
      },
      showInLegend: false,
    },
  ],
})
const voteSearch = ref('')
const voteStatus = ref({
  label: 'Все',
  value: 1
})
const voteType = ref({
  label: 'Все',
  value: 1
})
const statusOptions = ref([
  {
    label: 'Все',
    value: 1
  },
  {
    label: 'Открыто',
    value: 2,
    operand: '>'
  },
  {
    label: 'Завершено',
    value: 3,
    operand: '<='
  }
])
const typeOptions = ref([
  {
    label: 'Все',
    value: 1
  },
  {
    label: 'Общегородское',
    value: 2,
    operand: '='
  },
  {
    label: 'Таргетированное',
    value: 3,
    operand: '<>'
  }
])
const voteUsersPagination = ref({
  sortBy: 'sso_id',
  descending: true,
  page: 1,
  rowsPerPage: 10,
  rowsNumber: 0,
})
const userCols = ref([{
  label: 'SSO ID',
  name: 'sso_id',
  field: 'sso_id',
  align: 'left',
  type: 'number',
  headerStyle: 'font-weight: 700',
}])
const currentPollId = ref(0)
const personalLoader = ref(false)
const questionColumns = ref([
  {
    label: 'ID',
    name: 'question_id',
    field: 'question_id',
    align: 'left',
    type: 'string',
    headerStyle: 'font-weight: 700',
  },
  {
    label: 'Название вопроса',
    name: 'question_title',
    field: 'question_title',
    align: 'left',
    type: 'string',
    headerStyle: 'font-weight: 700',
  },
])
const questionRows = ref([])
const questionLoader = ref(false)
const answerColumns = ref([
  {
    label: 'ID',
    name: 'answer_id',
    field: 'answer_id',
    align: 'left',
    type: 'string',
    headerStyle: 'font-weight: 700',
    style: 'width: 55px',
  },
  {
    label: 'Название ответа',
    name: 'answer_title',
    field: 'answer_title',
    align: 'left',
    type: 'string',
    headerStyle: 'font-weight: 700',
    style: 'width: 500px',
  },
  {
    label: 'Количество голосов',
    name: 'votes_count',
    field: 'votes_count',
    align: 'center',
    type: 'number',
    sortable: true,
    headerStyle: 'font-weight: 700',
  },
])
const answerUsersCols = ref([
  {
    label: 'SSO ID',
    name: 'sso_id',
    field: 'sso_id',
    align: 'left',
    type: 'number',
    headerStyle: 'font-weight: 700',
  }
])
const answerUsersRows = ref([])
const answerUsersPagination = ref({
  sortBy: 'sso_id',
  descending: true,
  page: 1,
  rowsPerPage: 10,
  rowsNumber: 0,
})
const answerUsersLoader = ref(false)
const currentAnswer = ref()
const currentQuestionName = ref('')

projects.value.forEach((project) => {
  if (project.name === route.query.project) currentProject.value = project;
});

const voteRows = computed(() => {
  const rawList = store.getters.getListPollLoadItems
  const filteredList = []

  if (rawList) {
    for (const i of rawList) {
      const beginDate = date.formatDate(i.getPollDateBegin().getSeconds() * 1000, 'DD.MM.YYYY')
      const endDate = date.formatDate(i.getPollDateEnd().getSeconds() * 1000, 'DD.MM.YYYY')
      const todayDate = Date.now()
      const endCompareDate = i.getPollDateEnd().getSeconds() * 1000

      filteredList.push({
        poll_id: i.getPollId(),
        title: i.getPollTitle(),
        poll_status: todayDate < endCompareDate ? 'Открыто' : 'Завершено',
        poll_user_count: i.getPollUserCount().toLocaleString(),
        open_answers: i.getPollOpenAnswers().toLocaleString(),
        loaded_answers: i.getPollLoadedAnswers().toLocaleString(),
        poll_date_begin: beginDate,
        poll_date_end: endDate,
        poll_target: i.getPollTarget() ? 'Таргетированное' : 'Общегородское'
      })
    }
  }

  return filteredList
})
const userRows = computed(() => {
  const items = store.getters.getVoteUsers
  const filteredList = []

  if (items) {
    for (const i of items) filteredList.push({ sso_id: i })
  }

  return filteredList
})
const exportRows = computed(() => {
  const items = store.getters.getAnswerExportInClassItems;
  const rawList: any = [];

  if (items) {
    for (const i of items) {
      rawList.push({
        sso_id: i.getSsoId(),
        poll_title: i.getPollTitle(),
        answer_src_text: i.getAnswerSrcText(),
      });
    }
  }

  return rawList;
});

const reportList = computed(() => {
  let item = [] as any;
  if (currentProjectStore.value.reportList)
    item = currentProjectStore.value.reportList;
  return item;
});
const isReportBusy = computed(() => {
  let item = false;
  if (currentProjectStore.value.reportListBusy)
    item = currentProjectStore.value.reportListBusy;
  return item;
});
const isLoadPollsBusy = computed(() => store.getters.isListPollLoadBusy)
const pollDate = computed(() => {
  const items = store.getters.getPollByDate
  const filteredList = []

  if (items) {
    for (const i of items) {
      const date = i.getDate().split('-')

      filteredList.push([Date.UTC(date[0], date[1] - 1, date[2]), i.getVotes()])
    }
  }

  return filteredList
})
const isPollByDate = computed(() => store.getters.isPollByDate)
const isVoteUsers = computed(() => store.getters.isVoteUsers)

watch(pollDate, newValue => {
  chartOptions.value.series[0].data = newValue
})

const getCurrentProjectStore = () => {
  const projectsStore = ref([
    {
      name: "ag",
      reportList: store.getters.getAgReportFasterMax,
      reportListBusy: store.getters.getIsAgReportFasterMaxBusy,
    },
    {
      name: "gi",
      reportList: store.getters.getGiReportFasterMax,
      reportListBusy: store.getters.getIsGiReportFasterMaxBusy,
    },
    {
      name: 'gz',
      reportList: store.getters.getGzReportFasterMax,
      reportListBusy: store.getters.getIsGzReportFasterMaxBusy,
    },
    {
      name: 'ed',
      reportList: store.getters.getEdReportFasterMax,
      reportListBusy: store.getters.getIsEdReportFasterMaxBusy,
    },
    {
      name: 'ag-child',
      reportList: store.getters.getAgChildReportFasterMax,
      reportListBusy: store.getters.getIsAgChildReportFasterMaxBusy,
    },
  ]);

  projectsStore.value.forEach((project) => {
    if (project.name === currentProject.value.name) {
      currentProjectStore.value = project;
    }
  });
};

const dateOptionsStart = (date: any) => {
  return date <= dateTo.value.split("-").join("/");
};

const dateOptionsEnd = (date: any) => {
  return (
    date >= dateFrom.value.split("-").join("/") &&
    date <=
      new Date(new Date().setDate(new Date().getDate() - 1))
        .toJSON()
        .slice(0, 10)
        .split("-")
        .join("/")
  );
};

const explainDates = () => {
  for (let i = 1; i < 7; i++) {
    const monday = new Date();
    const sunday = new Date();
    monday.setDate(monday.getDate() - ((monday.getDay() + 6) % 7));
    monday.setDate(monday.getDate() - 7 - 7 * i);
    sunday.setDate(sunday.getDate() - ((sunday.getDay() + 6) % 7));
    sunday.setDate(sunday.getDate() - 1 - 7 * i);

    datesForTable.value.push({
      startDate: monday.toJSON().slice(0, 10),
      endDate: sunday.toJSON().slice(0, 10),
    });
  }
};
const exportToPdf = () => {
  isExport.value = true;
  html2pdf.value.generatePdf();

  setTimeout(() => {
    isExport.value = false;
  }, 1000);
};

const getTableData = () => {
  store
    .dispatch("getAnswerInClassItems", {
      filter: [
        {
          field_name: "asrc.answer_src_time::date",
          operand: ">=",
          value: dateFrom.value,
        },
        {
          field_name: "asrc.answer_src_time::date",
          operand: "<=",
          value: dateTo.value,
        },
      ],
      sort: {
        name: reportPagination.value.sortBy,
        exp: reportPagination.value.descending ? "desc" : "asc",
      },
      pagination: {
        page: reportPagination.value.page,
        limit: reportPagination.value.rowsPerPage,
        pages: 0,
        cnt: reportPagination.value.rowsNumber,
      },
      class_name: "Класс для отчета АГ",
    })
    .then((res) => {
      reportPagination.value.rowsNumber = res.pagination.getCnt();
    });
};

const getExportTableData = () => {
  store
    .dispatch("getAnswerExportInClassItems", {
      filter: [
        {
          field_name: "asrc.answer_src_time::date",
          operand: ">=",
          value: dateFrom.value,
        },
        {
          field_name: "asrc.answer_src_time::date",
          operand: "<=",
          value: dateTo.value,
        },
      ],
      sort: {
        name: exportPagination.value.sortBy,
        exp: exportPagination.value.descending ? "desc" : "asc",
      },
      pagination: {
        page: exportPagination.value.page,
        limit: exportPagination.value.rowsPerPage,
        pages: 0,
        cnt: exportPagination.value.rowsNumber,
      },
      class_name: "Класс для отчета АГ",
    })
    .then((res) => {
      exportPagination.value.rowsNumber = res.pagination.getCnt();
    });
};

const onRequestExport = (props: any) => {
  exportPagination.value = props.pagination;
  getExportTableData();
};

const applyPeriodsFasterMax = (
  startDate: string,
  endDate: string,
  isMain: boolean,
  isWatching: boolean
) => {
  getTableData();
  getExportTableData();

  if (currentProject.value.title === 'АГ') loadListPollLoad(true)
  currentProjectStore.value = {
    name: "",
    reportList: "",
    reportListBusy: true,
  };
  store
    .dispatch(currentProject.value.reportType, {
      startDate: isMain && !isWatching ? dateFrom.value : startDate,
      endDate: isMain && !isWatching ? dateTo.value : endDate,
    })
    .then(() => {
      dateFromAfterLoad.value =
        isMain && !isWatching ? dateFrom.value : startDate;
      dateToAfterLoad.value = isMain && !isWatching ? dateTo.value : endDate;
      if (!isMain && !isWatching) {
        watchStatus.value = false;
        exportToPdf();
      }

      if (isWatching) watchStatus.value = false;

      getCurrentProjectStore();
    });
};

const getFilters = () => {
  const filters = []

  if (voteStatus.value.value !== 1) {
    filters.push({
      field_name: 'poll_date_end',
      operand: voteStatus.value.operand,
      value: date.formatDate(Date.now(), 'YYYY-MM-DD')
    })
  }

  if (voteType.value.value !== 1) {
    filters.push({
      field_name: 'poll_target',
      operand: voteType.value.operand,
      value: ""
    })
  }

  filters.push({
    field_name: 'poll_date_begin',
    operand: '>=',
    value: dateFrom.value,
    or: [
      {
        field_name: 'poll_date_end',
        operand: '>=',
        value: dateFrom.value
      }
    ]
  })

  filters.push({
    field_name: 'poll_date_begin',
    operand: '<=',
    value: dateTo.value,
    or: [
      {
        field_name: 'poll_date_end',
        operand: '<=',
        value: dateTo.value
      }
    ]
  })

  if (voteSearch.value) {
    filters.push({
      field_name: 'poll_id::text',
      operand: 'ilike',
      value: voteSearch.value,
      or: [
        {
          field_name: 'poll_title::text',
          operand: 'ilike',
          value: voteSearch.value
        }
      ]
    })
  }

  return filters
}

const loadListPollLoad = (isResetPagination: boolean) => {
  if (isResetPagination) {
    loadPollsPagination.value.page = 1
    loadPollsPagination.value.rowsNumber = 0
  }

  store
      .dispatch('getListPollLoadItems', {
        filter: getFilters(),
        sort: {
          name: loadPollsPagination.value.sortBy,
          exp: loadPollsPagination.value.descending ? 'DESC' : 'ASC',
        },
        pagination: {
          page: loadPollsPagination.value.page,
          limit: loadPollsPagination.value.rowsPerPage,
          pages: 0,
          cnt: loadPollsPagination.value.rowsNumber,
        }
      })
      .then((res) => {
        loadPollsPagination.value.rowsNumber = res.pagination.getCnt()
      })
}

const loadPollsTableRequest = (props: any) => {
  loadPollsPagination.value = props.pagination
  loadListPollLoad(false)
}

const voteUsersRequest = (props: any) => {
  voteUsersPagination.value = props.pagination
  getVoteUsers(false)
}

const voteRowClick = (_, props) => {
  selectedVote.value = [props]
  currentPollId.value = props.poll_id
  getPollByDate()
  getVoteUsers(true)
  getQuestionRows()
}

const getPollByDate = () => {
  store
      .dispatch('getPollsByDate', {
        poll_id: currentPollId.value
      })
      .then(res => {
        console.log('bydate res', res)
      })
}

const getVoteUsers = (isResetPagination: boolean) => {
  if (isResetPagination) {
    voteUsersPagination.value.page = 1
    voteUsersPagination.value.rowsNumber = 0
  }

  store
      .dispatch('getVoteUsers', {
        pagination: {
          page: voteUsersPagination.value.page,
          limit: voteUsersPagination.value.rowsPerPage,
          pages: 0,
          cnt: voteUsersPagination.value.rowsNumber,
        },
        poll_id: currentPollId.value
      })
      .then(res => {
        voteUsersPagination.value.rowsNumber = res.pagination.getCnt()
      })
}

const getPersonalPolls = () => {
  personalLoader.value = true

  loadPolls
      .getPersonalPollLoadItems(
          {
            page: voteUsersPagination.value.page,
            limit: voteUsersPagination.value.rowsPerPage,
            pages: 0,
            cnt: voteUsersPagination.value.rowsNumber,
          },
          currentPollId.value
      )
      .then(() => {
        router.push({
          name: 'UserProjects',
          query: {
            data: 'personal'
          }
        })

        personalLoader.value = false
      })
}

const getQuestionRows = () => {
  questionLoader.value = true

  loadPolls
      .getPollLoadAnswers(currentPollId.value)
      .then(res => {
        console.log('question rows', res.toObject())

        questionRows.value = res.toObject().itemsList.map(question => {
          return {
            question_id: question.questionId,
            question_title: question.questionTitle,
            answers_list:
                question.answersList.map(answer => {
                  return {
                    answer_id: answer.answerId,
                    answer_title: answer.answerTitle,
                    votes_count: answer.votesCount,
                  }
                }).sort((a, b) => b.votes_count - a.votes_count),
          }
        })

        questionLoader.value = false
      })
}

const getAnswerAccounts = () => {
  answerUsersLoader.value = true

  loadPolls
      .getAnswerAccounts(
          {
            page: answerUsersPagination.value.page,
            limit: answerUsersPagination.value.rowsPerPage,
            pages: 0,
            cnt: answerUsersPagination.value.rowsNumber,
          },
          currentAnswer.value.answer_id
      )
      .then(res => {
        const items = res.toObject()

        console.log('items', items)

        if (items) {
          answerUsersPagination.value.rowsNumber = items.pagination?.cnt | undefined
          answerUsersRows.value = items.ssoIdList.map(el => {
            return {
              sso_id: el,
            }
          })
        }

        answerUsersLoader.value = false
      })
}

const onAnswerUsersRequest = (props: any) => {
  answerUsersPagination.value = props.pagination
  getAnswerAccounts()
}

onMounted(() => {
  getTableData();
  explainDates();
  if (currentProject.value.title === 'АГ') loadListPollLoad(true)
  applyPeriodsFasterMax("", "", true, false);
});

onBeforeMount(() => {
  let beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000),
      day = beforeOneWeek.getDay(),
      diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1),
      lastMonday = new Date(beforeOneWeek.setDate(diffToMonday)),
      lastSunday = new Date(beforeOneWeek.setDate(diffToMonday + 6))

  dateFrom.value = lastMonday.toJSON().slice(0, 10)
  dateTo.value = lastSunday.toJSON().slice(0, 10)
});

watch(reportList, newValue => {
  if (currentProject.value.name === 'ag') {
    pointNamesArr.value.forEach(el => {
      el.mainValue = newValue[el.main]
      el.periodValue = newValue[el.period]
    })

    pointNamesArr.value = pointNamesArr.value.sort((a, b) => b.mainValue - a.mainValue)
  }
})

const pointNamesArr = ref([
  {
    label: 'Публикация профиля',
    main: 'Публикация профиля (баллы)',
    period: 'Публикация профиля за период (баллы)',
    mainValue: 0,
    periodValue: 0,
  },
  {
    label: 'Получение гор. баллов',
    main: 'Отмена заказов, некоторые викторины, за участие в Городе заданий, победы/участия в акциях (баллы)',
    period: 'Отмена заказов, некоторые викторины, за участие в Городе заданий, победы/участия в акциях за период (баллы)',
    mainValue: 0,
    periodValue: 0,
  },
  {
    label: 'Списание ошибочно начисленных баллов',
    main: 'Списание ошибочно начисленных баллов (баллы)',
    period: 'Списание ошибочно начисленных баллов за период (баллы)',
    mainValue: 0,
    periodValue: 0,
  },
  {
    label: 'Активность на пр. АГ',
    main: 'Активность на проекте АГ (баллы)',
    period: 'Активность на проекте АГ за период (баллы)',
    mainValue: 0,
    periodValue: 0,
  },
  {
    label: 'Интервью',
    main: 'Интервью (баллы)',
    period: 'Интервью за период (баллы)',
    mainValue: 0,
    periodValue: 0,
  },
  {
    label: 'Заполн. профиля',
    main: 'Заполн. профиля (баллы)',
    period: 'Заполн. профиля за период (баллы)',
    mainValue: 0,
    periodValue: 0,
  },
  {
    label: 'Регистрация',
    main: 'Регистрация (баллы)',
    period: 'Регистрация за период (баллы)',
    mainValue: 0,
    periodValue: 0,
  },
  {
    label: 'Промокод',
    main: 'Промокод (баллы)',
    period: 'Промокод за период (баллы)',
    mainValue: 0,
    periodValue: 0,
  },
  {
    label: 'Промоушен',
    main: 'Промоушен (баллы)',
    period: 'Промоушен за период (баллы)',
    mainValue: 0,
    periodValue: 0,
  },
  {
    label: 'Марафон',
    main: 'Марафон (баллы)',
    period: 'Марафон за период (баллы)',
    mainValue: 0,
    periodValue: 0,
  },
  {
    label: 'Согласование дизайн-проекта размещения вывески',
    main: 'Согласование дизайн-проекта размещения вывески (баллы)',
    period: 'Согласование дизайн-проекта размещения вывески за период (баллы)',
    mainValue: 0,
    periodValue: 0,
  },
])

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_highcharts = _resolveComponent("highcharts")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, " Цифровой профиль проекта " + _toDisplayString(currentProject.value.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (!watchStatus.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_q_input, {
                  filled: "",
                  modelValue: dateFrom.value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((dateFrom).value = $event)),
                  "hide-bottom-space": "",
                  "hide-hint": "",
                  style: {"width":"199px","cursor":"pointer"},
                  "input-style": "cursor:pointer",
                  label: "Дата начала",
                  mask: "####-##-##",
                  readonly: "",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (qDateProxy1.value.show())),
                  class: "q-mr-sm"
                }, {
                  append: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      name: "event",
                      class: "cursor-pointer"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_popup_proxy, {
                          cover: "",
                          "transition-show": "scale",
                          "transition-hide": "scale",
                          ref_key: "qDateProxy1",
                          ref: qDateProxy1
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_date, {
                              modelValue: dateFrom.value,
                              "onUpdate:modelValue": [
                                _cache[0] || (_cache[0] = ($event: any) => ((dateFrom).value = $event)),
                                _cache[1] || (_cache[1] = ($event: any) => (qDateProxy1.value.hide()))
                              ],
                              mask: "YYYY-MM-DD",
                              "navigation-min-year-month": "2012/01",
                              "navigation-max-year-month": _unref(maxDateLimit),
                              "default-year-month": "2012/01",
                              "no-unset": "",
                              options: dateOptionsStart
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_7, [
                                  _withDirectives(_createVNode(_component_q_btn, {
                                    label: "Close",
                                    color: "primary",
                                    flat: ""
                                  }, null, 512), [
                                    [_directive_close_popup]
                                  ])
                                ])
                              ]),
                              _: 1
                            }, 8, ["modelValue", "navigation-max-year-month"])
                          ]),
                          _: 1
                        }, 512)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _cache[21] || (_cache[21] = _createElementVNode("div", { class: "text-subtitle1" }, "—", -1)),
                _createVNode(_component_q_input, {
                  filled: "",
                  modelValue: dateTo.value,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((dateTo).value = $event)),
                  label: "Дата окончания",
                  "hide-bottom-space": "",
                  "hide-hint": "",
                  mask: "####-##-##",
                  readonly: "",
                  style: {"width":"199px","cursor":"pointer"},
                  "input-style": "cursor:pointer",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (qDateProxy2.value.show())),
                  class: "q-ml-sm"
                }, {
                  append: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      name: "event",
                      class: "cursor-pointer"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_popup_proxy, {
                          cover: "",
                          "transition-show": "scale",
                          "transition-hide": "scale",
                          ref_key: "qDateProxy2",
                          ref: qDateProxy2
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_date, {
                              modelValue: dateTo.value,
                              "onUpdate:modelValue": [
                                _cache[4] || (_cache[4] = ($event: any) => ((dateTo).value = $event)),
                                _cache[5] || (_cache[5] = ($event: any) => (qDateProxy2.value.hide()))
                              ],
                              mask: "YYYY-MM-DD",
                              "no-unset": "",
                              "navigation-max-year-month": _unref(maxDateLimit),
                              "navigation-min-year-month": "2012/01",
                              options: dateOptionsEnd
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_8, [
                                  _withDirectives(_createVNode(_component_q_btn, {
                                    label: "Close",
                                    color: "primary",
                                    flat: ""
                                  }, null, 512), [
                                    [_directive_close_popup]
                                  ])
                                ])
                              ]),
                              _: 1
                            }, 8, ["modelValue", "navigation-max-year-month"])
                          ]),
                          _: 1
                        }, 512)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _createVNode(_component_q_btn, {
                class: "q-mt-sm",
                color: "primary",
                label: "Применить",
                onClick: _cache[8] || (_cache[8] = ($event: any) => (applyPeriodsFasterMax('', '', true, false))),
                style: {"margin":"auto 0 auto 16px"}
              }),
              (currentProject.value.name === 'ag')
                ? (_openBlock(), _createBlock(Vue3Html2pdf, {
                    key: 0,
                    "show-layout": false,
                    "float-layout": false,
                    "enable-download": true,
                    "preview-modal": false,
                    "paginate-elements-by-height": 1070,
                    filename: 
              'НИГМА. Отчет по проекту' +
              currentProject.value.subtitle +
              'за период с ' +
              dateFromAfterLoad.value.split('-').reverse().join('.') +
              ' по ' +
              dateToAfterLoad.value.split('-').reverse().join('.')
            ,
                    "pdf-quality": 3,
                    "manual-pagination": false,
                    "pdf-format": "a4",
                    "pdf-orientation": "portrait",
                    "pdf-content-width": "836px",
                    ref_key: "html2pdf",
                    ref: html2pdf,
                    class: "project-page"
                  }, {
                    "pdf-content": _withCtx(() => [
                      (Object.keys(reportList.value).length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _cache[43] || (_cache[43] = _createElementVNode("div", { class: "text-weight-bold text-h5" }, "Основные показатели", -1)),
                            _cache[44] || (_cache[44] = _createElementVNode("div", { class: "text-h6 q-mt-sm" }, "Пользователи", -1)),
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("div", _hoisted_11, [
                                _createElementVNode("div", _hoisted_12, [
                                  _cache[25] || (_cache[25] = _createElementVNode("div", { class: "text-subtitle2" }, " Всего пользователей проекта ", -1)),
                                  _createElementVNode("div", _hoisted_13, _toDisplayString(reportList.value["Всего пользователей проекта"].toLocaleString()), 1),
                                  _createElementVNode("div", _hoisted_14, _toDisplayString(reportList.value["Новых регистраций"] > 0 ? "+" : "") + _toDisplayString(reportList.value["Новых регистраций"].toLocaleString()), 1),
                                  _cache[26] || (_cache[26] = _createElementVNode("div", {
                                    class: "q-mt-sm",
                                    style: {"font-size":"12px"}
                                  }, " По типу УЗ ", -1)),
                                  _createElementVNode("div", _hoisted_15, [
                                    _createElementVNode("div", _hoisted_16, [
                                      _cache[22] || (_cache[22] = _createElementVNode("span", { class: "custom-text" }, "Полная", -1)),
                                      _createElementVNode("span", _hoisted_17, _toDisplayString(reportList.value["Полная"].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_18, _toDisplayString(reportList.value["Полная за период"] > 0 ? "+" : "") + _toDisplayString(reportList.value["Полная за период"].toLocaleString()), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_19, [
                                      _cache[23] || (_cache[23] = _createElementVNode("span", { class: "custom-text" }, "Стандартная", -1)),
                                      _createElementVNode("span", _hoisted_20, _toDisplayString(reportList.value["Стандартная"].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_21, _toDisplayString(reportList.value["Стандартная за период"] > 0
                                ? "+"
                                : "") + _toDisplayString(reportList.value[
                                "Стандартная за период"
                              ].toLocaleString()), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_22, [
                                      _cache[24] || (_cache[24] = _createElementVNode("span", { class: "custom-text" }, "Упрощенная", -1)),
                                      _createElementVNode("span", _hoisted_23, _toDisplayString(reportList.value["Упрощенная"].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_24, _toDisplayString(reportList.value["Упрощенная за период"] > 0 ? "+" : "") + _toDisplayString(reportList.value[
                                "Упрощенная за период"
                              ].toLocaleString()), 1)
                                    ])
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_25, [
                                  _createElementVNode("div", _hoisted_26, [
                                    _createElementVNode("div", _hoisted_27, [
                                      _cache[27] || (_cache[27] = _createElementVNode("div", {
                                        class: "custom-text",
                                        style: {"white-space":"nowrap"}
                                      }, " Новых регистраций ", -1)),
                                      _createElementVNode("div", _hoisted_28, _toDisplayString(reportList.value["Новых регистраций"].toLocaleString()), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_29, [
                                      _cache[28] || (_cache[28] = _createElementVNode("div", {
                                        class: "custom-text",
                                        style: {"white-space":"nowrap"}
                                      }, " Начислено гор. баллов ", -1)),
                                      _createElementVNode("div", _hoisted_30, _toDisplayString(reportList.value[
                                "Начислено гор. баллов"
                              ].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_31, _toDisplayString(reportList.value["Начислено гор. баллов за период"] > 0
                                ? "+"
                                : "") + _toDisplayString(reportList.value[
                                "Начислено гор. баллов за период"
                              ].toLocaleString()), 1)
                                    ])
                                  ]),
                                  _createElementVNode("div", _hoisted_32, [
                                    _createElementVNode("div", _hoisted_33, [
                                      _cache[29] || (_cache[29] = _createElementVNode("div", {
                                        class: "custom-text",
                                        style: {"white-space":"nowrap"}
                                      }, " Всего посещений сайта ", -1)),
                                      _createElementVNode("div", _hoisted_34, _toDisplayString(reportList.value[
                                "Всего посещений сайта"
                              ].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_35, _toDisplayString(reportList.value["Посещений сайта за период"] > 0
                                ? "+"
                                : "") + _toDisplayString(reportList.value[
                                "Посещений сайта за период"
                              ].toLocaleString()), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_36, [
                                      _cache[30] || (_cache[30] = _createElementVNode("div", {
                                        class: "custom-text",
                                        style: {"white-space":"nowrap"}
                                      }, " Всего посещений приложения ", -1)),
                                      _createElementVNode("div", _hoisted_37, _toDisplayString(reportList.value[
                                "Всего посещений приложения"
                              ].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_38, _toDisplayString(reportList.value["Посещений приложения за период"] > 0
                                ? "+"
                                : "") + _toDisplayString(reportList.value[
                                "Посещений приложения за период"
                              ].toLocaleString()), 1)
                                    ])
                                  ]),
                                  _createElementVNode("div", _hoisted_39, [
                                    _createElementVNode("div", _hoisted_40, [
                                      _cache[31] || (_cache[31] = _createElementVNode("div", {
                                        class: "custom-text",
                                        style: {"white-space":"nowrap"}
                                      }, " Уник. посетителей сайта ", -1)),
                                      _createElementVNode("div", _hoisted_41, _toDisplayString(reportList.value[
                                "Уник. посетителей сайта"
                              ].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_42, _toDisplayString(reportList.value["Уник. посетителей сайта за период"] >
                              0
                                ? "+"
                                : "") + _toDisplayString(reportList.value[
                                "Уник. посетителей сайта за период"
                              ].toLocaleString()), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_43, [
                                      _cache[32] || (_cache[32] = _createElementVNode("div", {
                                        class: "custom-text",
                                        style: {"white-space":"nowrap"}
                                      }, " Уник. пользователей прил. ", -1)),
                                      _createElementVNode("div", _hoisted_44, _toDisplayString(reportList.value[
                                "Уник. пользователей прил."
                              ].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_45, _toDisplayString(reportList.value[
                                "Уник. пользователей прил. за период"
                              ] > 0
                                ? "+"
                                : "") + _toDisplayString(reportList.value[
                                "Уник. пользователей прил. за период"
                              ].toLocaleString()), 1)
                                    ])
                                  ])
                                ])
                              ])
                            ]),
                            _cache[45] || (_cache[45] = _createElementVNode("div", { class: "text-h6 q-mt-md" }, "Голосования", -1)),
                            _createElementVNode("div", _hoisted_46, [
                              _createElementVNode("div", _hoisted_47, [
                                _cache[33] || (_cache[33] = _createElementVNode("div", { class: "custom-text" }, "Открыто голосований", -1)),
                                _createElementVNode("div", _hoisted_48, _toDisplayString(reportList.value["Открыто голосований"].toLocaleString()), 1)
                              ]),
                              _createElementVNode("div", _hoisted_49, [
                                _cache[34] || (_cache[34] = _createElementVNode("div", { class: "custom-text" }, "Завершено голосований", -1)),
                                _createElementVNode("div", _hoisted_50, _toDisplayString(reportList.value["Завершено голосований"].toLocaleString()), 1)
                              ]),
                              _createElementVNode("div", _hoisted_51, [
                                _cache[35] || (_cache[35] = _createElementVNode("div", { class: "custom-text" }, "Уникальных проголосовавших", -1)),
                                _createElementVNode("div", _hoisted_52, _toDisplayString(reportList.value[
                          "Уникальных проголосовавших"
                        ].toLocaleString()), 1),
                                _createElementVNode("div", _hoisted_53, _toDisplayString(reportList.value["Уникальных проголосовавших за период"] > 0
                          ? "+"
                          : "") + _toDisplayString(reportList.value[
                          "Уникальных проголосовавших за период"
                        ].toLocaleString()), 1)
                              ]),
                              _createElementVNode("div", _hoisted_54, [
                                _cache[36] || (_cache[36] = _createElementVNode("div", { class: "custom-text" }, "Всего голосов", -1)),
                                _createElementVNode("div", _hoisted_55, _toDisplayString(reportList.value["Всего голосов"].toLocaleString()), 1),
                                _createElementVNode("div", _hoisted_56, _toDisplayString(reportList.value["Всего голосов за период"] > 0 ? "+" : "") + _toDisplayString(reportList.value["Всего голосов за период"].toLocaleString()), 1)
                              ])
                            ]),
                            _cache[46] || (_cache[46] = _createElementVNode("div", { class: "text-h6 q-mt-md" }, "Гор. новинки и Пульс столицы", -1)),
                            _createElementVNode("div", _hoisted_57, [
                              _createElementVNode("div", _hoisted_58, [
                                _createElementVNode("div", _hoisted_59, [
                                  _createElementVNode("div", _hoisted_60, [
                                    _cache[37] || (_cache[37] = _createElementVNode("div", { class: "custom-text" }, "Открыто гор. новинок", -1)),
                                    _createElementVNode("div", _hoisted_61, _toDisplayString(reportList.value["Открыто гор. новинок"].toLocaleString()), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_62, [
                                    _cache[38] || (_cache[38] = _createElementVNode("div", { class: "custom-text" }, " Уникальных оценивших гор. новинки ", -1)),
                                    _createElementVNode("div", _hoisted_63, _toDisplayString(reportList.value[
                              "Уникальных оценивших гор. новинки"
                            ].toLocaleString()), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_64, [
                                    _cache[39] || (_cache[39] = _createElementVNode("div", { class: "custom-text" }, "Всего оценок гор. новинок", -1)),
                                    _createElementVNode("div", _hoisted_65, _toDisplayString(reportList.value[
                              "Всего оценок гор. новинок"
                            ].toLocaleString()), 1)
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_66, [
                                  _createElementVNode("div", _hoisted_67, [
                                    _cache[40] || (_cache[40] = _createElementVNode("div", { class: "custom-text" }, "Открыто Пульсов столицы", -1)),
                                    _createElementVNode("div", _hoisted_68, _toDisplayString(reportList.value[
                              "Открыто Пульсов столицы"
                            ].toLocaleString()), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_69, [
                                    _cache[41] || (_cache[41] = _createElementVNode("div", { class: "custom-text" }, " Уникальных оценивших Пульсы ", -1)),
                                    _createElementVNode("div", _hoisted_70, _toDisplayString(reportList.value[
                              "Уникальных оценивших Пульсы"
                            ].toLocaleString()), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_71, [
                                    _cache[42] || (_cache[42] = _createElementVNode("div", { class: "custom-text" }, "Всего оценок Пульсов", -1)),
                                    _createElementVNode("div", _hoisted_72, _toDisplayString(reportList.value["Всего оценок Пульсов"].toLocaleString()), 1)
                                  ])
                                ])
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (Object.keys(reportList.value).length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_73, [
                            _cache[52] || (_cache[52] = _createElementVNode("div", { class: "text-weight-bold text-h5" }, "Баллы", -1)),
                            _createElementVNode("div", _hoisted_74, [
                              _createElementVNode("div", _hoisted_75, [
                                _cache[47] || (_cache[47] = _createElementVNode("div", { class: "custom-text" }, "Всего", -1)),
                                _createElementVNode("div", _hoisted_76, _toDisplayString(reportList.value["Начислено гор. баллов"].toLocaleString()), 1),
                                _createElementVNode("div", _hoisted_77, _toDisplayString(reportList.value["Начислено гор. баллов за период"] > 0
                          ? "+"
                          : "") + _toDisplayString(reportList.value[
                          "Начислено гор. баллов за период"
                        ].toLocaleString()), 1)
                              ]),
                              _createElementVNode("div", _hoisted_78, [
                                _cache[48] || (_cache[48] = _createElementVNode("div", { class: "custom-text" }, "Голосования", -1)),
                                _createElementVNode("div", _hoisted_79, _toDisplayString(reportList.value["Голосования (баллы)"].toLocaleString()), 1),
                                _createElementVNode("div", _hoisted_80, _toDisplayString(reportList.value["Голосования за период (баллы)"] > 0
                          ? "+"
                          : "") + _toDisplayString(reportList.value[
                          "Голосования за период (баллы)"
                        ].toLocaleString()), 1)
                              ]),
                              _createElementVNode("div", _hoisted_81, [
                                _cache[49] || (_cache[49] = _createElementVNode("div", { class: "custom-text" }, "Гор. новинки", -1)),
                                _createElementVNode("div", _hoisted_82, _toDisplayString(reportList.value["Гор. новинки (баллы)"].toLocaleString()), 1),
                                _createElementVNode("div", _hoisted_83, _toDisplayString(reportList.value["Гор. новинки за период (баллы)"] > 0
                          ? "+"
                          : "") + _toDisplayString(reportList.value[
                          "Гор. новинки за период (баллы)"
                        ].toLocaleString()), 1)
                              ]),
                              _createElementVNode("div", _hoisted_84, [
                                _cache[50] || (_cache[50] = _createElementVNode("div", { class: "custom-text" }, "Пульсы столицы", -1)),
                                _createElementVNode("div", _hoisted_85, _toDisplayString(reportList.value["Пульсы столицы (баллы)"].toLocaleString()), 1),
                                _createElementVNode("div", _hoisted_86, _toDisplayString(reportList.value["Пульсы столицы за период (баллы)"] > 0
                          ? "+"
                          : "") + _toDisplayString(reportList.value[
                          "Пульсы столицы за период (баллы)"
                        ].toLocaleString()), 1)
                              ]),
                              _createElementVNode("div", _hoisted_87, [
                                _cache[51] || (_cache[51] = _createElementVNode("div", { class: "custom-text" }, "Реф. программа", -1)),
                                _createElementVNode("div", _hoisted_88, _toDisplayString(reportList.value["Реф. программа (баллы)"].toLocaleString()), 1),
                                _createElementVNode("div", _hoisted_89, _toDisplayString(reportList.value["Реф. программа за период (баллы)"] > 0
                          ? "+"
                          : "") + _toDisplayString(reportList.value[
                          "Реф. программа за период (баллы)"
                        ].toLocaleString()), 1)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_90, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pointNamesArr.value, (point) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  class: "ag-block q-pa-sm",
                                  key: point.main,
                                  style: _normalizeStyle(point.mainValue === 0 ? 'display: none' : null)
                                }, [
                                  _createElementVNode("div", _hoisted_91, _toDisplayString(point.label), 1),
                                  _createElementVNode("div", _hoisted_92, _toDisplayString(point.mainValue.toLocaleString()), 1),
                                  _createElementVNode("div", _hoisted_93, _toDisplayString(point.periodValue > 0 ? '+' : '') + _toDisplayString(point.periodValue.toLocaleString()), 1)
                                ], 4))
                              }), 128))
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (Object.keys(reportList.value).length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_94, [
                            _cache[57] || (_cache[57] = _createElementVNode("div", { class: "text-weight-bold text-h5" }, [
                              _createTextVNode(" Аномалии "),
                              _createElementVNode("span", { class: "text-weight-light text-subtitle1" }, "(отклонения от среднего за последние 3 периода)")
                            ], -1)),
                            _createElementVNode("div", _hoisted_95, [
                              _createElementVNode("div", _hoisted_96, [
                                _cache[53] || (_cache[53] = _createElementVNode("div", { class: "custom-text" }, "Отклонение по регистрациям", -1)),
                                _createElementVNode("div", {
                                  class: _normalizeClass([
                        reportList.value['Отклонение по регистрациям'] > 0
                          ? 'text-green'
                          : 'text-red'
                      , "custom-title"])
                                }, _toDisplayString(reportList.value["Отклонение по регистрациям"] > 0 ? "+" : "") + _toDisplayString(reportList.value[
                          "Отклонение по регистрациям"
                        ].toLocaleString()), 3),
                                _createElementVNode("div", _hoisted_97, _toDisplayString(reportList.value["Отклонение по регистрациям %"].toFixed(1)) + "% (проц. от среднего) ", 1)
                              ]),
                              _createElementVNode("div", _hoisted_98, [
                                _cache[54] || (_cache[54] = _createElementVNode("div", { class: "custom-text" }, "Отклонение по баллам", -1)),
                                _createElementVNode("div", {
                                  class: _normalizeClass([
                        reportList.value['Отклонение по баллам'] > 0
                          ? 'text-green'
                          : 'text-red'
                      , "custom-title"])
                                }, _toDisplayString(reportList.value["Отклонение по баллам"] > 0 ? "+" : "") + _toDisplayString(reportList.value["Отклонение по баллам"].toLocaleString()), 3),
                                _createElementVNode("div", _hoisted_99, _toDisplayString(reportList.value["Отклонение по баллам %"].toFixed(1)) + "% (проц. от среднего) ", 1)
                              ]),
                              _createElementVNode("div", _hoisted_100, [
                                _cache[55] || (_cache[55] = _createElementVNode("div", { class: "custom-text" }, "Отклонение по посещению сайта", -1)),
                                _createElementVNode("div", {
                                  class: _normalizeClass([
                        reportList.value['Отклонение по посещению сайта'] > 0
                          ? 'text-green'
                          : 'text-red'
                      , "custom-title"])
                                }, _toDisplayString(reportList.value["Отклонение по посещению сайта"] > 0
                          ? "+"
                          : "") + _toDisplayString(reportList.value[
                          "Отклонение по посещению сайта"
                        ].toLocaleString()), 3),
                                _createElementVNode("div", _hoisted_101, _toDisplayString(reportList.value["Отклонение по посещению сайта %"].toFixed(
                          1
                        )) + "% (проц. от среднего) ", 1)
                              ]),
                              _createElementVNode("div", _hoisted_102, [
                                _cache[56] || (_cache[56] = _createElementVNode("div", { class: "custom-text" }, "Отклонение по посещению прил.", -1)),
                                _createElementVNode("div", {
                                  class: _normalizeClass([
                        reportList.value['Отклонение по посещению прил.'] > 0
                          ? 'text-green'
                          : 'text-red'
                      , "custom-title"])
                                }, _toDisplayString(reportList.value["Отклонение по посещению прил."] > 0
                          ? "+"
                          : "") + _toDisplayString(reportList.value[
                          "Отклонение по посещению прил."
                        ].toLocaleString()), 3),
                                _createElementVNode("div", _hoisted_103, _toDisplayString(reportList.value["Отклонение по посещению прил. %"].toFixed(
                          1
                        )) + "% (проц. от среднего) ", 1)
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (Object.keys(reportList.value).length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_104, [
                            _cache[61] || (_cache[61] = _createElementVNode("div", { class: "text-weight-bold text-h5" }, " Анализ негативных реакций ", -1)),
                            _createElementVNode("div", _hoisted_105, [
                              _createElementVNode("div", _hoisted_106, [
                                _cache[58] || (_cache[58] = _createElementVNode("div", { class: "custom-text" }, "Негативных ответов", -1)),
                                _createElementVNode("div", _hoisted_107, _toDisplayString(reportPagination.value.rowsNumber.toLocaleString()), 1)
                              ]),
                              _createElementVNode("div", _hoisted_108, [
                                _cache[59] || (_cache[59] = _createElementVNode("div", { class: "custom-text" }, "Негативных оценок гор. нов.", -1)),
                                _createElementVNode("div", _hoisted_109, _toDisplayString(reportList.value[
                          "Негативных оценок гор. новинок"
                        ].toLocaleString()), 1),
                                _createElementVNode("div", _hoisted_110, _toDisplayString(reportList.value["Негативных оценок гор. новинок %"].toFixed(
                          1
                        )) + "% (проц. от общего кол-ва) ", 1)
                              ]),
                              _createElementVNode("div", _hoisted_111, [
                                _cache[60] || (_cache[60] = _createElementVNode("div", { class: "custom-text" }, "Негативных оценок Пульсов", -1)),
                                _createElementVNode("div", _hoisted_112, _toDisplayString(reportList.value["Негативных оценок Пульсов"].toLocaleString()), 1),
                                _createElementVNode("div", _hoisted_113, _toDisplayString(reportList.value["Негативных оценок Пульсов %"].toFixed(1)) + "% (проц. от общего кол-ва) ", 1)
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (exportRows.value.length)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 4,
                            class: "overflow-hidden bg-white text-black q-mt-md",
                            style: _normalizeStyle([{"border-radius":"10px","border":"1px solid #797979","width":"750px"}, isExport.value ? '' : 'display: none;'])
                          }, [
                            _cache[62] || (_cache[62] = _createElementVNode("div", {
                              class: "custom-text q-ml-md q-mt-sm",
                              style: {"font-size":"14px"}
                            }, " Негативные ответы ", -1)),
                            _createVNode(_component_q_table, {
                              columns: cols,
                              rows: exportRows.value,
                              pagination: exportPagination.value,
                              "onUpdate:pagination": _cache[9] || (_cache[9] = ($event: any) => ((exportPagination).value = $event)),
                              class: "no-shadow sticky-header-table-answer",
                              onRequest: onRequestExport,
                              "hide-bottom": "",
                              "wrap-cells": ""
                            }, null, 8, ["rows", "pagination"])
                          ], 4))
                        : _createCommentVNode("", true),
                      (Object.keys(reportList.value).length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_114, [
                            _cache[66] || (_cache[66] = _createElementVNode("div", { class: "text-weight-bold text-h5" }, " Голосования ", -1)),
                            _createElementVNode("div", _hoisted_115, [
                              _createVNode(_component_q_input, {
                                filled: "",
                                label: "Поиск по ID и названию",
                                dense: "",
                                modelValue: voteSearch.value,
                                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((voteSearch).value = $event)),
                                style: {"max-width":"300px","width":"100%"},
                                onKeyup: _cache[11] || (_cache[11] = _withKeys(($event: any) => (loadListPollLoad(true)), ["enter"]))
                              }, null, 8, ["modelValue"]),
                              _createVNode(_component_q_btn, {
                                icon: "search",
                                color: "primary",
                                dense: "",
                                class: "q-ml-sm",
                                style: {"width":"40px"},
                                onClick: _cache[12] || (_cache[12] = ($event: any) => (loadListPollLoad(true)))
                              }),
                              _createVNode(_component_q_select, {
                                class: "q-ml-sm",
                                filled: "",
                                dense: "",
                                label: "Статус",
                                modelValue: voteStatus.value,
                                "onUpdate:modelValue": [
                                  _cache[13] || (_cache[13] = ($event: any) => ((voteStatus).value = $event)),
                                  _cache[14] || (_cache[14] = ($event: any) => (loadListPollLoad(true)))
                                ],
                                options: statusOptions.value,
                                style: {"max-width":"130px","width":"100%"}
                              }, null, 8, ["modelValue", "options"]),
                              _createVNode(_component_q_select, {
                                class: "q-ml-sm",
                                filled: "",
                                dense: "",
                                label: "Тип",
                                modelValue: voteType.value,
                                "onUpdate:modelValue": [
                                  _cache[15] || (_cache[15] = ($event: any) => ((voteType).value = $event)),
                                  _cache[16] || (_cache[16] = ($event: any) => (loadListPollLoad(true)))
                                ],
                                options: typeOptions.value,
                                style: {"max-width":"170px","width":"100%"}
                              }, null, 8, ["modelValue", "options"])
                            ]),
                            _createElementVNode("div", _hoisted_116, [
                              _createVNode(_component_q_table, {
                                class: "sticky-header-table-votes",
                                columns: voteCols.value,
                                rows: voteRows.value,
                                onRequest: loadPollsTableRequest,
                                "rows-per-page-options": [10, 25, 50, 100],
                                pagination: loadPollsPagination.value,
                                "onUpdate:pagination": _cache[17] || (_cache[17] = ($event: any) => ((loadPollsPagination).value = $event)),
                                "wrap-cells": "",
                                style: {"width":"100%","height":"540px"},
                                "table-style": "background: #3d5afe; color: white",
                                dense: "",
                                "binary-state-sort": "",
                                onRowClick: voteRowClick,
                                "row-key": "poll_id",
                                selected: selectedVote.value,
                                "onUpdate:selected": _cache[18] || (_cache[18] = ($event: any) => ((selectedVote).value = $event)),
                                selection: "single",
                                "hide-selected-banner": ""
                              }, {
                                "header-selection": _withCtx(() => _cache[63] || (_cache[63] = [])),
                                pagination: _withCtx((scope) => [
                                  _createTextVNode(_toDisplayString((scope.pagination.rowsPerPage * scope.pagination.page - scope.pagination.rowsPerPage + 1).toLocaleString()) + " - " + _toDisplayString(scope.pagination.rowsPerPage * scope.pagination.page > scope.pagination.rowsNumber
                        ? scope.pagination.rowsNumber.toLocaleString()
                        : (scope.pagination.rowsPerPage * scope.pagination.page).toLocaleString()) + " из " + _toDisplayString(scope.pagination.rowsNumber.toLocaleString()) + " ", 1),
                                  (scope.pagesNumber > 2)
                                    ? (_openBlock(), _createBlock(_component_q_btn, {
                                        key: 0,
                                        icon: "first_page",
                                        color: "white",
                                        round: "",
                                        dense: "",
                                        flat: "",
                                        disable: scope.isFirstPage,
                                        onClick: scope.firstPage,
                                        class: "q-ml-md"
                                      }, null, 8, ["disable", "onClick"]))
                                    : _createCommentVNode("", true),
                                  _createVNode(_component_q_btn, {
                                    icon: "chevron_left",
                                    color: "white",
                                    round: "",
                                    dense: "",
                                    flat: "",
                                    disable: scope.isFirstPage,
                                    onClick: scope.prevPage
                                  }, null, 8, ["disable", "onClick"]),
                                  _createVNode(_component_q_btn, {
                                    icon: "chevron_right",
                                    color: "white",
                                    round: "",
                                    dense: "",
                                    flat: "",
                                    disable: scope.isLastPage,
                                    onClick: scope.nextPage
                                  }, null, 8, ["disable", "onClick"]),
                                  (scope.pagesNumber > 2)
                                    ? (_openBlock(), _createBlock(_component_q_btn, {
                                        key: 1,
                                        icon: "last_page",
                                        color: "white",
                                        round: "",
                                        dense: "",
                                        flat: "",
                                        disable: scope.isLastPage,
                                        onClick: scope.lastPage
                                      }, null, 8, ["disable", "onClick"]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              }, 8, ["columns", "rows", "pagination", "selected"]),
                              _createVNode(_component_q_inner_loading, {
                                showing: isLoadPollsBusy.value,
                                style: {"z-index":"10"},
                                color: "white"
                              }, null, 8, ["showing"])
                            ]),
                            (selectedVote.value.length)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_117, " Распределение голосов по дням (Голосование \"" + _toDisplayString(selectedVote.value[0].title) + "\") ", 1))
                              : _createCommentVNode("", true),
                            (selectedVote.value.length)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_118, [
                                  _createVNode(_component_highcharts, {
                                    class: "hc full-width",
                                    options: chartOptions.value,
                                    ref: "chart",
                                    style: {"height":"336px"}
                                  }, null, 8, ["options"]),
                                  _createVNode(_component_q_inner_loading, {
                                    showing: isPollByDate.value,
                                    color: "grey"
                                  }, null, 8, ["showing"])
                                ]))
                              : _createCommentVNode("", true),
                            (selectedVote.value.length)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_119, " Участники голосования: \"" + _toDisplayString(selectedVote.value[0].title) + "\" ", 1))
                              : _createCommentVNode("", true),
                            (selectedVote.value.length)
                              ? (_openBlock(), _createBlock(_component_q_btn, {
                                  key: 3,
                                  label: "Открыть в ЦП",
                                  color: "primary",
                                  class: "q-ml-auto q-mt-sm row",
                                  onClick: getPersonalPolls,
                                  disable: isVoteUsers.value
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_tooltip, {
                                      anchor: "top middle",
                                      self: "bottom middle",
                                      offset: [10, 10]
                                    }, {
                                      default: _withCtx(() => _cache[64] || (_cache[64] = [
                                        _createElementVNode("p", {
                                          style: {"font-size":"16px"},
                                          class: "action-tooltip"
                                        }, "Будут переданы первые 300 000 пользователей", -1)
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["disable"]))
                              : _createCommentVNode("", true),
                            (selectedVote.value.length)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_120, [
                                  _createVNode(_component_q_table, {
                                    class: "sticky-header-table-vote-users",
                                    columns: userCols.value,
                                    rows: userRows.value,
                                    "rows-per-page-options": [10, 25, 50, 100],
                                    pagination: voteUsersPagination.value,
                                    "onUpdate:pagination": _cache[19] || (_cache[19] = ($event: any) => ((voteUsersPagination).value = $event)),
                                    onRequest: voteUsersRequest,
                                    "wrap-cells": "",
                                    style: {"width":"100%","height":"440px"},
                                    "table-style": "background: #3d5afe; color: white",
                                    dense: "",
                                    "binary-state-sort": "",
                                    "row-key": "sso_id"
                                  }, {
                                    pagination: _withCtx((scope) => [
                                      _createTextVNode(_toDisplayString((scope.pagination.rowsPerPage * scope.pagination.page - scope.pagination.rowsPerPage + 1).toLocaleString()) + " - " + _toDisplayString(scope.pagination.rowsPerPage * scope.pagination.page > scope.pagination.rowsNumber
                        ? scope.pagination.rowsNumber.toLocaleString()
                        : (scope.pagination.rowsPerPage * scope.pagination.page).toLocaleString()) + " из " + _toDisplayString(scope.pagination.rowsNumber.toLocaleString()) + " ", 1),
                                      (scope.pagesNumber > 2)
                                        ? (_openBlock(), _createBlock(_component_q_btn, {
                                            key: 0,
                                            icon: "first_page",
                                            color: "white",
                                            round: "",
                                            dense: "",
                                            flat: "",
                                            disable: scope.isFirstPage,
                                            onClick: scope.firstPage,
                                            class: "q-ml-md"
                                          }, null, 8, ["disable", "onClick"]))
                                        : _createCommentVNode("", true),
                                      _createVNode(_component_q_btn, {
                                        icon: "chevron_left",
                                        color: "white",
                                        round: "",
                                        dense: "",
                                        flat: "",
                                        disable: scope.isFirstPage,
                                        onClick: scope.prevPage
                                      }, null, 8, ["disable", "onClick"]),
                                      _createVNode(_component_q_btn, {
                                        icon: "chevron_right",
                                        color: "white",
                                        round: "",
                                        dense: "",
                                        flat: "",
                                        disable: scope.isLastPage,
                                        onClick: scope.nextPage
                                      }, null, 8, ["disable", "onClick"]),
                                      (scope.pagesNumber > 2)
                                        ? (_openBlock(), _createBlock(_component_q_btn, {
                                            key: 1,
                                            icon: "last_page",
                                            color: "white",
                                            round: "",
                                            dense: "",
                                            flat: "",
                                            disable: scope.isLastPage,
                                            onClick: scope.lastPage
                                          }, null, 8, ["disable", "onClick"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  }, 8, ["columns", "rows", "pagination"]),
                                  _createVNode(_component_q_inner_loading, {
                                    showing: isVoteUsers.value,
                                    style: {"z-index":"10"},
                                    color: "white"
                                  }, null, 8, ["showing"])
                                ]))
                              : _createCommentVNode("", true),
                            (selectedVote.value.length)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_121, " Вопросы голосования: \"" + _toDisplayString(selectedVote.value[0].title) + "\" ", 1))
                              : _createCommentVNode("", true),
                            (selectedVote.value.length)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_122, [
                                  _createVNode(_component_q_table, {
                                    class: "sticky-header-table-questions",
                                    rows: questionRows.value,
                                    columns: questionColumns.value,
                                    "rows-per-page-options": [10, 25, 50, 100],
                                    "wrap-cells": "",
                                    style: {"width":"100%","height":"440px"},
                                    "table-style": "background: #3d5afe; color: white",
                                    dense: "",
                                    "row-key": "question_id"
                                  }, {
                                    pagination: _withCtx((scope) => [
                                      _createTextVNode(_toDisplayString((scope.pagination.rowsPerPage * scope.pagination.page - scope.pagination.rowsPerPage + 1).toLocaleString()) + " - " + _toDisplayString(scope.pagination.rowsPerPage * scope.pagination.page > questionRows.value.length
                        ? questionRows.value.length.toLocaleString()
                        : (scope.pagination.rowsPerPage * scope.pagination.page).toLocaleString()) + " из " + _toDisplayString(questionRows.value.length.toLocaleString()) + " ", 1),
                                      (scope.pagesNumber > 2)
                                        ? (_openBlock(), _createBlock(_component_q_btn, {
                                            key: 0,
                                            icon: "first_page",
                                            color: "white",
                                            round: "",
                                            dense: "",
                                            flat: "",
                                            disable: scope.isFirstPage,
                                            onClick: scope.firstPage,
                                            class: "q-ml-md"
                                          }, null, 8, ["disable", "onClick"]))
                                        : _createCommentVNode("", true),
                                      _createVNode(_component_q_btn, {
                                        icon: "chevron_left",
                                        color: "white",
                                        round: "",
                                        dense: "",
                                        flat: "",
                                        disable: scope.isFirstPage,
                                        onClick: scope.prevPage
                                      }, null, 8, ["disable", "onClick"]),
                                      _createVNode(_component_q_btn, {
                                        icon: "chevron_right",
                                        color: "white",
                                        round: "",
                                        dense: "",
                                        flat: "",
                                        disable: scope.isLastPage,
                                        onClick: scope.nextPage
                                      }, null, 8, ["disable", "onClick"]),
                                      (scope.pagesNumber > 2)
                                        ? (_openBlock(), _createBlock(_component_q_btn, {
                                            key: 1,
                                            icon: "last_page",
                                            color: "white",
                                            round: "",
                                            dense: "",
                                            flat: "",
                                            disable: scope.isLastPage,
                                            onClick: scope.lastPage
                                          }, null, 8, ["disable", "onClick"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    header: _withCtx((props) => [
                                      _createVNode(_component_q_tr, { props: props }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_th, { "auto-width": "" }),
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                                            return (_openBlock(), _createBlock(_component_q_th, {
                                              key: col.name,
                                              props: props
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(col.label), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["props"]))
                                          }), 128))
                                        ]),
                                        _: 2
                                      }, 1032, ["props"])
                                    ]),
                                    body: _withCtx((props) => [
                                      _createVNode(_component_q_tr, { props: props }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_td, { "auto-width": "" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_q_btn, {
                                                size: "sm",
                                                flat: "",
                                                round: "",
                                                dense: "",
                                                onClick: ($event: any) => (props.expand = !props.expand),
                                                icon: 
                                props.expand
                                  ? 'fas fa-caret-down'
                                  : 'fas fa-caret-right'
                              
                                              }, null, 8, ["onClick", "icon"])
                                            ]),
                                            _: 2
                                          }, 1024),
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                                            return (_openBlock(), _createBlock(_component_q_td, {
                                              key: col.name,
                                              props: props
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(col.value), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["props"]))
                                          }), 128))
                                        ]),
                                        _: 2
                                      }, 1032, ["props"]),
                                      _withDirectives(_createVNode(_component_q_tr, { props: props }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_td, { colspan: "100%" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_q_table, {
                                                class: "sticky-header-table-questions",
                                                dense: "",
                                                columns: answerColumns.value,
                                                rows: props.row.answers_list,
                                                "table-style": "background: #3d5afe; color: white",
                                                style: {"max-height":"440px"},
                                                "binary-state-sort": "",
                                                "wrap-cells": ""
                                              }, {
                                                pagination: _withCtx((scope) => [
                                                  _createTextVNode(_toDisplayString((scope.pagination.rowsPerPage * scope.pagination.page - scope.pagination.rowsPerPage + 1).toLocaleString()) + " - " + _toDisplayString(scope.pagination.rowsPerPage * scope.pagination.page > props.row.answers_list.length
                                ? props.row.answers_list.length.toLocaleString()
                                : (scope.pagination.rowsPerPage * scope.pagination.page).toLocaleString()) + " из " + _toDisplayString(props.row.answers_list.length.toLocaleString()) + " ", 1),
                                                  (scope.pagesNumber > 2)
                                                    ? (_openBlock(), _createBlock(_component_q_btn, {
                                                        key: 0,
                                                        icon: "first_page",
                                                        color: "white",
                                                        round: "",
                                                        dense: "",
                                                        flat: "",
                                                        disable: scope.isFirstPage,
                                                        onClick: scope.firstPage,
                                                        class: "q-ml-md"
                                                      }, null, 8, ["disable", "onClick"]))
                                                    : _createCommentVNode("", true),
                                                  _createVNode(_component_q_btn, {
                                                    icon: "chevron_left",
                                                    color: "white",
                                                    round: "",
                                                    dense: "",
                                                    flat: "",
                                                    disable: scope.isFirstPage,
                                                    onClick: scope.prevPage
                                                  }, null, 8, ["disable", "onClick"]),
                                                  _createVNode(_component_q_btn, {
                                                    icon: "chevron_right",
                                                    color: "white",
                                                    round: "",
                                                    dense: "",
                                                    flat: "",
                                                    disable: scope.isLastPage,
                                                    onClick: scope.nextPage
                                                  }, null, 8, ["disable", "onClick"]),
                                                  (scope.pagesNumber > 2)
                                                    ? (_openBlock(), _createBlock(_component_q_btn, {
                                                        key: 1,
                                                        icon: "last_page",
                                                        color: "white",
                                                        round: "",
                                                        dense: "",
                                                        flat: "",
                                                        disable: scope.isLastPage,
                                                        onClick: scope.lastPage
                                                      }, null, 8, ["disable", "onClick"]))
                                                    : _createCommentVNode("", true)
                                                ]),
                                                "body-cell-votes_count": _withCtx((count_props) => [
                                                  _createVNode(_component_q_td, {
                                                    props: count_props,
                                                    class: "cursor-pointer",
                                                    onClick: ($event: any) => {currentAnswer.value = count_props.row; currentQuestionName.value = props.row.question_title; getAnswerAccounts()}
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(count_props.row.votes_count.toLocaleString()), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["props", "onClick"])
                                                ]),
                                                _: 2
                                              }, 1032, ["columns", "rows"])
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1032, ["props"]), [
                                        [_vShow, props.expand]
                                      ])
                                    ]),
                                    _: 1
                                  }, 8, ["rows", "columns"]),
                                  _createVNode(_component_q_inner_loading, {
                                    showing: questionLoader.value,
                                    style: {"z-index":"10"},
                                    color: "white"
                                  }, null, 8, ["showing"])
                                ]))
                              : _createCommentVNode("", true),
                            (currentAnswer.value)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_123, " Участники ответа \"" + _toDisplayString(currentAnswer.value.answer_title) + "\" на вопрос \"" + _toDisplayString(currentQuestionName.value) + "\" голосования \"" + _toDisplayString(selectedVote.value[0].title) + "\" ", 1))
                              : _createCommentVNode("", true),
                            (currentAnswer.value)
                              ? (_openBlock(), _createBlock(_component_q_btn, {
                                  key: 8,
                                  label: "Открыть в ЦП",
                                  color: "primary",
                                  class: "q-ml-auto q-mt-sm row",
                                  onClick: getPersonalPolls,
                                  disable: isVoteUsers.value
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_tooltip, {
                                      anchor: "top middle",
                                      self: "bottom middle",
                                      offset: [10, 10]
                                    }, {
                                      default: _withCtx(() => _cache[65] || (_cache[65] = [
                                        _createElementVNode("p", {
                                          style: {"font-size":"16px"},
                                          class: "action-tooltip"
                                        }, "Будут переданы первые 300 000 пользователей", -1)
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["disable"]))
                              : _createCommentVNode("", true),
                            (currentAnswer.value)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_124, [
                                  _createVNode(_component_q_table, {
                                    class: "sticky-header-table-questions",
                                    rows: answerUsersRows.value,
                                    columns: answerUsersCols.value,
                                    "rows-per-page-options": [10, 25, 50, 100],
                                    "wrap-cells": "",
                                    style: {"width":"100%","height":"440px"},
                                    "table-style": "background: #3d5afe; color: white",
                                    dense: "",
                                    "row-key": "sso_id",
                                    onRequest: onAnswerUsersRequest,
                                    pagination: answerUsersPagination.value,
                                    "onUpdate:pagination": _cache[20] || (_cache[20] = ($event: any) => ((answerUsersPagination).value = $event))
                                  }, {
                                    pagination: _withCtx((scope) => [
                                      _createTextVNode(_toDisplayString((scope.pagination.rowsPerPage * scope.pagination.page - scope.pagination.rowsPerPage + 1).toLocaleString()) + " - " + _toDisplayString(scope.pagination.rowsPerPage * scope.pagination.page > scope.pagination.rowsNumber
                        ? scope.pagination.rowsNumber.toLocaleString()
                        : (scope.pagination.rowsPerPage * scope.pagination.page).toLocaleString()) + " из " + _toDisplayString(scope.pagination.rowsNumber.toLocaleString()) + " ", 1),
                                      (scope.pagesNumber > 2)
                                        ? (_openBlock(), _createBlock(_component_q_btn, {
                                            key: 0,
                                            icon: "first_page",
                                            color: "white",
                                            round: "",
                                            dense: "",
                                            flat: "",
                                            disable: scope.isFirstPage,
                                            onClick: scope.firstPage,
                                            class: "q-ml-md"
                                          }, null, 8, ["disable", "onClick"]))
                                        : _createCommentVNode("", true),
                                      _createVNode(_component_q_btn, {
                                        icon: "chevron_left",
                                        color: "white",
                                        round: "",
                                        dense: "",
                                        flat: "",
                                        disable: scope.isFirstPage,
                                        onClick: scope.prevPage
                                      }, null, 8, ["disable", "onClick"]),
                                      _createVNode(_component_q_btn, {
                                        icon: "chevron_right",
                                        color: "white",
                                        round: "",
                                        dense: "",
                                        flat: "",
                                        disable: scope.isLastPage,
                                        onClick: scope.nextPage
                                      }, null, 8, ["disable", "onClick"]),
                                      (scope.pagesNumber > 2)
                                        ? (_openBlock(), _createBlock(_component_q_btn, {
                                            key: 1,
                                            icon: "last_page",
                                            color: "white",
                                            round: "",
                                            dense: "",
                                            flat: "",
                                            disable: scope.isLastPage,
                                            onClick: scope.lastPage
                                          }, null, 8, ["disable", "onClick"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  }, 8, ["rows", "columns", "pagination"]),
                                  _createVNode(_component_q_inner_loading, {
                                    showing: answerUsersLoader.value,
                                    style: {"z-index":"10"},
                                    color: "white"
                                  }, null, 8, ["showing"])
                                ]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["filename"]))
                : _createCommentVNode("", true),
              (currentProject.value.name === 'gi')
                ? (_openBlock(), _createBlock(Vue3Html2pdf, {
                    key: 1,
                    "show-layout": false,
                    "float-layout": false,
                    "enable-download": true,
                    "preview-modal": false,
                    "paginate-elements-by-height": 1070,
                    filename: 
              'НИГМА. Отчет по проекту Город идей за период с ' +
              dateFromAfterLoad.value.split('-').reverse().join('.') +
              ' по ' +
              dateToAfterLoad.value.split('-').reverse().join('.')
            ,
                    "pdf-quality": 3,
                    "manual-pagination": false,
                    "pdf-format": "a4",
                    "pdf-orientation": "portrait",
                    "pdf-content-width": "836px",
                    ref_key: "html2pdf",
                    ref: html2pdf,
                    class: "project-page"
                  }, {
                    "pdf-content": _withCtx(() => [
                      (Object.keys(reportList.value).length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_125, [
                            _cache[89] || (_cache[89] = _createElementVNode("div", { class: "text-weight-bold text-h5" }, "Основные показатели", -1)),
                            _cache[90] || (_cache[90] = _createElementVNode("div", { class: "text-h6 q-mt-sm" }, "Пользователи", -1)),
                            _createElementVNode("div", _hoisted_126, [
                              _createElementVNode("div", _hoisted_127, [
                                _createElementVNode("div", _hoisted_128, [
                                  _cache[70] || (_cache[70] = _createElementVNode("div", { class: "text-subtitle2" }, " Всего пользователей проекта ", -1)),
                                  _createElementVNode("div", _hoisted_129, _toDisplayString(reportList.value[
                            "Всего пользователей проекта"
                          ].toLocaleString()), 1),
                                  _createElementVNode("div", _hoisted_130, _toDisplayString(reportList.value["Новых регистраций"] > 0 ? "+" : "") + _toDisplayString(reportList.value["Новых регистраций"].toLocaleString()), 1),
                                  _cache[71] || (_cache[71] = _createElementVNode("div", {
                                    class: "q-mt-sm",
                                    style: {"font-size":"12px"}
                                  }, " По типу УЗ ", -1)),
                                  _createElementVNode("div", _hoisted_131, [
                                    _createElementVNode("div", _hoisted_132, [
                                      _cache[67] || (_cache[67] = _createElementVNode("span", { class: "custom-text" }, "Полная", -1)),
                                      _createElementVNode("span", _hoisted_133, _toDisplayString(reportList.value["Полная"].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_134, _toDisplayString(reportList.value["Полная за период"] > 0 ? "+" : "") + _toDisplayString(reportList.value["Полная за период"].toLocaleString()), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_135, [
                                      _cache[68] || (_cache[68] = _createElementVNode("span", { class: "custom-text" }, "Стандартная", -1)),
                                      _createElementVNode("span", _hoisted_136, _toDisplayString(reportList.value["Стандартная"].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_137, _toDisplayString(reportList.value["Стандартная за период"] > 0
                                ? "+"
                                : "") + _toDisplayString(reportList.value[
                                "Стандартная за период"
                              ].toLocaleString()), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_138, [
                                      _cache[69] || (_cache[69] = _createElementVNode("span", { class: "custom-text" }, "Упрощенная", -1)),
                                      _createElementVNode("span", _hoisted_139, _toDisplayString(reportList.value["Упрощенная"].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_140, _toDisplayString(reportList.value["Упрощенная за период"] > 0 ? "+" : "") + _toDisplayString(reportList.value[
                                "Упрощенная за период"
                              ].toLocaleString()), 1)
                                    ])
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_141, [
                                  _createElementVNode("div", _hoisted_142, [
                                    _createElementVNode("div", _hoisted_143, [
                                      _cache[72] || (_cache[72] = _createElementVNode("div", {
                                        class: "custom-text",
                                        style: {"white-space":"nowrap"}
                                      }, " Новых регистраций ", -1)),
                                      _createElementVNode("div", _hoisted_144, _toDisplayString(reportList.value["Новых регистраций"].toLocaleString()), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_145, [
                                      _cache[73] || (_cache[73] = _createElementVNode("div", {
                                        class: "custom-text",
                                        style: {"white-space":"nowrap"}
                                      }, " Начислено баллов ", -1)),
                                      _createElementVNode("div", _hoisted_146, _toDisplayString(reportList.value["Начислено баллов"].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_147, _toDisplayString(reportList.value["Начислено баллов за период"] > 0
                                ? "+"
                                : "") + _toDisplayString(reportList.value[
                                "Начислено баллов за период"
                              ].toLocaleString()), 1)
                                    ])
                                  ]),
                                  _createElementVNode("div", _hoisted_148, [
                                    _createElementVNode("div", _hoisted_149, [
                                      _cache[74] || (_cache[74] = _createElementVNode("div", {
                                        class: "custom-text",
                                        style: {"white-space":"nowrap"}
                                      }, " Всего посещений сайта ", -1)),
                                      _createElementVNode("div", _hoisted_150, _toDisplayString(reportList.value[
                                "Всего посещений сайта"
                              ].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_151, _toDisplayString(reportList.value["Посещений сайта за период"] > 0
                                ? "+"
                                : "") + _toDisplayString(reportList.value[
                                "Посещений сайта за период"
                              ].toLocaleString()), 1)
                                    ]),
                                    _cache[75] || (_cache[75] = _createElementVNode("div", { class: "gi-users__right-item_card gi-block q-pa-sm no-border" }, null, -1))
                                  ]),
                                  _createElementVNode("div", _hoisted_152, [
                                    _createElementVNode("div", _hoisted_153, [
                                      _cache[76] || (_cache[76] = _createElementVNode("div", {
                                        class: "custom-text",
                                        style: {"white-space":"nowrap"}
                                      }, " Уник. посетителей сайта ", -1)),
                                      _createElementVNode("div", _hoisted_154, _toDisplayString(reportList.value[
                                "Уник. посетителей сайта"
                              ].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_155, _toDisplayString(reportList.value["Уник. посетителей сайта за период"] >
                              0
                                ? "+"
                                : "") + _toDisplayString(reportList.value[
                                "Уник. посетителей сайта за период"
                              ].toLocaleString()), 1)
                                    ]),
                                    _cache[77] || (_cache[77] = _createElementVNode("div", { class: "gi-users__right-item_card gi-block q-pa-sm no-border" }, null, -1))
                                  ])
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_156, [
                              _createElementVNode("div", _hoisted_157, [
                                _cache[81] || (_cache[81] = _createElementVNode("div", { class: "text-h6" }, "Идеи 24/7", -1)),
                                _createElementVNode("div", _hoisted_158, [
                                  _createElementVNode("div", _hoisted_159, [
                                    _cache[78] || (_cache[78] = _createElementVNode("div", { class: "custom-text" }, "Подано идей 24/7", -1)),
                                    _createElementVNode("div", _hoisted_160, _toDisplayString(reportList.value["Подано идей 24/7"].toLocaleString()), 1),
                                    _createElementVNode("div", _hoisted_161, _toDisplayString(reportList.value["Подано идей 24/7 за период"] > 0
                              ? "+"
                              : "") + _toDisplayString(reportList.value[
                              "Подано идей 24/7 за период"
                            ].toLocaleString()), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_162, [
                                    _cache[79] || (_cache[79] = _createElementVNode("div", { class: "custom-text" }, " Прошло модерацию идей 24/7 ", -1)),
                                    _createElementVNode("div", _hoisted_163, _toDisplayString(reportList.value[
                              "Прошло модерацию идей 24/7"
                            ].toLocaleString()), 1),
                                    _createElementVNode("div", _hoisted_164, _toDisplayString(reportList.value["Прошло модерацию идей 24/7 за период"] >
                            0
                              ? "+"
                              : "") + _toDisplayString(reportList.value[
                              "Прошло модерацию идей 24/7 за период"
                            ].toLocaleString()), 1)
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_165, [
                                  _cache[80] || (_cache[80] = _createElementVNode("div", { class: "custom-text" }, "Рассмотрено ОИВ идей 24/7", -1)),
                                  _createElementVNode("div", _hoisted_166, _toDisplayString(reportList.value[
                            "Рассмотрено ОИВ идей 24/7"
                          ].toLocaleString()), 1),
                                  _createElementVNode("div", _hoisted_167, _toDisplayString(reportList.value["Рассмотрено ОИВ идей 24/7"] > 0
                            ? "+"
                            : "") + _toDisplayString(reportList.value[
                            "Рассмотрено ОИВ идей 24/7"
                          ].toLocaleString()), 1)
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_168, [
                                _cache[84] || (_cache[84] = _createElementVNode("div", { class: "text-h6" }, "Фото идей", -1)),
                                _createElementVNode("div", _hoisted_169, [
                                  _createElementVNode("div", _hoisted_170, [
                                    _cache[82] || (_cache[82] = _createElementVNode("div", { class: "custom-text" }, "Загружено фото идей", -1)),
                                    _createElementVNode("div", _hoisted_171, _toDisplayString(reportList.value["Загружено фото идей"].toLocaleString()), 1),
                                    _createElementVNode("div", _hoisted_172, _toDisplayString(reportList.value["Загружено фото идей за период"] > 0
                              ? "+"
                              : "") + _toDisplayString(reportList.value[
                              "Загружено фото идей за период"
                            ].toLocaleString()), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_173, [
                                    _cache[83] || (_cache[83] = _createElementVNode("div", { class: "custom-text" }, "Опубликовано фото идей", -1)),
                                    _createElementVNode("div", _hoisted_174, _toDisplayString(reportList.value[
                              "Опубликовано фото идей"
                            ].toLocaleString()), 1),
                                    _createElementVNode("div", _hoisted_175, _toDisplayString(reportList.value["Опубликовано фото идей за период"] > 0
                              ? "+"
                              : "") + _toDisplayString(reportList.value[
                              "Опубликовано фото идей за период"
                            ].toLocaleString()), 1)
                                  ])
                                ])
                              ])
                            ]),
                            _cache[91] || (_cache[91] = _createElementVNode("div", { class: "text-h6 q-mt-md" }, " Активности текущего тематического проекта ", -1)),
                            _createElementVNode("div", _hoisted_176, [
                              _createElementVNode("div", _hoisted_177, [
                                _cache[85] || (_cache[85] = _createElementVNode("div", { class: "custom-text" }, "Участников проекта", -1)),
                                _createElementVNode("div", _hoisted_178, _toDisplayString(reportList.value["Участников проекта"].toLocaleString()), 1)
                              ]),
                              _createElementVNode("div", _hoisted_179, [
                                _cache[86] || (_cache[86] = _createElementVNode("div", { class: "custom-text" }, "Отправлено проектных идей", -1)),
                                _createElementVNode("div", _hoisted_180, _toDisplayString(reportList.value["Отправлено проектных идей"].toLocaleString()), 1)
                              ]),
                              _createElementVNode("div", _hoisted_181, [
                                _cache[87] || (_cache[87] = _createElementVNode("div", { class: "custom-text" }, " Идей, прошедших на голосование ", -1)),
                                _createElementVNode("div", _hoisted_182, _toDisplayString(reportList.value[
                          "Идей, прошедших на голосование"
                        ].toLocaleString()), 1)
                              ]),
                              _createElementVNode("div", _hoisted_183, [
                                _cache[88] || (_cache[88] = _createElementVNode("div", { class: "custom-text" }, "Оставлено комментариев", -1)),
                                _createElementVNode("div", _hoisted_184, _toDisplayString(reportList.value["Оставлено комментариев"].toLocaleString()), 1)
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (Object.keys(reportList.value).length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_185, [
                            _cache[98] || (_cache[98] = _createElementVNode("div", { class: "text-weight-bold text-h5" }, [
                              _createTextVNode(" Аномалии "),
                              _createElementVNode("span", { class: "text-weight-light text-subtitle1" }, "(отклонения от среднего за последние 3 периода)")
                            ], -1)),
                            _createElementVNode("div", _hoisted_186, [
                              _createElementVNode("div", _hoisted_187, [
                                _cache[92] || (_cache[92] = _createElementVNode("div", { class: "custom-text" }, "Отклонение по регистрациям", -1)),
                                _createElementVNode("div", {
                                  class: _normalizeClass([
                        reportList.value['Отклонение по регистрациям'] > 0
                          ? 'text-green'
                          : 'text-red'
                      , "custom-title"])
                                }, _toDisplayString(reportList.value["Отклонение по регистрациям"] > 0 ? "+" : "") + _toDisplayString(reportList.value[
                          "Отклонение по регистрациям"
                        ].toLocaleString()), 3),
                                _createElementVNode("div", _hoisted_188, _toDisplayString(reportList.value["Отклонение по регистрациям %"].toFixed(1)) + "% (проц. от среднего) ", 1)
                              ]),
                              _createElementVNode("div", _hoisted_189, [
                                _cache[93] || (_cache[93] = _createElementVNode("div", { class: "custom-text" }, "Отклонение по баллам", -1)),
                                _createElementVNode("div", {
                                  class: _normalizeClass([
                        reportList.value['Отклонение по баллам'] > 0
                          ? 'text-green'
                          : 'text-red'
                      , "custom-title"])
                                }, _toDisplayString(reportList.value["Отклонение по баллам"] > 0 ? "+" : "") + _toDisplayString(reportList.value["Отклонение по баллам"].toLocaleString()), 3),
                                _createElementVNode("div", _hoisted_190, _toDisplayString(reportList.value["Отклонение по баллам %"].toFixed(1)) + "% (проц. от среднего) ", 1)
                              ]),
                              _createElementVNode("div", _hoisted_191, [
                                _cache[94] || (_cache[94] = _createElementVNode("div", { class: "custom-text" }, "Отклонение по посещению сайта", -1)),
                                _createElementVNode("div", {
                                  class: _normalizeClass([
                        reportList.value['Отклонение по посещению сайта'] > 0
                          ? 'text-green'
                          : 'text-red'
                      , "custom-title"])
                                }, _toDisplayString(reportList.value["Отклонение по посещению сайта"] > 0
                          ? "+"
                          : "") + _toDisplayString(reportList.value[
                          "Отклонение по посещению сайта"
                        ].toLocaleString()), 3),
                                _createElementVNode("div", _hoisted_192, _toDisplayString(reportList.value["Отклонение по посещению сайта %"].toFixed(
                          1
                        )) + "% (проц. от среднего) ", 1)
                              ]),
                              _createElementVNode("div", _hoisted_193, [
                                _cache[95] || (_cache[95] = _createElementVNode("div", { class: "custom-text" }, " Отклонение по кол-ву идей 24/7 ", -1)),
                                _createElementVNode("div", {
                                  class: _normalizeClass([
                        reportList.value['Отклонение по кол-ву идей 24/7'] > 0
                          ? 'text-green'
                          : 'text-red'
                      , "custom-title"])
                                }, _toDisplayString(reportList.value["Отклонение по кол-ву идей 24/7"] > 0
                          ? "+"
                          : "") + _toDisplayString(reportList.value[
                          "Отклонение по кол-ву идей 24/7"
                        ].toLocaleString()), 3),
                                _createElementVNode("div", _hoisted_194, _toDisplayString(reportList.value["Отклонение по кол-ву идей 24/7 %"].toFixed(
                          1
                        )) + "% (проц. от среднего) ", 1)
                              ]),
                              _createElementVNode("div", _hoisted_195, [
                                _cache[96] || (_cache[96] = _createElementVNode("div", { class: "custom-text" }, " Отклонение по кол-ву фото идей ", -1)),
                                _createElementVNode("div", {
                                  class: _normalizeClass([
                        reportList.value['Отклонение по кол-ву фото идей 24/7'] > 0
                          ? 'text-green'
                          : 'text-red'
                      , "custom-title"])
                                }, _toDisplayString(reportList.value["Отклонение по кол-ву фото идей 24/7"] > 0
                          ? "+"
                          : "") + _toDisplayString(reportList.value[
                          "Отклонение по кол-ву фото идей 24/7"
                        ].toLocaleString()), 3),
                                _createElementVNode("div", _hoisted_196, _toDisplayString(reportList.value[
                          "Отклонение по кол-ву фото идей 24/7 %"
                        ].toFixed(1)) + "% (проц. от среднего) ", 1)
                              ]),
                              _createElementVNode("div", _hoisted_197, [
                                _cache[97] || (_cache[97] = _createElementVNode("div", { class: "custom-text" }, " Отклонение по кол-ву проектных идей текущего тематического проекта ", -1)),
                                _createElementVNode("div", {
                                  class: _normalizeClass([
                        reportList.value[
                          'Отклонение по кол-ву проектных идей текущего тематического проекта'
                        ] > 0
                          ? 'text-green'
                          : 'text-red'
                      , "custom-title"])
                                }, _toDisplayString(reportList.value[
                          "Отклонение по кол-ву проектных идей текущего тематического проекта"
                        ] > 0
                          ? "+"
                          : "") + _toDisplayString(reportList.value[
                          "Отклонение по кол-ву проектных идей текущего тематического проекта"
                        ].toLocaleString()), 3),
                                _createElementVNode("div", _hoisted_198, _toDisplayString(reportList.value[
                          "Отклонение по кол-ву проектных идей текущего тематического проекта %"
                        ].toFixed(1)) + "% (проц. от среднего) ", 1)
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["filename"]))
                : _createCommentVNode("", true),
              (currentProject.value.name === 'gz')
                ? (_openBlock(), _createBlock(Vue3Html2pdf, {
                    key: 2,
                    "show-layout": false,
                    "float-layout": false,
                    "enable-download": true,
                    "preview-modal": false,
                    "paginate-elements-by-height": 1070,
                    filename: 'НИГМА. Отчет по проекту Город заданий за период с ' + dateFromAfterLoad.value.split('-').reverse().join('.') + ' по ' + dateToAfterLoad.value.split('-').reverse().join('.'),
                    "pdf-quality": 3,
                    "manual-pagination": false,
                    "pdf-format": "a4",
                    "pdf-orientation": "portrait",
                    "pdf-content-width": "760px",
                    ref_key: "html2pdf",
                    ref: html2pdf,
                    class: "project-page"
                  }, {
                    "pdf-content": _withCtx(() => [
                      (Object.keys(reportList.value).length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_199, " НИГМА. Отчет по проекту “Город заданий” за период с " + _toDisplayString(dateFromAfterLoad.value.split('-').reverse().join('.')) + " по " + _toDisplayString(dateToAfterLoad.value.split('-').reverse().join('.')), 1))
                        : _createCommentVNode("", true),
                      (Object.keys(reportList.value).length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_200, [
                            _cache[124] || (_cache[124] = _createElementVNode("div", { class: "text-weight-bold text-h5" }, "Основные показатели", -1)),
                            _cache[125] || (_cache[125] = _createElementVNode("div", { class: "text-h6 q-mt-sm" }, "Пользователи", -1)),
                            _createElementVNode("div", _hoisted_201, [
                              _createElementVNode("div", _hoisted_202, [
                                _createElementVNode("div", _hoisted_203, [
                                  _cache[102] || (_cache[102] = _createElementVNode("div", { class: "text-subtitle2" }, "Пользователей за все время", -1)),
                                  _createElementVNode("div", _hoisted_204, _toDisplayString(reportList.value['Пользователей за все время'].toLocaleString()), 1),
                                  _createElementVNode("div", _hoisted_205, _toDisplayString(reportList.value['Пользователей за период'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Пользователей за период'].toLocaleString()), 1),
                                  _cache[103] || (_cache[103] = _createElementVNode("div", {
                                    class: "q-mt-sm",
                                    style: {"font-size":"12px"}
                                  }, "По типу УЗ", -1)),
                                  _createElementVNode("div", _hoisted_206, [
                                    _createElementVNode("div", _hoisted_207, [
                                      _cache[99] || (_cache[99] = _createElementVNode("span", { class: "custom-text" }, "Полная", -1)),
                                      _createElementVNode("span", _hoisted_208, _toDisplayString(reportList.value['Полная за все время'].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_209, _toDisplayString(reportList.value['Полная за период'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Полная за период'].toLocaleString()), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_210, [
                                      _cache[100] || (_cache[100] = _createElementVNode("span", { class: "custom-text" }, "Стандартная", -1)),
                                      _createElementVNode("span", _hoisted_211, _toDisplayString(reportList.value['Стандартная за все время'].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_212, _toDisplayString(reportList.value['Стандартная за период'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Стандартная за период'].toLocaleString()), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_213, [
                                      _cache[101] || (_cache[101] = _createElementVNode("span", { class: "custom-text" }, "Упрощенная", -1)),
                                      _createElementVNode("span", _hoisted_214, _toDisplayString(reportList.value['Упрощенная за все время'].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_215, _toDisplayString(reportList.value['Упрощенная за период'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Упрощенная за период'].toLocaleString()), 1)
                                    ])
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_216, [
                                  _cache[107] || (_cache[107] = _createElementVNode("div", { class: "text-subtitle2" }, "Пользователей с начала года", -1)),
                                  _createElementVNode("div", _hoisted_217, _toDisplayString(reportList.value['Пользователей с начала года'].toLocaleString()), 1),
                                  _cache[108] || (_cache[108] = _createElementVNode("div", {
                                    class: "q-mt-auto",
                                    style: {"font-size":"12px"}
                                  }, "По типу УЗ", -1)),
                                  _createElementVNode("div", _hoisted_218, [
                                    _createElementVNode("div", _hoisted_219, [
                                      _cache[104] || (_cache[104] = _createElementVNode("span", { class: "custom-text" }, "Полная", -1)),
                                      _createElementVNode("span", _hoisted_220, _toDisplayString(reportList.value['Полная с начала года'].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_221, _toDisplayString(reportList.value['Полная за период'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Полная за период'].toLocaleString()), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_222, [
                                      _cache[105] || (_cache[105] = _createElementVNode("span", { class: "custom-text" }, "Стандартная", -1)),
                                      _createElementVNode("span", _hoisted_223, _toDisplayString(reportList.value['Стандартная с начала года'].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_224, _toDisplayString(reportList.value['Стандартная за период'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Стандартная за период'].toLocaleString()), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_225, [
                                      _cache[106] || (_cache[106] = _createElementVNode("span", { class: "custom-text" }, "Упрощенная", -1)),
                                      _createElementVNode("span", _hoisted_226, _toDisplayString(reportList.value['Упрощенная с начала года'].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_227, _toDisplayString(reportList.value['Упрощенная за период'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Упрощенная за период'].toLocaleString()), 1)
                                    ])
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_228, [
                                  _cache[112] || (_cache[112] = _createElementVNode("div", { class: "text-subtitle2" }, "Пользователей за период", -1)),
                                  _createElementVNode("div", _hoisted_229, _toDisplayString(reportList.value['Пользователей за период'].toLocaleString()), 1),
                                  _cache[113] || (_cache[113] = _createElementVNode("div", {
                                    class: "q-mt-auto",
                                    style: {"font-size":"12px"}
                                  }, "По типу УЗ", -1)),
                                  _createElementVNode("div", _hoisted_230, [
                                    _createElementVNode("div", _hoisted_231, [
                                      _cache[109] || (_cache[109] = _createElementVNode("span", { class: "custom-text" }, "Полная", -1)),
                                      _createElementVNode("span", _hoisted_232, _toDisplayString(reportList.value['Полная за период'].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_233, _toDisplayString(reportList.value['Полная за период'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Полная за период'].toLocaleString()), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_234, [
                                      _cache[110] || (_cache[110] = _createElementVNode("span", { class: "custom-text" }, "Стандартная", -1)),
                                      _createElementVNode("span", _hoisted_235, _toDisplayString(reportList.value['Стандартная за период'].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_236, _toDisplayString(reportList.value['Стандартная за период'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Стандартная за период'].toLocaleString()), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_237, [
                                      _cache[111] || (_cache[111] = _createElementVNode("span", { class: "custom-text" }, "Упрощенная", -1)),
                                      _createElementVNode("span", _hoisted_238, _toDisplayString(reportList.value['Упрощенная за период'].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_239, _toDisplayString(reportList.value['Упрощенная за период'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Упрощенная за период'].toLocaleString()), 1)
                                    ])
                                  ])
                                ])
                              ])
                            ]),
                            _cache[126] || (_cache[126] = _createElementVNode("div", { class: "text-h6 q-mt-md" }, "Задания", -1)),
                            _createElementVNode("div", _hoisted_240, [
                              _createElementVNode("div", _hoisted_241, [
                                _cache[114] || (_cache[114] = _createElementVNode("div", { class: "custom-text" }, "Успешных выполнений за все время", -1)),
                                _createElementVNode("div", _hoisted_242, _toDisplayString(reportList.value['Успешных выполнений за все время'].toLocaleString()), 1)
                              ]),
                              _createElementVNode("div", _hoisted_243, [
                                _cache[115] || (_cache[115] = _createElementVNode("div", { class: "custom-text" }, "Успешных выполнений с начала года", -1)),
                                _createElementVNode("div", _hoisted_244, _toDisplayString(reportList.value['Успешных выполнений с начала года'].toLocaleString()), 1)
                              ]),
                              _createElementVNode("div", _hoisted_245, [
                                _cache[116] || (_cache[116] = _createElementVNode("div", { class: "custom-text" }, "Успешных выполнений за период", -1)),
                                _createElementVNode("div", _hoisted_246, _toDisplayString(reportList.value['Успешных выполнений за период'].toLocaleString()), 1)
                              ])
                            ]),
                            _cache[127] || (_cache[127] = _createElementVNode("div", { class: "text-h6 q-mt-md" }, "Баллы", -1)),
                            _createElementVNode("div", _hoisted_247, [
                              _createElementVNode("div", _hoisted_248, [
                                _cache[117] || (_cache[117] = _createElementVNode("div", { class: "custom-text" }, "Начисленных баллов за все время", -1)),
                                _createElementVNode("div", _hoisted_249, _toDisplayString(reportList.value['Начисленных баллов за все время'].toLocaleString()), 1)
                              ]),
                              _createElementVNode("div", _hoisted_250, [
                                _cache[118] || (_cache[118] = _createElementVNode("div", { class: "custom-text" }, "Начисленных баллов с начала года", -1)),
                                _createElementVNode("div", _hoisted_251, _toDisplayString(reportList.value['Начисленных баллов с начала года'].toLocaleString()), 1)
                              ]),
                              _createElementVNode("div", _hoisted_252, [
                                _cache[119] || (_cache[119] = _createElementVNode("div", { class: "custom-text" }, "Начисленных баллов за период", -1)),
                                _createElementVNode("div", _hoisted_253, _toDisplayString(reportList.value['Начисленных баллов за период'].toLocaleString()), 1)
                              ])
                            ]),
                            _cache[128] || (_cache[128] = _createElementVNode("div", { class: "text-h6 q-mt-md" }, "Посещения", -1)),
                            _createElementVNode("div", _hoisted_254, [
                              _createElementVNode("div", _hoisted_255, [
                                _cache[120] || (_cache[120] = _createElementVNode("div", { class: "custom-text" }, "Всего посещений сайта", -1)),
                                _createElementVNode("div", _hoisted_256, _toDisplayString(reportList.value['Всего посещений сайта'].toLocaleString()), 1),
                                _createElementVNode("div", _hoisted_257, _toDisplayString(reportList.value['Посещений сайта за период'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Посещений сайта за период'].toLocaleString()), 1)
                              ]),
                              _createElementVNode("div", _hoisted_258, [
                                _cache[121] || (_cache[121] = _createElementVNode("div", { class: "custom-text" }, "Уник. посетителей сайта", -1)),
                                _createElementVNode("div", _hoisted_259, _toDisplayString(reportList.value['Уник. посетителей сайта'].toLocaleString()), 1),
                                _createElementVNode("div", _hoisted_260, _toDisplayString(reportList.value['Уник. посетителей сайта за период'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Уник. посетителей сайта за период'].toLocaleString()), 1)
                              ]),
                              _createElementVNode("div", _hoisted_261, [
                                _cache[122] || (_cache[122] = _createElementVNode("div", { class: "custom-text" }, "Всего посещений приложения", -1)),
                                _createElementVNode("div", _hoisted_262, _toDisplayString(reportList.value['Всего посещений приложения'].toLocaleString()), 1),
                                _createElementVNode("div", _hoisted_263, _toDisplayString(reportList.value['Посещений приложения за период'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Посещений приложения за период'].toLocaleString()), 1)
                              ]),
                              _createElementVNode("div", _hoisted_264, [
                                _cache[123] || (_cache[123] = _createElementVNode("div", { class: "custom-text" }, "Уник. посетителей приложения", -1)),
                                _createElementVNode("div", _hoisted_265, _toDisplayString(reportList.value['Уник. посетителей прил.'].toLocaleString()), 1),
                                _createElementVNode("div", _hoisted_266, _toDisplayString(reportList.value['Уник. посетителей прил. за период'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Уник. посетителей прил. за период'].toLocaleString()), 1)
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (Object.keys(reportList.value).length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_267, [
                            _cache[133] || (_cache[133] = _createElementVNode("div", { class: "text-weight-bold text-h5" }, [
                              _createTextVNode("Аномалии "),
                              _createElementVNode("span", { class: "text-weight-light text-subtitle1" }, "(отклонения от среднего за последние 3 периода)")
                            ], -1)),
                            _createElementVNode("div", _hoisted_268, [
                              _createElementVNode("div", _hoisted_269, [
                                _cache[129] || (_cache[129] = _createElementVNode("div", { class: "custom-text" }, "Отклонение по регистрациям", -1)),
                                _createElementVNode("div", {
                                  class: _normalizeClass([reportList.value['Отклонение по регистрациям'] > 0 ? 'text-green' : 'text-red', "custom-title"])
                                }, _toDisplayString(reportList.value['Отклонение по регистрациям'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Отклонение по регистрациям'].toLocaleString()), 3),
                                _createElementVNode("div", _hoisted_270, _toDisplayString(reportList.value['Отклонение по регистрациям %'].toFixed(1)) + "% (проц. от среднего)", 1)
                              ]),
                              _createElementVNode("div", _hoisted_271, [
                                _cache[130] || (_cache[130] = _createElementVNode("div", { class: "custom-text" }, "Отклонение по баллам", -1)),
                                _createElementVNode("div", {
                                  class: _normalizeClass([reportList.value['Отклонение по баллам'] > 0 ? 'text-green' : 'text-red', "custom-title"])
                                }, _toDisplayString(reportList.value['Отклонение по баллам'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Отклонение по баллам'].toLocaleString()), 3),
                                _createElementVNode("div", _hoisted_272, _toDisplayString(reportList.value['Отклонение по баллам %'].toFixed(1)) + "% (проц. от среднего)", 1)
                              ]),
                              _createElementVNode("div", _hoisted_273, [
                                _cache[131] || (_cache[131] = _createElementVNode("div", { class: "custom-text" }, "Отклонение по посещению сайта", -1)),
                                _createElementVNode("div", {
                                  class: _normalizeClass([reportList.value['Отклонение по посещению сайта'] > 0 ? 'text-green' : 'text-red', "custom-title"])
                                }, _toDisplayString(reportList.value['Отклонение по посещению сайта'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Отклонение по посещению сайта'].toLocaleString()), 3),
                                _createElementVNode("div", _hoisted_274, _toDisplayString(reportList.value['Отклонение по посещению сайта %'].toFixed(1)) + "% (проц. от среднего)", 1)
                              ]),
                              _createElementVNode("div", _hoisted_275, [
                                _cache[132] || (_cache[132] = _createElementVNode("div", { class: "custom-text" }, "Отклонение по посещению прил.", -1)),
                                _createElementVNode("div", {
                                  class: _normalizeClass([reportList.value['Отклонение по посещению прил.'] > 0 ? 'text-green' : 'text-red', "custom-title"])
                                }, _toDisplayString(reportList.value['Отклонение по посещению прил.'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Отклонение по посещению прил.'].toLocaleString()), 3),
                                _createElementVNode("div", _hoisted_276, _toDisplayString(reportList.value['Отклонение по посещению прил. %'].toFixed(1)) + "% (проц. от среднего)", 1)
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["filename"]))
                : _createCommentVNode("", true),
              (currentProject.value.name === 'ed')
                ? (_openBlock(), _createBlock(Vue3Html2pdf, {
                    key: 3,
                    "show-layout": false,
                    "float-layout": false,
                    "enable-download": true,
                    "preview-modal": false,
                    "paginate-elements-by-height": 1070,
                    filename: 'НИГМА. Отчет по проекту Электронный дом за период с ' + dateFromAfterLoad.value.split('-').reverse().join('.') + ' по ' + dateToAfterLoad.value.split('-').reverse().join('.'),
                    "pdf-quality": 3,
                    "manual-pagination": false,
                    "pdf-format": "a4",
                    "pdf-orientation": "portrait",
                    "pdf-content-width": "760px",
                    ref_key: "html2pdf",
                    ref: html2pdf,
                    class: "project-page"
                  }, {
                    "pdf-content": _withCtx(() => [
                      (Object.keys(reportList.value).length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_277, " НИГМА. Отчет по проекту “Электронный дом” за период с " + _toDisplayString(dateFromAfterLoad.value.split('-').reverse().join('.')) + " по " + _toDisplayString(dateToAfterLoad.value.split('-').reverse().join('.')), 1))
                        : _createCommentVNode("", true),
                      (Object.keys(reportList.value).length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_278, [
                            _cache[148] || (_cache[148] = _createElementVNode("div", { class: "text-weight-bold text-h5" }, "Основные показатели", -1)),
                            _cache[149] || (_cache[149] = _createElementVNode("div", {
                              class: "text-h6 q-mt-sm",
                              style: {"width":"667px"}
                            }, "Пользователи", -1)),
                            _createElementVNode("div", _hoisted_279, [
                              _createElementVNode("div", _hoisted_280, [
                                _createElementVNode("div", _hoisted_281, [
                                  _cache[134] || (_cache[134] = _createElementVNode("div", { class: "text-subtitle2" }, "Всего пользователей проекта", -1)),
                                  _createElementVNode("div", _hoisted_282, _toDisplayString(reportList.value['Всего пользователей проекта'].toLocaleString()), 1),
                                  _createElementVNode("div", _hoisted_283, _toDisplayString(reportList.value['Новых регистраций (за период)'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Новых регистраций (за период)'].toLocaleString()), 1),
                                  _cache[135] || (_cache[135] = _createElementVNode("div", {
                                    class: "q-mt-sm",
                                    style: {"font-size":"12px"}
                                  }, "По типу УЗ", -1)),
                                  _cache[136] || (_cache[136] = _createElementVNode("div", {
                                    class: "row no-wrap",
                                    style: {"gap":"10px"}
                                  }, [
                                    _createElementVNode("div", { class: "column items-start" }, [
                                      _createElementVNode("span", { class: "custom-text" }, "Полная"),
                                      _createElementVNode("span", { class: "custom-title" }, "Нет данных")
                                    ]),
                                    _createElementVNode("div", { class: "column items-start" }, [
                                      _createElementVNode("span", { class: "custom-text" }, "Стандартная"),
                                      _createElementVNode("span", { class: "custom-title" }, "Нет данных")
                                    ]),
                                    _createElementVNode("div", { class: "column items-start" }, [
                                      _createElementVNode("span", { class: "custom-text" }, "Упрощенная"),
                                      _createElementVNode("span", { class: "custom-title" }, "Нет данных")
                                    ])
                                  ], -1))
                                ]),
                                _createElementVNode("div", _hoisted_284, [
                                  _createElementVNode("div", _hoisted_285, [
                                    _createElementVNode("div", _hoisted_286, [
                                      _cache[137] || (_cache[137] = _createElementVNode("div", {
                                        class: "custom-text",
                                        style: {"white-space":"nowrap"}
                                      }, "Новых регистраций", -1)),
                                      _createElementVNode("div", _hoisted_287, _toDisplayString(reportList.value['Новых регистраций (за период)'].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_288, _toDisplayString(reportList.value['Новых регистраций в сравнении с предыдущим периодом'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Новых регистраций в сравнении с предыдущим периодом'].toLocaleString()), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_289, [
                                      _cache[138] || (_cache[138] = _createElementVNode("div", {
                                        class: "custom-text",
                                        style: {"white-space":"nowrap"}
                                      }, "Начислено баллов", -1)),
                                      _createElementVNode("div", _hoisted_290, _toDisplayString(reportList.value['Начислено баллов (за период)'].toLocaleString()), 1),
                                      _createElementVNode("div", _hoisted_291, _toDisplayString(reportList.value['Начислено баллов в сравнении с предыдущим периодом'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Начислено баллов в сравнении с предыдущим периодом'].toLocaleString()), 1)
                                    ])
                                  ])
                                ])
                              ])
                            ]),
                            _cache[150] || (_cache[150] = _createElementVNode("div", { class: "text-h6 q-mt-md" }, "Общие собрания собственников", -1)),
                            _createElementVNode("div", _hoisted_292, [
                              _cache[140] || (_cache[140] = _createElementVNode("div", { class: "ed-block q-pa-sm full-width" }, [
                                _createElementVNode("div", { class: "custom-text" }, "Завершено"),
                                _createElementVNode("div", { class: "custom-title" }, "Нет данных")
                              ], -1)),
                              _cache[141] || (_cache[141] = _createElementVNode("div", { class: "ed-block q-pa-sm full-width" }, [
                                _createElementVNode("div", { class: "custom-text" }, "Активно"),
                                _createElementVNode("div", { class: "custom-title" }, "Нет данных")
                              ], -1)),
                              _createElementVNode("div", _hoisted_293, [
                                _cache[139] || (_cache[139] = _createElementVNode("div", { class: "custom-text" }, "Поступило голосов", -1)),
                                _createElementVNode("div", _hoisted_294, _toDisplayString(reportList.value['Общие собрания собственников, поступило голосов (за период)'].toLocaleString()), 1),
                                _createElementVNode("div", _hoisted_295, _toDisplayString(reportList.value['Общие собрания собственников, поступило голосов в сравнении с предыдущим периодом'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Общие собрания собственников, поступило голосов в сравнении с предыдущим периодом'].toLocaleString()), 1)
                              ])
                            ]),
                            _cache[151] || (_cache[151] = _createElementVNode("div", { class: "text-h6 q-mt-md" }, "Опросы", -1)),
                            _createElementVNode("div", _hoisted_296, [
                              _cache[143] || (_cache[143] = _createElementVNode("div", { class: "ed-block q-pa-sm full-width" }, [
                                _createElementVNode("div", { class: "custom-text" }, "Завершено"),
                                _createElementVNode("div", { class: "custom-title" }, "Нет данных")
                              ], -1)),
                              _cache[144] || (_cache[144] = _createElementVNode("div", { class: "ed-block q-pa-sm full-width" }, [
                                _createElementVNode("div", { class: "custom-text" }, "Активно"),
                                _createElementVNode("div", { class: "custom-title" }, "Нет данных")
                              ], -1)),
                              _createElementVNode("div", _hoisted_297, [
                                _cache[142] || (_cache[142] = _createElementVNode("div", { class: "custom-text" }, "Поступило голосов", -1)),
                                _createElementVNode("div", _hoisted_298, _toDisplayString(reportList.value['Опросы, поступило голосов (за период)'].toLocaleString()), 1),
                                _createElementVNode("div", _hoisted_299, _toDisplayString(reportList.value['Опросы, поступило голосов в сравнении с предыдущим периодом'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Опросы, поступило голосов в сравнении с предыдущим периодом'].toLocaleString()), 1)
                              ])
                            ]),
                            _cache[152] || (_cache[152] = _createElementVNode("div", { class: "text-h6 q-mt-md" }, "Заявки", -1)),
                            _createElementVNode("div", _hoisted_300, [
                              _createElementVNode("div", _hoisted_301, [
                                _cache[145] || (_cache[145] = _createElementVNode("div", { class: "custom-text" }, "Подано всего", -1)),
                                _createElementVNode("div", _hoisted_302, _toDisplayString(reportList.value['Заявки (за период)']), 1),
                                _createElementVNode("div", _hoisted_303, _toDisplayString(reportList.value['Заявки в сравнении с предыдущим периодом'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Заявки в сравнении с предыдущим периодом'].toLocaleString()), 1)
                              ]),
                              _cache[146] || (_cache[146] = _createElementVNode("div", { class: "ed-block q-pa-sm full-width" }, [
                                _createElementVNode("div", { class: "custom-text" }, "Из них в Наш город"),
                                _createElementVNode("div", { class: "custom-title" }, "Нет данных")
                              ], -1)),
                              _cache[147] || (_cache[147] = _createElementVNode("div", { class: "ed-block q-pa-sm full-width" }, [
                                _createElementVNode("div", { class: "custom-text" }, "Из них в ЕДЦ"),
                                _createElementVNode("div", { class: "custom-title" }, "Нет данных")
                              ], -1))
                            ]),
                            _cache[153] || (_cache[153] = _createElementVNode("div", {
                              class: "row no-wrap items-start q-mt-md",
                              style: {"gap":"16px","max-width":"667px"}
                            }, [
                              _createElementVNode("div", { class: "ed-block q-pa-sm full-width" }, [
                                _createElementVNode("div", { class: "custom-text" }, "Топ 5 тем за все время"),
                                _createElementVNode("div", { class: "custom-title" }, "Нет данных")
                              ]),
                              _createElementVNode("div", { class: "ed-block q-pa-sm full-width" }, [
                                _createElementVNode("div", { class: "custom-text" }, "Топ 5 тем за неделю"),
                                _createElementVNode("div", { class: "custom-title" }, "Нет данных")
                              ])
                            ], -1)),
                            _cache[154] || (_cache[154] = _createElementVNode("div", {
                              class: "row no-wrap items-start q-mt-md",
                              style: {"gap":"16px","max-width":"667px"}
                            }, [
                              _createElementVNode("div", { class: "ed-block q-pa-sm full-width" }, [
                                _createElementVNode("div", { class: "custom-text" }, "Топ 5 домов по количеству заявок за все время"),
                                _createElementVNode("div", { class: "custom-title" }, "Нет данных")
                              ]),
                              _createElementVNode("div", { class: "ed-block q-pa-sm full-width" }, [
                                _createElementVNode("div", { class: "custom-text" }, "Топ 5 домов по количеству заявок за неделю"),
                                _createElementVNode("div", { class: "custom-title" }, "Нет данных")
                              ])
                            ], -1))
                          ]))
                        : _createCommentVNode("", true),
                      (Object.keys(reportList.value).length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_304, [
                            _cache[158] || (_cache[158] = _createElementVNode("div", { class: "text-h6 q-mt-md" }, "Обращения в УО", -1)),
                            _cache[159] || (_cache[159] = _createElementVNode("div", {
                              class: "row no-wrap items-start",
                              style: {"gap":"5px","max-width":"667px"}
                            }, [
                              _createElementVNode("div", { class: "ed-block q-pa-sm full-width" }, [
                                _createElementVNode("div", { class: "custom-text" }, "Подано обращений"),
                                _createElementVNode("div", { class: "custom-title" }, "Нет данных")
                              ])
                            ], -1)),
                            _cache[160] || (_cache[160] = _createElementVNode("div", { class: "text-h6 q-mt-md" }, "Общедомовые чаты", -1)),
                            _createElementVNode("div", _hoisted_305, [
                              _cache[156] || (_cache[156] = _createElementVNode("div", { class: "ed-block q-pa-sm full-width" }, [
                                _createElementVNode("div", { class: "custom-text" }, "Количество домов с чатами"),
                                _createElementVNode("div", { class: "custom-title" }, "Нет данных")
                              ], -1)),
                              _createElementVNode("div", _hoisted_306, [
                                _cache[155] || (_cache[155] = _createElementVNode("div", { class: "custom-text" }, "Поступило сообщений", -1)),
                                _createElementVNode("div", _hoisted_307, _toDisplayString(reportList.value['Поступило сообщений в общий чат (за период)']), 1),
                                _createElementVNode("div", _hoisted_308, _toDisplayString(reportList.value['Поступило сообщений в общий чат в сравнении с предыдущим периодом'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Поступило сообщений в общий чат в сравнении с предыдущим периодом'].toLocaleString()), 1)
                              ])
                            ]),
                            _cache[161] || (_cache[161] = _createElementVNode("div", { class: "text-h6 q-mt-md" }, "Объявления", -1)),
                            _createElementVNode("div", _hoisted_309, [
                              _createElementVNode("div", _hoisted_310, [
                                _cache[157] || (_cache[157] = _createElementVNode("div", { class: "custom-text" }, "Опубликовано объявлений", -1)),
                                _createElementVNode("div", _hoisted_311, _toDisplayString(reportList.value['Опубликовано объявлений (за период)']), 1),
                                _createElementVNode("div", _hoisted_312, _toDisplayString(reportList.value['Опубликовано объявлений в сравнении с предыдущим периодом'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Опубликовано объявлений в сравнении с предыдущим периодом'].toLocaleString()), 1)
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (Object.keys(reportList.value).length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_313, [
                            _cache[164] || (_cache[164] = _createElementVNode("div", { class: "text-weight-bold text-h5" }, [
                              _createTextVNode("Аномалии "),
                              _createElementVNode("span", { class: "text-weight-light text-subtitle1" }, "(отклонения от среднего за последние 3 периода)")
                            ], -1)),
                            _createElementVNode("div", _hoisted_314, [
                              _createElementVNode("div", _hoisted_315, [
                                _cache[162] || (_cache[162] = _createElementVNode("div", { class: "custom-text" }, "Отклонение по регистрациям", -1)),
                                _createElementVNode("div", {
                                  class: _normalizeClass([reportList.value['Отклонение по регистрациям'] > 0 ? 'text-green' : 'text-red', "custom-title"])
                                }, _toDisplayString(reportList.value['Отклонение по регистрациям'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Отклонение по регистрациям'].toLocaleString()), 3),
                                _createElementVNode("div", _hoisted_316, _toDisplayString(reportList.value['Отклонение по регистрациям %'].toFixed(1)) + "% (проц. от среднего)", 1)
                              ]),
                              _createElementVNode("div", _hoisted_317, [
                                _cache[163] || (_cache[163] = _createElementVNode("div", { class: "custom-text" }, "Отклонение по баллам", -1)),
                                _createElementVNode("div", {
                                  class: _normalizeClass([reportList.value['Отклонение по баллам'] > 0 ? 'text-green' : 'text-red', "custom-title"])
                                }, _toDisplayString(reportList.value['Отклонение по баллам'] > 0 ? '+' : '') + _toDisplayString(reportList.value['Отклонение по баллам'].toLocaleString()), 3),
                                _createElementVNode("div", _hoisted_318, _toDisplayString(reportList.value['Отклонение по баллам %'].toFixed(1)) + "% (проц. от среднего)", 1)
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["filename"]))
                : _createCommentVNode("", true),
              (!isReportBusy.value && currentProject.value.name === 'ag-child')
                ? (_openBlock(), _createBlock(AgChild, {
                    key: 4,
                    currentProject: currentProject.value,
                    dateFromAfterLoad: dateFromAfterLoad.value,
                    dateToAfterLoad: dateToAfterLoad.value,
                    reportList: reportList.value,
                    isExport: isExport.value,
                    exportRows: exportRows.value
                  }, null, 8, ["currentProject", "dateFromAfterLoad", "dateToAfterLoad", "reportList", "isExport", "exportRows"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_q_inner_loading, {
      showing: isReportBusy.value || isExport.value || personalLoader.value,
      label: "Подождите, форма загружается",
      "label-style": "font-size: 24px; font-weight: 700",
      style: {"z-index":"100"}
    }, null, 8, ["showing"])
  ]))
}
}

})