// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "ag_answer_class.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * Модель данных
 *
 * @generated from protobuf message proto.nigmamsg.AgAnswerClassModel
 */
export interface AgAnswerClassModel {
    /**
     * @generated from protobuf field: int64 class_id = 1;
     */
    classId: number;
    /**
     * @generated from protobuf field: int64 message_grp_id = 2;
     */
    messageGrpId: number;
    /**
     * @generated from protobuf field: int64 ag_answer_grp_id = 3;
     */
    agAnswerGrpId: number;
}
/**
 * Входящий запрос для создания элемента
 *
 * @generated from protobuf message proto.nigmamsg.CreateAgAnswerClassRequest
 */
export interface CreateAgAnswerClassRequest {
    /**
     * @generated from protobuf field: proto.nigmamsg.AgAnswerClassModel item = 1;
     */
    item?: AgAnswerClassModel;
}
/**
 * Ответ сервера для создания элемента
 *
 * @generated from protobuf message proto.nigmamsg.CreateAgAnswerClassResponse
 */
export interface CreateAgAnswerClassResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
}
/**
 * Входящий запрос для удаления элемента
 *
 * @generated from protobuf message proto.nigmamsg.DeleteAgAnswerClassRequest
 */
export interface DeleteAgAnswerClassRequest {
    /**
     * @generated from protobuf field: proto.nigmamsg.AgAnswerClassModel item = 1;
     */
    item?: AgAnswerClassModel;
}
/**
 * Ответ сервера для удаления элемента
 *
 * @generated from protobuf message proto.nigmamsg.DeleteAgAnswerClassResponse
 */
export interface DeleteAgAnswerClassResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
}
/**
 * Входящий запрос для сохранения элементов
 *
 * @generated from protobuf message proto.nigmamsg.SaveAgAnswerClassRequest
 */
export interface SaveAgAnswerClassRequest {
    /**
     * @generated from protobuf field: int64 class_id = 1;
     */
    classId: number;
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.AgAnswerClassModel items = 2;
     */
    items: AgAnswerClassModel[];
}
/**
 * Ответ сервера для сохранения элементов
 *
 * @generated from protobuf message proto.nigmamsg.SaveAgAnswerClassResponse
 */
export interface SaveAgAnswerClassResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
}
/**
 * Входящий запрос для удаления элементов
 *
 * @generated from protobuf message proto.nigmamsg.RemoveAgAnswerClassRequest
 */
export interface RemoveAgAnswerClassRequest {
    /**
     * @generated from protobuf field: int64 class_id = 1;
     */
    classId: number;
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.AgAnswerClassModel items = 2;
     */
    items: AgAnswerClassModel[];
}
/**
 * Ответ сервера для удаления элементаов
 *
 * @generated from protobuf message proto.nigmamsg.RemoveAgAnswerClassResponse
 */
export interface RemoveAgAnswerClassResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class AgAnswerClassModel$Type extends MessageType<AgAnswerClassModel> {
    constructor() {
        super("proto.nigmamsg.AgAnswerClassModel", [
            { no: 1, name: "class_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "message_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "ag_answer_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<AgAnswerClassModel>): AgAnswerClassModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classId = 0;
        message.messageGrpId = 0;
        message.agAnswerGrpId = 0;
        if (value !== undefined)
            reflectionMergePartial<AgAnswerClassModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgAnswerClassModel): AgAnswerClassModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_id */ 1:
                    message.classId = reader.int64().toNumber();
                    break;
                case /* int64 message_grp_id */ 2:
                    message.messageGrpId = reader.int64().toNumber();
                    break;
                case /* int64 ag_answer_grp_id */ 3:
                    message.agAnswerGrpId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgAnswerClassModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_id = 1; */
        if (message.classId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classId);
        /* int64 message_grp_id = 2; */
        if (message.messageGrpId !== 0)
            writer.tag(2, WireType.Varint).int64(message.messageGrpId);
        /* int64 ag_answer_grp_id = 3; */
        if (message.agAnswerGrpId !== 0)
            writer.tag(3, WireType.Varint).int64(message.agAnswerGrpId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.AgAnswerClassModel
 */
export const AgAnswerClassModel = new AgAnswerClassModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateAgAnswerClassRequest$Type extends MessageType<CreateAgAnswerClassRequest> {
    constructor() {
        super("proto.nigmamsg.CreateAgAnswerClassRequest", [
            { no: 1, name: "item", kind: "message", T: () => AgAnswerClassModel }
        ]);
    }
    create(value?: PartialMessage<CreateAgAnswerClassRequest>): CreateAgAnswerClassRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateAgAnswerClassRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateAgAnswerClassRequest): CreateAgAnswerClassRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.AgAnswerClassModel item */ 1:
                    message.item = AgAnswerClassModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateAgAnswerClassRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.AgAnswerClassModel item = 1; */
        if (message.item)
            AgAnswerClassModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.CreateAgAnswerClassRequest
 */
export const CreateAgAnswerClassRequest = new CreateAgAnswerClassRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateAgAnswerClassResponse$Type extends MessageType<CreateAgAnswerClassResponse> {
    constructor() {
        super("proto.nigmamsg.CreateAgAnswerClassResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CreateAgAnswerClassResponse>): CreateAgAnswerClassResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.success = false;
        if (value !== undefined)
            reflectionMergePartial<CreateAgAnswerClassResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateAgAnswerClassResponse): CreateAgAnswerClassResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateAgAnswerClassResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.CreateAgAnswerClassResponse
 */
export const CreateAgAnswerClassResponse = new CreateAgAnswerClassResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteAgAnswerClassRequest$Type extends MessageType<DeleteAgAnswerClassRequest> {
    constructor() {
        super("proto.nigmamsg.DeleteAgAnswerClassRequest", [
            { no: 1, name: "item", kind: "message", T: () => AgAnswerClassModel }
        ]);
    }
    create(value?: PartialMessage<DeleteAgAnswerClassRequest>): DeleteAgAnswerClassRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DeleteAgAnswerClassRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteAgAnswerClassRequest): DeleteAgAnswerClassRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.AgAnswerClassModel item */ 1:
                    message.item = AgAnswerClassModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteAgAnswerClassRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.AgAnswerClassModel item = 1; */
        if (message.item)
            AgAnswerClassModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.DeleteAgAnswerClassRequest
 */
export const DeleteAgAnswerClassRequest = new DeleteAgAnswerClassRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteAgAnswerClassResponse$Type extends MessageType<DeleteAgAnswerClassResponse> {
    constructor() {
        super("proto.nigmamsg.DeleteAgAnswerClassResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteAgAnswerClassResponse>): DeleteAgAnswerClassResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.success = false;
        if (value !== undefined)
            reflectionMergePartial<DeleteAgAnswerClassResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteAgAnswerClassResponse): DeleteAgAnswerClassResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteAgAnswerClassResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.DeleteAgAnswerClassResponse
 */
export const DeleteAgAnswerClassResponse = new DeleteAgAnswerClassResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveAgAnswerClassRequest$Type extends MessageType<SaveAgAnswerClassRequest> {
    constructor() {
        super("proto.nigmamsg.SaveAgAnswerClassRequest", [
            { no: 1, name: "class_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgAnswerClassModel }
        ]);
    }
    create(value?: PartialMessage<SaveAgAnswerClassRequest>): SaveAgAnswerClassRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classId = 0;
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<SaveAgAnswerClassRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveAgAnswerClassRequest): SaveAgAnswerClassRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_id */ 1:
                    message.classId = reader.int64().toNumber();
                    break;
                case /* repeated proto.nigmamsg.AgAnswerClassModel items */ 2:
                    message.items.push(AgAnswerClassModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveAgAnswerClassRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_id = 1; */
        if (message.classId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classId);
        /* repeated proto.nigmamsg.AgAnswerClassModel items = 2; */
        for (let i = 0; i < message.items.length; i++)
            AgAnswerClassModel.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.SaveAgAnswerClassRequest
 */
export const SaveAgAnswerClassRequest = new SaveAgAnswerClassRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveAgAnswerClassResponse$Type extends MessageType<SaveAgAnswerClassResponse> {
    constructor() {
        super("proto.nigmamsg.SaveAgAnswerClassResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SaveAgAnswerClassResponse>): SaveAgAnswerClassResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.success = false;
        if (value !== undefined)
            reflectionMergePartial<SaveAgAnswerClassResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveAgAnswerClassResponse): SaveAgAnswerClassResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveAgAnswerClassResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.SaveAgAnswerClassResponse
 */
export const SaveAgAnswerClassResponse = new SaveAgAnswerClassResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveAgAnswerClassRequest$Type extends MessageType<RemoveAgAnswerClassRequest> {
    constructor() {
        super("proto.nigmamsg.RemoveAgAnswerClassRequest", [
            { no: 1, name: "class_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgAnswerClassModel }
        ]);
    }
    create(value?: PartialMessage<RemoveAgAnswerClassRequest>): RemoveAgAnswerClassRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classId = 0;
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<RemoveAgAnswerClassRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveAgAnswerClassRequest): RemoveAgAnswerClassRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_id */ 1:
                    message.classId = reader.int64().toNumber();
                    break;
                case /* repeated proto.nigmamsg.AgAnswerClassModel items */ 2:
                    message.items.push(AgAnswerClassModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveAgAnswerClassRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_id = 1; */
        if (message.classId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classId);
        /* repeated proto.nigmamsg.AgAnswerClassModel items = 2; */
        for (let i = 0; i < message.items.length; i++)
            AgAnswerClassModel.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.RemoveAgAnswerClassRequest
 */
export const RemoveAgAnswerClassRequest = new RemoveAgAnswerClassRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveAgAnswerClassResponse$Type extends MessageType<RemoveAgAnswerClassResponse> {
    constructor() {
        super("proto.nigmamsg.RemoveAgAnswerClassResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<RemoveAgAnswerClassResponse>): RemoveAgAnswerClassResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.success = false;
        if (value !== undefined)
            reflectionMergePartial<RemoveAgAnswerClassResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveAgAnswerClassResponse): RemoveAgAnswerClassResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveAgAnswerClassResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.RemoveAgAnswerClassResponse
 */
export const RemoveAgAnswerClassResponse = new RemoveAgAnswerClassResponse$Type();
/**
 * @generated ServiceType for protobuf service proto.nigmamsg.AgAnswerClassService
 */
export const AgAnswerClassService = new ServiceType("proto.nigmamsg.AgAnswerClassService", [
    { name: "CreateAgAnswerClass", options: {}, I: CreateAgAnswerClassRequest, O: CreateAgAnswerClassResponse },
    { name: "DeleteAgAnswerClass", options: {}, I: DeleteAgAnswerClassRequest, O: DeleteAgAnswerClassResponse },
    { name: "SaveAgAnswerClass", options: {}, I: SaveAgAnswerClassRequest, O: SaveAgAnswerClassResponse },
    { name: "RemoveAgAnswerClass", options: {}, I: RemoveAgAnswerClassRequest, O: RemoveAgAnswerClassResponse }
]);
