import {MessageGrpServiceClient} from 'proto/nigma-message-proto/polls/message_grp.client'
import {useGrpcClientWithLogger} from "@/components/modules/rec-system/services/UserGroups/middleware";
import {
    ListMessageGrpChildsRequest,
    ListMessageGrpRequest,
    ListMessageGrpWithFindMessageRequest, ReduceMessageGrpRequest, SeparateMessageGrpRequest, SplitMessageGrpRequest,
    UpdateTextMessageGrpRequest,
} from "proto/nigma-message-proto/polls/message_grp";

export const useMessageGrpApi = () => {
    const client = useGrpcClientWithLogger().create(MessageGrpServiceClient, {baseUrl: window.VUE_APP_GATEWAY_URL})

    const metadata = () => {
        return {
            Authorization: localStorage.getItem('access-key') || ''
        }
    }

    return {
        listMessageGrpRequest: (request: ListMessageGrpRequest) => {
            return client.listMessageGrp(request, {meta: metadata()}).response
        },
        listMessageGrpWithFindMessageRequest: (request: ListMessageGrpWithFindMessageRequest) => {
            return client.listMessageGrpWithFindMessage(request, {meta: metadata()}).response
        },
        listMessageGrpChildsRequest: (request: ListMessageGrpChildsRequest) => {
            return client.listMessageGrpChilds(request, {meta: metadata()}).response
        },
        updateTextMessageGrpRequest: (request: UpdateTextMessageGrpRequest) => {
            return client.updateTextMessageGrp(request, {meta: metadata()}).response
        },
        reduceMessageGrpRequest: (request: ReduceMessageGrpRequest) => {
            return client.reduceMessageGrp(request, {meta: metadata()}).response
        },
        splitMessageGrpRequest: (request: SplitMessageGrpRequest) => {
            return client.splitMessageGrp(request, {meta: metadata()}).response
        },
        separateMessageGrpRequest: (request: SeparateMessageGrpRequest) => {
            return client.separateMessageGrp(request, {meta: metadata()}).response
        }
    }
}