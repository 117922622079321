import {useGrpcClientWithLogger} from "@/components/modules/rec-system/services/UserGroups/middleware";
import {ClassNotTagServiceClient} from "proto/nigma-message-proto/polls/class_not_tag.client";
import {
    ListClassNotTagRequest,
    ItemClassNotTagRequest,
    CreateClassNotTagRequest,
    UpdateClassNotTagRequest,
    DeleteClassNotTagRequest,
    SaveClassNotTagRequest,
} from "proto/nigma-message-proto/polls/class_not_tag"

export const useClassNotTagApi = () => {
    const client = useGrpcClientWithLogger().create(ClassNotTagServiceClient, {baseUrl: window.VUE_APP_GATEWAY_URL})

    const metadata = () => {
        return {
            Authorization: localStorage.getItem('access-key') || ''
        }
    }

    return {
        listClassNotTag: (request: ListClassNotTagRequest) => {
            return client.listClassNotTag(request, {meta: metadata()}).response
        },
        itemClassNotTag: (request: ItemClassNotTagRequest) => {
            return client.itemClassNotTag(request, {meta: metadata()}).response
        },
        createClassNotTag: (request: CreateClassNotTagRequest) => {
            return client.createClassNotTag(request, {meta: metadata()}).response
        },
        updateClassNotTag: (request: UpdateClassNotTagRequest) => {
            return client.updateClassNotTag(request, {meta: metadata()}).response
        },
        deleteClassNotTag: (request: DeleteClassNotTagRequest) => {
            return client.deleteClassNotTag(request, {meta: metadata()}).response
        },
        saveClassNotTag: (request: SaveClassNotTagRequest) => {
            return client.saveClassNotTag(request, {meta: metadata()}).response
        }
    }
}