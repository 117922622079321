// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "class_not_tag.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Pagination } from "./proto/nigma-common-proto/filter";
import { Sort } from "./proto/nigma-common-proto/filter";
import { Filter } from "./proto/nigma-common-proto/filter";
/**
 * Модель данных
 * Без префиксов для фильтров
 *
 * @generated from protobuf message proto.nigmamsg.ClassNotTagModel
 */
export interface ClassNotTagModel {
    /**
     * @generated from protobuf field: int64 class_id = 1;
     */
    classId: number;
    /**
     * @generated from protobuf field: string not_tag_name = 2;
     */
    notTagName: string;
    /**
     * @generated from protobuf field: string not_tag_descr = 3;
     */
    notTagDescr: string;
}
/**
 * Входящий запрос для получения списка данных
 *
 * @generated from protobuf message proto.nigmamsg.ListClassNotTagRequest
 */
export interface ListClassNotTagRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
}
/**
 * Ответ сервера для получения списка данных
 *
 * @generated from protobuf message proto.nigmamsg.ListClassNotTagResponse
 */
export interface ListClassNotTagResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.ClassNotTagModel items = 1;
     */
    items: ClassNotTagModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListClassNotTagRequest params = 2;
     */
    params?: ListClassNotTagRequest;
}
/**
 * Входящий запрос для получения элемента
 *
 * @generated from protobuf message proto.nigmamsg.ItemClassNotTagRequest
 */
export interface ItemClassNotTagRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
}
/**
 * Ответ сервера для получения элемента
 *
 * @generated from protobuf message proto.nigmamsg.ItemClassNotTagResponse
 */
export interface ItemClassNotTagResponse {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassNotTagModel item = 1;
     */
    item?: ClassNotTagModel;
}
/**
 * Входящий запрос для создания элемента
 *
 * @generated from protobuf message proto.nigmamsg.CreateClassNotTagRequest
 */
export interface CreateClassNotTagRequest {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassNotTagModel item = 1;
     */
    item?: ClassNotTagModel;
}
/**
 * Ответ сервера для создания элемента
 *
 * @generated from protobuf message proto.nigmamsg.CreateClassNotTagResponse
 */
export interface CreateClassNotTagResponse {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassNotTagModel item = 1;
     */
    item?: ClassNotTagModel;
}
/**
 * Входящий запрос для обновления элемента
 *
 * @generated from protobuf message proto.nigmamsg.UpdateClassNotTagRequest
 */
export interface UpdateClassNotTagRequest {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassNotTagModel item = 1;
     */
    item?: ClassNotTagModel;
}
/**
 * Ответ сервера для обновления элемента
 *
 * @generated from protobuf message proto.nigmamsg.UpdateClassNotTagResponse
 */
export interface UpdateClassNotTagResponse {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassNotTagModel item = 1;
     */
    item?: ClassNotTagModel;
}
/**
 * Входящий запрос для удаления элемента
 *
 * @generated from protobuf message proto.nigmamsg.DeleteClassNotTagRequest
 */
export interface DeleteClassNotTagRequest {
    /**
     * @generated from protobuf field: int64 class_id = 1;
     */
    classId: number;
    /**
     * @generated from protobuf field: string not_tag_name = 2;
     */
    notTagName: string;
}
/**
 * Ответ сервера для удаления элемента
 *
 * @generated from protobuf message proto.nigmamsg.DeleteClassNotTagResponse
 */
export interface DeleteClassNotTagResponse {
    /**
     * @generated from protobuf field: int64 class_id = 1;
     */
    classId: number;
    /**
     * @generated from protobuf field: string not_tag_name = 2;
     */
    notTagName: string;
}
/**
 * Входящий запрос для сохранения элементов
 *
 * @generated from protobuf message proto.nigmamsg.SaveClassNotTagRequest
 */
export interface SaveClassNotTagRequest {
    /**
     * @generated from protobuf field: int64 class_id = 1;
     */
    classId: number;
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.ClassNotTagModel items = 2;
     */
    items: ClassNotTagModel[];
}
/**
 * Ответ сервера для сохранения элементаов
 *
 * @generated from protobuf message proto.nigmamsg.SaveClassNotTagResponse
 */
export interface SaveClassNotTagResponse {
    /**
     * @generated from protobuf field: int64 class_id = 1;
     */
    classId: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class ClassNotTagModel$Type extends MessageType<ClassNotTagModel> {
    constructor() {
        super("proto.nigmamsg.ClassNotTagModel", [
            { no: 1, name: "class_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "not_tag_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "not_tag_descr", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ClassNotTagModel>): ClassNotTagModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classId = 0;
        message.notTagName = "";
        message.notTagDescr = "";
        if (value !== undefined)
            reflectionMergePartial<ClassNotTagModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClassNotTagModel): ClassNotTagModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_id */ 1:
                    message.classId = reader.int64().toNumber();
                    break;
                case /* string not_tag_name */ 2:
                    message.notTagName = reader.string();
                    break;
                case /* string not_tag_descr */ 3:
                    message.notTagDescr = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ClassNotTagModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_id = 1; */
        if (message.classId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classId);
        /* string not_tag_name = 2; */
        if (message.notTagName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.notTagName);
        /* string not_tag_descr = 3; */
        if (message.notTagDescr !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.notTagDescr);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ClassNotTagModel
 */
export const ClassNotTagModel = new ClassNotTagModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListClassNotTagRequest$Type extends MessageType<ListClassNotTagRequest> {
    constructor() {
        super("proto.nigmamsg.ListClassNotTagRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination }
        ]);
    }
    create(value?: PartialMessage<ListClassNotTagRequest>): ListClassNotTagRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ListClassNotTagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListClassNotTagRequest): ListClassNotTagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListClassNotTagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListClassNotTagRequest
 */
export const ListClassNotTagRequest = new ListClassNotTagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListClassNotTagResponse$Type extends MessageType<ListClassNotTagResponse> {
    constructor() {
        super("proto.nigmamsg.ListClassNotTagResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ClassNotTagModel },
            { no: 2, name: "params", kind: "message", T: () => ListClassNotTagRequest }
        ]);
    }
    create(value?: PartialMessage<ListClassNotTagResponse>): ListClassNotTagResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListClassNotTagResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListClassNotTagResponse): ListClassNotTagResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.ClassNotTagModel items */ 1:
                    message.items.push(ClassNotTagModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListClassNotTagRequest params */ 2:
                    message.params = ListClassNotTagRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListClassNotTagResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.ClassNotTagModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            ClassNotTagModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListClassNotTagRequest params = 2; */
        if (message.params)
            ListClassNotTagRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListClassNotTagResponse
 */
export const ListClassNotTagResponse = new ListClassNotTagResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemClassNotTagRequest$Type extends MessageType<ItemClassNotTagRequest> {
    constructor() {
        super("proto.nigmamsg.ItemClassNotTagRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter }
        ]);
    }
    create(value?: PartialMessage<ItemClassNotTagRequest>): ItemClassNotTagRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ItemClassNotTagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemClassNotTagRequest): ItemClassNotTagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ItemClassNotTagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ItemClassNotTagRequest
 */
export const ItemClassNotTagRequest = new ItemClassNotTagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemClassNotTagResponse$Type extends MessageType<ItemClassNotTagResponse> {
    constructor() {
        super("proto.nigmamsg.ItemClassNotTagResponse", [
            { no: 1, name: "item", kind: "message", T: () => ClassNotTagModel }
        ]);
    }
    create(value?: PartialMessage<ItemClassNotTagResponse>): ItemClassNotTagResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ItemClassNotTagResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemClassNotTagResponse): ItemClassNotTagResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassNotTagModel item */ 1:
                    message.item = ClassNotTagModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ItemClassNotTagResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassNotTagModel item = 1; */
        if (message.item)
            ClassNotTagModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ItemClassNotTagResponse
 */
export const ItemClassNotTagResponse = new ItemClassNotTagResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateClassNotTagRequest$Type extends MessageType<CreateClassNotTagRequest> {
    constructor() {
        super("proto.nigmamsg.CreateClassNotTagRequest", [
            { no: 1, name: "item", kind: "message", T: () => ClassNotTagModel }
        ]);
    }
    create(value?: PartialMessage<CreateClassNotTagRequest>): CreateClassNotTagRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateClassNotTagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateClassNotTagRequest): CreateClassNotTagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassNotTagModel item */ 1:
                    message.item = ClassNotTagModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateClassNotTagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassNotTagModel item = 1; */
        if (message.item)
            ClassNotTagModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.CreateClassNotTagRequest
 */
export const CreateClassNotTagRequest = new CreateClassNotTagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateClassNotTagResponse$Type extends MessageType<CreateClassNotTagResponse> {
    constructor() {
        super("proto.nigmamsg.CreateClassNotTagResponse", [
            { no: 1, name: "item", kind: "message", T: () => ClassNotTagModel }
        ]);
    }
    create(value?: PartialMessage<CreateClassNotTagResponse>): CreateClassNotTagResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateClassNotTagResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateClassNotTagResponse): CreateClassNotTagResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassNotTagModel item */ 1:
                    message.item = ClassNotTagModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateClassNotTagResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassNotTagModel item = 1; */
        if (message.item)
            ClassNotTagModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.CreateClassNotTagResponse
 */
export const CreateClassNotTagResponse = new CreateClassNotTagResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateClassNotTagRequest$Type extends MessageType<UpdateClassNotTagRequest> {
    constructor() {
        super("proto.nigmamsg.UpdateClassNotTagRequest", [
            { no: 1, name: "item", kind: "message", T: () => ClassNotTagModel }
        ]);
    }
    create(value?: PartialMessage<UpdateClassNotTagRequest>): UpdateClassNotTagRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateClassNotTagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateClassNotTagRequest): UpdateClassNotTagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassNotTagModel item */ 1:
                    message.item = ClassNotTagModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateClassNotTagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassNotTagModel item = 1; */
        if (message.item)
            ClassNotTagModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.UpdateClassNotTagRequest
 */
export const UpdateClassNotTagRequest = new UpdateClassNotTagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateClassNotTagResponse$Type extends MessageType<UpdateClassNotTagResponse> {
    constructor() {
        super("proto.nigmamsg.UpdateClassNotTagResponse", [
            { no: 1, name: "item", kind: "message", T: () => ClassNotTagModel }
        ]);
    }
    create(value?: PartialMessage<UpdateClassNotTagResponse>): UpdateClassNotTagResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateClassNotTagResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateClassNotTagResponse): UpdateClassNotTagResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassNotTagModel item */ 1:
                    message.item = ClassNotTagModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateClassNotTagResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassNotTagModel item = 1; */
        if (message.item)
            ClassNotTagModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.UpdateClassNotTagResponse
 */
export const UpdateClassNotTagResponse = new UpdateClassNotTagResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteClassNotTagRequest$Type extends MessageType<DeleteClassNotTagRequest> {
    constructor() {
        super("proto.nigmamsg.DeleteClassNotTagRequest", [
            { no: 1, name: "class_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "not_tag_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteClassNotTagRequest>): DeleteClassNotTagRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classId = 0;
        message.notTagName = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteClassNotTagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteClassNotTagRequest): DeleteClassNotTagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_id */ 1:
                    message.classId = reader.int64().toNumber();
                    break;
                case /* string not_tag_name */ 2:
                    message.notTagName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteClassNotTagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_id = 1; */
        if (message.classId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classId);
        /* string not_tag_name = 2; */
        if (message.notTagName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.notTagName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.DeleteClassNotTagRequest
 */
export const DeleteClassNotTagRequest = new DeleteClassNotTagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteClassNotTagResponse$Type extends MessageType<DeleteClassNotTagResponse> {
    constructor() {
        super("proto.nigmamsg.DeleteClassNotTagResponse", [
            { no: 1, name: "class_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "not_tag_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteClassNotTagResponse>): DeleteClassNotTagResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classId = 0;
        message.notTagName = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteClassNotTagResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteClassNotTagResponse): DeleteClassNotTagResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_id */ 1:
                    message.classId = reader.int64().toNumber();
                    break;
                case /* string not_tag_name */ 2:
                    message.notTagName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteClassNotTagResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_id = 1; */
        if (message.classId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classId);
        /* string not_tag_name = 2; */
        if (message.notTagName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.notTagName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.DeleteClassNotTagResponse
 */
export const DeleteClassNotTagResponse = new DeleteClassNotTagResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveClassNotTagRequest$Type extends MessageType<SaveClassNotTagRequest> {
    constructor() {
        super("proto.nigmamsg.SaveClassNotTagRequest", [
            { no: 1, name: "class_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ClassNotTagModel }
        ]);
    }
    create(value?: PartialMessage<SaveClassNotTagRequest>): SaveClassNotTagRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classId = 0;
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<SaveClassNotTagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveClassNotTagRequest): SaveClassNotTagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_id */ 1:
                    message.classId = reader.int64().toNumber();
                    break;
                case /* repeated proto.nigmamsg.ClassNotTagModel items */ 2:
                    message.items.push(ClassNotTagModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveClassNotTagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_id = 1; */
        if (message.classId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classId);
        /* repeated proto.nigmamsg.ClassNotTagModel items = 2; */
        for (let i = 0; i < message.items.length; i++)
            ClassNotTagModel.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.SaveClassNotTagRequest
 */
export const SaveClassNotTagRequest = new SaveClassNotTagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveClassNotTagResponse$Type extends MessageType<SaveClassNotTagResponse> {
    constructor() {
        super("proto.nigmamsg.SaveClassNotTagResponse", [
            { no: 1, name: "class_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SaveClassNotTagResponse>): SaveClassNotTagResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classId = 0;
        if (value !== undefined)
            reflectionMergePartial<SaveClassNotTagResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveClassNotTagResponse): SaveClassNotTagResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_id */ 1:
                    message.classId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveClassNotTagResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_id = 1; */
        if (message.classId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.SaveClassNotTagResponse
 */
export const SaveClassNotTagResponse = new SaveClassNotTagResponse$Type();
/**
 * @generated ServiceType for protobuf service proto.nigmamsg.ClassNotTagService
 */
export const ClassNotTagService = new ServiceType("proto.nigmamsg.ClassNotTagService", [
    { name: "ListClassNotTag", options: {}, I: ListClassNotTagRequest, O: ListClassNotTagResponse },
    { name: "ItemClassNotTag", options: {}, I: ItemClassNotTagRequest, O: ItemClassNotTagResponse },
    { name: "CreateClassNotTag", options: {}, I: CreateClassNotTagRequest, O: CreateClassNotTagResponse },
    { name: "UpdateClassNotTag", options: {}, I: UpdateClassNotTagRequest, O: UpdateClassNotTagResponse },
    { name: "DeleteClassNotTag", options: {}, I: DeleteClassNotTagRequest, O: DeleteClassNotTagResponse },
    { name: "SaveClassNotTag", options: {}, I: SaveClassNotTagRequest, O: SaveClassNotTagResponse }
]);
