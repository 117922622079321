import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "relative-position",
  style: {"width":"60%"}
}

import {ref, watch, computed} from "vue";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'CityEarnedChart',
  props: ['sortType', 'styleProps'],
  setup(__props) {

const props = __props

const chartOptions = ref({
  chart: {
    type: 'line',
    zoomType: 'x',
  },

  boost: {
    useGPUTranslations: true,
  },

  xAxis: {
    type: 'datetime',
  },
  yAxis: {
    min: 0,
    title: {
      text: ''
    }
  },

  plotOptions: {
    series: {
      innerSize: '90%',
      showInLegend: true,
      dataLabels: {
        enabled: true,
      }
    }
  },

  title: {
    text: ''
  },

  subtitle: {
    text: '',
    verticalAlign: 'middle',
    y: 30
  },

  series: [],
})

const isEarnedPointsChart = computed(() => store.getters.isEarnedPointsChart)
const getEarnedPoints = computed(() => {
  const items = store.getters.getEarnedPointsChart
  const filteredList = []
  let counter = 0

  if (items) {
    for (const i of items) {
      filteredList.push([])
      filteredList[counter] = { data: [] as any, name: i.getProject() }

      for (const j of i.getPointsList()) {
        let date = j.getDate().split('-')

        if (props.sortType === 0) {
          filteredList[counter].data.push([Date.UTC(date[0], date[1] - 1, date[2]), j.getValue()])
        }

        if (props.sortType === 2) {
          filteredList[counter].data.push([Date.UTC(date[0], date[1] - 1), j.getValue()])
        }

        if (props.sortType === 4) {
          filteredList[counter].data.push([Date.UTC(date[0]), j.getValue()])
        }
      }

      counter++
    }
  }

  return filteredList
})

watch(getEarnedPoints, (newValue) => {
  chartOptions.value.series = newValue
})

return (_ctx: any,_cache: any) => {
  const _component_highcharts = _resolveComponent("highcharts")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_highcharts, {
      class: "hc",
      options: chartOptions.value,
      ref: "chart",
      style: _normalizeStyle(`height: ${props.styleProps}px`)
    }, null, 8, ["options", "style"]),
    _createVNode(_component_q_inner_loading, {
      showing: isEarnedPointsChart.value,
      color: "grey"
    }, null, 8, ["showing"])
  ]))
}
}

})