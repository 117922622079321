import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "user-projects__input-dropdown"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 0,
  style: {"text-align":"center","margin":"4px 0"}
}
const _hoisted_4 = {
  key: 1,
  class: "text-red q-pa-sm"
}

import store from '@/store'
import { ref, watch, computed } from 'vue'
import { onClickOutside } from '@vueuse/core'


export default /*@__PURE__*/_defineComponent({
  __name: 'UsersSearchInput',
  props: ['labelValue'],
  emits: ['clear', 'requestTable'],
  setup(__props, { emit: __emit }) {

const emit = __emit
const props = __props

const filterInput = ref('')
const isInputInFocus = ref(false)
const rootDiv: any = ref()
const pageToLoad = ref(1)
const isThereMoreToBeLoaded = ref(false)

const getSearchAccountItems = computed(() => {
  const rawUsers = store.getters.getSearchAccountItems
  const filteredUsers = []
  for (const i in rawUsers) {
    if (filterInput.value) {
      if (matchedString(rawUsers[i].getSsoId()).includes('<b')) {
        filteredUsers.push({
          guid: rawUsers[i].getSsoId(),
          ssoid: matchedString(rawUsers[i].getSsoId()),
          guid_real: rawUsers[i].getAccountGuid(),
        })
      }
    }
  }

  return filteredUsers
})

const isSearchUsersBusy = computed(() => store.getters.isSearchUsersBusy)

onClickOutside(rootDiv, () => {
  isInputInFocus.value = false
})

watch(filterInput, async () => {
  if (!filterInput.value) {
    store.commit('setSearchAccountItems', [])
    store.commit('setAccountSearchString', '')
  } else {
    if (filterInput.value.length >= 26) {
      console.log('lenght more 26')
      await loadTableData({
        filter: [
          {
            field_name: 'a.sso_id',
            operand: '=',
            value: filterInput.value,
          },
        ],
      })
    } else {
      console.log('lenght less 26')

      await loadTableData({
        filter: [
          {
            field_name: 'a.sso_id',
            operand: 'ilike',
            value: filterInput.value,
          },
        ],
      })
    }
  }
})

const clearSearch = () => {
  store.commit('setAccountSearchString', '')
  store.commit('addPendingSearchItem', undefined)
  pageToLoad.value = 1
  emit('clear')
}

const loadTableData = async (filter: any) => {
  await store.dispatch('getSearchAccountItems', filter).then((items) => {
    items.length > 100
      ? (isThereMoreToBeLoaded.value = true)
      : (isThereMoreToBeLoaded.value = false)
  })
}

const loadUsersTable = (guid: string, guid_real: string) => {
  store.commit('setAccountSearchString', guid)
  emit('requestTable', guid, guid_real)
  // await store
  //   .dispatch('getAccountsItems', {
  //     filter: [
  //       {
  //         field_name: 'a.sso_id',
  //         operand: '=',
  //         value: guid,
  //       },
  //     ],
  //     sort: {
  //       name: 'a.sso_id',
  //       exp: 'desc',
  //     },
  //     pagination: {
  //       page: 1,
  //       limit: 7,
  //       pages: 0,
  //     },
  //   })
  //   .then(() => (isInputInFocus.value = false))

  isInputInFocus.value = false
}

const loadMore = () => {
  store
    .dispatch('loadMoreSearchAccountItems', {
      filter: [
        {
          field_name: 'a.sso_id',
          operand: 'ilike',
          value: filterInput.value,
        },
      ],
      pagination: {
        page: ++pageToLoad.value,
        limit: 101,
        pages: 0,
      },
    })
    .then((items) => {
      items.length > 100
        ? (isThereMoreToBeLoaded.value = true)
        : (isThereMoreToBeLoaded.value = false)
    })
}

const matchedString = (string: string) => {
  const regexp = filterInput.value.replaceAll('.', '\\.')
  return string.replace(
    new RegExp(regexp, 'gi'),
    (str) =>
      `<b style="background:rgba(193 ,0, 21 ,.3);padding: 0px 2px; border-radius: 4px; margin:0;">${str}</b>`
  )
}

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_virtual_scroll = _resolveComponent("q-virtual-scroll")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_scroll_area = _resolveComponent("q-scroll-area")!
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!

  return (_openBlock(), _createElementBlock("div", {
    class: "user-projects__input",
    ref_key: "rootDiv",
    ref: rootDiv
  }, [
    _createVNode(_component_q_input, {
      modelValue: filterInput.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filterInput).value = $event)),
      filled: "",
      debounce: "500",
      style: {"min-width":"400px"},
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (isInputInFocus.value = true)),
      onClear: clearSearch,
      clearable: "",
      maxlength: "40",
      label: props.labelValue
    }, {
      prepend: _withCtx(() => [
        _createVNode(_component_q_icon, { name: "search" })
      ]),
      _: 1
    }, 8, ["modelValue", "label"]),
    (
      (isInputInFocus.value && getSearchAccountItems.value && filterInput.value) ||
      (isInputInFocus.value && isSearchUsersBusy.value)
    )
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (getSearchAccountItems.value.length > 0)
            ? (_openBlock(), _createBlock(_component_q_scroll_area, {
                key: 0,
                id: "scroll-area-with-virtual-scroll-1",
                style: {"height":"200px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_virtual_scroll, {
                    "scroll-target": "#scroll-area-with-virtual-scroll-1 > .scroll",
                    items: getSearchAccountItems.value
                  }, {
                    default: _withCtx(({ item }) => [
                      _createVNode(_component_q_list, {
                        bordered: "",
                        separator: "",
                        dense: ""
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(), _createBlock(_component_q_item, {
                            key: item.guid,
                            clickable: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_item_section, {
                                onClick: ($event: any) => (loadUsersTable(item.guid, item.guid_real))
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", {
                                    innerHTML: item.ssoid
                                  }, null, 8, _hoisted_2)
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1024))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 1
                  }, 8, ["items"]),
                  (isThereMoreToBeLoaded.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_q_btn, {
                          label: "Загрузить еще",
                          color: "grey-4",
                          size: "10px",
                          onClick: loadMore
                        })
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, " По заданным критериям ничего не найдено, введите корректный SSO ID ")),
          _createVNode(_component_q_inner_loading, { showing: isSearchUsersBusy.value }, {
            default: _withCtx(() => [
              _createVNode(_component_q_spinner, {
                color: "grey",
                size: "3em"
              })
            ]),
            _: 1
          }, 8, ["showing"])
        ]))
      : _createCommentVNode("", true)
  ], 512))
}
}

})