import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "doc-container" }
const _hoisted_3 = { class: "row q-col-gutter-xl" }
const _hoisted_4 = { class: "text-h6 text-center" }

import { ref, computed } from "vue";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectList',
  setup(__props) {

const projectList = ref([
  {
    title: "Цифровой профиль ПЛ",
    url: "user-digital-project/report/pl",
    access: "admin editor user",
  },
  {
    title: "Цифровой профиль АГ",
    url: "user-digital-project/report?project=ag",
    access: "admin editor user",
  },
  {
    title: "Цифровой профиль АГ детям",
    url: "user-digital-project/report?project=ag-child",
    access: "admin editor user",
  },
  {
    title: "Цифровой профиль ГИ",
    url: "user-digital-project/report?project=gi",
    access: "admin editor user",
  },
  {
    title: "Цифровой профиль ГЗ",
    url: "user-digital-project/report?project=gz",
    access: "admin editor user",
  },
  {
    title: "Цифровой профиль ЭД",
    url: "user-digital-project/report?project=ed",
    access: "admin editor user",
  },
]);

const userRole = computed(() => {
  return store.getters.getUserAccess;
});

return (_ctx: any,_cache: any) => {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-h4 q-pb-md" }, "Проекты:", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(projectList.value, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "col-xs-5 col-sm-5 col-md-3",
            style: _normalizeStyle(item.access.includes(userRole.value) ? null : 'display: none')
          }, [
            (item.access.includes(userRole.value))
              ? (_openBlock(), _createBlock(_component_q_card, {
                  key: 0,
                  class: "chapter-card"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_card_section, { class: "q-card__chapters" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(item.title), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_separator),
                    _createVNode(_component_q_card_actions, { vertical: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_btn, {
                          to: item.url,
                          label: "В раздел",
                          color: "primary",
                          class: "q-mx-xl q-my-sm text-weight-bold"
                        }, null, 8, ["to"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true)
          ], 4))
        }), 128))
      ])
    ])
  ]))
}
}

})