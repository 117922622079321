import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, unref as _unref, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  class: "row",
  style: {"max-height":"100%"}
}
const _hoisted_2 = {
  class: "row text-black q-mt-lg",
  style: {"width":"100%"}
}
const _hoisted_3 = { class: "col-5 q-pr-lg text-black relative-position" }
const _hoisted_4 = { class: "col-12 shadow-4" }
const _hoisted_5 = { class: "col-12 bg-white" }
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = {
  class: "row",
  style: {"width":"100%"}
}
const _hoisted_8 = {
  class: "col-xl-5 col-lg-5",
  style: {"padding-right":"5px"}
}
const _hoisted_9 = { style: {"padding":"0 8px 0 5px","height":"100%"} }
const _hoisted_10 = {
  class: "col-xl-5 col-lg-4",
  style: {"padding-right":"0"}
}
const _hoisted_11 = { style: {"padding-left":"10px","height":"100%"} }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { title: "Исходные ответы" }
const _hoisted_16 = { title: "Исходные ответы" }
const _hoisted_17 = {
  key: 1,
  title: "Исходные ответы"
}
const _hoisted_18 = { class: "col-7 shadow-4 bg-white text-black relative-position" }
const _hoisted_19 = { style: {"overflow":"auto"} }

import store from "@/store";
import { computed, onMounted, ref } from "vue";
import TableAnswerSource from "../../../views/answers/TableAnswerSource.vue";
import WordFormSettings from "../../../views/answers/WordFormSettings.vue";
import type { QTable, QTableColumn, QTableSlots } from "quasar";
import { Filter } from "proto/nigma-common-proto/filter";
import { useAgQuestionApi } from "@/components/modules/polls/services/useAgQuestionApi";
import { AgQuestionModel } from "proto/nigma-message-proto/polls/ag_question";
import { useTextHighlighter } from "@/composables/useTextHighlighter";
import MessageChilds from "@/components/modules/polls/components/MessageChilds.vue";
import QuestionMessages from "@/components/modules/polls/components/agMessages/listPoll/QuestionMessages.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'QuestionCombining',
  props: ["pollId"],
  setup(__props) {

const props = __props;

const { listAgQuestionRequest } = useAgQuestionApi();
const { markedFindValue } = useTextHighlighter();

const isActiveModal = ref(false);
const isActiveModalWordForm = ref(false);
const isActiveModalSource = ref(false);
const currentCountAnswer = ref(null);
const isFullCount = ref(false);

const columnsResult = ref<QTableColumn[]>([
  {
    name: "agQuestionStitle",
    field: "agQuestionStitle",
    label: "Наименование вопроса",
    align: "left",
  },
  {
    name: "answerSrcSum",
    field: "answerSrcSum",
    label: "Количество ответов",
    align: "center",
  },
]);
const columnsChild = ref<QTableColumn[]>([
  {
    name: "agQuestionStitle",
    field: "agQuestionStitle",
    label: "Наименование вопроса",
    align: "left",
  },
  {
    name: "answerSrcCount",
    field: "answerSrcCount",
    label: "Количество ответов",
    align: "center",
  },
]);
const resultFilter = ref<Filter>({
  items: [
    {
      fieldName: "aq.ag_question_stitle",
      operand: "ilike",
      value: "",
    },
  ],
});
const resultPagination = ref({
  sortBy: "answer_src_sum",
  descending: true,
  page: 1,
  rowsPerPage: 25,
  rowsNumber: 0,
});
const rowsPerPageResultOptions = [10, 25, 50, 100];
const search = ref("");
const processedSign = ref(null);
const processedOptions = ref([
  { id: 0, title: "Все вопросы" },
  { id: 1, title: "Только обработанные" },
  { id: 2, title: "Только необработанные" },
]);
const selectedResult = ref([]);
const expandedResult = ref([]);
const markedFind = ref<string[]>([]);
const getTableRefVar = ref([]);
const addQuestionPanel = ref();
const agQuestionItems = ref<AgQuestionModel[]>([]);
const questionChildRef = ref();

const userRole = computed(() => store.getters.getUserAccess);

const openChildTable: QTableSlots["body"] = (props) => {
  setTimeout(() => {
    if (props) {
      props.__trClass = "";
    }
  }, 300);

  if (expandedResult.value[0] === props.row.agQuestionId) {
    expandedResult.value = [];
    getTableRefVar.value = [];
  } else {
    getTableRefVar.value = [];
    expandedResult.value = [props.row.agQuestionId];
  }
};

const changeProcessedSign = (event: any) => {
  const processed = JSON.parse(JSON.stringify(event));

  if (processed.id === 0) {
    resultFilter.value.items.pop();
  } else if (processed.id === 1) {
    resultFilter.value.items[1] = {
      fieldName: "aq.ag_question_processed",
      operand: "=",
      value: "true",
    };
  } else if (processed.id === 2) {
    resultFilter.value.items[1] = {
      fieldName: "aq.ag_question_processed",
      operand: "=",
      value: "false",
    };
  }

  findAnswersResult();
};

const afterAction = () => {
  search.value = "";
  expandedResult.value = [];

  if (processedSign.value?.id > 1) {
    processedSign.value = processedOptions.value[0];
    changeProcessedSign(processedSign.value);
  }

  resultPagination.value.page = 1;
  resultPagination.value.rowsNumber = 0;

  loadQuestionItems(true);
};

const onRowClickMessageResult = (props: any, isResetSimilarity: boolean) => {
  selectedResult.value = [props.row];

  setTimeout(() => {
    if (props) {
      props.__trClass = "";
    }
  }, 300);

  if (addQuestionPanel.value)
    addQuestionPanel.value?.loadData(
      true,
      isResetSimilarity,
      selectedResult.value[0]
    );
};

const hideModal = () => {
  isActiveModal.value = false;
  isActiveModalWordForm.value = false;
  isActiveModalSource.value = false;
  currentCountAnswer.value = null;
  isFullCount.value = false;
};

const onRowShowAnswerSource = (row: any, isFull: boolean) => {
  hideModal();

  currentCountAnswer.value = Object.assign(row);
  isFullCount.value = isFull;
  isActiveModalSource.value = true;
  isActiveModal.value = true;
};

const onRequestResult = (props: unknown) => {
  resultPagination.value = props.pagination;
  loadQuestionItems(false);
};

const findAnswersResult = () => {
  markedFind.value = [];

  resultFilter.value.items[0].value = search.value;

  if (search.value.length) {
    markedFind.value.push(search.value);
  }

  loadQuestionItems(true);
};

const loadQuestionItems = (isResetPagination: boolean) => {
  if (isResetPagination) {
    resultPagination.value.page = 1;
    resultPagination.value.rowsNumber = 0;
  }

  listAgQuestionRequest({
    filter: resultFilter.value,
    sort: {
      name: resultPagination.value.sortBy,
      exp: resultPagination.value.descending ? "DESC" : "ASC",
    },
    pagination: {
      page: resultPagination.value.page,
      limit: resultPagination.value.rowsPerPage,
      pages: 0,
      cnt: 0,
    },
    agPollId: props.pollId,
    isWorked: false,
  }).then((res) => {
    resultPagination.value.rowsNumber = res.params.pagination.cnt;
    agQuestionItems.value = res.items;

    selectedResult.value.length
      ? onRowClickMessageResult({ row: selectedResult.value[0] }, false)
      : null;
  });
};

const separateTableChild = () => questionChildRef.value?.separateChild();

const afterSeparate = () => {
  afterAction();
  getTableRefVar.value = [];
};

const checkChildLength = (childLength) => (getTableRefVar.value = childLength);

const loadData = () => {
  hideModal();
  processedSign.value = processedOptions.value[0];
  loadQuestionItems(true);
};

onMounted(() => loadData());

return (_ctx: any,_cache: any) => {
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[13] || (_cache[13] = _createElementVNode("div", { class: "text-h6 bg-white text-black text-weight-bold q-pa-sm" }, " Вопросы ", -1)),
            _createVNode(_component_q_separator),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_q_table, {
                  class: "sticky-header-table-answer",
                  flat: "",
                  dense: "",
                  rows: agQuestionItems.value,
                  columns: columnsResult.value,
                  "row-key": "agQuestionId",
                  "wrap-cells": "",
                  selection: "single",
                  selected: selectedResult.value,
                  "onUpdate:selected": _cache[3] || (_cache[3] = ($event: any) => ((selectedResult).value = $event)),
                  pagination: resultPagination.value,
                  "onUpdate:pagination": _cache[4] || (_cache[4] = ($event: any) => ((resultPagination).value = $event)),
                  "rows-per-page-options": rowsPerPageResultOptions,
                  expanded: expandedResult.value,
                  "onUpdate:expanded": _cache[5] || (_cache[5] = ($event: any) => ((expandedResult).value = $event)),
                  onRequest: onRequestResult,
                  style: _normalizeStyle(agQuestionItems.value.length ? 'height: 51vh' : null)
                }, {
                  top: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_q_input, {
                          modelValue: search.value,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
                          filled: "",
                          label: "Текст для поиска",
                          onKeypress: _withKeys(findAnswersResult, ["enter"])
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_q_btn, {
                          style: {"padding":"10px","margin-top":"6px","margin-right":"1px"},
                          "background-color": "gray-12",
                          icon: "mdi-magnify",
                          onClick: findAnswersResult
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_tooltip, null, {
                              default: _withCtx(() => _cache[7] || (_cache[7] = [
                                _createTextVNode("Найти вопросы")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _createVNode(_component_q_select, {
                          modelValue: processedSign.value,
                          "onUpdate:modelValue": [
                            _cache[1] || (_cache[1] = ($event: any) => ((processedSign).value = $event)),
                            _cache[2] || (_cache[2] = ($event: any) => (changeProcessedSign($event)))
                          ],
                          options: processedOptions.value,
                          filled: "",
                          "option-value": "id",
                          "option-label": "title",
                          label: "Признак обработки ответа",
                          style: {"white-space":"nowrap"}
                        }, null, 8, ["modelValue", "options"])
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        ('admin editor'.includes(userRole.value))
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 0,
                              style: {"padding":"10px","margin-top":"6px","margin-right":"1px"},
                              "background-color": "gray-12",
                              icon: "mdi-pen-remove",
                              onClick: separateTableChild,
                              disable: 
                            agQuestionItems.value.length === 0 ||
                            getTableRefVar.value.length === 0
                          
                            }, {
                              default: _withCtx(() => [
                                (
                              agQuestionItems.value.length !== 0 &&
                              getTableRefVar.value.length !== 0
                            )
                                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                                        _createTextVNode("Разделить вопросы")
                                      ])),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true),
                                (agQuestionItems.value.length === 0)
                                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                                        _createTextVNode("Количество вопросов должно быть > 0")
                                      ])),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true),
                                (
                              agQuestionItems.value.length > 0 &&
                              getTableRefVar.value.length === 0
                            )
                                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 2 }, {
                                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                                        _createTextVNode("Необходимо выбрать хотя бы 1 объединенный вопрос")
                                      ])),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }, 8, ["disable"]))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]),
                  body: _withCtx((props) => [
                    (_openBlock(), _createBlock(_component_q_tr, {
                      key: 
                        props.rowIndex +
                        '###primary###' +
                        props.row.agQuestionId
                      ,
                      props: props,
                      class: _normalizeClass(
                        props.row.agQuestionProcessed ? 'answer-processed' : ''
                      )
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_td, null, {
                          default: _withCtx(() => [
                            _withDirectives(_createVNode(_component_q_btn, {
                              size: "sm",
                              flat: "",
                              rounded: "",
                              dense: "",
                              icon: 
                            props.expand
                              ? 'fas fa-caret-down'
                              : 'fas fa-caret-right'
                          ,
                              onClick: ($event: any) => (openChildTable(props))
                            }, null, 8, ["icon", "onClick"]), [
                              [_vShow, props.row.hasChilds]
                            ])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_q_td, {
                          onClick: ($event: any) => (onRowClickMessageResult(props, true))
                        }, {
                          default: _withCtx(() => [
                            (markedFind.value.length)
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 0,
                                  innerHTML: 
                            _unref(markedFindValue)(
                              props.row.agQuestionStitle,
                              markedFind.value
                            )
                          
                                }, null, 8, _hoisted_12))
                              : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(props.row.agQuestionStitle), 1))
                          ]),
                          _: 2
                        }, 1032, ["onClick"]),
                        _createVNode(_component_q_td, {
                          class: "text-center",
                          style: {"width":"120px"}
                        }, {
                          default: _withCtx(() => [
                            (props.row.hasChilds)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                                  _createElementVNode("span", _hoisted_15, _toDisplayString(props.row.answerSrcSum.toLocaleString()), 1),
                                  _cache[11] || (_cache[11] = _createTextVNode(" | ")),
                                  _createElementVNode("span", _hoisted_16, _toDisplayString(props.row.answerSrcCount.toLocaleString()), 1)
                                ]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(props.row.answerSrcCount.toLocaleString()), 1))
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["props", "class"])),
                    (props.expand)
                      ? (_openBlock(), _createBlock(_component_q_tr, {
                          ref: 'childQuestionTableRow_' + props.row.agQuestionId,
                          key: 
                        props.rowIndex +
                        '###secondary###' +
                        props.row.agQuestionId
                      ,
                          props: props
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_td, {
                              colspan: "100%",
                              style: {"padding-right":"0","padding-top":"0","padding-left":"1.7rem"}
                            }, {
                              default: _withCtx(() => [
                                (props.expand)
                                  ? (_openBlock(), _createBlock(MessageChilds, {
                                      key: 0,
                                      ref_key: "questionChildRef",
                                      ref: questionChildRef,
                                      "table-key": 
                            props.rowIndex +
                            '###expanded###' +
                            props.row.agQuestionId
                          ,
                                      columns: columnsChild.value,
                                      "ag-question-id": props.row.agQuestionId,
                                      type: "question",
                                      onShowSource: onRowShowAnswerSource,
                                      onAfterSeparate: afterSeparate,
                                      onCheckChild: checkChildLength
                                    }, null, 8, ["table-key", "columns", "ag-question-id"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["props"]))
                      : _createCommentVNode("", true)
                  ]),
                  "no-data": _withCtx(() => _cache[12] || (_cache[12] = [
                    _createElementVNode("div", { class: "col-12 text-center text-h4" }, "Нет данных", -1)
                  ])),
                  _: 1
                }, 8, ["rows", "columns", "selected", "pagination", "expanded", "style"])
              ])
            ])
          ]),
          _createVNode(_component_q_inner_loading, {
            showing: _ctx.isQuestionBusy,
            color: "grey",
            style: {"z-index":"500"}
          }, null, 8, ["showing"])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _cache[14] || (_cache[14] = _createElementVNode("div", { class: "text-h6 text-weight-bold q-pa-sm" }, " Поиск схожих вариантов ", -1)),
            _createVNode(_component_q_separator),
            _createVNode(QuestionMessages, {
              ref_key: "addQuestionPanel",
              ref: addQuestionPanel,
              onAfterAction: afterAction,
              onShowSource: onRowShowAnswerSource,
              "ag-poll-id": __props.pollId,
              "current-node": currentCountAnswer.value
            }, null, 8, ["ag-poll-id", "current-node"])
          ])
        ])
      ])
    ]),
    _createVNode(_component_q_dialog, {
      modelValue: isActiveModal.value,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((isActiveModal).value = $event)),
      persistent: ""
    }, {
      default: _withCtx(() => [
        (isActiveModalWordForm.value)
          ? (_openBlock(), _createBlock(WordFormSettings, {
              key: 0,
              poll: _ctx.poll,
              onHideModal: hideModal
            }, null, 8, ["poll"]))
          : _createCommentVNode("", true),
        (isActiveModalSource.value)
          ? (_openBlock(), _createBlock(TableAnswerSource, {
              key: 1,
              answer: currentCountAnswer.value,
              isFull: isFullCount.value,
              onHideModal: hideModal
            }, null, 8, ["answer", "isFull"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})