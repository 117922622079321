import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "users" }
const _hoisted_2 = { class: "users__tabs" }

import { ref } from 'vue'
import CoreAnalyze from './CoreAnalyze.vue'
import UsersSegm from './UsersSegm.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'UsersCoreSeg',
  setup(__props) {

const tab = ref('analyze')

return (_ctx: any,_cache: any) => {
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "users__title" }, "Пользователи", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_tabs, {
        modelValue: tab.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((tab).value = $event)),
        class: "text-grey",
        "active-color": "primary",
        "indicator-color": "primary",
        align: "left",
        "no-caps": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab, {
            name: "analyze",
            label: "АНАЛИЗ ЯДРА"
          }),
          _createVNode(_component_q_tab, {
            name: "segmentation",
            label: "СЕГМЕНТАЦИЯ ПО ТРАТАМ"
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_q_separator),
      _createVNode(_component_q_tab_panels, {
        modelValue: tab.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((tab).value = $event)),
        animated: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab_panel, {
            name: "analyze",
            style: {"background-color":"#e5e5e5"}
          }, {
            default: _withCtx(() => [
              _createVNode(CoreAnalyze)
            ]),
            _: 1
          }),
          _createVNode(_component_q_tab_panel, {
            name: "segmentation",
            style: {"background-color":"#e5e5e5"}
          }, {
            default: _withCtx(() => [
              _createVNode(UsersSegm)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ]))
}
}

})