import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row q-pa-md overflow-auto full-height" }
const _hoisted_2 = { class: "col-12 q-pb-lg" }
const _hoisted_3 = { class: "row q-pb-lg" }
const _hoisted_4 = { class: "col-9 q-pr-md" }
const _hoisted_5 = { class: "col-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AudienceSegmentationChart = _resolveComponent("AudienceSegmentationChart")!
  const _component_SegmentationBarChart = _resolveComponent("SegmentationBarChart")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_ProjectsChoice = _resolveComponent("ProjectsChoice")!
  const _component_MetricsChoice = _resolveComponent("MetricsChoice")!
  const _component_PeriodsChoice = _resolveComponent("PeriodsChoice")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-subtitle1 q-pb-xs" }, "Сегментирование аудитории", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_AudienceSegmentationChart, { buildChart: _ctx.buildChart }, null, 8, ["buildChart"]),
          _createVNode(_component_SegmentationBarChart)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_q_card, { class: "q-pa-md q-mb-md" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_toggle, {
                modelValue: _ctx.keyIndicators,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.keyIndicators) = $event)),
                color: "secondary",
                label: "Расчет только по ключевым показателям"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ProjectsChoice),
          _createVNode(_component_MetricsChoice),
          _createVNode(_component_PeriodsChoice),
          _createVNode(_component_q_btn, {
            color: "secondary",
            label: "Построить график",
            onClick: _ctx.getChartData,
            class: "full-width"
          }, null, 8, ["onClick"])
        ])
      ])
    ])
  ]))
}