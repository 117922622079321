import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "activity__date-select row q-gutter-lg q-mr-md" }
const _hoisted_2 = { class: "row items-center justify-end" }
const _hoisted_3 = { class: "row items-center justify-end" }

import store from '@/store'
import { ref, watch, onMounted } from 'vue'
import date from 'quasar/src/utils/date/date.js';


export default /*@__PURE__*/_defineComponent({
  __name: 'DatePeriod',
  setup(__props) {

const dateFrom = ref('2021-01-01')
const dateTo = ref(new Date().toJSON().slice(0, 10))
let maxDateFromLimit = date.formatDate(dateTo.value, 'YYYY/MM')
const qDateProxy1: any = ref()
const qDateProxy2: any = ref()

watch(dateTo, newValue => {
  maxDateFromLimit = date.formatDate(newValue, 'YYYY/MM')
})

onMounted(() => {
  store.dispatch('setDateForEvent', {
    dateFrom: dateFrom.value,
    dateTo: dateTo.value,
  })
  checkDate()
})

watch([dateFrom, dateTo], () => {
  store.dispatch('setDateForEvent', {
    dateFrom: dateFrom.value,
    dateTo: dateTo.value,
  })
})

const checkDate = () => {
  let oldDate = new Date()
  let newDate = new Date(oldDate.getFullYear(), oldDate.getMonth() + 1, 0, 23, 59, 59)
  let objDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()).toLocaleString()
      .split(',')
      .slice(0, 1)
      .toString()
      .split('.')
      .reverse()
      .join('-')

  dateTo.value = objDate
}

const hideFrom = (_, reason) => {
  if (reason === 'month') {
    qDateProxy1.value.hide()
  }
}

const hideTo = (_, reason) => {
  if (reason === 'month') {
    let oldDate = new Date(dateTo.value.split('-')[0], dateTo.value.split('-')[1], dateTo.value.split('-')[2])
    let newDate = new Date(oldDate.getFullYear(), oldDate.getMonth(), 0, 23, 59, 59).getDate()

    let newArr:any = []

    dateTo.value.split('-').forEach(el => {
      newArr.push(el)
    })

    newArr[2] = newDate.toString()
    dateTo.value = newArr.join('-')

    qDateProxy2.value.hide()
  }
}

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_input, {
      filled: "",
      modelValue: dateFrom.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((dateFrom).value = $event)),
      "hide-bottom-space": "",
      "hide-hint": "",
      style: {"width":"170px","cursor":"pointer"},
      "input-style": "cursor:pointer",
      label: "Дата начала",
      mask: "####-##-##",
      readonly: "",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (qDateProxy1.value.show()))
    }, {
      append: _withCtx(() => [
        _createVNode(_component_q_icon, {
          name: "event",
          class: "cursor-pointer"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_popup_proxy, {
              cover: "",
              "transition-show": "scale",
              "transition-hide": "scale",
              ref_key: "qDateProxy1",
              ref: qDateProxy1
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_date, {
                  modelValue: dateFrom.value,
                  "onUpdate:modelValue": [
                    _cache[0] || (_cache[0] = ($event: any) => ((dateFrom).value = $event)),
                    hideFrom
                  ],
                  mask: "YYYY-MM-DD",
                  "navigation-min-year-month": "2012/01",
                  "navigation-max-year-month": _unref(maxDateFromLimit),
                  "default-year-month": "2012/01",
                  "no-unset": "",
                  minimal: "",
                  "emit-immediately": ""
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _withDirectives(_createVNode(_component_q_btn, {
                        label: "Закрыть",
                        color: "primary",
                        flat: ""
                      }, null, 512), [
                        [_directive_close_popup]
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "navigation-max-year-month"])
              ]),
              _: 1
            }, 512)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_q_input, {
      filled: "",
      modelValue: dateTo.value,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((dateTo).value = $event)),
      label: "Дата окончания",
      "hide-bottom-space": "",
      "hide-hint": "",
      mask: "####-##-##",
      readonly: "",
      style: {"width":"170px","cursor":"pointer"},
      "input-style": "cursor:pointer",
      onClick: _cache[5] || (_cache[5] = ($event: any) => (qDateProxy2.value.show()))
    }, {
      append: _withCtx(() => [
        _createVNode(_component_q_icon, {
          name: "event",
          class: "cursor-pointer"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_popup_proxy, {
              cover: "",
              "transition-show": "scale",
              "transition-hide": "scale",
              ref_key: "qDateProxy2",
              ref: qDateProxy2
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_date, {
                  modelValue: dateTo.value,
                  "onUpdate:modelValue": [
                    _cache[3] || (_cache[3] = ($event: any) => ((dateTo).value = $event)),
                    hideTo
                  ],
                  mask: "YYYY-MM-DD",
                  "navigation-min-year-month": "2012/01",
                  "no-unset": "",
                  "emit-immediately": "",
                  minimal: ""
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _withDirectives(_createVNode(_component_q_btn, {
                        label: "Закрыть",
                        color: "primary",
                        flat: ""
                      }, null, 512), [
                        [_directive_close_popup]
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }, 512)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})