// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "message_grp_cls.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { MessageGrpClsService } from "./message_grp_cls";
import type { ListMessageGrpClsOutResponse } from "./message_grp_cls";
import type { ListMessageGrpClsOutRequest } from "./message_grp_cls";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListMessageGrpClsInResponse } from "./message_grp_cls";
import type { ListMessageGrpClsInRequest } from "./message_grp_cls";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.MessageGrpClsService
 */
export interface IMessageGrpClsServiceClient {
    /**
     * @generated from protobuf rpc: ListMessageGrpClsIn(proto.nigmamsg.ListMessageGrpClsInRequest) returns (proto.nigmamsg.ListMessageGrpClsInResponse);
     */
    listMessageGrpClsIn(input: ListMessageGrpClsInRequest, options?: RpcOptions): UnaryCall<ListMessageGrpClsInRequest, ListMessageGrpClsInResponse>;
    /**
     * @generated from protobuf rpc: ListMessageGrpClsOut(proto.nigmamsg.ListMessageGrpClsOutRequest) returns (proto.nigmamsg.ListMessageGrpClsOutResponse);
     */
    listMessageGrpClsOut(input: ListMessageGrpClsOutRequest, options?: RpcOptions): UnaryCall<ListMessageGrpClsOutRequest, ListMessageGrpClsOutResponse>;
}
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.MessageGrpClsService
 */
export class MessageGrpClsServiceClient implements IMessageGrpClsServiceClient, ServiceInfo {
    typeName = MessageGrpClsService.typeName;
    methods = MessageGrpClsService.methods;
    options = MessageGrpClsService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ListMessageGrpClsIn(proto.nigmamsg.ListMessageGrpClsInRequest) returns (proto.nigmamsg.ListMessageGrpClsInResponse);
     */
    listMessageGrpClsIn(input: ListMessageGrpClsInRequest, options?: RpcOptions): UnaryCall<ListMessageGrpClsInRequest, ListMessageGrpClsInResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMessageGrpClsInRequest, ListMessageGrpClsInResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListMessageGrpClsOut(proto.nigmamsg.ListMessageGrpClsOutRequest) returns (proto.nigmamsg.ListMessageGrpClsOutResponse);
     */
    listMessageGrpClsOut(input: ListMessageGrpClsOutRequest, options?: RpcOptions): UnaryCall<ListMessageGrpClsOutRequest, ListMessageGrpClsOutResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMessageGrpClsOutRequest, ListMessageGrpClsOutResponse>("unary", this._transport, method, opt, input);
    }
}
