import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "result--head" }
const _hoisted_2 = { class: "result--data" }
const _hoisted_3 = { class: "row no-wrap" }

import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'QueryResult',
  props: ['result'],
  emits: ['exportQbuilder'],
  setup(__props, { emit: __emit }) {

const props = __props

const qBuilderResult = ref(props.result)
const emit = __emit


return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_scroll_area = _resolveComponent("q-scroll-area")!
  const _component_q_card_section = _resolveComponent("q-card-section")!

  return (_openBlock(), _createBlock(_component_q_card_section, { class: "result" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("h3", null, "Результат запроса:", -1)),
        _createVNode(_component_q_btn, {
          "no-caps": "",
          unelevated: "",
          label: "Экспорт excel",
          color: "secondary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('exportQbuilder')))
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_q_scroll_area, { style: {"height":"500px","width":"100%"} }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(qBuilderResult.value, (tab) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "result--tab",
                  key: tab.label
                }, [
                  _createElementVNode("h3", null, [
                    _createTextVNode(_toDisplayString(tab.sLabel) + " ", 1),
                    _createVNode(_component_q_tooltip, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(tab.sLabel) + "/ " + _toDisplayString(tab.label), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tab.list, (item) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: item.id
                      }, [
                        _createTextVNode(_toDisplayString(item.item) + " ", 1),
                        _createVNode(_component_q_tooltip, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.item), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]))
                    }), 128))
                  ])
                ]))
              }), 128))
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}
}

})