import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-subtitle2" }
const _hoisted_2 = {
  class: "col-12",
  style: {"widt":"100%","height":"calc(100% - 160px)","overflow":"hidden"}
}
const _hoisted_3 = {
  style: {"width":"calc(100% - 10px)","height":"100%","margin-right":"10px","overflow":"auto","border":"1px solid black"},
  class: "custom-tree"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_tree = _resolveComponent("q-tree")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_DataLoading = _resolveComponent("DataLoading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_q_card, {
      style: {"width":"1024px","height":"1024px","max-width":"80vw","max-height":"80vh"},
      class: "q-pa-md"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_label, { class: "text-h5" }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("Выбор основного слова")
                  ])),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_1, " " + _toDisplayString(_ctx.selectedText) + " ", 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_q_separator),
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_q_tree, {
              ref: "wordsParentTree",
              nodes: _ctx.getWordsTreeFormItems,
              "node-key": "id",
              "label-key": "text",
              selected: _ctx.selectedNode,
              "onUpdate:selected": [
                _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedNode) = $event)),
                _ctx.selectNode
              ]
            }, null, 8, ["nodes", "selected", "onUpdate:selected"])
          ])
        ], 512), [
          [_vShow, !_ctx.isWordsTreeFormBusy]
        ]),
        _createVNode(_component_q_separator),
        _createVNode(_component_q_card_actions, { align: "right" }, {
          default: _withCtx(() => [
            _createVNode(_component_q_btn, {
              disable: _ctx.selectedNode === null,
              color: "primary",
              onClick: _ctx.selectParent
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" Выбрать ")
              ])),
              _: 1
            }, 8, ["disable", "onClick"]),
            _createVNode(_component_q_btn, {
              color: "primary",
              onClick: _ctx.closeWordEdit
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Отмена ")
              ])),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512), [
      [_vShow, !_ctx.isWordsBusy]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_DataLoading, { msgLoading: "Перенос" })
    ], 512), [
      [_vShow, _ctx.isWordsBusy]
    ])
  ], 64))
}