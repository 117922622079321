import script from "./ClassView.vue?vue&type=script&setup=true&lang=ts"
export * from "./ClassView.vue?vue&type=script&setup=true&lang=ts"

import "./ClassView.vue?vue&type=style&index=0&id=86265f4e&lang=scss"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTree from 'quasar/src/components/tree/QTree.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QBtn,QTooltip,QInput,QIcon,QTree,QInnerLoading,QDialog});
