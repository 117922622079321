import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow } from "vue"

const _hoisted_1 = { style: {"padding":"5px 24px","max-height":"87vh"} }
const _hoisted_2 = {
  class: "row items-stretch",
  style: {"height":"100%"}
}
const _hoisted_3 = { class: "col-5" }
const _hoisted_4 = { class: "col-12 q-pr-lg" }
const _hoisted_5 = { class: "col-12 q-pr-lg q-mt-lg" }
const _hoisted_6 = {
  class: "col-12 bg-white text-black shadow-4",
  style: {"height":"74vh","overflow":"hidden"}
}
const _hoisted_7 = {
  class: "row cls-answers__actions items-center",
  style: {"min-height":"40px"}
}
const _hoisted_8 = { class: "col-lg-7 col-xl-9 cls-answers__input q-pa-sm" }
const _hoisted_9 = {
  key: 0,
  class: "row col-xl-2 col-lg-3 justify-end"
}
const _hoisted_10 = {
  style: {"width":"100%","height":"57vh","overflow":"auto"},
  class: "custom-tree"
}
const _hoisted_11 = { class: "row items-center" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { key: 1 }
const _hoisted_14 = {
  class: "col-7 shadow-4 bg-white text-black",
  style: {"overflow":"hidden"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tree = _resolveComponent("q-tree")!
  const _component_VkMessageClsPanel = _resolveComponent("VkMessageClsPanel")!
  const _component_DataLoading = _resolveComponent("DataLoading")!
  const _component_VkMessageClsSource = _resolveComponent("VkMessageClsSource")!
  const _component_ClassAdd = _resolveComponent("ClassAdd")!
  const _component_ClassEdit = _resolveComponent("ClassEdit")!
  const _component_ClassDelete = _resolveComponent("ClassDelete")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "text-h4 q-mt-sm q-mb-sm" }, " Классификация ", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_q_select, {
            ref: "classifierSelect",
            filled: "",
            modelValue: _ctx.classifier,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.classifier) = $event)),
              _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeListClassifier($event)))
            ],
            "use-input": "",
            "hide-selected": "",
            "fill-input": "",
            "input-debounce": "300",
            options: _ctx.classifierVOptions,
            "option-value": "id",
            "option-label": "name",
            "options-dense": "",
            "options-html": "",
            label: "Классификаторы",
            loading: _ctx.isClassifierBusy,
            onFilter: _ctx.filterClassifier,
            onFilterAbort: _ctx.abortFilterClassifier,
            onPopupHide: _ctx.popupHideClassifier
          }, {
            "no-option": _withCtx(() => [
              _createVNode(_component_q_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_section, { class: "text-grey" }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode(" Не найдено ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue", "options", "loading", "onFilter", "onFilterAbort", "onPopupHide"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "text-h6 text-weight-bold q-pa-sm" }, "Классы", -1)),
            _createVNode(_component_q_separator),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_q_input, {
                  style: {"margin":"1px 1px"},
                  disable: _ctx.isClassTreeBusy,
                  modelValue: _ctx.filter,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filter) = $event)),
                  filled: "",
                  onKeyup: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onInputSearchControl($event))),
                  label: "Поиск по классам"
                }, {
                  prepend: _withCtx(() => [
                    _createVNode(_component_q_icon, { name: "mdi-magnify" })
                  ]),
                  _: 1
                }, 8, ["disable", "modelValue"])
              ]),
              ('admin editor'.includes(_ctx.userRole))
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_component_q_btn, {
                      label: "ДЕЙСТВИЯ",
                      color: "primary",
                      style: {"height":"56px","display":"none"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_menu, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_list, null, {
                              default: _withCtx(() => [
                                _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                  dense: "",
                                  clickable: "",
                                  onClick: _ctx.addClass
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_item_section, { avatar: "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_icon, { name: "mdi-plus" })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_q_item_section, null, {
                                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                                        _createTextVNode("Добавить класс")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["onClick"])), [
                                  [_directive_close_popup]
                                ]),
                                _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                  dense: "",
                                  clickable: "",
                                  disable: _ctx.isClassTreeBusy || !_ctx.selectedNode,
                                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.editClass(false)))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_item_section, { avatar: "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_icon, { name: "mdi-pencil" })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_q_item_section, null, {
                                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                                        _createTextVNode("Редактировать класс")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["disable"])), [
                                  [_directive_close_popup]
                                ]),
                                _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                  dense: "",
                                  clickable: "",
                                  disable: _ctx.isClassTreeBusy || !_ctx.selectedNode,
                                  onClick: _ctx.deleteClass
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_item_section, { avatar: "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_icon, { name: "mdi-close" })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_q_item_section, null, {
                                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                                        _createTextVNode("Удалить класс")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["disable", "onClick"])), [
                                  [_directive_close_popup]
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_q_tree, {
                ref: "classTree",
                nodes: _ctx.getClassTreeItems,
                "node-key": "id",
                "label-key": "name",
                filter: _ctx.search,
                selected: _ctx.selectedNode,
                "onUpdate:selected": [
                  _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedNode) = $event)),
                  _ctx.selectNode
                ]
              }, {
                "default-header": _withCtx((prop) => [
                  _createElementVNode("div", _hoisted_11, [
                    (_ctx.search.length > 0)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          innerHTML: _ctx.strMarkRedPlus(_ctx.search, prop.node.name)
                        }, null, 8, _hoisted_12))
                      : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(prop.node.name), 1))
                  ])
                ]),
                _: 1
              }, 8, ["nodes", "filter", "selected", "onUpdate:selected"])
            ])
          ])
        ])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", null, [
          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "text-h6 text-weight-bold q-pa-sm bg-white text-black" }, "Добавление слов в классы", -1)),
          _createVNode(_component_q_separator),
          _createVNode(_component_VkMessageClsPanel, {
            ref: "vkClsMessagePanel",
            onShowSource: _ctx.onRowShowMessageSource,
            onEditClass: _cache[6] || (_cache[6] = ($event: any) => (_ctx.editClass(true)))
          }, null, 8, ["onShowSource"])
        ])
      ], 512), [
        [_vShow, !_ctx.isClassTreeBusy]
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createVNode(_component_DataLoading)
      ], 512), [
        [_vShow, _ctx.isClassTreeBusy]
      ])
    ]),
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.isActiveModal,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isActiveModal) = $event)),
      persistent: ""
    }, {
      default: _withCtx(() => [
        (_ctx.isActiveModalSource)
          ? (_openBlock(), _createBlock(_component_VkMessageClsSource, {
              key: 0,
              message: _ctx.currentCountMessage,
              isFull: _ctx.isFullCount,
              onHideModal: _ctx.hideModal
            }, null, 8, ["message", "isFull", "onHideModal"]))
          : _createCommentVNode("", true),
        (_ctx.isActiveModalAdd)
          ? (_openBlock(), _createBlock(_component_ClassAdd, {
              key: 1,
              clsId: _ctx.classifier.id,
              onAfterAction: _ctx.afterAction,
              onHideModal: _ctx.hideModal
            }, null, 8, ["clsId", "onAfterAction", "onHideModal"]))
          : _createCommentVNode("", true),
        (_ctx.isActiveModalEdit)
          ? (_openBlock(), _createBlock(_component_ClassEdit, {
              key: 2,
              clsId: _ctx.classifier.id,
              onAfterAction: _ctx.afterAction,
              onHideModal: _ctx.hideModal,
              "curr-tab": _ctx.currentTab,
              ref: "editClassRef"
            }, null, 8, ["clsId", "onAfterAction", "onHideModal", "curr-tab"]))
          : _createCommentVNode("", true),
        (_ctx.isActiveModalDelete)
          ? (_openBlock(), _createBlock(_component_ClassDelete, {
              key: 3,
              onAfterAction: _ctx.afterAction,
              onHideModal: _ctx.hideModal
            }, null, 8, ["onAfterAction", "onHideModal"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}