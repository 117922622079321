// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "classifier.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { ClassifierService } from "./classifier";
import type { DeleteClassifierResponse } from "./classifier";
import type { DeleteClassifierRequest } from "./classifier";
import type { UpdateClassifierResponse } from "./classifier";
import type { UpdateClassifierRequest } from "./classifier";
import type { CreateClassifierResponse } from "./classifier";
import type { CreateClassifierRequest } from "./classifier";
import type { ItemClassifierResponse } from "./classifier";
import type { ItemClassifierRequest } from "./classifier";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListClassifierResponse } from "./classifier";
import type { ListClassifierRequest } from "./classifier";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.ClassifierService
 */
export interface IClassifierServiceClient {
    /**
     * @generated from protobuf rpc: ListClassifier(proto.nigmamsg.ListClassifierRequest) returns (proto.nigmamsg.ListClassifierResponse);
     */
    listClassifier(input: ListClassifierRequest, options?: RpcOptions): UnaryCall<ListClassifierRequest, ListClassifierResponse>;
    /**
     * @generated from protobuf rpc: ItemClassifier(proto.nigmamsg.ItemClassifierRequest) returns (proto.nigmamsg.ItemClassifierResponse);
     */
    itemClassifier(input: ItemClassifierRequest, options?: RpcOptions): UnaryCall<ItemClassifierRequest, ItemClassifierResponse>;
    /**
     * @generated from protobuf rpc: CreateClassifier(proto.nigmamsg.CreateClassifierRequest) returns (proto.nigmamsg.CreateClassifierResponse);
     */
    createClassifier(input: CreateClassifierRequest, options?: RpcOptions): UnaryCall<CreateClassifierRequest, CreateClassifierResponse>;
    /**
     * @generated from protobuf rpc: UpdateClassifier(proto.nigmamsg.UpdateClassifierRequest) returns (proto.nigmamsg.UpdateClassifierResponse);
     */
    updateClassifier(input: UpdateClassifierRequest, options?: RpcOptions): UnaryCall<UpdateClassifierRequest, UpdateClassifierResponse>;
    /**
     * @generated from protobuf rpc: DeleteClassifier(proto.nigmamsg.DeleteClassifierRequest) returns (proto.nigmamsg.DeleteClassifierResponse);
     */
    deleteClassifier(input: DeleteClassifierRequest, options?: RpcOptions): UnaryCall<DeleteClassifierRequest, DeleteClassifierResponse>;
}
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.ClassifierService
 */
export class ClassifierServiceClient implements IClassifierServiceClient, ServiceInfo {
    typeName = ClassifierService.typeName;
    methods = ClassifierService.methods;
    options = ClassifierService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ListClassifier(proto.nigmamsg.ListClassifierRequest) returns (proto.nigmamsg.ListClassifierResponse);
     */
    listClassifier(input: ListClassifierRequest, options?: RpcOptions): UnaryCall<ListClassifierRequest, ListClassifierResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListClassifierRequest, ListClassifierResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ItemClassifier(proto.nigmamsg.ItemClassifierRequest) returns (proto.nigmamsg.ItemClassifierResponse);
     */
    itemClassifier(input: ItemClassifierRequest, options?: RpcOptions): UnaryCall<ItemClassifierRequest, ItemClassifierResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ItemClassifierRequest, ItemClassifierResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateClassifier(proto.nigmamsg.CreateClassifierRequest) returns (proto.nigmamsg.CreateClassifierResponse);
     */
    createClassifier(input: CreateClassifierRequest, options?: RpcOptions): UnaryCall<CreateClassifierRequest, CreateClassifierResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateClassifierRequest, CreateClassifierResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateClassifier(proto.nigmamsg.UpdateClassifierRequest) returns (proto.nigmamsg.UpdateClassifierResponse);
     */
    updateClassifier(input: UpdateClassifierRequest, options?: RpcOptions): UnaryCall<UpdateClassifierRequest, UpdateClassifierResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateClassifierRequest, UpdateClassifierResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteClassifier(proto.nigmamsg.DeleteClassifierRequest) returns (proto.nigmamsg.DeleteClassifierResponse);
     */
    deleteClassifier(input: DeleteClassifierRequest, options?: RpcOptions): UnaryCall<DeleteClassifierRequest, DeleteClassifierResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteClassifierRequest, DeleteClassifierResponse>("unary", this._transport, method, opt, input);
    }
}
