import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, isRef as _isRef, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "quiz-agc__sso-table" }
const _hoisted_2 = { class: "quiz-agc__sso-table--title text-h6" }

import { ref, watch } from 'vue';
import { usePagination } from '@/composables/usePagination';
import { LoadPolls } from '@/components/modules/polls/services/api/LoadPolls';
import type { QTableColumn } from 'quasar';
import { useRouter } from 'vue-router';

type Props = {
  voteId: number,
  voteName: string,
}

type Row = {
  id: number,
  sso_id: string
}

export default /*@__PURE__*/_defineComponent({
  __name: 'QuizSsoTable',
  props: {
    voteId: {},
    voteName: {}
  },
  setup(__props: any) {

const props = __props

const LoadPollsApi = new LoadPolls()

const router = useRouter()

const { onTableRequest, pagination } = usePagination()
const quizSsoList = ref<Row[]>([])
const columns: QTableColumn<Row>[] = [{
  field: 'sso_id',
  name: 'sso_id',
  label: 'SSO ID',
  align: 'left',
}]

const isSsoListLoading = ref(false)
function loadSsoIds() {

  isSsoListLoading.value = true

  LoadPollsApi.getVoteUsers(
    { page: pagination.value.page, pages: 0, cnt: 0, limit: pagination.value.rowsPerPage },
    { poll_id: props.voteId }
  ).then(res => {
    const { ssoIdList, pagination: { cnt = 0 } = {} } = res.toObject()

    quizSsoList.value = ssoIdList.map((ssoId, idx) => ({ sso_id: ssoId, id: idx }))

    pagination.value.rowsNumber = cnt
  })
    .finally(() => isSsoListLoading.value = false)
}

const onRequest = onTableRequest(loadSsoIds)

const isUserProfileLoading = ref(false)
// Not sure how it works, just copied it
const openInUserProfile = () => {
  isUserProfileLoading.value = true

  LoadPollsApi.getPersonalPollLoadItems(
    {
      page: 1,
      limit: 10,
      pages: 0,
      cnt: 0,
    },
    props.voteId
  )
    .then(() => {
      router.push({
        name: 'UserProjects',
        query: {
          data: 'personal'
        }
      })

    })
    .finally(() => {
      isUserProfileLoading.value = false
    })
}

// When another vote is clicked reload the table
watch(() => props.voteId, () => {
  pagination.value.page = 1
  pagination.value.rowsNumber = 0
  loadSsoIds()
}, { immediate: true })

return (_ctx: any,_cache: any) => {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, [
      _createElementVNode("span", null, " Участники викторины: " + _toDisplayString(props.voteName), 1),
      (quizSsoList.value.length)
        ? (_openBlock(), _createBlock(_component_q_btn, {
            key: 0,
            label: "Открыть в ЦП",
            color: "primary",
            class: "q-ml-auto q-mt-sm row",
            loading: isUserProfileLoading.value,
            onClick: openInUserProfile
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_tooltip, {
                anchor: "top middle",
                self: "bottom middle",
                offset: [10, 10]
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createElementVNode("p", {
                    style: {"font-size":"16px"},
                    class: "action-tooltip"
                  }, "Будут переданы первые 300 000 пользователей", -1)
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["loading"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_q_table, {
      rows: quizSsoList.value,
      columns: columns,
      "row-key": "id",
      pagination: _unref(pagination),
      "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => (_isRef(pagination) ? (pagination).value = $event : null)),
      loading: isSsoListLoading.value,
      onRequest: _unref(onRequest),
      dense: ""
    }, null, 8, ["rows", "pagination", "loading", "onRequest"])
  ]))
}
}

})