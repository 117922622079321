export type TreeNode<T> = T & { children: TreeNode<T>[] }

export const useTreeConverter = () => {
    return {
        treeConverter: <T, K extends keyof T>(
            items: T[],
            options: {id: K, parentId: K, tree?: (treeNode: TreeNode<T>) => TreeNode<T>}
        ): TreeNode<T>[] => {
            const tree = items
            const hashTable = Object.create(null)

            tree.forEach(data => hashTable[data[options.id]] = { ...data, children: [] })
            const filteredTree: TreeNode<T>[] = []

            tree.forEach((data) => {
                if (data[options.parentId]) hashTable[data[options.parentId]].children.push(hashTable[data[options.id]])
                else filteredTree.push(hashTable[data[options.id]])
            })

            return Object.values(filteredTree)
        },

        getNodeByKey<T, K extends keyof TreeNode<T>>(
            key: K,
            value: TreeNode<T>[K],
            tree: TreeNode<T>[]
        ): TreeNode<T> | undefined {
            const stack = [...tree]

            while (stack.length) {
                const node = stack['shift']()

                if (node) {
                    if (node[key] === value) return node;

                    node.children && stack.push(...node.children)
                }
            }
        }
    }
}