// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "ag_answer_src.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { AgAnswerSrcService } from "./ag_answer_src";
import type { PersonalListAgAnswerSrcSourceResponse } from "./ag_answer_src";
import type { PersonalListAgAnswerSrcSourceRequest } from "./ag_answer_src";
import type { PersonalListAgAnswerSrcResponse } from "./ag_answer_src";
import type { PersonalListAgAnswerSrcRequest } from "./ag_answer_src";
import type { ListAgAnswerSrcInClassResponse } from "./ag_answer_src";
import type { ListAgAnswerSrcInClassRequest } from "./ag_answer_src";
import type { ListAgAnswerSrcSourceResponse } from "./ag_answer_src";
import type { ListAgAnswerSrcSourceRequest } from "./ag_answer_src";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListAgAnswerSrcResponse } from "./ag_answer_src";
import type { ListAgAnswerSrcRequest } from "./ag_answer_src";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.AgAnswerSrcService
 */
export interface IAgAnswerSrcServiceClient {
    /**
     * @generated from protobuf rpc: ListAgAnswerSrc(proto.nigmamsg.ListAgAnswerSrcRequest) returns (proto.nigmamsg.ListAgAnswerSrcResponse);
     */
    listAgAnswerSrc(input: ListAgAnswerSrcRequest, options?: RpcOptions): UnaryCall<ListAgAnswerSrcRequest, ListAgAnswerSrcResponse>;
    /**
     * @generated from protobuf rpc: ListAgAnswerSrcSource(proto.nigmamsg.ListAgAnswerSrcSourceRequest) returns (proto.nigmamsg.ListAgAnswerSrcSourceResponse);
     */
    listAgAnswerSrcSource(input: ListAgAnswerSrcSourceRequest, options?: RpcOptions): UnaryCall<ListAgAnswerSrcSourceRequest, ListAgAnswerSrcSourceResponse>;
    /**
     * @generated from protobuf rpc: ListAgAnswerSrcInClass(proto.nigmamsg.ListAgAnswerSrcInClassRequest) returns (proto.nigmamsg.ListAgAnswerSrcInClassResponse);
     */
    listAgAnswerSrcInClass(input: ListAgAnswerSrcInClassRequest, options?: RpcOptions): UnaryCall<ListAgAnswerSrcInClassRequest, ListAgAnswerSrcInClassResponse>;
    /**
     * @generated from protobuf rpc: PersonalListAgAnswerSrc(proto.nigmamsg.PersonalListAgAnswerSrcRequest) returns (proto.nigmamsg.PersonalListAgAnswerSrcResponse);
     */
    personalListAgAnswerSrc(input: PersonalListAgAnswerSrcRequest, options?: RpcOptions): UnaryCall<PersonalListAgAnswerSrcRequest, PersonalListAgAnswerSrcResponse>;
    /**
     * @generated from protobuf rpc: PersonalListAgAnswerSrcSource(proto.nigmamsg.PersonalListAgAnswerSrcSourceRequest) returns (proto.nigmamsg.PersonalListAgAnswerSrcSourceResponse);
     */
    personalListAgAnswerSrcSource(input: PersonalListAgAnswerSrcSourceRequest, options?: RpcOptions): UnaryCall<PersonalListAgAnswerSrcSourceRequest, PersonalListAgAnswerSrcSourceResponse>;
}
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.AgAnswerSrcService
 */
export class AgAnswerSrcServiceClient implements IAgAnswerSrcServiceClient, ServiceInfo {
    typeName = AgAnswerSrcService.typeName;
    methods = AgAnswerSrcService.methods;
    options = AgAnswerSrcService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ListAgAnswerSrc(proto.nigmamsg.ListAgAnswerSrcRequest) returns (proto.nigmamsg.ListAgAnswerSrcResponse);
     */
    listAgAnswerSrc(input: ListAgAnswerSrcRequest, options?: RpcOptions): UnaryCall<ListAgAnswerSrcRequest, ListAgAnswerSrcResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListAgAnswerSrcRequest, ListAgAnswerSrcResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListAgAnswerSrcSource(proto.nigmamsg.ListAgAnswerSrcSourceRequest) returns (proto.nigmamsg.ListAgAnswerSrcSourceResponse);
     */
    listAgAnswerSrcSource(input: ListAgAnswerSrcSourceRequest, options?: RpcOptions): UnaryCall<ListAgAnswerSrcSourceRequest, ListAgAnswerSrcSourceResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListAgAnswerSrcSourceRequest, ListAgAnswerSrcSourceResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListAgAnswerSrcInClass(proto.nigmamsg.ListAgAnswerSrcInClassRequest) returns (proto.nigmamsg.ListAgAnswerSrcInClassResponse);
     */
    listAgAnswerSrcInClass(input: ListAgAnswerSrcInClassRequest, options?: RpcOptions): UnaryCall<ListAgAnswerSrcInClassRequest, ListAgAnswerSrcInClassResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListAgAnswerSrcInClassRequest, ListAgAnswerSrcInClassResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: PersonalListAgAnswerSrc(proto.nigmamsg.PersonalListAgAnswerSrcRequest) returns (proto.nigmamsg.PersonalListAgAnswerSrcResponse);
     */
    personalListAgAnswerSrc(input: PersonalListAgAnswerSrcRequest, options?: RpcOptions): UnaryCall<PersonalListAgAnswerSrcRequest, PersonalListAgAnswerSrcResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<PersonalListAgAnswerSrcRequest, PersonalListAgAnswerSrcResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: PersonalListAgAnswerSrcSource(proto.nigmamsg.PersonalListAgAnswerSrcSourceRequest) returns (proto.nigmamsg.PersonalListAgAnswerSrcSourceResponse);
     */
    personalListAgAnswerSrcSource(input: PersonalListAgAnswerSrcSourceRequest, options?: RpcOptions): UnaryCall<PersonalListAgAnswerSrcSourceRequest, PersonalListAgAnswerSrcSourceResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<PersonalListAgAnswerSrcSourceRequest, PersonalListAgAnswerSrcSourceResponse>("unary", this._transport, method, opt, input);
    }
}
