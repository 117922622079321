import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted, ref } from "vue";
import { useClassifierApi } from "@/components/modules/polls/services/useClassifierApi";
import { ClassifierModel } from "proto/nigma-message-proto/polls/classifier";
import BaseModalCard from "@/components/Base/BaseModalCard.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ClsAdd',
  emits: ["afterAction", "hideModal"],
  setup(__props, { emit: __emit }) {

const { createClassifierRequest } = useClassifierApi();

const emit = __emit;

const item = ref<ClassifierModel>({
  classifierId: 0,
  classifierName: "",
  classifierDescr: "",
  classifierNotes: "",
  classifierMultiple: false,
  classessCnt: 0,
});

const saveCls = () => {
  createClassifierRequest({
    item: item.value,
  }).then(() => {
    emit("afterAction", "create");
  });
};

const closeClsAdd = () => emit("hideModal");

const loadData = () => {
  item.value = {
    classifierId: 0,
    classifierName: "",
    classifierDescr: "",
    classifierNotes: "",
    classifierMultiple: false,
    classessCnt: 0,
  };
};

onMounted(loadData);

return (_ctx: any,_cache: any) => {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(BaseModalCard, { onClose: closeClsAdd }, {
    header: _withCtx(() => _cache[4] || (_cache[4] = [
      _createElementVNode("div", { class: "text-h5" }, "Создание классификатора", -1)
    ])),
    body: _withCtx(() => [
      _createVNode(_component_q_input, {
        modelValue: item.value.classifierName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((item.value.classifierName) = $event)),
        label: "Наименование",
        filled: ""
      }, null, 8, ["modelValue"]),
      _createVNode(_component_q_input, {
        type: "textarea",
        modelValue: item.value.classifierDescr,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((item.value.classifierDescr) = $event)),
        label: "Описание",
        filled: ""
      }, null, 8, ["modelValue"]),
      _createVNode(_component_q_input, {
        type: "textarea",
        modelValue: item.value.classifierNotes,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((item.value.classifierNotes) = $event)),
        label: "Примечания",
        filled: ""
      }, null, 8, ["modelValue"]),
      _createVNode(_component_q_checkbox, {
        modelValue: item.value.classifierMultiple,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((item.value.classifierMultiple) = $event)),
        label: "Возможность привязки одного сообщения к нескольким классам данного классификатора"
      }, null, 8, ["modelValue"])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_q_btn, {
        color: "secondary",
        onClick: saveCls
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode("Сохранить")
        ])),
        _: 1
      }),
      _createVNode(_component_q_btn, {
        color: "red-5",
        onClick: closeClsAdd
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode("Отмена")
        ])),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})