// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "ag_answer_grp.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { AgAnswerGrpService } from "./ag_answer_grp";
import type { SeparateAgAnswerGrpResponse } from "./ag_answer_grp";
import type { SeparateAgAnswerGrpRequest } from "./ag_answer_grp";
import type { ReduceAgAnswerGrpResponse } from "./ag_answer_grp";
import type { ReduceAgAnswerGrpRequest } from "./ag_answer_grp";
import type { ListAgAnswerGrpChildsResponse } from "./ag_answer_grp";
import type { ListAgAnswerGrpChildsRequest } from "./ag_answer_grp";
import type { ListAgAnswerGrpWithFindAnswerResponse } from "./ag_answer_grp";
import type { ListAgAnswerGrpWithFindAnswerRequest } from "./ag_answer_grp";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListAgAnswerGrpResponse } from "./ag_answer_grp";
import type { ListAgAnswerGrpRequest } from "./ag_answer_grp";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.AgAnswerGrpService
 */
export interface IAgAnswerGrpServiceClient {
    /**
     * @generated from protobuf rpc: ListAgAnswerGrp(proto.nigmamsg.ListAgAnswerGrpRequest) returns (proto.nigmamsg.ListAgAnswerGrpResponse);
     */
    listAgAnswerGrp(input: ListAgAnswerGrpRequest, options?: RpcOptions): UnaryCall<ListAgAnswerGrpRequest, ListAgAnswerGrpResponse>;
    /**
     * @generated from protobuf rpc: ListAgAnswerGrpWithFindAnswer(proto.nigmamsg.ListAgAnswerGrpWithFindAnswerRequest) returns (proto.nigmamsg.ListAgAnswerGrpWithFindAnswerResponse);
     */
    listAgAnswerGrpWithFindAnswer(input: ListAgAnswerGrpWithFindAnswerRequest, options?: RpcOptions): UnaryCall<ListAgAnswerGrpWithFindAnswerRequest, ListAgAnswerGrpWithFindAnswerResponse>;
    /**
     * @generated from protobuf rpc: ListAgAnswerGrpChilds(proto.nigmamsg.ListAgAnswerGrpChildsRequest) returns (proto.nigmamsg.ListAgAnswerGrpChildsResponse);
     */
    listAgAnswerGrpChilds(input: ListAgAnswerGrpChildsRequest, options?: RpcOptions): UnaryCall<ListAgAnswerGrpChildsRequest, ListAgAnswerGrpChildsResponse>;
    /**
     * @generated from protobuf rpc: ReduceAgAnswerGrp(proto.nigmamsg.ReduceAgAnswerGrpRequest) returns (proto.nigmamsg.ReduceAgAnswerGrpResponse);
     */
    reduceAgAnswerGrp(input: ReduceAgAnswerGrpRequest, options?: RpcOptions): UnaryCall<ReduceAgAnswerGrpRequest, ReduceAgAnswerGrpResponse>;
    /**
     * @generated from protobuf rpc: SeparateAgAnswerGrp(proto.nigmamsg.SeparateAgAnswerGrpRequest) returns (proto.nigmamsg.SeparateAgAnswerGrpResponse);
     */
    separateAgAnswerGrp(input: SeparateAgAnswerGrpRequest, options?: RpcOptions): UnaryCall<SeparateAgAnswerGrpRequest, SeparateAgAnswerGrpResponse>;
}
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.AgAnswerGrpService
 */
export class AgAnswerGrpServiceClient implements IAgAnswerGrpServiceClient, ServiceInfo {
    typeName = AgAnswerGrpService.typeName;
    methods = AgAnswerGrpService.methods;
    options = AgAnswerGrpService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ListAgAnswerGrp(proto.nigmamsg.ListAgAnswerGrpRequest) returns (proto.nigmamsg.ListAgAnswerGrpResponse);
     */
    listAgAnswerGrp(input: ListAgAnswerGrpRequest, options?: RpcOptions): UnaryCall<ListAgAnswerGrpRequest, ListAgAnswerGrpResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListAgAnswerGrpRequest, ListAgAnswerGrpResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListAgAnswerGrpWithFindAnswer(proto.nigmamsg.ListAgAnswerGrpWithFindAnswerRequest) returns (proto.nigmamsg.ListAgAnswerGrpWithFindAnswerResponse);
     */
    listAgAnswerGrpWithFindAnswer(input: ListAgAnswerGrpWithFindAnswerRequest, options?: RpcOptions): UnaryCall<ListAgAnswerGrpWithFindAnswerRequest, ListAgAnswerGrpWithFindAnswerResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListAgAnswerGrpWithFindAnswerRequest, ListAgAnswerGrpWithFindAnswerResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListAgAnswerGrpChilds(proto.nigmamsg.ListAgAnswerGrpChildsRequest) returns (proto.nigmamsg.ListAgAnswerGrpChildsResponse);
     */
    listAgAnswerGrpChilds(input: ListAgAnswerGrpChildsRequest, options?: RpcOptions): UnaryCall<ListAgAnswerGrpChildsRequest, ListAgAnswerGrpChildsResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListAgAnswerGrpChildsRequest, ListAgAnswerGrpChildsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReduceAgAnswerGrp(proto.nigmamsg.ReduceAgAnswerGrpRequest) returns (proto.nigmamsg.ReduceAgAnswerGrpResponse);
     */
    reduceAgAnswerGrp(input: ReduceAgAnswerGrpRequest, options?: RpcOptions): UnaryCall<ReduceAgAnswerGrpRequest, ReduceAgAnswerGrpResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReduceAgAnswerGrpRequest, ReduceAgAnswerGrpResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SeparateAgAnswerGrp(proto.nigmamsg.SeparateAgAnswerGrpRequest) returns (proto.nigmamsg.SeparateAgAnswerGrpResponse);
     */
    separateAgAnswerGrp(input: SeparateAgAnswerGrpRequest, options?: RpcOptions): UnaryCall<SeparateAgAnswerGrpRequest, SeparateAgAnswerGrpResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<SeparateAgAnswerGrpRequest, SeparateAgAnswerGrpResponse>("unary", this._transport, method, opt, input);
    }
}
