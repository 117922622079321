import {useGrpcClientWithLogger} from "@/components/modules/rec-system/services/UserGroups/middleware";
import {AgAnswerSrcServiceClient} from "proto/nigma-message-proto/polls/ag_answer_src.client";
import {
    ListAgAnswerSrcRequest,
    ListAgAnswerSrcSourceRequest,
    ListAgAnswerSrcInClassRequest,
    PersonalListAgAnswerSrcRequest,
    PersonalListAgAnswerSrcSourceRequest,
} from "proto/nigma-message-proto/polls/ag_answer_src"

export const useAgAnswerSrcApi = () => {
    const client = useGrpcClientWithLogger().create(AgAnswerSrcServiceClient, {baseUrl: window.VUE_APP_GATEWAY_URL})

    const metadata = () => {
        return {
            Authorization: localStorage.getItem('access-key') || ''
        }
    }

    return {
        listAgAnswerSrcRequest: (request: ListAgAnswerSrcRequest) => {
            return client.listAgAnswerSrc(request, {meta: metadata()}).response
        },
        listAgAnswerSrcSourceRequest: (request: ListAgAnswerSrcSourceRequest) => {
            return client.listAgAnswerSrcSource(request, {meta: metadata()}).response
        },
        listAgAnswerSrcInClassRequest: (request: ListAgAnswerSrcInClassRequest) => {
            return client.listAgAnswerSrcInClass(request, {meta: metadata()}).response
        },
        personalListAgAnswerSrcRequest: (request: PersonalListAgAnswerSrcRequest) => {
            return client.personalListAgAnswerSrc(request, {meta: metadata()}).response
        },
        personalListAgAnswerSrcSourceRequest: (request: PersonalListAgAnswerSrcSourceRequest) => {
            return client.personalListAgAnswerSrcSource(request, {meta: metadata()}).response
        }
    }
}