import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pa-sm" }
const _hoisted_2 = { class: "row items-center justify-between full-width" }
const _hoisted_3 = {
  class: "row no-wrap",
  style: {"width":"50%"}
}
const _hoisted_4 = { class: "relative-position q-mt-md" }
const _hoisted_5 = {
  class: "row justify-center",
  style: {"width":"200px"}
}
const _hoisted_6 = { class: "text-subtitle2" }
const _hoisted_7 = { class: "text-subtitle2" }
const _hoisted_8 = { class: "text-subtitle2" }
const _hoisted_9 = { class: "text-h6 text-weight-bold" }
const _hoisted_10 = {
  class: "row justify-end",
  style: {"gap":"5px"}
}

import {onMounted, reactive, ref} from "vue";
import {TagsApi} from "@/components/modules/polls/services/api/TagsApi";
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';;

type TagsRows = {
  tagName: string,
  tagId: number,
  tagDescr: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TagsList',
  setup(__props) {

const tagsApi = new TagsApi()
const $q = useQuasar()

const rows = ref<TagsRows[]>([])
const columns = ref([
  {
    name: 'tagName',
    label: 'Название тега',
    field: 'tagName',
    align: 'left',
    headerStyle: 'font-weight: 700',
  },
  {
    name: 'tagDescr',
    label: 'Описание тега',
    field: 'tagDescr',
    align: 'left',
    headerStyle: 'font-weight: 700',
  },
  {
    name: 'actions',
    label: 'Действие',
    field: 'actions',
    style: 'width: 200px',
    align: 'center',
    headerStyle: 'font-weight: 700',
  }
])
const tagsPagination = ref({
  sortBy: '',
  descending: true,
  page: 1,
  rowsPerPage: 10,
  rowsNumber: 0,
})
const tagsLoader = ref<boolean>(false)
const tagsSearch = ref<string>('')
const modalHandler = ref(false)
const crudTitle = ref<string>('')
const crudLabel = ref<string>('')
const tagsModel = reactive({
  tagId: 0,
  tagName: '',
  tagDescr: '',
  tagParentId: 1,
  tagColor: '#a3ff66',
  tagInUse: true,
  metricList: [],
})
const activity = ref(0)
const loyalty = ref(0)
const usefulness = ref(0)
const interesting = ref(0)
const crudLoader = ref<boolean>(false)
const colorPopup = ref<boolean>(false)

const onTableRequest = (props: any) => {
  tagsPagination.value = props.pagination
  getAllTagsList()
}

const getAllTagsList = () => {
  tagsLoader.value = true

  tagsApi
      .getTagsList(
          [
            {
              field_name: 'tag_name',
              operand: 'ilike',
              value: tagsSearch.value,
            }
          ],
          {
            name: 'tag_name',
            exp: 'asc',
          },
          {
            page: tagsPagination.value.page,
            limit: tagsPagination.value.rowsPerPage,
            pages: 0,
            cnt: 0,
          }
      )
      .then((res: any) => {
        console.log('resplonsetags', res)

        rows.value = res[0].map(el => {
          return {
            tagId: el.tagId,
            tagName: el.tagName,
            tagDescr: el.tagDescr,
            tagColor: el.tagColor,
            tagInUse: el.tagInUse,
            metricList: el.metricsList,
          }
        })

        tagsLoader.value = false
        tagsPagination.value.rowsNumber = res[1]
      })
}

const getRGBComponents = (color: any) => {
  const r = color.substring(1, 3)
  const g = color.substring(3, 5)
  const b = color.substring(5, 7)

  return {
    R: parseInt(r, 16),
    G: parseInt(g, 16),
    B: parseInt(b, 16)
  }
}

const idealTextColor = (bgColor: any) => {
  const nThreshold = 105
  const components = getRGBComponents(bgColor)
  const bgDelta = (components.R * 0.299) + (components.G * 0.587) + (components.B * 0.114)

  return ((255 - bgDelta) < nThreshold) ? '#000000' : '#ffffff'
}

const modalTagsEdit = (type: string, row?: any) => {
  modalHandler.value = true

  if (type === 'create') {
    crudLabel.value = 'Создать'
    crudTitle.value = 'Создание тега'

    tagsModel.tagId = 0
    tagsModel.tagName = ''
    tagsModel.tagDescr = ''
    tagsModel.tagColor = '#a3ff66'
    tagsModel.tagInUse = true
    activity.value = 0
    loyalty.value = 0
    usefulness.value = 0
    interesting.value = 0
  } else {
    crudLabel.value = 'Сохранить'
    crudTitle.value = 'Редактирование тега'

    tagsModel.tagName = row.tagName
    tagsModel.tagId = row.tagId
    tagsModel.tagDescr = row.tagDescr
    tagsModel.metricList = row.metricList
    tagsModel.tagColor = row.tagColor
    tagsModel.tagInUse = row.tagInUse

    activity.value = tagsModel.metricList[0].metricRatio
    loyalty.value = tagsModel.metricList[1].metricRatio
    usefulness.value = tagsModel.metricList[2].metricRatio
    interesting.value = tagsModel.metricList[3].metricRatio
  }
}

const createTags = () => {
  crudLoader.value = true

  tagsApi
      .createTags({
        tagName: tagsModel.tagName,
        tagDescr: tagsModel.tagDescr,
        tagParentId: tagsModel.tagParentId,
        tagColor: tagsModel.tagColor,
        tagRatiosList: [
          {
            ratioId: 0,
            metricId: 1,
            metricRatio: activity.value,
            metricName: 'Активность',
            metricIdx: 1,
            tagId: 0,
          },
          {
            ratioId: 0,
            metricId: 2,
            metricRatio: loyalty.value,
            metricName: 'Лояльность',
            metricIdx: 1,
            tagId: 0,
          },
          {
            ratioId: 0,
            metricId: 3,
            metricRatio: usefulness.value,
            metricName: 'Полезность',
            metricIdx: 1,
            tagId: 0,
          },
          {
            ratioId: 0,
            metricId: 4,
            metricRatio: interesting.value,
            metricName: 'Заинтересованность',
            metricIdx: 1,
            tagId: 0,
          },
        ]
      })
      .then(() => {
        $q.notify({
          type: 'positive',
          message: 'Тег успешно создан.'
        })

        crudLoader.value = false

        getAllTagsList()
        modalHandler.value = false
      })
}

const editTags = () => {
  const metricList = []
  crudLoader.value = true

  tagsModel.metricList.forEach((el, index) => {
    let ratio = 0

    if (index === 0) ratio = activity.value
    if (index === 1) ratio = loyalty.value
    if (index === 2) ratio = usefulness.value
    if (index === 3) ratio = interesting.value

    metricList.push({
      ratioId: el.ratioId,
      metricId: el.metricId,
      metricRatio: ratio,
      metricName: el.metricName,
      metricIdx: el.metricIdx,
      tagId: el.tagId,
    })
  })

  tagsApi
      .updateTags({
        tagId: tagsModel.tagId,
        tagName: tagsModel.tagName,
        tagDescr: tagsModel.tagDescr,
        tagParentId: tagsModel.tagParentId,
        tagColor: tagsModel.tagColor,
        metricsList: metricList,
        tagInUse: tagsModel.tagInUse,
      })
      .then(() => {
        $q.notify({
          type: 'positive',
          message: 'Тег успешно изменен.'
        })

        crudLoader.value = false

        getAllTagsList()
        modalHandler.value = false
      })
      .catch(() => {
        $q.notify({
          type: 'positive',
          message: 'Тег успешно изменен.'
        })

        crudLoader.value = false

        getAllTagsList()
        modalHandler.value = false
      })
}

const visibleHandler = (row: any) => {
  tagsLoader.value = true
  const methodRow = row

  tagsApi
      .updateTags({
        tagId: methodRow.tagId,
        tagName: methodRow.tagName,
        tagDescr: methodRow.tagDescr,
        tagParentId: methodRow.tagParentId,
        tagColor: methodRow.tagColor,
        metricsList: methodRow.metricList,
        tagInUse: !methodRow.tagInUse,
      })
      .then(() => {
        $q.notify({
          type: 'positive',
          message: methodRow.tagInUse ? 'Тег успешно скрыт.' : 'Тег успешно отображен.'
        })

        tagsLoader.value = false

        getAllTagsList()
      })
      .catch(() => {
        $q.notify({
          type: 'positive',
          message: methodRow.tagInUse ? 'Тег успешно скрыт.' : 'Тег успешно отображен.'
        })

        tagsLoader.value = false

        getAllTagsList()
      })
}

onMounted(() => getAllTagsList())

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_color = _resolveComponent("q-color")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card, { class: "q-pa-sm" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_q_btn, {
            label: "Добавить",
            color: "primary",
            icon: "mdi-plus",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (modalTagsEdit('create')))
          }),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_q_input, {
              filled: "",
              dense: "",
              style: {"width":"100%"},
              modelValue: tagsSearch.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((tagsSearch).value = $event)),
              label: "Поиск по названию",
              onKeyup: _withKeys(getAllTagsList, ["enter"])
            }, {
              prepend: _withCtx(() => [
                _createVNode(_component_q_icon, { name: "mdi-magnify" })
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_q_btn, {
              color: "primary",
              icon: "mdi-magnify",
              dense: "",
              class: "q-ml-sm q-pl-sm q-pr-sm",
              onClick: getAllTagsList
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_q_table, {
            rows: rows.value,
            columns: columns.value,
            "row-key": "tagId",
            "wrap-cells": "",
            class: "list-tags-table q-mt-sm shadow-4",
            "rows-per-page-options": [10, 25, 50, 100],
            onRequest: onTableRequest,
            pagination: tagsPagination.value,
            "onUpdate:pagination": _cache[2] || (_cache[2] = ($event: any) => ((tagsPagination).value = $event)),
            style: {"height":"80vh"}
          }, {
            "body-cell-tagName": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    style: _normalizeStyle([{"width":"max-content","border-radius":"10px","padding":"4px 8px"}, {
                    background: props.row.tagColor,
                    color: idealTextColor(props.row.tagColor)
                  }])
                  }, _toDisplayString(props.row.tagName), 5)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-actions": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_q_btn, {
                      flat: "",
                      dense: "",
                      round: "",
                      icon: "mdi-pencil",
                      color: "primary",
                      onClick: ($event: any) => (modalTagsEdit('edit', props.row))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_tooltip, null, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_6, " Редактировать тег \"" + _toDisplayString(props.row.tagName) + "\" ", 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    (props.row.tagInUse)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          flat: "",
                          dense: "",
                          round: "",
                          icon: "mdi-eye",
                          color: "secondary",
                          onClick: ($event: any) => (visibleHandler(props.row))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_tooltip, null, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_7, " Скрыть тег \"" + _toDisplayString(props.row.tagName) + "\" ", 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : (_openBlock(), _createBlock(_component_q_btn, {
                          key: 1,
                          flat: "",
                          dense: "",
                          round: "",
                          icon: "mdi-eye-off",
                          color: "secondary",
                          onClick: ($event: any) => (visibleHandler(props.row))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_tooltip, null, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_8, " Отобразить тег \"" + _toDisplayString(props.row.tagName) + "\" ", 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                  ])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            _: 1
          }, 8, ["rows", "columns", "pagination"]),
          _createVNode(_component_q_inner_loading, {
            showing: tagsLoader.value,
            color: "grey",
            style: {"z-index":"100"}
          }, null, 8, ["showing"])
        ]),
        _createVNode(_component_q_dialog, {
          modelValue: modalHandler.value,
          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((modalHandler).value = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_card, {
              class: "q-pa-md relative-position",
              style: {"max-width":"1000px"}
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, _toDisplayString(crudTitle.value), 1),
                _createVNode(_component_q_form, {
                  onSubmit: _cache[14] || (_cache[14] = _withModifiers(($event: any) => (crudLabel.value === 'Сохранить' ? editTags() : createTags()), ["prevent"]))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_input, {
                      filled: "",
                      label: "Название тега",
                      style: {"width":"800px"},
                      class: "q-mt-md",
                      modelValue: tagsModel.tagName,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((tagsModel.tagName) = $event)),
                      rules: [val => val !== '' || 'Заполните это поле']
                    }, null, 8, ["modelValue", "rules"]),
                    _createVNode(_component_q_input, {
                      filled: "",
                      label: "Описание тега",
                      style: {"width":"800px"},
                      modelValue: tagsModel.tagDescr,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((tagsModel.tagDescr) = $event)),
                      rules: [val => val !== '' || 'Заполните это поле']
                    }, null, 8, ["modelValue", "rules"]),
                    _createVNode(_component_q_input, {
                      modelValue: tagsModel.tagColor,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((tagsModel.tagColor) = $event)),
                      label: "Выбор цвета обозначения тега",
                      filled: "",
                      readonly: "",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (colorPopup.value = true)),
                      rules: [val => !!val || 'Заполните это поле'],
                      ref: "classColor"
                    }, {
                      prepend: _withCtx(() => [
                        _createElementVNode("div", {
                          style: _normalizeStyle([{"width":"20px","height":"20px"}, {background: tagsModel.tagColor}])
                        }, null, 4)
                      ]),
                      append: _withCtx(() => [
                        _createVNode(_component_q_icon, {
                          name: "colorize",
                          class: "cursor-pointer"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_popup_proxy, {
                              cover: "",
                              "transition-show": "scale",
                              "transition-hide": "scale",
                              modelValue: colorPopup.value,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((colorPopup).value = $event))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_color, {
                                  modelValue: tagsModel.tagColor,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((tagsModel.tagColor) = $event))
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue", "rules"]),
                    _createVNode(_component_q_input, {
                      filled: "",
                      label: "Влияние на активность",
                      style: {"width":"800px"},
                      modelValue: activity.value,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((activity).value = $event)),
                      rules: [val => val !== '' || 'Заполните это поле']
                    }, null, 8, ["modelValue", "rules"]),
                    _createVNode(_component_q_input, {
                      filled: "",
                      label: "Влияние на лояльность",
                      style: {"width":"800px"},
                      modelValue: loyalty.value,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((loyalty).value = $event)),
                      rules: [val => val !== '' || 'Заполните это поле']
                    }, null, 8, ["modelValue", "rules"]),
                    _createVNode(_component_q_input, {
                      filled: "",
                      label: "Влияние на полезность",
                      style: {"width":"800px"},
                      modelValue: usefulness.value,
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((usefulness).value = $event)),
                      rules: [val => val !== '' || 'Заполните это поле']
                    }, null, 8, ["modelValue", "rules"]),
                    _createVNode(_component_q_input, {
                      filled: "",
                      label: "Влияние на заинтересованность",
                      style: {"width":"800px"},
                      modelValue: interesting.value,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((interesting).value = $event)),
                      rules: [val => val !== '' || 'Заполните это поле']
                    }, null, 8, ["modelValue", "rules"]),
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_q_btn, {
                        color: "secondary",
                        label: crudLabel.value,
                        type: "submit"
                      }, null, 8, ["label"]),
                      _createVNode(_component_q_btn, {
                        color: "red",
                        label: "Отмена",
                        onClick: _cache[13] || (_cache[13] = ($event: any) => (modalHandler.value = false))
                      })
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_inner_loading, {
                  showing: crudLoader.value,
                  color: "grey"
                }, null, 8, ["showing"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    })
  ]))
}
}

})