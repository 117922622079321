import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "relative-position",
  style: {"width":"40%"}
}

import {ref, computed, watch} from "vue";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'CityProjectChart',
  props: ['propsStyle'],
  setup(__props) {

const props = __props

const chartOptions = ref({
  chart: {
    type: 'pie'
  },

  plotOptions: {
    series: {
      innerSize: '90%',
      showInLegend: true,
      dataLabels: {
        enabled: true,
        formatter: function () {
          return `${this.y.toLocaleString()} (${this.point.proc}%)`
        }
      }
    }
  },

  title: {
    text: ''
  },

  subtitle: {
    text: '',
    verticalAlign: 'middle',
    style: {
      fontSize: '18px',
      fontWeight: 700,
      color: 'black',
    }
  },

  series: [
    {
      name: 'Количество',
      data: []
    }
  ]
})

const isPointsByProject = computed(() => store.getters.isPointsByProject)
const getProjectPoints = computed(() => {
  const items = store.getters.getPointsByProject
  const filteredList = []
  let allSum = 0

  if (items) {
    for (const i of items) {
      allSum += i.getValue()
    }

    for (const i of items) {
      filteredList.push({
        name: i.getProject(),
        y: i.getValue(),
        proc: ((i.getValue() / allSum) * 100).toFixed(3)
      })
    }
  }

  return {filteredList, allSum}
})

watch(getProjectPoints, (newValue) => {
  chartOptions.value.series[0].data = newValue.filteredList
  chartOptions.value.subtitle.text = newValue.allSum.toLocaleString()
})

return (_ctx: any,_cache: any) => {
  const _component_highcharts = _resolveComponent("highcharts")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_highcharts, {
      class: "hc",
      options: chartOptions.value,
      ref: "chart",
      style: _normalizeStyle(`height: ${props.propsStyle}px`)
    }, null, 8, ["options", "style"]),
    _createVNode(_component_q_inner_loading, {
      showing: isPointsByProject.value,
      color: "grey"
    }, null, 8, ["showing"])
  ]))
}
}

})