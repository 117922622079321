import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { ref, computed, watch } from 'vue'
import { onClickOutside } from '@vueuse/core'
import store from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'ProjectsSelect',
  props: {
  loadActions: {
    type: Boolean,
    default: true,
  },
  singleProject: {
    type: Number,
    default: 0,
  },
  projectAssociation: {
    type: Object,
    default: () => {},
  }
},
  setup(__props) {

const props = __props

const filterInput = ref('')
const selectedProjects = ref()
const isDropdownOpen = ref(false)
const selectedNodes = ref()
const projectSelectRef = ref()
const projectsInputRef = ref()

const getProjectItems = computed(() => {
  let rawProjects = store.getters.getProjectsItems

  const treeNodes: any = [
    {
      label: 'Все проекты',
      children: [],
    },
  ]

  for (let i in rawProjects) {
    treeNodes[0].children.push({ label: rawProjects[i].getProjectName() })
  }
  return treeNodes
})
const getAccountSsoId = computed(() => store.getters.getAccountSsoId)

const onSelected = (projects: any) => {
  let projectsIds: any = []

  for (const item of projects) {
    projectsIds.push(props.projectAssociation[item])
  }

  selectedProjects.value = projects.join(', ')

  store.dispatch('setSelectedProjectsItems', projectsIds).then(() => {
    if (props.loadActions) {
      store.dispatch('getActionsList', {
        filter: {
          sso_id: getAccountSsoId.value,
          project_ids: projectsIds,
        },
      })
    }
  })
}

const showDropDown = (value: boolean) => {
  isDropdownOpen.value = value
}

onClickOutside(projectSelectRef, (e: any) => {
  e.target.closest('.project-select-tree') ? null : showDropDown(false)
})

watch(getProjectItems, () => {
  selectedNodes.value = []

  if (getProjectItems.value[0].children.length <= 1) {
    selectedNodes.value = []
    const label = getProjectItems?.value[0]?.children[0]?.label
    selectedProjects.value = label
    if (getProjectItems.value[0].children[0]) {
      getProjectItems.value[0].children[0].disabled = true
      getProjectItems.value[0].disabled = true
    }
    if (label) {
      selectedNodes.value.push(getProjectItems.value[0].children[0].label)
    }
    store.dispatch('setSelectedProjectsItems', props.projectAssociation[label])
  }
})

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_tree = _resolveComponent("q-tree")!

  return (_openBlock(), _createElementBlock("div", {
    class: "project-select-tree col",
    ref_key: "projectSelectRef",
    ref: projectSelectRef
  }, [
    _createVNode(_component_q_input, {
      ref_key: "projectsInputRef",
      ref: projectsInputRef,
      modelValue: filterInput.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filterInput).value = $event)),
      filled: "",
      label: selectedProjects.value || 'Все проекты',
      "hide-bottom-space": "",
      clearable: "",
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (showDropDown(true)))
    }, {
      append: _withCtx(() => [
        _createVNode(_component_q_icon, {
          name: isDropdownOpen.value ? 'fas fa-caret-up' : 'fas fa-caret-down'
        }, null, 8, ["name"])
      ]),
      _: 1
    }, 8, ["modelValue", "label"]),
    (getProjectItems.value && isDropdownOpen.value)
      ? (_openBlock(), _createElementBlock("div", _mergeProps({
          key: 0,
          class: "select-tree-dropdown"
        }, _ctx.$attrs), [
          _createVNode(_component_q_tree, {
            nodes: getProjectItems.value,
            "node-key": "label",
            "tick-strategy": "leaf",
            "default-expand-all": "",
            ticked: selectedNodes.value,
            "onUpdate:ticked": [
              _cache[2] || (_cache[2] = ($event: any) => ((selectedNodes).value = $event)),
              onSelected
            ],
            filter: filterInput.value
          }, null, 8, ["nodes", "ticked", "filter"])
        ], 16))
      : _createCommentVNode("", true)
  ], 512))
}
}

})