import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pa-md" }

import RecList from "@/components/RecList.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'RecSystem',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-h4 q-pb-md" }, "Рекомендательная система", -1)),
    _createVNode(RecList)
  ]))
}
}

})