import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, isRef as _isRef, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "intersection-table" }
const _hoisted_2 = { class: "column q-mb-sm" }
const _hoisted_3 = {
  class: "flex items-center",
  style: {"width":"100%"}
}
const _hoisted_4 = {
  class: "text-weight-bold",
  style: {"font-size":"30px","color":"rgb(2, 123, 227)","margin":"0","line-height":"34px"}
}
const _hoisted_5 = { class: "int-table-row q-ml-auto" }
const _hoisted_6 = { class: "row justify-end q-mt-sm" }

import {
  computed,
  onMounted,
  ref,
  toRaw,
} from "vue";
import store from "@/store";
import ExportButtonIntersection from "@/components/modules/accounts/components/ExportButton/ExportButtonIntersection.vue";
import IntTableProjects from "@/components/modules/intersection/components/Registration/IntTableProjects.vue";
import { useRouter } from "vue-router";
import { usePagination } from "@/composables/usePagination";

interface IntersectionTableProps {
  dateMin: string;
  dateMax: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'IntersectionTable',
  props: {
    dateMin: {},
    dateMax: {}
  },
  setup(__props: any, { expose: __expose }) {

const { onTableRequest: onRequest, pagination: tablePagination } =
  usePagination({
    page: 1,
    rowsPerPage: 5,
    rowsNumber: 0,
    descending: true,
    sortBy: "",
  });

const router = useRouter();

const props = __props;

const allProjects = ref(false);
const loader = ref(false);
const projectAssociation = ref({});

const metricValues = computed(() => {
  const metricList = store.getters.getMetricChecked;

  return toRaw(metricList);
});

const intersectionCols = computed(() => {
  const slctdProjects = store.getters.getSelectedProjectItems;
  const filteredList = [];
  filteredList.push({
    name: "sso_id",
    label: "SSO ID",
    field: "id",
    align: "left",
  });

  if (slctdProjects) {
    filteredList.push(
      {
        name: "1",
        label: "Активный гражданин",
        field: "ag",
        align: "left",
      },
      {
        name: "2",
        label: "Город заданий",
        field: "gz",
        align: "left",
      },
      {
        name: "3",
        label: "Город идей",
        field: "gi",
        align: "left",
      },
      {
        name: "4",
        label: "Электронный дом",
        field: "ed",
        align: "left",
      },
      {
        name: "5",
        label: "Наш город",
        field: "ng",
        align: "left",
      },
      {
        name: "6",
        label: "Миллион призов",
        field: "mp",
        align: "left",
      },
      {
        name: "17",
        label: "Активный гражданин - детям",
        field: "agc",
        align: "left",
      }
    );
  } else {
    filteredList.push(
      {
        name: "1",
        label: "Активный гражданин",
        field: "ag",
        align: "left",
      },
      {
        name: "2",
        label: "Город заданий",
        field: "gz",
        align: "left",
      },
      {
        name: "3",
        label: "Город идей",
        field: "gi",
        align: "left",
      },
      {
        name: "4",
        label: "Электронный дом",
        field: "ed",
        align: "left",
      },
      {
        name: "5",
        label: "Наш город",
        field: "ng",
        align: "left",
      },
      {
        name: "6",
        label: "Миллион призов",
        field: "mp",
        align: "left",
      },
      {
        name: "17",
        label: "Активный гражданин - детям",
        field: "agc",
        align: "left",
      }
    );
  }

  return filteredList;
});

const intersectionRows = computed(() => {
  const rows = store.getters.getIntersectionTable;
  const filteredList = [];

  if (rows) {
    for (const item of rows) {
      let arr = item.getProjectYesList();

      filteredList.push({
        id: item.getSsoId(),
        ag: arr.includes(1) ? "Зарегистрирован" : "",
        gz: arr.includes(2) ? "Зарегистрирован" : "",
        gi: arr.includes(3) ? "Зарегистрирован" : "",
        ed: arr.includes(4) ? "Зарегистрирован" : "",
        ng: arr.includes(5) ? "Зарегистрирован" : "",
        mp: arr.includes(6) ? "Зарегистрирован" : "",
        agc: arr.includes(17) ? "Зарегистрирован" : "",
      });
    }
  }

  return filteredList;
});

const selectedProjects = computed(() => store.getters.getSelectedProjectItems);
const isTableBusy = computed(() => store.getters.isIntersectionTableBusy);

const personalProjectInfo = () => {
  loader.value = true;

  store
    .dispatch("getPersonalProjectInfo", {
      activity: metricValues.value["Активность"],
      loyalty: metricValues.value["Лояльность"],
      useful: metricValues.value["Полезность"],
      interest: metricValues.value["Заинтересованность"],
      all_project: allProjects.value,
      project_ids: selectedProjects.value,
      date_begin: props.dateMin,
      date_end: props.dateMax,
    })
    .then(() => {
      router.push({
        name: "UserProjects",
        query: {
          data: "personal",
        },
      });

      loader.value = false;
    });
};

const getProjects = () => {
  store
    .dispatch("getProjectsItems", {
      filter: [
        {
          field_name: "is_visible",
          operand: "=",
          value: "true",
        },
      ],
      sort: {
        name: "project_id",
        exp: "asc",
      },
    })
    .then((res) => {
      const filteredAssocProjects = [];

      for (const i of res.items) {
        filteredAssocProjects.push([i.getProjectName(), i.getProjectId()]);
      }

      projectAssociation.value = Object.fromEntries(filteredAssocProjects);
    });
};

const loadData = () => {
  store
    .dispatch("getIntersectionTable", {
      pagination: {
        page: tablePagination.value.page,
        limit: tablePagination.value.rowsPerPage,
        pages: 0,
        cnt: 0,
      },
      activity: metricValues.value["Активность"],
      loyalty: metricValues.value["Лояльность"],
      useful: metricValues.value["Полезность"],
      interest: metricValues.value["Заинтересованность"],
      all_project: allProjects.value,
      project_ids: selectedProjects.value,
      date_begin: props.dateMin,
      date_end: props.dateMax,
    })
    .then(({ params }) => {
      tablePagination.value.rowsNumber = params.getPagination().getCnt();
    });
};

const onIntersectionTableRequest = onRequest(loadData);

__expose({
  loadData,
});

onMounted(() => {
  getProjects();
  loadData();
});

return (_ctx: any,_cache: any) => {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(tablePagination).rowsNumber.toLocaleString()), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_q_checkbox, {
            modelValue: allProjects.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((allProjects).value = $event)),
            label: "Обязательная регистрация во всех перечисленных проектах",
            class: "q-mr-sm"
          }, null, 8, ["modelValue"]),
          _createVNode(IntTableProjects, {
            "project-association": projectAssociation.value,
            class: "q-mr-sm"
          }, null, 8, ["project-association"])
        ]),
        _createVNode(_component_q_btn, {
          label: "Применить",
          color: "primary",
          style: {"height":"56px"},
          onClick: loadData
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tooltip, {
              anchor: "top middle",
              self: "bottom middle",
              offset: [10, 10]
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("p", {
                  style: {"font-size":"16px"},
                  class: "action-tooltip"
                }, " Будут переданы первые 300 000 пользователей ", -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(ExportButtonIntersection, {
          "selected-projects": selectedProjects.value,
          "all-check": allProjects.value,
          "date-min": props.dateMin,
          "date-max": props.dateMax,
          class: "justify-end",
          style: {"height":"56px"}
        }, null, 8, ["selected-projects", "all-check", "date-min", "date-max"]),
        _createVNode(_component_q_btn, {
          label: "Открыть в ЦП",
          color: "primary",
          class: "q-ml-sm",
          style: {"height":"56px"},
          onClick: personalProjectInfo
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tooltip, {
              anchor: "top middle",
              self: "bottom middle",
              offset: [10, 10]
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createElementVNode("p", {
                  style: {"font-size":"16px"},
                  class: "action-tooltip"
                }, " Будут переданы первые 300 000 пользователей ", -1)
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_q_table, {
      class: "intersection-table__field",
      columns: intersectionCols.value,
      rows: intersectionRows.value,
      loading: isTableBusy.value,
      "rows-per-page-options": [5, 10, 15, 25],
      "row-key": "sso_id",
      "wrap-cells": "",
      onRequest: _unref(onIntersectionTableRequest),
      pagination: _unref(tablePagination),
      "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_isRef(tablePagination) ? (tablePagination).value = $event : null))
    }, {
      loading: _withCtx(() => [
        _createVNode(_component_q_inner_loading, {
          showing: true,
          style: {"z-index":"2"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_spinner, {
              color: "grey",
              size: "3em"
            })
          ]),
          _: 1
        })
      ]),
      pagination: _withCtx((scope) => [
        _createTextVNode(_toDisplayString((
            scope.pagination.rowsPerPage * scope.pagination.page -
            scope.pagination.rowsPerPage +
            1
          ).toLocaleString()) + " - " + _toDisplayString(scope.pagination.rowsPerPage * scope.pagination.page >
          scope.pagination.rowsNumber
            ? scope.pagination.rowsNumber.toLocaleString()
            : (
                scope.pagination.rowsPerPage * scope.pagination.page
              ).toLocaleString()) + " из " + _toDisplayString(scope.pagination.rowsNumber.toLocaleString()) + " ", 1),
        (scope.pagesNumber > 2)
          ? (_openBlock(), _createBlock(_component_q_btn, {
              key: 0,
              icon: "first_page",
              color: "grey-8",
              round: "",
              dense: "",
              flat: "",
              disable: scope.isFirstPage,
              onClick: scope.firstPage,
              class: "q-ml-md"
            }, null, 8, ["disable", "onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_q_btn, {
          icon: "chevron_left",
          color: "grey-8",
          round: "",
          dense: "",
          flat: "",
          disable: scope.isFirstPage,
          onClick: scope.prevPage
        }, null, 8, ["disable", "onClick"]),
        _createVNode(_component_q_btn, {
          icon: "chevron_right",
          color: "grey-8",
          round: "",
          dense: "",
          flat: "",
          disable: scope.isLastPage,
          onClick: scope.nextPage
        }, null, 8, ["disable", "onClick"]),
        (scope.pagesNumber > 2)
          ? (_openBlock(), _createBlock(_component_q_btn, {
              key: 1,
              icon: "last_page",
              color: "grey-8",
              round: "",
              dense: "",
              flat: "",
              disable: scope.isLastPage,
              onClick: scope.lastPage
            }, null, 8, ["disable", "onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["columns", "rows", "loading", "onRequest", "pagination"]),
    _createVNode(_component_q_inner_loading, {
      showing: loader.value,
      color: "grey",
      label: `Подождите, страница скоро откроется`
    }, null, 8, ["showing"])
  ]))
}
}

})