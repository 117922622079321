// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "message_class.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { MessageClassService } from "./message_class";
import type { RemoveMessageClassResponse } from "./message_class";
import type { RemoveMessageClassRequest } from "./message_class";
import type { SaveMessageClassResponse } from "./message_class";
import type { SaveMessageClassRequest } from "./message_class";
import type { DeleteMessageClassResponse } from "./message_class";
import type { DeleteMessageClassRequest } from "./message_class";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { CreateMessageClassResponse } from "./message_class";
import type { CreateMessageClassRequest } from "./message_class";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.MessageClassService
 */
export interface IMessageClassServiceClient {
    /**
     * @generated from protobuf rpc: CreateMessageClass(proto.nigmamsg.CreateMessageClassRequest) returns (proto.nigmamsg.CreateMessageClassResponse);
     */
    createMessageClass(input: CreateMessageClassRequest, options?: RpcOptions): UnaryCall<CreateMessageClassRequest, CreateMessageClassResponse>;
    /**
     * @generated from protobuf rpc: DeleteMessageClass(proto.nigmamsg.DeleteMessageClassRequest) returns (proto.nigmamsg.DeleteMessageClassResponse);
     */
    deleteMessageClass(input: DeleteMessageClassRequest, options?: RpcOptions): UnaryCall<DeleteMessageClassRequest, DeleteMessageClassResponse>;
    /**
     * @generated from protobuf rpc: SaveMessageClass(proto.nigmamsg.SaveMessageClassRequest) returns (proto.nigmamsg.SaveMessageClassResponse);
     */
    saveMessageClass(input: SaveMessageClassRequest, options?: RpcOptions): UnaryCall<SaveMessageClassRequest, SaveMessageClassResponse>;
    /**
     * @generated from protobuf rpc: RemoveMessageClass(proto.nigmamsg.RemoveMessageClassRequest) returns (proto.nigmamsg.RemoveMessageClassResponse);
     */
    removeMessageClass(input: RemoveMessageClassRequest, options?: RpcOptions): UnaryCall<RemoveMessageClassRequest, RemoveMessageClassResponse>;
}
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.MessageClassService
 */
export class MessageClassServiceClient implements IMessageClassServiceClient, ServiceInfo {
    typeName = MessageClassService.typeName;
    methods = MessageClassService.methods;
    options = MessageClassService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: CreateMessageClass(proto.nigmamsg.CreateMessageClassRequest) returns (proto.nigmamsg.CreateMessageClassResponse);
     */
    createMessageClass(input: CreateMessageClassRequest, options?: RpcOptions): UnaryCall<CreateMessageClassRequest, CreateMessageClassResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateMessageClassRequest, CreateMessageClassResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteMessageClass(proto.nigmamsg.DeleteMessageClassRequest) returns (proto.nigmamsg.DeleteMessageClassResponse);
     */
    deleteMessageClass(input: DeleteMessageClassRequest, options?: RpcOptions): UnaryCall<DeleteMessageClassRequest, DeleteMessageClassResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteMessageClassRequest, DeleteMessageClassResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SaveMessageClass(proto.nigmamsg.SaveMessageClassRequest) returns (proto.nigmamsg.SaveMessageClassResponse);
     */
    saveMessageClass(input: SaveMessageClassRequest, options?: RpcOptions): UnaryCall<SaveMessageClassRequest, SaveMessageClassResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<SaveMessageClassRequest, SaveMessageClassResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveMessageClass(proto.nigmamsg.RemoveMessageClassRequest) returns (proto.nigmamsg.RemoveMessageClassResponse);
     */
    removeMessageClass(input: RemoveMessageClassRequest, options?: RpcOptions): UnaryCall<RemoveMessageClassRequest, RemoveMessageClassResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<RemoveMessageClassRequest, RemoveMessageClassResponse>("unary", this._transport, method, opt, input);
    }
}
