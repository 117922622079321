import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, isRef as _isRef } from "vue"

const _hoisted_1 = { style: {"margin":"10px 10px 0 10px","height":"calc(100% - 75px)","overflow":"hidden"} }
const _hoisted_2 = {
  class: "row",
  style: {"height":"100%"}
}
const _hoisted_3 = {
  class: "col-12",
  style: {"height":"100%","overflow":"hidden"}
}
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "row justify-center" }

import store from "@/store";
import { computed, onMounted, ref } from "vue";
import ClsAdd from "../cls/ClsAdd.vue";
import ClsEdit from "../cls/ClsEdit.vue";
import ClsDelete from "../cls/ClsDelete.vue";
import { useRouter } from "vue-router";
import { useClassifierApi } from "@/components/modules/polls/services/useClassifierApi";
import { ClassifierModel } from "proto/nigma-message-proto/polls/classifier";
import type { QTableColumn } from "quasar";
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';;
import { usePagination } from "@/composables/usePagination";


export default /*@__PURE__*/_defineComponent({
  __name: 'ClsList',
  setup(__props) {

const { listClassifierRequest } = useClassifierApi();

const $q = useQuasar();
const router = useRouter();

const classifierRows = ref<ClassifierModel[]>([]);
const columns: QTableColumn[] = [
  {
    name: "classifierName",
    label: "Наименование",
    field: "classifierName",
    align: "left",
  },
  {
    name: "classifierDescr",
    label: "Описание",
    field: "classifierDescr",
    align: "left",
  },
  {
    name: "classifierNotes",
    label: "Примечания",
    field: "classifierNotes",
    align: "left",
  },
  {
    name: "action",
    label: "Действие",
    field: "id",
    align: "center",
    headerStyle: "width: 100px",
    style: "width: 40px",
  },
];
const { onTableRequest: onRequest, pagination: classifierPagination } =
  usePagination({
    sortBy: "classifier_name",
    descending: false,
    page: 1,
    rowsPerPage: 25,
    rowsNumber: 0,
  });
const rowsPerPageClassifierOptions = [10, 25, 50, 100];
const filter = ref("");
const search = ref("");
const oldSearch = ref("");
const searchTimeout = ref<ReturnType<typeof setTimeout>>(
  setTimeout(() => {}, 500)
);
const minSearchSymbol = ref(3);
const isActiveModal = ref(false);
const isActiveModalAdd = ref(false);
const isActiveModalEdit = ref(false);
const isActiveModalDelete = ref(false);
const selectedClass = ref<ClassifierModel>({
  classifierId: 0,
  classifierName: "",
  classifierDescr: "",
  classifierNotes: "",
  classifierMultiple: false,
});

const userRole = computed(() => store.getters.getUserAccess);

const hideModal = () => {
  isActiveModal.value = false;
  isActiveModalAdd.value = false;
  isActiveModalEdit.value = false;
  isActiveModalDelete.value = false;
  selectedClass.value = {
    classifierId: 0,
    classifierName: "",
    classifierDescr: "",
    classifierNotes: "",
    classifierMultiple: false,
  };
};

const addCls = () => {
  isActiveModalAdd.value = true;
  isActiveModal.value = true;
};

const editCls = () => {
  isActiveModalEdit.value = true;
  isActiveModal.value = true;
};

const deleteCls = () => {
  isActiveModalDelete.value = true;
  isActiveModal.value = true;
};

const actionRow = (method: any, row: any) => {
  selectedClass.value = row;

  if (method === "list")
    router.push("/nsi/classifiers/tree/" + selectedClass.value.classifierId);
  if (method === "edit") editCls();
  if (method === "delete") deleteCls();
};

const afterAction = (type: string) => {
  loadClassifierItems(true);
  hideModal();

  if (type === "create") {
    $q.notify({
      type: "positive",
      message: "Классификатор успешно создан",
    });
  }

  if (type === "update") {
    $q.notify({
      type: "positive",
      message: "Классификатор успешно изменён",
    });
  }

  if (type === "delete") {
    $q.notify({
      type: "positive",
      message: "Классификатор успешно удалён",
    });
  }
};

const strMarkRedPlus = (search: string, subject: string) => {
  let escapeRegExp = function (str: string) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  };

  return subject.replace(
    new RegExp("(" + escapeRegExp(search) + ")", "gi"),
    "<span style='color: red;'><b>$1</b></span>"
  );
};

const initSearch = () => {
  if (searchTimeout.value) {
    clearTimeout(searchTimeout.value);
  }
  filter.value = "";
  search.value = "";
  oldSearch.value = "";
  searchTimeout.value = setTimeout(() => {}, 500);
};

const onInputSearchControl = (event: any) => {
  if (searchTimeout.value) {
    clearTimeout(searchTimeout.value);
  }

  searchTimeout.value = setTimeout(() => {
    const searchStr: string = event.target.value;

    if (searchStr.length >= minSearchSymbol.value) {
      search.value = searchStr.toLowerCase();

      loadClassifierItems(true);
    } else {
      clearSearch(oldSearch.value.length >= minSearchSymbol.value);
    }

    oldSearch.value = searchStr;
  }, 500);
};

const clearSearch = (isReload: boolean = true) => {
  search.value = "";

  if (isReload) {
    loadClassifierItems(true);
  }
};

const getFilters = () => {
  return search.value.length > 0
    ? {
        items: [
          {
            fieldName: "classifier_id",
            operand: ">",
            value: "0",
          },
          {
            fieldName: "classifier_name",
            operand: "ilike",
            value: search.value,
            or: {
              items: [
                {
                  fieldName: "classifier_descr",
                  operand: "ilike",
                  value: search.value,
                },
                {
                  fieldName: "classifier_notes",
                  operand: "ilike",
                  value: search.value,
                },
              ],
            },
          },
        ],
      }
    : { items: [] };
};

const loadClassifierItems = (isResetPagination: boolean) => {
  if (isResetPagination) {
    classifierPagination.value.page = 1;
    classifierPagination.value.rowsNumber = 0;
  }

  listClassifierRequest({
    filter: getFilters(),
    sort: {
      name: classifierPagination.value.sortBy,
      exp: classifierPagination.value.descending ? "DESC" : "ASC",
    },
    pagination: {
      page: classifierPagination.value.page,
      limit: classifierPagination.value.rowsPerPage,
      pages: 0,
      cnt: classifierPagination.value.rowsNumber,
    },
  }).then((res) => {
    classifierPagination.value.rowsNumber = res.params?.pagination?.cnt || 0;
    classifierRows.value = res.items;
  });
};

const onRequestClassifier = onRequest(() => loadClassifierItems(false));

const loadData = () => {
  hideModal();
  initSearch();
  loadClassifierItems(true);
};

onMounted(loadData);

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card, { style: {"padding":"10px 10px 0 10px","height":"100%","box-shadow":"none"} }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_q_table, {
              flat: "",
              style: {"height":"100%","overflow":"auto"},
              rows: classifierRows.value,
              columns: columns,
              "row-key": "classifierId",
              pagination: _unref(classifierPagination),
              "onUpdate:pagination": _cache[2] || (_cache[2] = ($event: any) => (_isRef(classifierPagination) ? (classifierPagination).value = $event : null)),
              "rows-per-page-options": rowsPerPageClassifierOptions,
              onRequest: _unref(onRequestClassifier)
            }, {
              top: _withCtx(() => [
                ('admin editor'.includes(userRole.value))
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 0,
                      color: "primary",
                      label: "Добавить",
                      icon: "mdi-plus",
                      onClick: addCls
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_q_space),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    modelValue: filter.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filter).value = $event)),
                    filled: "",
                    onKeyup: _cache[1] || (_cache[1] = ($event: any) => (onInputSearchControl($event)))
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_q_icon, { name: "mdi-magnify" })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ])
              ]),
              "body-cell-classifierName": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    (search.value.length)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          innerHTML: strMarkRedPlus(search.value, props.value)
                        }, null, 8, _hoisted_5))
                      : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(props.value), 1))
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-classifierDescr": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    (search.value.length)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          innerHTML: strMarkRedPlus(search.value, props.value)
                        }, null, 8, _hoisted_7))
                      : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(props.value), 1))
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-classifierNotes": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    (search.value.length)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          innerHTML: strMarkRedPlus(search.value, props.value)
                        }, null, 8, _hoisted_9))
                      : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(props.value), 1))
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-action": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_q_btn, {
                        flat: "",
                        dense: "",
                        round: "",
                        icon: "mdi-dots-vertical"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_menu, { "auto-close": "" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_list, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_item, {
                                    clickable: "",
                                    onClick: ($event: any) => (actionRow('list', props.row))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_item_section, null, {
                                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                                          _createTextVNode("Содержимое классификатора")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]),
                                  ('admin editor'.includes(userRole.value))
                                    ? (_openBlock(), _createBlock(_component_q_item, {
                                        key: 0,
                                        clickable: "",
                                        onClick: ($event: any) => (actionRow('edit', props.row))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_item_section, null, {
                                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                                              _createTextVNode("Редактировать")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"]))
                                    : _createCommentVNode("", true),
                                  ('admin editor'.includes(userRole.value))
                                    ? (_openBlock(), _createBlock(_component_q_item, {
                                        key: 1,
                                        clickable: "",
                                        onClick: ($event: any) => (actionRow('delete', props.row)),
                                        disable: props.row.classessCnt > 0
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_item_section, null, {
                                            default: _withCtx(() => _cache[6] || (_cache[6] = [
                                              _createTextVNode("Удалить")
                                            ])),
                                            _: 1
                                          }),
                                          (props.row.classessCnt > 0)
                                            ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                                                default: _withCtx(() => _cache[7] || (_cache[7] = [
                                                  _createElementVNode("div", { class: "text-subtitle2" }, " Нельзя удалить классификатор, содержащий классы ", -1)
                                                ])),
                                                _: 1
                                              }))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick", "disable"]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "no-data": _withCtx(() => _cache[8] || (_cache[8] = [
                _createElementVNode("div", { class: "col-12 text-center" }, "Нет данных", -1)
              ])),
              _: 1
            }, 8, ["rows", "pagination", "onRequest"])
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_q_dialog, {
      modelValue: isActiveModal.value,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((isActiveModal).value = $event)),
      persistent: ""
    }, {
      default: _withCtx(() => [
        (isActiveModalAdd.value)
          ? (_openBlock(), _createBlock(ClsAdd, {
              key: 0,
              onAfterAction: afterAction,
              onHideModal: hideModal
            }))
          : _createCommentVNode("", true),
        (isActiveModalEdit.value)
          ? (_openBlock(), _createBlock(ClsEdit, {
              key: 1,
              classModel: selectedClass.value,
              onAfterAction: afterAction,
              onHideModal: hideModal
            }, null, 8, ["classModel"]))
          : _createCommentVNode("", true),
        (isActiveModalDelete.value)
          ? (_openBlock(), _createBlock(ClsDelete, {
              key: 2,
              classModel: selectedClass.value,
              onAfterAction: afterAction,
              onHideModal: hideModal
            }, null, 8, ["classModel"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})