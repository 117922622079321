import script from "./QuestionMessages.vue?vue&type=script&setup=true&lang=ts"
export * from "./QuestionMessages.vue?vue&type=script&setup=true&lang=ts"

import "./QuestionMessages.vue?vue&type=style&index=0&id=2885f869&lang=sass"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QBtn,QTooltip,QSelect,QTable,QTd,QCardSection,QItemLabel,QDialog,QInnerLoading});
