import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { MessageGrpModel } from "@/proto/nigma-message-proto/polls/message_grp";
import { onMounted, ref } from "vue";
import { useMessageGrpApi } from "../../services/useMessageGrpApi";
import { useAlert } from "@/composables/useAlert";

interface MessageFormSingleProps {
  message: MessageGrpModel;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MessageFormSingle',
  props: {
    message: {}
  },
  emits: ["hideModal", "afterActionSplitSingle"],
  setup(__props: any, { emit: __emit }) {

const { splitMessageGrpRequest, updateTextMessageGrpRequest } =
  useMessageGrpApi();
const { Alert } = useAlert();

const props = __props;
const emit = __emit;

const item = ref<MessageGrpModel & { separator: string }>({
  messageGrpId: 0,
  messageGrpParentId: 0,
  messageGrpText: "",
  messageGrpCount: 0,
  messageGrpProcessed: false,
  messageGrpSum: 0,
  similarity: 0,
  hasChilds: false,
  separator: "",
});

const splitMessage = () => {
  if (!item.value.messageGrpText) {
    Alert("Текст ответа не введен", "negative");
  }

  if (!item.value.separator) {
    Alert("Строка (символ) для разбивки не введен", "negative");
  }

  if (item.value.messageGrpText.indexOf(item.value.separator) === -1) {
    Alert(
      "Строка (символ) для разбивки отсутствует в тексте ответа",
      "negative"
    );
  }

  if (item.value.messageGrpText != props.message.messageGrpText) {
    updateTextMessageGrpRequest({
      messageGrpId: item.value.messageGrpId,
      messageGrpText: item.value.messageGrpText,
    }).then(actualSplitMessage);
  } else {
    actualSplitMessage();
  }
};

const actualSplitMessage = () => {
  splitMessageGrpRequest({
    splitString: item.value?.separator || "",
    splittedIds: [item.value?.messageGrpId || 0],
  }).then(() => emit("afterActionSplitSingle"));
};

const closeSplitFormSingle = () => emit("hideModal");

const loadData = () => {
  item.value = {
    messageGrpId: props.message.messageGrpId,
    messageGrpParentId: props.message.messageGrpParentId,
    messageGrpText: props.message.messageGrpText,
    messageGrpCount: props.message.messageGrpCount,
    messageGrpProcessed: props.message.messageGrpProcessed,
    messageGrpSum: props.message.messageGrpSum,
    similarity: props.message.similarity,
    hasChilds: props.message.hasChilds,
    separator: "#",
  };
};

onMounted(loadData);

return (_ctx: any,_cache: any) => {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    style: {"width":"1024px","max-width":"80vw","height":"640px","max-height":"80vh"},
    class: "q-pa-md"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_label, { class: "text-h5" }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Разбиение ответа")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_separator),
      _createElementVNode("div", null, [
        _createVNode(_component_q_form, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_input, {
                  modelValue: item.value.messageGrpId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((item.value.messageGrpId) = $event)),
                  readonly: "",
                  label: "Код ответа"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_input, {
                  type: "textarea",
                  modelValue: item.value.messageGrpText,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((item.value.messageGrpText) = $event)),
                  label: "Текст ответа",
                  maxlength: "1024"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_input, {
                  modelValue: item.value.separator,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((item.value.separator) = $event)),
                  label: "Строка(символ) для разбивки",
                  maxlength: "32"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_q_separator),
        _createVNode(_component_q_card_actions, { align: "right" }, {
          default: _withCtx(() => [
            _createVNode(_component_q_btn, {
              color: "primary",
              onClick: splitMessage
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" Применить ")
              ])),
              _: 1
            }),
            _createVNode(_component_q_btn, {
              color: "primary",
              onClick: closeSplitFormSingle
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" Отмена ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}
}

})