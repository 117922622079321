import {useGrpcClientWithLogger} from "@/components/modules/rec-system/services/UserGroups/middleware";
import {ClassTagServiceClient} from "proto/nigma-message-proto/polls/class_tag.client";
import {
    ListClassTagRequest,
    ItemClassTagRequest,
    CreateClassTagRequest,
    UpdateClassTagRequest,
    DeleteClassTagRequest,
    SaveClassTagRequest,
} from "proto/nigma-message-proto/polls/class_tag"

export const useClassTagApi = () => {
    const client = useGrpcClientWithLogger().create(ClassTagServiceClient, {baseUrl: window.VUE_APP_GATEWAY_URL})

    const metadata = () => {
        return {
            Authorization: localStorage.getItem('access-key') || ''
        }
    }

    return {
        listClassTagRequest: (request: ListClassTagRequest) => {
            return client.listClassTag(request, {meta: metadata()}).response
        },
        itemClassTagRequest: (request: ItemClassTagRequest) => {
            return client.itemClassTag(request, {meta: metadata()}).response
        },
        createClassTagRequest: (request: CreateClassTagRequest) => {
            return client.createClassTag(request, {meta: metadata()}).response
        },
        updateClassTagRequest: (request: UpdateClassTagRequest) => {
            return client.updateClassTag(request, {meta: metadata()}).response
        },
        deleteClassTagRequest: (request: DeleteClassTagRequest) => {
            return client.deleteClassTag(request, {meta: metadata()}).response
        },
        saveClassTagRequest: (request: SaveClassTagRequest) => {
            return client.saveClassTag(request, {meta: metadata()}).response
        },
    }
}