import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "q-pa-md col-12 items-start q-gutter-md",
  style: {"height":"calc(100% - 140px)"}
}
const _hoisted_2 = { class: "self-center full-width no-outline" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "text-subtitle2" }
const _hoisted_5 = {
  class: "col-12",
  style: {"widt":"100%","height":"calc(100% - 160px)","overflow":"hidden"}
}
const _hoisted_6 = {
  style: {"width":"calc(100% - 10px)","height":"100%","margin-right":"10px","overflow":"auto","border":"1px solid black"},
  class: "custom-tree"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_field = _resolveComponent("q-field")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_DataLoading = _resolveComponent("DataLoading")!
  const _component_q_tree = _resolveComponent("q-tree")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_card, {
    style: {"width":"1024px","max-width":"80vw","height":"1024px","max-height":"80vh"},
    class: "q-pa-md"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_label, { class: "text-h5" }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Создание Слова")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_separator),
      (!_ctx.isWordsBusy)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_q_card, { style: {"height":"100%"} }, {
              default: _withCtx(() => [
                _createVNode(_component_q_form, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_card_section, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_input, {
                          modelValue: _ctx.item.text,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.text) = $event)),
                          label: "Текст слова"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_card_section, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_field, {
                          filled: "",
                          "model-value": _ctx.item.parent_id,
                          label: "Основное слово"
                        }, {
                          prepend: _withCtx(() => [
                            _createVNode(_component_q_btn, {
                              "background-color": "gray-12",
                              onClick: _ctx.changeParent
                            }, {
                              default: _withCtx(() => _cache[4] || (_cache[4] = [
                                _createTextVNode(" Изменить ")
                              ])),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          control: _withCtx(() => [
                            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.item.parentText), 1)
                          ]),
                          _: 1
                        }, 8, ["model-value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_q_separator),
            _createVNode(_component_q_card_actions, { align: "right" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  color: "primary",
                  onClick: _ctx.saveWord
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" Сохранить ")
                  ])),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_q_btn, {
                  color: "primary",
                  onClick: _ctx.closeWordAdd
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode(" Отмена ")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_DataLoading, { msgLoading: "Сохранение" })
          ])),
      _createVNode(_component_q_dialog, {
        modelValue: _ctx.isActiveModal,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isActiveModal) = $event)),
        persistent: ""
      }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_q_card, {
            style: {"width":"1024px","height":"1024px","max-width":"80vw","max-height":"80vh"},
            class: "q-pa-md"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, { class: "text-h5" }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createTextVNode("Выбор основного слова")
                        ])),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_4, " " + _toDisplayString(_ctx.selectedText) + " ", 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_q_separator),
              _withDirectives(_createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_q_tree, {
                    ref: "wordsParentTree",
                    nodes: _ctx.getWordsTreeFormItems,
                    "node-key": "id",
                    "label-key": "text",
                    selected: _ctx.selectedNode,
                    "onUpdate:selected": [
                      _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedNode) = $event)),
                      _ctx.selectNode
                    ]
                  }, null, 8, ["nodes", "selected", "onUpdate:selected"])
                ])
              ], 512), [
                [_vShow, !_ctx.isWordsTreeFormBusy]
              ]),
              _createVNode(_component_q_separator),
              _createVNode(_component_q_card_actions, { align: "right" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    disable: _ctx.selectedNode === null,
                    color: "primary",
                    onClick: _ctx.selectParent
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode(" Выбрать ")
                    ])),
                    _: 1
                  }, 8, ["disable", "onClick"]),
                  _createVNode(_component_q_btn, {
                    color: "primary",
                    onClick: _ctx.hideModal
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode(" Отмена ")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512), [
            [_vShow, _ctx.isActiveModalTree]
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}