import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-subtitle2" }

import BaseModalCard from "@/components/Base/BaseModalCard.vue";
import {useClassGroupApi} from "@/components/modules/polls/services/useClassGroupApi";
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';;

interface DeleteClassGroupProps {
  id: number,
  name: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DeleteClassGroupModal',
  props: {
    id: {},
    name: {}
  },
  emits: ['hideModal', 'afterDelete'],
  setup(__props: any, { emit: __emit }) {

const { deleteClassGroupRequest } = useClassGroupApi()

const props = __props
const emit = __emit

const $q = useQuasar()

const deleteClassGroup = () => {
  deleteClassGroupRequest({
    classGroupId: props.id
  })
      .then(() => {
        $q.notify({
          type: 'positive',
          message: 'Группа классов успешно удалена'
        })

        emit('hideModal', true)
        emit('afterDelete')
      })
      .catch(() => {
        $q.notify({
          type: 'negative',
          message: 'Что-то пошло не так, попробуйте еще раз'
        })
      })
}

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(BaseModalCard, {
    onClose: _cache[1] || (_cache[1] = ($event: any) => (emit('hideModal')))
  }, {
    header: _withCtx(() => _cache[2] || (_cache[2] = [
      _createElementVNode("div", { class: "text-h5" }, "Удаление группы классов", -1)
    ])),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, "Вы действительно хотите удалить группу классов " + _toDisplayString(props.name) + "?", 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_q_btn, {
        color: "secondary",
        onClick: deleteClassGroup
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("Удалить")
        ])),
        _: 1
      }),
      _createVNode(_component_q_btn, {
        color: "red-5",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('hideModal')))
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode("Отмена")
        ])),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})