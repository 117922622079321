import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pa-sm" }
const _hoisted_2 = { class: "row items-center justify-between full-width" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = {
  class: "row no-wrap",
  style: {"width":"50%"}
}
const _hoisted_5 = { class: "relative-position q-mt-md" }

import {onMounted, ref} from "vue";
import {useClassApi} from "@/components/modules/polls/services/useClassApi";
import {useRoute} from "vue-router";
import {ClassModel} from "proto/nigma-message-proto/polls/class";
import ClassAdd from "@/components/modules/polls/components/class/ClassAdd.vue";
import ClassEdit from "@/components/modules/polls/components/class/ClassEdit.vue";
import {TreeNode, useTreeConverter} from "@/composables/useTreeConverter";
import {ClassTagModel} from "proto/nigma-message-proto/polls/class_tag";
import {useClassTagApi} from "@/components/modules/polls/services/useClassTagApi";
import ClassDelete from "@/components/modules/polls/components/class/ClassDelete.vue";
import {useClassNotTagApi} from "@/components/modules/polls/services/useClassNotTagApi";
import {ClassNotTagModel} from "proto/nigma-message-proto/polls/class_not_tag";


export default /*@__PURE__*/_defineComponent({
  __name: 'ClassView',
  setup(__props) {

const { listClassRequest } = useClassApi()
const { listClassTagRequest } = useClassTagApi()
const { listClassNotTag } = useClassNotTagApi()
const { treeConverter, getNodeByKey } = useTreeConverter()

const route = useRoute()

const classItem = ref<TreeNode<ClassModel>>()
const classItems = ref<TreeNode<ClassModel>[]>([])
const selectedClass = ref('')
const classTags = ref<ClassTagModel[]>([])
const classNotTags = ref<ClassNotTagModel[]>([])
const classLoader = ref(false)
const classSearch = ref('')
const isActiveModal = ref(false)
const isCreateModal = ref(false)
const isEditModal = ref(false)
const isDeleteModal = ref(false)
const classifierId = ref('0')

const getClassItems = () => {
  classLoader.value = true
  classifierId.value = route.params.id.toString()

  listClassRequest({
    filter: {
      items: [
        {
          fieldName: 'c.classifier_id',
          operand: '=',
          value: classifierId.value
        }
      ]
    },
    sort: {
      name: '',
      exp: ''
    },
    pagination: {
      page: 1,
      limit: 100000,
      pages: 0,
      cnt: 0,
    }
  })
      .then(res => {
        classItems.value = treeConverter(res.items, {id: 'classId', parentId: 'classParentId'})
      })
      .finally(() => classLoader.value = false)
}

const getClassTags = () => {
  listClassTagRequest({
    filter: {
      items: [
        {
          fieldName: 'class_id',
          operand: '=',
          value: classItem.value?.classId.toString() || '0'
        }
      ]
    },
    sort: {
      name: '',
      exp: '',
    },
    pagination: {
      page: 1,
      pages: 0,
      limit: 100000,
      cnt: 0
    }
  })
      .then(res => {
        classTags.value = res.items
      })
}

const getClassNotTags = () => {
  listClassNotTag({
    filter: {
      items: [
        {
          fieldName: 'class_id',
          operand: '=',
          value: classItem.value?.classId.toString() || '0'
        }
      ]
    },
    sort: {
      name: '',
      exp: '',
    },
    pagination: {
      page: 1,
      pages: 0,
      limit: 100000,
      cnt: 0
    }
  })
      .then(res => {
        classNotTags.value = res.items
      })
}

const crudHandler = (type: string) => {
  isActiveModal.value = true

  if (type === 'create') {
    isCreateModal.value = true
  } else {
    isEditModal.value = true
  }
}

const deleteClass = () => {
  isActiveModal.value = true
  isDeleteModal.value = true
}

const afterAction = () => {
  selectedClass.value = ''
  hideModal()
  getClassItems()
}

const selectClass = () => {
  classItem.value = getNodeByKey('classId', Number(selectedClass.value), classItems.value)

  if (selectedClass.value) {
    getClassTags()
    getClassNotTags()
  }
}

const hideModal = () => {
  isActiveModal.value = false
  isCreateModal.value = false
  isEditModal.value = false
  isDeleteModal.value = false
}

onMounted(() => getClassItems())

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_tree = _resolveComponent("q-tree")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card, { class: "q-pa-sm" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_q_btn, {
              label: "Добавить",
              color: "primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (crudHandler('create')))
            }),
            _createVNode(_component_q_btn, {
              label: "Редактировать",
              color: "secondary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (crudHandler('edit'))),
              class: "q-ml-sm",
              disable: !selectedClass.value
            }, {
              default: _withCtx(() => [
                (!selectedClass.value)
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createElementVNode("div", { class: "text-subtitle2" }, "Выберите класс", -1)
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["disable"]),
            _createVNode(_component_q_btn, {
              label: "Удалить",
              color: "red-5",
              onClick: deleteClass,
              class: "q-ml-sm",
              disable: !selectedClass.value || classItem.value?.children.length > 0
            }, {
              default: _withCtx(() => [
                (!selectedClass.value)
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createElementVNode("div", { class: "text-subtitle2" }, "Выберите класс", -1)
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (classItem.value?.children.length > 0)
                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createElementVNode("div", { class: "text-subtitle2" }, "Нельзя удалить класс, имеющий дочерние элементы", -1)
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["disable"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_q_input, {
              filled: "",
              dense: "",
              style: {"width":"100%"},
              modelValue: classSearch.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((classSearch).value = $event)),
              label: "Поиск по названию",
              onKeyup: _withKeys(getClassItems, ["enter"])
            }, {
              prepend: _withCtx(() => [
                _createVNode(_component_q_icon, { name: "mdi-magnify" })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_q_tree, {
            class: "class-crud-tree",
            nodes: classItems.value,
            "node-key": "classId",
            "label-key": "className",
            filter: classSearch.value,
            selected: selectedClass.value,
            "onUpdate:selected": [
              _cache[3] || (_cache[3] = ($event: any) => ((selectedClass).value = $event)),
              selectClass
            ],
            "selected-color": "primary"
          }, null, 8, ["nodes", "filter", "selected"]),
          _createVNode(_component_q_inner_loading, {
            showing: classLoader.value,
            color: "grey",
            style: {"z-index":"100"}
          }, null, 8, ["showing"])
        ]),
        _createVNode(_component_q_dialog, {
          modelValue: isActiveModal.value,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((isActiveModal).value = $event))
        }, {
          default: _withCtx(() => [
            (isCreateModal.value)
              ? (_openBlock(), _createBlock(ClassAdd, {
                  key: 0,
                  "cls-id": Number(classifierId.value),
                  onAfterAction: afterAction,
                  onHideModal: hideModal,
                  "current-node": classItem.value
                }, null, 8, ["cls-id", "current-node"]))
              : _createCommentVNode("", true),
            (isEditModal.value)
              ? (_openBlock(), _createBlock(ClassEdit, {
                  key: 1,
                  "cls-id": Number(classifierId.value),
                  onAfterAction: afterAction,
                  onHideModal: hideModal,
                  "curr-tab": "main",
                  "current-node": classItem.value,
                  "current-tags": classTags.value,
                  "current-not-tags": classNotTags.value
                }, null, 8, ["cls-id", "current-node", "current-tags", "current-not-tags"]))
              : _createCommentVNode("", true),
            (isDeleteModal.value)
              ? (_openBlock(), _createBlock(ClassDelete, {
                  key: 2,
                  onAfterAction: afterAction,
                  onHideModal: hideModal,
                  "current-node": classItem.value
                }, null, 8, ["current-node"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    })
  ]))
}
}

})