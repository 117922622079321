// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "class_group.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Pagination } from "./proto/nigma-common-proto/filter";
import { Sort } from "./proto/nigma-common-proto/filter";
import { Filter } from "./proto/nigma-common-proto/filter";
/**
 * Модель данных
 * Префикс для фильтров cg.
 *
 * @generated from protobuf message proto.nigmamsg.ClassGroupModel
 */
export interface ClassGroupModel {
    /**
     * @generated from protobuf field: int64 class_group_id = 1;
     */
    classGroupId: number;
    /**
     * @generated from protobuf field: string class_group_name = 2;
     */
    classGroupName: string;
    /**
     * @generated from protobuf field: string class_group_alias = 3;
     */
    classGroupAlias: string;
    /**
     * @generated from protobuf field: string class_group_color = 4;
     */
    classGroupColor: string;
    /**
     * @generated from protobuf field: double class_group_ratio = 5;
     */
    classGroupRatio: number;
    /**
     * @generated from protobuf field: string class_group_descr = 6;
     */
    classGroupDescr: string;
    /**
     * @generated from protobuf field: string class_group_notes = 7;
     */
    classGroupNotes: string;
}
/**
 * Входящий запрос для получения списка данных
 *
 * @generated from protobuf message proto.nigmamsg.ListClassGroupRequest
 */
export interface ListClassGroupRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
}
/**
 * Ответ сервера для получения списка данных
 *
 * @generated from protobuf message proto.nigmamsg.ListClassGroupResponse
 */
export interface ListClassGroupResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.ClassGroupModel items = 1;
     */
    items: ClassGroupModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListClassGroupRequest params = 2;
     */
    params?: ListClassGroupRequest;
}
/**
 * Входящий запрос для получения элемента
 *
 * @generated from protobuf message proto.nigmamsg.ItemClassGroupRequest
 */
export interface ItemClassGroupRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
}
/**
 * Ответ сервера для получения элемента
 *
 * @generated from protobuf message proto.nigmamsg.ItemClassGroupResponse
 */
export interface ItemClassGroupResponse {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassGroupModel item = 1;
     */
    item?: ClassGroupModel;
}
/**
 * Входящий запрос для получения списка данных для account_guid
 *
 * @generated from protobuf message proto.nigmamsg.ListClassGroupAccountRequest
 */
export interface ListClassGroupAccountRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
    /**
     * @generated from protobuf field: string account_guid = 4;
     */
    accountGuid: string;
}
/**
 * Ответ сервера для получения списка данных
 *
 * @generated from protobuf message proto.nigmamsg.ListClassGroupAccountResponse
 */
export interface ListClassGroupAccountResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.ClassGroupModel items = 1;
     */
    items: ClassGroupModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListClassGroupAccountRequest params = 2;
     */
    params?: ListClassGroupAccountRequest;
}
/**
 * Входящий запрос для создания элемента
 *
 * @generated from protobuf message proto.nigmamsg.CreateClassGroupRequest
 */
export interface CreateClassGroupRequest {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassGroupModel item = 1;
     */
    item?: ClassGroupModel;
}
/**
 * Ответ сервера для создания элемента
 *
 * @generated from protobuf message proto.nigmamsg.CreateClassGroupResponse
 */
export interface CreateClassGroupResponse {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassGroupModel item = 1;
     */
    item?: ClassGroupModel;
}
/**
 * Входящий запрос для обновления элемента
 *
 * @generated from protobuf message proto.nigmamsg.UpdateClassGroupRequest
 */
export interface UpdateClassGroupRequest {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassGroupModel item = 1;
     */
    item?: ClassGroupModel;
}
/**
 * Ответ сервера для обновления элемента
 *
 * @generated from protobuf message proto.nigmamsg.UpdateClassGroupResponse
 */
export interface UpdateClassGroupResponse {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassGroupModel item = 1;
     */
    item?: ClassGroupModel;
}
/**
 * Входящий запрос для удаления элемента
 *
 * @generated from protobuf message proto.nigmamsg.DeleteClassGroupRequest
 */
export interface DeleteClassGroupRequest {
    /**
     * @generated from protobuf field: int64 class_group_id = 1;
     */
    classGroupId: number;
}
/**
 * Ответ сервера для удаления элемента
 *
 * @generated from protobuf message proto.nigmamsg.DeleteClassGroupResponse
 */
export interface DeleteClassGroupResponse {
    /**
     * @generated from protobuf field: int64 class_group_id = 1;
     */
    classGroupId: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class ClassGroupModel$Type extends MessageType<ClassGroupModel> {
    constructor() {
        super("proto.nigmamsg.ClassGroupModel", [
            { no: 1, name: "class_group_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "class_group_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "class_group_alias", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "class_group_color", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "class_group_ratio", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "class_group_descr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "class_group_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ClassGroupModel>): ClassGroupModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classGroupId = 0;
        message.classGroupName = "";
        message.classGroupAlias = "";
        message.classGroupColor = "";
        message.classGroupRatio = 0;
        message.classGroupDescr = "";
        message.classGroupNotes = "";
        if (value !== undefined)
            reflectionMergePartial<ClassGroupModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClassGroupModel): ClassGroupModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_group_id */ 1:
                    message.classGroupId = reader.int64().toNumber();
                    break;
                case /* string class_group_name */ 2:
                    message.classGroupName = reader.string();
                    break;
                case /* string class_group_alias */ 3:
                    message.classGroupAlias = reader.string();
                    break;
                case /* string class_group_color */ 4:
                    message.classGroupColor = reader.string();
                    break;
                case /* double class_group_ratio */ 5:
                    message.classGroupRatio = reader.double();
                    break;
                case /* string class_group_descr */ 6:
                    message.classGroupDescr = reader.string();
                    break;
                case /* string class_group_notes */ 7:
                    message.classGroupNotes = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ClassGroupModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_group_id = 1; */
        if (message.classGroupId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classGroupId);
        /* string class_group_name = 2; */
        if (message.classGroupName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.classGroupName);
        /* string class_group_alias = 3; */
        if (message.classGroupAlias !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.classGroupAlias);
        /* string class_group_color = 4; */
        if (message.classGroupColor !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.classGroupColor);
        /* double class_group_ratio = 5; */
        if (message.classGroupRatio !== 0)
            writer.tag(5, WireType.Bit64).double(message.classGroupRatio);
        /* string class_group_descr = 6; */
        if (message.classGroupDescr !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.classGroupDescr);
        /* string class_group_notes = 7; */
        if (message.classGroupNotes !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.classGroupNotes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ClassGroupModel
 */
export const ClassGroupModel = new ClassGroupModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListClassGroupRequest$Type extends MessageType<ListClassGroupRequest> {
    constructor() {
        super("proto.nigmamsg.ListClassGroupRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination }
        ]);
    }
    create(value?: PartialMessage<ListClassGroupRequest>): ListClassGroupRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ListClassGroupRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListClassGroupRequest): ListClassGroupRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListClassGroupRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListClassGroupRequest
 */
export const ListClassGroupRequest = new ListClassGroupRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListClassGroupResponse$Type extends MessageType<ListClassGroupResponse> {
    constructor() {
        super("proto.nigmamsg.ListClassGroupResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ClassGroupModel },
            { no: 2, name: "params", kind: "message", T: () => ListClassGroupRequest }
        ]);
    }
    create(value?: PartialMessage<ListClassGroupResponse>): ListClassGroupResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListClassGroupResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListClassGroupResponse): ListClassGroupResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.ClassGroupModel items */ 1:
                    message.items.push(ClassGroupModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListClassGroupRequest params */ 2:
                    message.params = ListClassGroupRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListClassGroupResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.ClassGroupModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            ClassGroupModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListClassGroupRequest params = 2; */
        if (message.params)
            ListClassGroupRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListClassGroupResponse
 */
export const ListClassGroupResponse = new ListClassGroupResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemClassGroupRequest$Type extends MessageType<ItemClassGroupRequest> {
    constructor() {
        super("proto.nigmamsg.ItemClassGroupRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter }
        ]);
    }
    create(value?: PartialMessage<ItemClassGroupRequest>): ItemClassGroupRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ItemClassGroupRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemClassGroupRequest): ItemClassGroupRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ItemClassGroupRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ItemClassGroupRequest
 */
export const ItemClassGroupRequest = new ItemClassGroupRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemClassGroupResponse$Type extends MessageType<ItemClassGroupResponse> {
    constructor() {
        super("proto.nigmamsg.ItemClassGroupResponse", [
            { no: 1, name: "item", kind: "message", T: () => ClassGroupModel }
        ]);
    }
    create(value?: PartialMessage<ItemClassGroupResponse>): ItemClassGroupResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ItemClassGroupResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemClassGroupResponse): ItemClassGroupResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassGroupModel item */ 1:
                    message.item = ClassGroupModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ItemClassGroupResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassGroupModel item = 1; */
        if (message.item)
            ClassGroupModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ItemClassGroupResponse
 */
export const ItemClassGroupResponse = new ItemClassGroupResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListClassGroupAccountRequest$Type extends MessageType<ListClassGroupAccountRequest> {
    constructor() {
        super("proto.nigmamsg.ListClassGroupAccountRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination },
            { no: 4, name: "account_guid", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListClassGroupAccountRequest>): ListClassGroupAccountRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountGuid = "";
        if (value !== undefined)
            reflectionMergePartial<ListClassGroupAccountRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListClassGroupAccountRequest): ListClassGroupAccountRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                case /* string account_guid */ 4:
                    message.accountGuid = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListClassGroupAccountRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string account_guid = 4; */
        if (message.accountGuid !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.accountGuid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListClassGroupAccountRequest
 */
export const ListClassGroupAccountRequest = new ListClassGroupAccountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListClassGroupAccountResponse$Type extends MessageType<ListClassGroupAccountResponse> {
    constructor() {
        super("proto.nigmamsg.ListClassGroupAccountResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ClassGroupModel },
            { no: 2, name: "params", kind: "message", T: () => ListClassGroupAccountRequest }
        ]);
    }
    create(value?: PartialMessage<ListClassGroupAccountResponse>): ListClassGroupAccountResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListClassGroupAccountResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListClassGroupAccountResponse): ListClassGroupAccountResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.ClassGroupModel items */ 1:
                    message.items.push(ClassGroupModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListClassGroupAccountRequest params */ 2:
                    message.params = ListClassGroupAccountRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListClassGroupAccountResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.ClassGroupModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            ClassGroupModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListClassGroupAccountRequest params = 2; */
        if (message.params)
            ListClassGroupAccountRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListClassGroupAccountResponse
 */
export const ListClassGroupAccountResponse = new ListClassGroupAccountResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateClassGroupRequest$Type extends MessageType<CreateClassGroupRequest> {
    constructor() {
        super("proto.nigmamsg.CreateClassGroupRequest", [
            { no: 1, name: "item", kind: "message", T: () => ClassGroupModel }
        ]);
    }
    create(value?: PartialMessage<CreateClassGroupRequest>): CreateClassGroupRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateClassGroupRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateClassGroupRequest): CreateClassGroupRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassGroupModel item */ 1:
                    message.item = ClassGroupModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateClassGroupRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassGroupModel item = 1; */
        if (message.item)
            ClassGroupModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.CreateClassGroupRequest
 */
export const CreateClassGroupRequest = new CreateClassGroupRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateClassGroupResponse$Type extends MessageType<CreateClassGroupResponse> {
    constructor() {
        super("proto.nigmamsg.CreateClassGroupResponse", [
            { no: 1, name: "item", kind: "message", T: () => ClassGroupModel }
        ]);
    }
    create(value?: PartialMessage<CreateClassGroupResponse>): CreateClassGroupResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateClassGroupResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateClassGroupResponse): CreateClassGroupResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassGroupModel item */ 1:
                    message.item = ClassGroupModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateClassGroupResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassGroupModel item = 1; */
        if (message.item)
            ClassGroupModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.CreateClassGroupResponse
 */
export const CreateClassGroupResponse = new CreateClassGroupResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateClassGroupRequest$Type extends MessageType<UpdateClassGroupRequest> {
    constructor() {
        super("proto.nigmamsg.UpdateClassGroupRequest", [
            { no: 1, name: "item", kind: "message", T: () => ClassGroupModel }
        ]);
    }
    create(value?: PartialMessage<UpdateClassGroupRequest>): UpdateClassGroupRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateClassGroupRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateClassGroupRequest): UpdateClassGroupRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassGroupModel item */ 1:
                    message.item = ClassGroupModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateClassGroupRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassGroupModel item = 1; */
        if (message.item)
            ClassGroupModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.UpdateClassGroupRequest
 */
export const UpdateClassGroupRequest = new UpdateClassGroupRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateClassGroupResponse$Type extends MessageType<UpdateClassGroupResponse> {
    constructor() {
        super("proto.nigmamsg.UpdateClassGroupResponse", [
            { no: 1, name: "item", kind: "message", T: () => ClassGroupModel }
        ]);
    }
    create(value?: PartialMessage<UpdateClassGroupResponse>): UpdateClassGroupResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateClassGroupResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateClassGroupResponse): UpdateClassGroupResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassGroupModel item */ 1:
                    message.item = ClassGroupModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateClassGroupResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassGroupModel item = 1; */
        if (message.item)
            ClassGroupModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.UpdateClassGroupResponse
 */
export const UpdateClassGroupResponse = new UpdateClassGroupResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteClassGroupRequest$Type extends MessageType<DeleteClassGroupRequest> {
    constructor() {
        super("proto.nigmamsg.DeleteClassGroupRequest", [
            { no: 1, name: "class_group_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteClassGroupRequest>): DeleteClassGroupRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classGroupId = 0;
        if (value !== undefined)
            reflectionMergePartial<DeleteClassGroupRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteClassGroupRequest): DeleteClassGroupRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_group_id */ 1:
                    message.classGroupId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteClassGroupRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_group_id = 1; */
        if (message.classGroupId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classGroupId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.DeleteClassGroupRequest
 */
export const DeleteClassGroupRequest = new DeleteClassGroupRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteClassGroupResponse$Type extends MessageType<DeleteClassGroupResponse> {
    constructor() {
        super("proto.nigmamsg.DeleteClassGroupResponse", [
            { no: 1, name: "class_group_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteClassGroupResponse>): DeleteClassGroupResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classGroupId = 0;
        if (value !== undefined)
            reflectionMergePartial<DeleteClassGroupResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteClassGroupResponse): DeleteClassGroupResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_group_id */ 1:
                    message.classGroupId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteClassGroupResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_group_id = 1; */
        if (message.classGroupId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classGroupId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.DeleteClassGroupResponse
 */
export const DeleteClassGroupResponse = new DeleteClassGroupResponse$Type();
/**
 * @generated ServiceType for protobuf service proto.nigmamsg.ClassGroupService
 */
export const ClassGroupService = new ServiceType("proto.nigmamsg.ClassGroupService", [
    { name: "ListClassGroup", options: {}, I: ListClassGroupRequest, O: ListClassGroupResponse },
    { name: "ItemClassGroup", options: {}, I: ItemClassGroupRequest, O: ItemClassGroupResponse },
    { name: "ListClassGroupAccount", options: {}, I: ListClassGroupAccountRequest, O: ListClassGroupAccountResponse },
    { name: "CreateClassGroup", options: {}, I: CreateClassGroupRequest, O: CreateClassGroupResponse },
    { name: "UpdateClassGroup", options: {}, I: UpdateClassGroupRequest, O: UpdateClassGroupResponse },
    { name: "DeleteClassGroup", options: {}, I: DeleteClassGroupRequest, O: DeleteClassGroupResponse }
]);
