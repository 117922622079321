import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, unref as _unref } from "vue"

const _hoisted_1 = { class: "sql-builder" }
const _hoisted_2 = { class: "sql-builder__constructor" }
const _hoisted_3 = { key: 3 }
const _hoisted_4 = { class: "sql-builder__constructor--navigation-narrow" }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { class: "sql-builder__constructor--tabs" }
const _hoisted_8 = { class: "sql-builder__constructor--right" }
const _hoisted_9 = { class: "sql-builder__constructor--column" }
const _hoisted_10 = { class: "sql-builder__constructor--header" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "sql-builder__constructor--query" }
const _hoisted_13 = { class: "sql-builder__constructor--form-navigation" }
const _hoisted_14 = { class: "sql-builder__constructor--columns" }
const _hoisted_15 = {
  key: 0,
  class: "sql-builder__constructor--result"
}
const _hoisted_16 = {
  key: 1,
  class: "sql-builder__constructor--save"
}
const _hoisted_17 = {
  key: 2,
  class: "sql-builder__constructor--request"
}

import { onMounted, computed, ref, ComputedRef } from 'vue';
import store from '@/store';
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';import uid from 'quasar/src/utils/uid/uid.js';;
import type { QInput } from 'quasar';
import { SourceApi } from '../../services/api/SourceApi';
import { SqlBuilderApi, SourceTreeChildren, QbuilderItem } from '../../services/api/SqlBuilderApi';
import { Source } from '../../proto/pb/source_pb';
import { VueFlow, useVueFlow, Connection, Position, Handle, } from '@vue-flow/core'

import QueryResult from '../../components/Tab/QueryResult.vue'
import QueryRequest from '../../components/Tab/QueryRequest.vue'
import SaveModal from '../../components/Tab/SaveModal.vue'
import SavedQueryList from '../../components/SavedQueryList.vue'
import FormQuery from '../../components/Tab/FormQuery.vue'

type SourceFieldType = {
  sourceId: number;
  sourceSchema: string;
  sourceTable: string;
  sourceDescription: string;
  sourceFieldsChecksum: string;
  isCustomView: boolean;
};

export type СonstructorForm = {
  name: string;
  label: string;
  type: string;
  sort: string;
  alias: string;
  operand: string;
  table: string;
  tableName: string;
  value: string | number;
  selected: boolean;
  group: string | null;
  tableId: string;
  logicalOperator: string
};


type SourceTree = {
  id: string;
  schema: string;
  table: string;
  label?: string;
  header: string;
  checkSum: string;
  isCustomView: boolean;
  children: SourceTreeChildren[]
}

type Edge = {
  id: string;
  source: string;
  target: string;
  type: string;
  style: object;
  targetHandle: string;
  sourceHandle: string;
  leftSchema: string,
  leftTable: string,
  leftTableAlias: string,
  leftTableField: string,
  rightSchema: string,
  rightTable: string,
  rightTableAlias: string,
  rightTableField: string,
  rightTableParams: string,
}

export type FlowSource = {
  id: string,
  type: string,
  position: { x: number, y: number },
  style: { width: string, height: string },
  connectable: boolean,
  header: string,
  children: Array<SourceTreeChildren>,
  data: {
    label: string,
    schema: string,
    table: string,
    sourceId: string,
    checkSum: string,
    children: Array<SourceTreeChildren>,
    selected: boolean
  }
}

type BuilderResult = {
  name?: string,
  label: string,
  sLabel: string,
  list: Array<{
    id: number | string,
    item: string | number
  }>
}

export type BuilderTab = {
  uid: string,
  name: string,
  nameRef: QInput | null,
  description: string,
  descriptionRef: QInput | null,
  limit: string,
  grouping: boolean,
  isSaved: boolean,
  flow: FlowSource[],
  edges: Edge[],
  query: СonstructorForm[],
  isLoading: boolean,
  result: BuilderResult[] | null,
  showResult: boolean,
  exectAll: boolean,
  showSave: boolean,
  showRequest: boolean,
  request: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SqlQueryBuilder',
  setup(__props) {

const sourceApi = new SourceApi();
const sqlBuilderApi = new SqlBuilderApi();

const { onNodesChange, onEdgesChange, onNodeDrag } = useVueFlow()
const $q = useQuasar()

const notify = (message: string, type: string) => {
  return $q.notify({
    message: message,
    color: type,
    progress: true,
    actions: [
      { icon: 'close', color: 'white', round: true, handler: () => { } }
    ]
  })
}

const isSourceFieldListLoading = ref(false);
const isSqlBuilderListVisible = ref(false);
const sources = ref<Source[]>([]);
const sourcesTree = ref<SourceTree[]>([])
const qBuilderResult = ref<BuilderResult[]>([])
const resultModal = ref(false);
const sqlSaveModal = ref(false);
let counter = 1;
const leftPanelOpen = ref(true)
const sourceLoading = ref(true)

const builderTabs = ref<BuilderTab[]>([
  {
    uid: '84cd999c-ea7f-46a3-a459-92e27d949c94',
    name: 'Без названия',
    nameRef: null,
    description: '',
    descriptionRef: null,
    limit: '',
    grouping: false,
    isSaved: false,
    flow: [],
    edges: [],
    query: [],
    isLoading: false,
    result: null,
    showResult: false,
    exectAll: true,
    showSave: false,
    showRequest: false,
    request: ''
  }
])

const builderTab = ref(builderTabs.value[0].uid)

const isSourceSelected = computed(() => {
  if (activeTab.value.flow.length === 0) {
    notify('Добавьте источник данных на форму', 'red')
    return true
  }

  return false
})

const validateForm = computed(() => {

  if (activeTab.value.flow.length === 0) {
    notify('Добавьте источник данных на форму', 'red')
    return true
  }

  activeTab.value.nameRef?.validate()
  activeTab.value.descriptionRef?.validate()

  if (activeTab.value.nameRef?.hasError && activeTab.value.descriptionRef?.hasError) {
    notify('Укажите название и описание для запроса', 'red')
    return true
  }
  if (activeTab.value.nameRef?.hasError) {
    notify('Укажите название для запроса', 'red')
    return true
  }
  if (activeTab.value.descriptionRef?.hasError) {
    notify('Укажите описание для запроса', 'red')
    return true
  }

  return false
})

const reqItem = computed(() => {
  const fields = {
    flow: activeTab.value.flow,
    edges: activeTab.value.edges,
    form: activeTab.value.query,
    grouping: activeTab.value.grouping
  }

  let queryEmpty = true
  const item: QbuilderItem = {
    id: 0,
    name: activeTab.value.name === '' ? 'Без названия' : activeTab.value.name,
    authorId: +store.getters.getUserId!,
    sourceId: 0,
    description: activeTab.value.description === '' ? 'Без описания' : activeTab.value.description,
    select: [],
    where: [],
    sort: [],
    group: [],
    join: activeTab.value.edges,
    fields: JSON.stringify(fields),
    limit: {
      limit: +activeTab.value.limit,
      offset: 0,
    },
    isSaved: activeTab.value.isSaved,
    exectAll: activeTab.value.exectAll
  }

  activeTab.value.flow.forEach((source: FlowSource, idx: number) => {
    console.log(activeTab.value.flow)
    if (activeTab.value.edges.length !== 0) {
      const tableIdx = activeTab.value.flow.findIndex((table: QbuilderItem) => table.id === activeTab.value.edges[0].source)
      const joinFrom = activeTab.value.flow[tableIdx]
      item.sourceId = +joinFrom.data.sourceId
      item.from = {
        schema: joinFrom.data.schema,
        table: joinFrom.data.table,
        tableParams: JSON.stringify(joinFrom.data),
        alias: joinFrom.children[0].table,
        checkSum: joinFrom.data.checkSum
      }
    } else if (idx === 0) {
      item.sourceId = +source.data.sourceId
      item.from = {
        schema: source.data.schema,
        table: source.data.table,
        tableParams: JSON.stringify(source.data),
        alias: source.children[0].table,
        checkSum: source.data.checkSum
      }
    }
  })

  activeTab.value.query.forEach((column: СonstructorForm) => {
    const colItem = JSON.parse(JSON.stringify(column))

    if (colItem && colItem.selected) {
      queryEmpty = false
      if (!activeTab.value.grouping) colItem.group = ''
      item.select?.push(colItem)
    }
    if (colItem.operand && colItem.value !== '') {
      const where = {
        table: colItem.table,
        fieldName: colItem.name,
        operand: colItem.operand,
        value: colItem.value.toString(),
        type: colItem.type,
        logicalOperator: colItem.logicalOperator
      }
      item.where?.push(where)
    }
    if (colItem.sort !== '' && colItem.sort !== 'unset') {
      item.sort?.push({
        table: colItem.table ?? '',
        name: colItem.name ?? '',
        exp: colItem.sort ?? '',
        priority: 0
      })
    }
    if (colItem.group && colItem.group !== 'distinct') {
      if (colItem.group === 'group') colItem.group = ''
      item.group?.push({
        table: colItem.table.toString() ?? '',
        byField: colItem.name.toString() ?? ''
      })
    }
  })

  if (queryEmpty) item.select = [...activeTab.value.flow[0].children]

  return item

})

const activeTab: ComputedRef = computed<BuilderTab>(() => {
  const defaultTab = {
    uid: uid(),
    name: 'Без названия',
    nameRef: null,
    description: '',
    descriptionRef: null,
    limit: '',
    grouping: false,
    isSaved: false,
    flow: [],
    edges: [],
    query: [],
    isLoading: false,
    result: null,
    showResult: false,
    exectAll: true,
    showSave: false,
    showRequest: false,
    request: ''
  }
  return builderTabs.value.find(tab => tab.uid === builderTab.value) ?? defaultTab
})

const addTab = () => {
  const id = uid()
  builderTabs.value.push({
    uid: id,
    name: 'Без названия',
    nameRef: null,
    description: '',
    descriptionRef: null,
    limit: '',
    grouping: false,
    isSaved: false,
    flow: [],
    edges: [],
    query: [],
    isLoading: false,
    result: null,
    showResult: false,
    exectAll: true,
    showSave: false,
    showRequest: false,
    request: ''
  })
  builderTab.value = id
}

const closeTab = (id: number) => {
  $q.dialog({
    title: 'Закрыть?',
    message: 'Все не сохраненные действия будут удалены. <br> Вы уверены что хотите закрыть вкладку?',
    html: true,
    ok: {
      push: true,
      color: 'primary'
    },
    cancel: {
      push: true,
      color: 'negative'
    },
    persistent: true
  }).onOk(() => {
    if (builderTabs.value.length === 1) {
      builderTabs.value.splice(id, 1)
      return
    }
    builderTab.value = id === 0 ? builderTabs.value[id + 1].uid : builderTabs.value[id - 1].uid
    builderTabs.value.splice(id, 1)
  })
}

const getSourceFieldsList = (source: SourceFieldType) => {
  isSourceFieldListLoading.value = true;
  sqlSaveModal.value = false;
  sourcesTree.value = []

  sourceApi.listSourceField(source).then((res) => {
    const items = res.getItemsList();

    const childrenList: SourceTreeChildren[] = [];
    let idx = 0;
    items.forEach((item) => {
      childrenList.push({
        idx: idx,
        tableName: source.sourceDescription,
        tableId: '',
        table: source.sourceTable,
        label: source.isCustomView ? item.getFieldName() : item.getFieldDescription(),
        name: item.getFieldName(),
        type: item.getFieldDataType(),
        selected: false,
        alias: source.sourceTable,
        sort: 'unset',
        operand: '',
        group: 'group',
        value: '',
        logicalOperator: '-'
      })
      idx++
    });
    sourcesTree.value.push({
      id: source.sourceId.toString(),
      schema: source.sourceSchema,
      table: source.sourceTable,
      label: source.sourceDescription,
      checkSum: source.sourceFieldsChecksum,
      header: 'title',
      children: childrenList,
      isCustomView: source.isCustomView
    })
    sourceLoading.value = false
  });
};

const getSourceList = () => {
  sourceLoading.value = true
  sources.value = []
  sourceApi.listSource().then((res) => {
    sources.value = res.getItemsList();
    res.getItemsList().forEach((item) => {
      let source = {
        sourceId: item.getSourceId(),
        sourceSchema: item.getSourceSchema(),
        sourceTable: item.getSourceTable(),
        sourceDescription: item.getSourceDescription(),
        sourceFieldsChecksum: item.getSourceFieldsChecksum(),
        isCustomView: item.getSourceTable().includes('view') ? true : false
      };
      getSourceFieldsList(source);
    })
    $q.loading.hide()
  });
};

const deleteSource = (source: number) => {
  $q.dialog({
    title: 'Удалить?',
    message: 'Вы уверены что хотите удалить сохраненный источник данных?',
    html: true,
    ok: {
      push: true,
      color: 'primary'
    },
    cancel: {
      push: true,
      color: 'negative'
    },
    persistent: true
  }).onOk(() => {
    sourceApi.deleteSource(source)
      .then(() => {
        getSourceList()
        notify('Источник данных удален', 'positive')
      })
      .catch(() => {
        getSourceList()
        notify('Возникла ошибка при удалении', 'red')
      })
  })
}

const exportQueryResult = () => {
  $q.loading.show()
  sqlBuilderApi.exportQbuilder(reqItem.value).then((res) => {
    const blob = new Blob([res.getFile()], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    const link = document.createElement('a')
    let date = (new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0].replace('T', '-'))

    link.href = window.URL.createObjectURL(blob)
    link.download = 'sql-builder-' + date + '.xlsx'
    link.click()
    $q.loading.hide()
  });
}

const group = (data: Record<string, string>[]) => {
  const result: BuilderResult[] = []

  const list: Record<string, string[]> = {}

  data.forEach(item => {
    const keys = Object.keys(item)

    keys.forEach(key => {
      if (!list[key]) list[key] = []
    })
  })

  data.forEach(item => {
    const keys = Object.keys(item)
    keys.forEach(key => {
      list[key].push(item[key])
    })
  })

  for (const key in list) {
    result.push({
      name: key.split('/')[1],
      label: key.split('/')[0],
      sLabel: key.split('/')[2],
      list: list[key].map(item => {
        return {
          id: uid(),
          item
        }
      })
    })
  }

  return result
}

const executeQuery = (tab: BuilderTab) => {
  if (isSourceSelected.value) return
  tab.isLoading = true
  sqlBuilderApi.executeQbuilder(reqItem.value).then((res) => {
    const resultList = JSON.parse(res.getQueryResult())

    if (resultList.length === 0) {
      notify('Что-то пошло не так, проверьте запрос', 'red')
      tab.isLoading = false
      return
    }
    tab.result = group(resultList)
    tab.showResult = true
    tab.isLoading = false
  });
}

const getSqlQuery = (tab: BuilderTab) => {
  if (isSourceSelected.value) return
  $q.loading.show()
  sqlBuilderApi.getSqlRequest(reqItem.value).then((res) => {
    tab.request = res.getSqlQuery()
    tab.showRequest = true
    $q.loading.hide()
  })
}

const clearQbuilder = () => {
  activeTab.value.name = 'Без названия'
  activeTab.value.query = []
  activeTab.value.description = ""
  activeTab.value.flow.length = 0
  activeTab.value.edges = []
  activeTab.value.limit = ''
  qBuilderResult.value = []
  resultModal.value = false
};

const addToFlow = (source: SourceTree) => {
  let id = Math.floor(Math.random() * 100).toString() || ''
  source.children.forEach(children => {
    children.table = 't' + counter
    children.tableId = id
  })
  activeTab.value.flow.push({
    uid: activeTab.value.uid,
    id: id,
    type: 'table',
    position: { x: 10, y: 10 },
    style: { width: 'auto', height: 'auto' },
    connectable: true,
    header: 'parent',
    children: JSON.parse(JSON.stringify(source.children)),
    data: {
      label: source.label ?? '',
      schema: source.schema ?? '',
      table: source.table ?? '',
      sourceId: source.id ?? '',
      checkSum: source.checkSum ?? '',
      children: JSON.parse(JSON.stringify(source.children)),
      selected: false
    }
  })
  counter++
}

const onConnect = (params: Connection) => {
  const edge = {
    uid: activeTab.value.uid,
    id: Math.floor(Math.random() * 100000).toString(),
    source: params.source,
    target: params.target,
    type: 'step',
    style: { stroke: '#000' },
    targetHandle: params.targetHandle ?? '',
    sourceHandle: params.sourceHandle ?? '',
    leftSchema: '',
    leftTable: '',
    leftTableAlias: '',
    leftTableField: '',
    rightSchema: '',
    rightTable: '',
    rightTableAlias: '',
    rightTableField: '',
    rightTableParams: ''
  }
  activeTab.value.flow.forEach((source: FlowSource) => {
    if (source.id === edge.source) {
      const tableFieldId = params.sourceHandle ? +params.sourceHandle : 0
      edge.leftSchema = source.data.schema
      edge.leftTable = source.data.table
      edge.leftTableAlias = source.children[0].table
      edge.leftTableField = source.data.children[tableFieldId].name ?? ''
    }
    if (source.id === edge.target) {
      const tableFieldId = params.targetHandle ? +params.targetHandle : 0
      edge.rightSchema = source.data.schema
      edge.rightTable = source.data.table
      edge.rightTableAlias = source.children[0].table
      edge.rightTableField = source.data.children[tableFieldId].name ?? ''
    }
  })
  activeTab.value.edges.push(edge)
}

onNodesChange((changes) => {
  const change = changes[0]

  if (change.type === 'remove') {
    for (let i = activeTab.value.query.length; i--;) {
      if (activeTab.value.query[i].tableId === change.id) activeTab.value.query.splice(i, 1)
    }
    activeTab.value.flow.forEach((source: FlowSource, idx: number) => {
      if (change.id === source.id) activeTab.value.flow.splice(idx, 1)
    })
  }
})

onEdgesChange((changes) => {
  const change = changes[0]
  if (change.type === 'remove') {
    activeTab.value.edges.forEach((edge: Edge, idx: number) => {
      if (change.id === edge.id) activeTab.value.edges.splice(idx, 1)
    })
  }
})

onNodeDrag((changes) => {
  activeTab.value.flow.forEach((source: FlowSource, idx: number) => {
    if (changes.node.id === source.id) {
      activeTab.value.flow[idx].position.x = changes.node.computedPosition.x
      activeTab.value.flow[idx].position.y = changes.node.computedPosition.y
    }
  })
})

const saveQbuilder = () => {
  if (!validateForm.value) activeTab.value.showSave = true
}

const selectAllChildren = (item: SourceTree) => {
  item.children.forEach(child => {
    child.selected = true
    addCol(child)
  })
}

const addCol = (item: SourceTreeChildren) => {
  item.group = 'group'
  item.selected = true
  item.sort = "unset"
  item.operand = '='
  item.alias = item.alias + Math.floor(Math.random() * 100).toString()
  activeTab.value.query.push(JSON.parse(JSON.stringify(item)))
}

const addEmptyCol = () => {
  activeTab.value.query.push({
    name: "",
    label: "",
    type: "",
    sort: "unset",
    alias: "",
    operand: "=",
    table: "",
    tableName: "",
    value: "",
    selected: true,
    group: 'group',
    tableId: '',
    logicalOperator: '-'
  });
};

const deleteCol = (id: number) => {
  activeTab.value.query.splice(id, 1)
}

const setSource = (source: SourceTreeChildren, id: number) => {
  activeTab.value.query[id] = {
    name: source.name ?? '',
    label: source.label ?? '',
    type: source.type ?? '',
    sort: activeTab.value.query[id].sort,
    alias: activeTab.value.query[id].alias + Math.floor(Math.random() * 100).toString(),
    operand: activeTab.value.query[id].operand,
    tableName: source.tableName ?? '',
    table: source.table ?? '',
    value: "",
    selected: false,
    group: 'group',
    tableId: source.tableId,
    logicalOperator: '-'
  }
}

const closeSavedQueryList = (tab: string, visible: boolean) => {
  builderTab.value = tab
  isSqlBuilderListVisible.value = visible
}

const changeSourceNameOnFlow = (source: SourceTree) => {
  $q.dialog({
    title: 'Название источника',
    prompt: {
      model: source.label ?? '',
      type: 'text'
    },
    persistent: true
  }).onOk((data) => {
    source.label = data
  })
}

onMounted(() => {
  getSourceList();
});


return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tree = _resolveComponent("q-tree")!
  const _component_q_skeleton = _resolveComponent("q-skeleton")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[13] || (_cache[13] = _createElementVNode("h2", null, "Конструктор запросов", -1)),
      _createElementVNode("div", {
        class: _normalizeClass([[leftPanelOpen.value ? '' : 'narrow'], "sql-builder__constructor--wrapper"])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([[leftPanelOpen.value ? '' : 'narrow'], "sql-builder__constructor--navigation"])
        }, [
          (leftPanelOpen.value)
            ? (_openBlock(), _createBlock(_component_q_btn, {
                key: 0,
                color: "primary",
                "no-caps": "",
                unelevated: "",
                label: "Создать",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (addTab()))
              }))
            : _createCommentVNode("", true),
          (leftPanelOpen.value)
            ? (_openBlock(), _createBlock(_component_q_btn, {
                key: 1,
                color: "primary",
                "no-caps": "",
                unelevated: "",
                label: "Сохраненные",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (isSqlBuilderListVisible.value = true))
              }))
            : _createCommentVNode("", true),
          (leftPanelOpen.value && !sourceLoading.value)
            ? (_openBlock(), _createBlock(_component_q_tree, {
                key: 2,
                class: "sql-builder__constructor--tree",
                nodes: sourcesTree.value,
                "node-key": "id",
                dense: ""
              }, {
                "header-title": _withCtx((prop) => [
                  _createTextVNode(_toDisplayString(prop.node.label) + " ", 1),
                  (prop.node.isCustomView)
                    ? (_openBlock(), _createBlock(_component_q_btn, {
                        key: 0,
                        class: "sql-builder__constructor--delete-source",
                        color: "red",
                        flat: "",
                        round: "",
                        icon: "delete",
                        onClick: _withModifiers(($event: any) => (deleteSource(prop.node.id)), ["stop"])
                      }, null, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_q_btn, {
                    class: "sql-builder__constructor--add-to-flow",
                    color: "primary",
                    flat: "",
                    round: "",
                    icon: "add_circle",
                    onClick: _withModifiers(($event: any) => (addToFlow(prop.node)), ["stop"])
                  }, null, 8, ["onClick"])
                ]),
                _: 1
              }, 8, ["nodes"]))
            : _createCommentVNode("", true),
          (leftPanelOpen.value && sourceLoading.value)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (item) => {
                  return _createElementVNode("li", { key: item }, [
                    _createVNode(_component_q_skeleton, {
                      height: "33px",
                      square: ""
                    })
                  ])
                }), 64))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            (!leftPanelOpen.value)
              ? (_openBlock(), _createBlock(_component_q_btn, {
                  key: 0,
                  color: "primary",
                  round: "",
                  icon: "add_circle",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (addTab()))
                }))
              : _createCommentVNode("", true),
            (!leftPanelOpen.value)
              ? (_openBlock(), _createBlock(_component_q_btn, {
                  key: 1,
                  color: "primary",
                  round: "",
                  icon: "save",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (isSqlBuilderListVisible.value = true))
                }))
              : _createCommentVNode("", true),
            (!leftPanelOpen.value && !sourceLoading.value)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sourcesTree.value, (source) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: source.id
                    }, [
                      _createElementVNode("p", null, _toDisplayString(source.label !== '' ? source.label : 'Без названия'), 1),
                      (source.isCustomView)
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 0,
                            class: "sql-builder__constructor--delete-source",
                            color: "red",
                            flat: "",
                            round: "",
                            icon: "delete",
                            onClick: _withModifiers(($event: any) => (deleteSource(+source.id)), ["stop"])
                          }, null, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_q_btn, {
                        class: "sql-builder__constructor--add-to-flow",
                        color: "primary",
                        flat: "",
                        round: "",
                        icon: "add_circle",
                        onClick: _withModifiers(($event: any) => (addToFlow(source)), ["stop"])
                      }, null, 8, ["onClick"]),
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(source.label !== '' ? source.label : 'Без названия'), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (!leftPanelOpen.value && sourceLoading.value)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (item) => {
                    return _createElementVNode("li", { key: item }, [
                      _createVNode(_component_q_skeleton, {
                        height: "33px",
                        width: "100%",
                        square: ""
                      })
                    ])
                  }), 64))
                ]))
              : _createCommentVNode("", true)
          ])
        ], 2),
        _createVNode(_component_q_btn, {
          onClick: _cache[4] || (_cache[4] = ($event: any) => (leftPanelOpen.value ? leftPanelOpen.value = false : leftPanelOpen.value = true)),
          class: _normalizeClass([[leftPanelOpen.value ? '' : 'narrow'], "sql-builder__constructor--navigation-resize"]),
          round: "",
          color: "primary",
          icon: "navigate_before"
        }, null, 8, ["class"])
      ], 2),
      _createVNode(_component_q_form, {
        class: _normalizeClass([[leftPanelOpen.value ? '' : 'narrow'], "sql-builder__constructor--form"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, { class: "sql-builder__constructor--flow" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_q_tabs, {
                  modelValue: builderTab.value,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((builderTab).value = $event)),
                  "indicator-color": "transparent",
                  class: "text-white shadow-2",
                  dense: "",
                  align: "left"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(builderTabs.value, (tab, index) => {
                      return (_openBlock(), _createBlock(_component_q_tab, {
                        class: "sql-builder__constructor--tab bg-primary",
                        key: tab.uid,
                        "no-caps": "",
                        name: tab.uid,
                        label: tab.name
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_btn, {
                            flat: "",
                            round: "",
                            size: "xs",
                            color: "primary",
                            "text-color": "white",
                            icon: "close",
                            onClick: _withModifiers(($event: any) => (closeTab(index)), ["stop"])
                          }, null, 8, ["onClick"])
                        ]),
                        _: 2
                      }, 1032, ["name", "label"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _createVNode(_component_q_tab_panels, {
                modelValue: builderTab.value,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((builderTab).value = $event)),
                animated: ""
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(builderTabs.value, (tab) => {
                    return (_openBlock(), _createBlock(_component_q_tab_panel, {
                      key: tab.uid,
                      name: tab.uid
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_unref(VueFlow), {
                            "translate-extent": [[0, 0], [9600, 4800]],
                            nodes: tab.flow,
                            edges: tab.edges,
                            onConnect: onConnect,
                            style: { backgroundColor: '#c9dae9' }
                          }, {
                            "node-table": _withCtx((props) => [
                              _createElementVNode("div", _hoisted_9, [
                                _createElementVNode("div", _hoisted_10, [
                                  _createElementVNode("p", {
                                    class: "sql-builder__constructor--label",
                                    onClick: ($event: any) => (changeSourceNameOnFlow(props.data))
                                  }, _toDisplayString(props.data.label), 9, _hoisted_11),
                                  _createVNode(_component_q_btn, {
                                    flat: "",
                                    round: "",
                                    color: "white",
                                    icon: "add_circle",
                                    onClick: ($event: any) => (selectAllChildren(props.data))
                                  }, null, 8, ["onClick"])
                                ]),
                                _createElementVNode("ul", null, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.data.children, (children) => {
                                    return (_openBlock(), _createElementBlock("li", {
                                      key: children.label
                                    }, [
                                      _createTextVNode(_toDisplayString(children.label) + " ", 1),
                                      _createVNode(_component_q_btn, {
                                        color: "primary",
                                        flat: "",
                                        round: "",
                                        icon: "add_circle",
                                        onClick: ($event: any) => (addCol(children))
                                      }, null, 8, ["onClick"]),
                                      _createVNode(_unref(Handle), {
                                        id: children.idx,
                                        type: "target",
                                        position: _unref(Position).Left
                                      }, null, 8, ["id", "position"]),
                                      _createVNode(_unref(Handle), {
                                        id: children.idx,
                                        type: "source",
                                        position: _unref(Position).Right
                                      }, null, 8, ["id", "position"])
                                    ]))
                                  }), 128))
                                ])
                              ])
                            ]),
                            _: 2
                          }, 1032, ["nodes", "edges"]),
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("div", _hoisted_13, [
                              _createVNode(_component_q_checkbox, {
                                size: "xs",
                                modelValue: tab.grouping,
                                "onUpdate:modelValue": ($event: any) => ((tab.grouping) = $event),
                                label: "Группировать",
                                color: "primary"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                              _createVNode(_component_q_input, {
                                ref_for: true,
                                ref: el => tab.nameRef = el,
                                modelValue: tab.name,
                                "onUpdate:modelValue": ($event: any) => ((tab.name) = $event),
                                rules: [val => (val && val.length > 0)],
                                label: "Навание",
                                dense: "",
                                type: "text",
                                outlined: ""
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "rules"]),
                              _createVNode(_component_q_input, {
                                ref_for: true,
                                ref: el => tab.descriptionRef = el,
                                modelValue: tab.description,
                                "onUpdate:modelValue": ($event: any) => ((tab.description) = $event),
                                rules: [val => (val && val.length > 0)],
                                label: "Описание",
                                dense: "",
                                type: "text",
                                outlined: ""
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "rules"]),
                              _createVNode(_component_q_input, {
                                modelValue: tab.limit,
                                "onUpdate:modelValue": ($event: any) => ((tab.limit) = $event),
                                label: "Лимит",
                                dense: "",
                                type: "number",
                                outlined: ""
                              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                              _createVNode(_component_q_btn, {
                                color: "primary",
                                "no-caps": "",
                                unelevated: "",
                                label: "Очистить",
                                onClick: _cache[6] || (_cache[6] = ($event: any) => (clearQbuilder()))
                              }),
                              _createVNode(_component_q_btn, {
                                color: "primary",
                                "no-caps": "",
                                unelevated: "",
                                label: "SQL",
                                onClick: ($event: any) => (getSqlQuery(tab))
                              }, null, 8, ["onClick"]),
                              _createVNode(_component_q_btn, {
                                color: "primary",
                                "no-caps": "",
                                unelevated: "",
                                label: "Сохранить",
                                onClick: _cache[7] || (_cache[7] = ($event: any) => (saveQbuilder()))
                              }),
                              _createVNode(_component_q_btn, {
                                color: "primary",
                                "no-caps": "",
                                unelevated: "",
                                label: "Выполнить",
                                onClick: ($event: any) => (executeQuery(tab))
                              }, null, 8, ["onClick"])
                            ]),
                            _createElementVNode("div", _hoisted_14, [
                              (tab.query.length > 0)
                                ? (_openBlock(), _createBlock(FormQuery, {
                                    key: 0,
                                    query: tab.query,
                                    "onUpdate:query": ($event: any) => ((tab.query) = $event),
                                    flow: tab.flow,
                                    "onUpdate:flow": ($event: any) => ((tab.flow) = $event),
                                    grouping: tab.grouping,
                                    "onUpdate:grouping": ($event: any) => ((tab.grouping) = $event),
                                    activeTab: activeTab.value,
                                    "onUpdate:activeTab": _cache[8] || (_cache[8] = ($event: any) => ((activeTab).value = $event)),
                                    onDeleteCol: deleteCol,
                                    onSetSource: setSource
                                  }, null, 8, ["query", "onUpdate:query", "flow", "onUpdate:flow", "grouping", "onUpdate:grouping", "activeTab"]))
                                : _createCommentVNode("", true)
                            ]),
                            _createVNode(_component_q_btn, {
                              class: "sql-builder__constructor--add-row",
                              color: "primary",
                              flat: "",
                              round: "",
                              icon: "add_circle",
                              onClick: _cache[9] || (_cache[9] = ($event: any) => (addEmptyCol()))
                            })
                          ]),
                          (tab.showResult)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                _createVNode(QueryResult, {
                                  result: tab.result,
                                  onExportQbuilder: exportQueryResult
                                }, null, 8, ["result"]),
                                _createVNode(_component_q_btn, {
                                  size: "md",
                                  class: "close-button",
                                  flat: "",
                                  round: "",
                                  color: "black",
                                  icon: "close",
                                  onClick: ($event: any) => (tab.showResult = false)
                                }, null, 8, ["onClick"])
                              ]))
                            : _createCommentVNode("", true),
                          (tab.showSave)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                _createVNode(SaveModal, {
                                  onGetSourceList: _cache[10] || (_cache[10] = ($event: any) => (getSourceList())),
                                  reqItem: reqItem.value,
                                  sqlSaveModal: sqlSaveModal.value,
                                  activeTab: activeTab.value,
                                  onNotify: notify
                                }, null, 8, ["reqItem", "sqlSaveModal", "activeTab"]),
                                _createVNode(_component_q_btn, {
                                  size: "md",
                                  class: "close-button",
                                  flat: "",
                                  round: "",
                                  color: "black",
                                  icon: "close",
                                  onClick: ($event: any) => (tab.showSave = false)
                                }, null, 8, ["onClick"])
                              ]))
                            : _createCommentVNode("", true),
                          (tab.showRequest)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                _createVNode(QueryRequest, {
                                  result: tab.request
                                }, null, 8, ["result"]),
                                _createVNode(_component_q_btn, {
                                  size: "md",
                                  class: "close-button",
                                  flat: "",
                                  round: "",
                                  color: "black",
                                  icon: "close",
                                  onClick: ($event: any) => (tab.showRequest = false)
                                }, null, 8, ["onClick"])
                              ]))
                            : _createCommentVNode("", true),
                          _createVNode(_component_q_inner_loading, {
                            showing: tab.isLoading
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_spinner, {
                                color: "primary",
                                size: "3em"
                              })
                            ]),
                            _: 2
                          }, 1032, ["showing"])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["name"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _createVNode(_component_q_dialog, {
      class: "sql-builder__list--modal",
      modelValue: isSqlBuilderListVisible.value,
      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((isSqlBuilderListVisible).value = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(SavedQueryList, {
          builderTabs: builderTabs.value,
          onCloseSavedQueryList: closeSavedQueryList,
          onNotify: notify
        }, null, 8, ["builderTabs"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})