import {useGrpcClientWithLogger} from "@/components/modules/rec-system/services/UserGroups/middleware";
import {MessageClassServiceClient} from "proto/nigma-message-proto/polls/message_class.client";
import {
    CreateMessageClassRequest,
    DeleteMessageClassRequest,
    SaveMessageClassRequest,
    RemoveMessageClassRequest,
} from "proto/nigma-message-proto/polls/message_class"

export const useMessageClassApi = () => {
    const client = useGrpcClientWithLogger().create(MessageClassServiceClient, {baseUrl: window.VUE_APP_GATEWAY_URL})

    const metadata = () => {
        return {
            Authorization: localStorage.getItem('access-key') || ''
        }
    }

    return {
        createMessageClassRequest: (request: CreateMessageClassRequest) => {
            return client.createMessageClass(request, {meta: metadata()}).response
        },
        deleteMessageClassRequest: (request: DeleteMessageClassRequest) => {
            return client.deleteMessageClass(request, {meta: metadata()}).response
        },
        saveMessageClassRequest: (request: SaveMessageClassRequest) => {
            return client.saveMessageClass(request, {meta: metadata()}).response
        },
        removeMessageClassRequest: (request: RemoveMessageClassRequest) => {
            return client.removeMessageClass(request, {meta: metadata()}).response
        },
    }
}