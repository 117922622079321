import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"margin":"10px 10px 0 10px","height":"calc(100% - 50px)","overflow":"hidden"} }
const _hoisted_2 = { style: {"margin":"10px 10px 0 10px","height":"calc(100% - 50px)","overflow":"hidden"} }
const _hoisted_3 = {
  class: "row",
  style: {"height":"100%","padding-left":"0"}
}
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = {
  class: "col-12",
  style: {"height":"calc(100% - 110px)","overflow":"hidden"}
}
const _hoisted_6 = {
  style: {"width":"calc(100% - 10px)","height":"100%","margin-right":"10px","overflow":"auto","border":"1px solid black"},
  class: "custom-tree"
}
const _hoisted_7 = { style: {"width":"calc(100% - 10px)","height":"100%","margin-left":"10px","overflow":"auto","border":"1px solid black"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_tree = _resolveComponent("q-tree")!
  const _component_WordPanel = _resolveComponent("WordPanel")!
  const _component_q_splitter = _resolveComponent("q-splitter")!
  const _component_DataLoading = _resolveComponent("DataLoading")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_WordAdd = _resolveComponent("WordAdd")!
  const _component_WordEdit = _resolveComponent("WordEdit")!
  const _component_WordDelete = _resolveComponent("WordDelete")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-h4 q-pb-md" }, "Словарь", -1)),
      _createVNode(_component_q_card, { style: {"padding":"10px 10px 0 10px","height":"100%"} }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_q_toolbar, {
              class: "q-mb-md",
              style: {"padding-left":"0","padding-right":"0"}
            }, {
              default: _withCtx(() => [
                ('admin editor'.includes(_ctx.userRole))
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 0,
                      color: "primary",
                      disable: _ctx.isWordsTreeBusy,
                      label: "Добавить",
                      icon: "mdi-plus",
                      onClick: _ctx.addWord,
                      style: {"margin-right":"16px"}
                    }, null, 8, ["disable", "onClick"]))
                  : _createCommentVNode("", true),
                ('admin editor'.includes(_ctx.userRole))
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 1,
                      color: "primary",
                      disable: 
              _ctx.isWordsTreeBusy ||
              !_ctx.getCurrentWordsNode ||
              _ctx.getCurrentWordsNode.id == 0 ||
              _ctx.getCurrentWordsNode.hasChilds
            ,
                      label: "Переместить",
                      icon: "mdi-pencil",
                      onClick: _ctx.editWord,
                      style: {"margin-right":"16px"}
                    }, null, 8, ["disable", "onClick"]))
                  : _createCommentVNode("", true),
                ('admin editor'.includes(_ctx.userRole))
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 2,
                      color: "primary",
                      disable: 
              _ctx.isWordsTreeBusy ||
              !_ctx.getCurrentWordsNode ||
              _ctx.getCurrentWordsNode.id == 0
            ,
                      label: "Удалить",
                      icon: "mdi-close",
                      onClick: _ctx.deleteWord
                    }, null, 8, ["disable", "onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_q_space),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_input, {
                    disable: _ctx.isWordsTreeBusy,
                    modelValue: _ctx.filter,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event)),
                    filled: "",
                    onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInputSearchControl($event))),
                    style: {"width":"calc(100% - 10px)","margin-left":"auto"}
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_q_icon, { name: "mdi-magnify" })
                    ]),
                    _: 1
                  }, 8, ["disable", "modelValue"])
                ])
              ]),
              _: 1
            }),
            _withDirectives(_createElementVNode("div", _hoisted_5, [
              _createVNode(_component_q_splitter, {
                modelValue: _ctx.splitterSize,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.splitterSize) = $event)),
                style: {"width":"100%","height":"100%","overflow":"hidden"}
              }, {
                before: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_q_tree, {
                      ref: "wordsTree",
                      nodes: _ctx.getWordsTreeItems,
                      "node-key": "id",
                      "label-key": "text",
                      filter: _ctx.search,
                      selected: _ctx.selectedWordsNode,
                      "onUpdate:selected": [
                        _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedWordsNode) = $event)),
                        _ctx.selectNode
                      ]
                    }, null, 8, ["nodes", "filter", "selected", "onUpdate:selected"])
                  ])
                ]),
                after: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_WordPanel)
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ], 512), [
              [_vShow, !_ctx.isWordsTreeBusy]
            ]),
            _withDirectives(_createElementVNode("div", null, [
              _createVNode(_component_DataLoading)
            ], 512), [
              [_vShow, _ctx.isWordsTreeBusy]
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_q_dialog, {
        modelValue: _ctx.isActiveModal,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isActiveModal) = $event)),
        persistent: ""
      }, {
        default: _withCtx(() => [
          (_ctx.isActiveModalAdd)
            ? (_openBlock(), _createBlock(_component_WordAdd, {
                key: 0,
                onAfterAction: _ctx.afterAction,
                onHideModal: _ctx.hideModal
              }, null, 8, ["onAfterAction", "onHideModal"]))
            : _createCommentVNode("", true),
          (_ctx.isActiveModalEdit)
            ? (_openBlock(), _createBlock(_component_WordEdit, {
                key: 1,
                onAfterAction: _ctx.afterAction,
                onHideModal: _ctx.hideModal
              }, null, 8, ["onAfterAction", "onHideModal"]))
            : _createCommentVNode("", true),
          (_ctx.isActiveModalDelete)
            ? (_openBlock(), _createBlock(_component_WordDelete, {
                key: 2,
                onAfterAction: _ctx.afterAction,
                onHideModal: _ctx.hideModal
              }, null, 8, ["onAfterAction", "onHideModal"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ], 512), [
      [_vShow, !_ctx.isWordsTreeBusy]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_DataLoading)
    ], 512), [
      [_vShow, _ctx.isWordsTreeBusy]
    ])
  ]))
}