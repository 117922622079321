import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "row",
  style: {"gap":"16px"}
}

import { Ref, ref, onMounted, computed, watchEffect } from 'vue'
import store from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'UsersSegm',
  setup(__props) {

const chartOptions: any = ref({
  chart: {
    type: 'column',
  },
  colors: ['#FFB1C1', '#9AD0F5', '#FFD687'],
  title: { text: '' },
  xAxis: {
    visible: true,
    // categories: ['150000 13%', '450000 60%', '150000 20%'],
    type: 'category',
    labels: {
      formatter: function (this: any) {
        return this.value.split(' ').join('<br>')
      } as any,
    },
  },
  yAxis: {
    visible: false,
    title: {
      text: '',
    },
  },
  tooltip: {
    headerFormat: '',
  },
  legend: {
    enabled: false,
    reversed: false,
    align: 'left',
  },
  //   plotOptions: {
  //     series: {
  //       stacking: 'normal',
  //       dataLabels: {
  //         enabled: true,
  //       },
  //     },
  //   },
  series: [
    {
      name: 'Количество',
      data: [
        // { name: 'first', y: 650_000, color: '#FFB1C1' },
        // { name: 'second', y: 150_000, color: '#9AD0F5' },
        // { name: 'third', y: 450_000, color: '#FFD687' },
      ],
    },
  ],
})

// const settings = computed(() => store.getters.getModalSettings)
// const inputPoints: Ref<number> = ref(settings.value.lowEdgePoints)
// const inputPrc: Ref<number> = ref(settings.value.orPrc)

// const date = computed(() => store.getters.getDateForEventList)
// const getProjectIds = computed(() => store.getters.getSelectedProjectItems)
const isCountPointSegmBusy = computed(() => store.getters.isCountPointSegmBusy)
// const checkedMetrics = computed(() => store.getters.getCheckedMetrics)

watchEffect(() => {
  const getCntPoints = store.getters.getCountPointSegm

  if (getCntPoints && getCntPoints.getCntEarnedOnly()) {
    let sum =
      getCntPoints.getCntEarnedOnly() +
      getCntPoints.getCntSpentAll() +
      getCntPoints.getCntSpentPart() +
      getCntPoints.getCntNoTransactions()

    let pointEarn =
      Math.round((getCntPoints.getCntEarnedOnly() * 100) / sum) + '%'
    let pointSpent =
      Math.round((getCntPoints.getCntSpentAll() * 100) / sum) + '%'
    let pointPart =
      Math.round((getCntPoints.getCntSpentPart() * 100) / sum) + '%'
    let pointNoT =
      Math.round((getCntPoints.getCntNoTransactions() * 100) / sum) + '%'

    chartOptions.value.series[0].data = []

    chartOptions.value.series[0].data.push({
      name: getCntPoints.getCntEarnedOnly() + ' ' + pointEarn,
      y: getCntPoints.getCntEarnedOnly(),
      color: '#FFB1C1',
    })
    chartOptions.value.series[0].data.push({
      name: getCntPoints.getCntSpentAll() + ' ' + pointSpent,
      y: getCntPoints.getCntSpentAll(),
      color: '#9AD0F5',
    })
    chartOptions.value.series[0].data.push({
      name: getCntPoints.getCntSpentPart() + ' ' + pointPart,
      y: getCntPoints.getCntSpentPart(),
      color: '#FFD687',
    })
    chartOptions.value.series[0].data.push({
      name: getCntPoints.getCntNoTransactions() + ' ' + pointNoT,
      y: getCntPoints.getCntNoTransactions(),
      color: '#575757',
    })
  } else {
    chartOptions.value.series[0].data = []
  }
})

// const applyPoints = () => {
//   store.dispatch('getCountPointSegm', {
//     date_from: date.value.dateFrom || '2012-01-01',
//     date_to: date.value.dateTo || new Date().toJSON().slice(0, 10),
//     project_ids: getProjectIds.value || [1, 2, 3, 4, 5, 6],
//     value: inputPoints.value || 0,
//     percent: inputPrc.value || 0,
//     from_ids: checkedMetrics.value.from || [1, 2, 3, 4],
//     to_ids: checkedMetrics.value.to || [5, 6, 7, 8],
//     from_name: checkedMetrics.value.fromName || 'Активность',
//     to_name: checkedMetrics.value.toName || 'Лояльность',
//     add_param: settings.value.addParameters || false,
//   })

//   store.dispatch('setModalSettings', {
//     lowEdgePoints: +inputPoints.value,
//     orPrc: +inputPrc.value,
//   })
// }

// const reset = () => {
//   inputPoints.value = 499
//   inputPrc.value = 30
//   applyPoints()
// }

// onMounted(() => {
//   // applyPoints()
// })

return (_ctx: any,_cache: any) => {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_highcharts = _resolveComponent("highcharts")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card, { class: "col" }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card_section, {
          horizontal: "",
          style: {"height":"524px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_card_section, {
              style: {"width":"280px","margin-top":"66px"},
              class: "column items-center"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("div", { class: "column" }, [
                  _createElementVNode("span", { style: {"margin-bottom":"10px"} }, "Легенда"),
                  _createElementVNode("div", {
                    class: "colors column",
                    style: {"row-gap":"5px"}
                  }, [
                    _createElementVNode("div", { class: "earn row" }, [
                      _createElementVNode("div", { class: "color" }),
                      _createElementVNode("div", { class: "text" }, "Только копят")
                    ]),
                    _createElementVNode("div", { class: "spend row" }, [
                      _createElementVNode("div", { class: "color" }),
                      _createElementVNode("div", { class: "text" }, "Тратят все")
                    ]),
                    _createElementVNode("div", { class: "evenly row" }, [
                      _createElementVNode("div", { class: "color" }),
                      _createElementVNode("div", { class: "text" }, "Тратят частично")
                    ]),
                    _createElementVNode("div", { class: "no-trans row" }, [
                      _createElementVNode("div", { class: "color" }),
                      _createElementVNode("div", { class: "text" }, "Нет транзакций")
                    ])
                  ])
                ], -1)
              ])),
              _: 1
            }),
            _createVNode(_component_highcharts, {
              class: "hc",
              options: chartOptions.value
            }, null, 8, ["options"]),
            _createVNode(_component_q_inner_loading, {
              showing: isCountPointSegmBusy.value,
              color: "grey"
            }, null, 8, ["showing"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})