import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-subtitle1" }
const _hoisted_2 = { class: "text-weight-bold text-primary" }

import { onMounted, ref } from "vue";
import { ClassNotTagsCrudProps } from "@/components/modules/polls/views/generalMessages/classification/MultipleClassification.vue";
import { ClassNotTagModel } from "proto/nigma-message-proto/polls/class_not_tag";
import BaseModalCard from "@/components/Base/BaseModalCard.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'NotTagDelete',
  props: {
    notTagItem: {}
  },
  emits: ["actualDelete", "hideModal"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const classNotTagItem = ref<ClassNotTagModel & { index: number }>({
  classId: 0,
  notTagName: "",
  notTagDescr: "",
  index: 0,
});

const actualDelete = () => emit("actualDelete", classNotTagItem.value.index);

const closeTagDelete = () => emit("hideModal");

const loadData = () => {
  classNotTagItem.value = {
    classId: props.notTagItem?.classId || 0,
    notTagName: props.notTagItem?.notTagName || "",
    notTagDescr: props.notTagItem?.notTagDescr || "",
    index: props.notTagItem?.index || 0,
  };
};

onMounted(() => loadData());

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(BaseModalCard, {
    onClose: _cache[0] || (_cache[0] = ($event: any) => (emit('hideModal'))),
    class: "relative-position"
  }, {
    header: _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("div", { class: "text-h5" }, "Удаление тега", -1)
    ])),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[2] || (_cache[2] = _createTextVNode(" Вы действительно хотите удалить тэг - ")),
        _createElementVNode("span", _hoisted_2, _toDisplayString(classNotTagItem.value.notTagName), 1),
        _cache[3] || (_cache[3] = _createTextVNode(" ? "))
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_q_btn, {
        color: "secondary",
        onClick: actualDelete
      }, {
        default: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode("Удалить")
        ])),
        _: 1
      }),
      _createVNode(_component_q_btn, {
        color: "red-5",
        onClick: closeTagDelete
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode("Отмена")
        ])),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})