import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, withModifiers as _withModifiers, createSlots as _createSlots } from "vue"
import _imports_0 from '@/assets/img/split.svg'


const _hoisted_1 = {
  class: "aside-filters__metrics column",
  style: {"gap":"8px","margin-bottom":"10px"}
}
const _hoisted_2 = {
  class: "aside-filters__points column",
  style: {"gap":"8px","margin-bottom":"10px"}
}
const _hoisted_3 = {
  class: "aside-filters__activity column",
  style: {"gap":"8px","margin-bottom":"10px"}
}
const _hoisted_4 = {
  class: "aside-filters__activity-first row",
  style: {"gap":"4px","position":"relative"}
}
const _hoisted_5 = { class: "row items-center justify-end" }
const _hoisted_6 = { class: "row items-center justify-end" }
const _hoisted_7 = {
  class: "aside-filters__activity-first row",
  style: {"gap":"4px","position":"relative"}
}
const _hoisted_8 = { class: "row items-center justify-end" }
const _hoisted_9 = { class: "row items-center justify-end" }
const _hoisted_10 = {
  class: "aside-filters__activity-first row",
  style: {"gap":"4px","position":"relative"}
}
const _hoisted_11 = { class: "row items-center justify-end" }
const _hoisted_12 = { class: "row items-center justify-end" }
const _hoisted_13 = {
  class: "aside-filters__area column",
  style: {"gap":"8px","margin-bottom":"10px","position":"relative"}
}
const _hoisted_14 = {
  class: "aside-filters__blocked column",
  style: {"position":"relative","margin-bottom":"10px"}
}

import { ref, onMounted, computed, unref, toRaw } from 'vue'
import store from '@/store'
import GroupSelectFilter from '../UserProjectsList/GroupSelectFilter.vue'
import GroupSelectFilterArea from '../UserProjectsList/GroupSelectFilterArea.vue'
import date from 'quasar/src/utils/date/date.js';
import { onClickOutside } from '@vueuse/core'
import { TagsApi } from "@/components/modules/polls/services/api/TagsApi";
import type { QPopupProxyInnerComponent } from 'quasar';

type Dates = {
  from: string,
  to: string,
  fromLast: string,
  toLast: string,
  fromBirth: string,
  toBirth: string
}

type DatesKeys = keyof Dates;


export default /*@__PURE__*/_defineComponent({
  __name: 'AsideFilters',
  emits: ['on-filters'],
  setup(__props, { emit: __emit }) {

const emit = __emit

onMounted(() => {
  getAllTagsList()
  store.dispatch('getRegionList', {})
  store.dispatch('getDistrictList', {})
  store.dispatch('getFamilyList', {})
  store.dispatch('getSocialList', {})
  store.dispatch('getTypesList', {})
  store.dispatch('getMessageList', {})
  store
    .dispatch('getMetricList', {
      filter: {},
      sort: {},
      pagination: {},
    })
    .then((value: any) => {
      for (const i in value) {
        metricsList.value.push(value[i])
      }
    })
  computedFilters.value
})

const tagsApi = new TagsApi()

const sexList = ref(['Мужской', 'Женский', 'Не указано'])

const blockedList = computed(() => [
  {
    label: 'Да',
    value: '1'
  },
  {
    label: 'Нет',
    value: '0'
  }
])

const regionList: any = computed(() => {
  const rawList = store.getters.getRegionList
  const filteredList: any = []

  for (const i of rawList) {
    filteredList.push(i.getRegionName())
  }

  return filteredList
})

const isRegionBusy = computed(() => store.getters.isRegionBusy)

const isDistrictBusy = computed(() => store.getters.isDistrictBusy)

const isFamilyBusy = computed(() => store.getters.isFamilyList)

const isMessageListBusy = computed(() => store.getters.isMessageList)

const isSocialBusy = computed(() => store.getters.isSocialList)

const isTypeList = computed(() => store.getters.isTypesList)

const districtList: any = computed(() => {
  const rawList = store.getters.getDistrictList
  const filteredList = []

  for (const i of rawList) {
    filteredList.push(i.getDistrictName())
  }

  return filteredList
})

const familyList: any = computed(() => {
  const rawList = store.getters.getFamilyList
  const filteredList = []

  for (const i of rawList) {
    filteredList.push(i.getFamilyStatus())
  }

  console.log('СЕМЕЙНОЕ ПОЛОЖЕНИЕ фильтры', filteredList)

  return filteredList
})

const messageList: any = computed(() => {
  const rawList = store.getters.getMessageList
  const filteredList = []

  console.log('MESAAGE RAW LIST', rawList)

  for (const i of rawList) {
    console.log('GROUP VALUES LIST', i.getGroupValuesList())
    if (i.getGroupName() == 'Группа сообщений') {
      for (const j of i.getGroupValuesList()) {
        filteredList.push(j.getGroupValue())
      }
    }
  }

  return filteredList
})

const getAllTagsList = () => {
  tagsApi
    .getTagsList(
      [],
      {
        name: 'tag_name',
        exp: 'asc',
      },
      {}
    )
    .then((res: any) => {
      console.log('resplonsetags', res)
      tagsList.value = res[0].map(el => {
        return {
          label: el.tagName,
          value: el.tagId,
        }
      })
    })
}

const tagsList = ref([])

const socialList: any = computed(() => {
  const rawList = store.getters.getSocialList
  const filteredList = []

  for (const i of rawList) {
    filteredList.push(i.getSocial())
  }

  console.log('РОД ДЕЯТЕЛЬНОСТИ фильтры', filteredList)

  return filteredList
})

const typeList: any = computed(() => {
  const rawList = store.getters.getTypesList
  const filteredList = []

  for (const i of rawList) {
    filteredList.push({
      value: i.getTypeId(),
      label: i.getTypeDescr(),
    })
  }

  filteredList.push({
    value: 999999999999,
    label: 'Не заполнено'
  })

  console.log('ТИП УЧЕТНОЙ ЗАПИСИ фильтры', filteredList)

  return filteredList
})

const groupSelectRef: any = ref(null)

const metricsList: any = ref([])
const floatingBtn = ref()
const filtersRoot = ref()

const ActivityMetricsList: any = ref(null)
const LoyaltyMetricsList: any = ref(null)
const UsefullMetricsList: any = ref(null)
const InterestMetricsList: any = ref(null)
const regionMetricsList: any = ref(null)
const districtMetricsList: any = ref(null)
const sexMetricList: any = ref(null)
const familyMetricList: any = ref(null)
const messageMetricList: any = ref(null)
const tagsMetricList: any = ref(null)
const socialMetricList: any = ref(null)
const typesMetricList: any = ref(null)

const pointsEarnedCityRef = ref()
const earnedInputCityFrom = ref(null)
const earnedInputCityTo = ref(null)

const pointsEarnedPrizeRef = ref()
const earnedInputPrizeFrom = ref(null)
const earnedInputPrizeTo = ref(null)

const pointsSpentCityRef = ref()
const spentInputCityFrom = ref(null)
const spentInputCityTo = ref(null)

const pointsSpentPrizeRef = ref()
const spentInputPrizeFrom = ref(null)
const spentInputPrizeTo = ref(null)

const pointsCurrentCityRef = ref()
const currentInputCityFrom = ref(null)
const currentInputCityTo = ref(null)

const pointsCurrentPrizeRef = ref()
const currentInputPrizeFrom = ref(null)
const currentInputPrizeTo = ref(null)

const currentChildRef = ref()
const currentChildInputFrom = ref(null)
const currentChildInputTo = ref(null)

const activityCntRef = ref()
const activityInputFrom = ref(null)
const activityInputTo = ref(null)

const maxDateLimit = date.formatDate(Date.now(), 'YYYY/MM')
const dateFromRef = ref()

const qDateProxy1: any = ref()
const qDateProxy2: any = ref()

const qDateProxyLast1: any = ref()
const qDateProxyLast2: any = ref()

const qDateProxyBirth1: any = ref()
const qDateProxyBirth2: any = ref()

const dates = ref({
  from: '',
  to: '',
  fromLast: '',
  toLast: '',
  fromBirth: '',
  toBirth: ''
})

const isBlockedRef: any = ref()

const groupFilters = ref<Record<string, unknown>>({})

const computedFilters = computed(() => {
  const filters = []

  if (ActivityMetricsList.value) {
    filters.push(toRaw(ActivityMetricsList.value))
  }

  if (LoyaltyMetricsList.value) {
    filters.push(toRaw(LoyaltyMetricsList.value))
  }

  if (UsefullMetricsList.value) {
    filters.push(toRaw(UsefullMetricsList.value))
  }

  if (InterestMetricsList.value) {
    filters.push(toRaw(InterestMetricsList.value))
  }

  if (regionMetricsList.value) {
    filters.push(toRaw(regionMetricsList.value))
  }

  if (districtMetricsList.value) {
    filters.push(toRaw(districtMetricsList.value))
  }

  if (sexMetricList.value) {
    filters.push(toRaw(sexMetricList.value))
  }

  if (familyMetricList.value) {
    filters.push(toRaw(familyMetricList.value))
  }

  if (messageMetricList.value) {
    filters.push(toRaw(messageMetricList.value))
  }

  if (tagsMetricList.value) {
    filters.push(toRaw(tagsMetricList.value))
  }

  if (socialMetricList.value) {
    filters.push(toRaw(socialMetricList.value))
  }

  if (typesMetricList.value) {
    filters.push(toRaw(typesMetricList.value))
  }

  if (isBlockedRef.value) {
    filters.push(toRaw(isBlockedRef.value))
  }

  if (earnedInputCityFrom.value) {
    filters.push(
      {
        field_name: 'a.points_earned_city',
        operand: '>=',
        value: earnedInputCityFrom.value,
      }
    )
  }

  if (earnedInputCityTo.value) {
    filters.push(
      {
        field_name: 'a.points_earned_city',
        operand: '<=',
        value: earnedInputCityTo.value,
      }
    )
  }

  if (earnedInputPrizeFrom.value) {
    filters.push(
      {
        field_name: 'a.points_earned_prize',
        operand: '>=',
        value: earnedInputPrizeFrom.value,
      }
    )
  }

  if (earnedInputPrizeTo.value) {
    filters.push(
      {
        field_name: 'a.points_earned_prize',
        operand: '<=',
        value: earnedInputPrizeTo.value,
      }
    )
  }

  if (spentInputCityFrom.value) {
    filters.push(
      {
        field_name: 'a.points_spent_city',
        operand: '>=',
        value: spentInputCityFrom.value,
      },
    )
  }

  if (spentInputCityTo.value) {
    filters.push(
      {
        field_name: 'a.points_spent_city',
        operand: '<=',
        value: spentInputCityTo.value,
      }
    )
  }

  if (spentInputPrizeFrom.value) {
    filters.push(
      {
        field_name: 'a.points_spent_prize',
        operand: '>=',
        value: spentInputPrizeFrom.value,
      }
    )
  }

  if (spentInputPrizeTo.value) {
    filters.push(
      {
        field_name: 'a.points_spent_prize',
        operand: '<=',
        value: spentInputPrizeTo.value,
      }
    )
  }

  if (currentInputCityFrom.value) {
    filters.push(
      {
        field_name: 'a.points_city',
        operand: '>=',
        value: currentInputCityFrom.value,
      },
    )
  }

  if (currentInputCityTo.value) {
    filters.push(
      {
        field_name: 'a.points_city',
        operand: '<=',
        value: currentInputCityTo.value,
      }
    )
  }

  if (currentInputPrizeFrom.value) {
    filters.push(
      {
        field_name: 'a.points_prize',
        operand: '>=',
        value: currentInputPrizeFrom.value,
      }
    )
  }

  if (currentInputPrizeTo.value) {
    filters.push(
      {
        field_name: 'a.points_prize',
        operand: '<=',
        value: currentInputPrizeTo.value,
      }
    )
  }

  if (activityInputTo.value && activityInputTo.value > 0) {
    filters.push(
      {
        field_name: 'a.cnt_activity',
        operand: '>=',
        value: activityInputFrom.value ? unref(activityInputFrom) : 0,
      },
      {
        field_name: 'a.cnt_activity',
        operand: '<=',
        value: activityInputTo.value ? unref(activityInputTo) : 0,
      }
    )
  } else {
    filters.push({
      field_name: 'a.cnt_activity',
      operand: '>=',
      value: activityInputFrom.value ? unref(activityInputFrom) : 0,
    })
  }

  if (currentChildInputFrom.value) {
    filters.push(
      {
        field_name: 'a.num_child',
        operand: '>=',
        value: currentChildInputFrom.value,
      },
    )
  }

  if (currentChildInputTo.value) {
    filters.push(
      {
        field_name: 'a.num_child',
        operand: '<=',
        value: currentChildInputTo.value,
      },
    )
  }

  if (dates.value.from) {
    filters.push(
      {
        field_name: 'a.first_activity_date',
        operand: '>=',
        value: dates.value.from,
      }
    )
  }

  if (dates.value.to) {
    filters.push(
      {
        field_name: 'a.first_activity_date',
        operand: '<=',
        value: dates.value.to,
      }
    )
  }

  if (dates.value.fromLast) {
    filters.push(
      {
        field_name: 'a.last_activity_date',
        operand: '>=',
        value: dates.value.fromLast,
      }
    )
  }

  if (dates.value.toLast) {
    filters.push(
      {
        field_name: 'a.last_activity_date',
        operand: '<=',
        value: dates.value.toLast
      }
    )
  }

  if (dates.value.fromBirth !== '' && dates.value.toBirth !== '') {
    filters.push(
      {
        field_name: 'a.birthday',
        operand: '>=',
        value: unref(dates.value.fromBirth),
      },
      {
        field_name: 'a.birthday',
        operand: '<=',
        value: unref(dates.value.toBirth),
      }
    )
  }

  return filters
})

const asideBtn = (e: any) => {
  let el = e?.srcElement || e?.parentElement
  // console.log('EVENT')
  // console.dir(e)
  if (floatingBtn.value.style.opacity == 1) {
    floatingBtn.value.style.opacity = '0'
    floatingBtn.value.style.visibility = 'hidden'
  }
  const arrStops = [
    'filter-select-tree col',
    'aside-filters__points-earned row',
    'aside-filters__points-spent row',
    'aside-filters__points-current row',
    'aside-filters__activity-count row',
    'aside-filters__activity-first row',
    'aside-filters__area column',
    'aside-filters__blocked column',
  ]

  while (el.parentElement && !arrStops.includes(el.className)) {
    el = el.parentElement
  }

  el.firstChild.after(floatingBtn.value)
  setTimeout(() => {
    floatingBtn.value.style.opacity = '1'
    floatingBtn.value.style.visibility = 'visible'
  }, 300)
  // console.log('ELEMENT', el)
}

onClickOutside(filtersRoot, () => {
  // e.target.closest('.filter-select-tree') ? null : showDropDown(false)
  floatingBtn.value.style.opacity = '0'
  floatingBtn.value.style.visibility = 'hidden'
})

const onSelectedDropdown = (itemsObj: any) => {
  asideBtn(itemsObj[1])
  const { items, label }: any = itemsObj[0]

  console.log('ON SELECTED', itemsObj)

  if (label == 'Активность') {
    let tempFilter: any = {}

    items.forEach((item: number, idx: number) => {
      if (idx == 0) {
        tempFilter = {
          field_name: 'a.activity_value_id',
          operand: '=',
          value: item,
          or: [],
        }
      } else {
        tempFilter.or.push({
          field_name: 'a.activity_value_id',
          operand: '=',
          value: item,
        })
      }
    })

    ActivityMetricsList.value = tempFilter
  }

  if (label == 'Лояльность') {
    let tempFilter: any = {}

    items.forEach((item: number, idx: number) => {
      if (idx == 0) {
        tempFilter = {
          field_name: 'a.loyalty_value_id',
          operand: '=',
          value: item,
          or: [],
        }
      } else {
        tempFilter.or.push({
          field_name: 'a.loyalty_value_id',
          operand: '=',
          value: item,
        })
      }
    })

    LoyaltyMetricsList.value = tempFilter
  }
  if (label == 'Полезность') {
    let tempFilter: any = {}

    items.forEach((item: number, idx: number) => {
      if (idx == 0) {
        tempFilter = {
          field_name: 'a.usefulness_value_id',
          operand: '=',
          value: item,
          or: [],
        }
      } else {
        tempFilter.or.push({
          field_name: 'a.usefulness_value_id',
          operand: '=',
          value: item,
        })
      }
    })
    UsefullMetricsList.value = tempFilter
  }
  if (label == 'Заинтересованность') {
    let tempFilter: any = {}

    items.forEach((item: number, idx: number) => {
      if (idx == 0) {
        tempFilter = {
          field_name: 'a.interest_value_id',
          operand: '=',
          value: item,
          or: [],
        }
      } else {
        tempFilter.or.push({
          field_name: 'a.interest_value_id',
          operand: '=',
          value: item,
        })
      }
    })
    InterestMetricsList.value = tempFilter
  }
  if (label == 'Округ') {
    let tempFilter: any = {}

    items.forEach((item: number, idx: number) => {
      if (idx == 0) {
        tempFilter = {
          field_name: 'a.region',
          operand: '=',
          value: item,
          or: [],
        }
      } else {
        tempFilter.or.push({
          field_name: 'a.region',
          operand: '=',
          value: item,
        })
      }
    })
    regionMetricsList.value = tempFilter
  }
  if (label == 'Район') {
    let tempFilter: any = {}

    items.forEach((item: number, idx: number) => {
      if (idx == 0) {
        tempFilter = {
          field_name: 'a.district',
          operand: '=',
          value: item,
          or: [],
        }
      } else {
        tempFilter.or.push({
          field_name: 'a.district',
          operand: '=',
          value: item,
        })
      }
    })
    districtMetricsList.value = tempFilter
  }
  if (label == 'Пол') {
    let tempFilter: any = {}

    items.forEach((item: number, idx: number) => {
      if (idx == 0) {
        tempFilter = {
          field_name: 'a.sex',
          operand: '=',
          value: item,
          or: [],
        }
      } else {
        tempFilter.or.push({
          field_name: 'a.sex',
          operand: '=',
          value: item,
        })
      }
    })
    sexMetricList.value = tempFilter
  }
  if (label == 'Семейное положение') {
    let tempFilter: any = {}

    items.forEach((item: number, idx: number) => {
      if (idx == 0) {
        tempFilter = {
          field_name: 'a.family_status',
          operand: '=',
          value: item,
          or: [],
        }
      } else {
        tempFilter.or.push({
          field_name: 'a.family_status',
          operand: '=',
          value: item,
        })
      }
    })
    familyMetricList.value = tempFilter
  }
  if (label == 'Группа сообщений') {
    let tempFilter: any = {}

    items.forEach((item: number, idx: number) => {
      if (idx == 0) {
        tempFilter = {
          field_name: 'messages_group',
          operand: '=',
          value: item,
          or: [],
        }
      } else {
        tempFilter.or.push({
          field_name: 'messages_group',
          operand: '=',
          value: item,
        })
      }
    })
    messageMetricList.value = tempFilter
  }
  if (label == 'Теги') {
    let tempFilter: any = []

    items.forEach((item: number) => {
      tempFilter.push({
        field_name: 'tags_ids',
        operand: 'has',
        value: item,
        or: [],
      })
    })
    tagsMetricList.value = tempFilter
  }
  if (label == 'Род деятельности') {
    let tempFilter: any = {}

    items.forEach((item: number, idx: number) => {
      if (idx == 0) {
        tempFilter = {
          field_name: 'a.social',
          operand: '=',
          value: item,
          or: [],
        }
      } else {
        tempFilter.or.push({
          field_name: 'a.social',
          operand: '=',
          value: item,
        })
      }
    })
    socialMetricList.value = tempFilter
  }
  if (label == 'Тип учетной записи') {
    let tempFilter: any = {}

    items.forEach((item: number, idx: number) => {
      if (item !== 999999999999) {
        if (idx == 0) {
          tempFilter = {
            field_name: 'a.type_id',
            operand: '=',
            value: item,
            or: [],
          }
        } else {
          tempFilter.or.push({
            field_name: 'a.type_id',
            operand: '=',
            value: item,
          })
        }
      }
      else {
        if (idx == 0) {
          tempFilter = {
            field_name: 'a.type_id',
            operand: '=',
            value: 0,
            or: [],
          }
        } else {
          tempFilter.or.push({
            field_name: 'a.type_id',
            operand: '=',
            value: 0,
          })
        }
      }
    })
    typesMetricList.value = tempFilter
  }
  if (label == 'Пользователь заблокирован?') {
    let tempFilter: any = {}

    items.forEach((item: number, idx: number) => {
      if (idx == 0) {
        tempFilter = {
          field_name: 'is_blocked',
          operand: '=',
          value: Number(item),
          or: [],
        }
      } else {
        tempFilter.or.push({
          field_name: 'is_blocked',
          operand: '=',
          value: Number(item),
        })
      }
    })
    isBlockedRef.value = tempFilter
  }
}

const applyFilters = () => {
  emit('on-filters', computedFilters.value)
  floatingBtn.value.style.opacity = '0'
}

const resetFields = () => {
  ActivityMetricsList.value = null
  LoyaltyMetricsList.value = null
  UsefullMetricsList.value = null
  InterestMetricsList.value = null
  regionMetricsList.value = null
  districtMetricsList.value = null
  sexMetricList.value = null
  familyMetricList.value = null
  messageMetricList.value = null
  tagsMetricList.value = null
  socialMetricList.value = null
  typesMetricList.value = null

  isBlockedRef.value = null

  earnedInputCityFrom.value = null
  earnedInputCityTo.value = null
  earnedInputPrizeFrom.value = null
  earnedInputPrizeTo.value = null

  spentInputCityFrom.value = null
  spentInputCityTo.value = null
  spentInputPrizeFrom.value = null
  spentInputPrizeTo.value = null

  currentInputCityFrom.value = null
  currentInputCityTo.value = null
  currentInputPrizeFrom.value = null
  currentInputPrizeTo.value = null

  currentChildInputTo.value = null
  currentChildInputFrom.value = null

  activityInputFrom.value = null
  activityInputTo.value = null

  dates.value.from = ''
  dates.value.to = ''

  dates.value.fromLast = ''
  dates.value.toLast = ''

  dates.value.fromBirth = ''
  dates.value.toBirth = ''

  if (groupSelectRef.value) {
    for (const i of groupSelectRef.value) {
      i.reset()
    }
  }

  if (Object.keys(groupFilters.value).length) {
    for (const i of Object.values(groupFilters.value)) {
      const component = i as InstanceType<typeof GroupSelectFilterArea>
      component?.reset?.()
    }
  }

  applyFilters()
}

const clearDate = (ref: QPopupProxyInnerComponent, type?: DatesKeys) => {
  asideBtn(ref?.$el)
  type ? dates.value[type] = '' : ref?.hide()
}


return (_ctx: any,_cache: any) => {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      ref_key: "filtersRoot",
      ref: filtersRoot,
      class: "aside-filters"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _cache[65] || (_cache[65] = _createElementVNode("span", { class: "text-weight-bold" }, "Метрики", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(metricsList.value, (item) => {
          return (_openBlock(), _createBlock(GroupSelectFilter, {
            key: item,
            label: item.getMetricName(),
            options: item.getItemsList(),
            "root-label": "Все группы",
            onOnSelected: onSelectedDropdown,
            ref_for: true,
            ref_key: "groupSelectRef",
            ref: groupSelectRef
          }, null, 8, ["label", "options"]))
        }), 128)),
        _createVNode(GroupSelectFilterArea, {
          label: "Группа сообщений",
          "root-label": "Выбрать все",
          options: messageList.value,
          loading: isMessageListBusy.value,
          onOnSelected: onSelectedDropdown,
          ref: (el) => groupFilters.value['message'] = el
        }, null, 8, ["options", "loading"]),
        _createVNode(GroupSelectFilterArea, {
          label: "Теги",
          "root-label": "Выбрать все",
          options: tagsList.value,
          loading: isMessageListBusy.value,
          onOnSelected: onSelectedDropdown,
          "node-key": "value",
          ref: (el) => groupFilters.value['tags'] = el
        }, null, 8, ["options", "loading"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _cache[72] || (_cache[72] = _createElementVNode("span", { class: "text-weight-bold" }, "Заработано городских баллов", -1)),
        _createElementVNode("div", {
          class: "aside-filters__points-earned row",
          style: {"gap":"4px","position":"relative"},
          ref_key: "pointsEarnedCityRef",
          ref: pointsEarnedCityRef
        }, [
          _createVNode(_component_q_input, {
            clearable: "",
            modelValue: earnedInputCityFrom.value,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((earnedInputCityFrom).value = $event)),
              _cache[1] || (_cache[1] = ($event: any) => (asideBtn(pointsEarnedCityRef.value.firstChild)))
            ],
            outlined: "",
            filled: "",
            class: "col",
            label: "От"
          }, null, 8, ["modelValue"]),
          _cache[66] || (_cache[66] = _createElementVNode("img", {
            class: "col-shrink",
            src: _imports_0,
            alt: ""
          }, null, -1)),
          _createVNode(_component_q_input, {
            clearable: "",
            class: "col",
            modelValue: earnedInputCityTo.value,
            "onUpdate:modelValue": [
              _cache[2] || (_cache[2] = ($event: any) => ((earnedInputCityTo).value = $event)),
              _cache[3] || (_cache[3] = ($event: any) => (asideBtn(pointsEarnedCityRef.value.firstChild)))
            ],
            outlined: "",
            filled: "",
            label: "До"
          }, null, 8, ["modelValue"])
        ], 512),
        _cache[73] || (_cache[73] = _createElementVNode("span", { class: "text-weight-bold" }, "Потрачено городских баллов", -1)),
        _createElementVNode("div", {
          class: "aside-filters__points-spent row",
          style: {"gap":"4px","position":"relative"},
          ref_key: "pointsSpentCityRef",
          ref: pointsSpentCityRef
        }, [
          _createVNode(_component_q_input, {
            clearable: "",
            modelValue: spentInputCityFrom.value,
            "onUpdate:modelValue": [
              _cache[4] || (_cache[4] = ($event: any) => ((spentInputCityFrom).value = $event)),
              _cache[5] || (_cache[5] = ($event: any) => (asideBtn(pointsSpentCityRef.value.firstChild)))
            ],
            outlined: "",
            filled: "",
            class: "col",
            label: "От"
          }, null, 8, ["modelValue"]),
          _cache[67] || (_cache[67] = _createElementVNode("img", {
            class: "col-shrink",
            src: _imports_0,
            alt: ""
          }, null, -1)),
          _createVNode(_component_q_input, {
            clearable: "",
            class: "col",
            modelValue: spentInputCityTo.value,
            "onUpdate:modelValue": [
              _cache[6] || (_cache[6] = ($event: any) => ((spentInputCityTo).value = $event)),
              _cache[7] || (_cache[7] = ($event: any) => (asideBtn(pointsSpentCityRef.value.firstChild)))
            ],
            outlined: "",
            filled: "",
            label: "До"
          }, null, 8, ["modelValue"])
        ], 512),
        _cache[74] || (_cache[74] = _createElementVNode("span", { class: "text-weight-bold" }, "Остаток городских баллов", -1)),
        _createElementVNode("div", {
          class: "aside-filters__points-current row",
          style: {"gap":"4px","position":"relative"},
          ref_key: "pointsCurrentCityRef",
          ref: pointsCurrentCityRef
        }, [
          _createVNode(_component_q_input, {
            clearable: "",
            modelValue: currentInputCityFrom.value,
            "onUpdate:modelValue": [
              _cache[8] || (_cache[8] = ($event: any) => ((currentInputCityFrom).value = $event)),
              _cache[9] || (_cache[9] = ($event: any) => (asideBtn(pointsCurrentCityRef.value.firstChild)))
            ],
            outlined: "",
            filled: "",
            class: "col",
            label: "От"
          }, null, 8, ["modelValue"]),
          _cache[68] || (_cache[68] = _createElementVNode("img", {
            class: "col-shrink",
            src: _imports_0,
            alt: ""
          }, null, -1)),
          _createVNode(_component_q_input, {
            clearable: "",
            class: "col",
            modelValue: currentInputCityTo.value,
            "onUpdate:modelValue": [
              _cache[10] || (_cache[10] = ($event: any) => ((currentInputCityTo).value = $event)),
              _cache[11] || (_cache[11] = ($event: any) => (asideBtn(pointsCurrentCityRef.value.firstChild)))
            ],
            outlined: "",
            filled: "",
            label: "До"
          }, null, 8, ["modelValue"])
        ], 512),
        _cache[75] || (_cache[75] = _createElementVNode("span", { class: "text-weight-bold" }, "Заработано призовых баллов", -1)),
        _createElementVNode("div", {
          class: "aside-filters__points-earned row",
          style: {"gap":"4px","position":"relative"},
          ref_key: "pointsEarnedPrizeRef",
          ref: pointsEarnedPrizeRef
        }, [
          _createVNode(_component_q_input, {
            clearable: "",
            modelValue: earnedInputPrizeFrom.value,
            "onUpdate:modelValue": [
              _cache[12] || (_cache[12] = ($event: any) => ((earnedInputPrizeFrom).value = $event)),
              _cache[13] || (_cache[13] = ($event: any) => (asideBtn(pointsEarnedPrizeRef.value.firstChild)))
            ],
            outlined: "",
            filled: "",
            class: "col",
            label: "От"
          }, null, 8, ["modelValue"]),
          _cache[69] || (_cache[69] = _createElementVNode("img", {
            class: "col-shrink",
            src: _imports_0,
            alt: ""
          }, null, -1)),
          _createVNode(_component_q_input, {
            clearable: "",
            class: "col",
            modelValue: earnedInputPrizeTo.value,
            "onUpdate:modelValue": [
              _cache[14] || (_cache[14] = ($event: any) => ((earnedInputPrizeTo).value = $event)),
              _cache[15] || (_cache[15] = ($event: any) => (asideBtn(pointsEarnedPrizeRef.value.firstChild)))
            ],
            outlined: "",
            filled: "",
            label: "До"
          }, null, 8, ["modelValue"])
        ], 512),
        _cache[76] || (_cache[76] = _createElementVNode("span", { class: "text-weight-bold" }, "Потрачено призовых баллов", -1)),
        _createElementVNode("div", {
          class: "aside-filters__points-spent row",
          style: {"gap":"4px","position":"relative"},
          ref_key: "pointsSpentPrizeRef",
          ref: pointsSpentPrizeRef
        }, [
          _createVNode(_component_q_input, {
            clearable: "",
            modelValue: spentInputPrizeFrom.value,
            "onUpdate:modelValue": [
              _cache[16] || (_cache[16] = ($event: any) => ((spentInputPrizeFrom).value = $event)),
              _cache[17] || (_cache[17] = ($event: any) => (asideBtn(pointsSpentPrizeRef.value.firstChild)))
            ],
            outlined: "",
            filled: "",
            class: "col",
            label: "От"
          }, null, 8, ["modelValue"]),
          _cache[70] || (_cache[70] = _createElementVNode("img", {
            class: "col-shrink",
            src: _imports_0,
            alt: ""
          }, null, -1)),
          _createVNode(_component_q_input, {
            clearable: "",
            class: "col",
            modelValue: spentInputPrizeTo.value,
            "onUpdate:modelValue": [
              _cache[18] || (_cache[18] = ($event: any) => ((spentInputPrizeTo).value = $event)),
              _cache[19] || (_cache[19] = ($event: any) => (asideBtn(pointsSpentPrizeRef.value.firstChild)))
            ],
            outlined: "",
            filled: "",
            label: "До"
          }, null, 8, ["modelValue"])
        ], 512),
        _cache[77] || (_cache[77] = _createElementVNode("span", { class: "text-weight-bold" }, "Остаток призовых баллов", -1)),
        _createElementVNode("div", {
          class: "aside-filters__points-current row",
          style: {"gap":"4px","position":"relative"},
          ref_key: "pointsCurrentPrizeRef",
          ref: pointsCurrentPrizeRef
        }, [
          _createVNode(_component_q_input, {
            clearable: "",
            modelValue: currentInputPrizeFrom.value,
            "onUpdate:modelValue": [
              _cache[20] || (_cache[20] = ($event: any) => ((currentInputPrizeFrom).value = $event)),
              _cache[21] || (_cache[21] = ($event: any) => (asideBtn(pointsCurrentPrizeRef.value.firstChild)))
            ],
            outlined: "",
            filled: "",
            class: "col",
            label: "От"
          }, null, 8, ["modelValue"]),
          _cache[71] || (_cache[71] = _createElementVNode("img", {
            class: "col-shrink",
            src: _imports_0,
            alt: ""
          }, null, -1)),
          _createVNode(_component_q_input, {
            clearable: "",
            class: "col",
            modelValue: currentInputPrizeTo.value,
            "onUpdate:modelValue": [
              _cache[22] || (_cache[22] = ($event: any) => ((currentInputPrizeTo).value = $event)),
              _cache[23] || (_cache[23] = ($event: any) => (asideBtn(pointsCurrentPrizeRef.value.firstChild)))
            ],
            outlined: "",
            filled: "",
            label: "До"
          }, null, 8, ["modelValue"])
        ], 512)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _cache[83] || (_cache[83] = _createElementVNode("span", { class: "text-weight-bold" }, "Количество активностей", -1)),
        _createElementVNode("div", {
          class: "aside-filters__activity-count row",
          style: {"gap":"4px","position":"relative"},
          ref_key: "activityCntRef",
          ref: activityCntRef
        }, [
          _createVNode(_component_q_input, {
            clearable: "",
            modelValue: activityInputFrom.value,
            "onUpdate:modelValue": [
              _cache[24] || (_cache[24] = ($event: any) => ((activityInputFrom).value = $event)),
              _cache[25] || (_cache[25] = ($event: any) => (asideBtn(activityCntRef.value.firstChild)))
            ],
            outlined: "",
            filled: "",
            class: "col",
            label: "От"
          }, null, 8, ["modelValue"]),
          _cache[78] || (_cache[78] = _createElementVNode("img", {
            class: "col-shrink",
            src: _imports_0,
            alt: ""
          }, null, -1)),
          _createVNode(_component_q_input, {
            clearable: "",
            class: "col",
            modelValue: activityInputTo.value,
            "onUpdate:modelValue": [
              _cache[26] || (_cache[26] = ($event: any) => ((activityInputTo).value = $event)),
              _cache[27] || (_cache[27] = ($event: any) => (asideBtn(activityCntRef.value.firstChild)))
            ],
            outlined: "",
            filled: "",
            label: "До"
          }, null, 8, ["modelValue"])
        ], 512),
        _cache[84] || (_cache[84] = _createElementVNode("span", { class: "text-weight-bold" }, "Первая активность", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_q_input, {
            clearable: "",
            filled: "",
            modelValue: dates.value.from,
            "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((dates.value.from) = $event)),
            label: "Дата начала",
            "hide-bottom-space": "",
            "hide-hint": "",
            mask: "####-##-##",
            readonly: "",
            style: {"width":"170px","cursor":"pointer"},
            "input-style": "cursor:pointer",
            onClick: _cache[32] || (_cache[32] = ($event: any) => (qDateProxy1.value.show())),
            class: "col",
            ref_key: "dateFromRef",
            ref: dateFromRef
          }, _createSlots({
            prepend: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: "event",
                class: "cursor-pointer"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_popup_proxy, {
                    cover: "",
                    "transition-show": "scale",
                    "transition-hide": "scale",
                    ref_key: "qDateProxy1",
                    ref: qDateProxy1
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_date, {
                        modelValue: dates.value.from,
                        "onUpdate:modelValue": [
                          _cache[28] || (_cache[28] = ($event: any) => ((dates.value.from) = $event)),
                          _cache[29] || (_cache[29] = ($event: any) => (clearDate(qDateProxy1.value)))
                        ],
                        mask: "YYYY-MM-DD",
                        "navigation-min-year-month": "2012/01",
                        "navigation-max-year-month": _unref(maxDateLimit),
                        "no-unset": ""
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, [
                            _withDirectives(_createVNode(_component_q_btn, {
                              label: "Close",
                              color: "primary",
                              flat: ""
                            }, null, 512), [
                              [_directive_close_popup]
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "navigation-max-year-month"])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              })
            ]),
            _: 2
          }, [
            (dates.value.from)
              ? {
                  name: "append",
                  fn: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      name: "cancel",
                      onClick: _cache[30] || (_cache[30] = _withModifiers(($event: any) => (clearDate(qDateProxy1.value, 'from')), ["stop","prevent"]))
                    })
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["modelValue"]),
          _cache[79] || (_cache[79] = _createElementVNode("img", {
            class: "col-shrink",
            src: _imports_0,
            alt: ""
          }, null, -1)),
          _createVNode(_component_q_input, {
            clearable: "",
            filled: "",
            modelValue: dates.value.to,
            "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((dates.value.to) = $event)),
            label: "Дата окончания",
            "hide-bottom-space": "",
            "hide-hint": "",
            mask: "####-##-##",
            readonly: "",
            style: {"width":"170px","cursor":"pointer"},
            "input-style": "cursor:pointer",
            onClick: _cache[37] || (_cache[37] = ($event: any) => (qDateProxy2.value.show())),
            class: "col",
            onBlur: _cache[38] || (_cache[38] = ($event: any) => (asideBtn($event)))
          }, _createSlots({
            prepend: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: "event",
                class: "cursor-pointer"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_popup_proxy, {
                    cover: "",
                    "transition-show": "scale",
                    "transition-hide": "scale",
                    ref_key: "qDateProxy2",
                    ref: qDateProxy2
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_date, {
                        modelValue: dates.value.to,
                        "onUpdate:modelValue": [
                          _cache[33] || (_cache[33] = ($event: any) => ((dates.value.to) = $event)),
                          _cache[34] || (_cache[34] = ($event: any) => (clearDate(qDateProxy2.value)))
                        ],
                        mask: "YYYY-MM-DD",
                        "navigation-min-year-month": "2012/01",
                        "navigation-max-year-month": _unref(maxDateLimit),
                        "no-unset": ""
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_6, [
                            _withDirectives(_createVNode(_component_q_btn, {
                              label: "Close",
                              color: "primary",
                              flat: ""
                            }, null, 512), [
                              [_directive_close_popup]
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "navigation-max-year-month"])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              })
            ]),
            _: 2
          }, [
            (dates.value.to)
              ? {
                  name: "append",
                  fn: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      name: "cancel",
                      onClick: _cache[35] || (_cache[35] = _withModifiers(($event: any) => (clearDate(qDateProxy2.value, 'to')), ["stop","prevent"]))
                    })
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["modelValue"])
        ]),
        _cache[85] || (_cache[85] = _createElementVNode("span", { class: "text-weight-bold" }, "Последняя активность", -1)),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_q_input, {
            clearable: "",
            filled: "",
            modelValue: dates.value.fromLast,
            "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => ((dates.value.fromLast) = $event)),
            label: "Дата Начала",
            "hide-bottom-space": "",
            "hide-hint": "",
            mask: "####-##-##",
            readonly: "",
            style: {"width":"170px","cursor":"pointer"},
            "input-style": "cursor:pointer",
            onClick: _cache[43] || (_cache[43] = ($event: any) => (qDateProxyLast1.value.show())),
            class: "col"
          }, _createSlots({
            prepend: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: "event",
                class: "cursor-pointer"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_popup_proxy, {
                    cover: "",
                    "transition-show": "scale",
                    "transition-hide": "scale",
                    ref_key: "qDateProxyLast1",
                    ref: qDateProxyLast1
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_date, {
                        modelValue: dates.value.fromLast,
                        "onUpdate:modelValue": [
                          _cache[39] || (_cache[39] = ($event: any) => ((dates.value.fromLast) = $event)),
                          _cache[40] || (_cache[40] = ($event: any) => (clearDate(qDateProxyLast1.value)))
                        ],
                        mask: "YYYY-MM-DD",
                        "navigation-min-year-month": "2012/01",
                        "navigation-max-year-month": _unref(maxDateLimit),
                        "no-unset": ""
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_8, [
                            _withDirectives(_createVNode(_component_q_btn, {
                              label: "Close",
                              color: "primary",
                              flat: ""
                            }, null, 512), [
                              [_directive_close_popup]
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "navigation-max-year-month"])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              })
            ]),
            _: 2
          }, [
            (dates.value.fromLast)
              ? {
                  name: "append",
                  fn: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      name: "cancel",
                      onClick: _cache[41] || (_cache[41] = _withModifiers(($event: any) => (clearDate(qDateProxyLast1.value, 'fromLast')), ["stop","prevent"]))
                    })
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["modelValue"]),
          _cache[80] || (_cache[80] = _createElementVNode("img", {
            class: "col-shrink",
            src: _imports_0,
            alt: ""
          }, null, -1)),
          _createVNode(_component_q_input, {
            clearable: "",
            filled: "",
            modelValue: dates.value.toLast,
            "onUpdate:modelValue": _cache[47] || (_cache[47] = ($event: any) => ((dates.value.toLast) = $event)),
            label: "Дата окончания",
            "hide-bottom-space": "",
            "hide-hint": "",
            mask: "####-##-##",
            readonly: "",
            style: {"width":"170px","cursor":"pointer"},
            "input-style": "cursor:pointer",
            onClick: _cache[48] || (_cache[48] = ($event: any) => (qDateProxyLast2.value.show())),
            class: "col",
            onBlur: _cache[49] || (_cache[49] = ($event: any) => (asideBtn($event)))
          }, _createSlots({
            prepend: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: "event",
                class: "cursor-pointer"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_popup_proxy, {
                    cover: "",
                    "transition-show": "scale",
                    "transition-hide": "scale",
                    ref_key: "qDateProxyLast2",
                    ref: qDateProxyLast2
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_date, {
                        modelValue: dates.value.toLast,
                        "onUpdate:modelValue": [
                          _cache[44] || (_cache[44] = ($event: any) => ((dates.value.toLast) = $event)),
                          _cache[45] || (_cache[45] = ($event: any) => (clearDate(qDateProxyLast2.value)))
                        ],
                        mask: "YYYY-MM-DD",
                        "navigation-min-year-month": "2012/01",
                        "navigation-max-year-month": _unref(maxDateLimit),
                        "no-unset": ""
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_9, [
                            _withDirectives(_createVNode(_component_q_btn, {
                              label: "Close",
                              color: "primary",
                              flat: ""
                            }, null, 512), [
                              [_directive_close_popup]
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "navigation-max-year-month"])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              })
            ]),
            _: 2
          }, [
            (dates.value.toLast)
              ? {
                  name: "append",
                  fn: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      name: "cancel",
                      onClick: _cache[46] || (_cache[46] = _withModifiers(($event: any) => (clearDate(qDateProxyLast2.value, 'toLast')), ["stop","prevent"]))
                    })
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["modelValue"])
        ]),
        _cache[86] || (_cache[86] = _createElementVNode("span", { class: "text-weight-bold" }, "Пол", -1)),
        _createVNode(GroupSelectFilterArea, {
          label: "Пол",
          "root-label": "Выбрать все",
          options: sexList.value,
          onOnSelected: onSelectedDropdown,
          ref: (el) => groupFilters.value['gender'] = el
        }, null, 8, ["options"]),
        _cache[87] || (_cache[87] = _createElementVNode("span", { class: "text-weight-bold" }, "Дата рождения", -1)),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_q_input, {
            clearable: "",
            filled: "",
            modelValue: dates.value.fromBirth,
            "onUpdate:modelValue": _cache[53] || (_cache[53] = ($event: any) => ((dates.value.fromBirth) = $event)),
            label: "Дата Начала",
            "hide-bottom-space": "",
            "hide-hint": "",
            mask: "####-##-##",
            readonly: "",
            style: {"width":"170px","cursor":"pointer"},
            "input-style": "cursor:pointer",
            onClick: _cache[54] || (_cache[54] = ($event: any) => (qDateProxyBirth1.value.show())),
            class: "col"
          }, _createSlots({
            prepend: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: "event",
                class: "cursor-pointer"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_popup_proxy, {
                    cover: "",
                    "transition-show": "scale",
                    "transition-hide": "scale",
                    ref_key: "qDateProxyBirth1",
                    ref: qDateProxyBirth1
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_date, {
                        modelValue: dates.value.fromBirth,
                        "onUpdate:modelValue": [
                          _cache[50] || (_cache[50] = ($event: any) => ((dates.value.fromBirth) = $event)),
                          _cache[51] || (_cache[51] = ($event: any) => (clearDate(qDateProxyBirth1.value)))
                        ],
                        mask: "YYYY-MM-DD",
                        "navigation-min-year-month": "1950/01",
                        "navigation-max-year-month": _unref(maxDateLimit),
                        "no-unset": ""
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_11, [
                            _withDirectives(_createVNode(_component_q_btn, {
                              label: "Close",
                              color: "primary",
                              flat: ""
                            }, null, 512), [
                              [_directive_close_popup]
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "navigation-max-year-month"])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              })
            ]),
            _: 2
          }, [
            (dates.value.fromBirth)
              ? {
                  name: "append",
                  fn: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      name: "cancel",
                      onClick: _cache[52] || (_cache[52] = _withModifiers(($event: any) => (clearDate(qDateProxyBirth1.value, 'fromBirth')), ["stop","prevent"]))
                    })
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["modelValue"]),
          _cache[81] || (_cache[81] = _createElementVNode("img", {
            class: "col-shrink",
            src: _imports_0,
            alt: ""
          }, null, -1)),
          _createVNode(_component_q_input, {
            clearable: "",
            filled: "",
            modelValue: dates.value.toBirth,
            "onUpdate:modelValue": _cache[58] || (_cache[58] = ($event: any) => ((dates.value.toBirth) = $event)),
            label: "Дата окончания",
            "hide-bottom-space": "",
            "hide-hint": "",
            mask: "####-##-##",
            readonly: "",
            style: {"width":"170px","cursor":"pointer"},
            "input-style": "cursor:pointer",
            onClick: _cache[59] || (_cache[59] = ($event: any) => (qDateProxyBirth2.value.show())),
            class: "col",
            onBlur: _cache[60] || (_cache[60] = ($event: any) => (asideBtn($event)))
          }, _createSlots({
            prepend: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: "event",
                class: "cursor-pointer"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_popup_proxy, {
                    cover: "",
                    "transition-show": "scale",
                    "transition-hide": "scale",
                    ref_key: "qDateProxyBirth2",
                    ref: qDateProxyBirth2
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_date, {
                        modelValue: dates.value.toBirth,
                        "onUpdate:modelValue": [
                          _cache[55] || (_cache[55] = ($event: any) => ((dates.value.toBirth) = $event)),
                          _cache[56] || (_cache[56] = ($event: any) => (clearDate(qDateProxyBirth2.value)))
                        ],
                        mask: "YYYY-MM-DD",
                        "navigation-min-year-month": "1950/01",
                        "navigation-max-year-month": _unref(maxDateLimit),
                        "no-unset": ""
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_12, [
                            _withDirectives(_createVNode(_component_q_btn, {
                              label: "Close",
                              color: "primary",
                              flat: ""
                            }, null, 512), [
                              [_directive_close_popup]
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "navigation-max-year-month"])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              })
            ]),
            _: 2
          }, [
            (dates.value.toBirth)
              ? {
                  name: "append",
                  fn: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      name: "cancel",
                      onClick: _cache[57] || (_cache[57] = _withModifiers(($event: any) => (clearDate(qDateProxyBirth2.value, 'toBirth')), ["stop","prevent"]))
                    })
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["modelValue"])
        ]),
        _cache[88] || (_cache[88] = _createElementVNode("span", { class: "text-weight-bold" }, "Семейное положение", -1)),
        _createVNode(GroupSelectFilterArea, {
          label: "Семейное положение",
          "root-label": "Выбрать все",
          options: familyList.value,
          loading: isFamilyBusy.value,
          onOnSelected: onSelectedDropdown,
          ref: (el) => groupFilters.value['familyStatus'] = el
        }, null, 8, ["options", "loading"]),
        _cache[89] || (_cache[89] = _createElementVNode("span", { class: "text-weight-bold" }, "Количество детей", -1)),
        _createElementVNode("div", {
          class: "aside-filters__points-current row",
          style: {"gap":"4px","position":"relative"},
          ref_key: "currentChildRef",
          ref: currentChildRef
        }, [
          _createVNode(_component_q_input, {
            clearable: "",
            modelValue: currentChildInputFrom.value,
            "onUpdate:modelValue": [
              _cache[61] || (_cache[61] = ($event: any) => ((currentChildInputFrom).value = $event)),
              _cache[62] || (_cache[62] = ($event: any) => (asideBtn(currentChildRef.value.firstChild)))
            ],
            outlined: "",
            filled: "",
            class: "col",
            label: "От"
          }, null, 8, ["modelValue"]),
          _cache[82] || (_cache[82] = _createElementVNode("img", {
            class: "col-shrink",
            src: _imports_0,
            alt: ""
          }, null, -1)),
          _createVNode(_component_q_input, {
            clearable: "",
            class: "col",
            modelValue: currentChildInputTo.value,
            "onUpdate:modelValue": [
              _cache[63] || (_cache[63] = ($event: any) => ((currentChildInputTo).value = $event)),
              _cache[64] || (_cache[64] = ($event: any) => (asideBtn(currentChildRef.value.firstChild)))
            ],
            outlined: "",
            filled: "",
            label: "До"
          }, null, 8, ["modelValue"])
        ], 512),
        _cache[90] || (_cache[90] = _createElementVNode("span", { class: "text-weight-bold" }, "Род деятельности", -1)),
        _createVNode(GroupSelectFilterArea, {
          label: "Род деятельности",
          "root-label": "Выбрать все",
          options: socialList.value,
          loading: isSocialBusy.value,
          onOnSelected: onSelectedDropdown,
          ref: (el) => groupFilters.value['occupation'] = el
        }, null, 8, ["options", "loading"]),
        _cache[91] || (_cache[91] = _createElementVNode("span", { class: "text-weight-bold" }, "Тип учетной записи", -1)),
        _createVNode(GroupSelectFilterArea, {
          label: "Тип учетной записи",
          "root-label": "Выбрать все",
          options: typeList.value,
          loading: isTypeList.value,
          onOnSelected: onSelectedDropdown,
          ref: (el) => groupFilters.value['accountType'] = el,
          "node-key": 'value'
        }, null, 8, ["options", "loading"])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _cache[92] || (_cache[92] = _createElementVNode("span", { class: "text-weight-bold" }, "Округ и район", -1)),
        _createVNode(GroupSelectFilterArea, {
          label: "Округ",
          "root-label": "Выбрать все",
          options: regionList.value,
          loading: isRegionBusy.value,
          onOnSelected: onSelectedDropdown,
          ref: (el) => groupFilters.value['District'] = el
        }, null, 8, ["options", "loading"]),
        _createVNode(GroupSelectFilterArea, {
          label: "Район",
          "root-label": "Выбрать все",
          options: districtList.value,
          loading: isDistrictBusy.value,
          onOnSelected: onSelectedDropdown,
          ref: (el) => groupFilters.value['Area'] = el
        }, null, 8, ["options", "loading"])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _cache[93] || (_cache[93] = _createElementVNode("span", { class: "text-weight-bold" }, "Пользователь заблокирован?", -1)),
        _createVNode(GroupSelectFilterArea, {
          label: "Пользователь заблокирован?",
          "root-label": "Выбрать все",
          options: blockedList.value,
          loading: false,
          onOnSelected: onSelectedDropdown,
          ref: (el) => groupFilters.value['blocked'] = el,
          "node-key": 'value'
        }, null, 8, ["options"])
      ]),
      _createVNode(_component_q_btn, {
        color: "primary",
        label: "Применить фильтры",
        style: {"width":"100%","margin-bottom":"8px"},
        align: "left",
        onClick: applyFilters
      }),
      _createVNode(_component_q_btn, {
        color: "secondary",
        label: "Сбросить фильтры",
        style: {"width":"100%"},
        align: "left",
        onClick: resetFields
      })
    ], 512),
    _createElementVNode("div", {
      ref_key: "floatingBtn",
      ref: floatingBtn,
      class: "floating-confirm-btn"
    }, [
      _createVNode(_component_q_btn, {
        onClick: applyFilters,
        color: "primary",
        label: "Применить"
      })
    ], 512)
  ], 64))
}
}

})