// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "class_tag.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { ClassTagService } from "./class_tag";
import type { SaveClassTagResponse } from "./class_tag";
import type { SaveClassTagRequest } from "./class_tag";
import type { DeleteClassTagResponse } from "./class_tag";
import type { DeleteClassTagRequest } from "./class_tag";
import type { UpdateClassTagResponse } from "./class_tag";
import type { UpdateClassTagRequest } from "./class_tag";
import type { CreateClassTagResponse } from "./class_tag";
import type { CreateClassTagRequest } from "./class_tag";
import type { ItemClassTagResponse } from "./class_tag";
import type { ItemClassTagRequest } from "./class_tag";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListClassTagResponse } from "./class_tag";
import type { ListClassTagRequest } from "./class_tag";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.ClassTagService
 */
export interface IClassTagServiceClient {
    /**
     * @generated from protobuf rpc: ListClassTag(proto.nigmamsg.ListClassTagRequest) returns (proto.nigmamsg.ListClassTagResponse);
     */
    listClassTag(input: ListClassTagRequest, options?: RpcOptions): UnaryCall<ListClassTagRequest, ListClassTagResponse>;
    /**
     * @generated from protobuf rpc: ItemClassTag(proto.nigmamsg.ItemClassTagRequest) returns (proto.nigmamsg.ItemClassTagResponse);
     */
    itemClassTag(input: ItemClassTagRequest, options?: RpcOptions): UnaryCall<ItemClassTagRequest, ItemClassTagResponse>;
    /**
     * @generated from protobuf rpc: CreateClassTag(proto.nigmamsg.CreateClassTagRequest) returns (proto.nigmamsg.CreateClassTagResponse);
     */
    createClassTag(input: CreateClassTagRequest, options?: RpcOptions): UnaryCall<CreateClassTagRequest, CreateClassTagResponse>;
    /**
     * @generated from protobuf rpc: UpdateClassTag(proto.nigmamsg.UpdateClassTagRequest) returns (proto.nigmamsg.UpdateClassTagResponse);
     */
    updateClassTag(input: UpdateClassTagRequest, options?: RpcOptions): UnaryCall<UpdateClassTagRequest, UpdateClassTagResponse>;
    /**
     * @generated from protobuf rpc: DeleteClassTag(proto.nigmamsg.DeleteClassTagRequest) returns (proto.nigmamsg.DeleteClassTagResponse);
     */
    deleteClassTag(input: DeleteClassTagRequest, options?: RpcOptions): UnaryCall<DeleteClassTagRequest, DeleteClassTagResponse>;
    /**
     * @generated from protobuf rpc: SaveClassTag(proto.nigmamsg.SaveClassTagRequest) returns (proto.nigmamsg.SaveClassTagResponse);
     */
    saveClassTag(input: SaveClassTagRequest, options?: RpcOptions): UnaryCall<SaveClassTagRequest, SaveClassTagResponse>;
}
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.ClassTagService
 */
export class ClassTagServiceClient implements IClassTagServiceClient, ServiceInfo {
    typeName = ClassTagService.typeName;
    methods = ClassTagService.methods;
    options = ClassTagService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ListClassTag(proto.nigmamsg.ListClassTagRequest) returns (proto.nigmamsg.ListClassTagResponse);
     */
    listClassTag(input: ListClassTagRequest, options?: RpcOptions): UnaryCall<ListClassTagRequest, ListClassTagResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListClassTagRequest, ListClassTagResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ItemClassTag(proto.nigmamsg.ItemClassTagRequest) returns (proto.nigmamsg.ItemClassTagResponse);
     */
    itemClassTag(input: ItemClassTagRequest, options?: RpcOptions): UnaryCall<ItemClassTagRequest, ItemClassTagResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ItemClassTagRequest, ItemClassTagResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateClassTag(proto.nigmamsg.CreateClassTagRequest) returns (proto.nigmamsg.CreateClassTagResponse);
     */
    createClassTag(input: CreateClassTagRequest, options?: RpcOptions): UnaryCall<CreateClassTagRequest, CreateClassTagResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateClassTagRequest, CreateClassTagResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateClassTag(proto.nigmamsg.UpdateClassTagRequest) returns (proto.nigmamsg.UpdateClassTagResponse);
     */
    updateClassTag(input: UpdateClassTagRequest, options?: RpcOptions): UnaryCall<UpdateClassTagRequest, UpdateClassTagResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateClassTagRequest, UpdateClassTagResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteClassTag(proto.nigmamsg.DeleteClassTagRequest) returns (proto.nigmamsg.DeleteClassTagResponse);
     */
    deleteClassTag(input: DeleteClassTagRequest, options?: RpcOptions): UnaryCall<DeleteClassTagRequest, DeleteClassTagResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteClassTagRequest, DeleteClassTagResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SaveClassTag(proto.nigmamsg.SaveClassTagRequest) returns (proto.nigmamsg.SaveClassTagResponse);
     */
    saveClassTag(input: SaveClassTagRequest, options?: RpcOptions): UnaryCall<SaveClassTagRequest, SaveClassTagResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<SaveClassTagRequest, SaveClassTagResponse>("unary", this._transport, method, opt, input);
    }
}
