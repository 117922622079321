import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "q-pa-md col-12 items-start q-gutter-md" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "self-center full-width no-outline" }
const _hoisted_5 = { class: "self-center full-width no-outline" }
const _hoisted_6 = { class: "self-center full-width no-outline" }
const _hoisted_7 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_field = _resolveComponent("q-field")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_splitter = _resolveComponent("q-splitter")!
  const _component_DataLoading = _resolveComponent("DataLoading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_q_card, null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_1, [
          _createVNode(_component_q_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_label, { class: "text-h5" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.node.title), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_q_separator),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_q_splitter, {
              modelValue: _ctx.splitterSize,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.splitterSize) = $event))
            }, {
              before: _withCtx(() => [
                _createVNode(_component_q_card, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      ('' !== _ctx.node.parentName)
                        ? (_openBlock(), _createBlock(_component_q_card_section, { key: 0 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_field, {
                                "model-value": _ctx.node.parent_id,
                                label: "Родительский показатель"
                              }, {
                                control: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.node.parentName), 1)
                                ]),
                                _: 1
                              }, 8, ["model-value"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_q_card_section, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_field, {
                            "model-value": _ctx.node.title,
                            label: "Наименование"
                          }, {
                            control: _withCtx(() => [
                              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.node.title), 1)
                            ]),
                            _: 1
                          }, 8, ["model-value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_card_section, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_field, {
                            "model-value": _ctx.node.weight,
                            label: "Вес"
                          }, {
                            control: _withCtx(() => [
                              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.node.weight), 1)
                            ]),
                            _: 1
                          }, 8, ["model-value"])
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                })
              ]),
              after: _withCtx(() => [
                _createVNode(_component_q_table, {
                  style: {"height":"100%"},
                  title: "Значения",
                  rows: _ctx.getScorecardValueItems,
                  columns: _ctx.columns,
                  "row-key": "id",
                  loading: _ctx.isScorecardValueBusy
                }, {
                  "no-data": _withCtx(() => _cache[1] || (_cache[1] = [
                    _createElementVNode("div", { class: "col-12 text-center" }, "Нет данных", -1)
                  ])),
                  _: 1
                }, 8, ["rows", "columns", "loading"])
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ], 512), [
          [_vShow, _ctx.isSelectedScorecardNode]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_7, [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_label, { class: "text-h5 text-center" }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Не выбран узел дерева")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ], 512), [
          [_vShow, !_ctx.isSelectedScorecardNode]
        ])
      ]),
      _: 1
    }, 512), [
      [_vShow, !_ctx.isScorecardBusy]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_DataLoading)
    ], 512), [
      [_vShow, _ctx.isScorecardBusy]
    ])
  ], 64))
}