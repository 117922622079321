import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {onMounted, ref} from 'vue'
import {ClassTagModel} from "proto/nigma-message-proto/polls/class_tag";


export default /*@__PURE__*/_defineComponent({
  __name: 'TagAdd',
  emits: ['afterAdd', 'hideModal'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const classTagItem = ref<ClassTagModel>({
  classId: 0,
  tagName: '',
  tagDescr: '',
})

const saveTag = () => {
  if (!classTagItem.value.tagName) {
    console.log('Наименование тэга не введено')
    return false
  }

  emit('afterAdd', classTagItem.value)
}

const closeTagAdd = () => emit('hideModal')

const loadData = () => {
  classTagItem.value = {
    classId: 0,
    tagName: '',
    tagDescr: '',
  }
}

onMounted(() => loadData())

return (_ctx: any,_cache: any) => {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    style: {"width":"1024px","max-width":"80vw","max-height":"80vh"},
    class: "q-pa-md"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_label, { class: "text-h5" }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Создание тэга")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_separator),
      _createVNode(_component_q_form, {
        onSubmit: _withModifiers(saveTag, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_input, {
                modelValue: classTagItem.value.tagName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((classTagItem.value.tagName) = $event)),
                label: "Наименование",
                maxlength: "32"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_input, {
                type: "textarea",
                modelValue: classTagItem.value.tagDescr,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((classTagItem.value.tagDescr) = $event)),
                label: "Описание"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_separator),
          _createVNode(_component_q_card_actions, { align: "right" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                type: "submit",
                color: "primary"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" Сохранить ")
                ])),
                _: 1
              }),
              _createVNode(_component_q_btn, {
                color: "primary",
                onClick: closeTagAdd
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" Отмена ")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})