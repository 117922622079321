import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-black checkbox-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_option_group = _resolveComponent("q-option-group")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, { class: "col-12 q-pa-md q-mb-md column" }, {
    default: _withCtx(() => [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-h6" }, "Периоды", -1)),
      _createVNode(_component_q_option_group, {
        modelValue: _ctx.checkedProjects,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkedProjects) = $event)),
          _ctx.updateCheckedPeriod
        ],
        options: _ctx.getPeriodItems,
        color: "secondary",
        type: "checkbox",
        inline: _ctx.isGroupInline
      }, {
        label: _withCtx((opt) => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(opt.label), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "options", "inline", "onUpdate:modelValue"])
    ]),
    _: 1
  }))
}