import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { style: {"height":"100%","box-shadow":"none","position":"relative"} }
const _hoisted_2 = {
  class: "q-pa-md col-12",
  style: {"height":"100%"}
}
const _hoisted_3 = {
  class: "row items-center",
  style: {"width":"100%"}
}
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "text-weight-bold" }
const _hoisted_6 = {
  key: 0,
  class: "col-3 q-ml-auto q-mr-sm"
}
const _hoisted_7 = {
  key: 1,
  class: "col-3 q-ml-auto q-mr-sm"
}
const _hoisted_8 = { class: "col-3" }
const _hoisted_9 = { class: "row q-ml-sm" }
const _hoisted_10 = {
  class: "col-12",
  style: {"width":"100%","height":"calc(100% - 73px)","margin":"10px 0px 0px 10px"}
}
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "row justify-center" }
const _hoisted_15 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_DataLoading = _resolveComponent("DataLoading")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_MessageFormSingle = _resolveComponent("MessageFormSingle")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "col-3 text-subtitle1 ellipsis-2-lines overflow-hidden",
          title: _ctx.getCurrentMessageResult.text,
          style: {"overflow":"auto"}
        }, [
          _cache[7] || (_cache[7] = _createTextVNode(" Сообщение: ")),
          _createElementVNode("span", _hoisted_5, "(" + _toDisplayString(_ctx.getCurrentMessageResult.sum) + ") " + _toDisplayString(_ctx.getCurrentMessageResult.text), 1)
        ], 8, _hoisted_4),
        (_ctx.findType.value === 'similar')
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_q_input, {
                type: "number",
                modelValue: _ctx.similarity,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.similarity) = $event)),
                filled: "",
                min: "0.01",
                max: "0.99",
                step: "0.01",
                label: "Коэффициент схожести",
                onKeydown: _withKeys(_withModifiers(_ctx.findMessages, ["prevent"]), ["enter"])
              }, null, 8, ["modelValue", "onKeydown"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.findType.value === 'single')
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_q_input, {
                filled: "",
                modelValue: _ctx.search,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
                label: "Текст для поиска",
                onKeydown: _withKeys(_withModifiers(_ctx.findMessages, ["prevent"]), ["enter"])
              }, null, 8, ["modelValue", "onKeydown"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_q_btn, {
          style: {"padding":"10px","margin-right":"10px"},
          "background-color": "gray-12",
          icon: "mdi-magnify",
          onClick: _ctx.findMessages
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tooltip, null, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode("Найти ответы")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_q_select, {
            options: _ctx.findTypeOptions,
            modelValue: _ctx.findType,
            "onUpdate:modelValue": [
              _cache[2] || (_cache[2] = ($event: any) => ((_ctx.findType) = $event)),
              _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeFindType(_ctx.findType)))
            ],
            filled: "",
            "option-value": "value",
            "option-label": "title",
            label: "Тип поиска"
          }, null, 8, ["options", "modelValue"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          ('admin editor'.includes(_ctx.userRole))
            ? (_openBlock(), _createBlock(_component_q_btn, {
                key: 0,
                color: "primary",
                disable: _ctx.isMessageGrpWorkedBusy,
                label: "ОБОБЩИТЬ",
                onClick: _ctx.makeActionWorked,
                style: {"height":"56px"}
              }, null, 8, ["disable", "onClick"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_10, [
        _createVNode(_component_q_table, {
          style: _normalizeStyle([{"overflow":"auto"}, _ctx.getMessageGrpWorkedItems.length ? 'height: 63vh' : null]),
          class: "sticky-header-table-answer",
          flat: "",
          dense: "",
          rows: _ctx.getMessageGrpWorkedItems,
          columns: _ctx.columnsWorked,
          "row-key": "id",
          selection: 'admin editor'.includes(_ctx.userRole) ? 'multiple' : 'none',
          "wrap-cells": "",
          selected: _ctx.selectedWorked,
          "onUpdate:selected": [
            _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedWorked) = $event)),
            _ctx.onUpdateSelectedWorked
          ],
          loading: _ctx.isMessageGrpWorkedBusy,
          pagination: _ctx.workedPagination,
          "onUpdate:pagination": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.workedPagination) = $event)),
          "rows-per-page-options": _ctx.rowsPerPageWorkedOptions,
          onRequest: _ctx.onRequestWorked
        }, {
          "body-cell-text": _withCtx((props) => [
            _createVNode(_component_q_td, { props: props }, {
              default: _withCtx(() => [
                (_ctx.markedFind.length)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      innerHTML: _ctx.markedAll(props.value)
                    }, null, 8, _hoisted_11))
                  : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(props.value), 1))
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-sum": _withCtx((props) => [
            _createVNode(_component_q_td, {
              props: props,
              style: {"width":"60px"}
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  style: {"cursor":"pointer"},
                  title: "Исходные ответы",
                  onClick: ($event: any) => (_ctx.onRowShowMessageSource(props.row, true))
                }, _toDisplayString(props.value), 9, _hoisted_13)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "body-cell-similarity": _withCtx((props) => [
            _withDirectives(_createVNode(_component_q_td, {
              props: props,
              style: {"width":"80px"}
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(props.value.toFixed(4)), 1)
              ]),
              _: 2
            }, 1032, ["props"]), [
              [_vShow, _ctx.findType.value = 'similar']
            ])
          ]),
          "body-cell-action": _withCtx((props) => [
            _createElementVNode("div", _hoisted_14, [
              ('admin editor'.includes(_ctx.userRole))
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 0,
                    flat: "",
                    dense: "",
                    round: "",
                    icon: "mdi-dots-vertical"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_menu, { "auto-close": "" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_list, { dense: "" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_item, {
                                clickable: "",
                                onClick: ($event: any) => (_ctx.actionRow('split', props.row))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_item_section, null, {
                                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                                      _createTextVNode("Разбить")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true)
            ])
          ]),
          "no-data": _withCtx(() => _cache[10] || (_cache[10] = [
            _createElementVNode("div", { class: "col-12 text-center text-h4" }, "Нет данных", -1)
          ])),
          _: 1
        }, 8, ["style", "rows", "columns", "selection", "selected", "loading", "pagination", "rows-per-page-options", "onRequest", "onUpdate:selected"])
      ], 512), [
        [_vShow, !_ctx.isMessageGrpWorkedBusy]
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createVNode(_component_DataLoading, { msgLoading: "Загрузка" })
      ], 512), [
        [_vShow, _ctx.isMessageGrpWorkedBusy]
      ])
    ], 512), [
      [_vShow, _ctx.isCurrentMessageResult]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_15, [
      _createVNode(_component_q_card_section, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_label, { class: "text-h5 text-center" }, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [
              _createTextVNode("Не выбран ответ в левой панели")
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ], 512), [
      [_vShow, !_ctx.isCurrentMessageResult]
    ]),
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.isActiveModal,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isActiveModal) = $event)),
      persistent: ""
    }, {
      default: _withCtx(() => [
        (_ctx.isActiveModalSplitSingle)
          ? (_openBlock(), _createBlock(_component_MessageFormSingle, {
              key: 0,
              message: _ctx.currentActionMessage,
              onAfterActionSplitSingle: _ctx.afterActionSplitSingle,
              onHideModal: _ctx.hideModal
            }, null, 8, ["message", "onAfterActionSplitSingle", "onHideModal"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}