import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row relative-position" }

import store from '@/store'
import {computed} from 'vue'
import * as XLSX from 'xlsx'


export default /*@__PURE__*/_defineComponent({
  __name: 'ExportAnswers',
  props: ['filter', 'sort', 'answer_grp_id', 'is_full'],
  setup(__props) {

const props = __props

const getExportTable = computed(() => {
  const rawAnswerItems = store.getters.getExportAnswerSrcSourceItems

  let rows: any = []
  if (rawAnswerItems !== undefined) {
    for (let answerItem of rawAnswerItems) {
      rows.push({
        'Код': answerItem.getAnswerSrcId(),
        'Пользователь': answerItem.getSsoId() ?? 'Не задано',
        'Ответ': answerItem.getAnswerSrcText(),
        'Время': getFormattedDateTimeOriginFromTimestamp(answerItem.getAnswerSrcTime()),
      })
    }
  }
  return rows
})

const isExport = computed(() => store.getters.getIsExportAnswerSrcSourceItems)

const loadData = (isCsv: boolean) => {
  return store.dispatch('getExportAnswerSrcSourceItems', {
    filter: props.filter,
    sort: {
      name: props.sort.name,
      exp: props.sort.exp ? 'desc' : 'asc',
    },
    pagination: {
      page: 1,
      limit: isCsv ? 250000 : 150000,
      pages: 0,
      cnt: 0,
    },
    answer_grp_id: props.answer_grp_id.id,
    is_full: props.is_full,
  })
}

const exportData = async (type: string) => {
  let filename = ''
  if (type == 'xlsx') {
    await loadData(false)

    filename = `Таблица исходных ответов '` + props.answer_grp_id.text +`'.xlsx`
  } else {
    await loadData(true)

    filename = `Таблица исходных ответов '` + props.answer_grp_id.text +`'.csv`
  }
  let ws = XLSX.utils.json_to_sheet(getExportTable.value)
  let wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Таблица исходных ответов')
  XLSX.writeFile(wb, filename)
}

const getFormattedDateTimeOriginFromTimestamp = (timestamp: any) => {
  const datetime = new Date(1000 * timestamp)
  return (
      (datetime.getHours().toString().length == 1 ? '0' : '') +
      datetime.getHours() +
      ':' +
      (datetime.getMinutes().toString().length == 1 ? '0' : '') +
      datetime.getMinutes() +
      ' ' +
      (datetime.getDate().toString().length == 1 ? '0' : '') +
      datetime.getDate() +
      '.' +
      ((datetime.getMonth() + 1).toString().length == 1 ? '0' : '') +
      (datetime.getMonth() + 1) +
      '.' +
      datetime.getFullYear()
  )
}

return (_ctx: any,_cache: any) => {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_btn_dropdown = _resolveComponent("q-btn-dropdown")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_btn_dropdown, {
      label: "Экспорт",
      color: "secondary",
      stretch: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_list, null, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createBlock(_component_q_item, {
              clickable: "",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (exportData('xlsx')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_label, null, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("Excel")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })), [
              [_directive_close_popup]
            ]),
            _withDirectives((_openBlock(), _createBlock(_component_q_item, {
              clickable: "",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (exportData('csv')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_label, null, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("CSV")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })), [
              [_directive_close_popup]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_q_inner_loading, {
      showing: isExport.value,
      color: "grey"
    }, null, 8, ["showing"])
  ]))
}
}

})