import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "relative-position",
  style: {"width":"500px","height":"300px"}
}

import { ref, toRaw, watchEffect, computed, watch } from 'vue'
import store from '@/store'
import AccountListModal from './AccountListModal.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'StandartAlg',
  setup(__props) {

const isAccountsDialogVisible = ref(false)
const initialReq = ref(false)
const saveAccountsFilter = ref()
const intersectionNames = ref()
const accountsDialogPagination = ref({
  sortBy: '',
  descending: true,
  page: 1,
  rowsPerPage: 10,
  rowsNumber: 0,
})

const accountsMeticsTableRequest = (pagination: any) => {
  accountsDialogPagination.value = pagination
  store
    .dispatch('getAccountListValues', {
      filter: saveAccountsFilter.value,
      pagination: {
        page: pagination.page,
        limit: accountsDialogPagination.value.rowsPerPage,
        pages: 0,
        cnt: pagination.rowsNumber,
      },
    })
    .then((res) => {
      accountsDialogPagination.value.rowsNumber = res
        .getParams()
        .getPagination()
        .getCnt()
    })
  console.log('TABLE REQUEST ', pagination)
}

const chartOptions = ref({
  chart: {
    type: 'heatmap',
    // marginTop: 40,
    // marginBottom: 80,
    marginRight: 40,
    plotBorderWidth: 1,
    // width: 1770,
  },
  // colors: ['#000000'],

  title: {
    text: 'Пересечение метрик',
  },

  xAxis: {
    categories: ['Низкая', 'Умеренная', 'Высокая', 'Супер'] as any,
    title: {
      text: '',
    },
  },

  yAxis: {
    categories: ['Супер', 'Высокая', 'Умеренная', 'Низкая'] as any,
    title: {
      text: '',
    },
    reversed: true,
  },

  // accessibility: {
  //   point: {
  //     descriptionFormatter: function (point: any) {
  //       var ix = point.index + 1,
  //         xName = getPointCategoryName(point, 'x'),
  //         yName = getPointCategoryName(point, 'y'),
  //         val = point.value
  //       return ix + '. ' + xName + ' sales ' + yName + ', ' + val + '.'
  //     },
  //   },
  // },
  colorAxis: {
    maxColor: '#1976D2',
    minColor: '#fff',
    // min: 0,
    // max: null,
    // stops: [
    //   [0.1, '#FFF'],
    //   [0.3, '#E3F2FD'],
    //   [0.7, '#64B5F6'],
    //   [1, '#1976D2'],
    // ],
    // labels: {
    //   step: 4,
    //   enabled: true,
    //   formatter: function () {
    //     return this.value === 0 ? 'Free' : 'Full'
    //   },
    // },
  },
  plotOptions: {
    series: {
      keys: ['x', 'y', 'value', 'color'],
      cursor: 'pointer',
      events: {
        click: function (e: any) {
          console.log('CLICK E', e)
          initialReq.value = true
          saveAccountsFilter.value = {
            date_from: date.value.dateFrom,
            date_to: date.value.dateTo,
            from_metric_alias: checkedMetrics.value.fromName,
            to_metric_alias: checkedMetrics.value.toName,
            from_metric_value_id: e.point.options.metricFrom,
            to_metric_value_id: e.point.options.metricTo,
            is_add_param: settings.value.addParameters || false,
            project_ids:
              getProjectIds.value && getProjectIds.value.length
                ? toRaw(getProjectIds.value)
                : [1, 2, 3, 4, 5, 6],
          }

          intersectionNames.value = {
            from: checkedMetrics.value.fromName,
            to: checkedMetrics.value.toName,
          }
          store
            .dispatch('getAccountListValues', {
              filter: saveAccountsFilter.value,
              pagination: {
                page: 1,
                limit: accountsDialogPagination.value.rowsPerPage,
                pages: 0,
                cnt: 0,
              },
            })
            .then((res) => {
              accountsDialogPagination.value.rowsNumber = res
                .getParams()
                .getPagination()
                .getCnt()

              initialReq.value = false
              console.log('RESSSS', res.getParams().getPagination())
            })
          isAccountsDialogVisible.value = true
        },
      },
    },
  },
  legend: {
    enabled: false,
  },

  tooltip: {
    // eslint-disable-next-line
    formatter: function (this: any) {
      return 'Всего пользователей: ' + this.point.value
    } as any,
  },
  //super #1976D2
  //high #64B5F6
  //neutral #E3F2FD
  //low #FFF
  series: [
    {
      name: 'Количество',
      borderWidth: 1,
      data: [
        // [0, 0, 22154],
        // [3, 0, 16521],
        // [0, 2, 7185],
        // [0, 3, 785],
        // [1, 0, 115874],
        // [1, 1, 241873],
        // [1, 2, 315241],
        // [1, 3, 1074],
        // [2, 0, 114789],
        // [2, 1, 216348],
        // [2, 2, 318542],
        // [2, 3, 847],
        // [0, 1, 14876],
        // [3, 1, 145831],
        // [3, 2, 141254],
        // [3, 3, 567],
      ] as any,
      dataLabels: {
        enabled: true,
        color: '#000000',
      },
    },
  ],
})

const isSegmentationBusy = computed(() => store.getters.getIsSegmentationBusy)
const categories = computed(() => store.getters.getCheckedMetricsNames)
const date = computed(() => store.getters.getDateForEventList)
const checkedMetrics = computed(() => store.getters.getCheckedMetrics)
const settings = computed(() => store.getters.getModalSettings)
const getProjectIds = computed(() => store.getters.getSelectedProjectItems)

watch(
  chartOptions.value,
  () => {
    chartOptions.value.xAxis.title.text = categories.value[1]
    chartOptions.value.yAxis.title.text = categories.value[0]
  },
  { deep: true }
)

watchEffect(() => {
  const items = store.getters.getSegmentationData
  const association = store.getters.getMetricValuesAssociation
  // const categories = store.getters.getCheckedMetricsNames

  const Cols: any = {
    Супер: 3,
    Высокая: 2,
    Умеренная: 1,
    Низкая: 0,
  }
  const Rows: any = {
    Супер: 0,
    Высокая: 1,
    Умеренная: 2,
    Низкая: 3,
  }
  const data = []
  let singleMetric = false
  if (items && association) {
    for (const i of items) {
      if (!i.getToMetricValueId()) {
        singleMetric = true
        data.push({
          metricTo: i.getToMetricValueId(),
          metricFrom: i.getFromMetricValueId(),
          y: 0,
          x: Cols[association[i.getFromMetricValueId()]],
          value: i.getCnt(),
        })
      } else {
        data.push({
          metricTo: i.getToMetricValueId(),
          metricFrom: i.getFromMetricValueId(),
          x: Cols[association[i.getToMetricValueId()]],
          y: Rows[association[i.getFromMetricValueId()]],
          value: i.getCnt(),
        })
      }
    }

    if (singleMetric) {
      chartOptions.value.yAxis.categories = ['']
    } else {
      chartOptions.value.yAxis.categories = [
        'Супер',
        'Высокая',
        'Умеренная',
        'Низкая',
      ]
    }

    console.log('SEGMENT CHAR', data)
    chartOptions.value.series[0].data = data
  }
})

return (_ctx: any,_cache: any) => {
  const _component_highcharts = _resolveComponent("highcharts")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, { style: {"width":"100%"} }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card_section, {
        horizontal: "",
        style: {"height":"500px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_highcharts, {
            class: "hc",
            options: chartOptions.value,
            ref: "chart"
          }, null, 8, ["options"]),
          _createVNode(_component_q_inner_loading, {
            showing: isSegmentationBusy.value,
            color: "grey"
          }, null, 8, ["showing"])
        ]),
        _: 1
      }),
      _createVNode(_component_q_dialog, {
        modelValue: isAccountsDialogVisible.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isAccountsDialogVisible).value = $event)),
        "no-esc-dismiss": ""
      }, {
        default: _withCtx(() => [
          (!initialReq.value)
            ? (_openBlock(), _createBlock(AccountListModal, {
                key: 0,
                pagination: accountsDialogPagination.value,
                filter: saveAccountsFilter.value,
                intersectionNames: intersectionNames.value,
                onRequest: accountsMeticsTableRequest
              }, null, 8, ["pagination", "filter", "intersectionNames"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_q_inner_loading, { showing: initialReq.value }, null, 8, ["showing"])
              ]))
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}
}

})