import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    style: {"width":"1024px","max-width":"80vw","max-height":"80vh"},
    class: "q-pa-md"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_label, { class: "text-h5" }, {
                default: _withCtx(() => [
                  _cache[0] || (_cache[0] = _createElementVNode("p", null, "Удаление значения", -1)),
                  _createElementVNode("p", null, "«" + _toDisplayString(_ctx.item.title) + "»", 1),
                  _createElementVNode("p", null, "Балл: " + _toDisplayString(_ctx.item.points), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_separator),
      _createVNode(_component_q_card_section, null, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("p", null, "Вы действительно хотите удалить значение - ?", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_q_separator),
      _createVNode(_component_q_card_actions, { align: "right" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_btn, {
            color: "primary",
            onClick: _ctx.actualDelete
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" Удалить ")
            ])),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_q_btn, {
            color: "primary",
            onClick: _ctx.closeValueDelete
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode(" Отмена ")
            ])),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}