import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row justify-between" }
const _hoisted_2 = { class: "row items-center q-mb-lg q-gutter-md" }
const _hoisted_3 = { class: "row items-center q-mb-lg q-gutter-md" }
const _hoisted_4 = { class: "row items-center q-mb-lg q-gutter-md" }
const _hoisted_5 = { class: "row items-center q-mb-lg q-gutter-md" }
const _hoisted_6 = { class: "row q-gutter-md q-mt-lg" }

import store from '@/store'
import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SettingsModal',
  setup(__props) {

const settingsModal = ref(false)
const minNeighbourCnt = ref(40)
const maxNeighbourLen = ref(20)
const addParameters = ref(false)
const pcr1 = ref(30)
const pcr2 = ref(60)
const pcr3 = ref(80)
const cntMaxActivity = ref(300)
const sumActivity = ref(80)
const lowEdgePoints = ref(500)
const orPrc = ref(40)

const applySettings = () => {
  store.dispatch('setModalSettings', {
    minNeighbourCnt: +minNeighbourCnt.value,
    maxNeighbourLen: +maxNeighbourLen.value,
    addParameters: addParameters.value,
    pcr1: +pcr1.value / 100,
    pcr2: +pcr2.value / 100,
    pcr3: +pcr3.value / 100,
    cntMaxActivity: +cntMaxActivity.value,
    sumActivity: +sumActivity.value / 100,
    lowEdgePoints: +lowEdgePoints.value,
    orPrc: +orPrc.value,
  })
  settingsModal.value = false
}

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "row items-center q-gutter-sm cursor-pointer",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (settingsModal.value = !settingsModal.value))
    }, [
      _createVNode(_component_q_icon, {
        name: "settings",
        color: "grey-7"
      }),
      _cache[8] || (_cache[8] = _createElementVNode("span", null, "НАСТРОЙКИ", -1))
    ]),
    _createVNode(_component_q_dialog, {
      modelValue: settingsModal.value,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((settingsModal).value = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, {
          class: "settings-modal",
          style: {"max-width":"1058px","width":"100%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_card_section, { class: "text-h6 q-px-xl q-pt-xl" }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode("Настройки")
              ])),
              _: 1
            }),
            _createVNode(_component_q_card_section, { class: "q-px-xl" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_q_checkbox, {
                    modelValue: addParameters.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((addParameters).value = $event)),
                    label: "С учетом дополнительных параметров"
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_q_card_section, { horizontal: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_separator, { style: {"width":"100%"} })
              ]),
              _: 1
            }),
            _createVNode(_component_q_card_section, { class: "q-px-xl q-pb-xl" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_q_input, {
                    class: "input-md",
                    modelValue: cntMaxActivity.value,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((cntMaxActivity).value = $event)),
                    outlined: "",
                    type: "number",
                    readonly: ""
                  }, null, 8, ["modelValue"]),
                  _cache[10] || (_cache[10] = _createElementVNode("span", null, "Количество пользователей для расчета средней максимальной активности", -1))
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_q_input, {
                    class: "input-md",
                    modelValue: sumActivity.value,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((sumActivity).value = $event)),
                    outlined: "",
                    rules: [(val) => val <= 100],
                    "no-error-icon": "",
                    "hide-bottom-space": "",
                    type: "number"
                  }, null, 8, ["modelValue", "rules"]),
                  _cache[11] || (_cache[11] = _createElementVNode("span", null, "Суммарная активность ядра, %", -1))
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_input, {
                    class: "input-md",
                    modelValue: lowEdgePoints.value,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((lowEdgePoints).value = $event)),
                    outlined: "",
                    type: "number"
                  }, null, 8, ["modelValue"]),
                  _cache[12] || (_cache[12] = _createElementVNode("span", null, "Нижняя граница остатка баллов", -1))
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_q_input, {
                    class: "input-md",
                    modelValue: orPrc.value,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((orPrc).value = $event)),
                    outlined: "",
                    type: "number"
                  }, null, 8, ["modelValue"]),
                  _cache[13] || (_cache[13] = _createElementVNode("span", null, "Нижняя граница остатка баллов, %", -1))
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_q_btn, {
                    onClick: applySettings,
                    label: "Применить",
                    color: "primary",
                    style: {"width":"268px","height":"58px"}
                  }),
                  _createVNode(_component_q_btn, {
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (settingsModal.value = false)),
                    label: "Закрыть",
                    color: "primary",
                    outline: "",
                    style: {"width":"268px","height":"58px"}
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})