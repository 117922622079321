import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"margin":"10px"} }
const _hoisted_2 = {
  class: "row",
  style: {"height":"100%"}
}
const _hoisted_3 = {
  class: "col-12",
  style: {"height":"100%","overflow":"hidden"}
}
const _hoisted_4 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "row justify-center" }

import store from '@/store'
import {computed, onMounted, ref} from 'vue'
import UserAdd from './UserAdd.vue'
import UserDelete from './UserDelete.vue'
import UserEdit from './UserEdit.vue'
import type {QTableColumn} from "quasar";
import {useAlert} from "@/composables/useAlert";
import {UsersApi} from "@/components/modules/users/services/api/UsersApi";

type UserType = {
  userId: number,
  email: string,
  fio: string,
  userRole: string,
  username: string,
  password: string,
  userRefreshToken: string,
  userToken: string,
  userActive: boolean,
  userActivated: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UsersList',
  setup(__props) {

const { Alert } = useAlert()
const usersApi = new UsersApi()

const userList = ref<UserType[]>([])
const filteredUserList = ref<UserType[]>([])
const columns: QTableColumn[] = [
  {
    name: 'fio',
    label: 'Ф.И.О',
    field: 'fio',
    align: 'left',
  },
  {
    name: 'email',
    label: 'E-mail',
    field: 'email',
    align: 'left',
  },
  {
    name: 'userRole',
    label: 'Роль',
    field: 'userRole',
    align: 'left',
  },
  {
    name: 'username',
    label: 'Имя в системе',
    field: 'username',
    align: 'left',
  },
  {
    name: 'user_active',
    label: 'Статус активации',
    field: 'userActive',
    align: 'center',
    sortable: true
  },
  {
    name: 'user_activated',
    label: 'Дата активации',
    field: 'userActivated',
    align: 'center',
    sortable: true
  },
  {
    name: 'action',
    label: 'Действие',
    field: 'action',
    align: 'center',
    headerStyle: 'width: 100px',
    style: 'width: 40px',
  },
]
const userPagination = ref({
  sortBy: 'username',
  descending: false,
  page: 1,
  rowsPerPage: 25,
  rowsNumber: 0,
})
const rowsPerPageUserOptions = [10, 25, 50, 100]
const filter = ref('')
const search = ref('')
const oldSearch = ref('')
const searchTimeout = ref<ReturnType<typeof setTimeout>>(setTimeout(() => {}, 500))
const isActiveModal = ref(false)
const isActiveModalAdd = ref(false)
const isActiveModalEdit = ref(false)
const isActiveModalDelete = ref(false)
const selectedUserId = ref(0)

const isUsersListBusy = computed(() => store.getters.getIsUsersListBusy)
const isResend = computed(() => store.getters.getIsResend)

const hideModal = () => {
  isActiveModal.value = false
  isActiveModalAdd.value = false
  isActiveModalEdit.value = false
  isActiveModalDelete.value = false
  selectedUserId.value = 0
}

const addUser = () => {
  isActiveModalAdd.value = true
  isActiveModal.value = true
}

const editUser = () => {
  isActiveModalEdit.value = true
  isActiveModal.value = true
}

const deleteUser = () => {
  isActiveModalDelete.value = true
  isActiveModal.value = true
}

const actionRow = (method: string, id: number) => {
  selectedUserId.value = id
  method === 'edit' ? editUser() : deleteUser()
}

const afterAction = () => {
  loadUsersListItems(true)
  hideModal()
}

const initSearch = () => {
  if (searchTimeout.value) {
    clearTimeout(searchTimeout.value)
  }

  filter.value = ''
  search.value = ''
  oldSearch.value = ''
  searchTimeout.value = setTimeout(() => {}, 500)
}

const loadUsersListItems = (isResetPagination: boolean) => {
  if (isResetPagination) {
    userPagination.value.page = 1
    userPagination.value.rowsNumber = 0
  }

  usersApi
      .getUsersListItems(
          {},
          {
            name: userPagination.value.sortBy,
            exp: userPagination.value.descending ? 'DESC' : 'ASC',
          },
          {
            page: userPagination.value.page,
            limit: userPagination.value.rowsPerPage,
            pages: 0,
            cnt: 0,
          }
      )
      .then(res => {
        userList.value = res.toObject().itemsList.map(item => {
          return {
            userId: item.userId,
            email: item.email,
            fio: `${item.lastName} ${item.firstName} ${item.middleName}`,
            userRole: item.userRole,
            username: item.username,
            password: item.password,
            userRefreshToken: item.userRefreshToken,
            userToken: item.userToken,
            userActive: item.userActive,
            userActivated: item.userActivated
                ? item.userActivated.split(' ')[0].split('-').reverse().join('.')
                : 'Пользователь не активирован',
          }
        })
        filteredUserList.value = userList.value
        userPagination.value.rowsNumber = res.getParams()?.getPagination()?.getCnt() || 0
      })
}

const activateUser = (props: unknown) => {
  if (!props.value) {
    store
        .dispatch('resendMail', {
          id: props.row.id
        })
        .then(() => Alert('Письмо успешно отправлено', 'positive'))
  }
}

const onRequest = (props) => {
  userPagination.value = props.pagination
  loadUsersListItems(false)
}

const loadData = () => {
  hideModal()
  initSearch()
  loadUsersListItems(true)
}

const filterMethod = (value: string) => filteredUserList.value = userList.value.filter(item => item.fio.toLowerCase().includes(value.toLowerCase()))

onMounted(loadData)

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-h4 q-pb-md" }, "Системные пользователи", -1)),
    _createVNode(_component_q_card, {
      style: {"padding":"10px","height":"80vh"},
      class: "shadow-4"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_q_table, {
              flat: "",
              style: {"height":"100%","overflow":"auto"},
              rows: filteredUserList.value,
              columns: columns,
              "row-key": "userId",
              loading: isUsersListBusy.value,
              pagination: userPagination.value,
              "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => ((userPagination).value = $event)),
              "rows-per-page-options": rowsPerPageUserOptions,
              onRequest: onRequest,
              "binary-state-sort": ""
            }, {
              top: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  color: "primary",
                  disable: isUsersListBusy.value,
                  label: "Добавить",
                  icon: "mdi-plus",
                  onClick: addUser
                }, null, 8, ["disable"]),
                _createVNode(_component_q_space),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    modelValue: search.value,
                    "onUpdate:modelValue": [
                      _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
                      filterMethod
                    ],
                    filled: "",
                    style: {"width":"500px"},
                    label: "Поиск по ФИО"
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_q_icon, { name: "mdi-magnify" })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ])
              ]),
              "body-cell-user_active": _withCtx((props) => [
                _createVNode(_component_q_td, {
                  props: props,
                  class: "relative-position"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      onClick: ($event: any) => (activateUser(props)),
                      class: _normalizeClass(!props.value ? 'text-primary cursor-pointer' : null)
                    }, _toDisplayString(props.value ? 'Выполнена' : 'Отправить повторно'), 11, _hoisted_5),
                    (!props.value)
                      ? (_openBlock(), _createBlock(_component_q_inner_loading, {
                          key: 0,
                          showing: isResend.value
                        }, null, 8, ["showing"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-action": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_q_btn, {
                        flat: "",
                        dense: "",
                        round: "",
                        icon: "mdi-dots-vertical"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_menu, { "auto-close": "" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_list, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_item, {
                                    clickable: "",
                                    onClick: ($event: any) => (actionRow('edit', props.row.userId))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_item_section, null, {
                                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                                          _createTextVNode("Редактировать")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]),
                                  _createVNode(_component_q_item, {
                                    clickable: "",
                                    onClick: ($event: any) => (actionRow('delete', props.row.userId))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_item_section, null, {
                                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                                          _createTextVNode("Удалить")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "no-data": _withCtx(() => _cache[5] || (_cache[5] = [
                _createElementVNode("div", { class: "col-12 text-center" }, "Нет данных", -1)
              ])),
              _: 1
            }, 8, ["rows", "loading", "pagination"])
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_q_dialog, {
      modelValue: isActiveModal.value,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((isActiveModal).value = $event)),
      persistent: ""
    }, {
      default: _withCtx(() => [
        (isActiveModalAdd.value)
          ? (_openBlock(), _createBlock(UserAdd, {
              key: 0,
              onAfterAction: afterAction,
              onHideModal: hideModal
            }))
          : _createCommentVNode("", true),
        (isActiveModalEdit.value)
          ? (_openBlock(), _createBlock(UserEdit, {
              key: 1,
              userId: selectedUserId.value,
              onAfterAction: afterAction,
              onHideModal: hideModal
            }, null, 8, ["userId"]))
          : _createCommentVNode("", true),
        (isActiveModalDelete.value)
          ? (_openBlock(), _createBlock(UserDelete, {
              key: 2,
              userId: selectedUserId.value,
              onAfterAction: afterAction,
              onHideModal: hideModal
            }, null, 8, ["userId"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})