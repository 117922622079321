import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ag-report bg-white text-black q-pa-md q-mt-md"
}
const _hoisted_2 = {
  class: "ag-users",
  style: {"width":"100%","max-width":"unset"}
}
const _hoisted_3 = {
  class: "ag-check",
  style: {"gap":"15px","width":"100%","max-width":"unset"}
}
const _hoisted_4 = {
  class: "ag-users__left ag-block q-pa-sm",
  style: {"width":"calc(50% - 25px)"}
}
const _hoisted_5 = { class: "custom-title" }
const _hoisted_6 = { class: "custom-plus" }
const _hoisted_7 = {
  class: "row no-wrap",
  style: {"gap":"20px"}
}
const _hoisted_8 = { class: "column items-start" }
const _hoisted_9 = { class: "custom-title" }
const _hoisted_10 = { class: "custom-plus" }
const _hoisted_11 = { class: "column items-start" }
const _hoisted_12 = { class: "custom-title" }
const _hoisted_13 = { class: "custom-plus" }
const _hoisted_14 = { class: "column items-start" }
const _hoisted_15 = { class: "custom-title" }
const _hoisted_16 = { class: "custom-plus" }
const _hoisted_17 = { class: "column items-start" }
const _hoisted_18 = { class: "custom-title" }
const _hoisted_19 = { class: "custom-plus" }
const _hoisted_20 = {
  class: "row no-wrap",
  style: {"gap":"20px"}
}
const _hoisted_21 = { class: "column items-start" }
const _hoisted_22 = { class: "custom-title" }
const _hoisted_23 = { class: "custom-plus" }
const _hoisted_24 = { class: "column items-start" }
const _hoisted_25 = { class: "custom-title" }
const _hoisted_26 = { class: "custom-plus" }
const _hoisted_27 = { class: "column items-start" }
const _hoisted_28 = { class: "custom-title" }
const _hoisted_29 = { class: "custom-plus" }
const _hoisted_30 = {
  class: "ag-users__right",
  style: {"width":"calc(50% - 10px)"}
}
const _hoisted_31 = {
  class: "ag-users__right-item",
  style: {"width":"100%"}
}
const _hoisted_32 = {
  class: "ag-users__right-item_card ag-block q-pa-sm",
  style: {"height":"fit-content"}
}
const _hoisted_33 = { class: "custom-title" }
const _hoisted_34 = {
  class: "ag-users__right-item_card ag-block q-pa-sm",
  style: {"height":"fit-content"}
}
const _hoisted_35 = { class: "custom-title" }
const _hoisted_36 = { class: "custom-plus" }
const _hoisted_37 = {
  class: "row",
  style: {"gap":"15px","margin":"15px 0 0 0"}
}
const _hoisted_38 = {
  class: "ag-block q-pa-sm",
  style: {"width":"calc(50% - 25px)"}
}
const _hoisted_39 = { class: "custom-title" }
const _hoisted_40 = { class: "custom-plus" }
const _hoisted_41 = {
  class: "ag-block q-pa-sm",
  style: {"width":"calc(50% - 25px)"}
}
const _hoisted_42 = { class: "custom-title" }
const _hoisted_43 = { class: "custom-plus" }
const _hoisted_44 = {
  key: 1,
  class: "ag-report bg-white text-black q-pa-md q-mt-md"
}
const _hoisted_45 = {
  class: "row q-mt-sm",
  style: {"gap":"15px"}
}
const _hoisted_46 = {
  class: "ag-block q-pa-sm",
  style: {"width":"calc(50% - 25px)"}
}
const _hoisted_47 = { class: "custom-plus" }
const _hoisted_48 = {
  class: "ag-block q-pa-sm",
  style: {"width":"calc(50% - 25px)"}
}
const _hoisted_49 = { class: "custom-plus" }
const _hoisted_50 = {
  key: 2,
  class: "ag-report bg-white text-black q-pa-md q-mt-md"
}
const _hoisted_51 = {
  class: "row q-mt-sm",
  style: {"gap":"15px"}
}
const _hoisted_52 = {
  class: "ag-block q-pa-sm",
  style: {"width":"calc(50% - 25px)"}
}
const _hoisted_53 = { class: "custom-title" }
const _hoisted_54 = { class: "custom-plus" }
const _hoisted_55 = {
  class: "ag-block q-pa-sm",
  style: {"width":"calc(50% - 25px)"}
}
const _hoisted_56 = { class: "custom-title" }
const _hoisted_57 = { class: "custom-plus" }
const _hoisted_58 = {
  class: "row q-mt-sm",
  style: {"gap":"15px"}
}
const _hoisted_59 = {
  class: "ag-block q-pa-sm",
  style: {"width":"calc(50% - 25px)"}
}
const _hoisted_60 = { class: "custom-title" }
const _hoisted_61 = { class: "custom-plus" }
const _hoisted_62 = {
  class: "ag-block q-pa-sm",
  style: {"width":"calc(50% - 25px)"}
}
const _hoisted_63 = { class: "custom-title" }
const _hoisted_64 = { class: "custom-plus" }
const _hoisted_65 = {
  class: "row q-mt-sm",
  style: {"gap":"15px"}
}
const _hoisted_66 = {
  class: "ag-block q-pa-sm",
  style: {"width":"calc(50% - 25px)"}
}
const _hoisted_67 = { class: "custom-title" }
const _hoisted_68 = { class: "custom-plus" }
const _hoisted_69 = {
  class: "ag-block q-pa-sm",
  style: {"width":"calc(50% - 25px)"}
}
const _hoisted_70 = { class: "custom-title" }
const _hoisted_71 = { class: "custom-plus" }

import { toRefs } from "vue"
import Vue3Html2pdf from "vue3-html2pdf/src/vue3-html2pdf.vue";
import QuizTable from "../components/AgChild/Quiz/QuizTable.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AgChild',
  props: ['currentProject', 'dateFromAfterLoad', 'dateToAfterLoad', 'reportList'],
  setup(__props) {

const props = __props
const { currentProject, dateFromAfterLoad, dateToAfterLoad, reportList } = toRefs(props)


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Vue3Html2pdf, {
    "show-layout": false,
    "float-layout": false,
    "enable-download": true,
    "preview-modal": false,
    "paginate-elements-by-height": 1070,
    filename: 'НИГМА. Отчет по проекту' +
      _unref(currentProject).subtitle +
      'за период с ' +
      _unref(dateFromAfterLoad).split('-').reverse().join('.') +
      ' по ' +
      _unref(dateToAfterLoad).split('-').reverse().join('.')
      ,
    "pdf-quality": 3,
    "manual-pagination": false,
    "pdf-format": "a4",
    "pdf-orientation": "portrait",
    "pdf-content-width": "836px",
    ref: "html2pdf",
    class: "project-page"
  }, {
    "pdf-content": _withCtx(() => [
      (Object.keys(_unref(reportList)).length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _cache[14] || (_cache[14] = _createElementVNode("div", { class: "text-weight-bold text-h5" }, "Основные показатели", -1)),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-subtitle2" }, " Всего пользователей проекта ", -1)),
                  _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(reportList)["Всего пользователей проекта"].toLocaleString()), 1),
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(reportList)["Новых регистраций"] > 0 ? "+" : "") + _toDisplayString(_unref(reportList)["Новых регистраций"].toLocaleString()), 1),
                  _cache[8] || (_cache[8] = _createElementVNode("div", {
                    class: "q-mt-sm",
                    style: {"font-size":"12px"}
                  }, " По возрастным группам ", -1)),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "custom-text" }, "6-7 лет", -1)),
                      _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(reportList)["6-7 лет"].toLocaleString()), 1),
                      _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(reportList)["6-7 лет за период"] > 0 ? "+" : "") + _toDisplayString(_unref(reportList)["6-7 лет за период"].toLocaleString()), 1)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "custom-text" }, "8-10 лет", -1)),
                      _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(reportList)["8-10 лет"].toLocaleString()), 1),
                      _createElementVNode("div", _hoisted_13, _toDisplayString(_unref(reportList)["8-10 лет за период"] > 0 ? "+" : "") + " " + _toDisplayString(_unref(reportList)["8-10 лет за период"].toLocaleString()), 1)
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "custom-text" }, "11-13 лет", -1)),
                      _createElementVNode("span", _hoisted_15, _toDisplayString(_unref(reportList)["11-13 лет"].toLocaleString()), 1),
                      _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(reportList)["11-13 лет за период"] > 0 ? "+" : "") + " " + _toDisplayString(_unref(reportList)["11-13 лет за период"].toLocaleString()), 1)
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _cache[3] || (_cache[3] = _createElementVNode("span", { class: "custom-text" }, "Прочие", -1)),
                      _createElementVNode("span", _hoisted_18, _toDisplayString(_unref(reportList)["Другие"].toLocaleString()), 1),
                      _createElementVNode("div", _hoisted_19, _toDisplayString(_unref(reportList)["Другие за период"] > 0 ? "+" : "") + " " + _toDisplayString(_unref(reportList)["Другие за период"].toLocaleString()), 1)
                    ])
                  ]),
                  _cache[9] || (_cache[9] = _createElementVNode("div", {
                    class: "q-mt-sm",
                    style: {"font-size":"12px"}
                  }, " Распределение по типу УЗ ", -1)),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, [
                      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "custom-text" }, "Полная", -1)),
                      _createElementVNode("span", _hoisted_22, _toDisplayString(_unref(reportList)["Полная"].toLocaleString()), 1),
                      _createElementVNode("div", _hoisted_23, _toDisplayString(_unref(reportList)["Полная за период"] > 0 ? "+" : "") + " " + _toDisplayString(_unref(reportList)["Полная за период"].toLocaleString()), 1)
                    ]),
                    _createElementVNode("div", _hoisted_24, [
                      _cache[5] || (_cache[5] = _createElementVNode("span", { class: "custom-text" }, "Стандартная", -1)),
                      _createElementVNode("span", _hoisted_25, _toDisplayString(_unref(reportList)["Стандартная"].toLocaleString()), 1),
                      _createElementVNode("div", _hoisted_26, _toDisplayString(_unref(reportList)["Стандартная за период"] > 0 ? "+" : "") + _toDisplayString(_unref(reportList)["Стандартная за период"].toLocaleString()), 1)
                    ]),
                    _createElementVNode("div", _hoisted_27, [
                      _cache[6] || (_cache[6] = _createElementVNode("span", { class: "custom-text" }, "Упрощенная", -1)),
                      _createElementVNode("span", _hoisted_28, _toDisplayString(_unref(reportList)["Упрощенная"].toLocaleString()), 1),
                      _createElementVNode("div", _hoisted_29, _toDisplayString(_unref(reportList)["Упрощенная за период"] > 0 ? "+" : "") + _toDisplayString(_unref(reportList)["Упрощенная за период"].toLocaleString()), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("div", _hoisted_31, [
                    _createElementVNode("div", _hoisted_32, [
                      _cache[10] || (_cache[10] = _createElementVNode("div", {
                        class: "custom-text",
                        style: {"white-space":"nowrap"}
                      }, " Новых регистраций ", -1)),
                      _createElementVNode("div", _hoisted_33, _toDisplayString(_unref(reportList)["Новых регистраций"].toLocaleString()), 1)
                    ]),
                    _createElementVNode("div", _hoisted_34, [
                      _cache[11] || (_cache[11] = _createElementVNode("div", {
                        class: "custom-text",
                        style: {"white-space":"nowrap"}
                      }, " Начислено баллов ", -1)),
                      _createElementVNode("div", _hoisted_35, _toDisplayString(_unref(reportList)["Начислено баллов"].toLocaleString()), 1),
                      _createElementVNode("div", _hoisted_36, _toDisplayString(_unref(reportList)["Начислено баллов за период"] > 0 ? "+" : "") + " " + _toDisplayString(_unref(reportList)["Начислено баллов за период"].toLocaleString()), 1)
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_37, [
              _createElementVNode("div", _hoisted_38, [
                _cache[12] || (_cache[12] = _createElementVNode("div", { class: "custom-text" }, "Участники, завершившие хотя бы одну викторину", -1)),
                _createElementVNode("div", _hoisted_39, _toDisplayString(_unref(reportList)["Участники, завершившие хотя бы одну викторину"].toLocaleString()), 1),
                _createElementVNode("div", _hoisted_40, _toDisplayString(_unref(reportList)["Участники, завершившие хотя бы одну викторину за период"] > 0 ? "+" : "") + _toDisplayString(_unref(reportList)["Участники, завершившие хотя бы одну викторину за период"].toLocaleString()), 1)
              ]),
              _createElementVNode("div", _hoisted_41, [
                _cache[13] || (_cache[13] = _createElementVNode("div", { class: "custom-text" }, "Участники, правильно решившие пример хотя бы один раз Приз", -1)),
                _createElementVNode("div", _hoisted_42, _toDisplayString(_unref(reportList)["Участники, правильно решившие пример хотя бы один раз Приз"].toLocaleString()), 1),
                _createElementVNode("div", _hoisted_43, _toDisplayString(_unref(reportList)["Участники, правильно решившие пример хотя бы один раз Приз за период"] > 0 ? "+" : "") + _toDisplayString(_unref(reportList)["Участники, правильно решившие пример хотя бы один раз Приз за период"].toLocaleString()), 1)
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (Object.keys(_unref(reportList)).length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
            _cache[17] || (_cache[17] = _createElementVNode("div", { class: "text-weight-bold text-h5" }, [
              _createTextVNode(" Аномалии"),
              _createElementVNode("span", { class: "text-weight-light text-subtitle1" }, "(отклонения от среднего за последние 3 недели)")
            ], -1)),
            _createElementVNode("div", _hoisted_45, [
              _createElementVNode("div", _hoisted_46, [
                _cache[15] || (_cache[15] = _createElementVNode("div", { class: "custom-text" }, "Отклонение по регистрациям", -1)),
                _createElementVNode("div", {
                  class: _normalizeClass([_unref(reportList)['Отклонение по регистрациям'] > 0 ? 'text-green' : 'text-red', "custom-title"])
                }, _toDisplayString(+_unref(reportList)["Отклонение по регистрациям"] > 0 ? "+" : "") + " " + _toDisplayString(_unref(reportList)["Отклонение по регистрациям"].toLocaleString()), 3),
                _createElementVNode("div", _hoisted_47, _toDisplayString(_unref(reportList)["Отклонение по регистрациям %"].toFixed(1)) + "% (проц. от среднего) ", 1)
              ]),
              _createElementVNode("div", _hoisted_48, [
                _cache[16] || (_cache[16] = _createElementVNode("div", { class: "custom-text" }, "Отклонение по баллам", -1)),
                _createElementVNode("div", {
                  class: _normalizeClass([_unref(reportList)['Отклонение по баллам'] > 0 ? 'text-green' : 'text-red', "custom-title"])
                }, _toDisplayString(+_unref(reportList)["Отклонение по баллам"] > 0 ? "+" : "") + " " + _toDisplayString(_unref(reportList)["Отклонение по баллам"].toLocaleString()), 3),
                _createElementVNode("div", _hoisted_49, _toDisplayString(_unref(reportList)["Отклонение по баллам %"].toFixed(1)) + "% (проц. от среднего) ", 1)
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (Object.keys(_unref(reportList)).length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
            _cache[24] || (_cache[24] = _createElementVNode("div", { class: "text-weight-bold text-h5" }, " Данные по возрастным группам ", -1)),
            _cache[25] || (_cache[25] = _createElementVNode("div", { class: "text-h6 q-mt-sm" }, "6-7 лет", -1)),
            _createElementVNode("div", _hoisted_51, [
              _createElementVNode("div", _hoisted_52, [
                _cache[18] || (_cache[18] = _createElementVNode("div", { class: "custom-text" }, "Участники, завершившие хотя бы одну викторину", -1)),
                _createElementVNode("div", _hoisted_53, _toDisplayString(_unref(reportList)["Участники, завершившие хотя бы одну викторину 6-7 лет"].toLocaleString()), 1),
                _createElementVNode("div", _hoisted_54, _toDisplayString(_unref(reportList)["Участники, завершившие хотя бы одну викторину 6-7 лет за период"] > 0 ? "+" : "") + _toDisplayString(_unref(reportList)["Участники, завершившие хотя бы одну викторину 6-7 лет за период"].toLocaleString()), 1)
              ]),
              _createElementVNode("div", _hoisted_55, [
                _cache[19] || (_cache[19] = _createElementVNode("div", { class: "custom-text" }, "Участники, правильно решившие пример хотя бы один раз \"Приз\"", -1)),
                _createElementVNode("div", _hoisted_56, _toDisplayString(_unref(reportList)["Участники, правильно решившие пример хотя бы один раз Приз 6-7 лет"].toLocaleString()), 1),
                _createElementVNode("div", _hoisted_57, _toDisplayString(_unref(reportList)["Участники, правильно решившие пример хотя бы один раз Приз 6-7 лет за период"] > 0 ? "+" :
                "") + " " + _toDisplayString(_unref(reportList)[
                "Участники, правильно решившие пример хотя бы один раз Приз 6-7 лет за период"]
                .toLocaleString()), 1)
              ])
            ]),
            _cache[26] || (_cache[26] = _createElementVNode("div", { class: "text-h6 q-mt-sm" }, "8-10 лет", -1)),
            _createElementVNode("div", _hoisted_58, [
              _createElementVNode("div", _hoisted_59, [
                _cache[20] || (_cache[20] = _createElementVNode("div", { class: "custom-text" }, "Участники, завершившие хотя бы одну викторину", -1)),
                _createElementVNode("div", _hoisted_60, _toDisplayString(_unref(reportList)["Участники, завершившие хотя бы одну викторину 8-10 лет"].toLocaleString()), 1),
                _createElementVNode("div", _hoisted_61, _toDisplayString(_unref(reportList)["Участники, завершившие хотя бы одну викторину 8-10 лет за период"] > 0 ? "+" : "") + _toDisplayString(_unref(reportList)["Участники, завершившие хотя бы одну викторину 8-10 лет за период"].toLocaleString()), 1)
              ]),
              _createElementVNode("div", _hoisted_62, [
                _cache[21] || (_cache[21] = _createElementVNode("div", { class: "custom-text" }, "Участники, правильно решившие пример хотя бы один раз \"Приз\"", -1)),
                _createElementVNode("div", _hoisted_63, _toDisplayString(_unref(reportList)["Участники, правильно решившие пример хотя бы один раз Приз 8-10 лет"].toLocaleString()), 1),
                _createElementVNode("div", _hoisted_64, _toDisplayString(_unref(reportList)["Участники, правильно решившие пример хотя бы один раз Приз 8-10 лет за период"] > 0 ? "+" :
                "") + " " + _toDisplayString(_unref(reportList)[
                "Участники, правильно решившие пример хотя бы один раз Приз 8-10 лет за период"]
                .toLocaleString()), 1)
              ])
            ]),
            _cache[27] || (_cache[27] = _createElementVNode("div", { class: "text-h6 q-mt-sm" }, "11-13 лет", -1)),
            _createElementVNode("div", _hoisted_65, [
              _createElementVNode("div", _hoisted_66, [
                _cache[22] || (_cache[22] = _createElementVNode("div", { class: "custom-text" }, "Участники, завершившие хотя бы одну викторину", -1)),
                _createElementVNode("div", _hoisted_67, _toDisplayString(_unref(reportList)["Участники, завершившие хотя бы одну викторину 11-13 лет"].toLocaleString()), 1),
                _createElementVNode("div", _hoisted_68, _toDisplayString(_unref(reportList)["Участники, завершившие хотя бы одну викторину 11-13 лет за период"] > 0 ? "+" : "") + _toDisplayString(_unref(reportList)["Участники, завершившие хотя бы одну викторину 11-13 лет за период"].toLocaleString()), 1)
              ]),
              _createElementVNode("div", _hoisted_69, [
                _cache[23] || (_cache[23] = _createElementVNode("div", { class: "custom-text" }, "Участники, правильно решившие пример хотя бы один раз \"Приз\"", -1)),
                _createElementVNode("div", _hoisted_70, _toDisplayString(_unref(reportList)["Участники, правильно решившие пример хотя бы один раз Приз 11-13 лет"]?.toLocaleString()), 1),
                _createElementVNode("div", _hoisted_71, _toDisplayString(_unref(reportList)["Участники, правильно решившие пример хотя бы один раз Приз 11-13 лет за период"] > 0 ? "+"
                : "") + " " + _toDisplayString(_unref(reportList)[
                "Участники, правильно решившие пример хотя бы один раз Приз 11-13 лет за период"
              ]?.toLocaleString()), 1)
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(QuizTable, {
        "date-begin": _unref(dateFromAfterLoad),
        "date-end": _unref(dateToAfterLoad)
      }, null, 8, ["date-begin", "date-end"])
    ]),
    _: 1
  }, 8, ["filename"]))
}
}

})