// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "class.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Pagination } from "./proto/nigma-common-proto/filter";
import { Sort } from "./proto/nigma-common-proto/filter";
import { Filter } from "./proto/nigma-common-proto/filter";
/**
 * Модель данных
 * Префикс для фильтров c.
 *
 * @generated from protobuf message proto.nigmamsg.ClassModel
 */
export interface ClassModel {
    /**
     * @generated from protobuf field: int64 class_id = 1;
     */
    classId: number;
    /**
     * @generated from protobuf field: int64 classifier_id = 2;
     */
    classifierId: number;
    /**
     * @generated from protobuf field: int64 class_parent_id = 3;
     */
    classParentId: number;
    /**
     * @generated from protobuf field: int64 class_idx = 4;
     */
    classIdx: number;
    /**
     * @generated from protobuf field: string class_name = 5;
     */
    className: string;
    /**
     * @generated from protobuf field: string class_descr = 6;
     */
    classDescr: string;
    /**
     * @generated from protobuf field: string class_notes = 7;
     */
    classNotes: string;
    /**
     * @generated from protobuf field: int64 class_group_id = 8;
     */
    classGroupId: number;
    /**
     * @generated from protobuf field: string class_group_name = 9;
     */
    classGroupName: string; // Префикс для фильтров cg.
    /**
     * @generated from protobuf field: string class_group_alias = 10;
     */
    classGroupAlias: string; // Префикс для фильтров cg.
    /**
     * @generated from protobuf field: string class_group_color = 11;
     */
    classGroupColor: string; // Префикс для фильтров cg.
    /**
     * @generated from protobuf field: double class_group_ratio = 12;
     */
    classGroupRatio: number; // Префикс для фильтров cg.
}
/**
 * Входящий запрос для получения списка данных
 *
 * @generated from protobuf message proto.nigmamsg.ListClassRequest
 */
export interface ListClassRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
}
/**
 * Ответ сервера для получения списка данных
 *
 * @generated from protobuf message proto.nigmamsg.ListClassResponse
 */
export interface ListClassResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.ClassModel items = 1;
     */
    items: ClassModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListClassRequest params = 2;
     */
    params?: ListClassRequest;
}
/**
 * Входящий запрос для получения элемента
 *
 * @generated from protobuf message proto.nigmamsg.ItemClassRequest
 */
export interface ItemClassRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
}
/**
 * Ответ сервера для получения элемента
 *
 * @generated from protobuf message proto.nigmamsg.ItemClassResponse
 */
export interface ItemClassResponse {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassModel item = 1;
     */
    item?: ClassModel;
}
/**
 * Входящий запрос для создания элемента
 *
 * @generated from protobuf message proto.nigmamsg.CreateClassRequest
 */
export interface CreateClassRequest {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassModel item = 1;
     */
    item?: ClassModel;
}
/**
 * Ответ сервера для создания элемента
 *
 * @generated from protobuf message proto.nigmamsg.CreateClassResponse
 */
export interface CreateClassResponse {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassModel item = 1;
     */
    item?: ClassModel;
}
/**
 * Входящий запрос для обновления элемента
 *
 * @generated from protobuf message proto.nigmamsg.UpdateClassRequest
 */
export interface UpdateClassRequest {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassModel item = 1;
     */
    item?: ClassModel;
}
/**
 * Ответ сервера для обновления элемента
 *
 * @generated from protobuf message proto.nigmamsg.UpdateClassResponse
 */
export interface UpdateClassResponse {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassModel item = 1;
     */
    item?: ClassModel;
}
/**
 * Входящий запрос для удаления элемента
 *
 * @generated from protobuf message proto.nigmamsg.DeleteClassRequest
 */
export interface DeleteClassRequest {
    /**
     * @generated from protobuf field: int64 class_id = 1;
     */
    classId: number;
}
/**
 * Ответ сервера для удаления элемента
 *
 * @generated from protobuf message proto.nigmamsg.DeleteClassResponse
 */
export interface DeleteClassResponse {
    /**
     * @generated from protobuf field: int64 class_id = 1;
     */
    classId: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class ClassModel$Type extends MessageType<ClassModel> {
    constructor() {
        super("proto.nigmamsg.ClassModel", [
            { no: 1, name: "class_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "classifier_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "class_parent_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "class_idx", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "class_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "class_descr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "class_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "class_group_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "class_group_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "class_group_alias", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "class_group_color", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "class_group_ratio", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<ClassModel>): ClassModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classId = 0;
        message.classifierId = 0;
        message.classParentId = 0;
        message.classIdx = 0;
        message.className = "";
        message.classDescr = "";
        message.classNotes = "";
        message.classGroupId = 0;
        message.classGroupName = "";
        message.classGroupAlias = "";
        message.classGroupColor = "";
        message.classGroupRatio = 0;
        if (value !== undefined)
            reflectionMergePartial<ClassModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClassModel): ClassModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_id */ 1:
                    message.classId = reader.int64().toNumber();
                    break;
                case /* int64 classifier_id */ 2:
                    message.classifierId = reader.int64().toNumber();
                    break;
                case /* int64 class_parent_id */ 3:
                    message.classParentId = reader.int64().toNumber();
                    break;
                case /* int64 class_idx */ 4:
                    message.classIdx = reader.int64().toNumber();
                    break;
                case /* string class_name */ 5:
                    message.className = reader.string();
                    break;
                case /* string class_descr */ 6:
                    message.classDescr = reader.string();
                    break;
                case /* string class_notes */ 7:
                    message.classNotes = reader.string();
                    break;
                case /* int64 class_group_id */ 8:
                    message.classGroupId = reader.int64().toNumber();
                    break;
                case /* string class_group_name */ 9:
                    message.classGroupName = reader.string();
                    break;
                case /* string class_group_alias */ 10:
                    message.classGroupAlias = reader.string();
                    break;
                case /* string class_group_color */ 11:
                    message.classGroupColor = reader.string();
                    break;
                case /* double class_group_ratio */ 12:
                    message.classGroupRatio = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ClassModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_id = 1; */
        if (message.classId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classId);
        /* int64 classifier_id = 2; */
        if (message.classifierId !== 0)
            writer.tag(2, WireType.Varint).int64(message.classifierId);
        /* int64 class_parent_id = 3; */
        if (message.classParentId !== 0)
            writer.tag(3, WireType.Varint).int64(message.classParentId);
        /* int64 class_idx = 4; */
        if (message.classIdx !== 0)
            writer.tag(4, WireType.Varint).int64(message.classIdx);
        /* string class_name = 5; */
        if (message.className !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.className);
        /* string class_descr = 6; */
        if (message.classDescr !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.classDescr);
        /* string class_notes = 7; */
        if (message.classNotes !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.classNotes);
        /* int64 class_group_id = 8; */
        if (message.classGroupId !== 0)
            writer.tag(8, WireType.Varint).int64(message.classGroupId);
        /* string class_group_name = 9; */
        if (message.classGroupName !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.classGroupName);
        /* string class_group_alias = 10; */
        if (message.classGroupAlias !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.classGroupAlias);
        /* string class_group_color = 11; */
        if (message.classGroupColor !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.classGroupColor);
        /* double class_group_ratio = 12; */
        if (message.classGroupRatio !== 0)
            writer.tag(12, WireType.Bit64).double(message.classGroupRatio);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ClassModel
 */
export const ClassModel = new ClassModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListClassRequest$Type extends MessageType<ListClassRequest> {
    constructor() {
        super("proto.nigmamsg.ListClassRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination }
        ]);
    }
    create(value?: PartialMessage<ListClassRequest>): ListClassRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ListClassRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListClassRequest): ListClassRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListClassRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListClassRequest
 */
export const ListClassRequest = new ListClassRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListClassResponse$Type extends MessageType<ListClassResponse> {
    constructor() {
        super("proto.nigmamsg.ListClassResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ClassModel },
            { no: 2, name: "params", kind: "message", T: () => ListClassRequest }
        ]);
    }
    create(value?: PartialMessage<ListClassResponse>): ListClassResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListClassResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListClassResponse): ListClassResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.ClassModel items */ 1:
                    message.items.push(ClassModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListClassRequest params */ 2:
                    message.params = ListClassRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListClassResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.ClassModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            ClassModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListClassRequest params = 2; */
        if (message.params)
            ListClassRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListClassResponse
 */
export const ListClassResponse = new ListClassResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemClassRequest$Type extends MessageType<ItemClassRequest> {
    constructor() {
        super("proto.nigmamsg.ItemClassRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter }
        ]);
    }
    create(value?: PartialMessage<ItemClassRequest>): ItemClassRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ItemClassRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemClassRequest): ItemClassRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ItemClassRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ItemClassRequest
 */
export const ItemClassRequest = new ItemClassRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemClassResponse$Type extends MessageType<ItemClassResponse> {
    constructor() {
        super("proto.nigmamsg.ItemClassResponse", [
            { no: 1, name: "item", kind: "message", T: () => ClassModel }
        ]);
    }
    create(value?: PartialMessage<ItemClassResponse>): ItemClassResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ItemClassResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemClassResponse): ItemClassResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassModel item */ 1:
                    message.item = ClassModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ItemClassResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassModel item = 1; */
        if (message.item)
            ClassModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ItemClassResponse
 */
export const ItemClassResponse = new ItemClassResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateClassRequest$Type extends MessageType<CreateClassRequest> {
    constructor() {
        super("proto.nigmamsg.CreateClassRequest", [
            { no: 1, name: "item", kind: "message", T: () => ClassModel }
        ]);
    }
    create(value?: PartialMessage<CreateClassRequest>): CreateClassRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateClassRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateClassRequest): CreateClassRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassModel item */ 1:
                    message.item = ClassModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateClassRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassModel item = 1; */
        if (message.item)
            ClassModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.CreateClassRequest
 */
export const CreateClassRequest = new CreateClassRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateClassResponse$Type extends MessageType<CreateClassResponse> {
    constructor() {
        super("proto.nigmamsg.CreateClassResponse", [
            { no: 1, name: "item", kind: "message", T: () => ClassModel }
        ]);
    }
    create(value?: PartialMessage<CreateClassResponse>): CreateClassResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateClassResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateClassResponse): CreateClassResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassModel item */ 1:
                    message.item = ClassModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateClassResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassModel item = 1; */
        if (message.item)
            ClassModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.CreateClassResponse
 */
export const CreateClassResponse = new CreateClassResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateClassRequest$Type extends MessageType<UpdateClassRequest> {
    constructor() {
        super("proto.nigmamsg.UpdateClassRequest", [
            { no: 1, name: "item", kind: "message", T: () => ClassModel }
        ]);
    }
    create(value?: PartialMessage<UpdateClassRequest>): UpdateClassRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateClassRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateClassRequest): UpdateClassRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassModel item */ 1:
                    message.item = ClassModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateClassRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassModel item = 1; */
        if (message.item)
            ClassModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.UpdateClassRequest
 */
export const UpdateClassRequest = new UpdateClassRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateClassResponse$Type extends MessageType<UpdateClassResponse> {
    constructor() {
        super("proto.nigmamsg.UpdateClassResponse", [
            { no: 1, name: "item", kind: "message", T: () => ClassModel }
        ]);
    }
    create(value?: PartialMessage<UpdateClassResponse>): UpdateClassResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateClassResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateClassResponse): UpdateClassResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassModel item */ 1:
                    message.item = ClassModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateClassResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassModel item = 1; */
        if (message.item)
            ClassModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.UpdateClassResponse
 */
export const UpdateClassResponse = new UpdateClassResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteClassRequest$Type extends MessageType<DeleteClassRequest> {
    constructor() {
        super("proto.nigmamsg.DeleteClassRequest", [
            { no: 1, name: "class_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteClassRequest>): DeleteClassRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classId = 0;
        if (value !== undefined)
            reflectionMergePartial<DeleteClassRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteClassRequest): DeleteClassRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_id */ 1:
                    message.classId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteClassRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_id = 1; */
        if (message.classId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.DeleteClassRequest
 */
export const DeleteClassRequest = new DeleteClassRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteClassResponse$Type extends MessageType<DeleteClassResponse> {
    constructor() {
        super("proto.nigmamsg.DeleteClassResponse", [
            { no: 1, name: "class_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteClassResponse>): DeleteClassResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classId = 0;
        if (value !== undefined)
            reflectionMergePartial<DeleteClassResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteClassResponse): DeleteClassResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_id */ 1:
                    message.classId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteClassResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_id = 1; */
        if (message.classId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.DeleteClassResponse
 */
export const DeleteClassResponse = new DeleteClassResponse$Type();
/**
 * @generated ServiceType for protobuf service proto.nigmamsg.ClassService
 */
export const ClassService = new ServiceType("proto.nigmamsg.ClassService", [
    { name: "ListClass", options: {}, I: ListClassRequest, O: ListClassResponse },
    { name: "ItemClass", options: {}, I: ItemClassRequest, O: ItemClassResponse },
    { name: "CreateClass", options: {}, I: CreateClassRequest, O: CreateClassResponse },
    { name: "UpdateClass", options: {}, I: UpdateClassRequest, O: UpdateClassResponse },
    { name: "DeleteClass", options: {}, I: DeleteClassRequest, O: DeleteClassResponse }
]);
