// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "classifier.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Pagination } from "./proto/nigma-common-proto/filter";
import { Sort } from "./proto/nigma-common-proto/filter";
import { Filter } from "./proto/nigma-common-proto/filter";
/**
 * Модель данных
 * Префикс для фильтров cls.
 *
 * @generated from protobuf message proto.nigmamsg.ClassifierModel
 */
export interface ClassifierModel {
    /**
     * @generated from protobuf field: int64 classifier_id = 1;
     */
    classifierId: number;
    /**
     * @generated from protobuf field: string classifier_name = 2;
     */
    classifierName: string;
    /**
     * @generated from protobuf field: string classifier_descr = 3;
     */
    classifierDescr: string;
    /**
     * @generated from protobuf field: string classifier_notes = 4;
     */
    classifierNotes: string;
    /**
     * @generated from protobuf field: bool classifier_multiple = 5;
     */
    classifierMultiple: boolean;
    /**
     * @generated from protobuf field: int64 classess_cnt = 6;
     */
    classessCnt: number;
}
/**
 * Входящий запрос для получения списка данных
 *
 * @generated from protobuf message proto.nigmamsg.ListClassifierRequest
 */
export interface ListClassifierRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
}
/**
 * Ответ сервера для получения списка данных
 *
 * @generated from protobuf message proto.nigmamsg.ListClassifierResponse
 */
export interface ListClassifierResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.ClassifierModel items = 1;
     */
    items: ClassifierModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListClassifierRequest params = 2;
     */
    params?: ListClassifierRequest;
}
/**
 * Входящий запрос для получения элемента
 *
 * @generated from protobuf message proto.nigmamsg.ItemClassifierRequest
 */
export interface ItemClassifierRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
}
/**
 * Ответ сервера для получения элемента
 *
 * @generated from protobuf message proto.nigmamsg.ItemClassifierResponse
 */
export interface ItemClassifierResponse {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassifierModel item = 1;
     */
    item?: ClassifierModel;
}
/**
 * Входящий запрос для создания элемента
 *
 * @generated from protobuf message proto.nigmamsg.CreateClassifierRequest
 */
export interface CreateClassifierRequest {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassifierModel item = 1;
     */
    item?: ClassifierModel;
}
/**
 * Ответ сервера для создания элемента
 *
 * @generated from protobuf message proto.nigmamsg.CreateClassifierResponse
 */
export interface CreateClassifierResponse {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassifierModel item = 1;
     */
    item?: ClassifierModel;
}
/**
 * Входящий запрос для обновления элемента
 *
 * @generated from protobuf message proto.nigmamsg.UpdateClassifierRequest
 */
export interface UpdateClassifierRequest {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassifierModel item = 1;
     */
    item?: ClassifierModel;
}
/**
 * Ответ сервера для обновления элемента
 *
 * @generated from protobuf message proto.nigmamsg.UpdateClassifierResponse
 */
export interface UpdateClassifierResponse {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassifierModel item = 1;
     */
    item?: ClassifierModel;
}
/**
 * Входящий запрос для удаления элемента
 *
 * @generated from protobuf message proto.nigmamsg.DeleteClassifierRequest
 */
export interface DeleteClassifierRequest {
    /**
     * @generated from protobuf field: int64 classifier_id = 1;
     */
    classifierId: number;
}
/**
 * Ответ сервера для удаления элемента
 *
 * @generated from protobuf message proto.nigmamsg.DeleteClassifierResponse
 */
export interface DeleteClassifierResponse {
    /**
     * @generated from protobuf field: int64 classifier_id = 1;
     */
    classifierId: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class ClassifierModel$Type extends MessageType<ClassifierModel> {
    constructor() {
        super("proto.nigmamsg.ClassifierModel", [
            { no: 1, name: "classifier_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "classifier_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "classifier_descr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "classifier_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "classifier_multiple", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "classess_cnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ClassifierModel>): ClassifierModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classifierId = 0;
        message.classifierName = "";
        message.classifierDescr = "";
        message.classifierNotes = "";
        message.classifierMultiple = false;
        message.classessCnt = 0;
        if (value !== undefined)
            reflectionMergePartial<ClassifierModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClassifierModel): ClassifierModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 classifier_id */ 1:
                    message.classifierId = reader.int64().toNumber();
                    break;
                case /* string classifier_name */ 2:
                    message.classifierName = reader.string();
                    break;
                case /* string classifier_descr */ 3:
                    message.classifierDescr = reader.string();
                    break;
                case /* string classifier_notes */ 4:
                    message.classifierNotes = reader.string();
                    break;
                case /* bool classifier_multiple */ 5:
                    message.classifierMultiple = reader.bool();
                    break;
                case /* int64 classess_cnt */ 6:
                    message.classessCnt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ClassifierModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 classifier_id = 1; */
        if (message.classifierId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classifierId);
        /* string classifier_name = 2; */
        if (message.classifierName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.classifierName);
        /* string classifier_descr = 3; */
        if (message.classifierDescr !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.classifierDescr);
        /* string classifier_notes = 4; */
        if (message.classifierNotes !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.classifierNotes);
        /* bool classifier_multiple = 5; */
        if (message.classifierMultiple !== false)
            writer.tag(5, WireType.Varint).bool(message.classifierMultiple);
        /* int64 classess_cnt = 6; */
        if (message.classessCnt !== 0)
            writer.tag(6, WireType.Varint).int64(message.classessCnt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ClassifierModel
 */
export const ClassifierModel = new ClassifierModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListClassifierRequest$Type extends MessageType<ListClassifierRequest> {
    constructor() {
        super("proto.nigmamsg.ListClassifierRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination }
        ]);
    }
    create(value?: PartialMessage<ListClassifierRequest>): ListClassifierRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ListClassifierRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListClassifierRequest): ListClassifierRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListClassifierRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListClassifierRequest
 */
export const ListClassifierRequest = new ListClassifierRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListClassifierResponse$Type extends MessageType<ListClassifierResponse> {
    constructor() {
        super("proto.nigmamsg.ListClassifierResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ClassifierModel },
            { no: 2, name: "params", kind: "message", T: () => ListClassifierRequest }
        ]);
    }
    create(value?: PartialMessage<ListClassifierResponse>): ListClassifierResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListClassifierResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListClassifierResponse): ListClassifierResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.ClassifierModel items */ 1:
                    message.items.push(ClassifierModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListClassifierRequest params */ 2:
                    message.params = ListClassifierRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListClassifierResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.ClassifierModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            ClassifierModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListClassifierRequest params = 2; */
        if (message.params)
            ListClassifierRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListClassifierResponse
 */
export const ListClassifierResponse = new ListClassifierResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemClassifierRequest$Type extends MessageType<ItemClassifierRequest> {
    constructor() {
        super("proto.nigmamsg.ItemClassifierRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter }
        ]);
    }
    create(value?: PartialMessage<ItemClassifierRequest>): ItemClassifierRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ItemClassifierRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemClassifierRequest): ItemClassifierRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ItemClassifierRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ItemClassifierRequest
 */
export const ItemClassifierRequest = new ItemClassifierRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemClassifierResponse$Type extends MessageType<ItemClassifierResponse> {
    constructor() {
        super("proto.nigmamsg.ItemClassifierResponse", [
            { no: 1, name: "item", kind: "message", T: () => ClassifierModel }
        ]);
    }
    create(value?: PartialMessage<ItemClassifierResponse>): ItemClassifierResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ItemClassifierResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemClassifierResponse): ItemClassifierResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassifierModel item */ 1:
                    message.item = ClassifierModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ItemClassifierResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassifierModel item = 1; */
        if (message.item)
            ClassifierModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ItemClassifierResponse
 */
export const ItemClassifierResponse = new ItemClassifierResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateClassifierRequest$Type extends MessageType<CreateClassifierRequest> {
    constructor() {
        super("proto.nigmamsg.CreateClassifierRequest", [
            { no: 1, name: "item", kind: "message", T: () => ClassifierModel }
        ]);
    }
    create(value?: PartialMessage<CreateClassifierRequest>): CreateClassifierRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateClassifierRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateClassifierRequest): CreateClassifierRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassifierModel item */ 1:
                    message.item = ClassifierModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateClassifierRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassifierModel item = 1; */
        if (message.item)
            ClassifierModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.CreateClassifierRequest
 */
export const CreateClassifierRequest = new CreateClassifierRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateClassifierResponse$Type extends MessageType<CreateClassifierResponse> {
    constructor() {
        super("proto.nigmamsg.CreateClassifierResponse", [
            { no: 1, name: "item", kind: "message", T: () => ClassifierModel }
        ]);
    }
    create(value?: PartialMessage<CreateClassifierResponse>): CreateClassifierResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateClassifierResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateClassifierResponse): CreateClassifierResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassifierModel item */ 1:
                    message.item = ClassifierModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateClassifierResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassifierModel item = 1; */
        if (message.item)
            ClassifierModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.CreateClassifierResponse
 */
export const CreateClassifierResponse = new CreateClassifierResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateClassifierRequest$Type extends MessageType<UpdateClassifierRequest> {
    constructor() {
        super("proto.nigmamsg.UpdateClassifierRequest", [
            { no: 1, name: "item", kind: "message", T: () => ClassifierModel }
        ]);
    }
    create(value?: PartialMessage<UpdateClassifierRequest>): UpdateClassifierRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateClassifierRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateClassifierRequest): UpdateClassifierRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassifierModel item */ 1:
                    message.item = ClassifierModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateClassifierRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassifierModel item = 1; */
        if (message.item)
            ClassifierModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.UpdateClassifierRequest
 */
export const UpdateClassifierRequest = new UpdateClassifierRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateClassifierResponse$Type extends MessageType<UpdateClassifierResponse> {
    constructor() {
        super("proto.nigmamsg.UpdateClassifierResponse", [
            { no: 1, name: "item", kind: "message", T: () => ClassifierModel }
        ]);
    }
    create(value?: PartialMessage<UpdateClassifierResponse>): UpdateClassifierResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateClassifierResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateClassifierResponse): UpdateClassifierResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassifierModel item */ 1:
                    message.item = ClassifierModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateClassifierResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassifierModel item = 1; */
        if (message.item)
            ClassifierModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.UpdateClassifierResponse
 */
export const UpdateClassifierResponse = new UpdateClassifierResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteClassifierRequest$Type extends MessageType<DeleteClassifierRequest> {
    constructor() {
        super("proto.nigmamsg.DeleteClassifierRequest", [
            { no: 1, name: "classifier_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteClassifierRequest>): DeleteClassifierRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classifierId = 0;
        if (value !== undefined)
            reflectionMergePartial<DeleteClassifierRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteClassifierRequest): DeleteClassifierRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 classifier_id */ 1:
                    message.classifierId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteClassifierRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 classifier_id = 1; */
        if (message.classifierId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classifierId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.DeleteClassifierRequest
 */
export const DeleteClassifierRequest = new DeleteClassifierRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteClassifierResponse$Type extends MessageType<DeleteClassifierResponse> {
    constructor() {
        super("proto.nigmamsg.DeleteClassifierResponse", [
            { no: 1, name: "classifier_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteClassifierResponse>): DeleteClassifierResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classifierId = 0;
        if (value !== undefined)
            reflectionMergePartial<DeleteClassifierResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteClassifierResponse): DeleteClassifierResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 classifier_id */ 1:
                    message.classifierId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteClassifierResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 classifier_id = 1; */
        if (message.classifierId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classifierId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.DeleteClassifierResponse
 */
export const DeleteClassifierResponse = new DeleteClassifierResponse$Type();
/**
 * @generated ServiceType for protobuf service proto.nigmamsg.ClassifierService
 */
export const ClassifierService = new ServiceType("proto.nigmamsg.ClassifierService", [
    { name: "ListClassifier", options: {}, I: ListClassifierRequest, O: ListClassifierResponse },
    { name: "ItemClassifier", options: {}, I: ItemClassifierRequest, O: ItemClassifierResponse },
    { name: "CreateClassifier", options: {}, I: CreateClassifierRequest, O: CreateClassifierResponse },
    { name: "UpdateClassifier", options: {}, I: UpdateClassifierRequest, O: UpdateClassifierResponse },
    { name: "DeleteClassifier", options: {}, I: DeleteClassifierRequest, O: DeleteClassifierResponse }
]);
