import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-h5" }
const _hoisted_2 = {
  class: "row items-center no-wrap",
  style: {"gap":"5px"}
}

import BaseModalCard from "@/components/Base/BaseModalCard.vue";
import {onMounted, ref} from "vue";
import {ClassGroupModel} from "proto/nigma-message-proto/polls/class_group";
import {useClassGroupApi} from "@/components/modules/polls/services/useClassGroupApi";
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';;

interface ClassGroupCrudProps {
  classGroupItem: ClassGroupModel | undefined
  crudType: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ClassGroupCrudModal',
  props: {
    classGroupItem: {},
    crudType: {}
  },
  emits: ['hideModal', 'afterClassGroupCrud'],
  setup(__props: any, { emit: __emit }) {

const $q = useQuasar()

const props = __props
const emit = __emit

const { createClassGroupRequest, updateClassGroupRequest } = useClassGroupApi()

const crudTypeTitle = ref('Создание группы классов')
const classGroupItem = ref<ClassGroupModel>({
  classGroupId: 0,
  classGroupName: '',
  classGroupAlias: '',
  classGroupColor: '',
  classGroupRatio: 0,
  classGroupDescr: '',
  classGroupNotes: '',
})
const colorPicker = ref(false)
const crudLoader = ref(false)

const saveHandler = () => {
  crudLoader.value = true

  if (props.crudType === 'create') {
    createClassGroupRequest({
      item: classGroupItem.value
    })
        .then(res => {
          $q.notify({
            type: 'positive',
            message: 'Создание группы классов прошло успешно'
          })

          emit('hideModal')
          emit('afterClassGroupCrud', res.item)
        })
        .catch(() => {
          $q.notify({
            type: 'negative',
            message: 'Что-то пошло не так, попробуйте еще раз'
          })
        })
        .finally(() => crudLoader.value = false)
  } else {
    updateClassGroupRequest({
      item: classGroupItem.value
    })
        .then(() => {
          $q.notify({
            type: 'positive',
            message: 'Редактирование группы классов прошло успешно'
          })

          emit('hideModal')
          emit('afterClassGroupCrud')
        })
        .catch(() => {
          $q.notify({
            type: 'negative',
            message: 'Что-то пошло не так, попробуйте еще раз'
          })
        })
        .finally(() => crudLoader.value = false)
  }
}

const loadData = () => {
  if (props.crudType === 'create') {
    crudTypeTitle.value = 'Создание группы классов'
  } else {
    if (props.classGroupItem) {
      classGroupItem.value = props.classGroupItem
    }

    crudTypeTitle.value = 'Редактирование группы классов'
  }
}

onMounted(() => loadData())

return (_ctx: any,_cache: any) => {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_color = _resolveComponent("q-color")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!

  return (_openBlock(), _createBlock(BaseModalCard, {
    onClose: _cache[10] || (_cache[10] = ($event: any) => (emit('hideModal'))),
    class: "relative-position"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(crudTypeTitle.value), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_q_input, {
        modelValue: classGroupItem.value.classGroupName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((classGroupItem.value.classGroupName) = $event)),
        label: "Наименование",
        filled: ""
      }, null, 8, ["modelValue"]),
      _createVNode(_component_q_input, {
        modelValue: classGroupItem.value.classGroupRatio,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((classGroupItem.value.classGroupRatio) = $event)),
        label: "Влияние на лояльность",
        filled: ""
      }, null, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_2, [
        (classGroupItem.value.classGroupColor)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              style: _normalizeStyle([{"width":"30px","height":"30px"}, `background: ${classGroupItem.value.classGroupColor}`]),
              class: _normalizeClass({'cursor-pointer': classGroupItem.value.classGroupRatio < 0}),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (classGroupItem.value.classGroupRatio < 0 ? colorPicker.value = true : null))
            }, null, 6))
          : _createCommentVNode("", true),
        _createVNode(_component_q_input, {
          modelValue: classGroupItem.value.classGroupColor,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((classGroupItem.value.classGroupColor) = $event)),
          label: "Выбор цвета обозначения группы",
          filled: "",
          readonly: "",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (colorPicker.value = true)),
          class: "full-width"
        }, {
          append: _withCtx(() => [
            _createVNode(_component_q_icon, {
              name: "colorize",
              class: "cursor-pointer"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_popup_proxy, {
                  cover: "",
                  "transition-show": "scale",
                  "transition-hide": "scale",
                  modelValue: colorPicker.value,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((colorPicker).value = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_color, {
                      modelValue: classGroupItem.value.classGroupColor,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((classGroupItem.value.classGroupColor) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          error: _withCtx(() => _cache[11] || (_cache[11] = [
            _createElementVNode("div", null, "Заполните это поле", -1)
          ])),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createVNode(_component_q_input, {
        modelValue: classGroupItem.value.classGroupDescr,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((classGroupItem.value.classGroupDescr) = $event)),
        label: "Описание",
        filled: ""
      }, null, 8, ["modelValue"]),
      _createVNode(_component_q_input, {
        modelValue: classGroupItem.value.classGroupNotes,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((classGroupItem.value.classGroupNotes) = $event)),
        label: "Примечания",
        filled: ""
      }, null, 8, ["modelValue"])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_q_btn, {
        color: "secondary",
        onClick: saveHandler
      }, {
        default: _withCtx(() => _cache[12] || (_cache[12] = [
          _createTextVNode("Сохранить")
        ])),
        _: 1
      }),
      _createVNode(_component_q_btn, {
        color: "red-5",
        onClick: _cache[9] || (_cache[9] = ($event: any) => (emit('hideModal')))
      }, {
        default: _withCtx(() => _cache[13] || (_cache[13] = [
          _createTextVNode("Отмена")
        ])),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_q_inner_loading, {
        showing: crudLoader.value,
        color: "grey"
      }, null, 8, ["showing"])
    ]),
    _: 1
  }))
}
}

})