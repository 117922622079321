import { useGrpcClientWithLogger } from "@/components/modules/rec-system/services/UserGroups/middleware";
import { AgAnswerGrpServiceClient } from "proto/nigma-message-proto/polls/ag_answer_grp.client";
import {
  ListAgAnswerGrpRequest,
  ListAgAnswerGrpWithFindAnswerRequest,
  ListAgAnswerGrpChildsRequest,
  ReduceAgAnswerGrpRequest,
  SeparateAgAnswerGrpRequest,
} from "proto/nigma-message-proto/polls/ag_answer_grp";

export const useAgAnswerGrpApi = () => {
  const oldClient = useGrpcClientWithLogger().create(AgAnswerGrpServiceClient, {
    baseUrl: window.VUE_APP_GATEWAY_URL,
  });

  const metadata = () => {
    return {
      Authorization: localStorage.getItem("access-key") || "",
    };
  };

  return {
    listAgAnswerGrpRequest: (request: ListAgAnswerGrpRequest) => {
      return oldClient.listAgAnswerGrp(request, { meta: metadata() });
    },
    listAgAnswerGrpWithFindAnswerRequest: (
      request: ListAgAnswerGrpWithFindAnswerRequest
    ) => {
      return oldClient.listAgAnswerGrpWithFindAnswer(request, {
        meta: metadata(),
      });
    },
    listAgAnswerGrpChildsRequest: (request: ListAgAnswerGrpChildsRequest) => {
      return oldClient.listAgAnswerGrpChilds(request, { meta: metadata() })
        .response;
    },
    reduceAgAnswerGrpRequest: (request: ReduceAgAnswerGrpRequest) => {
      return oldClient.reduceAgAnswerGrp(request, { meta: metadata() })
        .response;
    },
    separateAgAnswerGrpRequest: (request: SeparateAgAnswerGrpRequest) => {
      return oldClient.separateAgAnswerGrp(request, { meta: metadata() })
        .response;
    },
  };
};
