import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "q-pa-md col-12 items-start q-gutter-md",
  style: {"height":"calc(100% - 140px)"}
}
const _hoisted_2 = { class: "self-center full-width no-outline" }
const _hoisted_3 = { class: "row justify-center" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "text-subtitle2" }
const _hoisted_6 = {
  class: "col-12",
  style: {"widt":"100%","height":"calc(100% - 160px)","overflow":"hidden"}
}
const _hoisted_7 = {
  style: {"width":"calc(100% - 10px)","height":"100%","margin-right":"10px","overflow":"auto","border":"1px solid black"},
  class: "custom-tree"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_field = _resolveComponent("q-field")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_DataLoading = _resolveComponent("DataLoading")!
  const _component_q_tree = _resolveComponent("q-tree")!
  const _component_ValueAdd = _resolveComponent("ValueAdd")!
  const _component_ValueEdit = _resolveComponent("ValueEdit")!
  const _component_ValueDelete = _resolveComponent("ValueDelete")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_card, {
    style: {"width":"1024px","max-width":"80vw","height":"1024px","max-height":"80vh"},
    class: "q-pa-md"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_label, { class: "text-h5" }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode("Создание показателя")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_separator),
      (!_ctx.isScorecardBusy)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_q_card, { style: {"height":"100%"} }, {
              default: _withCtx(() => [
                _createVNode(_component_q_tabs, {
                  modelValue: _ctx.currentTab,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentTab) = $event)),
                  dense: "",
                  align: "justify"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_tab, {
                      name: "main",
                      label: "Основное"
                    }),
                    _createVNode(_component_q_tab, {
                      name: "values",
                      label: "Значения"
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_q_separator),
                _createVNode(_component_q_tab_panels, {
                  modelValue: _ctx.currentTab,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.currentTab) = $event)),
                  animated: "",
                  style: {"height":"calc(100% - 40px)","overflow":"hidden"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_tab_panel, {
                      name: "main",
                      style: {"width":"100% height: 100%","overflow":"auto"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_form, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_card_section, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_input, {
                                  modelValue: _ctx.item.title,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.title) = $event)),
                                  label: "Наименование"
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_q_card_section, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_field, {
                                  filled: "",
                                  "model-value": _ctx.item.parent_id,
                                  label: "Родительский показатель"
                                }, {
                                  prepend: _withCtx(() => [
                                    _createVNode(_component_q_btn, {
                                      "background-color": "gray-12",
                                      onClick: _ctx.changeParent
                                    }, {
                                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                                        _createTextVNode(" Изменить ")
                                      ])),
                                      _: 1
                                    }, 8, ["onClick"])
                                  ]),
                                  control: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.item.parentName), 1)
                                  ]),
                                  _: 1
                                }, 8, ["model-value"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_q_card_section, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_input, {
                                  type: "number",
                                  modelValue: _ctx.item.idx,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.item.idx) = $event)),
                                  min: "1",
                                  label: "Порядок в родительском показателе"
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_q_card_section, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_input, {
                                  type: "number",
                                  modelValue: _ctx.item.weight,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.item.weight) = $event)),
                                  min: "0.0",
                                  max: "1.0",
                                  step: "0.01",
                                  label: "Вес показателя"
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_tab_panel, {
                      name: "values",
                      style: {"height":"100%","overflow":"auto"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_table, {
                          style: {"height":"100%"},
                          rows: _ctx.values,
                          columns: _ctx.columns,
                          "row-key": "title",
                          loading: _ctx.isScorecardValueBusy
                        }, {
                          top: _withCtx(() => [
                            _createVNode(_component_q_btn, {
                              "background-color": "gray-12",
                              disable: _ctx.isScorecardValueBusy,
                              label: "Добавить",
                              icon: "mdi-plus",
                              onClick: _ctx.addValue
                            }, null, 8, ["disable", "onClick"])
                          ]),
                          "body-cell-action": _withCtx((props) => [
                            _createElementVNode("div", _hoisted_3, [
                              _createVNode(_component_q_btn, {
                                flat: "",
                                dense: "",
                                round: "",
                                icon: "mdi-dots-vertical"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_menu, { "auto-close": "" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_list, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_item, {
                                            clickable: "",
                                            onClick: ($event: any) => (_ctx.editValue(props.rowIndex))
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_q_item_section, null, {
                                                default: _withCtx(() => _cache[9] || (_cache[9] = [
                                                  _createTextVNode("Редактировать")
                                                ])),
                                                _: 1
                                              })
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"]),
                                          _createVNode(_component_q_item, {
                                            clickable: "",
                                            onClick: ($event: any) => (_ctx.deleteValue(props.rowIndex))
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_q_item_section, null, {
                                                default: _withCtx(() => _cache[10] || (_cache[10] = [
                                                  _createTextVNode("Удалить")
                                                ])),
                                                _: 1
                                              })
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"])
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024)
                            ])
                          ]),
                          "no-data": _withCtx(() => _cache[11] || (_cache[11] = [
                            _createElementVNode("div", { class: "col-12 text-center" }, "Нет данных", -1)
                          ])),
                          _: 1
                        }, 8, ["rows", "columns", "loading"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_q_separator),
            _createVNode(_component_q_card_actions, { align: "right" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  color: "primary",
                  onClick: _ctx.saveScorecard
                }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode(" Сохранить ")
                  ])),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_q_btn, {
                  color: "primary",
                  onClick: _ctx.closeScorecardAdd
                }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode(" Отмена ")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_DataLoading, { msgLoading: "Сохранение" })
          ])),
      _createVNode(_component_q_dialog, {
        modelValue: _ctx.isActiveModal,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isActiveModal) = $event)),
        persistent: ""
      }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_q_card, {
            style: {"width":"1024px","height":"1024px","max-width":"80vw","max-height":"80vh"},
            class: "q-pa-md"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, { class: "text-h5" }, {
                        default: _withCtx(() => _cache[14] || (_cache[14] = [
                          _createTextVNode("Выбор родительского показателя")
                        ])),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_5, " " + _toDisplayString(_ctx.selectedName) + " ", 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_q_separator),
              _withDirectives(_createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_q_tree, {
                    ref: "scorecardParentTree",
                    nodes: _ctx.getScorecardTreeFormItems,
                    "node-key": "id",
                    "label-key": "title",
                    selected: _ctx.selectedNode,
                    "onUpdate:selected": [
                      _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedNode) = $event)),
                      _ctx.selectNode
                    ]
                  }, null, 8, ["nodes", "selected", "onUpdate:selected"])
                ])
              ], 512), [
                [_vShow, !_ctx.isScorecardTreeFormBusy]
              ]),
              _createVNode(_component_q_separator),
              _createVNode(_component_q_card_actions, { align: "right" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    disable: _ctx.selectedNode === null,
                    color: "primary",
                    onClick: _ctx.selectParent
                  }, {
                    default: _withCtx(() => _cache[15] || (_cache[15] = [
                      _createTextVNode(" Выбрать ")
                    ])),
                    _: 1
                  }, 8, ["disable", "onClick"]),
                  _createVNode(_component_q_btn, {
                    color: "primary",
                    onClick: _ctx.hideModal
                  }, {
                    default: _withCtx(() => _cache[16] || (_cache[16] = [
                      _createTextVNode(" Отмена ")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512), [
            [_vShow, _ctx.isActiveModalTree]
          ]),
          (_ctx.isActiveModalAdd)
            ? (_openBlock(), _createBlock(_component_ValueAdd, {
                key: 0,
                onAfterAdd: _ctx.afterAdd,
                onHideModal: _ctx.hideModal
              }, null, 8, ["onAfterAdd", "onHideModal"]))
            : _createCommentVNode("", true),
          (_ctx.isActiveModalEdit)
            ? (_openBlock(), _createBlock(_component_ValueEdit, {
                key: 1,
                valueItem: _ctx.selectedValue,
                onAfterEdit: _ctx.afterEdit,
                onHideModal: _ctx.hideModal
              }, null, 8, ["valueItem", "onAfterEdit", "onHideModal"]))
            : _createCommentVNode("", true),
          (_ctx.isActiveModalDelete)
            ? (_openBlock(), _createBlock(_component_ValueDelete, {
                key: 2,
                valueItem: _ctx.selectedValue,
                onActualDelete: _ctx.actualDelete,
                onHideModal: _ctx.hideModal
              }, null, 8, ["valueItem", "onActualDelete", "onHideModal"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}