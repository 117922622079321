import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, unref as _unref, isRef as _isRef, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-12 row items-center",
  style: {"height":"100%"}
}
const _hoisted_2 = {
  class: "row items-center relative-position",
  style: {"width":"100%"}
}
const _hoisted_3 = { class: "col-6 q-pa-sm" }
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "text-weight-bold" }
const _hoisted_6 = { class: "col-6" }
const _hoisted_7 = {
  class: "col-12",
  style: {"width":"calc(100% - 50px)"}
}
const _hoisted_8 = {
  key: 0,
  class: "text-subtitle1 text-weight-bold"
}
const _hoisted_9 = { class: "row items-center col-12" }
const _hoisted_10 = { class: "col-6" }
const _hoisted_11 = { class: "col-6 column" }
const _hoisted_12 = {
  key: 0,
  class: "text-subtitle1 text-weight-bold"
}
const _hoisted_13 = { class: "row col-12" }
const _hoisted_14 = {
  class: "col-6",
  style: {"width":"calc(50% - 1px)"}
}
const _hoisted_15 = { class: "text-center" }
const _hoisted_16 = {
  class: "row items-center justify-end",
  style: {"margin-bottom":"4px","margin-right":"4px"}
}
const _hoisted_17 = {
  class: "col-12 relative-position",
  style: {"height":"48vh"}
}
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { key: 1 }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "col-6" }
const _hoisted_22 = {
  class: "row items-center justify-start",
  style: {"height":"60px","gap":"30px"}
}
const _hoisted_23 = { style: {"width":"120px"} }
const _hoisted_24 = { class: "text-center" }
const _hoisted_25 = {
  class: "row col-12 items-center q-pl-sm",
  style: {"padding-bottom":"4px"}
}
const _hoisted_26 = {
  class: "col-12 relative-position",
  style: {"height":"48vh"}
}
const _hoisted_27 = ["innerHTML"]
const _hoisted_28 = { key: 1 }
const _hoisted_29 = ["onClick"]
const _hoisted_30 = { class: "row" }

import store from "@/store";
import { computed, onMounted, ref } from "vue";
import { useAgAnswerClassApi } from "@/components/modules/polls/services/useAgAnswerClassApi";
import { MessagePanelProps } from "@/components/modules/polls/components/generalMessages/classification/MultipleClassificationMessages.vue";
import { useAgAnswerGrpClsApi } from "@/components/modules/polls/services/useAgAnswerGrpClsApi";
import { Filter } from "proto/nigma-common-proto/filter";
import { useTextHighlighter } from "@/composables/useTextHighlighter";
import {
  AgAnswerGrpClsInModel,
  AgAnswerGrpClsOutModel,
} from "proto/nigma-message-proto/polls/ag_answer_grp_cls";
import { ClassTagModel } from "proto/nigma-message-proto/polls/class_tag";
import type { QTableColumn } from "quasar";
import { AgAnswerGrpModel } from "proto/nigma-message-proto/polls/ag_answer_grp";
import { ClassNotTagModel } from "proto/nigma-message-proto/polls/class_not_tag";
import { usePagination } from "@/composables/usePagination";

type FindType = {
  label: string;
  value: string | boolean;
  disable?: boolean;
};

type TagsChoiceType = {
  [key: string]: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'AgClassificationMessages',
  props: {
    currentNode: {},
    multipleClassifier: { type: Boolean },
    agPollId: {},
    agQuestionId: {}
  },
  emits: ["showSource", "editClass"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const { listAgAnswerGrpClsInRequest, listAgAnswerGrpClsOutRequest } =
  useAgAnswerGrpClsApi();
const { saveAgAnswerClassRequest, removeAgAnswerClassRequest } =
  useAgAnswerClassApi();
const { markedFindValue } = useTextHighlighter();

const props = __props;
const emit = __emit;

const messageGrpInItems = ref<AgAnswerGrpClsInModel[]>([]);
const messageGrpOutItems = ref<AgAnswerGrpClsOutModel[]>([]);
const classTagsArr = ref<ClassTagModel[]>([]);
const classTagNames = ref<[[string], boolean][]>([]);
const classNotTagsArr = ref<ClassNotTagModel[]>([]);
const classNotTagNames = ref<[[string], boolean][]>([]);

const notClassifierOnly = ref<FindType>({
  label: "к классификатору",
  value: true,
});
const notClassifierOnlyOptions = ref<FindType[]>([
  {
    label: "к классификатору",
    value: true,
  },
  {
    label: "к классу",
    value: false,
  },
]);
const columnsInClass: QTableColumn[] = [
  {
    name: "messageGrpText",
    field: "messageGrpText",
    label: "Ответы",
    align: "left",
  },
  {
    name: "messageGrpSum",
    field: "messageGrpSum",
    label: "Количество голосов",
    align: "center",
  },
];
const columnsOutClass: QTableColumn[] = [
  {
    name: "messageGrpText",
    field: "messageGrpText",
    label: "Ответы",
    align: "left",
  },
  {
    name: "messageGrpSum",
    field: "messageGrpSum",
    label: "Количество голосов",
    align: "center",
  },
];
const inFilter = ref<Filter>({
  items: [],
});
const outFilter = ref<Filter>({
  items: [],
});
const inLoader = ref(false);
const outLoader = ref(false);
const { onTableRequest: inRequest, pagination: inPagination } = usePagination({
  sortBy: "message_grp_sum",
  descending: true,
  page: 1,
  rowsPerPage: 25,
  rowsNumber: 0,
});
const { onTableRequest: outRequest, pagination: outPagination } = usePagination(
  {
    sortBy: "message_grp_sum",
    descending: true,
    page: 1,
    rowsPerPage: 25,
    rowsNumber: 0,
  }
);
const rowsPerPageAnswerOptions = [10, 25, 50, 100];
const findTypeOptions = ref<FindType[]>([
  {
    label: "Поиск по тэгам",
    value: "tags",
  },
  {
    label: "Поиск по семантике",
    value: "similar",
    disable: true,
  },
  {
    label: "Простой поиск",
    value: "single",
  },
]);
const findType = ref<FindType>(findTypeOptions.value[0]);
const markedFind = ref<string[]>([]);
const selectedInClass = ref<AgAnswerGrpClsInModel[]>([]);
const selectedOutClass = ref<AgAnswerGrpClsOutModel[]>([]);
const search = ref("");
const searchEnter = ref("");
const similarity = ref("0.6");
const selectedTags = ref<TagsChoiceType>();
const selectedNotTags = ref<TagsChoiceType>();
const tagButtonShow = ref(false);
const notTagButtonShow = ref(false);
const viewChild = ref(false);
const abortOutTable = ref<null | AbortController>(null);
const agPollId = ref(0);
const agQuestionId = ref(0);

const userRole = computed(() => store.getters.getUserAccess);

const onRowShowAnswerSource = (row: AgAnswerGrpModel, isFull: boolean) =>
  emit("showSource", row, isFull);

const onUpdateSelectedIn = () => {
  selectedInClass.value = JSON.parse(JSON.stringify(selectedInClass.value));
  findTypeOptions.value[1].disable = !(
    selectedInClass.value && selectedInClass.value.length > 0
  );
};

// const changeNotClassifierOnly = () => {
//   findAnswers()
// }

const findAnswers = () => {
  outFilter.value.items = [];
  markedFind.value = [];

  if (findType.value.value === "single") {
    if (search.value.length) {
      outFilter.value.items = [
        {
          fieldName: "ag.message_grp_text",
          operand: "ilike",
          value: search.value,
        },
      ];

      markedFind.value.push(search.value);
    }
  }

  if (findType.value.value === "tags") {
    const selectedTagItems = Object.keys(selectedTags.value || {}).filter(
      (el) => selectedTags.value && selectedTags.value[el] === true
    );
    const selectedNotTagItems = Object.keys(selectedNotTags.value || {}).filter(
      (el) => selectedNotTags.value && selectedNotTags.value[el] === true
    );

    let filterTag = selectedTagItems.map((item) => {
      return {
        fieldName: "ag.message_grp_text",
        operand: "ilike",
        value: item,
      };
    });

    let filterNotTag = selectedNotTagItems.map((item) => {
      return {
        fieldName: "ag.message_grp_text",
        operand: "not ilike",
        value: item,
      };
    });

    const resultFilter = filterTag.concat(filterNotTag);

    outFilter.value.items = resultFilter ? resultFilter : [];
    markedFind.value = selectedTagItems;
  }

  if (findType.value.value === "similar") {
    const selectedIn = selectedInClass.value;

    let similar = parseFloat(similarity.value),
      filterSimilar = selectedIn.map((item) => {
        return {
          fieldName: "ag.message_grp_text",
          operand: "similar_" + similar.toFixed(2),
          value: item.messageGrpText,
        };
      });

    outFilter.value.items = filterSimilar ? filterSimilar : [];
  }

  loadOutAnswers(true);

  return true;
};

const findJoinAnswers = () => {
  inFilter.value.items = [];
  markedFind.value = [];

  if (searchEnter.value.length) {
    inFilter.value.items = [
      {
        fieldName: "ag.message_grp_text",
        operand: "ilike",
        value: searchEnter.value,
      },
    ];

    markedFind.value.push(searchEnter.value);
  }

  loadInAnswers(true);

  return true;
};

const addAnswers = () => {
  if (selectedOutClass.value.length > 0) {
    saveAgAnswerClassRequest({
      classId: props.currentNode?.classId || 0,
      items: selectedOutClass.value.map((item) => {
        return {
          classId: props.currentNode?.classId || 0,
          messageGrpId: item.messageGrpId,
          agAnswerGrpId: item.agAnswerGrpId,
        };
      }),
    }).then(() => reloadLists());
  }
};

const removeAnswers = () => {
  if (selectedInClass.value.length) {
    removeAgAnswerClassRequest({
      classId: props.currentNode?.classId || 0,
      items: selectedInClass.value.map((item) => {
        return {
          classId: props.currentNode?.classId || 0,
          messageGrpId: item.messageGrpId,
          agAnswerGrpId: item.agAnswerGrpId,
        };
      }),
    }).then(() => reloadLists());
  }
};

const reloadLists = () => {
  selectedInClass.value = [];
  selectedOutClass.value = [];

  loadInAnswers(true);
  loadOutAnswers(false);
};

const loadInAnswers = (isResetPagination: boolean) => {
  inLoader.value = true;

  if (isResetPagination) {
    inPagination.value.page = 1;
    inPagination.value.rowsNumber = 0;
  }

  listAgAnswerGrpClsInRequest({
    filter: inFilter.value,
    sort: {
      name: inPagination.value.sortBy,
      exp: inPagination.value.descending ? "DESC" : "ASC",
    },
    pagination: {
      page: inPagination.value.page,
      limit: inPagination.value.rowsPerPage,
      pages: 0,
      cnt: 0,
    },
    agQuestionId: agQuestionId.value,
    agPollId: agPollId.value,
    classId: props.currentNode?.classId || 0,
    viewChild: viewChild.value,
  })
    .then((res) => {
      if (res) {
        messageGrpInItems.value = res.items;
        inPagination.value.rowsNumber = res.params?.pagination?.cnt || 0;
      }
    })
    .finally(() => (inLoader.value = false));
};

const loadOutAnswers = (isResetPagination: boolean) => {
  tagButtonShow.value = false;
  notTagButtonShow.value = false;
  outLoader.value = true;

  if (isResetPagination) {
    outPagination.value.page = 1;
    outPagination.value.rowsNumber = 0;
  }

  if (abortOutTable.value) {
    abortOutTable.value.abort();
  }

  abortOutTable.value = new AbortController();

  const selectedTagItems = Object.keys(selectedTags.value || {}).filter(
    (el) => selectedTags.value && selectedTags.value[el] === true
  );
  const selectedNotTagItems = Object.keys(selectedNotTags.value || {}).filter(
    (el) => selectedNotTags.value && selectedNotTags.value[el] === true
  );

  if (findType.value.value === "tags") {
    outFilter.value.items = selectedTagItems
      .map((el) => {
        return {
          fieldName: "ag.message_grp_text",
          operand: "ilike",
          value: el,
        };
      })
      .concat(
        selectedNotTagItems.map((item) => {
          return {
            fieldName: "ag.message_grp_text",
            operand: "not ilike",
            value: item,
          };
        })
      );

    markedFind.value = selectedTagItems;
  }

  listAgAnswerGrpClsOutRequest(
    {
      filter: outFilter.value,
      sort: {
        name: outPagination.value.sortBy,
        exp: outPagination.value.descending ? "DESC" : "ASC",
      },
      pagination: {
        page: outPagination.value.page,
        limit: outPagination.value.rowsPerPage,
        pages: 0,
        cnt: 0,
      },
      agQuestionId: agQuestionId.value,
      agPollId: agPollId.value,
      classId: props.currentNode?.classId || 0,
      notClassifierOnly: notClassifierOnly.value.value as boolean,
      viewChild: viewChild.value,
    },
    abortOutTable.value
  )
    .then((res) => {
      if (res) {
        messageGrpOutItems.value = res.items;
        outPagination.value.rowsNumber = res.params?.pagination?.cnt || 0;
        outLoader.value = false;
      }
    })
    .catch((err) => {
      if (err.code === "CANCELLED") {
        abortOutTable.value = null;

        return;
      }
    });
};

const onRequestIn = inRequest(() => loadInAnswers(false));
const onRequestOut = outRequest(() => loadOutAnswers(false));

const editClass = (tabType: string) => emit("editClass", tabType);

const hasSelected = (props: any) => {
  const filter = selectedOutClass.value;
  const row = ref(props);

  if (filter.indexOf(row.value) == -1) {
    filter.push(row.value);
  } else {
    filter.splice(filter.indexOf(row.value), 1);
  }

  selectedOutClass.value = filter;
};

const loadData = (
  isLoadAnswers: boolean,
  classTags?: ClassTagModel[],
  classNotTags?: ClassNotTagModel[],
  pollId?: number,
  questionId?: number
) => {
  selectedInClass.value = [];
  selectedOutClass.value = [];
  markedFind.value = [];
  search.value = "";
  searchEnter.value = "";
  similarity.value = "0.6";
  findType.value = findTypeOptions.value[0];
  findTypeOptions.value[1].disable = true;
  tagButtonShow.value = false;
  notTagButtonShow.value = false;
  agPollId.value = pollId || 0;
  agQuestionId.value = questionId || 0;

  if (isLoadAnswers) {
    if (classTags) {
      classTagsArr.value = classTags;
      classTagNames.value = classTags.map((item) => [[item.tagName], true]);

      selectedTags.value = Object.fromEntries(classTagNames.value);
    }

    if (classNotTags) {
      classNotTagsArr.value = classNotTags;
      classNotTagNames.value = classNotTags.map((item) => [
        [item.notTagName],
        false,
      ]);

      selectedNotTags.value = Object.fromEntries(classNotTagNames.value);
    }

    loadInAnswers(true);
    loadOutAnswers(true);
  }
};

__expose({ loadData });

onMounted(() => {
  loadData(false);
});

return (_ctx: any,_cache: any) => {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_chip = _resolveComponent("q-chip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, { style: {"height":"100%","box-shadow":"none"} }, {
    default: _withCtx(() => [
      (_ctx.currentNode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: "text-subtitle1 custom-ellipsis",
                  title: _ctx.currentNode.className
                }, [
                  _cache[16] || (_cache[16] = _createTextVNode(" Выбранный класс: ")),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.currentNode.className), 1)
                ], 8, _hoisted_4)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _cache[18] || (_cache[18] = _createElementVNode("div", { class: "text-subtitle2 text-weight-bold" }, "Теги для поиска", -1)),
                _createElementVNode("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(classTagsArr.value, (item, index) => {
                    return (_openBlock(), _createBlock(_component_q_chip, {
                      disable: findType.value.value !== 'tags',
                      selected: selectedTags.value[item.tagName],
                      "onUpdate:selected": [
                        ($event: any) => ((selectedTags.value[item.tagName]) = $event),
                        _cache[0] || (_cache[0] = ($event: any) => (tagButtonShow.value = true))
                      ],
                      key: index,
                      class: _normalizeClass([selectedTags.value[item.tagName] ? 'bg-green-3' : null, "cursor-pointer"])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.tagName) + " ", 1),
                        (item.tagDescr)
                          ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.tagDescr), 1)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["disable", "selected", "onUpdate:selected", "class"]))
                  }), 128)),
                  (tagButtonShow.value)
                    ? (_openBlock(), _createBlock(_component_q_btn, {
                        key: 0,
                        label: "Применить",
                        color: "primary",
                        dense: "",
                        class: "q-ml-sm",
                        onClick: findAnswers
                      }))
                    : _createCommentVNode("", true)
                ]),
                (!classTagsArr.value.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _cache[17] || (_cache[17] = _createTextVNode(" Заданные теги для поиска отсутствуют ")),
                      ('admin editor'.includes(userRole.value))
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (editClass('tags'))),
                            class: "text-blue-9 cursor-pointer",
                            style: {"border-bottom":"2px solid blue"}
                          }, "Добавить теги"))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_component_q_separator, { style: {"width":"100%","margin":"5px 0"} }),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_q_checkbox, {
                    label: "Показывать дочерние сообщения класса",
                    class: "q-mr-sm",
                    modelValue: viewChild.value,
                    "onUpdate:modelValue": [
                      _cache[2] || (_cache[2] = ($event: any) => ((viewChild).value = $event)),
                      reloadLists
                    ]
                  }, {
                    default: _withCtx(() => [
                      (_ctx.multipleClassifier)
                        ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                            default: _withCtx(() => _cache[19] || (_cache[19] = [
                              _createElementVNode("div", { class: "text-subtitle2" }, " Выберите классификатор без возможности привязки сообщения к нескольким классами ", -1)
                            ])),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _cache[21] || (_cache[21] = _createElementVNode("div", { class: "text-subtitle2 text-weight-bold" }, " Теги для исключения ", -1)),
                  _createElementVNode("div", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(classNotTagsArr.value, (item, index) => {
                      return (_openBlock(), _createBlock(_component_q_chip, {
                        disable: findType.value.value !== 'tags',
                        selected: selectedNotTags.value[item.notTagName],
                        "onUpdate:selected": [
                          ($event: any) => ((selectedNotTags.value[item.notTagName]) = $event),
                          _cache[3] || (_cache[3] = ($event: any) => (notTagButtonShow.value = true))
                        ],
                        key: item.classId + index,
                        class: _normalizeClass([selectedNotTags.value[item.notTagName] ? 'bg-red-5' : null, "cursor-pointer"]),
                        "icon-selected": "mdi-close"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.notTagName) + " ", 1),
                          (item.notTagDescr)
                            ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.notTagDescr), 1)
                                ]),
                                _: 2
                              }, 1024))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["disable", "selected", "onUpdate:selected", "class"]))
                    }), 128)),
                    (notTagButtonShow.value)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          label: "Применить",
                          color: "primary",
                          dense: "",
                          class: "q-ml-sm",
                          onClick: findAnswers
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  (!classNotTagsArr.value.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _cache[20] || (_cache[20] = _createTextVNode(" Заданные теги для исключения отсутствуют ")),
                        ('admin editor'.includes(userRole.value))
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              onClick: _cache[4] || (_cache[4] = ($event: any) => (editClass('notTags'))),
                              class: "text-blue-9 cursor-pointer",
                              style: {"border-bottom":"2px solid blue"}
                            }, "Добавить теги"))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createVNode(_component_q_btn, {
                class: "absolute-right",
                icon: "edit",
                color: "primary",
                style: {"padding":"0","margin":"0","width":"40px","height":"40px","top":"2px","right":"5px"},
                onClick: _cache[5] || (_cache[5] = ($event: any) => (editClass('main')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_tooltip, null, {
                    default: _withCtx(() => _cache[22] || (_cache[22] = [
                      _createElementVNode("div", { class: "text-subtitle1" }, "Редактировать класс", -1)
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_q_separator, { style: {"width":"100%"} }),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", {
                  class: _normalizeClass(["row items-center q-pr-sm", 
              'admin editor'.includes(userRole.value)
                ? 'justify-end'
                : 'justify-center'
            ]),
                  style: {"height":"60px","width":"100%","gap":"30px"}
                }, [
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_q_item_label, { class: "text-h6 text-green text-weight-bold" }, {
                      default: _withCtx(() => _cache[23] || (_cache[23] = [
                        _createTextVNode("Привязанные")
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", null, [
                    ('admin editor'.includes(userRole.value))
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          outline: "",
                          color: "primary",
                          disable: inLoader.value || !selectedInClass.value.length,
                          label: _ctx.$q.screen.sm ? '' : 'Убрать',
                          "no-wrap": "",
                          "icon-right": "mdi-arrow-right-thick",
                          onClick: removeAnswers,
                          style: {"width":"100%","margin-right":"10px"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_tooltip, null, {
                              default: _withCtx(() => _cache[24] || (_cache[24] = [
                                _createTextVNode("Убрать выбранные ответы из класса")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["disable", "label"]))
                      : _createCommentVNode("", true)
                  ])
                ], 2),
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    modelValue: searchEnter.value,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((searchEnter).value = $event)),
                    label: "Текст для поиска",
                    onKeydown: _withKeys(_withModifiers(findJoinAnswers, ["prevent"]), ["enter"]),
                    filled: ""
                  }, null, 8, ["modelValue", "onKeydown"]),
                  _createVNode(_component_q_btn, {
                    style: {"margin":"5px 5px"},
                    "background-color": "gray-12",
                    disable: inLoader.value,
                    icon: "mdi-magnify",
                    onClick: findJoinAnswers
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => _cache[25] || (_cache[25] = [
                          _createTextVNode("Найти ответы")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["disable"])
                ]),
                _createVNode(_component_q_separator),
                _createElementVNode("div", _hoisted_17, [
                  (_openBlock(), _createBlock(_component_q_table, {
                    style: _normalizeStyle([{"overflow":"auto"}, messageGrpInItems.value.length ? 'height: 48vh' : null]),
                    class: "sticky-header-table-answer",
                    flat: "",
                    dense: "",
                    rows: messageGrpInItems.value,
                    columns: columnsInClass,
                    "row-key": "messageGrpId",
                    selection: 
                'admin editor'.includes(userRole.value) ? 'multiple' : 'none'
              ,
                    "wrap-cells": "",
                    selected: selectedInClass.value,
                    "onUpdate:selected": [
                      _cache[7] || (_cache[7] = ($event: any) => ((selectedInClass).value = $event)),
                      onUpdateSelectedIn
                    ],
                    pagination: _unref(inPagination),
                    "onUpdate:pagination": _cache[8] || (_cache[8] = ($event: any) => (_isRef(inPagination) ? (inPagination).value = $event : null)),
                    "rows-per-page-options": rowsPerPageAnswerOptions,
                    onRequest: _unref(onRequestIn),
                    key: 'inClasses_' + _ctx.currentNode.classId + '_' + agQuestionId.value
                  }, {
                    "body-cell-messageGrpText": _withCtx((props) => [
                      _createVNode(_component_q_td, { props: props }, {
                        default: _withCtx(() => [
                          (classTagsArr.value.length)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                innerHTML: _unref(markedFindValue)(props.value, markedFind.value)
                              }, null, 8, _hoisted_18))
                            : (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(props.value), 1))
                        ]),
                        _: 2
                      }, 1032, ["props"])
                    ]),
                    "body-cell-messageGrpSum": _withCtx((props) => [
                      _createVNode(_component_q_td, {
                        props: props,
                        style: {"width":"60px"}
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", {
                            style: {"cursor":"pointer"},
                            title: "Исходные ответы",
                            onClick: ($event: any) => (onRowShowAnswerSource(props.row, true))
                          }, _toDisplayString(props.value.toLocaleString()), 9, _hoisted_20)
                        ]),
                        _: 2
                      }, 1032, ["props"])
                    ]),
                    pagination: _withCtx((scope) => [
                      _createTextVNode(_toDisplayString((
                    scope.pagination.rowsPerPage * scope.pagination.page -
                    scope.pagination.rowsPerPage +
                    1
                  ).toLocaleString()) + " - " + _toDisplayString(scope.pagination.rowsPerPage * scope.pagination.page >
                  scope.pagination.rowsNumber
                    ? scope.pagination.rowsNumber.toLocaleString()
                    : (
                        scope.pagination.rowsPerPage * scope.pagination.page
                      ).toLocaleString()) + " из " + _toDisplayString(scope.pagination.rowsNumber.toLocaleString()) + " ", 1),
                      (scope.pagesNumber > 2)
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 0,
                            icon: "first_page",
                            color: "grey-8",
                            round: "",
                            dense: "",
                            flat: "",
                            disable: scope.isFirstPage,
                            onClick: scope.firstPage,
                            class: "q-ml-md",
                            size: "10px"
                          }, null, 8, ["disable", "onClick"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_q_btn, {
                        icon: "chevron_left",
                        color: "grey-8",
                        round: "",
                        dense: "",
                        flat: "",
                        disable: scope.isFirstPage,
                        onClick: scope.prevPage,
                        size: "10px"
                      }, null, 8, ["disable", "onClick"]),
                      _createVNode(_component_q_btn, {
                        icon: "chevron_right",
                        color: "grey-8",
                        round: "",
                        dense: "",
                        flat: "",
                        disable: scope.isLastPage,
                        onClick: scope.nextPage,
                        size: "10px"
                      }, null, 8, ["disable", "onClick"]),
                      (scope.pagesNumber > 2)
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 1,
                            icon: "last_page",
                            color: "grey-8",
                            round: "",
                            dense: "",
                            flat: "",
                            disable: scope.isLastPage,
                            onClick: scope.lastPage,
                            size: "10px"
                          }, null, 8, ["disable", "onClick"]))
                        : _createCommentVNode("", true)
                    ]),
                    "no-data": _withCtx(() => _cache[26] || (_cache[26] = [
                      _createElementVNode("div", { class: "col-12 text-center text-h4" }, "Нет данных", -1)
                    ])),
                    _: 1
                  }, 8, ["style", "rows", "selection", "selected", "pagination", "onRequest"])),
                  _createVNode(_component_q_inner_loading, {
                    showing: inLoader.value,
                    color: "grey"
                  }, null, 8, ["showing"])
                ])
              ]),
              _createVNode(_component_q_separator, { vertical: "" }),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    ('admin editor'.includes(userRole.value))
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          outline: "",
                          color: "primary",
                          disable: outLoader.value || !selectedOutClass.value.length,
                          label: _ctx.$q.screen.sm ? '' : 'Добавить',
                          "no-wrap": "",
                          icon: "mdi-arrow-left-thick",
                          onClick: addAnswers,
                          style: {"width":"100%","margin-left":"10px"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_tooltip, null, {
                              default: _withCtx(() => _cache[27] || (_cache[27] = [
                                _createTextVNode("Добавить выбранные ответы в класс")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["disable", "label"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_24, [
                    _createVNode(_component_q_item_label, { class: "text-h6 text-orange text-weight-bold" }, {
                      default: _withCtx(() => _cache[28] || (_cache[28] = [
                        _createTextVNode("Непривязанные")
                      ])),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _createVNode(_component_q_tab_panels, {
                    class: "col-xl-7 col-lg-5",
                    dense: "",
                    modelValue: findType.value.value,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((findType.value.value) = $event)),
                    animated: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_tab_panel, {
                        name: "tags",
                        style: {"padding":"0"}
                      }, {
                        default: _withCtx(() => _cache[29] || (_cache[29] = [
                          _createElementVNode("div", { class: "text-subtitle2 text-weight-bold" }, " Выберите теги в списке сверху ", -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_q_tab_panel, {
                        name: "similar",
                        style: {"padding":"0"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_input, {
                            dense: "",
                            type: "number",
                            modelValue: similarity.value,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((similarity).value = $event)),
                            min: "0.01",
                            max: "0.99",
                            step: "0.01",
                            label: "Коэффициент схожести",
                            onKeydown: _withKeys(_withModifiers(findAnswers, ["prevent"]), ["enter"]),
                            filled: ""
                          }, null, 8, ["modelValue", "onKeydown"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_q_tab_panel, {
                        name: "single",
                        style: {"padding":"0"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_input, {
                            dense: "",
                            modelValue: search.value,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((search).value = $event)),
                            label: "Текст для поиска",
                            onKeydown: _withKeys(_withModifiers(findAnswers, ["prevent"]), ["enter"]),
                            filled: ""
                          }, null, 8, ["modelValue", "onKeydown"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_q_btn, {
                    style: {"margin":"5px 5px"},
                    "background-color": "gray-12",
                    disable: outLoader.value,
                    icon: "mdi-magnify",
                    onClick: findAnswers
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => _cache[30] || (_cache[30] = [
                          _createTextVNode("Найти ответы")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["disable"]),
                  _createVNode(_component_q_btn, {
                    label: "Настройки",
                    "background-color": "gray12",
                    icon: "mdi-settings"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_menu, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_list, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_item, { class: "row items-center" }, {
                                default: _withCtx(() => [
                                  _cache[31] || (_cache[31] = _createElementVNode("div", { class: "text-subtitle1" }, "Привязка:", -1)),
                                  _createVNode(_component_q_select, {
                                    filled: "",
                                    options: notClassifierOnlyOptions.value,
                                    modelValue: notClassifierOnly.value,
                                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((notClassifierOnly).value = $event)),
                                    "option-value": "value",
                                    "option-label": "label",
                                    dense: "",
                                    class: "q-ml-auto",
                                    style: {"width":"200px","margin-left":"10px"}
                                  }, null, 8, ["options", "modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_q_item, { class: "row items-center" }, {
                                default: _withCtx(() => [
                                  _cache[32] || (_cache[32] = _createElementVNode("div", { class: "text-subtitle1" }, "Поиск:", -1)),
                                  _createVNode(_component_q_select, {
                                    filled: "",
                                    options: findTypeOptions.value,
                                    modelValue: findType.value,
                                    "onUpdate:modelValue": [
                                      _cache[13] || (_cache[13] = ($event: any) => ((findType).value = $event)),
                                      findAnswers
                                    ],
                                    "option-value": "value",
                                    "option-label": "label",
                                    dense: "",
                                    class: "q-ml-auto",
                                    style: {"width":"200px"}
                                  }, null, 8, ["options", "modelValue"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _createVNode(_component_q_separator),
                _createElementVNode("div", _hoisted_26, [
                  (_openBlock(), _createBlock(_component_q_table, {
                    style: _normalizeStyle(messageGrpOutItems.value.length ? 'height: 48vh' : null),
                    class: "sticky-header-table-answer",
                    flat: "",
                    dense: "",
                    rows: messageGrpOutItems.value,
                    columns: columnsOutClass,
                    "row-key": "messageGrpId",
                    selection: 
                'admin editor'.includes(userRole.value) ? 'multiple' : 'none'
              ,
                    "wrap-cells": "",
                    selected: selectedOutClass.value,
                    "onUpdate:selected": _cache[14] || (_cache[14] = ($event: any) => ((selectedOutClass).value = $event)),
                    pagination: _unref(outPagination),
                    "onUpdate:pagination": _cache[15] || (_cache[15] = ($event: any) => (_isRef(outPagination) ? (outPagination).value = $event : null)),
                    "rows-per-page-options": rowsPerPageAnswerOptions,
                    onRequest: _unref(onRequestOut),
                    key: 
                'outClasses_' + _ctx.currentNode?.classifierId + '_' + agQuestionId.value
              
                  }, {
                    "body-cell-messageGrpText": _withCtx((props) => [
                      _createVNode(_component_q_td, {
                        class: _normalizeClass(["cursor-pointer", props.row.has_classes ? 'bg-cyan-1' : '']),
                        props: props,
                        onClick: ($event: any) => (hasSelected(props.row))
                      }, {
                        default: _withCtx(() => [
                          (markedFind.value.length)
                            ? (_openBlock(), _createElementBlock("span", {
                                key: 0,
                                innerHTML: _unref(markedFindValue)(props.value, markedFind.value)
                              }, null, 8, _hoisted_27))
                            : (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(props.value), 1))
                        ]),
                        _: 2
                      }, 1032, ["props", "class", "onClick"])
                    ]),
                    "body-cell-messageGrpSum": _withCtx((props) => [
                      _createVNode(_component_q_td, {
                        props: props,
                        class: _normalizeClass(props.row.has_classes ? 'bg-cyan-1' : ''),
                        style: {"width":"60px"}
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", {
                            style: {"cursor":"pointer"},
                            title: "Исходные ответы",
                            onClick: ($event: any) => (onRowShowAnswerSource(props.row, true))
                          }, _toDisplayString(props.value.toLocaleString()), 9, _hoisted_29)
                        ]),
                        _: 2
                      }, 1032, ["props", "class"])
                    ]),
                    pagination: _withCtx((scope) => [
                      _createTextVNode(_toDisplayString((
                    scope.pagination.rowsPerPage * scope.pagination.page -
                    scope.pagination.rowsPerPage +
                    1
                  ).toLocaleString()) + " - " + _toDisplayString(scope.pagination.rowsPerPage * scope.pagination.page >
                  scope.pagination.rowsNumber
                    ? scope.pagination.rowsNumber.toLocaleString()
                    : (
                        scope.pagination.rowsPerPage * scope.pagination.page
                      ).toLocaleString()) + " из " + _toDisplayString(scope.pagination.rowsNumber.toLocaleString()) + " ", 1),
                      (scope.pagesNumber > 2)
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 0,
                            icon: "first_page",
                            color: "grey-8",
                            round: "",
                            dense: "",
                            flat: "",
                            disable: scope.isFirstPage,
                            onClick: scope.firstPage,
                            class: "q-ml-md",
                            size: "10px"
                          }, null, 8, ["disable", "onClick"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_q_btn, {
                        icon: "chevron_left",
                        color: "grey-8",
                        round: "",
                        dense: "",
                        flat: "",
                        disable: scope.isFirstPage,
                        onClick: scope.prevPage,
                        size: "10px"
                      }, null, 8, ["disable", "onClick"]),
                      _createVNode(_component_q_btn, {
                        icon: "chevron_right",
                        color: "grey-8",
                        round: "",
                        dense: "",
                        flat: "",
                        disable: scope.isLastPage,
                        onClick: scope.nextPage,
                        size: "10px"
                      }, null, 8, ["disable", "onClick"]),
                      (scope.pagesNumber > 2)
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 1,
                            icon: "last_page",
                            color: "grey-8",
                            round: "",
                            dense: "",
                            flat: "",
                            disable: scope.isLastPage,
                            onClick: scope.lastPage,
                            size: "10px"
                          }, null, 8, ["disable", "onClick"]))
                        : _createCommentVNode("", true)
                    ]),
                    "no-data": _withCtx(() => _cache[33] || (_cache[33] = [
                      _createElementVNode("div", { class: "col-12 text-center text-h4" }, "Нет данных", -1)
                    ])),
                    _: 1
                  }, 8, ["style", "rows", "selection", "selected", "pagination", "onRequest"])),
                  _createVNode(_component_q_inner_loading, {
                    showing: outLoader.value,
                    color: "grey"
                  }, null, 8, ["showing"])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("div", _hoisted_30, [
        _createVNode(_component_q_card_section, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_item_label, { class: "text-h5 text-center" }, {
              default: _withCtx(() => _cache[34] || (_cache[34] = [
                _createTextVNode("Не выбран узел дерева")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ], 512), [
        [_vShow, !_ctx.currentNode]
      ])
    ]),
    _: 1
  }))
}
}

})