import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative-position column" }
const _hoisted_2 = { class: "row items-start justify-between" }
const _hoisted_3 = {
  key: 0,
  class: "relative-position full-width"
}
const _hoisted_4 = {
  key: 1,
  class: "relative-position full-width"
}

import { computed, ref, toRaw, onMounted } from "vue";
import store from "@/store";
import ExportCityEarned from "@/components/modules/intersection/components/CityPoints/ExportCityEarned.vue";
import type { QTableColumn } from "quasar";


export default /*@__PURE__*/_defineComponent({
  __name: 'CityEarnedTable',
  props: ['dateMin', 'dateMax', 'splitAction'],
  setup(__props, { expose: __expose }) {

const props = __props

const earnedCityPagination = ref({
  sortBy: 'transaction_dt',
  descending: true,
  page: 1,
  rowsPerPage: 10,
  rowsNumber: 0,
})
const earnedCityGroupPagination = ref({
  sortBy: 'transaction_dt_count',
  descending: true,
  page: 1,
  rowsPerPage: 10,
  rowsNumber: 0,
})
const groupOptions = ref([
  {
    label: 'Без группировки',
    value: 'no_group',
  },
  {
    label: 'По SSO ID',
    value: 'sso_id',
  },
  {
    label: 'По проекту',
    value: 'project_id',
  },
  {
    label: 'По действию',
    value: 'activ_id',
  },
  {
    label: 'По дате транзакции',
    value: 'transaction_dt',
  },
])
const groupCurrent = ref(groupOptions.value[0])

const columns = ref([
  {
    name: 'sso_id',
    field: 'sso_id',
    label: 'SSO ID',
    align: 'left',
    style: 'width: 280px',
    headerStyle: 'font-weight: 700',
  },
  {
    name: 'project_name',
    field: 'project_name',
    label: 'Название проекта',
    align: 'left',
    sortable: true,
    style: 'width: 170px',
    headerStyle: 'font-weight: 700',
  },
  {
    name: 'activ_text',
    field: 'activ_text',
    label: 'Действие',
    align: 'left',
    sortable: true,
    style: 'width: 185px',
    headerStyle: 'font-weight: 700',
  },
  {
    name: 'description',
    field: 'description',
    label: 'Текст начисления',
    align: 'left',
    style: 'width: 400px',
    headerStyle: 'font-weight: 700',
  },
  {
    name: 'transaction_dt',
    field: 'transaction_dt',
    label: 'Дата транзакции',
    align: 'center',
    sortable: true,
    style: 'width: 200px',
    headerStyle: 'font-weight: 700',
  },
  {
    name: 'value',
    field: 'value',
    label: 'Количество баллов',
    align: 'center',
    sortable: true,
    headerStyle: 'font-weight: 700',
  },
])
const groupColumns = ref<QTableColumn[]>([])

const rows = computed(() => {
  const items = store.getters.getEarnedPointsTable
  const filteredList = []

  if (items && items.items) {
    for (const i of items.items) {
      filteredList.push({
        sso_id: i.getSsoId(),
        project_name: i.getProjectName(),
        activ_text: i.getActivText(),
        description: i.getDescription(),
        transaction_dt: i.getTransactionDt().split('-').reverse().join('.'),
        value: i.getValue().toLocaleString(),
      })
    }
  }

  return filteredList
})
const groupRows = computed(() => {
  const items = store.getters.getEarnedPointsGroupTable
  const filteredList = []

  if (items && items.items) {
    for (const i of items.items) {
      filteredList.push({
        sso_id_count:
          groupCurrent.value.value !== 'sso_id'
            ? Number(i.getSsoIdCount()).toLocaleString()
            : i.getSsoIdCount()
        ,
        project_count:
          groupCurrent.value.value !== 'project_id'
            ? Number(i.getProjectCount()).toLocaleString()
            : i.getProjectCount()
        ,
        activ_count: i.getActivCount().toLocaleString(),
        description_count: Number(i.getDescriptionCount()).toLocaleString(),
        transaction_dt_count: i.getTransactionDtCount().split('-').reverse().join('.').toLocaleString(),
        value: i.getValue().toLocaleString(),
      })
    }
  }

  return filteredList
})
const isEarnedPointsTable = computed(() => store.getters.isEarnedPointsTable)
const isEarnedPointsGroupTable = computed(() => store.getters.isEarnedPointsGroupTable)
const metricValues = computed(() => {
  const metricList = store.getters.getMetricChecked

  return toRaw(metricList)
})

const getEarnedTable = () => {
  store
    .dispatch('getEarnedPointsTable', {
      filter: [],
      sort: {
        name: earnedCityPagination.value.sortBy,
        exp: earnedCityPagination.value.descending ? 'DESC' : 'ASC'
      },
      pagination: {
        page: earnedCityPagination.value.page,
        limit: earnedCityPagination.value.rowsPerPage,
        pages: 0,
        cnt: 0
      },
      activity: metricValues.value['Активность'],
      loyalty: metricValues.value['Лояльность'],
      useful: metricValues.value['Полезность'],
      interest: metricValues.value['Заинтересованность'],
      activ_ids: props.splitAction,
      date_begin: props.dateMin,
      date_end: props.dateMax,
    })
    .then(res => {
      earnedCityPagination.value.rowsNumber = res.pagination
    })
}

const loadGroupedData = () => {
  let ssoColName = ''
  let projectName = ''
  let activName = ''
  let dateName = ''

  if (groupCurrent.value.value === 'sso_id') {
    ssoColName = 'SSO ID'
    projectName = 'Количество проектов'
    activName = 'Количество типов действий'
    dateName = 'Количество дат транзакций'
  }

  if (groupCurrent.value.value === 'project_id') {
    ssoColName = 'Количество пользователей'
    projectName = 'Название проекта'
    activName = 'Количество типов действий'
    dateName = 'Количество дат транзакций'
  }

  if (groupCurrent.value.value === 'activ_id') {
    ssoColName = 'Количество пользователей'
    projectName = 'Количество проектов'
    activName = 'Действие'
    dateName = 'Количество дат транзакций'
  }

  if (groupCurrent.value.value === 'transaction_dt') {
    ssoColName = 'Количество пользователей'
    projectName = 'Количество проектов'
    activName = 'Количество типов действий'
    dateName = 'Дата транзакции'
  }

  groupColumns.value = [
    {
      name: 'sso_id_count',
      field: 'sso_id_count',
      label: ssoColName,
      align: 'left',
      style: 'width: 330px',
      headerStyle: 'font-weight: 700',
      sortable: groupCurrent.value.value !== 'sso_id',
    },
    {
      name: 'project_count',
      field: 'project_count',
      label: projectName,
      align: groupCurrent.value.value !== 'project_id' ? 'center' : 'left',
      sortable: true,
      style: 'width: 220px',
      headerStyle: 'font-weight: 700',
    },
    {
      name: 'activ_count',
      field: 'activ_count',
      label: activName,
      align: groupCurrent.value.value !== 'activ_id' ? 'center' : 'left',
      sortable: true,
      style: 'width: 185px',
      headerStyle: 'font-weight: 700',
    },
    {
      name: 'description_count',
      field: 'description_count',
      label: 'Количество наименований активностей',
      align: 'center',
      style: 'width: 250px',
      headerStyle: 'font-weight: 700',
      sortable: true,
    },
    {
      name: 'transaction_dt_count',
      field: 'transaction_dt_count',
      label: dateName,
      align: 'center',
      sortable: true,
      style: 'width: 250px',
      headerStyle: 'font-weight: 700',
    },
    {
      name: 'value',
      field: 'value',
      label: 'Количество баллов',
      align: 'center',
      sortable: true,
      headerStyle: 'font-weight: 700',
    },
  ]

  store
    .dispatch('getEarnedPointsGroupTable', {
      filter: [],
      sort: {
        name: earnedCityGroupPagination.value.sortBy,
        exp: earnedCityGroupPagination.value.descending ? 'DESC' : 'ASC'
      },
      pagination: {
        page: earnedCityGroupPagination.value.page,
        limit: earnedCityGroupPagination.value.rowsPerPage,
        pages: 0,
        cnt: 0
      },
      activity: metricValues.value['Активность'],
      loyalty: metricValues.value['Лояльность'],
      useful: metricValues.value['Полезность'],
      interest: metricValues.value['Заинтересованность'],
      activ_ids: props.splitAction,
      date_begin: props.dateMin,
      date_end: props.dateMax,
      group_by: groupCurrent.value.value,
    })
    .then(res => {
      earnedCityGroupPagination.value.rowsNumber = res.pagination

      console.log('response', res)
    })
}

const onRequest = (props: any) => {
  earnedCityPagination.value = props.pagination

  getEarnedTable()
}

const onGroupRequest = (props: any) => {
  earnedCityGroupPagination.value = props.pagination

  loadGroupedData()
}

const selectHandler = () => {
  if (groupCurrent.value.value !== 'no_group') {
    loadGroupedData()
  } else {
    getEarnedTable()
  }
}

__expose({
  getEarnedTable,
  loadGroupedData,
  groupCurrent,
})

onMounted(() => getEarnedTable())

return (_ctx: any,_cache: any) => {
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_select, {
        style: {"width":"400px"},
        filled: "",
        label: "Группировка по столбцу",
        modelValue: groupCurrent.value,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((groupCurrent).value = $event)),
          selectHandler
        ],
        options: groupOptions.value,
        disable: isEarnedPointsTable.value || isEarnedPointsGroupTable.value
      }, null, 8, ["modelValue", "options", "disable"]),
      _createVNode(ExportCityEarned, {
        "date-min": __props.dateMin,
        "date-max": __props.dateMax,
        "split-action": __props.splitAction,
        grouping: groupCurrent.value.value,
        descending: earnedCityGroupPagination.value.descending,
        class: "q-mb-md",
        style: {"height":"56px"}
      }, null, 8, ["date-min", "date-max", "split-action", "grouping", "descending"])
    ]),
    (groupCurrent.value.value === 'no_group')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_q_table, {
            columns: columns.value,
            rows: rows.value,
            pagination: earnedCityPagination.value,
            "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => ((earnedCityPagination).value = $event)),
            onRequest: onRequest,
            "rows-per-page-options": [10, 25, 50, 100],
            "wrap-cells": "",
            "binary-state-sort": "",
            class: "full-width earned-table"
          }, {
            pagination: _withCtx((scope) => [
              _createTextVNode(_toDisplayString((scope.pagination.rowsPerPage * scope.pagination.page - scope.pagination.rowsPerPage + 1).toLocaleString()) + " - " + _toDisplayString(scope.pagination.rowsPerPage * scope.pagination.page > scope.pagination.rowsNumber
            ? scope.pagination.rowsNumber.toLocaleString()
            : (scope.pagination.rowsPerPage * scope.pagination.page).toLocaleString()) + " из " + _toDisplayString(scope.pagination.rowsNumber.toLocaleString()) + " ", 1),
              (scope.pagesNumber > 2)
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 0,
                    icon: "first_page",
                    color: "grey-8",
                    round: "",
                    dense: "",
                    flat: "",
                    disable: scope.isFirstPage,
                    onClick: scope.firstPage,
                    class: "q-ml-md"
                  }, null, 8, ["disable", "onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_q_btn, {
                icon: "chevron_left",
                color: "grey-8",
                round: "",
                dense: "",
                flat: "",
                disable: scope.isFirstPage,
                onClick: scope.prevPage
              }, null, 8, ["disable", "onClick"]),
              _createVNode(_component_q_btn, {
                icon: "chevron_right",
                color: "grey-8",
                round: "",
                dense: "",
                flat: "",
                disable: scope.isLastPage,
                onClick: scope.nextPage
              }, null, 8, ["disable", "onClick"]),
              (scope.pagesNumber > 2)
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 1,
                    icon: "last_page",
                    color: "grey-8",
                    round: "",
                    dense: "",
                    flat: "",
                    disable: scope.isLastPage,
                    onClick: scope.lastPage
                  }, null, 8, ["disable", "onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["columns", "rows", "pagination"]),
          _createVNode(_component_q_inner_loading, {
            showing: isEarnedPointsTable.value,
            color: "grey",
            style: {"z-index":"100"}
          }, null, 8, ["showing"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_q_table, {
            columns: groupColumns.value,
            rows: groupRows.value,
            pagination: earnedCityGroupPagination.value,
            "onUpdate:pagination": _cache[2] || (_cache[2] = ($event: any) => ((earnedCityGroupPagination).value = $event)),
            onRequest: onGroupRequest,
            "rows-per-page-options": [10, 25, 50, 100],
            "wrap-cells": "",
            "binary-state-sort": "",
            class: "full-width earned-table"
          }, null, 8, ["columns", "rows", "pagination"]),
          _createVNode(_component_q_inner_loading, {
            showing: isEarnedPointsGroupTable.value,
            color: "grey",
            style: {"z-index":"100"}
          }, null, 8, ["showing"])
        ]))
  ]))
}
}

})