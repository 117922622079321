// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "message_grp.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { MessageGrpService } from "./message_grp";
import type { SeparateMessageGrpResponse } from "./message_grp";
import type { SeparateMessageGrpRequest } from "./message_grp";
import type { SplitMessageGrpResponse } from "./message_grp";
import type { SplitMessageGrpRequest } from "./message_grp";
import type { ReduceMessageGrpResponse } from "./message_grp";
import type { ReduceMessageGrpRequest } from "./message_grp";
import type { UpdateTextMessageGrpResponse } from "./message_grp";
import type { UpdateTextMessageGrpRequest } from "./message_grp";
import type { ListMessageGrpChildsResponse } from "./message_grp";
import type { ListMessageGrpChildsRequest } from "./message_grp";
import type { ListMessageGrpWithFindMessageResponse } from "./message_grp";
import type { ListMessageGrpWithFindMessageRequest } from "./message_grp";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListMessageGrpResponse } from "./message_grp";
import type { ListMessageGrpRequest } from "./message_grp";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.MessageGrpService
 */
export interface IMessageGrpServiceClient {
    /**
     * @generated from protobuf rpc: ListMessageGrp(proto.nigmamsg.ListMessageGrpRequest) returns (proto.nigmamsg.ListMessageGrpResponse);
     */
    listMessageGrp(input: ListMessageGrpRequest, options?: RpcOptions): UnaryCall<ListMessageGrpRequest, ListMessageGrpResponse>;
    /**
     * @generated from protobuf rpc: ListMessageGrpWithFindMessage(proto.nigmamsg.ListMessageGrpWithFindMessageRequest) returns (proto.nigmamsg.ListMessageGrpWithFindMessageResponse);
     */
    listMessageGrpWithFindMessage(input: ListMessageGrpWithFindMessageRequest, options?: RpcOptions): UnaryCall<ListMessageGrpWithFindMessageRequest, ListMessageGrpWithFindMessageResponse>;
    /**
     * @generated from protobuf rpc: ListMessageGrpChilds(proto.nigmamsg.ListMessageGrpChildsRequest) returns (proto.nigmamsg.ListMessageGrpChildsResponse);
     */
    listMessageGrpChilds(input: ListMessageGrpChildsRequest, options?: RpcOptions): UnaryCall<ListMessageGrpChildsRequest, ListMessageGrpChildsResponse>;
    /**
     * @generated from protobuf rpc: UpdateTextMessageGrp(proto.nigmamsg.UpdateTextMessageGrpRequest) returns (proto.nigmamsg.UpdateTextMessageGrpResponse);
     */
    updateTextMessageGrp(input: UpdateTextMessageGrpRequest, options?: RpcOptions): UnaryCall<UpdateTextMessageGrpRequest, UpdateTextMessageGrpResponse>;
    /**
     * @generated from protobuf rpc: ReduceMessageGrp(proto.nigmamsg.ReduceMessageGrpRequest) returns (proto.nigmamsg.ReduceMessageGrpResponse);
     */
    reduceMessageGrp(input: ReduceMessageGrpRequest, options?: RpcOptions): UnaryCall<ReduceMessageGrpRequest, ReduceMessageGrpResponse>;
    /**
     * @generated from protobuf rpc: SplitMessageGrp(proto.nigmamsg.SplitMessageGrpRequest) returns (proto.nigmamsg.SplitMessageGrpResponse);
     */
    splitMessageGrp(input: SplitMessageGrpRequest, options?: RpcOptions): UnaryCall<SplitMessageGrpRequest, SplitMessageGrpResponse>;
    /**
     * @generated from protobuf rpc: SeparateMessageGrp(proto.nigmamsg.SeparateMessageGrpRequest) returns (proto.nigmamsg.SeparateMessageGrpResponse);
     */
    separateMessageGrp(input: SeparateMessageGrpRequest, options?: RpcOptions): UnaryCall<SeparateMessageGrpRequest, SeparateMessageGrpResponse>;
}
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.MessageGrpService
 */
export class MessageGrpServiceClient implements IMessageGrpServiceClient, ServiceInfo {
    typeName = MessageGrpService.typeName;
    methods = MessageGrpService.methods;
    options = MessageGrpService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ListMessageGrp(proto.nigmamsg.ListMessageGrpRequest) returns (proto.nigmamsg.ListMessageGrpResponse);
     */
    listMessageGrp(input: ListMessageGrpRequest, options?: RpcOptions): UnaryCall<ListMessageGrpRequest, ListMessageGrpResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMessageGrpRequest, ListMessageGrpResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListMessageGrpWithFindMessage(proto.nigmamsg.ListMessageGrpWithFindMessageRequest) returns (proto.nigmamsg.ListMessageGrpWithFindMessageResponse);
     */
    listMessageGrpWithFindMessage(input: ListMessageGrpWithFindMessageRequest, options?: RpcOptions): UnaryCall<ListMessageGrpWithFindMessageRequest, ListMessageGrpWithFindMessageResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMessageGrpWithFindMessageRequest, ListMessageGrpWithFindMessageResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListMessageGrpChilds(proto.nigmamsg.ListMessageGrpChildsRequest) returns (proto.nigmamsg.ListMessageGrpChildsResponse);
     */
    listMessageGrpChilds(input: ListMessageGrpChildsRequest, options?: RpcOptions): UnaryCall<ListMessageGrpChildsRequest, ListMessageGrpChildsResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMessageGrpChildsRequest, ListMessageGrpChildsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateTextMessageGrp(proto.nigmamsg.UpdateTextMessageGrpRequest) returns (proto.nigmamsg.UpdateTextMessageGrpResponse);
     */
    updateTextMessageGrp(input: UpdateTextMessageGrpRequest, options?: RpcOptions): UnaryCall<UpdateTextMessageGrpRequest, UpdateTextMessageGrpResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateTextMessageGrpRequest, UpdateTextMessageGrpResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReduceMessageGrp(proto.nigmamsg.ReduceMessageGrpRequest) returns (proto.nigmamsg.ReduceMessageGrpResponse);
     */
    reduceMessageGrp(input: ReduceMessageGrpRequest, options?: RpcOptions): UnaryCall<ReduceMessageGrpRequest, ReduceMessageGrpResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReduceMessageGrpRequest, ReduceMessageGrpResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SplitMessageGrp(proto.nigmamsg.SplitMessageGrpRequest) returns (proto.nigmamsg.SplitMessageGrpResponse);
     */
    splitMessageGrp(input: SplitMessageGrpRequest, options?: RpcOptions): UnaryCall<SplitMessageGrpRequest, SplitMessageGrpResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<SplitMessageGrpRequest, SplitMessageGrpResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SeparateMessageGrp(proto.nigmamsg.SeparateMessageGrpRequest) returns (proto.nigmamsg.SeparateMessageGrpResponse);
     */
    separateMessageGrp(input: SeparateMessageGrpRequest, options?: RpcOptions): UnaryCall<SeparateMessageGrpRequest, SeparateMessageGrpResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<SeparateMessageGrpRequest, SeparateMessageGrpResponse>("unary", this._transport, method, opt, input);
    }
}
