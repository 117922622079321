import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "quiz-agc" }
const _hoisted_2 = { class: "quiz-agc__filters" }
const _hoisted_3 = { class: "quiz-agc__table" }

import { ref, watch } from 'vue';
import date from 'quasar/src/utils/date/date.js';
import { usePagination } from '@/composables/usePagination';
import { LoadPolls } from '../../../../polls/services/api/LoadPolls';
import type { QTableColumn } from 'quasar';
import QuizSsoTable from './QuizSsoTable.vue';

type Props = {
  dateBegin: string,
  dateEnd: string
}

type Emits = {
  onVoteCntClick: [pollId: number]
}
type Row = {
  id: number,
  title: string,
  status: string,
  dateStart: string,
  dateEnd: string,
  cntVotes: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'QuizTable',
  props: {
    dateBegin: {},
    dateEnd: {}
  },
  emits: ["onVoteCntClick"],
  setup(__props: any) {

const props = __props



const LoadPollsApi = new LoadPolls()
const { onTableRequest, pagination } = usePagination()

const tableColumns: QTableColumn<Row>[] = [
  {
    field: 'id',
    name: 'id',
    label: 'ID',
    align: 'left',
  },
  {
    field: 'title',
    name: 'title',
    label: 'Наименование',
    align: 'left',
  },
  {
    field: 'status',
    name: 'status',
    label: 'Статус',
    align: 'left',
  },
  {
    field: 'dateStart',
    name: 'dateStart',
    label: 'Дата начала',
    align: 'left',
  },
  {
    field: 'dateEnd',
    name: 'dateEnd',
    label: 'Дата окончания',
    align: 'left',
  },
  {
    field: 'cntVotes',
    name: 'cntVotes',
    label: 'Кол-во участников',
    align: 'left',
  },
]

const rows = ref<Row[]>([])
const search = ref('')
const statusOptions = ref([
  {
    label: 'Все',
    value: 1
  },
  {
    label: 'Открыто',
    value: 2,
    operand: '>='
  },
  {
    label: 'Завершено',
    value: 3,
    operand: '<'
  }
])
const status = ref(statusOptions.value[0])


function loadPolls() {
  const filter: Parameters<typeof LoadPollsApi.getPollLoadChildren>['0']['filter'] = { itemsList: [] }

  if (status.value.value !== 1) {
    filter.itemsList.push({
      fieldName: 'poll_date_end',
      operand: status.value.operand || '=',
      value: date.formatDate(Date.now(), 'YYYY-MM-DD')
    })
  }

  if(!search.value){

  filter.itemsList.push({
    fieldName: 'poll_date_begin',
    operand: '>=',
    value: props.dateBegin,
    or: {
      itemsList: [
        {
          fieldName: 'poll_date_end',
          operand: '>=',
          value: props.dateBegin
        }
      ]
    }
  })

  filter.itemsList.push({
    fieldName: 'poll_date_begin',
    operand: '<=',
    value: props.dateEnd,
    or: {
      itemsList: [
        {
          fieldName: 'poll_date_end',
          operand: '<=',
          value: props.dateEnd
        }
      ]
    }
  })
}


  if (search.value) {
    const isNumber = !isNaN(Number(search.value))

    if (isNumber) {
      filter.itemsList.push({
        fieldName: 'poll_id',
        operand: '=',
        value: search.value,
      },)
    } else {

      filter.itemsList.push(
        {
          fieldName: 'poll_title',
          operand: 'ilike',
          value: search.value
        }
      )
    }
  }

  LoadPollsApi.getPollLoadChildren({
    filter,
    sort: { name: pagination.value.sortBy, exp: pagination.value.descending ? 'desc' : 'asc' },
    pagination: { page: pagination.value.page, pages: 0, cnt: 0, limit: pagination.value.rowsPerPage }
  })
    .then(res => {
      pagination.value.rowsNumber = res.toObject().params?.pagination?.cnt || 0
      console.log('%c[DEBUG]:Load polls child', 'color:violet', res.toObject().itemsList, res.toObject().params)
      rows.value = []

      for (const obj of res.toObject().itemsList) {
        const todayDate = Date.now()
        const endCompareDate = (obj.pollDateEnd?.seconds || 0) * 1000
        rows.value.push({
          id: obj.pollId,
          title: obj.pollTitle,
          status: todayDate < endCompareDate ? 'Открыто' : 'Завершено',
          dateStart: date.formatDate(new Date((obj.pollDateBegin?.seconds || 0) * 1000), 'DD.MM.YYYY'),
          dateEnd: date.formatDate(new Date((obj.pollDateEnd?.seconds || 0) * 1000), 'DD.MM.YYYY'),
          cntVotes: obj.pollUserCount
        })
      }
    })
}

const onRequest = onTableRequest(loadPolls)

loadPolls()

watch([props.dateBegin, props.dateEnd], loadPolls)

const quizSsoTableData = ref<{ pollId?: number, pollName?: string }>({
  pollId: undefined,
  pollName: undefined
})
const onVoteClick = (pollId: number, pollName: string) => {
  quizSsoTableData.value.pollId = pollId
  quizSsoTableData.value.pollName = pollName
}

return (_ctx: any,_cache: any) => {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("p", { class: "quiz-agc--title text-h6" }, "Викторины", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_input, {
        filled: "",
        label: "Поиск названию или ID",
        dense: "",
        modelValue: search.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
        style: {"max-width":"300px","width":"100%"},
        onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (loadPolls()), ["enter"]))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_q_btn, {
        icon: "search",
        color: "primary",
        dense: "",
        class: "q-ml-sm",
        style: {"width":"40px"},
        onClick: _cache[2] || (_cache[2] = ($event: any) => (loadPolls()))
      }),
      _createVNode(_component_q_select, {
        class: "q-ml-sm",
        filled: "",
        dense: "",
        label: "Статус",
        modelValue: status.value,
        "onUpdate:modelValue": [
          _cache[3] || (_cache[3] = ($event: any) => ((status).value = $event)),
          _cache[4] || (_cache[4] = ($event: any) => (loadPolls()))
        ],
        options: statusOptions.value,
        style: {"max-width":"130px","width":"100%"}
      }, null, 8, ["modelValue", "options"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_q_table, {
        columns: tableColumns,
        rows: rows.value,
        onRequest: _unref(onRequest),
        pagination: _unref(pagination),
        "onUpdate:pagination": _cache[5] || (_cache[5] = ($event: any) => (_isRef(pagination) ? (pagination).value = $event : null)),
        "binary-state-sort": "",
        dense: ""
      }, {
        "body-cell": _withCtx((props) => [
          _createVNode(_component_q_td, {
            class: _normalizeClass({ 'selected-row': quizSsoTableData.value.pollId == props.row.id }),
            props: props
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.value), 1)
            ]),
            _: 2
          }, 1032, ["class", "props"])
        ]),
        "body-cell-cntVotes": _withCtx((props) => [
          _createVNode(_component_q_td, {
            class: _normalizeClass(["cursor-pointer", { 'selected-row': quizSsoTableData.value.pollId == props.row.id }]),
            style: {"text-decoration":"underline"},
            props: props,
            onClick: ($event: any) => (onVoteClick(props.row.id, props.row.title))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.value), 1)
            ]),
            _: 2
          }, 1032, ["class", "props", "onClick"])
        ]),
        _: 1
      }, 8, ["rows", "onRequest", "pagination"])
    ]),
    (quizSsoTableData.value.pollId && quizSsoTableData.value.pollName)
      ? (_openBlock(), _createBlock(QuizSsoTable, {
          key: 0,
          "vote-id": quizSsoTableData.value.pollId,
          "vote-name": quizSsoTableData.value.pollName
        }, null, 8, ["vote-id", "vote-name"]))
      : _createCommentVNode("", true)
  ]))
}
}

})