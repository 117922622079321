// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "ag_poll.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Pagination } from "./proto/nigma-common-proto/filter";
import { Sort } from "./proto/nigma-common-proto/filter";
import { Filter } from "./proto/nigma-common-proto/filter";
/**
 * Модель данных
 * Префикс для фильтров ap.
 *
 * @generated from protobuf message proto.nigmamsg.AgPollModel
 */
export interface AgPollModel {
    /**
     * @generated from protobuf field: int64 ag_poll_id = 1;
     */
    agPollId: number;
    /**
     * @generated from protobuf field: string ag_poll_group = 2;
     */
    agPollGroup: string;
    /**
     * @generated from protobuf field: string ag_poll_title = 3;
     */
    agPollTitle: string;
    /**
     * @generated from protobuf field: string ag_poll_date_begin = 4;
     */
    agPollDateBegin: string;
    /**
     * @generated from protobuf field: string ag_poll_date_end = 5;
     */
    agPollDateEnd: string;
    /**
     * @generated from protobuf field: string ag_poll_notes = 6;
     */
    agPollNotes: string;
    /**
     * @generated from protobuf field: int64 ag_poll_user_count = 7;
     */
    agPollUserCount: number;
    /**
     * @generated from protobuf field: int64 ag_poll_total_answers = 8;
     */
    agPollTotalAnswers: number;
    /**
     * @generated from protobuf field: int64 ag_poll_open_answers = 9;
     */
    agPollOpenAnswers: number;
    /**
     * @generated from protobuf field: string ag_poll_date_update = 10;
     */
    agPollDateUpdate: string;
    /**
     * @generated from protobuf field: string ag_poll_target = 11;
     */
    agPollTarget: string;
}
/**
 * Входящий запрос для получения списка данных
 *
 * @generated from protobuf message proto.nigmamsg.ListAgPollRequest
 */
export interface ListAgPollRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
}
/**
 * Ответ сервера для получения списка данных
 *
 * @generated from protobuf message proto.nigmamsg.ListAgPollResponse
 */
export interface ListAgPollResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.AgPollModel items = 1;
     */
    items: AgPollModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListAgPollRequest params = 2;
     */
    params?: ListAgPollRequest;
}
// @generated message type with reflection information, may provide speed optimized methods
class AgPollModel$Type extends MessageType<AgPollModel> {
    constructor() {
        super("proto.nigmamsg.AgPollModel", [
            { no: 1, name: "ag_poll_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "ag_poll_group", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "ag_poll_title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "ag_poll_date_begin", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "ag_poll_date_end", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "ag_poll_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "ag_poll_user_count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "ag_poll_total_answers", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "ag_poll_open_answers", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 10, name: "ag_poll_date_update", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "ag_poll_target", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AgPollModel>): AgPollModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.agPollId = 0;
        message.agPollGroup = "";
        message.agPollTitle = "";
        message.agPollDateBegin = "";
        message.agPollDateEnd = "";
        message.agPollNotes = "";
        message.agPollUserCount = 0;
        message.agPollTotalAnswers = 0;
        message.agPollOpenAnswers = 0;
        message.agPollDateUpdate = "";
        message.agPollTarget = "";
        if (value !== undefined)
            reflectionMergePartial<AgPollModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgPollModel): AgPollModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ag_poll_id */ 1:
                    message.agPollId = reader.int64().toNumber();
                    break;
                case /* string ag_poll_group */ 2:
                    message.agPollGroup = reader.string();
                    break;
                case /* string ag_poll_title */ 3:
                    message.agPollTitle = reader.string();
                    break;
                case /* string ag_poll_date_begin */ 4:
                    message.agPollDateBegin = reader.string();
                    break;
                case /* string ag_poll_date_end */ 5:
                    message.agPollDateEnd = reader.string();
                    break;
                case /* string ag_poll_notes */ 6:
                    message.agPollNotes = reader.string();
                    break;
                case /* int64 ag_poll_user_count */ 7:
                    message.agPollUserCount = reader.int64().toNumber();
                    break;
                case /* int64 ag_poll_total_answers */ 8:
                    message.agPollTotalAnswers = reader.int64().toNumber();
                    break;
                case /* int64 ag_poll_open_answers */ 9:
                    message.agPollOpenAnswers = reader.int64().toNumber();
                    break;
                case /* string ag_poll_date_update */ 10:
                    message.agPollDateUpdate = reader.string();
                    break;
                case /* string ag_poll_target */ 11:
                    message.agPollTarget = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgPollModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ag_poll_id = 1; */
        if (message.agPollId !== 0)
            writer.tag(1, WireType.Varint).int64(message.agPollId);
        /* string ag_poll_group = 2; */
        if (message.agPollGroup !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.agPollGroup);
        /* string ag_poll_title = 3; */
        if (message.agPollTitle !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.agPollTitle);
        /* string ag_poll_date_begin = 4; */
        if (message.agPollDateBegin !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.agPollDateBegin);
        /* string ag_poll_date_end = 5; */
        if (message.agPollDateEnd !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.agPollDateEnd);
        /* string ag_poll_notes = 6; */
        if (message.agPollNotes !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.agPollNotes);
        /* int64 ag_poll_user_count = 7; */
        if (message.agPollUserCount !== 0)
            writer.tag(7, WireType.Varint).int64(message.agPollUserCount);
        /* int64 ag_poll_total_answers = 8; */
        if (message.agPollTotalAnswers !== 0)
            writer.tag(8, WireType.Varint).int64(message.agPollTotalAnswers);
        /* int64 ag_poll_open_answers = 9; */
        if (message.agPollOpenAnswers !== 0)
            writer.tag(9, WireType.Varint).int64(message.agPollOpenAnswers);
        /* string ag_poll_date_update = 10; */
        if (message.agPollDateUpdate !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.agPollDateUpdate);
        /* string ag_poll_target = 11; */
        if (message.agPollTarget !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.agPollTarget);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.AgPollModel
 */
export const AgPollModel = new AgPollModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAgPollRequest$Type extends MessageType<ListAgPollRequest> {
    constructor() {
        super("proto.nigmamsg.ListAgPollRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination }
        ]);
    }
    create(value?: PartialMessage<ListAgPollRequest>): ListAgPollRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ListAgPollRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAgPollRequest): ListAgPollRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAgPollRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListAgPollRequest
 */
export const ListAgPollRequest = new ListAgPollRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAgPollResponse$Type extends MessageType<ListAgPollResponse> {
    constructor() {
        super("proto.nigmamsg.ListAgPollResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgPollModel },
            { no: 2, name: "params", kind: "message", T: () => ListAgPollRequest }
        ]);
    }
    create(value?: PartialMessage<ListAgPollResponse>): ListAgPollResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListAgPollResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAgPollResponse): ListAgPollResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.AgPollModel items */ 1:
                    message.items.push(AgPollModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListAgPollRequest params */ 2:
                    message.params = ListAgPollRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAgPollResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.AgPollModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            AgPollModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListAgPollRequest params = 2; */
        if (message.params)
            ListAgPollRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListAgPollResponse
 */
export const ListAgPollResponse = new ListAgPollResponse$Type();
/**
 * @generated ServiceType for protobuf service proto.nigmamsg.AgPollService
 */
export const AgPollService = new ServiceType("proto.nigmamsg.AgPollService", [
    { name: "ListAgPoll", options: {}, I: ListAgPollRequest, O: ListAgPollResponse }
]);
