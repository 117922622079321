import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "q-pa-md row wrap justify-center items-center content-center" }
const _hoisted_2 = {
  key: 0,
  class: "text-h6 col-12 text-center q-pa-md"
}
const _hoisted_3 = {
  key: 1,
  class: "text-h6 col-12 text-center q-pa-md"
}
const _hoisted_4 = {
  id: "errMessage",
  class: "text-negative"
}
const _hoisted_5 = { class: "full-width row wrap justify-center items-center content-center" }
const _hoisted_6 = {
  id: "errMessage",
  class: "text-negative"
}
const _hoisted_7 = { class: "full-width row wrap justify-center items-center content-center relative-position" }

import store from '@/store'
import { ref } from 'vue'
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';;
import {ChangePasswordApi} from "@/components/modules/users/services/api/ChangePasswordApi";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginApp',
  setup(__props) {

const $q = useQuasar()
const login = ref()
const passw = ref()
const isPwd = ref(true)
const errMessage = ref('')
const isReset = ref(false)
const restoreApi = new ChangePasswordApi()
const email = ref('')
const changePwLoader = ref(false)

const onSubmit = () => {
  store
      .dispatch('doUserAuth', {
        login: login.value,
        passw: passw.value
      })
      .then(() => {
        location.reload()
      })
      .catch(() => {
        $q.notify({
          color: 'red-5',
          textColor: 'white',
          icon: 'error',
          message: 'Ошибка при попытке входа, проверьте ваши данные.'
        })
      })
}

const onReset = () => {
  login.value = null
  passw.value = null
}

const onRestore = () => {
  changePwLoader.value = true

  restoreApi
      .changePassword({ email: email.value })
      .then(() => {
        $q.notify({
          type: 'positive',
          message: 'Письмо отправлено на вашу почту, следуйте дальнейшим указаниям.'
        })

        isReset.value = false
      })
      .catch(() => {
        $q.notify({
          type: 'negative',
          message: 'При отправке письма произошла ошибка, попробуйте позже.'
        })
      })
      .finally(() => changePwLoader.value = false)
}

return (_ctx: any,_cache: any) => {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!isReset.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Вход в систему"))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, "Восстановление пароля")),
    _createVNode(_component_q_card, { class: "q-pa-md col-xs-12 col-sm-6 col-lg-4" }, {
      default: _withCtx(() => [
        (!isReset.value)
          ? (_openBlock(), _createBlock(_component_q_form, {
              key: 0,
              onSubmit: _withModifiers(onSubmit, ["prevent"]),
              onReset: onReset,
              class: "q-gutter-md"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_input, {
                  filled: "",
                  modelValue: login.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((login).value = $event)),
                  label: "Логин",
                  "lazy-rules": "",
                  rules: [(val) => (val && val.length > 0) || 'Неправильный логин']
                }, null, 8, ["modelValue", "rules"]),
                _createVNode(_component_q_input, {
                  modelValue: passw.value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((passw).value = $event)),
                  filled: "",
                  type: isPwd.value ? 'password' : 'text',
                  label: "Пароль"
                }, {
                  append: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      name: isPwd.value ? 'visibility_off' : 'visibility',
                      class: "cursor-pointer",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (isPwd.value = !isPwd.value))
                    }, null, 8, ["name"])
                  ]),
                  _: 1
                }, 8, ["modelValue", "type"]),
                _createElementVNode("div", _hoisted_4, _toDisplayString(errMessage.value), 1),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_q_btn, {
                    label: "Забыли пароль?",
                    color: "primary",
                    flat: "",
                    class: "absolute-bottom-right",
                    "no-caps": "",
                    unelevated: "",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (isReset.value = true))
                  }),
                  _createVNode(_component_q_btn, {
                    label: "Войти",
                    type: "submit",
                    color: "primary",
                    "no-caps": "",
                    unelevated: ""
                  }),
                  _createVNode(_component_q_btn, {
                    label: "Сброс",
                    type: "reset",
                    color: "primary",
                    flat: "",
                    class: "q-ml-sm",
                    "no-caps": "",
                    unelevated: ""
                  })
                ])
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_q_form, {
              key: 1,
              onSubmit: _withModifiers(onRestore, ["prevent"]),
              class: "q-gutter-md relative-position"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_input, {
                  filled: "",
                  modelValue: email.value,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((email).value = $event)),
                  label: "Email",
                  "lazy-rules": "",
                  rules: [(val) => (val && val.length > 0) || 'Неправильный email']
                }, null, 8, ["modelValue", "rules"]),
                _createElementVNode("div", _hoisted_6, _toDisplayString(errMessage.value), 1),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_q_btn, {
                    label: "Продолжить",
                    type: "submit",
                    color: "primary",
                    "no-caps": "",
                    unelevated: ""
                  }),
                  _createVNode(_component_q_btn, {
                    label: "Назад",
                    color: "primary",
                    flat: "",
                    class: "absolute-bottom-left q-ml-md",
                    "no-caps": "",
                    unelevated: "",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (isReset.value = false))
                  })
                ]),
                _createVNode(_component_q_inner_loading, {
                  showing: changePwLoader.value,
                  color: "grey"
                }, null, 8, ["showing"])
              ]),
              _: 1
            }))
      ]),
      _: 1
    })
  ]))
}
}

})