import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pa-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChaptersListVue = _resolveComponent("ChaptersListVue")!
  const _component_AddonList = _resolveComponent("AddonList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-h4 q-pb-md" }, "Главная страница", -1)),
    _createVNode(_component_ChaptersListVue),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-h4 q-pb-md q-mt-md" }, "Дополнительно", -1)),
    _createVNode(_component_AddonList)
  ]))
}