import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "bg-white q-pa-md text-black",
  style: {"width":"100%","max-width":"970px"}
}
const _hoisted_2 = {
  style: {"border":"1px solid black"},
  class: "q-mt-md q-pt-sm q-pb-sm"
}
const _hoisted_3 = {
  class: "q-pl-md column items-start",
  style: {"gap":"10px"}
}
const _hoisted_4 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_5 = {
  class: "row q-mt-md justify-end",
  style: {"gap":"10px"}
}

import { ref } from 'vue'
import SettingsModalMetrics
  from "@/components/modules/accounts/components/UserLoyalty/Filters/SettingsModalMetrics.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SettingsModalNew',
  setup(__props) {

const tab = ref('Активный гражданин')
const settingsModal = ref(false)
const selectOptions = ref(['Среднеквадратическое отклонение', 'Медиана'])
const selectModel = ref('Среднеквадратическое отклонение')
const sko = ref(0.75)
const median = ref(1)


return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "row items-center q-gutter-sm cursor-pointer",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (settingsModal.value = !settingsModal.value))
    }, [
      _createVNode(_component_q_icon, {
        name: "settings",
        color: "grey-7"
      }),
      _cache[9] || (_cache[9] = _createElementVNode("span", null, "НАСТРОЙКИ ПОКАЗАТЕЛЕЙ МЕТРИК", -1))
    ]),
    _createVNode(_component_q_dialog, {
      modelValue: settingsModal.value,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((settingsModal).value = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "text-h6 text-weight-bold" }, "Настройки показателей метрик", -1)),
          _createVNode(_component_q_tabs, {
            modelValue: tab.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((tab).value = $event)),
            "inline-label": "",
            "active-color": "primary",
            class: "text-grey-8"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_tab, {
                name: "Активный гражданин",
                label: "Активный гражданин"
              }),
              _createVNode(_component_q_tab, {
                name: "Город заданий",
                label: "Город заданий"
              }),
              _createVNode(_component_q_tab, {
                name: "Город идей",
                label: "Город идей",
                disable: ""
              }),
              _createVNode(_component_q_tab, {
                name: "Электронный дом",
                label: "Электронный дом",
                disable: ""
              }),
              _createVNode(_component_q_tab, {
                name: "Наш город",
                label: "Наш город",
                disable: ""
              }),
              _createVNode(_component_q_tab, {
                name: "Миллион призов",
                label: "Миллион призов",
                disable: ""
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_q_btn, {
              label: "Добавить новую метрику",
              color: "primary",
              class: "q-ml-sm",
              disable: ""
            }),
            _createVNode(_component_q_tab_panels, {
              modelValue: tab.value,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((tab).value = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_tab_panel, {
                  class: "q-mt-md q-mb-md",
                  name: "Активный гражданин",
                  style: {"padding":"0"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(SettingsModalMetrics, { "project-type": 'Активный гражданин' })
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_tab_panel, {
                  class: "q-mt-md q-mb-md",
                  name: "Город заданий",
                  style: {"padding":"0"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(SettingsModalMetrics, { "project-type": 'Город заданий' })
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_tab_panel, {
                  class: "q-mt-md q-mb-md",
                  name: "Город идей",
                  style: {"padding":"0"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(SettingsModalMetrics)
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_tab_panel, {
                  class: "q-mt-md q-mb-md",
                  name: "Электронный дом",
                  style: {"padding":"0"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(SettingsModalMetrics)
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_tab_panel, {
                  class: "q-mt-md q-mb-md",
                  name: "Наш город",
                  style: {"padding":"0"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(SettingsModalMetrics)
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_tab_panel, {
                  class: "q-mt-md q-mb-md",
                  name: "Миллион призов",
                  style: {"padding":"0"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(SettingsModalMetrics)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_q_btn, {
                label: "Добавить показатель в метрику",
                disable: "",
                color: "primary"
              }),
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "text-h6" }, "Разделяющая функция", -1)),
              _createElementVNode("div", _hoisted_4, [
                (selectModel.value === 'Среднеквадратическое отклонение')
                  ? (_openBlock(), _createBlock(_component_q_input, {
                      key: 0,
                      dense: "",
                      class: "input-custom",
                      outlined: "",
                      type: "number",
                      modelValue: sko.value,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((sko).value = $event))
                    }, null, 8, ["modelValue"]))
                  : _createCommentVNode("", true),
                (selectModel.value === 'Медиана')
                  ? (_openBlock(), _createBlock(_component_q_input, {
                      key: 1,
                      dense: "",
                      class: "input-custom",
                      outlined: "",
                      type: "number",
                      modelValue: median.value,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((median).value = $event))
                    }, null, 8, ["modelValue"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_q_select, {
                  outlined: "",
                  dense: "",
                  style: {"width":"400px"},
                  options: selectOptions.value,
                  modelValue: selectModel.value,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((selectModel).value = $event))
                }, null, 8, ["options", "modelValue"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_q_btn, {
              onClick: _cache[6] || (_cache[6] = ($event: any) => (settingsModal.value = false)),
              label: "Применить",
              color: "primary",
              style: {"height":"56px"}
            }),
            _createVNode(_component_q_btn, {
              onClick: _cache[7] || (_cache[7] = ($event: any) => (settingsModal.value = false)),
              label: "Закрыть",
              color: "primary",
              outline: ""
            })
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})