import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pa-sm" }
const _hoisted_2 = { class: "row items-center justify-between full-width" }
const _hoisted_3 = {
  class: "row no-wrap",
  style: {"width":"50%"}
}
const _hoisted_4 = { class: "relative-position q-mt-md" }
const _hoisted_5 = {
  class: "row justify-center",
  style: {"width":"200px"}
}
const _hoisted_6 = { class: "text-subtitle2" }

import {onMounted, ref} from "vue";
import type {QTableColumn} from "quasar";
import {useClassGroupApi} from "@/components/modules/polls/services/useClassGroupApi";
import {ClassGroupModel} from "proto/nigma-message-proto/polls/class_group";
import {useIdealTextColor} from "@/composables/useIdealTextColor";
import ClassGroupCrudModal from "@/components/modules/polls/components/classGroup/ClassGroupCrudModal.vue";
import DeleteClassGroupModal from "@/components/modules/polls/components/classGroup/DeleteClassGroupModal.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ClassGroups',
  setup(__props) {

const { listClassGroupRequest } = useClassGroupApi()
const { idealTextColor } = useIdealTextColor()

const classGroupItem = ref<ClassGroupModel | undefined>()
const classGroupItems = ref<ClassGroupModel[]>([])
const crudType = ref('create')
const columns = ref<QTableColumn[]>([
  {
    name: 'classGroupName',
    label: 'Название группы классов',
    field: 'classGroupName',
    align: 'left',
    headerStyle: 'font-weight: 700',
  },
  {
    name: 'classGroupDescr',
    label: 'Описание группы классов',
    field: 'classGroupDescr',
    align: 'left',
    headerStyle: 'font-weight: 700',
  },
  {
    name: 'actions',
    label: 'Действие',
    field: 'actions',
    style: 'width: 200px',
    align: 'center',
    headerStyle: 'font-weight: 700',
  }
])
const classGroupPagination = ref({
  sortBy: '',
  descending: true,
  page: 1,
  rowsPerPage: 10,
  rowsNumber: 0,
})
const classGroupLoader = ref<boolean>(false)
const classGroupSearch = ref<string>('')
const isActiveModal = ref(false)
const isCrudModal = ref(false)
const isDeleteModal = ref(false)

const onTableRequest = (props) => {
  classGroupPagination.value = props.pagination
  getClassGroupItems()
}

const getClassGroupItems = () => {
  classGroupLoader.value = true

  listClassGroupRequest({
    filter: {
      items: [
        {
          fieldName: 'cg.class_group_name',
          operand: 'ilike',
          value: classGroupSearch.value
        }
      ]
    },
    sort: {
      name: 'class_group_name',
      exp: 'asc'
    },
    pagination: {
      page: classGroupPagination.value.page,
      limit: classGroupPagination.value.rowsPerPage,
      pages: 0,
      cnt: 0,
    }
  })
      .then(res => {
        classGroupItems.value = res.items
        classGroupPagination.value.rowsNumber = res.params.pagination.cnt
      })
      .finally(() => classGroupLoader.value = false)
}

const crudHandler = (type: string, row?: ClassGroupModel) => {
  isActiveModal.value = true
  isCrudModal.value = true

  if (type === 'create') {
    crudType.value = 'create'
    classGroupItem.value = undefined
  } else {
    crudType.value = 'edit'
    classGroupItem.value = row
  }
}

const deleteClassGroup = (row: ClassGroupModel) => {
  classGroupItem.value = row
  isActiveModal.value = true
  isDeleteModal.value = true
}

const hideModal = () => {
  isActiveModal.value = false
  isCrudModal.value = false
  isDeleteModal.value = false
}

onMounted(() => getClassGroupItems())

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card, { class: "q-pa-sm" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_q_btn, {
            label: "Добавить",
            color: "primary",
            icon: "mdi-plus",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (crudHandler('create')))
          }),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_q_input, {
              filled: "",
              dense: "",
              style: {"width":"100%"},
              modelValue: classGroupSearch.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((classGroupSearch).value = $event)),
              label: "Поиск по названию",
              onKeyup: _withKeys(getClassGroupItems, ["enter"])
            }, {
              prepend: _withCtx(() => [
                _createVNode(_component_q_icon, { name: "mdi-magnify" })
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_q_btn, {
              color: "primary",
              icon: "mdi-magnify",
              dense: "",
              class: "q-ml-sm q-pl-sm q-pr-sm",
              onClick: getClassGroupItems
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_q_table, {
            rows: classGroupItems.value,
            columns: columns.value,
            "row-key": "tagId",
            "wrap-cells": "",
            class: "list-tags-table q-mt-sm shadow-4",
            "rows-per-page-options": [10, 25, 50, 100],
            onRequest: onTableRequest,
            pagination: classGroupPagination.value,
            "onUpdate:pagination": _cache[2] || (_cache[2] = ($event: any) => ((classGroupPagination).value = $event)),
            style: {"height":"80vh"}
          }, {
            "body-cell-classGroupName": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    style: _normalizeStyle([{"width":"max-content","border-radius":"10px","padding":"4px 8px"}, {
                    background: props.row.classGroupColor,
                    color: _unref(idealTextColor)(props.row.classGroupColor)
                  }])
                  }, _toDisplayString(props.row.classGroupName), 5)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-actions": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_q_btn, {
                      flat: "",
                      dense: "",
                      round: "",
                      icon: "mdi-pencil",
                      color: "primary",
                      onClick: ($event: any) => (crudHandler('edit', props.row))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_tooltip, null, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_6, " Редактировать группу классов \"" + _toDisplayString(props.row.classGroupName) + "\" ", 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_q_btn, {
                      flat: "",
                      dense: "",
                      round: "",
                      icon: "mdi-delete",
                      color: "red-5",
                      onClick: ($event: any) => (deleteClassGroup(props.row))
                    }, null, 8, ["onClick"])
                  ])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            _: 1
          }, 8, ["rows", "columns", "pagination"]),
          _createVNode(_component_q_inner_loading, {
            showing: classGroupLoader.value,
            color: "grey",
            style: {"z-index":"100"}
          }, null, 8, ["showing"])
        ]),
        _createVNode(_component_q_dialog, {
          modelValue: isActiveModal.value,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((isActiveModal).value = $event))
        }, {
          default: _withCtx(() => [
            (isCrudModal.value)
              ? (_openBlock(), _createBlock(ClassGroupCrudModal, {
                  key: 0,
                  "crud-type": crudType.value,
                  "class-group-item": classGroupItem.value,
                  onHideModal: hideModal,
                  onAfterClassGroupCrud: getClassGroupItems
                }, null, 8, ["crud-type", "class-group-item"]))
              : _createCommentVNode("", true),
            (isDeleteModal.value)
              ? (_openBlock(), _createBlock(DeleteClassGroupModal, {
                  key: 1,
                  id: classGroupItem.value.classGroupId,
                  name: classGroupItem.value.classGroupName,
                  onHideModal: hideModal,
                  onAfterDelete: getClassGroupItems
                }, null, 8, ["id", "name"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    })
  ]))
}
}

})