import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "row relative-position",
  style: {"margin-left":"0"}
}

import store from '@/store'
import {computed, ref} from 'vue'
// import Mixins from '@/mixins/main'
import * as XLSX from 'xlsx'
import {AccountsApi} from "@/components/modules/accounts/services/api/AccountsApi";


export default /*@__PURE__*/_defineComponent({
  __name: 'ExportButtonUsers',
  props: ['filter', 'disableProp', 'queryStatus'],
  setup(__props) {

const props = __props

// const getAccountSearchString = computed(
//   () => store.getters.getAccountSearchString
// )
const getExportAccountItems = computed(() => {
  const rawAccountsItems = store.getters.getExportAccountItems

  let rows: any = []
  if (rawAccountsItems !== undefined) {
    for (let accountItem of rawAccountsItems) {
      let sex =
        (accountItem.getSex() == 'Мужской' && 'М') ||
        (accountItem.getSex() == 'Женский' && 'Ж') ||
        'Не указан'

      rows.push({
        SSO_ID: accountItem.getSsoId() || 'Не заполнено',
        'Количество проектов': accountItem.getCntProjects() ?? 'Не заполнено',
        'Группа полезности': accountItem.getUsefulness() || 'Не заполнено',
        'Группа лояльности': accountItem.getLoyalty() || 'Не заполнено',
        'Группа заинтересованности':
          accountItem.getInterest() || 'Не заполнено',
        'Количество активностей':
          accountItem.getCntActivity() || 'Не заполнено',
        'Дата первой активности': accountItem.getFirstActivityDate().split(' ')[0].split('-').reverse().join('.') || 'Не заполнено',
        'Дата последней активности': accountItem.getLastActivityDate().split(' ')[0].split('-').reverse().join('.') || 'Не заполнено',
        'Заработано городских баллов': accountItem.getPointsEarnedCity() || 0,
        'Потрачено городских баллов': accountItem.getPointsSpentCity() || 0,
        'Текущий остаток городских баллов': accountItem.getPointsCity() || 0,
        'Заработано призовых баллов': accountItem.getPointsEarnedPrize() || 0,
        'Потрачено призовых баллов': accountItem.getPointsSpentPrize() || 0,
        'Текущий остаток призовых баллов': accountItem.getPointsPrize() || 0,
        Пол: sex,
        'Дата рождения':
          accountItem.getBirthday().split('-').reverse().join('.') ||
          'Не заполнено',
        'Семейное положение': accountItem.getFamilyStatus() || 'Не заполнено',
        'Количество детей': accountItem.getNumChild() === -1 ? 'Не указано' : accountItem.getNumChild(),
        'Округ регистрации': accountItem.getDistrict() || 'Не заполнено',
        'Район регистрации': accountItem.getRegion() || 'Не заполнено',
        'Адрес регистрации': accountItem.getAddress() || 'Не заполнено',
        'Род деятельности': accountItem.getSocial() || 'Не заполнено',
        'Тип учетной записи': accountItem.getTypeDescr() || 'Не заполнено',
      })
    }
  }
  return rows
})

const isAccountsBusy = computed(() => store.getters.isExportAccountsBusy)

const loadData = (isCsv: boolean) => {
  const filter = props.filter ? props.filter : []

  // if (getAccountSearchString.value) {
  //   filter.push({
  //     field_name: 'a.sso_id',
  //     operand: '=',
  //     value: getAccountSearchString.value,
  //   })
  // }

  console.log(
    '🚀 ~ file: ExportButtonUsers.vue:52 ~ loadData ~ filterAfter:',
    filter
  )

  return store.dispatch('getAccountsItemsExport', {
    filter: filter,
    sort: {
      name: 'a.account_guid',
      exp: 'desc',
    },
    pagination: {
      page: 1,
      limit: isCsv ? 150000 : 150000,
      pages: 0,
      cnt: 0,
    },
  })
}

const exportData = async (type: string) => {
  let filename = ''
  if (type == 'xlsx') {
    await loadData(false)

    filename = 'Пользователи.xlsx'
  } else {
    await loadData(true)

    filename = 'Пользователи.csv'
  }
  let ws = XLSX.utils.json_to_sheet(getExportAccountItems.value)
  let wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Пользователи')
  XLSX.writeFile(wb, filename)
}

const accountsApi = new AccountsApi()

const saveFile = (reportName: string, bytes: any) => {
  // const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${b64}`
  // const downloadLink = document.createElement('a');
  // document.body.appendChild(downloadLink);
  //
  // downloadLink.href = linkSource;
  // downloadLink.target = '_self';
  // downloadLink.download = 'output.xlsx';
  // downloadLink.click();
  const blob = new Blob([bytes], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
  const link = document.createElement('a')

  link.href = window.URL.createObjectURL(blob)
  link.download = reportName
  link.click()
}

const expLoader = ref(false)

const exportTable = () => {
  expLoader.value = true

  accountsApi
      .getExportAccountsTable(
          props.filter ? props.filter : [],
          {},
          {},
          {
            personal: props.queryStatus
          }
      )
      .then(res => {
        console.log('export response', res)

        saveFile('output.xlsx', res.getFile())
        expLoader.value = false
      })
}

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_btn, {
      label: "Экспорт excel",
      color: "secondary",
      stretch: "",
      onClick: exportTable
    }),
    _createVNode(_component_q_inner_loading, {
      showing: expLoader.value,
      color: "grey"
    }, null, 8, ["showing"])
  ]))
}
}

})