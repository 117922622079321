// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "message_src.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Pagination } from "./proto/nigma-common-proto/filter";
import { Sort } from "./proto/nigma-common-proto/filter";
import { Filter } from "./proto/nigma-common-proto/filter";
/**
 * Модель данных групп классов
 *
 * @generated from protobuf message proto.nigmamsg.MessageSrcClassGroupModel
 */
export interface MessageSrcClassGroupModel {
    /**
     * @generated from protobuf field: int64 class_group_id = 1;
     */
    classGroupId: number;
    /**
     * @generated from protobuf field: string class_group_name = 2;
     */
    classGroupName: string;
    /**
     * @generated from protobuf field: string class_group_alias = 3;
     */
    classGroupAlias: string;
    /**
     * @generated from protobuf field: string class_group_color = 4;
     */
    classGroupColor: string;
    /**
     * @generated from protobuf field: double class_group_ratio = 5;
     */
    classGroupRatio: number;
}
/**
 * Модель данных для связи с messages_grp
 *
 * @generated from protobuf message proto.nigmamsg.MessageSrcLinkedModel
 */
export interface MessageSrcLinkedModel {
    /**
     * @generated from protobuf field: int64 message_grp_id = 1;
     */
    messageGrpId: number;
    /**
     * @generated from protobuf field: int64 message_grp_parent_id = 2;
     */
    messageGrpParentId: number;
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.MessageSrcClassGroupModel class_group = 3;
     */
    classGroup: MessageSrcClassGroupModel[];
}
/**
 * Модель данных
 * Префикс для фильтров msrc.
 *
 * @generated from protobuf message proto.nigmamsg.MessageSrcModel
 */
export interface MessageSrcModel {
    /**
     * @generated from protobuf field: int64 source_id = 1;
     */
    sourceId: number;
    /**
     * @generated from protobuf field: int64 project_id = 2;
     */
    projectId: number; // Префикс для фильтров mt.
    /**
     * @generated from protobuf field: int64 message_type_id = 3;
     */
    messageTypeId: number;
    /**
     * @generated from protobuf field: string account_guid = 4;
     */
    accountGuid: string;
    /**
     * @generated from protobuf field: string message_text = 5;
     */
    messageText: string;
    /**
     * @generated from protobuf field: string message_time = 6;
     */
    messageTime: string;
    /**
     * @generated from protobuf field: string message_descr = 7;
     */
    messageDescr: string;
    /**
     * @generated from protobuf field: string project_name = 8;
     */
    projectName: string; // Префикс для фильтров p.
    /**
     * @generated from protobuf field: string project_short_name = 9;
     */
    projectShortName: string; // Префикс для фильтров p.
    /**
     * @generated from protobuf field: string message_type_text = 10;
     */
    messageTypeText: string; // Префикс для фильтров mt.
    /**
     * @generated from protobuf field: string sso_id = 11;
     */
    ssoId: string; // Префикс для фильтров a.
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.MessageSrcLinkedModel message_grp = 12;
     */
    messageGrp: MessageSrcLinkedModel[];
}
/**
 * Входящий запрос для получения списка данных
 *
 * @generated from protobuf message proto.nigmamsg.ListMessageSrcRequest
 */
export interface ListMessageSrcRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
}
/**
 * Ответ сервера для получения списка данных
 *
 * @generated from protobuf message proto.nigmamsg.ListMessageSrcResponse
 */
export interface ListMessageSrcResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.MessageSrcModel items = 1;
     */
    items: MessageSrcModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListMessageSrcRequest params = 2;
     */
    params?: ListMessageSrcRequest;
}
/**
 * Входящий запрос для получения списка данных для модалки
 *
 * @generated from protobuf message proto.nigmamsg.ListMessageSrcSourceRequest
 */
export interface ListMessageSrcSourceRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
    /**
     * @generated from protobuf field: int64 message_grp_id = 4;
     */
    messageGrpId: number;
    /**
     * @generated from protobuf field: bool has_full = 5;
     */
    hasFull: boolean;
}
/**
 * Ответ сервера для получения списка данных для модалки
 *
 * @generated from protobuf message proto.nigmamsg.ListMessageSrcSourceResponse
 */
export interface ListMessageSrcSourceResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.MessageSrcModel items = 1;
     */
    items: MessageSrcModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListMessageSrcSourceRequest params = 2;
     */
    params?: ListMessageSrcSourceRequest;
}
/**
 * Сохранение списка sso_id для последующего использования
 *
 * @generated from protobuf message proto.nigmamsg.PersonalListMessageSrcRequest
 */
export interface PersonalListMessageSrcRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: int64 user_id = 5;
     */
    userId: number;
}
/**
 * @generated from protobuf message proto.nigmamsg.PersonalListMessageSrcResponse
 */
export interface PersonalListMessageSrcResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
}
/**
 * Сохранение списка sso_id из модалки для последующего использования
 *
 * @generated from protobuf message proto.nigmamsg.PersonalListMessageSrcSourceRequest
 */
export interface PersonalListMessageSrcSourceRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: int64 message_grp_id = 3;
     */
    messageGrpId: number;
    /**
     * @generated from protobuf field: bool has_full = 4;
     */
    hasFull: boolean;
    /**
     * @generated from protobuf field: int64 user_id = 5;
     */
    userId: number;
}
/**
 * @generated from protobuf message proto.nigmamsg.PersonalListMessageSrcSourceResponse
 */
export interface PersonalListMessageSrcSourceResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class MessageSrcClassGroupModel$Type extends MessageType<MessageSrcClassGroupModel> {
    constructor() {
        super("proto.nigmamsg.MessageSrcClassGroupModel", [
            { no: 1, name: "class_group_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "class_group_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "class_group_alias", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "class_group_color", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "class_group_ratio", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<MessageSrcClassGroupModel>): MessageSrcClassGroupModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classGroupId = 0;
        message.classGroupName = "";
        message.classGroupAlias = "";
        message.classGroupColor = "";
        message.classGroupRatio = 0;
        if (value !== undefined)
            reflectionMergePartial<MessageSrcClassGroupModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageSrcClassGroupModel): MessageSrcClassGroupModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_group_id */ 1:
                    message.classGroupId = reader.int64().toNumber();
                    break;
                case /* string class_group_name */ 2:
                    message.classGroupName = reader.string();
                    break;
                case /* string class_group_alias */ 3:
                    message.classGroupAlias = reader.string();
                    break;
                case /* string class_group_color */ 4:
                    message.classGroupColor = reader.string();
                    break;
                case /* double class_group_ratio */ 5:
                    message.classGroupRatio = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageSrcClassGroupModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_group_id = 1; */
        if (message.classGroupId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classGroupId);
        /* string class_group_name = 2; */
        if (message.classGroupName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.classGroupName);
        /* string class_group_alias = 3; */
        if (message.classGroupAlias !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.classGroupAlias);
        /* string class_group_color = 4; */
        if (message.classGroupColor !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.classGroupColor);
        /* double class_group_ratio = 5; */
        if (message.classGroupRatio !== 0)
            writer.tag(5, WireType.Bit64).double(message.classGroupRatio);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.MessageSrcClassGroupModel
 */
export const MessageSrcClassGroupModel = new MessageSrcClassGroupModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageSrcLinkedModel$Type extends MessageType<MessageSrcLinkedModel> {
    constructor() {
        super("proto.nigmamsg.MessageSrcLinkedModel", [
            { no: 1, name: "message_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "message_grp_parent_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "class_group", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MessageSrcClassGroupModel }
        ]);
    }
    create(value?: PartialMessage<MessageSrcLinkedModel>): MessageSrcLinkedModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.messageGrpId = 0;
        message.messageGrpParentId = 0;
        message.classGroup = [];
        if (value !== undefined)
            reflectionMergePartial<MessageSrcLinkedModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageSrcLinkedModel): MessageSrcLinkedModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 message_grp_id */ 1:
                    message.messageGrpId = reader.int64().toNumber();
                    break;
                case /* int64 message_grp_parent_id */ 2:
                    message.messageGrpParentId = reader.int64().toNumber();
                    break;
                case /* repeated proto.nigmamsg.MessageSrcClassGroupModel class_group */ 3:
                    message.classGroup.push(MessageSrcClassGroupModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageSrcLinkedModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 message_grp_id = 1; */
        if (message.messageGrpId !== 0)
            writer.tag(1, WireType.Varint).int64(message.messageGrpId);
        /* int64 message_grp_parent_id = 2; */
        if (message.messageGrpParentId !== 0)
            writer.tag(2, WireType.Varint).int64(message.messageGrpParentId);
        /* repeated proto.nigmamsg.MessageSrcClassGroupModel class_group = 3; */
        for (let i = 0; i < message.classGroup.length; i++)
            MessageSrcClassGroupModel.internalBinaryWrite(message.classGroup[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.MessageSrcLinkedModel
 */
export const MessageSrcLinkedModel = new MessageSrcLinkedModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MessageSrcModel$Type extends MessageType<MessageSrcModel> {
    constructor() {
        super("proto.nigmamsg.MessageSrcModel", [
            { no: 1, name: "source_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "project_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "message_type_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "account_guid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "message_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "message_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "message_descr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "project_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "project_short_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "message_type_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "sso_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "message_grp", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MessageSrcLinkedModel }
        ]);
    }
    create(value?: PartialMessage<MessageSrcModel>): MessageSrcModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sourceId = 0;
        message.projectId = 0;
        message.messageTypeId = 0;
        message.accountGuid = "";
        message.messageText = "";
        message.messageTime = "";
        message.messageDescr = "";
        message.projectName = "";
        message.projectShortName = "";
        message.messageTypeText = "";
        message.ssoId = "";
        message.messageGrp = [];
        if (value !== undefined)
            reflectionMergePartial<MessageSrcModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageSrcModel): MessageSrcModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 source_id */ 1:
                    message.sourceId = reader.int64().toNumber();
                    break;
                case /* int64 project_id */ 2:
                    message.projectId = reader.int64().toNumber();
                    break;
                case /* int64 message_type_id */ 3:
                    message.messageTypeId = reader.int64().toNumber();
                    break;
                case /* string account_guid */ 4:
                    message.accountGuid = reader.string();
                    break;
                case /* string message_text */ 5:
                    message.messageText = reader.string();
                    break;
                case /* string message_time */ 6:
                    message.messageTime = reader.string();
                    break;
                case /* string message_descr */ 7:
                    message.messageDescr = reader.string();
                    break;
                case /* string project_name */ 8:
                    message.projectName = reader.string();
                    break;
                case /* string project_short_name */ 9:
                    message.projectShortName = reader.string();
                    break;
                case /* string message_type_text */ 10:
                    message.messageTypeText = reader.string();
                    break;
                case /* string sso_id */ 11:
                    message.ssoId = reader.string();
                    break;
                case /* repeated proto.nigmamsg.MessageSrcLinkedModel message_grp */ 12:
                    message.messageGrp.push(MessageSrcLinkedModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageSrcModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 source_id = 1; */
        if (message.sourceId !== 0)
            writer.tag(1, WireType.Varint).int64(message.sourceId);
        /* int64 project_id = 2; */
        if (message.projectId !== 0)
            writer.tag(2, WireType.Varint).int64(message.projectId);
        /* int64 message_type_id = 3; */
        if (message.messageTypeId !== 0)
            writer.tag(3, WireType.Varint).int64(message.messageTypeId);
        /* string account_guid = 4; */
        if (message.accountGuid !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.accountGuid);
        /* string message_text = 5; */
        if (message.messageText !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.messageText);
        /* string message_time = 6; */
        if (message.messageTime !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.messageTime);
        /* string message_descr = 7; */
        if (message.messageDescr !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.messageDescr);
        /* string project_name = 8; */
        if (message.projectName !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.projectName);
        /* string project_short_name = 9; */
        if (message.projectShortName !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.projectShortName);
        /* string message_type_text = 10; */
        if (message.messageTypeText !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.messageTypeText);
        /* string sso_id = 11; */
        if (message.ssoId !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.ssoId);
        /* repeated proto.nigmamsg.MessageSrcLinkedModel message_grp = 12; */
        for (let i = 0; i < message.messageGrp.length; i++)
            MessageSrcLinkedModel.internalBinaryWrite(message.messageGrp[i], writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.MessageSrcModel
 */
export const MessageSrcModel = new MessageSrcModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMessageSrcRequest$Type extends MessageType<ListMessageSrcRequest> {
    constructor() {
        super("proto.nigmamsg.ListMessageSrcRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination }
        ]);
    }
    create(value?: PartialMessage<ListMessageSrcRequest>): ListMessageSrcRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ListMessageSrcRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMessageSrcRequest): ListMessageSrcRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMessageSrcRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListMessageSrcRequest
 */
export const ListMessageSrcRequest = new ListMessageSrcRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMessageSrcResponse$Type extends MessageType<ListMessageSrcResponse> {
    constructor() {
        super("proto.nigmamsg.ListMessageSrcResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MessageSrcModel },
            { no: 2, name: "params", kind: "message", T: () => ListMessageSrcRequest }
        ]);
    }
    create(value?: PartialMessage<ListMessageSrcResponse>): ListMessageSrcResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListMessageSrcResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMessageSrcResponse): ListMessageSrcResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.MessageSrcModel items */ 1:
                    message.items.push(MessageSrcModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListMessageSrcRequest params */ 2:
                    message.params = ListMessageSrcRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMessageSrcResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.MessageSrcModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            MessageSrcModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListMessageSrcRequest params = 2; */
        if (message.params)
            ListMessageSrcRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListMessageSrcResponse
 */
export const ListMessageSrcResponse = new ListMessageSrcResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMessageSrcSourceRequest$Type extends MessageType<ListMessageSrcSourceRequest> {
    constructor() {
        super("proto.nigmamsg.ListMessageSrcSourceRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination },
            { no: 4, name: "message_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "has_full", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ListMessageSrcSourceRequest>): ListMessageSrcSourceRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.messageGrpId = 0;
        message.hasFull = false;
        if (value !== undefined)
            reflectionMergePartial<ListMessageSrcSourceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMessageSrcSourceRequest): ListMessageSrcSourceRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                case /* int64 message_grp_id */ 4:
                    message.messageGrpId = reader.int64().toNumber();
                    break;
                case /* bool has_full */ 5:
                    message.hasFull = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMessageSrcSourceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int64 message_grp_id = 4; */
        if (message.messageGrpId !== 0)
            writer.tag(4, WireType.Varint).int64(message.messageGrpId);
        /* bool has_full = 5; */
        if (message.hasFull !== false)
            writer.tag(5, WireType.Varint).bool(message.hasFull);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListMessageSrcSourceRequest
 */
export const ListMessageSrcSourceRequest = new ListMessageSrcSourceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMessageSrcSourceResponse$Type extends MessageType<ListMessageSrcSourceResponse> {
    constructor() {
        super("proto.nigmamsg.ListMessageSrcSourceResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MessageSrcModel },
            { no: 2, name: "params", kind: "message", T: () => ListMessageSrcSourceRequest }
        ]);
    }
    create(value?: PartialMessage<ListMessageSrcSourceResponse>): ListMessageSrcSourceResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListMessageSrcSourceResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMessageSrcSourceResponse): ListMessageSrcSourceResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.MessageSrcModel items */ 1:
                    message.items.push(MessageSrcModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListMessageSrcSourceRequest params */ 2:
                    message.params = ListMessageSrcSourceRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMessageSrcSourceResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.MessageSrcModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            MessageSrcModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListMessageSrcSourceRequest params = 2; */
        if (message.params)
            ListMessageSrcSourceRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListMessageSrcSourceResponse
 */
export const ListMessageSrcSourceResponse = new ListMessageSrcSourceResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PersonalListMessageSrcRequest$Type extends MessageType<PersonalListMessageSrcRequest> {
    constructor() {
        super("proto.nigmamsg.PersonalListMessageSrcRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 5, name: "user_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<PersonalListMessageSrcRequest>): PersonalListMessageSrcRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userId = 0;
        if (value !== undefined)
            reflectionMergePartial<PersonalListMessageSrcRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PersonalListMessageSrcRequest): PersonalListMessageSrcRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* int64 user_id */ 5:
                    message.userId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PersonalListMessageSrcRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int64 user_id = 5; */
        if (message.userId !== 0)
            writer.tag(5, WireType.Varint).int64(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.PersonalListMessageSrcRequest
 */
export const PersonalListMessageSrcRequest = new PersonalListMessageSrcRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PersonalListMessageSrcResponse$Type extends MessageType<PersonalListMessageSrcResponse> {
    constructor() {
        super("proto.nigmamsg.PersonalListMessageSrcResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<PersonalListMessageSrcResponse>): PersonalListMessageSrcResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.success = false;
        if (value !== undefined)
            reflectionMergePartial<PersonalListMessageSrcResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PersonalListMessageSrcResponse): PersonalListMessageSrcResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PersonalListMessageSrcResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.PersonalListMessageSrcResponse
 */
export const PersonalListMessageSrcResponse = new PersonalListMessageSrcResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PersonalListMessageSrcSourceRequest$Type extends MessageType<PersonalListMessageSrcSourceRequest> {
    constructor() {
        super("proto.nigmamsg.PersonalListMessageSrcSourceRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "message_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "has_full", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "user_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<PersonalListMessageSrcSourceRequest>): PersonalListMessageSrcSourceRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.messageGrpId = 0;
        message.hasFull = false;
        message.userId = 0;
        if (value !== undefined)
            reflectionMergePartial<PersonalListMessageSrcSourceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PersonalListMessageSrcSourceRequest): PersonalListMessageSrcSourceRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* int64 message_grp_id */ 3:
                    message.messageGrpId = reader.int64().toNumber();
                    break;
                case /* bool has_full */ 4:
                    message.hasFull = reader.bool();
                    break;
                case /* int64 user_id */ 5:
                    message.userId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PersonalListMessageSrcSourceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int64 message_grp_id = 3; */
        if (message.messageGrpId !== 0)
            writer.tag(3, WireType.Varint).int64(message.messageGrpId);
        /* bool has_full = 4; */
        if (message.hasFull !== false)
            writer.tag(4, WireType.Varint).bool(message.hasFull);
        /* int64 user_id = 5; */
        if (message.userId !== 0)
            writer.tag(5, WireType.Varint).int64(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.PersonalListMessageSrcSourceRequest
 */
export const PersonalListMessageSrcSourceRequest = new PersonalListMessageSrcSourceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PersonalListMessageSrcSourceResponse$Type extends MessageType<PersonalListMessageSrcSourceResponse> {
    constructor() {
        super("proto.nigmamsg.PersonalListMessageSrcSourceResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<PersonalListMessageSrcSourceResponse>): PersonalListMessageSrcSourceResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.success = false;
        if (value !== undefined)
            reflectionMergePartial<PersonalListMessageSrcSourceResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PersonalListMessageSrcSourceResponse): PersonalListMessageSrcSourceResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PersonalListMessageSrcSourceResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.PersonalListMessageSrcSourceResponse
 */
export const PersonalListMessageSrcSourceResponse = new PersonalListMessageSrcSourceResponse$Type();
/**
 * @generated ServiceType for protobuf service proto.nigmamsg.MessageSrcService
 */
export const MessageSrcService = new ServiceType("proto.nigmamsg.MessageSrcService", [
    { name: "ListMessageSrc", options: {}, I: ListMessageSrcRequest, O: ListMessageSrcResponse },
    { name: "ListMessageSrcSource", options: {}, I: ListMessageSrcSourceRequest, O: ListMessageSrcSourceResponse },
    { name: "PersonalListMessageSrc", options: {}, I: PersonalListMessageSrcRequest, O: PersonalListMessageSrcResponse },
    { name: "PersonalListMessageSrcSource", options: {}, I: PersonalListMessageSrcSourceRequest, O: PersonalListMessageSrcSourceResponse }
]);
