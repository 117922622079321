import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"margin":"10px 10px 0 10px","height":"calc(100% - 50px)","overflow":"hidden"} }
const _hoisted_2 = {
  class: "row",
  style: {"height":"100%"}
}
const _hoisted_3 = {
  class: "col-12",
  style: {"height":"100%","overflow":"hidden"}
}
const _hoisted_4 = { class: "row justify-center" }
const _hoisted_5 = {
  key: 2,
  style: {"height":"24px","font-size":"18px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_UserInfo = _resolveComponent("UserInfo")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_card, { style: {"padding":"10px 10px 0 10px","height":"100%","box-shadow":"none"} }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_q_table, {
              flat: "",
              dense: "",
              style: {"height":"100%","overflow":"auto"},
              rows: _ctx.getUserItems,
              columns: _ctx.getColumns,
              "row-key": "guid",
              "wrap-cells": "",
              loading: _ctx.isUsersBusy
            }, {
              top: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  color: "primary",
                  disable: _ctx.isUsersBusy,
                  label: "Расчитать",
                  icon: "mdi-calculator",
                  onClick: _ctx.calculateAllUsersPoints
                }, null, 8, ["disable", "onClick"]),
                _createVNode(_component_q_space),
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col-6" }, null, -1))
              ]),
              "body-cell-action": _withCtx((props) => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_btn, {
                    flat: "",
                    dense: "",
                    round: "",
                    icon: "mdi-dots-vertical"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_menu, { "auto-close": "" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_list, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_item, {
                                clickable: "",
                                onClick: ($event: any) => (_ctx.actionRow('info', props.value))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_item_section, null, {
                                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                                      _createTextVNode("Информация о пользователе")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 2
                              }, 1032, ["onClick"]),
                              _createVNode(_component_q_item, {
                                clickable: "",
                                onClick: ($event: any) => (_ctx.actionRow('calculate', props.value))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_item_section, null, {
                                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                                      _createTextVNode("Расчитать")
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ])
              ]),
              "body-cell-points": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    (props.value > 0)
                      ? (_openBlock(), _createBlock(_component_q_badge, {
                          key: 0,
                          color: "green",
                          label: props.value,
                          style: {"height":"24px","font-size":"18px"}
                        }, null, 8, ["label"]))
                      : (props.value < 0)
                        ? (_openBlock(), _createBlock(_component_q_badge, {
                            key: 1,
                            color: "red",
                            label: props.value,
                            style: {"height":"24px","font-size":"18px"}
                          }, null, 8, ["label"]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(props.value), 1))
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "no-data": _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("div", { class: "col-12 text-center" }, "Нет данных", -1)
              ])),
              _: 1
            }, 8, ["rows", "columns", "loading"])
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.isActiveModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isActiveModal) = $event)),
      persistent: ""
    }, {
      default: _withCtx(() => [
        (_ctx.isActiveModalInfo)
          ? (_openBlock(), _createBlock(_component_UserInfo, {
              key: 0,
              userGuid: _ctx.selectedUserGuid,
              onHideModal: _ctx.hideModal
            }, null, 8, ["userGuid", "onHideModal"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}