import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal__points-earned" }

import store from '@/store'
import { onMounted, onUnmounted, computed, ref, watch } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalPointsEarnedPrize',
  props: {
  guid: {
    type: String,
    default: '',
  },
  clearProjects: {
    type: Boolean,
    default: false,
  },
  cnt: {
    type: Number,
    default: 0,
  },
},
  setup(__props) {

const selectedProjects = computed(() => store.getters.getSelectedProjectItems)
const selectedEvents = computed(() => {
  return store.getters.getSelectedEvents
})
const selectedDate = computed(() => store.getters.getDateForEventList)

const props = __props

watch(
    () => props.cnt,
    (newVal) => {
      tablePagination.value.rowsNumber = newVal
    }
)

const earnedTableColumns: any = [
  {
    name: 'point_project_name',
    label: 'Проект',
    field: 'project',
    sortable: true,
    align: 'left',
  },
  {
    name: 'point_event_name',
    label: 'Действие',
    field: 'action',
    sortable: true,
    align: 'left',
  },
  {
    name: 'point_date',
    label: 'Дата',
    field: 'date',
    sortable: true,
    align: 'left',
  },
  {
    name: 'point_value',
    label: 'Заработано баллов',
    field: 'earned_points',
    sortable: true,
    align: 'left',
  },
  {
    name: 'point_type',
    label: 'Тип баллов',
    field: 'earned_type',
    align: 'left',
  },
]

onMounted(() => {
  // store.dispatch('setSelectedProjectsItems', [])
  store.commit('setEarnedPointsList', [])
  loadData(true)
})

onUnmounted(() => {
  store.commit('setEarnedPointsList', [])
})

const loadData = (isResetPagination: boolean) => {
  if (isResetPagination) {
    tablePage.value = 1
    tablePagination.value.page = 1
    tablePagination.value.rowsNumber = 0
  }

  let projectsId: any =
      selectedProjects.value && selectedProjects.value.length > 0
          ? selectedProjects.value
          : []
  let actionNames =
      selectedEvents.value && selectedEvents.value.length > 0
          ? selectedEvents.value
          : []

  if (props.clearProjects) {
    projectsId = []
  }
  console.log(
      '🚀 ~ file: ModalPointsEarned.vue:80 ~ loadData ~ props:',
      props,
      tablePagination.value
  )

  store
      .dispatch('getPointsEarnedList', {
        filter: {
          sso_id: props.guid,
          project_ids: projectsId,
          action_names: actionNames,
          date_begin: selectedDate.value?.dateFrom || '2012-01-01',
          date_end:
              selectedDate.value?.dateTo || new Date().toJSON().slice(0, 10),
          point_type: 'Призовые'
        },
        pagination: {
          page: tablePagination.value.page,
          limit: tablePagination.value.rowsPerPage,
          pages: 0,
          cnt: tablePagination.value.rowsNumber,
        },
        sort: {
          name: tablePagination.value.sortBy || 'point_date',
          exp: tablePagination.value.descending ? 'desc' : 'asc',
        },
      })
      .then((resolve) => {
        console.log('RESOLVE MODAL ITEMS', resolve)
        tablePagination.value.rowsNumber = resolve.params
        tableLoadingData.value = false
      })
}

const pointsList = computed(() => {
  const rawList = store.getters.getPointsEarnedList
  const filteredList: any = []

  for (const i in rawList) {
    filteredList.push({
      project: rawList[i].getPointProjectName(),
      action: rawList[i].getPointEventName(),
      date: rawList[i].getPointDate().split('-').reverse().join('.'),
      earned_points: rawList[i].getPointValue(),
      earned_type: rawList[i].getPointType(),
      ukey: i,
    })
  }
  return filteredList
})

const tablePage = ref(1)
const tableLoadingData = ref(false)
const tablePagination = ref({
  page: 1,
  rowsPerPage: 5,
  rowsNumber: props.cnt,
  descending: true,
  sortBy: '',
})

const isPointsBusy = computed(() => store.getters.isEarnedPointsBusy)

const onRequestResult = (prop: any) => {
  console.log(
      '🚀 ~ file: ModalPointsEarned.vue:142 ~ onRequestResult ~ prop:',
      prop,
      tablePagination.value
  )
  tableLoadingData.value = true
  tablePagination.value = prop.pagination
  store.commit('setEarnedChartPagination', prop.pagination)
  loadData(false)
}

return (_ctx: any,_cache: any) => {
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_table, {
      class: "points--table",
      columns: earnedTableColumns,
      rows: pointsList.value,
      "rows-per-page-options": [7, 10, 15, 25],
      pagination: tablePagination.value,
      "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => ((tablePagination).value = $event)),
      separator: "cell",
      onRequest: onRequestResult,
      "row-key": "ukey",
      loading: isPointsBusy.value,
      "no-data-label": "Не было начислений"
    }, {
      loading: _withCtx(() => [
        _createVNode(_component_q_inner_loading, {
          showing: true,
          style: {"z-index":"2"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_spinner, {
              color: "grey",
              size: "3em"
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["rows", "pagination", "loading"])
  ]))
}
}

})