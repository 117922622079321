import script from "./AgGeneralization.vue?vue&type=script&setup=true&lang=ts"
export * from "./AgGeneralization.vue?vue&type=script&setup=true&lang=ts"

import "./AgGeneralization.vue?vue&type=style&index=0&id=377f14f2&lang=css"
import "./AgGeneralization.vue?vue&type=style&index=1&id=377f14f2&lang=sass"

const __exports__ = script;

export default __exports__
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTree from 'quasar/src/components/tree/QTree.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';import ClosePopup from 'quasar/src/directives/close-popup/ClosePopup.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCardSection,QInput,QIcon,QTree,QSelect,QItem,QItemSection,QInnerLoading,QTooltip,QSeparator,QBtnDropdown,QList,QItemLabel,QTable,QBtn,QTr,QTh,QTd,QDialog});qInstall(script, 'directives', {ClosePopup});
