import {useGrpcClientWithLogger} from "@/components/modules/rec-system/services/UserGroups/middleware";
import {MessageSrcServiceClient} from "proto/nigma-message-proto/polls/message_src.client";
import {
    ListMessageSrcRequest,
    ListMessageSrcSourceRequest,
    PersonalListMessageSrcRequest,
    PersonalListMessageSrcSourceRequest,
} from "proto/nigma-message-proto/polls/message_src"

export const useMessageSrcApi = () => {
    const client = useGrpcClientWithLogger().create(MessageSrcServiceClient, {baseUrl: window.VUE_APP_GATEWAY_URL})

    const metadata = () => {
        return {
            Authorization: localStorage.getItem('access-key') || ''
        }
    }

    return {
        listMessageSrcRequest: (request: ListMessageSrcRequest) => {
            return client.listMessageSrc(request, {meta: metadata()}).response
        },
        listMessageSrcSourceRequest: (request: ListMessageSrcSourceRequest) => {
            return client.listMessageSrcSource(request, {meta: metadata()}).response
        },
        personalListMessageSrcRequest: (request: PersonalListMessageSrcRequest) => {
            return client.personalListMessageSrc(request, {meta: metadata()}).response
        },
        personalListMessageSrcSourceRequest: (request: PersonalListMessageSrcSourceRequest) => {
            return client.personalListMessageSrcSource(request, {meta: metadata()}).response
        }
    }
}