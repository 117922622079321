import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "q-pa-md col-12 items-start q-gutter-md",
  style: {"height":"calc(100% - 140px)"}
}
const _hoisted_2 = { class: "q-pa-sm" }
const _hoisted_3 = {
  class: "q-pa-sm",
  style: {"margin-bottom":"20px"}
}
const _hoisted_4 = { class: "self-center full-width no-outline" }
const _hoisted_5 = {
  class: "q-pa-sm row no-wrap items-center",
  style: {"margin-bottom":"20px","gap":"5px"}
}
const _hoisted_6 = {
  key: 0,
  class: "text-subtitle2"
}
const _hoisted_7 = {
  key: 1,
  class: "text-subtitle2"
}
const _hoisted_8 = {
  key: 0,
  class: "text-subtitle2"
}
const _hoisted_9 = {
  key: 1,
  class: "text-subtitle2"
}
const _hoisted_10 = {
  class: "q-pa-sm",
  style: {"margin-bottom":"20px"}
}
const _hoisted_11 = {
  class: "q-pa-sm",
  style: {"margin-bottom":"20px"}
}
const _hoisted_12 = { class: "q-pa-sm" }
const _hoisted_13 = { class: "row justify-center" }
const _hoisted_14 = { class: "row justify-center" }
const _hoisted_15 = { class: "text-subtitle2" }
const _hoisted_16 = {
  class: "col-12",
  style: {"widt":"100%","height":"calc(100% - 160px)","overflow":"hidden"}
}
const _hoisted_17 = {
  style: {"width":"calc(100% - 10px)","height":"100%","margin-right":"10px","overflow":"auto","border":"1px solid black"},
  class: "custom-tree"
}

import {computed, onMounted, ref} from 'vue'
import TagAdd from '../classTags/TagAdd.vue'
import TagEdit from '../classTags/TagEdit.vue'
import TagDelete from '../classTags/TagDelete.vue'
import {ClassModel} from "proto/nigma-message-proto/polls/class";
import type {QTableColumn, QTree} from "quasar"
import {useClassApi} from "@/components/modules/polls/services/useClassApi";
import {useClassTagApi} from "@/components/modules/polls/services/useClassTagApi";
import {TreeNode, useTreeConverter} from "@/composables/useTreeConverter";
import {ClassCrudProps} from "@/components/modules/polls/views/generalMessages/classification/MultipleClassification.vue";
import {ClassTagModel} from "proto/nigma-message-proto/polls/class_tag";
import {ClassGroupModel} from "proto/nigma-message-proto/polls/class_group";
import ClassCrudModal from "@/components/modules/polls/components/classGroup/ClassGroupCrudModal.vue";
import {useClassGroupApi} from "@/components/modules/polls/services/useClassGroupApi";
import DeleteClassGroupModal from "@/components/modules/polls/components/classGroup/DeleteClassGroupModal.vue";
import {ClassNotTagModel} from "proto/nigma-message-proto/polls/class_not_tag";
import NotTagAdd from "@/components/modules/polls/components/classNotTags/NotTagAdd.vue";
import NotTagEdit from "@/components/modules/polls/components/classNotTags/NotTagEdit.vue";
import NotTagDelete from "@/components/modules/polls/components/classNotTags/NotTagDelete.vue";
import {useClassNotTagApi} from "@/components/modules/polls/services/useClassNotTagApi";

const parentRootName = 'Нет (это корневой класс)'

export default /*@__PURE__*/_defineComponent({
  __name: 'ClassAdd',
  props: {
    clsId: {},
    currentNode: {},
    currTab: {},
    currentTags: {},
    currentNotTags: {}
  },
  emits: ['afterAction', 'hideModal'],
  setup(__props: any, { emit: __emit }) {

const { listClassRequest, createClassRequest } = useClassApi()
const { saveClassTagRequest } = useClassTagApi()
const { saveClassNotTag } = useClassNotTagApi()
const { listClassGroupRequest, itemClassGroupRequest } = useClassGroupApi()
const { treeConverter } = useTreeConverter()

const props = __props
const emit = __emit

const currentTab = ref('main')
const classItem = ref<ClassModel & { classParentName: string }>({
  classId: 0,
  classifierId: 0,
  classParentId: 0,
  classIdx: 1,
  className: '',
  classDescr: '',
  classNotes: '',
  classGroupId: 0,
  classGroupName: '',
  classGroupAlias: '',
  classGroupColor: '',
  classGroupRatio: 0,
  classParentName: parentRootName,
})
const columnsNotTags = ref<QTableColumn[]>([
  {
    name: 'notTagName',
    label: 'Наименование',
    field: 'notTagName',
    align: 'left',
  },
  {
    name: 'notTagDescr',
    label: 'Описание',
    field: 'notTagDescr',
    align: 'left',
  },
  {
    name: 'action',
    label: 'Действие',
    field: 'id',
    align: 'center',
    headerStyle: 'width: 100px',
    style: 'width: 40px',
  },
])
const columns = ref<QTableColumn[]>([
  {
    name: 'tagName',
    label: 'Наименование',
    field: 'tagName',
    align: 'left',
  },
  {
    name: 'tagDescr',
    label: 'Описание',
    field: 'tagDescr',
    align: 'left',
  },
  {
    name: 'action',
    label: 'Действие',
    field: 'id',
    align: 'center',
    headerStyle: 'width: 100px',
    style: 'width: 40px',
  },
])
const tags = ref<(ClassTagModel & { index?: number })[]>([])
const notTags = ref<(ClassNotTagModel & { index?: number })[]>([])
const isActiveModal = ref(false)
const isActiveModalTree = ref(false)
const isActiveModalAdd = ref(false)
const isActiveModalEdit = ref(false)
const isActiveModalDelete = ref(false)
const selectedTag = ref<ClassTagModel & { index: number }>({
  classId: 0,
  tagName: '',
  tagDescr: '',
  index: 0,
})
const selectedNode = ref('')
const selectedName = ref('')
// const colorPopup = ref(false)
const classParentTree = ref<InstanceType<typeof QTree>>()
const classItems = ref<TreeNode<ClassModel>[]>([])
const classGroupItem = ref<ClassGroupModel | undefined>()
const classGroupItems = ref<ClassGroupModel[]>([])
const isClassGroupModal = ref(false)
const classGroupCrudType = ref('create')
const isDeleteClassGroup = ref(false)
const selectedNotTag = ref<ClassNotTagModel & { index: number }>({
  classId: 0,
  notTagName: '',
  notTagDescr: '',
  index: 0,
})
const isNotTagAdd = ref(false)
const isNotTagEdit = ref(false)
const isNotTagDelete = ref(false)

const formValidator = computed(() => classItem.value.className === '')

const hideModal = () => {
  isActiveModal.value = false
  isActiveModalTree.value = false
  isActiveModalAdd.value = false
  isActiveModalEdit.value = false
  isActiveModalDelete.value = false
  isNotTagAdd.value = false
  isNotTagEdit.value = false
  isNotTagDelete.value = false
  selectedTag.value = {
    classId: 0,
    tagName: '',
    tagDescr: '',
    index: 0,
  }
  selectedNotTag.value = {
    classId: 0,
    notTagName: '',
    notTagDescr: '',
    index: 0,
  }
}

const changeParent = () => {
  isActiveModalTree.value = true
  isActiveModal.value = true

  listClassRequest({
    filter: {
      items: [
        {
          fieldName: 'classifier_id',
          operand: '=',
          value: props.clsId.toString()
        }
      ]
    },
    sort: {
      name: '',
      exp: '',
    },
    pagination: {
      page: 1,
      pages: 0,
      limit: 100000,
      cnt: 0,
    }
  })
      .then((res) => {
        setTreeNode(classItem.value.classParentId.toString())
        classItems.value = treeConverter(res.items, {id: "classId", parentId: "classParentId"})

        classItems.value.unshift({
          classId: 0,
          classifierId: 0,
          classParentId: 0,
          classIdx: 1,
          className: parentRootName,
          classDescr: '',
          classNotes: '',
          classGroupId: 0,
          classGroupName: '',
          classGroupAlias: '',
          classGroupColor: '',
          classGroupRatio: 0,
          children: []
        })
      })
}

const hideClassGroupModals = (isDelete?: boolean) => {
  isActiveModal.value = false
  isDeleteClassGroup.value = false
  isClassGroupModal.value = false

  if (isDelete) classGroupItem.value = undefined
}

const selectNode = () => {
  if (selectedNode.value) {
    let curNode: any =
        classParentTree.value !== undefined
            ? JSON.parse(JSON.stringify(classParentTree.value?.getNodeByKey(selectedNode.value)))
            : null

    if (curNode !== null) {
      selectedName.value = curNode.className
    } else {
      selectedName.value = ''
    }
  } else {
    selectedName.value = ''
  }
}

const setTreeNode = (setNode: string) => {
  if (setNode) {
    selectedNode.value = setNode

    let curNode: any =
        classParentTree.value !== undefined
            ? JSON.parse(JSON.stringify(classParentTree.value?.getNodeByKey(selectedNode.value)))
            : null

    if (curNode !== null) {
      selectedName.value = curNode.className

      while (curNode && curNode.classParentId !== undefined) {
        curNode = JSON.parse(
            JSON.stringify(classParentTree.value?.getNodeByKey(curNode.classParentId))
        )

        if (curNode) {
          classParentTree.value?.setExpanded(curNode.classId, true)
        }
      }
    }
  }
}

const selectParent = () => {
  if (selectedNode.value !== null) {
    let curNode: any =
        classParentTree.value !== undefined
            ? JSON.parse(JSON.stringify(classParentTree.value?.getNodeByKey(selectedNode.value)))
            : null

    if (curNode) {
      classItem.value.classParentId = curNode.classId
      classItem.value.classParentName = curNode.className

      hideModal()
    }
  }
}

const addTag = () => {
  isActiveModalAdd.value = true
  isActiveModal.value = true
}

const addNotTag = () => {
  isNotTagAdd.value = true
  isActiveModal.value = true
}

const editTag = (index: number) => {
  selectedTag.value = {
    classId: tags.value[index].classId,
    index: index,
    tagName: tags.value[index].tagName,
    tagDescr: tags.value[index].tagDescr,
  }

  isActiveModalEdit.value = true
  isActiveModal.value = true
}

const editNotTag = (index: number) => {
  selectedNotTag.value = {
    classId: notTags.value[index].classId,
    index: index,
    notTagName: notTags.value[index].notTagName,
    notTagDescr: notTags.value[index].notTagDescr,
  }

  isNotTagEdit.value = true
  isActiveModal.value = true
}

const deleteTag = (index: number) => {
  selectedTag.value = {
    classId: tags.value[index].classId,
    index: index,
    tagName: tags.value[index].tagName,
    tagDescr: tags.value[index].tagDescr,
  }

  isActiveModalDelete.value = true
  isActiveModal.value = true
}

const deleteNotTag = (index: number) => {
  selectedNotTag.value = {
    classId: notTags.value[index].classId,
    index: index,
    notTagName: notTags.value[index].notTagName,
    notTagDescr: notTags.value[index].notTagDescr,
  }

  isNotTagDelete.value = true
  isActiveModal.value = true
}

const classGroupCrudHandler = (type: string) => {
  isActiveModal.value = true
  isClassGroupModal.value = true

  if (type === 'create') {
    classGroupCrudType.value = 'create'
  } else {
    classGroupCrudType.value = 'edit'
  }
}

const afterAdd = (tagItem: ClassTagModel) => {
  if (checkTag(tagItem.tagName)) {
    tags.value.unshift({
      classId: 0,
      tagName: tagItem.tagName.trim(),
      tagDescr: tagItem.tagDescr.trim(),
    })

    hideModal()
  }
}

const afterNotTagAdd = (notTagItem: ClassNotTagModel) => {
  if (checkNotTag(notTagItem.notTagName)) {
    notTags.value.unshift({
      classId: 0,
      notTagName: notTagItem.notTagName.trim(),
      notTagDescr: notTagItem.notTagDescr.trim(),
    })

    hideModal()
  }
}

const afterEdit = (tagItem: ClassTagModel & { index: number }) => {
  tags.value[tagItem.index].tagName = tagItem.tagName.trim()
  tags.value[tagItem.index].tagDescr = tagItem.tagDescr.trim()

  hideModal()
}

const afterNotTagEdit = (notTagItem: ClassNotTagModel & { index: number }) => {
  notTags.value[notTagItem.index].notTagName = notTagItem.notTagName.trim()
  notTags.value[notTagItem.index].notTagDescr = notTagItem.notTagDescr.trim()

  hideModal()
}

const actualDelete = (index: number) => {
  tags.value.splice(index, 1)
  hideModal()
}

const actualNotTagDelete = (index: number) => {
  notTags.value.splice(index, 1)
  hideModal()
}

const checkTag = (name: string) => {
  if (name === '') {
    return false
  }

  const tagNames = tags.value.map(tagItem => tagItem.tagName)

  for (const tName of tagNames) if (tName === name) return false

  return true
}

const checkNotTag = (name: string) => {
  if (name === '') {
    return false
  }

  const notTagNames = notTags.value.map(tagItem => tagItem.notTagName)

  for (const tName of notTagNames) if (tName === name) return false

  return true
}

const saveClass = () => {
  classItem.value.classifierId = props.clsId

  if (formValidator.value) {
    currentTab.value = 'main'
  } else {
    if (classGroupItem.value) {
      classItem.value.classGroupId = classGroupItem.value?.classGroupId || 0
      classItem.value.classGroupName = classGroupItem.value?.classGroupName || ''
      classItem.value.classGroupAlias = classGroupItem.value?.classGroupAlias || ''
      classItem.value.classGroupColor = classGroupItem.value?.classGroupColor || ''
      classItem.value.classGroupRatio = classGroupItem.value?.classGroupRatio || 0
    }

    createClassRequest({
      item: classItem.value
    })
        .then(res => {
          Promise.all([
            saveClassTagRequest({
              classId: res.item.classId,
              items: tags.value
            }),
            saveClassNotTag({
              classId: res.item.classId,
              items: notTags.value
            })
          ])
              .then(() => emit('afterAction', res.item.classId))
        })
  }
}

const closeClassAdd = () => emit('hideModal')

const itemClassGroup = () => {
  if (props.currentNode?.classGroupId) {
    itemClassGroupRequest({
      filter: {
        items: [
          {
            fieldName: 'cg.class_group_id',
            operand: '=',
            value: props.currentNode.classGroupId.toString()
          }
        ]
      }
    })
        .then(res => {
          classGroupItem.value = res.item
        })
  } else {
    return false
  }
}

const listClassGroup = () => {
  listClassGroupRequest({
    filter: {
      items: []
    },
    sort: {
      name: '',
      exp: '',
    },
    pagination: {
      page: 1,
      limit: 100000,
      pages: 0,
      cnt: 0,
    }
  })
      .then(res => {
        classGroupItems.value = res.items
      })
}

const afterClassGroupCrud = (createClassGroupItem?: ClassGroupModel) => {
  listClassGroup()

  if (createClassGroupItem) classGroupItem.value = createClassGroupItem
}

const loadData = () => {
  isActiveModal.value = false
  itemClassGroup()
  listClassGroup()

  if (props.currentNode) {
    classItem.value = {
      classId: 0,
      classifierId: 0,
      classParentId: props.currentNode.classId,
      classIdx: 1,
      className: '',
      classDescr: '',
      classNotes: '',
      classGroupId: 0,
      classGroupName: '',
      classGroupAlias: '',
      classGroupColor: '',
      classGroupRatio: 0,
      classParentName: props.currentNode.className,
    }
  } else {
    classItem.value = {
      classId: 0,
      classifierId: 0,
      classParentId: 0,
      classIdx: 1,
      className: '',
      classDescr: '',
      classNotes: '',
      classGroupId: 0,
      classGroupName: '',
      classGroupAlias: '',
      classGroupColor: '',
      classGroupRatio: 0,
      classParentName: parentRootName,
    }

    classGroupItem.value = undefined
  }
}

onMounted(() => loadData())

return (_ctx: any,_cache: any) => {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_field = _resolveComponent("q-field")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_tree = _resolveComponent("q-tree")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_card, {
    style: {"width":"1024px","max-width":"80vw","height":"1024px","max-height":"80vh"},
    class: "q-pa-md"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_label, { class: "text-h5" }, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createTextVNode("Создание класса")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_separator),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_q_card, { style: {"height":"100%"} }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tabs, {
              modelValue: currentTab.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currentTab).value = $event)),
              dense: "",
              align: "justify"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_tab, {
                  name: "main",
                  label: "Основное"
                }),
                _createVNode(_component_q_tab, {
                  name: "tags",
                  label: "Теги для поиска"
                }),
                _createVNode(_component_q_tab, {
                  name: "not_tags",
                  label: "Теги для исключения"
                })
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_q_separator),
            _createVNode(_component_q_tab_panels, {
              modelValue: currentTab.value,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((currentTab).value = $event)),
              animated: "",
              style: {"height":"calc(100% - 40px)","overflow":"hidden"},
              "keep-alive": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_tab_panel, {
                  name: "main",
                  style: {"width":"100%","height":"100%","overflow":"auto"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_form, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_component_q_input, {
                            modelValue: classItem.value.className,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((classItem.value.className) = $event)),
                            label: "Наименование",
                            "bottom-slots": "",
                            filled: "",
                            error: formValidator.value
                          }, {
                            error: _withCtx(() => _cache[13] || (_cache[13] = [
                              _createElementVNode("div", null, "Заполните это поле", -1)
                            ])),
                            _: 1
                          }, 8, ["modelValue", "error"])
                        ]),
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_q_field, {
                            filled: "",
                            "model-value": classItem.value.classParentId,
                            label: "Родительский класс"
                          }, {
                            prepend: _withCtx(() => [
                              _createVNode(_component_q_btn, {
                                "background-color": "gray-12",
                                onClick: changeParent
                              }, {
                                default: _withCtx(() => _cache[14] || (_cache[14] = [
                                  _createTextVNode(" Изменить ")
                                ])),
                                _: 1
                              })
                            ]),
                            control: _withCtx(() => [
                              _createElementVNode("div", _hoisted_4, _toDisplayString(classItem.value.classParentName), 1)
                            ]),
                            _: 1
                          }, 8, ["model-value"])
                        ]),
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_q_select, {
                            options: classGroupItems.value,
                            "option-label": "classGroupName",
                            "option-value": "classGroupId",
                            filled: "",
                            label: "Группа классов",
                            modelValue: classGroupItem.value,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((classGroupItem).value = $event)),
                            class: "full-width"
                          }, null, 8, ["options", "modelValue"]),
                          _createVNode(_component_q_btn, {
                            icon: "mdi-plus",
                            dense: "",
                            round: "",
                            flat: "",
                            color: "green-5",
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (classGroupCrudHandler('create')))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_tooltip, null, {
                                default: _withCtx(() => _cache[15] || (_cache[15] = [
                                  _createElementVNode("div", { class: "text-subtitle2" }, "Добавить группу классов", -1)
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_q_btn, {
                            icon: "mdi-pencil",
                            dense: "",
                            round: "",
                            flat: "",
                            color: "primary",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (classGroupCrudHandler('edit'))),
                            disable: !classGroupItem.value
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_tooltip, null, {
                                default: _withCtx(() => [
                                  (classGroupItem.value)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, "Редактировать группу классов"))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_7, "Выберите группу классов"))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["disable"]),
                          _createVNode(_component_q_btn, {
                            icon: "mdi-delete",
                            dense: "",
                            round: "",
                            flat: "",
                            color: "red-5",
                            disable: !classGroupItem.value,
                            onClick: _cache[5] || (_cache[5] = ($event: any) => {isDeleteClassGroup.value = true; isActiveModal.value = true})
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_tooltip, null, {
                                default: _withCtx(() => [
                                  (classGroupItem.value)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, "Удалить группу классов"))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_9, "Выберите группу классов"))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["disable"])
                        ]),
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_q_input, {
                            type: "number",
                            modelValue: classItem.value.classIdx,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((classItem.value.classIdx) = $event)),
                            min: "1",
                            label: "Порядок в родительском классе",
                            filled: ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(_component_q_input, {
                            type: "textarea",
                            modelValue: classItem.value.classDescr,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((classItem.value.classDescr) = $event)),
                            label: "Описание",
                            filled: "",
                            autogrow: ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_12, [
                          _createVNode(_component_q_input, {
                            type: "textarea",
                            modelValue: classItem.value.classNotes,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((classItem.value.classNotes) = $event)),
                            label: "Примечания",
                            filled: "",
                            autogrow: ""
                          }, null, 8, ["modelValue"])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_tab_panel, {
                  name: "tags",
                  style: {"height":"100%","overflow":"auto"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_table, {
                      style: {"height":"100%"},
                      rows: tags.value,
                      columns: columns.value,
                      "row-key": "tagName",
                      "rows-per-page-options": [10, 25, 50, 100]
                    }, {
                      top: _withCtx(() => [
                        _createVNode(_component_q_btn, {
                          "background-color": "gray-12",
                          label: "Добавить",
                          icon: "mdi-plus",
                          onClick: addTag
                        })
                      ]),
                      "body-cell-action": _withCtx((props) => [
                        _createElementVNode("div", _hoisted_13, [
                          _createVNode(_component_q_btn, {
                            flat: "",
                            dense: "",
                            round: "",
                            icon: "mdi-dots-vertical"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_menu, { "auto-close": "" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_list, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_item, {
                                        clickable: "",
                                        onClick: ($event: any) => (editTag(props.rowIndex))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_item_section, null, {
                                            default: _withCtx(() => _cache[16] || (_cache[16] = [
                                              _createTextVNode("Редактировать")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"]),
                                      _createVNode(_component_q_item, {
                                        clickable: "",
                                        onClick: ($event: any) => (deleteTag(props.rowIndex))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_item_section, null, {
                                            default: _withCtx(() => _cache[17] || (_cache[17] = [
                                              _createTextVNode("Удалить")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"])
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ]),
                      "no-data": _withCtx(() => _cache[18] || (_cache[18] = [
                        _createElementVNode("div", { class: "col-12 text-center" }, "Нет данных", -1)
                      ])),
                      _: 1
                    }, 8, ["rows", "columns"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_tab_panel, {
                  name: "not_tags",
                  style: {"height":"100%","overflow":"auto"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_table, {
                      style: {"height":"100%"},
                      rows: notTags.value,
                      columns: columnsNotTags.value,
                      "row-key": "notTagName",
                      "rows-per-page-options": [10, 25, 50, 100]
                    }, {
                      top: _withCtx(() => [
                        _createVNode(_component_q_btn, {
                          "background-color": "gray-12",
                          label: "Добавить",
                          icon: "mdi-plus",
                          onClick: addNotTag
                        })
                      ]),
                      "body-cell-action": _withCtx((props) => [
                        _createElementVNode("div", _hoisted_14, [
                          _createVNode(_component_q_btn, {
                            flat: "",
                            dense: "",
                            round: "",
                            icon: "mdi-dots-vertical"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_menu, { "auto-close": "" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_list, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_item, {
                                        clickable: "",
                                        onClick: ($event: any) => (editNotTag(props.rowIndex))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_item_section, null, {
                                            default: _withCtx(() => _cache[19] || (_cache[19] = [
                                              _createTextVNode("Редактировать")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"]),
                                      _createVNode(_component_q_item, {
                                        clickable: "",
                                        onClick: ($event: any) => (deleteNotTag(props.rowIndex))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_item_section, null, {
                                            default: _withCtx(() => _cache[20] || (_cache[20] = [
                                              _createTextVNode("Удалить")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"])
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ]),
                      "no-data": _withCtx(() => _cache[21] || (_cache[21] = [
                        _createElementVNode("div", { class: "col-12 text-center" }, "Нет данных", -1)
                      ])),
                      _: 1
                    }, 8, ["rows", "columns"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_q_separator),
        _createVNode(_component_q_card_actions, { align: "right" }, {
          default: _withCtx(() => [
            _createVNode(_component_q_btn, {
              color: "primary",
              onClick: saveClass
            }, {
              default: _withCtx(() => _cache[22] || (_cache[22] = [
                _createTextVNode(" Создать ")
              ])),
              _: 1
            }),
            _createVNode(_component_q_btn, {
              color: "primary",
              onClick: closeClassAdd
            }, {
              default: _withCtx(() => _cache[23] || (_cache[23] = [
                _createTextVNode(" Отмена ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_q_dialog, {
        modelValue: isActiveModal.value,
        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((isActiveModal).value = $event)),
        persistent: ""
      }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_q_card, {
            style: {"width":"1024px","height":"1024px","max-width":"80vw","max-height":"80vh"},
            class: "q-pa-md"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_card_section, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_item_label, { class: "text-h5" }, {
                        default: _withCtx(() => _cache[24] || (_cache[24] = [
                          _createTextVNode("Выбор родительского класса")
                        ])),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_15, " " + _toDisplayString(selectedName.value) + " ", 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_q_separator),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_q_tree, {
                    ref_key: "classParentTree",
                    ref: classParentTree,
                    nodes: classItems.value,
                    "node-key": "classId",
                    "label-key": "className",
                    selected: selectedNode.value,
                    "onUpdate:selected": [
                      _cache[10] || (_cache[10] = ($event: any) => ((selectedNode).value = $event)),
                      selectNode
                    ],
                    "selected-color": "primary"
                  }, null, 8, ["nodes", "selected"])
                ])
              ]),
              _createVNode(_component_q_separator),
              _createVNode(_component_q_card_actions, { align: "right" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    disable: selectedNode.value === null,
                    color: "primary",
                    onClick: selectParent
                  }, {
                    default: _withCtx(() => _cache[25] || (_cache[25] = [
                      _createTextVNode(" Выбрать ")
                    ])),
                    _: 1
                  }, 8, ["disable"]),
                  _createVNode(_component_q_btn, {
                    color: "primary",
                    onClick: hideModal
                  }, {
                    default: _withCtx(() => _cache[26] || (_cache[26] = [
                      _createTextVNode(" Отмена ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512), [
            [_vShow, isActiveModalTree.value]
          ]),
          (isActiveModalAdd.value)
            ? (_openBlock(), _createBlock(TagAdd, {
                key: 0,
                onAfterAdd: afterAdd,
                onHideModal: hideModal
              }))
            : _createCommentVNode("", true),
          (isActiveModalEdit.value)
            ? (_openBlock(), _createBlock(TagEdit, {
                key: 1,
                "tag-item": selectedTag.value,
                onAfterEdit: afterEdit,
                onHideModal: hideModal
              }, null, 8, ["tag-item"]))
            : _createCommentVNode("", true),
          (isActiveModalDelete.value)
            ? (_openBlock(), _createBlock(TagDelete, {
                key: 2,
                "tag-item": selectedTag.value,
                onActualDelete: actualDelete,
                onHideModal: hideModal
              }, null, 8, ["tag-item"]))
            : _createCommentVNode("", true),
          (isNotTagAdd.value)
            ? (_openBlock(), _createBlock(NotTagAdd, {
                key: 3,
                onAfterAdd: afterNotTagAdd,
                onHideModal: hideModal
              }))
            : _createCommentVNode("", true),
          (isNotTagEdit.value)
            ? (_openBlock(), _createBlock(NotTagEdit, {
                key: 4,
                "not-tag-item": selectedNotTag.value,
                onAfterEdit: afterNotTagEdit,
                onHideModal: hideModal
              }, null, 8, ["not-tag-item"]))
            : _createCommentVNode("", true),
          (isNotTagDelete.value)
            ? (_openBlock(), _createBlock(NotTagDelete, {
                key: 5,
                "not-tag-item": selectedNotTag.value,
                onActualDelete: actualNotTagDelete,
                onHideModal: hideModal
              }, null, 8, ["not-tag-item"]))
            : _createCommentVNode("", true),
          (isClassGroupModal.value)
            ? (_openBlock(), _createBlock(ClassCrudModal, {
                key: 6,
                onHideModal: hideClassGroupModals,
                "class-group-item": classGroupItem.value,
                "crud-type": classGroupCrudType.value,
                onAfterClassGroupCrud: afterClassGroupCrud
              }, null, 8, ["class-group-item", "crud-type"]))
            : _createCommentVNode("", true),
          (isDeleteClassGroup.value)
            ? (_openBlock(), _createBlock(DeleteClassGroupModal, {
                key: 7,
                id: classGroupItem.value?.classGroupId || 0,
                name: classGroupItem.value?.classGroupName || '',
                onHideModal: hideClassGroupModals,
                onAfterDelete: afterClassGroupCrud
              }, null, 8, ["id", "name"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}
}

})