// import {useGrpcClientWithLogger} from "@/components/modules/rec-system/services/UserGroups/middleware";
import {AgAnswerGrpClsServiceClient} from "proto/nigma-message-proto/polls/ag_answer_grp_cls.client";
import {
    ListAgAnswerGrpClsInRequest,
    ListAgAnswerGrpClsOutRequest,
} from "proto/nigma-message-proto/polls/ag_answer_grp_cls"
import {useClientFactory} from "@/mixins/GrpcClientFactory";

export const useAgAnswerGrpClsApi = () => {
    // const client = useGrpcClientWithLogger().create(AgAnswerGrpClsServiceClient, {baseUrl: window.VUE_APP_GATEWAY_URL})
    const client = useClientFactory().create(AgAnswerGrpClsServiceClient, {baseUrl: window.VUE_APP_GATEWAY_URL})

    // const metadata = () => {
    //     return {
    //         Authorization: localStorage.getItem('access-key') || ''
    //     }
    // }

    return {
        listAgAnswerGrpClsInRequest: (request: ListAgAnswerGrpClsInRequest, abortController?: AbortController) => {
            return client.listAgAnswerGrpClsIn(request, {abort: abortController?.signal})
                .response
                .catch(console.error)
        },
        listAgAnswerGrpClsOutRequest: (request: ListAgAnswerGrpClsOutRequest, abortController?: AbortController) => {
            return client.listAgAnswerGrpClsOut(request, {abort: abortController?.signal})
                .response
                .catch(console.error)
        }
    }
}