import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { style: {"width":"100%"} }
const _hoisted_2 = { class: "inter-checkbox q-mb-md custom-inter" }
const _hoisted_3 = {
  class: "custom-flex",
  style: {"width":"100%","position":"relative"}
}
const _hoisted_4 = {
  key: 0,
  style: {"position":"relative","width":"100%"}
}
const _hoisted_5 = {
  key: 1,
  style: {"position":"relative","width":"100%"}
}
const _hoisted_6 = {
  key: 1,
  class: "relative-position",
  style: {"width":"500px","height":"300px"}
}
const _hoisted_7 = {
  key: 1,
  class: "relative-position",
  style: {"width":"500px","height":"300px"}
}

import store from '@/store'
import {computed, ref, toRaw} from 'vue'
import IntersectionModal from "@/components/modules/intersection/components/Actions/IntersectionModal.vue";
import ActivityModal from "@/components/modules/intersection/components/Actions/ActivityModal.vue";
import {useRouter} from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'IntersectionUsersChart',
  props: {
  min: {
    type: String,
    default: ''
  },
  max: {
    type: String,
    default: ''
  },
  filters: [] as any,
},
  emits: ['interCheck'],
  setup(__props, { emit: __emit }) {

const router = useRouter()

const sumActivity = ref(80)
const interCheck = ref([2, 3, 4])
const isAccountsDialogVisible = ref(false)
const accountsDialogPagination = ref({
  sortBy: '',
  descending: true,
  page: 1,
  rowsPerPage: 10,
  rowsNumber: 0,
})
const isAccountsDialogVisibleAct = ref(false)
const accountsDialogPaginationAct = ref({
  sortBy: '',
  descending: true,
  page: 1,
  rowsPerPage: 10,
  rowsNumber: 0,
})
const saveAccountsFilter = ref({})
const saveAccountsFilterAct = ref({})
const intersectionNames = ref([]) as any
const intersectionNamesAct = ref([]) as any
const initialReq = ref(false)
const initialReqAct = ref(false)
const loader = ref(false)
const loaderAct = ref(false)

const interOptions = ref([
  {
    label: 'Активный гражданин',
    value: 1,
    disable: true,
  },
  {
    label: 'Город заданий',
    value: 2,
    disable: false,
  },
  {
    label: 'Город идей',
    value: 3,
    disable: false,
  },
  {
    label: 'Электронный дом',
    value: 4,
    disable: false,
  },
  {
    label: 'Наш город',
    value: 5,
    disable: true,
  },
  {
    label: 'Миллион призов',
    value: 6,
    disable: true,
  },
  {
    label: 'Активный гражданин - детям',
    value: 17,
    disable: true,
  },
]) as any

const association = ref({
  1: 'АГ',
  2: 'ГЗ',
  3: 'ГИ',
  4: 'ЭД',
  5: 'НГ',
  6: 'МИЛП',
  17: 'АГД',
}) as any

const associationModal = ref({
  'АГ': 1,
  'ГЗ': 2,
  'ГИ': 3,
  'ЭД': 4,
  'НГ': 5,
  'МИЛП': 6,
  'АГД': 17,
}) as any

const colorAssoc = ref({
  'АГ': '#057b6c',
  'ГЗ': '#dab61b',
  'ГИ': '#3494ef',
  'ЭД': '#e17833',
  'НГ': '#e344a4',
  'МИЛП': '#744cc4',
  'АГД': '#98d1cb',
}) as any

const colors = ['#a8e6cf', '#fdffab', '#ffd3b6', '#ffaaa5', '#d4a5a5', '#ffecda',
  '#f9ffea', '#a6d0e4', '#00204a', '#005792', '#00bbf0', '#d9faff', '#a393eb',
  '#f5c7f7', 'teal', 'white', 'yellow']

const chartOptions = ref({
  chart: {
    height: 500,
  },

  series: [
    {
      type: 'venn',
      name: 'Пересечение',
      data: [],
      stickyTracking: false,
      cursor: 'pointer',
      events: {
        click: function (e: any) {

          console.log('CLICK E', e)
          initialReq.value = true
          intersectionNames.value = e.point.sets
          saveAccountsFilter.value = {
            date_begin: props.min,
            date_end: props.max,
            activity: toRaw(props.filters['Активность']),
            loyalty: toRaw(props.filters['Лояльность']),
            useful: toRaw(props.filters['Полезность']),
            interest: toRaw(props.filters['Заинтересованность']),
            project_yes: e.point.sets.map(
                (item: number) => associationModal.value[item]
            ),
          }
          store
              .dispatch('getIntersectionModalList', {
                filter: {},
                data: saveAccountsFilter.value,
                pagination: {
                  page: 1,
                  limit: accountsDialogPagination.value.rowsPerPage,
                  pages: 0,
                  cnt: 0,
                },
              })
              .then((res) => {
                accountsDialogPagination.value.rowsNumber = res
                    .getParams()
                    .getPagination()
                    .getCnt()
                console.log('CHECK RES', res)
                initialReq.value = false
              })
          isAccountsDialogVisible.value = true
        },
      }
    },
  ],

  title: {
    text: 'Пересечение пользователей',
  },
})

const activityOptions = ref({
  chart: {
    height: 500,
  },
  series: [
    {
      type: 'venn',
      data: [],
      name: 'Пересечение',
      cursor: 'pointer',
      events: {
        click: function (e: any) {

          console.log('CLICK E', e)
          initialReqAct.value = true
          intersectionNamesAct.value = e.point.sets
          saveAccountsFilterAct.value = {
            date_begin: props.min,
            date_end: props.max,
            activity: toRaw(props.filters['Активность']),
            loyalty: toRaw(props.filters['Лояльность']),
            useful: toRaw(props.filters['Полезность']),
            interest: toRaw(props.filters['Заинтересованность']),
            project_yes: e.point.sets.map(
                (item: number) => associationModal.value[item]
            ),
            project_ids: toRaw(interCheck.value),
            value: sumActivity.value / 100
          }
          store
              .dispatch('getActivityModalList', {
                filter: {},
                data: saveAccountsFilterAct.value,
                pagination: {
                  page: 1,
                  limit: accountsDialogPaginationAct.value.rowsPerPage,
                  pages: 0,
                  cnt: 0,
                },
              })
              .then((res) => {
                accountsDialogPaginationAct.value.rowsNumber = res
                    .getParams()
                    .getPagination()
                    .getCnt()
                console.log('CHECK RES', res)
                initialReqAct.value = false
              })
          isAccountsDialogVisibleAct.value = true
        },
      }
    },
  ],
  title: {
    text: 'Активное ядро',
  },
})

const props = __props

const emit = __emit

const getIntersectionList = computed(() => {
  const rawList = store.getters.getProjectIntersection
  const filteredList = []

  for (let i in rawList) {
    let projectArr = JSON.parse(rawList[i].getProjectYes()).map(
        (item: number) => association.value[item]
    )

    if (rawList[i].getAccountCount() != 0) {
      filteredList.push({
        sets: projectArr,
        value: rawList[i].getAccountCount(),
        name: projectArr,
        color: projectArr.length <= 1 ? colorAssoc.value[projectArr[0]] : colors[Number(i)]
      })
    }
  }

  console.log('FILTEREDDDD', filteredList)
  filteredList.reverse()

  return filteredList
})
const getActivityList = computed(() => {
  const rawList = store.getters.getProjectActivity
  const filteredList = []

  for (let i in rawList) {
    let projectArr = JSON.parse(rawList[i].getProjectYes()).map(
        (item: number) => association.value[item]
    )

    console.log(i)

    if (rawList[i].getAccountCount() != 0) {
      filteredList.push({
        sets: projectArr,
        value: rawList[i].getAccountCount(),
        name: projectArr,
        color: projectArr.length <= 1 ? colorAssoc.value[projectArr[0]] : colors[Number(i)]
      })
    }

  }

  filteredList.reverse()

  return filteredList
})
const getIsIntersectionListBusy = computed(() => store.getters.isProjectIntersectionBusy)
const getIsActivityListBusy = computed(() => store.getters.isProjectActivityBusy)

const applyIntersections = () => {
  console.log('PROPSA)', props.filters)

  store.dispatch('getIntersectionsProject', {
    filter: {},
    activity: toRaw(props.filters['Активность']),
    loyalty: toRaw(props.filters['Лояльность']),
    useful: toRaw(props.filters['Полезность']),
    interest: toRaw(props.filters['Заинтересованность']),
    sort: {},
    project_ids: toRaw(interCheck.value),
    date_begin: props.min || '2012-01-01',
    date_end: props.max || '2024-01-01',
  })
      .then(() => {
        if (getIntersectionList.value) {
          chartOptions.value.series[0].data = getIntersectionList.value
        }
      })

  store.dispatch('getProjectActivity', {
    filter: {},
    activity: toRaw(props.filters['Активность']),
    loyalty: toRaw(props.filters['Лояльность']),
    useful: toRaw(props.filters['Полезность']),
    interest: toRaw(props.filters['Заинтересованность']),
    sort: {},
    project_ids: toRaw(interCheck.value),
    value: sumActivity.value / 100,
    date_begin: props.min || '2012-01-01',
    date_end: props.max || '2024-01-01',
  })
      .then(() => {
        if (getActivityList.value) {
          activityOptions.value.series[0].data = getActivityList.value
        }
      })
}

const accountsMetricsTableRequest = (pagination: any) => {
  accountsDialogPagination.value = pagination
  store
      .dispatch('getIntersectionModalList', {
        filter: {},
        data: saveAccountsFilter.value,
        pagination: {
          page: pagination.page,
          limit: accountsDialogPagination.value.rowsPerPage,
          pages: 0,
          cnt: pagination.rowsNumber,
        },
      })
      .then((res) => {
        accountsDialogPagination.value.rowsNumber = res
            .getParams()
            .getPagination()
            .getCnt()
      })
  console.log('TABLE REQUEST ', pagination)
}

const accountsMetricsTableRequestAct = (pagination: any) => {
  accountsDialogPaginationAct.value = pagination
  store
      .dispatch('getActivityModalList', {
        filter: {},
        data: saveAccountsFilterAct.value,
        pagination: {
          page: pagination.page,
          limit: accountsDialogPaginationAct.value.rowsPerPage,
          pages: 0,
          cnt: pagination.rowsNumber,
        },
      })
      .then((res) => {
        accountsDialogPaginationAct.value.rowsNumber = res
            .getParams()
            .getPagination()
            .getCnt()
      })
  console.log('TABLE REQUEST ', pagination)
}

const personalIntersectionModal = () => {
  loader.value = true

  store
      .dispatch('getIntersectionPersonalModalList', {
        filter: {},
        data: saveAccountsFilter.value,
      })
      .then(() => {
        router.push({
          name: 'UserProjects',
          query: {
            data: 'personal'
          }
        })

        loader.value = false
      })
}

const personalIntersectionActModal = () => {
  loaderAct.value = true

  store
      .dispatch('getPersonalActivityModalList', {
        filter: {},
        data: saveAccountsFilterAct.value,
      })
      .then(() => {
        router.push({
          name: 'UserProjects',
          query: {
            data: 'personal'
          }
        })

        loaderAct.value = false
      })
}

const onTicked = () => {
  if (interCheck.value.length == 3) {
    for (let i in interOptions.value) {
      interCheck.value.includes(interOptions.value[i].value)
          ? (interOptions.value[i].disable = false)
          : (interOptions.value[i].disable = true)
    }
  } else {
    for (let i in interOptions.value) {
      interOptions.value[i].disable = false
    }
  }

  emit('interCheck', interCheck.value)
}

return (_ctx: any,_cache: any) => {
  const _component_q_option_group = _resolveComponent("q-option-group")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_highcharts = _resolveComponent("highcharts")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_option_group, {
        modelValue: interCheck.value,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((interCheck).value = $event)),
          onTicked
        ],
        options: interOptions.value,
        type: "checkbox",
        inline: "",
        color: "teal",
        disable: getIsActivityListBusy.value || getIsIntersectionListBusy.value
      }, null, 8, ["modelValue", "options", "disable"]),
      _createVNode(_component_q_input, {
        class: "input-md",
        modelValue: sumActivity.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((sumActivity).value = $event)),
        outlined: "",
        rules: [(val) => val <= 100 && val > 0],
        "no-error-icon": "",
        "hide-bottom-space": "",
        type: "number",
        style: {"margin-left":"auto","margin-top":"-16px"},
        suffix: "%"
      }, null, 8, ["modelValue", "rules"]),
      _createVNode(_component_q_btn, {
        label: "Рассчитать",
        color: "primary",
        style: {"margin-left":"16px","height":"56px"},
        onClick: applyIntersections,
        disable: getIsActivityListBusy.value || getIsIntersectionListBusy.value
      }, null, 8, ["disable"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (getIntersectionList.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_highcharts, {
              class: "hc",
              options: chartOptions.value,
              ref: "chart"
            }, null, 8, ["options"]),
            _createVNode(_component_q_inner_loading, {
              showing: getIsIntersectionListBusy.value,
              color: "grey"
            }, null, 8, ["showing"])
          ]))
        : _createCommentVNode("", true),
      (getActivityList.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_highcharts, {
              class: "hc",
              options: activityOptions.value,
              ref: "chart2"
            }, null, 8, ["options"]),
            _createVNode(_component_q_inner_loading, {
              showing: getIsActivityListBusy.value,
              color: "grey"
            }, null, 8, ["showing"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_q_dialog, {
      modelValue: isAccountsDialogVisible.value,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((isAccountsDialogVisible).value = $event)),
      "no-esc-dismiss": ""
    }, {
      default: _withCtx(() => [
        (!initialReq.value)
          ? (_openBlock(), _createBlock(IntersectionModal, {
              key: 0,
              pagination: accountsDialogPagination.value,
              filter: saveAccountsFilter.value,
              intersectionNames: intersectionNames.value,
              onRequest: accountsMetricsTableRequest,
              onPersonalList: personalIntersectionModal
            }, null, 8, ["pagination", "filter", "intersectionNames"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_q_inner_loading, { showing: initialReq.value }, null, 8, ["showing"])
            ])),
        _createElementVNode("div", null, [
          _createVNode(_component_q_inner_loading, {
            showing: loader.value,
            color: "grey",
            label: `Подождите, страница скоро откроется`,
            style: {"z-index":"100"}
          }, null, 8, ["showing"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_q_dialog, {
      modelValue: isAccountsDialogVisibleAct.value,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((isAccountsDialogVisibleAct).value = $event)),
      "no-esc-dismiss": ""
    }, {
      default: _withCtx(() => [
        (!initialReqAct.value)
          ? (_openBlock(), _createBlock(ActivityModal, {
              key: 0,
              pagination: accountsDialogPaginationAct.value,
              filter: saveAccountsFilterAct.value,
              intersectionNames: intersectionNamesAct.value,
              onRequest: accountsMetricsTableRequestAct,
              onPersonalListAct: personalIntersectionActModal
            }, null, 8, ["pagination", "filter", "intersectionNames"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_q_inner_loading, { showing: initialReqAct.value }, null, 8, ["showing"])
            ])),
        _createElementVNode("div", null, [
          _createVNode(_component_q_inner_loading, {
            showing: loaderAct.value,
            color: "grey",
            label: `Подождите, страница скоро откроется`,
            style: {"z-index":"100"}
          }, null, 8, ["showing"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})