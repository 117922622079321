import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "row",
  style: {"margin-left":"0"}
}

import store from '@/store'
import { ref, computed } from 'vue'
import * as XLSX from 'xlsx'


export default /*@__PURE__*/_defineComponent({
  __name: 'ExportDBScanListMetric',
  props: ['filter', 'names'],
  setup(__props) {

const props = __props
const rows: any = ref([])

const names = computed(() => props.names)

const exportData = async (type: string) => {
  store
      .dispatch('getDbscanExport', {
        filter: props.filter,
        pagination: {
          page: 1,
          limit: 100000,
          pages: 0,
          cnt: 0,
        },
      })
      .then((res) => {
        const items = res.getItemsList()
        console.log('EXPORT NAMES', names.value)
        rows.value.push({
          'Пересечение Пользователей': '',
          [props.names.from]: '',
          [props.names.to]: '',
        })
        for (const i of items) {
          rows.value.push({
            sso_id: i.getSsoId(),
          })
        }

        let filename = ''
        if (type == 'xlsx') {
          filename = 'Пользователи.xlsx'
        } else {
          filename = 'Пользователи.csv'
        }
        let ws = XLSX.utils.json_to_sheet(rows.value)
        let wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Пользователи')
        XLSX.writeFile(wb, filename)
      })
}

return (_ctx: any,_cache: any) => {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_btn_dropdown = _resolveComponent("q-btn-dropdown")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_btn_dropdown, {
      label: "Экспорт",
      color: "secondary",
      stretch: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_list, null, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createBlock(_component_q_item, {
              clickable: "",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (exportData('xlsx')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_label, null, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("Excel")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })), [
              [_directive_close_popup]
            ]),
            _withDirectives((_openBlock(), _createBlock(_component_q_item, {
              clickable: "",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (exportData('csv')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_label, null, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("CSV")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })), [
              [_directive_close_popup]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})