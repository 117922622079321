import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row relative-position" }

import store from '@/store'
import {computed, toRaw} from 'vue'
import * as XLSX from 'xlsx'


export default /*@__PURE__*/_defineComponent({
  __name: 'ExportButtonIntersection',
  props: {
    allCheck: Boolean,
    selectedProjects: Array,
    dateMin: String,
    dateMax: String,
},
  setup(__props) {

const props = __props

const getExportTable = computed(() => {
  const rawAccountsItems = store.getters.getExportTable

  let rows: any = []
  if (rawAccountsItems !== undefined) {
    for (let accountItem of rawAccountsItems) {
      let arr = accountItem.getProjectYesList()

      rows.push({
        'SSO ID': accountItem.getSsoId() ?? 'Не задано',
        'Активный гражданин': arr.includes(1) ? 'Зарегистрирован' : '',
        'Город заданий': arr.includes(2) ? 'Зарегистрирован' : '',
        'Город идей': arr.includes(3) ? 'Зарегистрирован' : '',
        'Электронный дом': arr.includes(4) ? 'Зарегистрирован' : '',
        'Наш город': arr.includes(5) ? 'Зарегистрирован' : '',
        'Миллион призов': arr.includes(6) ? 'Зарегистрирован' : '',
      })
    }
  }
  return rows
})

const metricValues = computed(() => {
  const metricList = store.getters.getMetricChecked

  return toRaw(metricList)
})

const isExport = computed(() => store.getters.isExportTable)

const loadData = (isCsv: boolean) => {
  return store.dispatch('getExportTable', {
    activity: metricValues.value['Активность'],
    loyalty: metricValues.value['Лояльность'],
    useful: metricValues.value['Полезность'],
    interest: metricValues.value['Заинтересованность'],
    pagination: {
      page: 1,
      limit: isCsv ? 250000 : 150000,
      pages: 0,
      cnt: 0,
    },
    all_project: props.allCheck,
    project_ids: props.selectedProjects || [1, 2, 3, 4, 5, 6],
    date_begin: props.dateMin || '2012-01-01',
    date_end: props.dateMax || '2024-01-01',
  })
}

const exportData = async (type: string) => {
  let filename = ''
  if (type == 'xlsx') {
    await loadData(false)

    filename = 'Таблица регистраций.xlsx'
  } else {
    await loadData(true)

    filename = 'Таблица регистраций.csv'
  }
  let ws = XLSX.utils.json_to_sheet(getExportTable.value)
  let wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Таблица регистраций')
  XLSX.writeFile(wb, filename)
}

return (_ctx: any,_cache: any) => {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_btn_dropdown = _resolveComponent("q-btn-dropdown")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_btn_dropdown, {
      label: "Экспорт",
      color: "secondary",
      stretch: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_list, null, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createBlock(_component_q_item, {
              clickable: "",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (exportData('xlsx')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_label, null, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("Excel")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })), [
              [_directive_close_popup]
            ]),
            _withDirectives((_openBlock(), _createBlock(_component_q_item, {
              clickable: "",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (exportData('csv')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_label, null, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("CSV")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })), [
              [_directive_close_popup]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_q_inner_loading, {
      showing: isExport.value,
      color: "grey"
    }, null, 8, ["showing"])
  ]))
}
}

})