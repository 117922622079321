import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "inter-registration" }
const _hoisted_2 = {
  style: {"gap":"16px","display":"none"},
  class: "row q-mb-md"
}
const _hoisted_3 = { class: "custom-row" }
const _hoisted_4 = { class: "row items-center justify-end" }
const _hoisted_5 = { class: "row items-center justify-end" }
const _hoisted_6 = {
  style: {"position":"relative","width":"100%","white-space":"nowrap","height":"56px"},
  class: "inter-registration__users q-ml-auto"
}
const _hoisted_7 = { class: "custom-row q-mt-lg" }
const _hoisted_8 = { class: "row q-mt-md" }
const _hoisted_9 = {
  class: "dynamic",
  name: "dynamic"
}
const _hoisted_10 = { class: "row q-mb-md justify-end" }
const _hoisted_11 = { class: "time" }
const _hoisted_12 = {
  class: "intersection",
  name: "intersection"
}

import UsersProjectsChart from "@/components/charts/UsersProjectsChart.vue";
import {computed, onBeforeMount, onMounted, ref, toRaw} from "vue";
import store from "@/store";
import DynamicChart from "@/components/modules/intersection/components/Registration/DynamicChart.vue";
import IntersectionUsersChart from "@/components/charts/IntersectionUsersChart.vue";
import IntersectionTable from "@/components/modules/intersection/components/Registration/IntersectionTable.vue";
import MetricComponent from "@/components/modules/intersection/components/Metrics/MetricComponent.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
import RegistrationChart from "@/components/modules/intersection/components/Registration/RegistrationChart.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InterRegistration',
  setup(__props) {

const usersCount = ref(0)
const tab = ref('user_count')
const dateMin = ref('')
const dateMax = ref(new Date().toJSON().slice(0, 10))
const qDateProxy1: any = ref()
const qDateProxy2: any = ref()
const sortType = ref('day')
const interRef = ref([])
const isDynamicListBusy = computed(() => store.getters.isDynamicListBusy)
const isAllCountBusy = computed(() => store.getters.isIntersectionUsersBusy)
const metricValues = computed(() => {
  const metricList = store.getters.getMetricChecked

  return toRaw(metricList)
})

const metricArr:any = ref([])
const intersectionTable = ref()

const dateOptionsStart = (date: any) => {
  return date < dateMax.value.split('-').join('/')
}

const dateOptionsEnd = (date: any) => {
  return date > dateMin.value.split('-').join('/') && date <= new Date().toJSON().slice(0, 10).split('-').join('/')
}

onBeforeMount(() => {
  store.dispatch('clearMetricChecked', [])
})

const getMinDate = () => {
  const dateSplitter = dateMax.value.split('-')
  const prevYear = Number(dateSplitter[0]) - 1

  dateMin.value = prevYear + '-' + dateSplitter[1] + '-' + dateSplitter[2]
}

const getAllCount = () => {
  store.dispatch('getCountUsersList', {
    activity: metricValues.value['Активность'],
    loyalty: metricValues.value['Лояльность'],
    useful: metricValues.value['Полезность'],
    interest: metricValues.value['Заинтересованность'],
    date_begin: dateMin.value,
    date_end: dateMax.value,
  }).then((value) => usersCount.value = value)
}

const getMinMaxDate = () => {
  store
      .dispatch('getMinMaxDate')
      .then((res) => {
        console.log('minmax', res)
        dateMin.value = res.getMinRegDate()
        dateMax.value = res.getMaxRegDate()
        applyPeriod()
      })
}

const getUsersProjectsChart = () => {
  store.dispatch('getListIntersectionUsers', {
    date: {
      activity: metricValues.value['Активность'],
      loyalty: metricValues.value['Лояльность'],
      useful: metricValues.value['Полезность'],
      interest: metricValues.value['Заинтересованность'],
      date_begin: dateMin.value,
      date_end: dateMax.value,
    }
  })
}

const getDynamicChart = () => {
  store.dispatch('getDynamicList', {
    project_ids: [],
    type_interval: sortType.value,
    date_begin: dateMin.value,
    date_end: dateMax.value,
    activity: metricValues.value['Активность'],
    loyalty: metricValues.value['Лояльность'],
    useful: metricValues.value['Полезность'],
    interest: metricValues.value['Заинтересованность'],
  })
}

const getIntersectionTable = () => {
  intersectionTable.value.loadData()
}

const applyPeriod = () => {
  getAllCount()
  if (tab.value === 'user_count') getUsersProjectsChart()
  if (tab.value === 'dynamic') getDynamicChart()
  if (tab.value === 'table') getIntersectionTable()
}

onMounted(() => {
  getMinDate()
  getAllCount()
  getUsersProjectsChart()

  store.dispatch('getMetricList', {
    filter: {},
    sort: {},
    pagination: {}
  }).then((value: any) => {
    for (const i in value) {
      metricArr.value.push(value[i])
    }
  })
})

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(Breadcrumbs),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(metricArr.value, (metricItem) => {
          return (_openBlock(), _createBlock(MetricComponent, {
            options: metricItem,
            key: metricItem
          }, null, 8, ["options"]))
        }), 128)),
        _createVNode(_component_q_btn, {
          style: {"height":"56px"},
          color: "primary",
          label: "Применить",
          size: "md",
          onClick: applyPeriod
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_q_input, {
          filled: "",
          modelValue: dateMin.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((dateMin).value = $event)),
          "hide-bottom-space": "",
          "hide-hint": "",
          style: {"width":"100%","cursor":"pointer"},
          "input-style": "cursor:pointer",
          label: "Дата начала",
          mask: "####-##-##",
          readonly: "",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (qDateProxy1.value.show()))
        }, {
          append: _withCtx(() => [
            _createVNode(_component_q_icon, {
              name: "event",
              class: "cursor-pointer"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_popup_proxy, {
                  cover: "",
                  "transition-show": "scale",
                  "transition-hide": "scale",
                  ref_key: "qDateProxy1",
                  ref: qDateProxy1
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_date, {
                      modelValue: dateMin.value,
                      "onUpdate:modelValue": [
                        _cache[0] || (_cache[0] = ($event: any) => ((dateMin).value = $event)),
                        _cache[1] || (_cache[1] = ($event: any) => (qDateProxy1.value.hide()))
                      ],
                      mask: "YYYY-MM-DD",
                      "navigation-max-year-month": new Date().toJSON().slice(0, 10).replace(/-/g,'/'),
                      options: dateOptionsStart
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _withDirectives(_createVNode(_component_q_btn, {
                            label: "Close",
                            color: "primary",
                            flat: ""
                          }, null, 512), [
                            [_directive_close_popup]
                          ])
                        ])
                      ]),
                      _: 1
                    }, 8, ["modelValue", "navigation-max-year-month"])
                  ]),
                  _: 1
                }, 512)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_q_input, {
          filled: "",
          modelValue: dateMax.value,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((dateMax).value = $event)),
          label: "Дата окончания",
          "hide-bottom-space": "",
          "hide-hint": "",
          mask: "####-##-##",
          readonly: "",
          style: {"width":"100%","cursor":"pointer"},
          "input-style": "cursor:pointer",
          onClick: _cache[7] || (_cache[7] = ($event: any) => (qDateProxy2.value.show()))
        }, {
          append: _withCtx(() => [
            _createVNode(_component_q_icon, {
              name: "event",
              class: "cursor-pointer"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_popup_proxy, {
                  cover: "",
                  "transition-show": "scale",
                  "transition-hide": "scale",
                  ref_key: "qDateProxy2",
                  ref: qDateProxy2
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_date, {
                      modelValue: dateMax.value,
                      "onUpdate:modelValue": [
                        _cache[4] || (_cache[4] = ($event: any) => ((dateMax).value = $event)),
                        _cache[5] || (_cache[5] = ($event: any) => (qDateProxy2.value.hide()))
                      ],
                      mask: "YYYY-MM-DD",
                      options: dateOptionsEnd
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                          _withDirectives(_createVNode(_component_q_btn, {
                            label: "Close",
                            color: "primary",
                            flat: ""
                          }, null, 512), [
                            [_directive_close_popup]
                          ])
                        ])
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _: 1
                }, 512)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_q_btn, {
          color: "primary",
          label: "Применить период",
          size: "md",
          onClick: applyPeriod,
          class: "custom-button"
        }),
        _createVNode(_component_q_btn, {
          color: "secondary",
          label: "За всё время",
          title: "При нажатии на кнопку отобразятся регистрации за всё время проектов (с первой по последнюю)",
          size: "md",
          onClick: getMinMaxDate,
          class: "custom-button"
        }),
        _createElementVNode("p", _hoisted_6, [
          _cache[14] || (_cache[14] = _createTextVNode("Уникальных зарегистрированных пользователей")),
          _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("span", null, _toDisplayString(usersCount.value.toLocaleString()), 1),
          _createVNode(_component_q_inner_loading, {
            showing: isAllCountBusy.value,
            color: "white"
          }, null, 8, ["showing"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_q_tabs, {
          modelValue: tab.value,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((tab).value = $event)),
          dense: "",
          class: "text-grey",
          "active-color": "primary",
          "indicator-color": "primary",
          align: "justify",
          "narrow-indicator": ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tab, {
              class: "custom-tab",
              name: "user_count",
              label: "Количество регистраций в проектах"
            }),
            _createVNode(_component_q_tab, {
              class: "custom-tab",
              name: "dynamic",
              label: "Динамика регистраций"
            }),
            _createVNode(_component_q_tab, {
              class: "custom-tab",
              name: "intersection",
              label: "Пересечение пользователей"
            }),
            _createVNode(_component_q_tab, {
              class: "custom-tab",
              name: "table",
              label: "Таблица регистраций"
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_q_separator)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_q_tab_panels, {
          modelValue: tab.value,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((tab).value = $event)),
          animated: "",
          style: {"width":"100%","background":"none","height":"unset"}
        }, {
          default: _withCtx(() => [
            _createVNode(UsersProjectsChart, {
              "disable-click": false,
              peace: usersCount.value,
              "date-min": dateMin.value,
              "date-max": dateMax.value,
              style: {"width":"100%"},
              name: "user_count",
              filters: metricValues.value
            }, null, 8, ["peace", "date-min", "date-max", "filters"]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_q_btn, {
                    outline: sortType.value !== 'day',
                    style: {"height":"56px","min-width":"100px"},
                    color: "primary",
                    label: "ДНИ",
                    size: "md",
                    onClick: _cache[9] || (_cache[9] = ($event: any) => {sortType.value='day'; applyPeriod()}),
                    disable: isDynamicListBusy.value
                  }, null, 8, ["outline", "disable"]),
                  _createVNode(_component_q_btn, {
                    outline: sortType.value !== 'month',
                    style: {"height":"56px","min-width":"100px"},
                    color: "primary",
                    label: "МЕСЯЦЫ",
                    size: "md",
                    class: "q-ml-md",
                    onClick: _cache[10] || (_cache[10] = ($event: any) => {sortType.value='month'; applyPeriod()}),
                    disable: isDynamicListBusy.value
                  }, null, 8, ["outline", "disable"]),
                  _createVNode(_component_q_btn, {
                    outline: sortType.value !== 'year',
                    style: {"height":"56px","min-width":"100px"},
                    color: "primary",
                    label: "ГОДЫ",
                    size: "md",
                    class: "q-ml-md",
                    onClick: _cache[11] || (_cache[11] = ($event: any) => {sortType.value='year'; applyPeriod()}),
                    disable: isDynamicListBusy.value
                  }, null, 8, ["outline", "disable"])
                ])
              ]),
              _createVNode(DynamicChart, {
                "sort-type": sortType.value,
                "date-min": dateMin.value,
                "date-max": dateMax.value,
                style: {"width":"100%"}
              }, null, 8, ["sort-type", "date-min", "date-max"])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(IntersectionUsersChart, {
                style: {"width":"100%"},
                onInterCheck: _cache[12] || (_cache[12] = (value) => interRef.value = value),
                min: dateMin.value,
                max: dateMax.value,
                filters: metricValues.value
              }, null, 8, ["min", "max", "filters"]),
              _createVNode(RegistrationChart, {
                class: "q-mt-xl",
                style: {"width":"100%"},
                "date-max": dateMax.value,
                "date-min": dateMin.value,
                filters: metricValues.value
              }, null, 8, ["date-max", "date-min", "filters"])
            ]),
            _createVNode(IntersectionTable, {
              name: "table",
              "date-min": dateMin.value,
              "date-max": dateMax.value,
              ref_key: "intersectionTable",
              ref: intersectionTable
            }, null, 8, ["date-min", "date-max"])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ])
  ], 64))
}
}

})