import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "user-projects q-pa-lg" }
const _hoisted_2 = { class: "user-projects__filters q-mb-md q-gutter-md row" }
const _hoisted_3 = { class: "text-h6 text-weight-bold" }
const _hoisted_4 = {
  class: "row relative-position items-center q-mt-sm",
  style: {"gap":"5px"}
}
const _hoisted_5 = { class: "row items-center" }
const _hoisted_6 = {
  key: 1,
  class: "table__check-icon row items-center"
}
const _hoisted_7 = { class: "row items-center q-gutter-sm" }
const _hoisted_8 = { style: {"font-size":"14px"} }
const _hoisted_9 = { style: {"font-size":"14px"} }
const _hoisted_10 = {
  key: 0,
  class: "table__check-icon row items-center"
}
const _hoisted_11 = { class: "row items-center q-gutter-sm" }
const _hoisted_12 = { style: {"font-size":"14px"} }
const _hoisted_13 = {
  key: 0,
  class: "row relative-position no-wrap items-center",
  style: {"gap":"3px"}
}
const _hoisted_14 = {
  class: "ellipsis",
  style: {"padding":"3px 5px","font-size":"14px","max-width":"220px"}
}
const _hoisted_15 = { class: "table__sex row items-center" }
const _hoisted_16 = {
  key: 0,
  class: "table__sex-check row no-wrap"
}
const _hoisted_17 = {
  key: 1,
  class: "table__check-icon row items-center"
}
const _hoisted_18 = { class: "row items-center q-gutter-sm" }
const _hoisted_19 = { style: {"font-size":"14px"} }
const _hoisted_20 = { style: {"font-size":"14px"} }
const _hoisted_21 = { class: "table__birth" }
const _hoisted_22 = { class: "table__birth-check row no-wrap items-center" }
const _hoisted_23 = {
  key: 1,
  class: "table__check-icon row items-center"
}
const _hoisted_24 = { class: "row items-center q-gutter-sm" }
const _hoisted_25 = { style: {"font-size":"14px"} }
const _hoisted_26 = { style: {"font-size":"14px"} }
const _hoisted_27 = { class: "table__family" }
const _hoisted_28 = { class: "table__family-check row no-wrap items-center" }
const _hoisted_29 = {
  key: 1,
  class: "table__check-icon row items-center"
}
const _hoisted_30 = { class: "row items-center q-gutter-sm" }
const _hoisted_31 = { style: {"font-size":"14px"} }
const _hoisted_32 = { style: {"font-size":"14px"} }
const _hoisted_33 = { class: "table__district" }
const _hoisted_34 = { class: "table__district-check row no-wrap items-center" }
const _hoisted_35 = {
  key: 1,
  class: "table__check-icon row items-center"
}
const _hoisted_36 = { class: "row items-center q-gutter-sm" }
const _hoisted_37 = { style: {"font-size":"14px"} }
const _hoisted_38 = { style: {"font-size":"14px"} }
const _hoisted_39 = { class: "table__region" }
const _hoisted_40 = { class: "table__region-check row no-wrap items-center" }
const _hoisted_41 = {
  key: 1,
  class: "table__check-icon row items-center"
}
const _hoisted_42 = { class: "row items-center q-gutter-sm" }
const _hoisted_43 = { style: {"font-size":"14px"} }
const _hoisted_44 = { style: {"font-size":"14px"} }
const _hoisted_45 = { class: "table__address" }
const _hoisted_46 = { class: "table__address-check row no-wrap items-center" }
const _hoisted_47 = {
  key: 1,
  class: "table__check-icon row items-center"
}
const _hoisted_48 = { class: "row items-center q-gutter-sm" }
const _hoisted_49 = { style: {"font-size":"14px"} }
const _hoisted_50 = { style: {"font-size":"14px"} }

import store from '@/store'
import {
  ref,
  computed,
  onMounted,
  watch,
  shallowRef,
  onUnmounted,
  toRaw,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import date from 'quasar/src/utils/date/date.js';import dom from 'quasar/src/utils/dom/dom.js';
import ModalPointsEarned from '../components/UserProjectsList/ModalPointsEarned.vue'
import ModalPointsSpent from '../components/UserProjectsList/ModalPointsSpent.vue'
import UsersSearchInput from '../components/UserProjectsList/UsersSearchInput.vue'
import ExportButtonUsers from '../components/ExportButton/ExportButtonUsers.vue'
import ProjectSelect from '../components/Filters/ProjectsSelect.vue'
import AsideFilters from '../components/UserProjectsList/AsideFilters.vue'
import ModalPointsEarnedCity from "@/components/modules/accounts/components/UserProjectsList/ModalPointsEarnedCity.vue";
import ModalPointsEarnedPrize
  from "@/components/modules/accounts/components/UserProjectsList/ModalPointsEarnedPrize.vue";
import ModalPointsSpentPrize from "@/components/modules/accounts/components/UserProjectsList/ModalPointsSpentPrize.vue";
import ModalPointsSpentCity from "@/components/modules/accounts/components/UserProjectsList/ModalPointsSpentCity.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserProjects',
  setup(__props) {

const router = useRouter()
const route = useRoute()

onMounted(() => {
  getProjects()
  loadAccountsItems(true)
  accountsChecksMap.value = {}
  console.log('query params', route.query)
})

onUnmounted(() => (breakLoop.value = true))

const breakLoop = ref(false)

const isShowModal = ref(false)

const tablePage = ref(1)

const usersFilters: any = ref([])

const filtersCheck = ref(false)

const sideFilters = ref()

const singleSearchFilter = ref()

//Таблица

const tablePagination = ref({
  sortBy: '',
  descending: true,
  page: 1,
  rowsPerPage: 50,
  rowsNumber: 0,
})

const filterAssoc: { [key: number]: string } = {
  1: 'a.is_ag',
  2: 'a.is_gz',
  3: 'a.is_gi',
  4: 'a.is_ed',
  5: 'a.is_ng',
  6: 'a.is_mp',
  17: 'a.is_agc'
}

const getUserList = computed(() => {
  const rawAccountsItems = store.getters.getAccountsItems

  let rows: any = []
  if (rawAccountsItems !== undefined) {
    for (let accountItem of rawAccountsItems) {
      let sex =
        (accountItem.getSex() == 'Мужской' && 'Мужской') ||
        (accountItem.getSex() == 'Женский' && 'Женский') ||
        'Не указан'
      let projectsWarning = []

      if (accountItem.getIsAg()) {
        projectsWarning.push({
          projectName: 'Активный гражданин',
          regDate: accountItem
            .getRegdateAg()
            .split(' ')[0]
            .split('-')
            .reverse()
            .join('.'),
        })
      }
      if (accountItem.getIsEd()) {
        projectsWarning.push({
          projectName: 'Электронный дом',
          regDate: accountItem
            .getRegdateEd()
            .split(' ')[0]
            .split('-')
            .reverse()
            .join('.'),
        })
      }
      if (accountItem.getIsGi()) {
        projectsWarning.push({
          projectName: 'Город идей',
          regDate: accountItem
            .getRegdateGi()
            .split(' ')[0]
            .split('-')
            .reverse()
            .join('.'),
        })
      }
      if (accountItem.getIsGz()) {
        projectsWarning.push({
          projectName: 'Город заданий',
          regDate: accountItem
            .getRegdateGz()
            .split(' ')[0]
            .split('-')
            .reverse()
            .join('.'),
        })
      }
      if (accountItem.getIsNg()) {
        projectsWarning.push({
          projectName: 'Наш город',
          regDate: accountItem
            .getRegdateNg()
            .split(' ')[0]
            .split('-')
            .reverse()
            .join('.'),
        })
      }
      if (accountItem.getIsMp()) {
        projectsWarning.push({
          projectName: 'Миллион призов',
          regDate: accountItem
            .getRegdateMp()
            .split(' ')[0]
            .split('-')
            .reverse()
            .join('.'),
        })
      }
      if (accountItem.getIsAgc()) {
        projectsWarning.push({
          projectName: 'Активный гражданин - детям',
          regDate: accountItem
            .getRegdateAgc()
            .split(' ')[0]
            .split('-')
            .reverse()
            .join('.'),
        })
      }

      rows.push({
        accGuid: accountItem.getAccountGuid(),
        guid: accountItem.getSsoId() || 'Не заполнено',
        project_count: accountItem.getCntProjects() ?? 'Не заполнено',
        projectsWarning,
        activity: accountItem.getActivity() || 'Не заполнено',
        group_usefulness: accountItem.getUsefulness() || 'Не заполнено',
        group_loyalty: accountItem.getLoyalty() || 'Не заполнено',
        group_ofinterest: accountItem.getInterest() || 'Не заполнено',
        group_message: accountItem.getMessagesGroup() || 'Нейтральная',
        class_message: accountItem.getMessagesClassesList(),
        cnt_activity: accountItem.getCntActivity() || 'Не заполнено',
        first_activity_date: accountItem.getFirstActivityDate().split(' ')[0].split('-').reverse().join('.') || 'Не заполнено',
        last_activity_date: accountItem.getLastActivityDate().split(' ')[0].split('-').reverse().join('.') || 'Не заполнено',
        points_earned_city: accountItem.getPointsEarnedCity() || 0,
        points_spent_city: accountItem.getPointsSpentCity() || 0,
        points_city: accountItem.getPointsCity() || 0,
        points_earned_prize: accountItem.getPointsEarnedPrize() || 0,
        points_spent_prize: accountItem.getPointsSpentPrize() || 0,
        points_prize: accountItem.getPointsPrize() || 0,
        sex: sex,
        birth:
          accountItem.getBirthday().split('-').reverse().join('.') ||
          'Не заполнено',
        family_status: accountItem.getFamilyStatus() || 'Не заполнено',
        num_child: accountItem.getNumChild() === -1 ? 'Не указано' : accountItem.getNumChild(),
        district: accountItem.getRegion() || 'Не заполнено',
        region: accountItem.getDistrict() || 'Не заполнено',
        address: accountItem.getAddress() || 'Не заполнено',
        social: accountItem.getSocial() || 'Не заполнено',
        type_descr: accountItem.getTypeDescr() || 'Не заполнено',
        is_blocked: accountItem.getIsBlocked() === 0 ? 'Нет' : 'Да',
        tags: accountItem.getTagsList(),
      })
    }
  }
  return rows
})

const getSelectedProjectItems = computed(
  () => store.getters.getSelectedProjectItems
)
const isAccountsBusy = computed(() => store.getters.isAccountsBusy)

const compFilter = computed(() => {
  let filter = []

  //Фильтр по проектам
  if (usersFilters.value) {
    filter.push(...toRaw(usersFilters.value))
  }

  //Фильтр поиска по списку
  if (userSearchFilter.value) {
    filter.push(...toRaw(userSearchFilter.value))
  }

  //Боковые фильтры
  if (sideFilters.value) {
    filter.push(...toRaw(sideFilters.value))
  }

  //Фильтр из поиска по одному id
  if (singleSearchFilter.value && singleSearchFilter.value.length) {
    // filter.push(...toRaw(singleSearchFilter.value))
    filter.push({
      field_name: 'a.sso_id',
      operand: '=',
      value: singleSearchFilter.value,
    })
  }

  return filter.flat()
})

const onRequestResult = (prop: any) => {
  console.log('ON REQUEST TABLE', prop)
  window.stop()
  tablePagination.value = prop.pagination
  loadAccountsItems(false)
}

const loadTableWithFilters = (filter: any) => {
  console.log('LOAD TABLE WITH FILTERS ', filter)
  loadAccountsItems(true)
}

const loadAccountsItems = (isResetPagination: boolean) => {
  if (isResetPagination) {
    tablePage.value = 1
    tablePagination.value.page = 1
    tablePagination.value.rowsNumber = 0
  }

  store
    .dispatch('getAccountsItems', {
      filter: compFilter.value ? compFilter.value : {},
      sort: {
        name: tablePagination.value.sortBy || 'a.account_guid',
        exp: tablePagination.value.descending ? 'DESC' : 'ASC',
      },
      pagination: {
        page: tablePagination.value.page,
        limit: tablePagination.value.rowsPerPage,
        pages: 0,
        cnt: 0,
      },
      personal: route.query.data === 'personal'
    })
    .then(
      (resolve) => {
        console.log('ACCS RESOLVE', resolve)
        console.log('RES PAGINATION', resolve.pagination)
        tablePagination.value.rowsNumber = resolve.pagination.getCnt()
      },
      (reject) => {
        console.log('getAccountsItems reject', reject)
      }
    )
}

const rawTableColumns: any = [
  {
    label: 'SSO ID',
    name: 'a.account_guid',
    field: 'guid',
    sortable: true,
    align: 'left',
  },
  {
    label: 'Количество проектов',
    name: 'is_ag+is_ed+is_gi+is_gz+is_ng+is_mp',
    field: 'project_count',
    sortable: true,
    align: 'left',
  },
  {
    label: 'Группа активности',
    name: 'activity',
    field: 'activity',
    sortable: false,
    align: 'left',
  },
  {
    label: 'Группа лояльности',
    name: 'group_loyalty',
    field: 'group_loyalty',
    sortable: false,
    align: 'left',
  },
  {
    label: 'Группа полезности',
    name: 'group_usefulness',
    field: 'group_usefulness',
    sortable: false,
    align: 'left',
  },
  {
    label: 'Группа заинтересованности',
    name: 'group_ofinterest',
    field: 'group_ofinterest',
    sortable: false,
    align: 'left',
  },
  {
    label: 'Группа сообщений',
    name: 'group_message',
    field: 'group_message',
    sortable: false,
    align: 'left'
  },
  {
    label: 'Теги',
    name: 'tags',
    field: 'tags',
    sortable: false,
    align: 'left',
  },
  {
    label: 'Тип учетной записи',
    name: 'type_descr',
    field: 'type_descr',
    sortable: false,
    align: 'left',
  },
  {
    label: 'Количество активностей',
    name: 'cnt_activity',
    field: 'cnt_activity',
    sortable: true,
    align: 'left',
  },
  {
    label: 'Дата первой активности',
    name: 'first_activity_date',
    field: 'first_activity_date',
    sortable: true,
    align: 'left',
  },
  {
    label: 'Дата последней активности',
    name: 'last_activity_date',
    field: 'last_activity_date',
    sortable: true,
    align: 'left',
  },
  {
    label: 'Заработано городских баллов',
    name: 'points_earned_city',
    field: 'points_earned_city',
    sortable: true,
    align: 'left',
  },
  {
    label: 'Потрачено городских баллов',
    name: 'points_spent_city',
    field: 'points_spent_city',
    sortable: true,
    align: 'left',
  },
  {
    label: 'Текущий остаток городских баллов',
    name: 'points_city',
    field: 'points_city',
    sortable: true,
    align: 'left',
  },
  {
    label: 'Заработано призовых баллов',
    name: 'points_earned_prize',
    field: 'points_earned_prize',
    sortable: true,
    align: 'left',
  },
  {
    label: 'Потрачено призовых баллов',
    name: 'points_spent_prize',
    field: 'points_spent_prize',
    sortable: true,
    align: 'left',
  },
  {
    label: 'Текущий остаток призовых баллов',
    name: 'points_prize',
    field: 'points_prize',
    sortable: true,
    align: 'left',
  },
  { label: 'Пол', name: 'a.sex', field: 'sex', sortable: false, align: 'left' },
  {
    label: 'Дата рождения',
    name: 'a.birthday',
    field: 'birth',
    sortable: false,
    align: 'left',
  },
  {
    label: 'Семейное положение',
    name: 'a.family_status',
    field: 'family_status',
    sortable: false,
    align: 'left',
  },
  {
    label: 'Количество детей',
    name: 'num_child',
    field: 'num_child',
    sortable: false,
    align: 'left',
  },
  {
    label: 'Округ регистрации',
    name: 'a.district',
    field: 'district',
    sortable: false,
    align: 'left',
  },
  {
    label: 'Район регистрации',
    name: 'a.region',
    field: 'region',
    sortable: false,
    align: 'left',
  },
  {
    label: 'Адрес регистрации',
    name: 'a.address',
    field: 'address',
    sortable: false,
    align: 'left',
  },
  {
    label: 'Род деятельности',
    name: 'social',
    field: 'social',
    sortable: false,
    align: 'left',
  },
  {
    label: 'Заблокирован',
    name: 'is_blocked',
    field: 'is_blocked',
    sortable: false,
    align: 'left',
  },
]

watch([getSelectedProjectItems, filtersCheck], ([value]) => {
  const filters: any = []

  value.forEach((item: any, idx: any) => {
    if (filtersCheck.value) {
      filters.push({
        field_name: filterAssoc[item],
        operand: '=',
        value: 1,
        or: [],
      })
    } else {
      if (idx === 0) {
        filters.push({
          field_name: filterAssoc[item],
          operand: '=',
          value: 1,
          or: [],
        })
      } else {
        filters[0].or.push({
          field_name: filterAssoc[item],
          operand: '=',
          value: 1,
        })
      }
    }
  })

  usersFilters.value = filters

  console.log(
    '🚀 ~ file: UserProjects.vue:198 ~ watch ~ filters:',
    usersFilters.value
  )
  loadTableWithFilters(compFilter.value)
})

// Modal table
const modalComponent = shallowRef()

const modalGuid = ref()

const openModal = (row: string, id: string) => {
  if (row == 'earned_city') {
    modalComponent.value = ModalPointsEarnedCity
    modalGuid.value = id
  }

  if (row == 'earned_prize') {
    modalComponent.value = ModalPointsEarnedPrize
    modalGuid.value = id
  }

  if (row == 'spent_city') {
    modalComponent.value = ModalPointsSpentCity
    modalGuid.value = id
  }

  if (row == 'spent_prize') {
    modalComponent.value = ModalPointsSpentPrize
    modalGuid.value = id
  }

  isShowModal.value = true
}

const openUserProfile = (profile: string) => {
  // window.stop()
  // store.commit('setAccountSex', accountsChecksMap.value[profile].sex.name)
  // router.push('/user-profile/' + profile)
  const routeData = router.resolve({
    path: '/user-profile/' + profile,
  })
  window.open(routeData.href, '_blank')
}

const accountsChecksMap: any = ref()
// const pointsEarnedMap: any = ref()
// const activityCountMap: any = ref()

watch(getUserList, () => {
  fetchChecks()
  // fetchPoints()
  // fetchActivityCnt()
})

const fetchChecks = async () => {
  const tempObj: any = {}
  accountsChecksMap.value = {}
  if (getUserList.value && getUserList.value.length > 0) {
    for (let i = 0; i < getUserList.value.length; i++) {
      tempObj[getUserList.value[i].guid] = {
        sex: {},
        birth: {},
        family: {},
        district: {},
        region: {},
        address: {},
      }
    }

    accountsChecksMap.value = tempObj

    for (let i = 0; i < getUserList.value.length; i++) {
      const guid = getUserList.value[i].guid
      accountsChecksMap.value[guid].projectsCnt = []
      if (breakLoop.value) {
        break
      }

      await store
        .dispatch('getCheckList', guid)
        .then((val) => {
          const sex = val.getSex()
          const birthday = val.getBirthday()
          const famStatus = val.getFamilyStatus()
          const district = val.getRegion()
          const region = val.getDistrict()
          const address = val.getAddress()

          accountsChecksMap.value[guid].sex = {
            flag: sex.getSuccess(),
            items: sex.getItemsList(),
          }
          accountsChecksMap.value[guid].birth = {
            flag: birthday.getSuccess(),
            items: birthday.getItemsList(),
          }
          accountsChecksMap.value[guid].family = {
            flag: famStatus.getSuccess(),
            items: famStatus.getItemsList(),
          }
          accountsChecksMap.value[guid].district = {
            flag: district.getSuccess(),
            items: district.getItemsList(),
          }
          accountsChecksMap.value[guid].region = {
            flag: region.getSuccess(),
            items: region.getItemsList(),
          }
          accountsChecksMap.value[guid].address = {
            flag: address.getSuccess(),
            items: address.getItemsList(),
          }
          let cntSex: any = {}
          for (const i of accountsChecksMap.value[guid].sex.items) {
            if (!cntSex[i.getValue()]) {
              cntSex[i.getValue()] = 1
            } else {
              cntSex[i.getValue()]++
            }
          }

          accountsChecksMap.value[guid].sex.name = findSex(cntSex)
        })
        .catch((err) => console.log('Fetch error ', err))
    }
  }
}

// const fetchPoints = async () => {
//   const tempObj: any = {}
//   pointsEarnedMap.value = {}
//   if (getUserList.value && getUserList.value.length > 0) {
//     for (let i = 0; i < getUserList.value.length; i++) {
//       tempObj[getUserList.value[i].guid] = {
//         earned: 'Загрузка...',
//         spent: 'Загрузка...',
//         current: 'Загрузка...',
//       }
//     }

//     pointsEarnedMap.value = tempObj

//     for (let i = 0; i < getUserList.value.length; i++) {
//       if (breakLoop.value) {
//         break
//       }
//       await store
//         .dispatch('getCountPoints', {
//           sso_id: getUserList.value[i].guid,
//           date_begin: '2012-01-01',
//           date_end: new Date().toJSON().slice(0, 10),
//         })
//         .then((val) => {
//           pointsEarnedMap.value[getUserList.value[i].guid].earned =
//             val.getCntEarned()
//           pointsEarnedMap.value[getUserList.value[i].guid].spent =
//             val.getCntSpent()
//           pointsEarnedMap.value[getUserList.value[i].guid].current =
//             val.getCntCurrent()
//         })
//         .catch((err) => console.log('Fetch error ', err))
//     }
//   }
// }

// const fetchActivityCnt = async () => {
//   const tempObj: any = {}
//   activityCountMap.value = {}
//   if (getUserList.value && getUserList.value.length > 0) {
//     for (let i = 0; i < getUserList.value.length; i++) {
//       tempObj[getUserList.value[i].guid] = 'Загрузка...'
//     }

//     activityCountMap.value = tempObj

//     for (let i = 0; i < getUserList.value.length; i++) {
//       if (breakLoop.value) {
//         break
//       }
//       await store
//         .dispatch('getEventsCounts', {
//           filter: {
//             sso_id: getUserList.value[i].guid,
//             project_ids: [],
//             action_names: [],
//             date_begin: '2012-01-01',
//             date_end: new Date().toJSON().slice(0, 10),
//           },
//           pagination: {
//             page: 1,
//             limit: 10000,
//             pages: 0,
//             cnt: 0,
//           },
//         })
//         .then((cnt) => {
//           activityCountMap.value[getUserList.value[i].guid] = cnt
//         })
//         .catch((err) => console.log('Fetch error ', err))
//     }
//   }
// }

const findSex = (obj: any) => {
  delete obj['Не указано']
  let arr = Object.keys(obj).map((key) => obj[key])
  let maxValue = Math.max(...arr)
  let found: any = 0
  let filteredSex: any = ''
  if (arr.length) {
    let cntForDupe = 0
    for (const i of arr) {
      if (i == maxValue) cntForDupe++
    }
    if (cntForDupe >= 2) {
      let names = Object.keys(obj).filter((key) => obj[key] === maxValue)
      console.log('🚀 ~ file: UserProjects.vue:428 ~ findSex ~ names:', names)

      if (names.length == 1 && !names.includes('Не указано')) {
        filteredSex = names[0]
      } else if (names.length == 2 && names.includes('Не указано')) {
        filteredSex = names.find((item) => item != 'Не указано')
      } else if (names.length == 2 && !names.includes('Не указано')) {
        filteredSex = 'Не указан'
      } else if (names.length == 3) {
        filteredSex = 'Не указан'
      } else {
        filteredSex = 'Не указан'
      }
    } else {
      found = maxValue
    }
  } else {
    filteredSex = 'Не указан'
  }
  if (!filteredSex && !isNaN(found)) {
    filteredSex = Object.keys(obj).find((key) => obj[key] === found)
  }

  if (filteredSex == 'Не указано') filteredSex = 'Не указан'

  return filteredSex
}

const isUserSearchShow = ref(false)
const userSearchInput = ref('')
const userSearchInputErr = ref('')
const userSearchInputLabel = ref('Поиск по списку')
const userSearchListFetched = ref(false)
const userSearchFilter = ref()

const longUserSearch = () => {
  const filter: any = []
  let inputArr: string[] = []

  //Очистка поиска по одному id если есть
  singleSearchFilter.value = []

  //Удаление лишних пробелов и замена запятых на пробел
  userSearchInput.value = userSearchInput.value
    .replace(/ |,|\n+?/g, ' ')
    .replace(/ +/g, ' ')

  inputArr = userSearchInput.value.split(' ')

  inputArr.forEach((item, idx) => {
    if (idx === 0) {
      filter.push({
        field_name: 'a.sso_id',
        operand: '=',
        value: item,
        or: [],
      })
    } else {
      filter[0].or.push({
        field_name: 'a.sso_id',
        operand: '=',
        value: item,
      })
    }
  })

  if (userSearchInput.value.length && !inputArr.length) {
    userSearchInputErr.value = 'Введите список пользователей'
  } else {
    userSearchInputErr.value = ''

    userSearchInputLabel.value = 'Очистить поиск'
    userSearchListFetched.value = true

    store.commit('setAccountSearchString', '')

    userSearchFilter.value = filter

    // tablePagination.value.rowsPerPage = inputArr.length

    // store.dispatch('getAccountsItems', {
    //   filter: compFilter.value ? compFilter.value : {},
    //   sort: {
    //     name: tablePagination.value.sortBy || 'a.sso_id',
    //     exp: 'DESC',
    //   },
    //   pagination: {
    //     page: 1,
    //     limit: inputArr.length,
    //     pages: 0,
    //   },
    // })

    loadAccountsItems(true)
    isUserSearchShow.value = false
  }
}

const userListSearchBtn = () => {
  if (userSearchInputLabel.value != 'Очистить поиск') {
    isUserSearchShow.value = !isUserSearchShow.value
  } else {
    userSearchInput.value = ''
    userSearchInputLabel.value = 'Поиск по списку'
    userSearchListFetched.value = false
    userSearchFilter.value = undefined
    tablePagination.value.rowsPerPage = 15
    loadAccountsItems(true)
  }
}

const getAccountSearchString = computed(
  () => store.getters.getAccountSearchString
)

const clearSearch = () => {
  if (getAccountSearchString.value) {
    userListSearchBtn()
  }
  singleSearchFilter.value = undefined
  userSearchInput.value = ''
  userSearchFilter.value = undefined
  loadAccountsItems(true)
}

const userTableRef: any = ref(null)
const filtersRef: any = ref(null)
const rootRef: any = ref(null)
const sideFilterLabel = ref('Фильтры')
const { style } = dom

const toggleFilter = () => {
  let toggle = 1

  return function () {
    toggle ^= 1
    if (!toggle) {
      sideFilterLabel.value = 'Скрыть фильтры'
    } else {
      sideFilterLabel.value = 'Фильтры'
    }
    const filtersWidth = style(filtersRef.value, 'width')

    if (!toggle) {
      userTableRef.value.style.paddingRight = filtersWidth
      filtersRef.value.style.transform = 'translateX(0)'
      rootRef.value.style.overflowY = 'visible'
      rootRef.value.style.overflowX = 'visible'
    } else {
      userTableRef.value.style.paddingRight = '0px'
      filtersRef.value.style.transform = 'translateX(100%)'
      rootRef.value.style.overflowX = 'hidden'
      rootRef.value.style.overflowY = 'hidden'
    }
  }
}
const toggleSideFilters = toggleFilter()

const sideFiltersFn = (filters: any) => {
  sideFilters.value = filters

  loadAccountsItems(false)
}

const clearListSearch = () => {
  if (userSearchInputLabel.value == 'Очистить поиск') {
    userSearchInput.value = ''
    userSearchInputLabel.value = 'Поиск по списку'
    userSearchListFetched.value = false
    userSearchFilter.value = undefined
  }
}

const projectAssociation = ref({})

const getProjects = () => {
  store.dispatch('getProjectsItems', {
    filter: [
      {
        field_name: 'is_visible',
        operand: '=',
        value: 'true',
      }
    ],
    sort: {
      name: 'project_id',
      exp: 'asc',
    },
  })
    .then((res) => {
      const filteredAssocProjects = []

      for (const i of res.items) {
        filteredAssocProjects.push([i.getProjectName(), i.getProjectId()])
      }

      projectAssociation.value = Object.fromEntries(filteredAssocProjects)
    })
}

// const customMetricArr = ['Низкая', 'Умеренная', 'Высокая', 'Супер']
// const customMetricObj = {
//   'Низкая': 0,
//   'Умеренная': 1,
//   'Высокая': 2,
//   'Супер': 3,
// }

const getRGBComponents = (color: any) => {
  const r = color.substring(1, 3)
  const g = color.substring(3, 5)
  const b = color.substring(5, 7)

  return {
    R: parseInt(r, 16),
    G: parseInt(g, 16),
    B: parseInt(b, 16)
  }
}

const idealTextColor = (bgColor: any) => {
  const nThreshold = 105
  const components = getRGBComponents(bgColor)
  const bgDelta = (components.R * 0.299) + (components.G * 0.587) + (components.B * 0.114)

  return ((255 - bgDelta) < nThreshold) ? '#000000' : '#ffffff'
}

const tagsList = ref(false)
const tagUser = ref({
  ssoId: '',
  tags: []
})

const checkAllTags = (props) => {
  tagsList.value = true

  tagUser.value.ssoId = props.guid
  tagUser.value.tags = props.tags
}

const excelModel = ref()
const excelRef = ref()
const b64 = ref('')

const excelHandler = () => {
  excelRef.value.pickFiles()
}

const excelLoader = () => {
  const reader = new FileReader()
  reader.readAsDataURL(excelModel.value)

  reader.onload = () => {
    b64.value = reader.result.split(',')[1] as any

    store
      .dispatch('loadExcelAccounts', { b64: b64.value })
      .then(() => location.replace('/user-projects?data=personal'))
  }
}

return (_ctx: any,_cache: any) => {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_file = _resolveComponent("q-file")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", {
    ref_key: "rootRef",
    ref: rootRef,
    class: "relative-position",
    style: {"overflow-x":"hidden","overflow-y":"hidden"}
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_q_checkbox, {
          modelValue: filtersCheck.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filtersCheck).value = $event)),
          label: "Для всех выбранных"
        }, null, 8, ["modelValue"]),
        _createVNode(ProjectSelect, {
          style: {"max-width":"350px"},
          "load-actions": false,
          "project-association": projectAssociation.value
        }, null, 8, ["project-association"]),
        _createVNode(UsersSearchInput, {
          onClear: clearSearch,
          onRequestTable: _cache[1] || (_cache[1] = (guid) => {
          singleSearchFilter.value = guid
          clearListSearch()
          loadAccountsItems(true)
        }
          )
        }),
        (_unref(route).query.data !== 'personal')
          ? (_openBlock(), _createBlock(_component_q_btn, {
              key: 0,
              color: "primary",
              label: userSearchInputLabel.value,
              onClick: userListSearchBtn,
              disable: isAccountsBusy.value
            }, null, 8, ["label", "disable"]))
          : (_openBlock(), _createBlock(_component_q_btn, {
              key: 1,
              color: "primary",
              label: "сбросить поиск",
              href: "/user-projects",
              disable: isAccountsBusy.value
            }, null, 8, ["disable"])),
        _createVNode(_component_q_dialog, {
          modelValue: isUserSearchShow.value,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((isUserSearchShow).value = $event)),
          "full-width": "",
          position: "bottom"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_q_card_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_input, {
                      modelValue: userSearchInput.value,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((userSearchInput).value = $event)),
                      filled: "",
                      type: "textarea",
                      "error-message": userSearchInputErr.value,
                      error: !!userSearchInputErr.value.length,
                      onFocus: _cache[3] || (_cache[3] = ($event: any) => (userSearchInputErr.value = ''))
                    }, null, 8, ["modelValue", "error-message", "error"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_q_card_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_btn, {
                      color: "primary",
                      label: "Найти",
                      onClick: longUserSearch
                    }),
                    _createVNode(_component_q_btn, {
                      class: "q-ml-md",
                      color: "secondary",
                      label: "поиск из файла",
                      onClick: excelHandler
                    }),
                    _createVNode(_component_q_file, {
                      modelValue: excelModel.value,
                      "onUpdate:modelValue": [
                        _cache[4] || (_cache[4] = ($event: any) => ((excelModel).value = $event)),
                        excelLoader
                      ],
                      ref_key: "excelRef",
                      ref: excelRef,
                      style: {"display":"none"},
                      accept: ".xlsx, .xls"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_q_btn, {
          label: sideFilterLabel.value,
          color: "primary",
          onClick: _unref(toggleSideFilters),
          disable: isAccountsBusy.value
        }, null, 8, ["label", "onClick", "disable"]),
        _createVNode(_component_q_space),
        _createVNode(ExportButtonUsers, {
          filter: compFilter.value,
          "disable-prop": isAccountsBusy.value,
          "query-status": _unref(route).query.data === 'personal'
        }, null, 8, ["filter", "disable-prop", "query-status"])
      ]),
      _createVNode(_component_q_dialog, {
        modelValue: tagsList.value,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((tagsList).value = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card, {
            class: "q-pa-lg relative-position",
            style: {"max-width":"800px"}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, "Все теги пользователя " + _toDisplayString(tagUser.value.ssoId), 1),
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tagUser.value.tags, (tag) => {
                  return (_openBlock(), _createBlock(_component_q_badge, {
                    rounded: "",
                    style: _normalizeStyle([{"padding":"6px 10px","font-size":"14px"}, { background: JSON.parse(tag).tag_color, color: idealTextColor(JSON.parse(tag).tag_color) }]),
                    key: JSON.parse(tag).tag_id
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(JSON.parse(tag).tag_name), 1)
                    ]),
                    _: 2
                  }, 1032, ["style"]))
                }), 128))
              ]),
              _createVNode(_component_q_btn, {
                flat: "",
                dense: "",
                round: "",
                icon: "mdi-close",
                color: "red",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (tagsList.value = false)),
                class: "absolute",
                style: {"right":"0","top":"0"}
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createElementVNode("div", {
        ref_key: "userTableRef",
        ref: userTableRef,
        class: "user-projects__user-table"
      }, [
        (getUserList.value)
          ? (_openBlock(), _createBlock(_component_q_table, {
              key: 0,
              class: "user-table-header",
              columns: rawTableColumns,
              rows: getUserList.value,
              loading: isAccountsBusy.value,
              "rows-per-page-options": [50, 100],
              "binary-state-sort": "",
              pagination: tablePagination.value,
              "onUpdate:pagination": _cache[8] || (_cache[8] = ($event: any) => ((tablePagination).value = $event)),
              "row-key": "guid",
              separator: "horizontal",
              style: {"height":"100%"},
              onRequest: onRequestResult
            }, {
              header: _withCtx((props) => [
                _createVNode(_component_q_tr, { props: props }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                      return (_openBlock(), _createBlock(_component_q_th, {
                        key: col.name,
                        props: props,
                        class: "text-left"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(col.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              body: _withCtx((props) => [
                _createVNode(_component_q_tr, { props: props }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_td, {
                      key: "a.account_guid",
                      props: props,
                      onClick: ($event: any) => (openUserProfile(props.row.accGuid)),
                      style: {"cursor":"pointer"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.guid), 1)
                      ]),
                      _: 2
                    }, 1032, ["props", "onClick"]),
                    _createVNode(_component_q_td, {
                      key: "is_ag+is_ed+is_gi+is_gz+is_ng+is_mp",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                          _createTextVNode(_toDisplayString(props.row.project_count) + " ", 1),
                          (!props.row.projectsWarning)
                            ? (_openBlock(), _createBlock(_component_q_spinner, {
                                key: 0,
                                color: "primary",
                                size: "1em"
                              }))
                            : _createCommentVNode("", true),
                          (
                    props.row.projectsWarning &&
                    props.row.projectsWarning?.length > 0
                  )
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createVNode(_component_q_icon, {
                                  name: "fas fa-info-circle",
                                  color: "teal",
                                  class: "table__warning-icon",
                                  size: "16px"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_tooltip, { class: "table__tooltip" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_list, {
                                          bordered: "",
                                          dense: "",
                                          separator: "",
                                          class: "table__popup-list"
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.projectsWarning, (item) => {
                                              return (_openBlock(), _createBlock(_component_q_item, { key: item }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_q_item_section, null, {
                                                    default: _withCtx(() => [
                                                      _createElementVNode("div", _hoisted_7, [
                                                        _createElementVNode("span", _hoisted_8, _toDisplayString(item.projectName + ': '), 1),
                                                        _createElementVNode("b", _hoisted_9, _toDisplayString(item.regDate), 1)
                                                      ])
                                                    ]),
                                                    _: 2
                                                  }, 1024)
                                                ]),
                                                _: 2
                                              }, 1024))
                                            }), 128))
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "activity",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_badge, {
                          rounded: "",
                          style: {"padding":"6px 10px","font-size":"14px"},
                          color: props.row.activity == 'Супер'
                  ? 'loyal'
                  : props.row.activity == 'Высокая'
                    ? 'yellow-8'
                    : props.row.activity == 'Умеренная'
                      ? 'cyan'
                      : props.row.activity == 'Не заполнено'
                        ? 'neutral'
                        : 'unloyal'
                  ,
                          "text-color": props.row.activity == 'Не заполнено'
                    ? 'black'
                    : 'white'
                    
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(props.row.activity), 1)
                          ]),
                          _: 2
                        }, 1032, ["color", "text-color"])
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "group_loyalty",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_badge, {
                          rounded: "",
                          style: {"padding":"6px 10px","font-size":"14px"},
                          color: props.row.group_loyalty == 'Супер'
                  ? 'loyal'
                  : props.row.group_loyalty == 'Высокая'
                    ? 'yellow-8'
                    : props.row.group_loyalty == 'Умеренная'
                      ? 'cyan'
                      : props.row.group_loyalty == 'Не заполнено'
                        ? 'neutral'
                        : 'unloyal'
                  ,
                          "text-color": props.row.group_loyalty == 'Не заполнено'
                    ? 'black'
                    : 'white'
                    
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(props.row.group_loyalty), 1)
                          ]),
                          _: 2
                        }, 1032, ["color", "text-color"])
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "group_usefulness",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_badge, {
                          rounded: "",
                          style: {"padding":"6px 10px","font-size":"14px"},
                          color: props.row.group_usefulness == 'Супер'
                  ? 'loyal'
                  : props.row.group_usefulness == 'Высокая'
                    ? 'yellow-8'
                    : props.row.group_usefulness == 'Умеренная'
                      ? 'cyan'
                      : props.row.group_usefulness == 'Не заполнено'
                        ? 'neutral'
                        : 'unloyal'
                  ,
                          "text-color": props.row.group_usefulness == 'Не заполнено'
                    ? 'black'
                    : 'white'
                    
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(props.row.group_usefulness), 1)
                          ]),
                          _: 2
                        }, 1032, ["color", "text-color"])
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "group_ofinterest",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_badge, {
                          rounded: "",
                          style: {"padding":"6px 10px","font-size":"14px"},
                          color: props.row.group_ofinterest == 'Супер'
                  ? 'loyal'
                  : props.row.group_ofinterest == 'Высокая'
                    ? 'yellow-8'
                    : props.row.group_ofinterest == 'Умеренная'
                      ? 'cyan'
                      : props.row.group_ofinterest == 'Не заполнено'
                        ? 'neutral'
                        : 'unloyal'
                  ,
                          "text-color": props.row.group_ofinterest == 'Не заполнено'
                    ? 'black'
                    : 'white'
                    
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(props.row.group_ofinterest), 1)
                          ]),
                          _: 2
                        }, 1032, ["color", "text-color"])
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "group_message",
                      class: "row items-center",
                      props: props,
                      style: {"gap":"5px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_badge, {
                          rounded: "",
                          style: {"padding":"6px 10px","font-size":"14px"},
                          color: props.row.group_message == 'Нейтральная'
                  ? 'neutral'
                  : 'unloyal'
                  ,
                          "text-color": props.row.group_message == 'Нейтральная'
                    ? 'black'
                    : 'white'
                    
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(props.row.group_message), 1)
                          ]),
                          _: 2
                        }, 1032, ["color", "text-color"]),
                        (
                  props.row.class_message &&
                  props.row.class_message?.length > 0
                )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                              _createVNode(_component_q_icon, {
                                name: "fas fa-info-circle",
                                color: "teal",
                                class: "table__warning-icon",
                                size: "16px"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_tooltip, { class: "table__tooltip" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_list, {
                                        bordered: "",
                                        dense: "",
                                        separator: "",
                                        class: "table__popup-list"
                                      }, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.class_message, (item) => {
                                            return (_openBlock(), _createBlock(_component_q_item, { key: item }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_q_item_section, null, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("div", _hoisted_11, [
                                                      _createElementVNode("b", _hoisted_12, _toDisplayString(item), 1)
                                                    ])
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          }), 128))
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "tags",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        (props.row.tags.length)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.tags.slice(0, 2), (tag) => {
                                return (_openBlock(), _createBlock(_component_q_badge, {
                                  rounded: "",
                                  style: _normalizeStyle({ background: JSON.parse(tag).tag_color, color: idealTextColor(JSON.parse(tag).tag_color) }),
                                  key: JSON.parse(tag).tag_id,
                                  title: JSON.parse(tag).tag_name
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_14, _toDisplayString(JSON.parse(tag).tag_name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["style", "title"]))
                              }), 128)),
                              (props.row.tags.length > 2)
                                ? (_openBlock(), _createBlock(_component_q_btn, {
                                    key: 0,
                                    flat: "",
                                    dense: "",
                                    round: "",
                                    icon: "mdi-eye",
                                    color: "primary",
                                    onClick: ($event: any) => (checkAllTags(props.row))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_tooltip, null, {
                                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                                          _createElementVNode("div", { class: "text-subtitle2" }, " Показать все теги ", -1)
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                : _createCommentVNode("", true)
                            ]))
                          : (_openBlock(), _createBlock(_component_q_badge, {
                              key: 1,
                              rounded: "",
                              style: {"padding":"6px 10px","font-size":"14px"},
                              color: "neutral",
                              "text-color": "black"
                            }, {
                              default: _withCtx(() => _cache[11] || (_cache[11] = [
                                _createTextVNode(" Не заданы ")
                              ])),
                              _: 1
                            }))
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "type_descr",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.type_descr), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "cnt_activity",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.cnt_activity.toLocaleString()), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "first_activity_date",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.first_activity_date), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "last_activity_date",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.last_activity_date), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "points_earned_city",
                      props: props,
                      onClick: ($event: any) => (openModal('earned_city', props.row.guid)),
                      style: {"cursor":"pointer"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.points_earned_city.toLocaleString()), 1)
                      ]),
                      _: 2
                    }, 1032, ["props", "onClick"]),
                    _createVNode(_component_q_td, {
                      key: "points_spent_city",
                      props: props,
                      onClick: ($event: any) => (openModal('spent_city', props.row.guid)),
                      style: {"cursor":"pointer"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.points_spent_city.toLocaleString()), 1)
                      ]),
                      _: 2
                    }, 1032, ["props", "onClick"]),
                    _createVNode(_component_q_td, {
                      key: "points_city",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.points_city.toLocaleString()), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "points_earned_prize",
                      props: props,
                      onClick: ($event: any) => (openModal('earned_prize', props.row.guid)),
                      style: {"cursor":"pointer"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.points_earned_prize.toLocaleString()), 1)
                      ]),
                      _: 2
                    }, 1032, ["props", "onClick"]),
                    _createVNode(_component_q_td, {
                      key: "points_spent_prize",
                      props: props,
                      onClick: ($event: any) => (openModal('spent_prize', props.row.guid)),
                      style: {"cursor":"pointer"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.points_spent_prize.toLocaleString()), 1)
                      ]),
                      _: 2
                    }, 1032, ["props", "onClick"]),
                    _createVNode(_component_q_td, {
                      key: "points_prize",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.points_prize.toLocaleString()), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "current_balance",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.current_balance.toLocaleString()), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "a.sex",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_15, [
                          (
                    accountsChecksMap.value && accountsChecksMap.value[props.row.guid]
                  )
                            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                _createTextVNode(_toDisplayString(accountsChecksMap.value[props.row.guid].sex.name == 'Не указан'
                        ? props.row.sex
                          ? props.row.sex
                          : 'Не указан'
                        : accountsChecksMap.value[props.row.guid].sex.name) + " ", 1),
                                (
                      (accountsChecksMap.value &&
                        !accountsChecksMap.value[props.row.guid]?.sex) ||
                      !accountsChecksMap.value[props.row.guid].sex.name
                    )
                                  ? (_openBlock(), _createBlock(_component_q_spinner, {
                                      key: 0,
                                      color: "primary",
                                      size: "1em"
                                    }))
                                  : _createCommentVNode("", true),
                                (
                      accountsChecksMap.value &&
                      !accountsChecksMap.value[props.row.guid]?.sex?.flag &&
                      accountsChecksMap.value[props.row.guid]?.sex?.items?.length >
                      0
                    )
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                      _createVNode(_component_q_icon, {
                                        name: "warning",
                                        color: "yellow-8",
                                        class: "table__warning-icon",
                                        size: "16px"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_tooltip, { class: "table__tooltip" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_q_list, {
                                                bordered: "",
                                                dense: "",
                                                separator: "",
                                                class: "table__popup-list q-px-sm"
                                              }, {
                                                default: _withCtx(() => [
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(accountsChecksMap.value[props.row.guid]
                              .sex.items, (item) => {
                                                    return (_openBlock(), _createBlock(_component_q_item, { key: item }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_q_item_section, null, {
                                                          default: _withCtx(() => [
                                                            _createElementVNode("div", _hoisted_18, [
                                                              _createElementVNode("span", _hoisted_19, _toDisplayString(item.getName() + ':'), 1),
                                                              _createElementVNode("b", _hoisted_20, _toDisplayString(item.getValue() == 'Не указано'
                                        ? 'Не указан'
                                        : item.getValue()), 1)
                                                            ])
                                                          ]),
                                                          _: 2
                                                        }, 1024)
                                                      ]),
                                                      _: 2
                                                    }, 1024))
                                                  }), 128))
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]))
                                  : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "a.birthday",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_21, [
                          _createElementVNode("div", _hoisted_22, [
                            _createTextVNode(_toDisplayString(props.row.birth) + " ", 1),
                            (
                      accountsChecksMap.value &&
                      !accountsChecksMap.value[props.row.guid]?.birth
                    )
                              ? (_openBlock(), _createBlock(_component_q_spinner, {
                                  key: 0,
                                  color: "primary",
                                  size: "1em"
                                }))
                              : _createCommentVNode("", true),
                            (
                      accountsChecksMap.value &&
                      !accountsChecksMap.value[props.row.guid]?.birth?.flag &&
                      accountsChecksMap.value[props.row.guid]?.birth?.items
                        ?.length > 0
                    )
                              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                  _createVNode(_component_q_icon, {
                                    name: "warning",
                                    color: "yellow-8",
                                    class: "table__warning-icon",
                                    size: "16px"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_tooltip, { class: "table__tooltip" }, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(accountsChecksMap.value[props.row.guid]
                            .birth.items, (item) => {
                                            return (_openBlock(), _createBlock(_component_q_list, {
                                              bordered: "",
                                              dense: "",
                                              separator: "",
                                              class: "table__popup-list q-px-sm",
                                              key: item
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_q_item_section, null, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("div", _hoisted_24, [
                                                      _createElementVNode("span", _hoisted_25, _toDisplayString(item.getName() + ':'), 1),
                                                      _createElementVNode("b", _hoisted_26, _toDisplayString((_unref(date).isValid(
                                      new Date(
                                        Date.parse(item?.getValue())
                                      ).toDateString()
                                    ) &&
                                      _unref(date).formatDate(
                                        new Date(
                                          Date.parse(item?.getValue())
                                        ).toDateString(),
                                        'YYYY-MM-DD'
                                      )) ||
                                    'Не заполнено'), 1)
                                                    ])
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          }), 128))
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "a.family_status",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_27, [
                          _createElementVNode("div", _hoisted_28, [
                            _createTextVNode(_toDisplayString(props.row.family_status) + " ", 1),
                            (
                      accountsChecksMap.value &&
                      !accountsChecksMap.value[props.row.guid]?.family
                    )
                              ? (_openBlock(), _createBlock(_component_q_spinner, {
                                  key: 0,
                                  color: "primary",
                                  size: "1em"
                                }))
                              : _createCommentVNode("", true),
                            (
                      accountsChecksMap.value &&
                      !accountsChecksMap.value[props.row.guid]?.family?.flag &&
                      accountsChecksMap.value[props.row.guid]?.family?.items
                        ?.length > 0
                    )
                              ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                                  _createVNode(_component_q_icon, {
                                    name: "warning",
                                    color: "yellow-8",
                                    class: "table__warning-icon",
                                    size: "16px"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_tooltip, { class: "table__tooltip" }, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(accountsChecksMap.value[props.row.guid]
                            .family.items, (item) => {
                                            return (_openBlock(), _createBlock(_component_q_list, {
                                              bordered: "",
                                              dense: "",
                                              separator: "",
                                              class: "table__popup-list q-px-sm",
                                              key: item
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_q_item_section, null, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("div", _hoisted_30, [
                                                      _createElementVNode("span", _hoisted_31, _toDisplayString(item.getName() + ':'), 1),
                                                      _createElementVNode("b", _hoisted_32, _toDisplayString(item.getValue()
                                      ? item.getValue()
                                      : 'Не заполнено'), 1)
                                                    ])
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          }), 128))
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "num_child",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.num_child), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "a.district",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_33, [
                          _createElementVNode("div", _hoisted_34, [
                            _createTextVNode(_toDisplayString(props.row.district) + " ", 1),
                            (
                      accountsChecksMap.value &&
                      !accountsChecksMap.value[props.row.guid]?.district
                    )
                              ? (_openBlock(), _createBlock(_component_q_spinner, {
                                  key: 0,
                                  color: "primary",
                                  size: "1em"
                                }))
                              : _createCommentVNode("", true),
                            (
                      accountsChecksMap.value &&
                      !accountsChecksMap.value[props.row.guid]?.district?.flag &&
                      accountsChecksMap.value[props.row.guid]?.district?.items
                        ?.length > 0
                    )
                              ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                                  _createVNode(_component_q_icon, {
                                    name: "warning",
                                    color: "yellow-8",
                                    class: "table__warning-icon",
                                    size: "16px"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_tooltip, { class: "table__tooltip" }, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(accountsChecksMap.value[props.row.guid]
                            .district.items, (item) => {
                                            return (_openBlock(), _createBlock(_component_q_list, {
                                              bordered: "",
                                              dense: "",
                                              separator: "",
                                              class: "table__popup-list q-px-sm",
                                              key: item
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_q_item_section, null, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("div", _hoisted_36, [
                                                      _createElementVNode("span", _hoisted_37, _toDisplayString(item.getName() + ':'), 1),
                                                      _createElementVNode("b", _hoisted_38, _toDisplayString(item.getValue()
                                      ? item.getValue()
                                      : 'Не заполнено'), 1)
                                                    ])
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          }), 128))
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "a.region",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_39, [
                          _createElementVNode("div", _hoisted_40, [
                            _createTextVNode(_toDisplayString(props.row.region) + " ", 1),
                            (
                      accountsChecksMap.value &&
                      !accountsChecksMap.value[props.row.guid]?.region
                    )
                              ? (_openBlock(), _createBlock(_component_q_spinner, {
                                  key: 0,
                                  color: "primary",
                                  size: "1em"
                                }))
                              : _createCommentVNode("", true),
                            (
                      accountsChecksMap.value &&
                      !accountsChecksMap.value[props.row.guid]?.region?.flag &&
                      accountsChecksMap.value[props.row.guid]?.region?.items
                        ?.length > 0
                    )
                              ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                                  _createVNode(_component_q_icon, {
                                    name: "warning",
                                    color: "yellow-8",
                                    class: "table__warning-icon",
                                    size: "16px"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_tooltip, { class: "table__tooltip" }, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(accountsChecksMap.value[props.row.guid]
                            .region.items, (item) => {
                                            return (_openBlock(), _createBlock(_component_q_list, {
                                              bordered: "",
                                              dense: "",
                                              separator: "",
                                              class: "table__popup-list q-px-sm",
                                              key: item
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_q_item_section, null, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("div", _hoisted_42, [
                                                      _createElementVNode("span", _hoisted_43, _toDisplayString(item.getName() + ':'), 1),
                                                      _createElementVNode("b", _hoisted_44, _toDisplayString(item.getValue()
                                      ? item.getValue()
                                      : 'Не заполнено'), 1)
                                                    ])
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          }), 128))
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "a.address",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_45, [
                          _createElementVNode("div", _hoisted_46, [
                            _createTextVNode(_toDisplayString(props.row.address) + " ", 1),
                            (
                      accountsChecksMap.value &&
                      !accountsChecksMap.value[props.row.guid]?.address
                    )
                              ? (_openBlock(), _createBlock(_component_q_spinner, {
                                  key: 0,
                                  color: "primary",
                                  size: "1em"
                                }))
                              : _createCommentVNode("", true),
                            (
                      accountsChecksMap.value &&
                      !accountsChecksMap.value[props.row.guid]?.address?.flag &&
                      accountsChecksMap.value[props.row.guid]?.address?.items
                        ?.length > 0
                    )
                              ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                                  _createVNode(_component_q_icon, {
                                    name: "warning",
                                    color: "yellow-8",
                                    class: "table__warning-icon",
                                    size: "16px"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_tooltip, { class: "table__tooltip" }, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(accountsChecksMap.value[props.row.guid]
                            .address.items, (item) => {
                                            return (_openBlock(), _createBlock(_component_q_list, {
                                              bordered: "",
                                              dense: "",
                                              separator: "",
                                              class: "table__popup-list q-px-sm",
                                              key: item
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_q_item_section, null, {
                                                  default: _withCtx(() => [
                                                    _createElementVNode("div", _hoisted_48, [
                                                      _createElementVNode("span", _hoisted_49, _toDisplayString(item.getName() + ':'), 1),
                                                      _createElementVNode("b", _hoisted_50, _toDisplayString(item.getValue()
                                      ? item.getValue()
                                      : 'Не заполнено'), 1)
                                                    ])
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          }), 128))
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "social",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.social), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "is_blocked",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.is_blocked), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"])
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              pagination: _withCtx((scope) => [
                _createTextVNode(_toDisplayString((scope.pagination.rowsPerPage * scope.pagination.page - scope.pagination.rowsPerPage +
              1).toLocaleString()) + " - " + _toDisplayString(scope.pagination.rowsPerPage * scope.pagination.page > scope.pagination.rowsNumber
              ? scope.pagination.rowsNumber.toLocaleString()
              : (scope.pagination.rowsPerPage * scope.pagination.page).toLocaleString()) + " из " + _toDisplayString(scope.pagination.rowsNumber.toLocaleString()) + " ", 1),
                (scope.pagesNumber > 2)
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 0,
                      icon: "first_page",
                      color: "grey-8",
                      round: "",
                      dense: "",
                      flat: "",
                      disable: scope.isFirstPage,
                      onClick: scope.firstPage,
                      class: "q-ml-md"
                    }, null, 8, ["disable", "onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_q_btn, {
                  icon: "chevron_left",
                  color: "grey-8",
                  round: "",
                  dense: "",
                  flat: "",
                  disable: scope.isFirstPage,
                  onClick: scope.prevPage
                }, null, 8, ["disable", "onClick"]),
                _createVNode(_component_q_btn, {
                  icon: "chevron_right",
                  color: "grey-8",
                  round: "",
                  dense: "",
                  flat: "",
                  disable: scope.isLastPage,
                  onClick: scope.nextPage
                }, null, 8, ["disable", "onClick"]),
                (scope.pagesNumber > 2)
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 1,
                      icon: "last_page",
                      color: "grey-8",
                      round: "",
                      dense: "",
                      flat: "",
                      disable: scope.isLastPage,
                      onClick: scope.lastPage
                    }, null, 8, ["disable", "onClick"]))
                  : _createCommentVNode("", true)
              ]),
              loading: _withCtx(() => [
                _createVNode(_component_q_inner_loading, {
                  showing: true,
                  style: {"z-index":"1"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_spinner, {
                      color: "grey",
                      size: "3em"
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["rows", "loading", "pagination"]))
          : _createCommentVNode("", true)
      ], 512),
      _createVNode(_component_q_dialog, {
        modelValue: isShowModal.value,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((isShowModal).value = $event)),
        "full-width": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card, { class: "user-projects__modal" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, null, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(modalComponent.value), {
                    guid: modalGuid.value,
                    clearProjects: true
                  }, null, 8, ["guid"]))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createElementVNode("div", {
      ref_key: "filtersRef",
      ref: filtersRef,
      class: "side-filters"
    }, [
      _createVNode(AsideFilters, { onOnFilters: sideFiltersFn })
    ], 512)
  ], 512))
}
}

})