import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import {ref, watch, toRaw} from 'vue'
import { onClickOutside } from '@vueuse/core'
import store from '@/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'MetricComponent',
  props: {
    options: {
        type: Object,
        default: () => {},
    }
},
  setup(__props) {

const props = __props

const filterInput = ref('')
// const selectedMetrics: any = ref([])
const isDropdownOpen = ref(false)
const selectedNodes: any = ref([])
const projectSelectRef = ref()
const options: any = ref([])
const label = ref('')

watch(
    () => props.options,
    (items: any) => {
        const treeNodes: any = [
            {
                label: 'Все метрики',
                children: [],
            },
        ]
        let arr = items.getItemsList()
        console.log('Metric Watcher', items)
        //
        label.value = items.getMetricName()
        // selectedMetrics.value.push(selectedNodes.value)
        //
        for (let i in arr) {
            treeNodes[0].children.push({
                label: arr[i].getMetricValueName(),
                value: arr[i].getMetricValueId(),
            })
        }
        //
        //
        options.value = treeNodes
    },
    {
        immediate: true,
    }
)

const onTicked = (value: any) => {
    console.log('UPD VALUE', value)

    selectedNodes.value.length ? filterInput.value = 'Выбрано групп: (' + selectedNodes.value.length + ')' : filterInput.value = ''

    store.dispatch('setCheckedMetric', [label.value, value])

    const metricList = store.getters.getMetricChecked
  console.log('METRICLIST', toRaw(metricList))
}

// const onSelected = (projects: any) => {
//   let projectsIds: any = []
//
//   for (const item of projects) {
//     projectsIds.push(projectAssociation[item])
//   }
//   if (projectsIds.length >= 6) {
//     selectedMetrics.value = ''
//   } else {
//     selectedMetrics.value = projects.join(', ')
//   }
//   store.dispatch('setSelectedProjectsItems', projectsIds).then(() => {
//     if (props.loadActions) {
//       store.dispatch('getActionsList', {
//         filter: {
//           sso_id: getAccountSsoId.value,
//           project_ids: projectsIds.length ? projectsIds : [1, 2, 3, 4, 5, 6],
//         },
//       })
//     }
//   })
//   // .then(() => store.dispatch('setSelectedEvents', []))
// }

const showDropDown = (value: boolean) => {
    isDropdownOpen.value = value
}

onClickOutside(projectSelectRef, () => {
   showDropDown(false)
})

// watch(getProjectItems, () => {
//   selectedNodes.value = []
//
//   if (getProjectItems.value[0].children.length <= 1) {
//     selectedNodes.value = []
//     const label = getProjectItems?.value[0]?.children[0]?.label
//     selectedMetrics.value = label
//     if (getProjectItems.value[0].children[0]) {
//       getProjectItems.value[0].children[0].disabled = true
//       getProjectItems.value[0].disabled = true
//     }
//     if (label) {
//       selectedNodes.value.push(getProjectItems.value[0].children[0].label)
//     }
//     store.dispatch('setSelectedProjectsItems', projectAssociation[label])
//   }
// })

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_tree = _resolveComponent("q-tree")!

  return (label.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "project-select-tree col",
        ref_key: "projectSelectRef",
        ref: projectSelectRef,
        onClick: _cache[2] || (_cache[2] = ($event: any) => (isDropdownOpen.value = !isDropdownOpen.value))
      }, [
        _createVNode(_component_q_input, {
          modelValue: filterInput.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filterInput).value = $event)),
          filled: "",
          label: label.value,
          "hide-bottom-space": "",
          clearable: "",
          readonly: ""
        }, {
          append: _withCtx(() => [
            _createVNode(_component_q_icon, {
              name: 'fas fa-caret-down',
              style: _normalizeStyle([isDropdownOpen.value ? 'transform:rotate(180deg)' : 'transform:rotate(0deg)', {"transition":"0.3s"}])
            }, null, 8, ["style"])
          ]),
          _: 1
        }, 8, ["modelValue", "label"]),
        (isDropdownOpen.value)
          ? (_openBlock(), _createElementBlock("div", _mergeProps({
              key: 0,
              class: "select-tree-dropdown"
            }, _ctx.$attrs, { style: {"margin-left":"0"} }), [
              _createVNode(_component_q_tree, {
                nodes: options.value,
                "node-key": "value",
                "tick-strategy": "leaf",
                "default-expand-all": "",
                ticked: selectedNodes.value,
                "onUpdate:ticked": [
                  _cache[1] || (_cache[1] = ($event: any) => ((selectedNodes).value = $event)),
                  onTicked
                ]
              }, null, 8, ["nodes", "ticked"])
            ], 16))
          : _createCommentVNode("", true)
      ], 512))
    : _createCommentVNode("", true)
}
}

})