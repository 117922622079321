import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "row items-center no-wrap" }
const _hoisted_2 = { class: "row items-center q-ml-md no-wrap" }
const _hoisted_3 = { class: "column items-end text-white" }
const _hoisted_4 = { class: "text-subtitle2 text-weight-bold" }
const _hoisted_5 = { class: "row items-center justify-between q-gutter-sm" }
const _hoisted_6 = { style: {"font-size":"14px"} }
const _hoisted_7 = {
  class: "row items-center",
  style: {"gap":"5px"}
}
const _hoisted_8 = { style: {"font-size":"14px"} }
const _hoisted_9 = {
  key: 0,
  class: "nav"
}
const _hoisted_10 = { class: "row items-center" }
const _hoisted_11 = {
  key: 0,
  class: "self-center q-mr-md"
}
const _hoisted_12 = { class: "app-main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_btn_dropdown = _resolveComponent("q-btn-dropdown")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_header = _resolveComponent("q-header")!
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "lHh Lpr lFf" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_header, { elevated: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_q_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_toolbar_title, { style: {"display":"flex"} }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_router_link, {
                      to: _ctx.headerLinkTo[_ctx.headerLabel],
                      style: {"text-decoration":"none","color":"#fff"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.headerLabel), 1)
                      ]),
                      _: 1
                    }, 8, ["to"]),
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-subtitle2 text-weight-bold" }, " Актуальность данных: ", -1)),
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.dateUpdate), 1)
                      ]),
                      _createVNode(_component_q_icon, {
                        name: _ctx.updateStatus
                ? 'fas fa-check-circle'
                : 'fas fa-exclamation-circle'
                ,
                        color: _ctx.updateStatus ? 'green' : 'red',
                        class: "table__warning-icon q-ml-sm",
                        size: "20px",
                        style: {"color":"white"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_tooltip, {
                            style: {"background":"white"},
                            class: "shadow-4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_list, {
                                bordered: "",
                                dense: "",
                                separator: "",
                                class: "table__popup-list",
                                style: {"background":"white !important","color":"#1d1d1d"}
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productInfoItems, (item, idx) => {
                                    return (_openBlock(), _createBlock(_component_q_item, { key: idx }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_item_section, null, {
                                          default: _withCtx(() => [
                                            _createElementVNode("div", _hoisted_5, [
                                              _createElementVNode("span", _hoisted_6, _toDisplayString(`${item.projectName}: `), 1),
                                              _createElementVNode("div", _hoisted_7, [
                                                _createElementVNode("b", _hoisted_8, _toDisplayString(item.dateUpdate), 1),
                                                _createVNode(_component_q_icon, {
                                                  name: item.isSuccess
                              ? 'fas fa-check-circle'
                              : 'fas fa-exclamation-circle'
                              ,
                                                  color: item.isSuccess ? 'green' : 'red',
                                                  class: "table__warning-icon q-ml-sm",
                                                  size: "20px",
                                                  style: {"color":"white"}
                                                }, null, 8, ["name", "color"])
                                              ])
                                            ])
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  }), 128))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["name", "color"])
                    ])
                  ]),
                  (_ctx.$route.path != '/')
                    ? (_openBlock(), _createElementBlock("nav", _hoisted_9, [
                        _createVNode(_component_q_btn, {
                          href: "/user-projects",
                          stretch: "",
                          flat: "",
                          label: "Пользователи"
                        }),
                        _createVNode(_component_q_btn, {
                          to: "/user-digital-project",
                          stretch: "",
                          flat: "",
                          label: "Проекты"
                        }),
                        _createVNode(_component_q_btn, {
                          to: "/intersection",
                          stretch: "",
                          flat: "",
                          label: "Сводная аналитика"
                        }),
                        _createVNode(_component_q_btn, {
                          to: "/user-loyalty",
                          stretch: "",
                          flat: "",
                          label: "Сегментация аудитории"
                        }),
                        _createVNode(_component_q_btn_dropdown, {
                          stretch: "",
                          flat: "",
                          label: "Дополнительно"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_list, null, {
                              default: _withCtx(() => [
                                (_ctx.userRole === 'admin')
                                  ? (_openBlock(), _createBlock(_component_q_item, { key: 0 }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_btn, {
                                          style: {"width":"100%","align-items":"flex-start"},
                                          to: "/users/manage",
                                          stretch: "",
                                          flat: "",
                                          label: "Системные пользователи"
                                        })
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_q_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_btn, {
                                      style: {"width":"100%","align-items":"flex-start"},
                                      to: "/nsi",
                                      stretch: "",
                                      flat: "",
                                      label: "НСИ"
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_q_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_btn, {
                                      style: {"width":"100%","align-items":"flex-start"},
                                      to: "/cls",
                                      stretch: "",
                                      flat: "",
                                      label: "Сообщения пользователей"
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_q_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_btn, {
                                      style: {"width":"100%","align-items":"flex-start"},
                                      to: "/reference",
                                      stretch: "",
                                      flat: "",
                                      label: "Справка"
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_q_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_btn, {
                                      style: {"width":"100%","align-items":"flex-start"},
                                      to: "/reports",
                                      stretch: "",
                                      flat: "",
                                      label: "Отчеты"
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_q_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_btn, {
                                      style: {"width":"100%","align-items":"flex-start"},
                                      to: "/tags",
                                      stretch: "",
                                      flat: "",
                                      label: "Присвоение тегов"
                                    })
                                  ]),
                                  _: 1
                                }),
                                (_ctx.userRole === 'admin')
                                  ? (_openBlock(), _createBlock(_component_q_item, { key: 1 }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_btn, {
                                          style: {"width":"100%","align-items":"flex-start"},
                                          to: "/sql-builder",
                                          stretch: "",
                                          flat: "",
                                          label: "Конструктор запросов"
                                        })
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_q_item, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_btn, {
                                      style: {"width":"100%","align-items":"flex-start"},
                                      to: "/recommend-system",
                                      stretch: "",
                                      flat: "",
                                      label: "Рекомендательная система"
                                    })
                                  ]),
                                  _: 1
                                }),
                                (_ctx.userRole === 'admin')
                                  ? (_openBlock(), _createBlock(_component_q_item, { key: 2 }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_btn, {
                                          style: {"width":"100%","align-items":"flex-start"},
                                          to: "/task-builder",
                                          stretch: "",
                                          flat: "",
                                          label: "Конструктор ручных заданий"
                                        })
                                      ]),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]))
                    : (_openBlock(), _createBlock(_component_q_space, { key: 1 })),
                  _createElementVNode("div", _hoisted_10, [
                    (_ctx.userData)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.userData[1] ? _ctx.userData[1] : "") + " " + _toDisplayString(_ctx.userData[0] ? _ctx.userData[0].split("")[0] + "." : "") + " " + _toDisplayString(_ctx.userData[2] ? _ctx.userData[2].split("")[0] + "." : ""), 1))
                      : _createCommentVNode("", true),
                    _createVNode(_component_q_btn, {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickLogout())),
                      color: "white",
                      "text-color": "black",
                      label: "Выход",
                      icon: "logout",
                      "no-caps": "",
                      unelevated: ""
                    })
                  ])
                ]),
                _: 1
              }),
              _cache[2] || (_cache[2] = _createElementVNode("div", null, null, -1))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_12, [
        (_ctx.$route.name != 'UserProfile' && _ctx.$route.name != 'IntersectionMain')
          ? (_openBlock(), _createBlock(_component_Breadcrumbs, { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component, route }) => [
            _createVNode(_Transition, {
              name: _ctx.transitionName,
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_KeepAlive, {
                  max: 1,
                  exclude: ['UserProjects', 'UserProfile']
                }, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                    key: route.path
                  }))
                ], 1024))
              ]),
              _: 2
            }, 1032, ["name"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}