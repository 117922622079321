export const useTextHighlighter = () => {
    const markTextRed = (search: string, subject: string) => {
        const escapeRegExp = function (str: string) {
            return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
        }

        return subject.replace(
            new RegExp('(' + escapeRegExp(search) + ')', 'gi'),
            "<span style='color: red;'><b>$1</b></span>"
        )
    }

    return {
        markedFindValue: (text: string, strArr?: string[], searchValue?: string) => {
            if (strArr && strArr.length) {
                const marked = JSON.parse(JSON.stringify(strArr))

                if (marked && marked.length) {
                    marked.sort((a: string, b: string) => b.length - a.length)
                    marked.forEach(
                        (item: string) => (text = markTextRed(item, text))
                    )
                }
            }

            if (searchValue) text = markTextRed(searchValue, text)

            return text
        }
    }
}