import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'QueryRequest',
  props: ['result'],
  setup(__props) {

const props = __props

const sqlRequestResult = ref(props.result)

return (_ctx: any,_cache: any) => {
  const _component_q_card_section = _resolveComponent("q-card-section")!

  return (_openBlock(), _createBlock(_component_q_card_section, { class: "result" }, {
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(sqlRequestResult.value), 1)
    ]),
    _: 1
  }))
}
}

})