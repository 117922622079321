import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { style: {"padding":"5px 24px","max-height":"87vh"} }
const _hoisted_2 = {
  class: "row",
  style: {"max-height":"100%"}
}
const _hoisted_3 = { class: "col-5 q-pr-lg text-black relative-position" }
const _hoisted_4 = { class: "col-12 shadow-4" }
const _hoisted_5 = { class: "col-12 bg-white" }
const _hoisted_6 = {
  class: "row",
  style: {"width":"100%"}
}
const _hoisted_7 = {
  class: "col-xl-5 col-lg-5",
  style: {"padding-right":"5px"}
}
const _hoisted_8 = { style: {"padding":"0 8px 0 5px","height":"100%"} }
const _hoisted_9 = {
  class: "col-xl-5 col-lg-4",
  style: {"padding-right":"0px 5px"}
}
const _hoisted_10 = { style: {"padding-left":"10px","height":"100%"} }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "col-7 shadow-4 bg-white text-black relative-position" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_VkMessageChildren = _resolveComponent("VkMessageChildren")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_VkMessagePanel = _resolveComponent("VkMessagePanel")!
  const _component_VkMessageSource = _resolveComponent("VkMessageSource")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "text-h4 q-mt-sm q-mb-sm" }, " Обобщение ", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "text-h6 bg-white text-black text-weight-bold q-pa-sm" }, "Сообщения", -1)),
          _createVNode(_component_q_separator),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_q_table, {
              style: _normalizeStyle([{"height":"100%","overflow":"auto"}, _ctx.getMessageGrpResultItems.length ? 'height: 73vh' : null]),
              class: "sticky-header-table-answer",
              flat: "",
              dense: "",
              rows: _ctx.getMessageGrpResultItems,
              columns: _ctx.columnsResult,
              "row-key": "id",
              "wrap-cells": "",
              selection: "single",
              selected: _ctx.selectedResult,
              "onUpdate:selected": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedResult) = $event)),
              loading: _ctx.isMessageGrpResultBusy,
              pagination: _ctx.resultPagination,
              "onUpdate:pagination": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.resultPagination) = $event)),
              "rows-per-page-options": _ctx.rowsPerPageResultOptions,
              expanded: _ctx.expandedResult,
              "onUpdate:expanded": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.expandedResult) = $event)),
              onRequest: _ctx.onRequestResult
            }, {
              top: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_q_input, {
                      filled: "",
                      modelValue: _ctx.search,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                      label: "Текст для поиска",
                      onKeypress: _withKeys(_ctx.findMessagesResult, ["enter"])
                    }, null, 8, ["modelValue", "onKeypress"])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_q_btn, {
                      style: {"padding":"10px","margin-top":"6px","margin-right":"1px"},
                      "background-color": "gray-12",
                      icon: "mdi-magnify",
                      onClick: _ctx.findMessagesResult
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_tooltip, null, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode("Найти сообщения")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_q_select, {
                      filled: "",
                      modelValue: _ctx.processedSign,
                      "onUpdate:modelValue": [
                        _cache[1] || (_cache[1] = ($event: any) => ((_ctx.processedSign) = $event)),
                        _cache[2] || (_cache[2] = ($event: any) => {_ctx.changeProcessedSign($event);})
                      ],
                      options: _ctx.processedOptions,
                      "option-value": "id",
                      "option-label": "title",
                      label: "Признак обработки ответа"
                    }, null, 8, ["modelValue", "options"])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    ('admin editor'.includes(_ctx.userRole))
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          style: {"padding":"10px","margin-top":"6px","margin-right":"1px"},
                          "background-color": "gray-12",
                          icon: "mdi-pen-remove",
                          onClick: _ctx.separateTableChild,
                          disable: _ctx.getTableRefVar.length === 0
                        }, {
                          default: _withCtx(() => [
                            (_ctx.getTableRefVar.length !== 0)
                              ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                                    _createTextVNode("Разделить сообщения")
                                  ])),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            (_ctx.getTableRefVar.length === 0)
                              ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                                    _createTextVNode("Необходимо выбрать хотя бы 1 обобщенный ответ")
                                  ])),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["onClick", "disable"]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              header: _withCtx((props) => [
                _createVNode(_component_q_tr, { props: props }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_th, { "auto-width": "" }),
                    _createVNode(_component_q_th, {
                      key: "text",
                      props: props,
                      class: "text-left"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.cols[0].label), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_th, {
                      key: "sum",
                      props: props,
                      class: "text-center",
                      style: {"width":"120px"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.cols[1].label), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"])
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              body: _withCtx((props) => [
                (_openBlock(), _createBlock(_component_q_tr, {
                  key: props.rowIndex + '###primary###' + props.row.id,
                  props: props,
                  class: _normalizeClass(
                        props.row.processed ? 'message-processed' : ''
                      )
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_td, null, {
                      default: _withCtx(() => [
                        _withDirectives(_createVNode(_component_q_btn, {
                          size: "sm",
                          flat: "",
                          rounded: "",
                          dense: "",
                          icon: 
                            props.expand
                              ? 'fas fa-caret-down'
                              : 'fas fa-caret-right'
                          ,
                          onClick: ($event: any) => (_ctx.openChildTable(props))
                        }, null, 8, ["icon", "onClick"]), [
                          [_vShow, props.row.has_childs]
                        ])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_td, {
                      onClick: ($event: any) => (_ctx.onRowClickMessageResult(props.row, true))
                    }, {
                      default: _withCtx(() => [
                        (_ctx.markedFind.length)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              innerHTML: _ctx.markedAll(props.row.text)
                            }, null, 8, _hoisted_11))
                          : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(props.row.text), 1))
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_q_td, {
                      class: "text-center",
                      style: {"width":"120px"}
                    }, {
                      default: _withCtx(() => [
                        (props.row.has_childs)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                              _createElementVNode("span", {
                                style: {"cursor":"pointer"},
                                title: "Исходные сообщения",
                                onClick: ($event: any) => (_ctx.onRowShowMessageSource(props.row, true))
                              }, _toDisplayString(props.row.sum), 9, _hoisted_14),
                              _cache[10] || (_cache[10] = _createTextVNode(" | ")),
                              _createElementVNode("span", {
                                style: {"cursor":"pointer"},
                                title: "Исходные сообщения",
                                onClick: ($event: any) => (_ctx.onRowShowMessageSource(props.row, false))
                              }, _toDisplayString(props.row.count), 9, _hoisted_15)
                            ]))
                          : (_openBlock(), _createElementBlock("span", {
                              key: 1,
                              style: {"cursor":"pointer"},
                              title: "Исходные сообщения",
                              onClick: ($event: any) => (_ctx.onRowShowMessageSource(props.row, false))
                            }, _toDisplayString(props.row.count), 9, _hoisted_16))
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["props", "class"])),
                (props.expand)
                  ? (_openBlock(), _createBlock(_component_q_tr, {
                      ref: 'childTableRow_' + props.row.id,
                      key: props.rowIndex + '###secondary###' + props.row.id,
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_td, {
                          colspan: "100%",
                          style: {"padding-right":"0","padding-top":"0","padding-left":"1.7rem"}
                        }, {
                          default: _withCtx(() => [
                            (props.expand)
                              ? (_openBlock(), _createBlock(_component_VkMessageChildren, {
                                  key: props.rowIndex + '###expanded###' + props.row.id,
                                  columns: _ctx.columnsChild,
                                  "is-message-grp-result-busy": _ctx.isMessageGrpResultBusy,
                                  "working-message-panel": _ctx.returnRef,
                                  childRowId: props.row.id,
                                  onShowSource: _ctx.onRowShowMessageSource,
                                  onAfterSeparate: _ctx.afterSeparate,
                                  onCheckChild: _ctx.checkChildLength,
                                  ref: "tableMessageRef"
                                }, null, 8, ["columns", "is-message-grp-result-busy", "working-message-panel", "childRowId", "onShowSource", "onAfterSeparate", "onCheckChild"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true)
              ]),
              "no-data": _withCtx(() => _cache[11] || (_cache[11] = [
                _createElementVNode("div", { class: "col-12 text-center text-h4" }, "Нет данных", -1)
              ])),
              pagination: _withCtx((scope) => [
                _createTextVNode(_toDisplayString((scope.pagination.rowsPerPage * scope.pagination.page - scope.pagination.rowsPerPage + 1).toLocaleString()) + " - " + _toDisplayString(scope.pagination.rowsPerPage * scope.pagination.page > scope.pagination.rowsNumber
                  ? scope.pagination.rowsNumber.toLocaleString()
                  : (scope.pagination.rowsPerPage * scope.pagination.page).toLocaleString()) + " из " + _toDisplayString(scope.pagination.rowsNumber.toLocaleString()) + " ", 1),
                (scope.pagesNumber > 2)
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 0,
                      icon: "first_page",
                      color: "grey-8",
                      round: "",
                      dense: "",
                      flat: "",
                      disable: scope.isFirstPage,
                      onClick: scope.firstPage,
                      class: "q-ml-md"
                    }, null, 8, ["disable", "onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_q_btn, {
                  icon: "chevron_left",
                  color: "grey-8",
                  round: "",
                  dense: "",
                  flat: "",
                  disable: scope.isFirstPage,
                  onClick: scope.prevPage
                }, null, 8, ["disable", "onClick"]),
                _createVNode(_component_q_btn, {
                  icon: "chevron_right",
                  color: "grey-8",
                  round: "",
                  dense: "",
                  flat: "",
                  disable: scope.isLastPage,
                  onClick: scope.nextPage
                }, null, 8, ["disable", "onClick"]),
                (scope.pagesNumber > 2)
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 1,
                      icon: "last_page",
                      color: "grey-8",
                      round: "",
                      dense: "",
                      flat: "",
                      disable: scope.isLastPage,
                      onClick: scope.lastPage
                    }, null, 8, ["disable", "onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["rows", "columns", "selected", "loading", "pagination", "rows-per-page-options", "expanded", "onRequest", "style"])
          ])
        ]),
        _createVNode(_component_q_inner_loading, {
          showing: _ctx.isMessageGrpResultBusy,
          color: "grey",
          style: {"z-index":"100"}
        }, null, 8, ["showing"])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "text-h6 text-weight-bold q-pa-sm" }, "Поиск схожих вариантов", -1)),
        _createVNode(_component_q_separator),
        _createVNode(_component_VkMessagePanel, {
          ref: "vkMessagePanel",
          onAfterAction: _ctx.afterAction,
          onShowSource: _ctx.onRowShowMessageSource
        }, null, 8, ["onAfterAction", "onShowSource"]),
        _createVNode(_component_q_inner_loading, {
          showing: _ctx.isMessageGrpWorkedBusy,
          color: "grey",
          style: {"z-index":"100"}
        }, null, 8, ["showing"])
      ])
    ]),
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.isActiveModal,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isActiveModal) = $event)),
      persistent: ""
    }, {
      default: _withCtx(() => [
        (_ctx.isActiveModalSource)
          ? (_openBlock(), _createBlock(_component_VkMessageSource, {
              key: 0,
              message: _ctx.currentCountMessage,
              isFull: _ctx.isFullCount,
              onHideModal: _ctx.hideModal
            }, null, 8, ["message", "isFull", "onHideModal"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}