import script from "./ClassEdit.vue?vue&type=script&setup=true&lang=ts"
export * from "./ClassEdit.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QField from 'quasar/src/components/field/QField.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QTree from 'quasar/src/components/tree/QTree.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QItem,QCardSection,QItemLabel,QSeparator,QTabs,QTab,QTabPanels,QTabPanel,QForm,QInput,QField,QBtn,QSelect,QTooltip,QTable,QMenu,QList,QItemSection,QCardActions,QDialog,QTree});
