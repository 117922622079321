// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "ag_answer_grp_cls.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { AgAnswerGrpClsService } from "./ag_answer_grp_cls";
import type { ListAgAnswerGrpClsOutResponse } from "./ag_answer_grp_cls";
import type { ListAgAnswerGrpClsOutRequest } from "./ag_answer_grp_cls";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListAgAnswerGrpClsInResponse } from "./ag_answer_grp_cls";
import type { ListAgAnswerGrpClsInRequest } from "./ag_answer_grp_cls";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.AgAnswerGrpClsService
 */
export interface IAgAnswerGrpClsServiceClient {
    /**
     * @generated from protobuf rpc: ListAgAnswerGrpClsIn(proto.nigmamsg.ListAgAnswerGrpClsInRequest) returns (proto.nigmamsg.ListAgAnswerGrpClsInResponse);
     */
    listAgAnswerGrpClsIn(input: ListAgAnswerGrpClsInRequest, options?: RpcOptions): UnaryCall<ListAgAnswerGrpClsInRequest, ListAgAnswerGrpClsInResponse>;
    /**
     * @generated from protobuf rpc: ListAgAnswerGrpClsOut(proto.nigmamsg.ListAgAnswerGrpClsOutRequest) returns (proto.nigmamsg.ListAgAnswerGrpClsOutResponse);
     */
    listAgAnswerGrpClsOut(input: ListAgAnswerGrpClsOutRequest, options?: RpcOptions): UnaryCall<ListAgAnswerGrpClsOutRequest, ListAgAnswerGrpClsOutResponse>;
}
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.AgAnswerGrpClsService
 */
export class AgAnswerGrpClsServiceClient implements IAgAnswerGrpClsServiceClient, ServiceInfo {
    typeName = AgAnswerGrpClsService.typeName;
    methods = AgAnswerGrpClsService.methods;
    options = AgAnswerGrpClsService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ListAgAnswerGrpClsIn(proto.nigmamsg.ListAgAnswerGrpClsInRequest) returns (proto.nigmamsg.ListAgAnswerGrpClsInResponse);
     */
    listAgAnswerGrpClsIn(input: ListAgAnswerGrpClsInRequest, options?: RpcOptions): UnaryCall<ListAgAnswerGrpClsInRequest, ListAgAnswerGrpClsInResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListAgAnswerGrpClsInRequest, ListAgAnswerGrpClsInResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListAgAnswerGrpClsOut(proto.nigmamsg.ListAgAnswerGrpClsOutRequest) returns (proto.nigmamsg.ListAgAnswerGrpClsOutResponse);
     */
    listAgAnswerGrpClsOut(input: ListAgAnswerGrpClsOutRequest, options?: RpcOptions): UnaryCall<ListAgAnswerGrpClsOutRequest, ListAgAnswerGrpClsOutResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListAgAnswerGrpClsOutRequest, ListAgAnswerGrpClsOutResponse>("unary", this._transport, method, opt, input);
    }
}
