import {ClassifierServiceClient} from 'proto/nigma-message-proto/polls/classifier.client'
import {useGrpcClientWithLogger} from "@/components/modules/rec-system/services/UserGroups/middleware";
import {
    ListClassifierRequest,
    ItemClassifierRequest,
    CreateClassifierRequest,
    UpdateClassifierRequest,
    DeleteClassifierRequest,
} from "proto/nigma-message-proto/polls/classifier";

export const useClassifierApi = () => {
    const client = useGrpcClientWithLogger().create(ClassifierServiceClient, {baseUrl: window.VUE_APP_GATEWAY_URL})

    const metadata = () => {
        return {
            Authorization: localStorage.getItem('access-key') || ''
        }
    }

    return {
        listClassifierRequest: (request: ListClassifierRequest) => {
            return client.listClassifier(request, {meta: metadata()}).response
        },
        itemClassifierRequest: (request: ItemClassifierRequest) => {
            return client.itemClassifier(request, {meta: metadata()}).response
        },
        createClassifierRequest: (request: CreateClassifierRequest) => {
            return client.createClassifier(request, {meta: metadata()}).response
        },
        updateClassifierRequest: (request: UpdateClassifierRequest) => {
            return client.updateClassifier(request, {meta: metadata()}).response
        },
        deleteClassifierRequest: (request: DeleteClassifierRequest) => {
            return client.deleteClassifier(request, {meta: metadata()}).response
        },
    }
}