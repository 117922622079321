import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, isRef as _isRef, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { style: {"height":"100%","box-shadow":"none","position":"relative"} }
const _hoisted_2 = {
  key: 0,
  class: "q-pa-md col-12",
  style: {"height":"100%"}
}
const _hoisted_3 = {
  class: "row items-center",
  style: {"width":"100%"}
}
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "text-weight-bold" }
const _hoisted_6 = {
  key: 0,
  class: "col-3 q-ml-auto q-mr-sm"
}
const _hoisted_7 = {
  key: 1,
  class: "col-3 q-ml-auto q-mr-sm"
}
const _hoisted_8 = { class: "col-3" }
const _hoisted_9 = { class: "row q-ml-sm" }
const _hoisted_10 = {
  class: "col-12",
  style: {"width":"100%","height":"calc(100% - 73px)","margin":"10px 0 0 10px"}
}
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "row justify-center" }
const _hoisted_15 = {
  key: 1,
  class: "row"
}

import store from "@/store";
import { computed, ref } from "vue";
import MessageFormSingle from "@/components/modules/polls/views/answers/MessageFormSingle.vue";
import { useMessageGrpApi } from "@/components/modules/polls/services/useMessageGrpApi";
import { MessageGrpModel } from "proto/nigma-message-proto/polls/message_grp";
import { Filter } from "proto/nigma-common-proto/filter";
import type { QTableColumn } from "quasar";
import { usePagination } from "@/composables/usePagination";
import { useTextHighlighter } from "@/composables/useTextHighlighter";

type FindType = {
  id: number;
  title: string;
  value: string;
  disable?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'MultipleGeneralizationMessages',
  emits: ["afterAction", "showSource"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { listMessageGrpRequest, reduceMessageGrpRequest } = useMessageGrpApi();
const { markedFindValue } = useTextHighlighter();

const emit = __emit;

const pseudoTableData = ref<MessageGrpModel[]>([]);
const pseudoTableLoader = ref(false);
const currentMessage = ref<MessageGrpModel>({
  messageGrpId: 0,
  messageGrpParentId: 0,
  messageGrpText: "",
  messageGrpCount: 0,
  messageGrpProcessed: false,
  messageGrpSum: 0,
  similarity: 0,
  hasChilds: false,
});

const isActiveModal = ref(false);
const isActiveModalSplitSingle = ref(false);
const isCurrentMessageResult = ref(true);
const currentActionMessage = ref<MessageGrpModel>();
const columnsWorked = ref<QTableColumn[]>([
  {
    name: "messageGrpText",
    field: "messageGrpText",
    label: "Сообщение",
    align: "left",
  },
  {
    name: "messageGrpSum",
    field: "messageGrpSum",
    label: "Количество голосов",
    align: "center",
  },
  {
    name: "action",
    label: "",
    field: "id",
    align: "center",
    headerStyle: "width: 40px",
    style: "width: 40px",
  },
]);
const similarColumn = ref<QTableColumn>({
  name: "similarity",
  field: "similarity",
  label: "Коэффициент схожести",
  align: "center",
});
const workedFilter = ref<Filter>({
  items: [
    {
      fieldName: "mg.message_grp_id",
      operand: "<>",
      value: "0",
    },
  ],
});
const { onTableRequest: onRequest, pagination: workedPagination } =
  usePagination({
    sortBy: "message_grp_sum",
    descending: true,
    page: 1,
    rowsPerPage: 25,
    rowsNumber: 0,
  });
const rowsPerPageWorkedOptions = [10, 25, 50, 100];
const findType = ref<FindType>({
  id: 0,
  title: "Поиск по семантике",
  value: "similar",
  disable: true,
});
const findTypeOptions = ref<FindType[]>([
  { id: 0, title: "Поиск по семантике", value: "similar", disable: true },
  { id: 1, title: "Простой поиск", value: "single" },
]);
const markedFind = ref<string[]>([]);
const selectedWorked = ref<MessageGrpModel[]>([]);
const search = ref("");
const similarity = ref("0.6");

const userRole = computed(() => store.getters.getUserAccess);

const onRowShowMessageSource = (row: MessageGrpModel, isFull: boolean) =>
  emit("showSource", row, isFull);

const hideModal = () => {
  isActiveModal.value = false;
  isActiveModalSplitSingle.value = false;
  currentActionMessage.value = undefined;
};

const splitSingle = () => {
  isActiveModalSplitSingle.value = true;
  isActiveModal.value = true;
};

const actionRow = (method: string, row: MessageGrpModel) => {
  currentActionMessage.value = Object.assign(row);

  method === "split" && splitSingle();
};

const afterActionSplitSingle = () => {
  emit("afterAction", currentMessage.value.messageGrpId);
  hideModal();
};

const makeActionWorked = () => {
  let reducedIds = selectedWorked.value.map((item) => item.messageGrpId);

  reduceMessageGrpRequest({
    messageGrpId: currentMessage.value.messageGrpId,
    reducedIds: reducedIds,
  }).then(() => {
    emit("afterAction", currentMessage.value.messageGrpId);
  });
};

const changeFindType = () => {
  if (findType.value.value === "single") {
    if (columnsWorked.value.length == 4) {
      columnsWorked.value.splice(2, 1);
    }
  } else {
    if (columnsWorked.value.length == 3) {
      columnsWorked.value.splice(2, 0, similarColumn.value);
    }
  }

  findMessages();
};

const findMessages = () => {
  markedFind.value = [];

  if (findType.value.value === "single") {
    workedFilter.value.items[1] = {
      fieldName: "mg.message_grp_text",
      operand: "ilike",
      value: search.value,
    };

    if (search.value.length) {
      markedFind.value.push(search.value);
    }
  } else {
    let similarValue = parseFloat(similarity.value);
    workedFilter.value.items[1] = {
      fieldName: "mg.message_grp_text",
      operand: "similar_" + similarValue.toFixed(2),
      value: currentMessage.value.messageGrpText,
    };
  }

  loadWorkedMessages(true);
};

const loadWorkedMessages = (isResetPagination: boolean) => {
  pseudoTableLoader.value = true;

  if (isResetPagination) {
    workedPagination.value.page = 1;
    workedPagination.value.rowsNumber = 0;
  }

  switch (findType.value.value) {
    case "single": {
      workedPagination.value.sortBy = "message_grp_sum";
      workedPagination.value.descending = true;
      break;
    }
    case "similar": {
      workedPagination.value.sortBy = "similarity";
      workedPagination.value.descending = true;
      break;
    }
    default: {
      console.log(
        "loadWorkedMessages - неизвестный тип поиска this.findType",
        findType.value.value
      );
    }
  }

  listMessageGrpRequest({
    filter: workedFilter.value,
    sort: {
      name: workedPagination.value.sortBy,
      exp: workedPagination.value.descending ? "DESC" : "ASC",
    },
    pagination: {
      page: workedPagination.value.page,
      limit: workedPagination.value.rowsPerPage,
      pages: 0,
      cnt: workedPagination.value.rowsNumber,
    },
    isWorked: true,
  })
    .then((res) => {
      workedPagination.value.rowsNumber = res.params?.pagination?.cnt || 0;
      pseudoTableData.value = res.items;
    })
    .finally(() => (pseudoTableLoader.value = false));
};

const onRequestWorked = onRequest(() => loadWorkedMessages(false));

const loadData = (
  isLoadMessages: boolean,
  isResetSimilarity: boolean,
  selectedRow: MessageGrpModel
) => {
  findType.value = findTypeOptions.value[0];
  let findTypeValue = findType.value.value;
  currentMessage.value = selectedRow;

  selectedWorked.value = [];
  markedFind.value = [];

  if (isResetSimilarity) {
    search.value = "";
    findTypeValue = "similar";
    similarity.value = "0.6";
  }

  findTypeOptions.value[0].disable = !isCurrentMessageResult.value;

  if ("similar" == findTypeValue && !isCurrentMessageResult.value) {
    findType.value = findTypeOptions.value[1];
  }

  if (findTypeValue === "single") {
    if (columnsWorked.value.length == 4) {
      columnsWorked.value.splice(2, 1);
    }
  } else {
    if (columnsWorked.value.length == 3) {
      columnsWorked.value.splice(2, 0, similarColumn.value);
    }
  }

  if (isLoadMessages) {
    let extFilter;
    workedFilter.value.items[0].value =
      currentMessage.value.messageGrpId.toString();

    if (findType.value.value === "single") {
      if (search.value.length) {
        extFilter = {
          fieldName: "mg.message_grp_text",
          operand: "ilike",
          value: search.value,
        };

        markedFind.value.push(search.value);
      }
    } else {
      extFilter = {
        fieldName: "mg.message_grp_text",
        operand: "similar_" + parseFloat(similarity.value).toFixed(2),
        value: currentMessage.value.messageGrpText,
      };
    }

    workedFilter.value.items[1] = extFilter;
    loadWorkedMessages(true);
  }
};

__expose({
  loadData,
});

return (_ctx: any,_cache: any) => {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (currentMessage.value.messageGrpId !== 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "col-3 text-subtitle1 ellipsis-2-lines overflow-hidden",
              title: currentMessage.value.messageGrpText,
              style: {"overflow":"auto"}
            }, [
              _cache[6] || (_cache[6] = _createTextVNode(" Сообщение: ")),
              _createElementVNode("span", _hoisted_5, "(" + _toDisplayString(currentMessage.value.messageGrpSum) + ") " + _toDisplayString(currentMessage.value.messageGrpText), 1)
            ], 8, _hoisted_4),
            (findType.value.value === 'similar')
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_q_input, {
                    type: "number",
                    modelValue: similarity.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((similarity).value = $event)),
                    filled: "",
                    min: "0.01",
                    max: "0.99",
                    step: "0.01",
                    label: "Коэффициент схожести",
                    onKeydown: _withKeys(_withModifiers(findMessages, ["prevent"]), ["enter"])
                  }, null, 8, ["modelValue", "onKeydown"])
                ]))
              : _createCommentVNode("", true),
            (findType.value.value === 'single')
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_q_input, {
                    filled: "",
                    modelValue: search.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((search).value = $event)),
                    label: "Текст для поиска",
                    onKeydown: _withKeys(_withModifiers(findMessages, ["prevent"]), ["enter"])
                  }, null, 8, ["modelValue", "onKeydown"])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_q_btn, {
              style: {"padding":"10px","margin-right":"10px"},
              "background-color": "gray-12",
              icon: "mdi-magnify",
              onClick: findMessages
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_tooltip, null, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("Найти ответы")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_q_select, {
                options: findTypeOptions.value,
                modelValue: findType.value,
                "onUpdate:modelValue": [
                  _cache[2] || (_cache[2] = ($event: any) => ((findType).value = $event)),
                  changeFindType
                ],
                filled: "",
                "option-value": "value",
                "option-label": "title",
                label: "Тип поиска"
              }, null, 8, ["options", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              ('admin editor'.includes(userRole.value))
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 0,
                    color: "primary",
                    disable: !selectedWorked.value.length,
                    label: "ОБОБЩИТЬ",
                    onClick: makeActionWorked,
                    style: {"height":"56px"}
                  }, {
                    default: _withCtx(() => [
                      (!selectedWorked.value.length)
                        ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                            default: _withCtx(() => _cache[8] || (_cache[8] = [
                              _createElementVNode("div", { class: "text-subtitle2" }, "Выберите хотя бы 1 сообщение", -1)
                            ])),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["disable"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_q_table, {
              style: _normalizeStyle([{"overflow":"auto"}, pseudoTableData.value.length ? 'height: 63vh' : null]),
              class: "sticky-header-table-answer",
              flat: "",
              dense: "",
              rows: pseudoTableData.value,
              columns: columnsWorked.value,
              "row-key": "messageGrpId",
              selection: 'admin editor'.includes(userRole.value) ? 'multiple' : 'none',
              "wrap-cells": "",
              selected: selectedWorked.value,
              "onUpdate:selected": _cache[3] || (_cache[3] = ($event: any) => ((selectedWorked).value = $event)),
              pagination: _unref(workedPagination),
              "onUpdate:pagination": _cache[4] || (_cache[4] = ($event: any) => (_isRef(workedPagination) ? (workedPagination).value = $event : null)),
              "rows-per-page-options": rowsPerPageWorkedOptions,
              onRequest: _unref(onRequestWorked)
            }, {
              "body-cell-messageGrpText": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    (markedFind.value.length)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          innerHTML: _unref(markedFindValue)(props.value, [], search.value)
                        }, null, 8, _hoisted_11))
                      : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(props.value), 1))
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-messageGrpSum": _withCtx((props) => [
                _createVNode(_component_q_td, {
                  props: props,
                  style: {"width":"60px"}
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      style: {"cursor":"pointer"},
                      title: "Исходные ответы",
                      onClick: ($event: any) => (onRowShowMessageSource(props.row, true))
                    }, _toDisplayString(props.value.toLocaleString()), 9, _hoisted_13)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-similarity": _withCtx((props) => [
                _withDirectives(_createVNode(_component_q_td, {
                  props: props,
                  style: {"width":"80px"}
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(props.value.toFixed(4)), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]), [
                  [_vShow, (findType.value.value = 'similar')]
                ])
              ]),
              "body-cell-action": _withCtx((props) => [
                _createElementVNode("div", _hoisted_14, [
                  ('admin editor'.includes(userRole.value))
                    ? (_openBlock(), _createBlock(_component_q_btn, {
                        key: 0,
                        flat: "",
                        dense: "",
                        round: "",
                        icon: "mdi-dots-vertical"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_menu, { "auto-close": "" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_list, { dense: "" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_item, {
                                    clickable: "",
                                    onClick: ($event: any) => (actionRow('split', props.row))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_item_section, null, {
                                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                                          _createTextVNode("Разбить")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true)
                ])
              ]),
              pagination: _withCtx((scope) => [
                _createTextVNode(_toDisplayString((
                scope.pagination.rowsPerPage * scope.pagination.page -
                scope.pagination.rowsPerPage +
                1
              ).toLocaleString()) + " - " + _toDisplayString(scope.pagination.rowsPerPage * scope.pagination.page >
              scope.pagination.rowsNumber
                ? scope.pagination.rowsNumber.toLocaleString()
                : (
                    scope.pagination.rowsPerPage * scope.pagination.page
                  ).toLocaleString()) + " из " + _toDisplayString(scope.pagination.rowsNumber.toLocaleString()) + " ", 1),
                (scope.pagesNumber > 2)
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 0,
                      icon: "first_page",
                      color: "grey-8",
                      round: "",
                      dense: "",
                      flat: "",
                      disable: scope.isFirstPage,
                      onClick: scope.firstPage,
                      class: "q-ml-md"
                    }, null, 8, ["disable", "onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_q_btn, {
                  icon: "chevron_left",
                  color: "grey-8",
                  round: "",
                  dense: "",
                  flat: "",
                  disable: scope.isFirstPage,
                  onClick: scope.prevPage
                }, null, 8, ["disable", "onClick"]),
                _createVNode(_component_q_btn, {
                  icon: "chevron_right",
                  color: "grey-8",
                  round: "",
                  dense: "",
                  flat: "",
                  disable: scope.isLastPage,
                  onClick: scope.nextPage
                }, null, 8, ["disable", "onClick"]),
                (scope.pagesNumber > 2)
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 1,
                      icon: "last_page",
                      color: "grey-8",
                      round: "",
                      dense: "",
                      flat: "",
                      disable: scope.isLastPage,
                      onClick: scope.lastPage
                    }, null, 8, ["disable", "onClick"]))
                  : _createCommentVNode("", true)
              ]),
              "no-data": _withCtx(() => _cache[10] || (_cache[10] = [
                _createElementVNode("div", { class: "col-12 text-center text-h4" }, "Нет данных", -1)
              ])),
              _: 1
            }, 8, ["style", "rows", "columns", "selection", "selected", "pagination", "onRequest"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_label, { class: "text-h5 text-center" }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createTextVNode("Не выбран ответ в левой панели")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ])),
    _createVNode(_component_q_dialog, {
      modelValue: isActiveModal.value,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((isActiveModal).value = $event)),
      persistent: ""
    }, {
      default: _withCtx(() => [
        (isActiveModalSplitSingle.value)
          ? (_openBlock(), _createBlock(MessageFormSingle, {
              key: 0,
              message: currentActionMessage.value,
              onAfterActionSplitSingle: afterActionSplitSingle,
              onHideModal: hideModal
            }, null, 8, ["message"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_q_inner_loading, {
      showing: pseudoTableLoader.value,
      color: "grey",
      style: {"z-index":"1000"}
    }, null, 8, ["showing"])
  ]))
}
}

})