import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "row col-12 q-mt-md" }
const _hoisted_2 = {
  class: "col-xl-4 col-lg-4 q-ml-sm",
  style: {"padding-right":"5px"}
}
const _hoisted_3 = {
  class: "col-xl-1 col-lg-2",
  style: {"padding-left":"5px","height":"100%"}
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { key: 1 }

import {computed, onMounted, ref} from 'vue'
import store from "@/store";
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';;
import PollsFilters from "@/components/modules/polls/components/agMessages/listPoll/PollsFilters.vue";
// import style = dom.style;


export default /*@__PURE__*/_defineComponent({
  __name: 'SocialNetworkLoad',
  setup(__props) {

const $q = useQuasar()

const loadPollsCols = [
  {
    label: 'ID',
    name: 'social_network_id',
    field: 'social_network_id',
    align: 'center',
    type: 'number',
    headerStyle: 'font-weight: 700',
  },
  {
    label: 'Название',
    name: 'social_network_title',
    field: 'social_network_title',
    sortable: true,
    align: 'left',
    style: 'min-width: 600px; max-width: 600px',
    headerStyle: 'font-weight: 700',
  },
  {
    label: 'Загруженные сообщения',
    name: 'social_network_loaded_messages',
    field: 'social_network_loaded_messages',
    sortable: true,
    align: 'center',
    headerStyle: 'font-weight: 700',
  },
  {
    label: 'Описание',
    name: 'social_network_notes',
    field: 'social_network_notes',
    align: 'center',
    headerStyle: 'font-weight: 700',
  },
  {
    label: 'Статус',
    name: 'social_network_loading_flag',
    field: 'social_network_loading_flag',
    align: 'center',
    headerStyle: 'font-weight: 700',
  },
]
const loadPollsSelected = ref([]) as any
const loadPollsPagination = ref({
  sortBy: 'social_network_loading_flag',
  descending: true,
  page: 1,
  rowsPerPage: 25,
  rowsNumber: 0,
})
const search = ref('')
const markedFind = ref([]) as any
const sideFilters = ref([]) as any
const filtersRef = ref(null) as any
// const sideFilterLabel = ref('ФИЛЬТРЫ')
const pollLoadRef = ref(null) as any
const rootRef = ref(null) as any
const statusFlag = ref(false)

const loadPollsRows = computed(() => {
  const rawList = store.getters.getListSocialNetworkLoadItems
  const filteredList = []

  if (rawList) {
    for (const i of rawList) {
      filteredList.push({
        social_network_id: i.getSocialNetworkId(),
        social_network_title: i.getSocialNetworkTitle(),
        social_network_loaded_messages: i.getSocialNetworkLoadedMessages().toLocaleString(),
        social_network_notes: i.getSocialNetworkNotes(),
        social_network_loading_flag: i.getSocialNetworkLoadingFlag(),
      })
    }
  }

  return filteredList
})
const isLoadPollsBusy = computed(() => store.getters.isListSocialNetworkLoadBusy)

const loadListPollLoad = (isResetPagination: boolean) => {
  if (isResetPagination) {
    loadPollsPagination.value.page = 1
    loadPollsPagination.value.rowsNumber = 0
  }

  store
      .dispatch('getListSocialNetworkLoadItems', {
        filter: sideFilters.value,
        sort: {
          name: loadPollsPagination.value.sortBy,
          exp: loadPollsPagination.value.descending ? 'DESC' : 'ASC',
        },
        pagination: {
          page: loadPollsPagination.value.page,
          limit: loadPollsPagination.value.rowsPerPage,
          pages: 0,
          cnt: loadPollsPagination.value.rowsNumber,
        }
      })
      .then((res) => {
        loadPollsPagination.value.rowsNumber = res.pagination.getCnt()
      })
}
const loadPollsTableRequest = (props: any) => {
  loadPollsPagination.value = props.pagination
  loadListPollLoad(false)
}
const loadPolls = () => {
  store.dispatch('loadSocialNetwork', {
    social_network_id: loadPollsSelected.value[0].social_network_id
  })
      .then(() => {
        loadListPollLoad(true)
        loadPollsSelected.value = []
      })
      .then(() => {
        checkLoadStatus(true)
      })
}
const findAnswersResult = () => {
  markedFind.value = []
  if (search.value.length) {
    markedFind.value.push(search.value)
  }

  if (sideFilters.value.length || sideFilters.value.length === 1) {
    sideFilters.value[0].value = search.value
    sideFilters.value[0].or[0].value = search.value
  } else {
    sideFilters.value = [
      {
        field_name: 'social_network_title',
        operand: 'ilike',
        value: search.value,
        or: [{
          field_name: 'social_network_id::text',
          operand: 'ilike',
          value: search.value
        }]
      }
    ]
  }


  // Подгрузка ответов учитывая поиск
  loadListPollLoad(true) // true - переключаем на первую страницу
}
const strMarkRedPlus = (search: string, subject: string) => {
  let escapeRegExp = function (str: string) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  }

  return subject.replace(
      new RegExp('(' + escapeRegExp(search) + ')', 'gi'),
      "<span style='color: red;'><b>$1</b></span>"
  )
}
const markedAll = (text: string) => {
  const marked = markedFind.value

  if (marked && marked.length) {
    marked.sort((a: string, b: string) => b.length - a.length)
    marked.forEach(
        (item: string) => (text = strMarkRedPlus(item, text))
    )
  }

  return text
}
const sideFiltersFn = (filters: any) => {
  sideFilters.value = filters

  loadListPollLoad(false)
}
// const toggleFilter = () => {
//   let toggle = 1
//
//   return function () {
//     toggle ^= 1
//     if (!toggle) {
//       sideFilterLabel.value = 'Скрыть фильтры'
//     } else {
//       sideFilterLabel.value = 'Фильтры'
//     }
//     const filtersWidth = style(filtersRef.value, 'width')
//
//     if (!toggle) {
//       pollLoadRef.value.style.paddingRight = filtersWidth
//       filtersRef.value.style.transform = 'translateX(0)'
//       rootRef.value.style.overflowY = 'visible'
//       rootRef.value.style.overflowX = 'visible'
//     } else {
//       pollLoadRef.value.style.paddingRight = '0px'
//       filtersRef.value.style.transform = 'translateX(100%)'
//       rootRef.value.style.overflowX = 'hidden'
//       rootRef.value.style.overflowY = 'hidden'
//     }
//   }
// }
// const toggleSideFilters = toggleFilter()
const checkLoadStatus = (isLoading: boolean) => {
  let timeout

  if (timeout) clearTimeout(timeout)

  timeout = setTimeout(() => {
    store
        .dispatch('checkLoadSocialNetworkStatus')
        .then((res) => {
          console.log('LOAD POLLS STATUS', res.getIsloading())
          statusFlag.value = true

          if (!res.getIsloading()) {
            loadListPollLoad(false)

            if (isLoading) {
              $q.notify({
                type: 'positive',
                message: 'Опрос успешно загружен'
              })
            }

            statusFlag.value = false
          }

          if (statusFlag.value) {
            checkLoadStatus(true)
          }
        })
  }, 5000)
}

const loadData = () => {
  loadListPollLoad(true)
  checkLoadStatus(false)
}

onMounted(() => {
  loadData()
})

return (_ctx: any,_cache: any) => {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", {
    class: "col-12 q-pa-md relative-position",
    ref_key: "rootRef",
    ref: rootRef,
    style: {"overflow-x":"hidden"}
  }, [
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-h4" }, "Загрузка (обновление) данных", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_q_btn, {
        label: "ЗАГРУЗИТЬ",
        color: "primary",
        style: {"height":"56px"},
        onClick: loadPolls,
        disable: !loadPollsSelected.value.length || statusFlag.value
      }, {
        default: _withCtx(() => [
          (!loadPollsSelected.value.length)
            ? (_openBlock(), _createBlock(_component_q_tooltip, {
                key: 0,
                class: "text-subtitle1"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" Выберите опрос ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (statusFlag.value)
            ? (_openBlock(), _createBlock(_component_q_tooltip, {
                key: 1,
                class: "text-subtitle1"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" Дождитесь окончания загрузки опроса ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["disable"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_q_input, {
          modelValue: search.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
          filled: "",
          label: "Поиск по ID или названию",
          onKeydown: _withKeys(_withModifiers(findAnswersResult, ["prevent"]), ["enter"])
        }, null, 8, ["modelValue", "onKeydown"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_q_btn, {
          style: {"padding":"10px","margin-top":"6px","margin-right":"1px"},
          "background-color": "gray-12",
          icon: "mdi-magnify",
          onClick: findAnswersResult
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tooltip, { class: "text-subtitle1" }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("Найти опросы")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", {
      ref_key: "pollLoadRef",
      ref: pollLoadRef,
      style: {"transition":"all 0.3s ease-out"}
    }, [
      _createVNode(_component_q_table, {
        class: "q-mt-md sticky-header-table-answer shadow-4",
        columns: loadPollsCols,
        rows: loadPollsRows.value,
        "row-key": "poll_id",
        selection: "single",
        selected: loadPollsSelected.value,
        "onUpdate:selected": _cache[1] || (_cache[1] = ($event: any) => ((loadPollsSelected).value = $event)),
        pagination: loadPollsPagination.value,
        "onUpdate:pagination": _cache[2] || (_cache[2] = ($event: any) => ((loadPollsPagination).value = $event)),
        loading: isLoadPollsBusy.value,
        onRequest: loadPollsTableRequest,
        style: _normalizeStyle(loadPollsRows.value ? 'height: 73vh' : null),
        "binary-state-sort": "",
        "rows-per-page-options": [10, 25, 50, 100]
      }, {
        body: _withCtx((props) => [
          _createVNode(_component_q_tr, {
            props: props,
            class: _normalizeClass(props.row.social_network_loading_flag ? 'bg-grey-5' : null)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_td, { style: {"position":"relative","width":"50px"} }, {
                default: _withCtx(() => [
                  (!props.row.social_network_loading_flag)
                    ? (_openBlock(), _createBlock(_component_q_checkbox, {
                        key: 0,
                        modelValue: props.selected,
                        "onUpdate:modelValue": ($event: any) => ((props.selected) = $event),
                        disable: props.row.social_network_loading_flag || props.row.open_answers == 0,
                        style: {"position":"relative"}
                      }, {
                        default: _withCtx(() => [
                          (props.row.social_network_loading_flag)
                            ? (_openBlock(), _createBlock(_component_q_tooltip, {
                                key: 0,
                                class: "text-subtitle1"
                              }, {
                                default: _withCtx(() => _cache[6] || (_cache[6] = [
                                  _createTextVNode(" Опрос загружается ")
                                ])),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "onUpdate:modelValue", "disable"]))
                    : (_openBlock(), _createBlock(_component_q_inner_loading, {
                        key: 1,
                        showing: props.row.social_network_loading_flag,
                        color: "grey-7"
                      }, null, 8, ["showing"]))
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_q_td, {
                props: props,
                key: "social_network_id"
              }, {
                default: _withCtx(() => [
                  (markedFind.value.length)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        innerHTML: markedAll(props.row.social_network_id.toString())
                      }, null, 8, _hoisted_4))
                    : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(props.row.social_network_id.toString()), 1))
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                props: props,
                key: "social_network_title",
                class: "ellipsis",
                title: props.row.social_network_title
              }, {
                default: _withCtx(() => [
                  (markedFind.value.length)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        innerHTML: markedAll(props.row.social_network_title)
                      }, null, 8, _hoisted_6))
                    : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(props.row.social_network_title), 1))
                ]),
                _: 2
              }, 1032, ["props", "title"]),
              _createVNode(_component_q_td, {
                props: props,
                key: "social_network_loaded_messages"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.social_network_loaded_messages), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                props: props,
                key: "social_network_notes"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.social_network_notes), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                props: props,
                key: "social_network_loading_flag"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(!props.row.social_network_loading_flag ? 'Доступен' : 'Загружается'), 1)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            _: 2
          }, 1032, ["props", "class"])
        ]),
        pagination: _withCtx((scope) => [
          _createTextVNode(_toDisplayString((scope.pagination.rowsPerPage * scope.pagination.page - scope.pagination.rowsPerPage + 1).toLocaleString()) + " - " + _toDisplayString(scope.pagination.rowsPerPage * scope.pagination.page > scope.pagination.rowsNumber
            ? scope.pagination.rowsNumber.toLocaleString()
            : (scope.pagination.rowsPerPage * scope.pagination.page).toLocaleString()) + " из " + _toDisplayString(scope.pagination.rowsNumber.toLocaleString()) + " ", 1),
          (scope.pagesNumber > 2)
            ? (_openBlock(), _createBlock(_component_q_btn, {
                key: 0,
                icon: "first_page",
                color: "grey-8",
                round: "",
                dense: "",
                flat: "",
                disable: scope.isFirstPage,
                onClick: scope.firstPage,
                class: "q-ml-md"
              }, null, 8, ["disable", "onClick"]))
            : _createCommentVNode("", true),
          _createVNode(_component_q_btn, {
            icon: "chevron_left",
            color: "grey-8",
            round: "",
            dense: "",
            flat: "",
            disable: scope.isFirstPage,
            onClick: scope.prevPage
          }, null, 8, ["disable", "onClick"]),
          _createVNode(_component_q_btn, {
            icon: "chevron_right",
            color: "grey-8",
            round: "",
            dense: "",
            flat: "",
            disable: scope.isLastPage,
            onClick: scope.nextPage
          }, null, 8, ["disable", "onClick"]),
          (scope.pagesNumber > 2)
            ? (_openBlock(), _createBlock(_component_q_btn, {
                key: 1,
                icon: "last_page",
                color: "grey-8",
                round: "",
                dense: "",
                flat: "",
                disable: scope.isLastPage,
                onClick: scope.lastPage
              }, null, 8, ["disable", "onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["rows", "selected", "pagination", "loading", "style"])
    ], 512),
    _createElementVNode("div", {
      ref_key: "filtersRef",
      ref: filtersRef,
      class: "polls-filters"
    }, [
      _createVNode(PollsFilters, { onOnFilters: sideFiltersFn })
    ], 512)
  ], 512))
}
}

})