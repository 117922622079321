import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from './../../../../assets/img/UserProfile/logo_int.png'


const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  class: "col-auto",
  style: {"border-right":"solid 1px #ccc","width":"267px","padding-top":"40px"}
}
const _hoisted_3 = { class: "col" }

import InterRegistration from "@/components/modules/intersection/components/Registration/InterRegistration.vue";
import {ref} from "vue";
import InterActions from "@/components/modules/intersection/components/Actions/InterActions.vue";
import InterCity from "@/components/modules/intersection/components/CityPoints/InterCity.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'IntersectionMain',
  setup(__props) {

const profileSection = ref('intreg')

return (_ctx: any,_cache: any) => {
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-center q-mb-md" }, [
        _createElementVNode("img", {
          src: _imports_0,
          style: {"width":"77px"},
          alt: ""
        })
      ], -1)),
      _createVNode(_component_q_tabs, {
        modelValue: profileSection.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((profileSection).value = $event)),
        vertical: "",
        "indicator-color": "transparent",
        "active-color": "blue",
        "narrow-indicator": "",
        style: {"height":"auto"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_tab, {
            name: "intreg",
            label: "Регистрации / Пересечение",
            style: {"justify-content":"flex-start"}
          }),
          _createVNode(_component_q_tab, {
            name: "intaction",
            label: "Действия",
            style: {"justify-content":"flex-start"}
          }),
          _createVNode(_component_q_tab, {
            name: "intcity",
            label: "Городские баллы",
            style: {"justify-content":"flex-start"}
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_q_tab_panels, {
        modelValue: profileSection.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((profileSection).value = $event)),
        animated: "",
        style: {"width":"100%","background":"none","height":"unset"}
      }, {
        default: _withCtx(() => [
          _createVNode(InterRegistration, { name: "intreg" }),
          _createVNode(InterActions, { name: "intaction" }),
          _createVNode(InterCity, { name: "intcity" })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ]))
}
}

})