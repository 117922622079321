import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport'
import type { RpcInterceptor, RpcOptions } from '@protobuf-ts/runtime-rpc'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Constructor = new (...args: any[]) => any

export function useClientFactory () {
  return createClientFactoryWithMiddleWare()
}
function createClientFactoryWithMiddleWare (middleWare?: RpcInterceptor[]) {
  return {
    // TODO: Подумать нужно ли передавать middleWare в виде массива
    use (options: RpcInterceptor[]) {
      return createClientFactoryWithMiddleWare(
        middleWare === undefined ? [...options] : [...middleWare, ...options]
      )
    },
    create<T extends Constructor> (
      ServiceClient: T,
      options?: { baseUrl?: string, rpcOptions?: Partial<RpcOptions> }
    ): InstanceType<T> {
      const transportOptions = new GrpcWebFetchTransport({
        // В идеале бы иметь файл .env чтобы обращаться через process.env
        baseUrl: (options?.baseUrl ?? window.VUE_APP_API_URL) || '',
        interceptors: [...(middleWare ?? [])],
        ...options?.rpcOptions
      })
      return new ServiceClient(transportOptions)
    }
  }
}
