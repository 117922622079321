import { useClientFactory } from '@/mixins/GrpcClientFactory';
import type { RpcInterceptor} from '@protobuf-ts/runtime-rpc'
import {Deferred,RpcMetadata,RpcStatus,UnaryCall,RpcError} from '@protobuf-ts/runtime-rpc'

const logger:RpcInterceptor = {
  interceptUnary(next, method, input, options) {
  const defHeader = new Deferred<RpcMetadata>();
  const defMessage = new Deferred<object>();
  const defStatus = new Deferred<RpcStatus>();
  const defTrailer = new Deferred<RpcMetadata>();

   const result = next(method, input, options)

  console.log(`%c[API]:${method.localName} request`,'color:lightblue',input,options)

  void (async () => {
    try {
      await result
      const response = await result.response;
      console.log(`%c[API]:${method.localName} response`,'color:lightgreen',response)

      defHeader.resolve(result.headers);
      defMessage.resolve(result.response);
      defStatus.resolve(result.status);
      defTrailer.resolve(result.trailers);

    } catch (err) {
      if(err instanceof RpcError){
        err.message = decodeURIComponent(err.message)
        console.log(`%c[ERROR]:${method.localName}`,'color:red',err.message)
      }else{
        //If somehow error is not an "RpcError" then we treat is as string
        console.log(`%c[ERROR]:${method.localName}`,'color:red',decodeURIComponent(err as string))
      }

      defHeader.rejectPending(err);
      defMessage.rejectPending(err);
      defStatus.rejectPending(err);
      defTrailer.rejectPending(err);
    }
  })()

    return new UnaryCall(method,
      options.meta ?? {},
      input,
      defHeader.promise,
      defMessage.promise,
      defStatus.promise,
      defTrailer.promise,)
},}


export const useGrpcClientWithLogger = () => useClientFactory().use([logger])
