// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "ag_question.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Pagination } from "./proto/nigma-common-proto/filter";
import { Sort } from "./proto/nigma-common-proto/filter";
import { Filter } from "./proto/nigma-common-proto/filter";
/**
 * Модель данных
 * Префикс для фильтров aq.
 *
 * @generated from protobuf message proto.nigmamsg.AgQuestionModel
 */
export interface AgQuestionModel {
    /**
     * @generated from protobuf field: int64 ag_question_id = 1;
     */
    agQuestionId: number;
    /**
     * @generated from protobuf field: int64 ag_question_parent_id = 2;
     */
    agQuestionParentId: number;
    /**
     * @generated from protobuf field: int64 ag_poll_id = 3;
     */
    agPollId: number;
    /**
     * @generated from protobuf field: int64 ag_question_type_id = 4;
     */
    agQuestionTypeId: number;
    /**
     * @generated from protobuf field: int64 ag_question_idx = 5;
     */
    agQuestionIdx: number;
    /**
     * @generated from protobuf field: string ag_question_title = 6;
     */
    agQuestionTitle: string;
    /**
     * @generated from protobuf field: string ag_question_stitle = 7;
     */
    agQuestionStitle: string;
    /**
     * @generated from protobuf field: bool ag_question_processed = 8;
     */
    agQuestionProcessed: boolean;
    /**
     * @generated from protobuf field: int64 answer_src_count = 9;
     */
    answerSrcCount: number;
    /**
     * @generated from protobuf field: int64 answer_src_sum = 10;
     */
    answerSrcSum: number; // Префикс s. (для ListAgQuestionChilds префикс aq.), в фильтрах не использовать
    /**
     * @generated from protobuf field: double similarity = 11;
     */
    similarity: number; // Только сортировка, без префикса
    /**
     * @generated from protobuf field: bool has_childs = 12;
     */
    hasChilds: boolean; // Только сортировка, без префикса
}
/**
 * Входящий запрос для получения корневых элементов (question_id === question_parent_id)
 *
 * @generated from protobuf message proto.nigmamsg.ListAgQuestionRequest
 */
export interface ListAgQuestionRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
    /**
     * @generated from protobuf field: int64 ag_poll_id = 4;
     */
    agPollId: number;
    /**
     * @generated from protobuf field: bool is_worked = 5;
     */
    isWorked: boolean;
}
/**
 * Ответ сервера для получения корневых элементов
 *
 * @generated from protobuf message proto.nigmamsg.ListAgQuestionResponse
 */
export interface ListAgQuestionResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.AgQuestionModel items = 1;
     */
    items: AgQuestionModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListAgQuestionRequest params = 2;
     */
    params?: ListAgQuestionRequest;
}
/**
 * Входящий запрос для получения дочерних(только листовых) элементов
 *
 * @generated from protobuf message proto.nigmamsg.ListAgQuestionChildsRequest
 */
export interface ListAgQuestionChildsRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
    /**
     * @generated from protobuf field: int64 ag_question_id = 4;
     */
    agQuestionId: number;
}
/**
 * Ответ сервера для получения дочерних элементов
 *
 * @generated from protobuf message proto.nigmamsg.ListAgQuestionChildsResponse
 */
export interface ListAgQuestionChildsResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.AgQuestionModel items = 1;
     */
    items: AgQuestionModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListAgQuestionChildsRequest params = 2;
     */
    params?: ListAgQuestionChildsRequest;
}
/**
 * Входящий запрос для сведения вопросов к одному (reduce)
 *
 * @generated from protobuf message proto.nigmamsg.ReduceAgQuestionRequest
 */
export interface ReduceAgQuestionRequest {
    /**
     * @generated from protobuf field: int64 ag_question_id = 1;
     */
    agQuestionId: number;
    /**
     * @generated from protobuf field: repeated int64 reduced_ids = 2;
     */
    reducedIds: number[];
}
/**
 * Ответ сервера после сведения вопросов к одному (reduce)
 *
 * @generated from protobuf message proto.nigmamsg.ReduceAgQuestionResponse
 */
export interface ReduceAgQuestionResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
}
/**
 * Входящий запрос для разобщения вопросов (separate)
 *
 * @generated from protobuf message proto.nigmamsg.SeparateAgQuestionRequest
 */
export interface SeparateAgQuestionRequest {
    /**
     * @generated from protobuf field: repeated int64 separated_ids = 2;
     */
    separatedIds: number[];
}
/**
 * Ответ сервера после разобщения вопросов (separate)
 *
 * @generated from protobuf message proto.nigmamsg.SeparateAgQuestionResponse
 */
export interface SeparateAgQuestionResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class AgQuestionModel$Type extends MessageType<AgQuestionModel> {
    constructor() {
        super("proto.nigmamsg.AgQuestionModel", [
            { no: 1, name: "ag_question_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "ag_question_parent_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "ag_poll_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "ag_question_type_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "ag_question_idx", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "ag_question_title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "ag_question_stitle", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "ag_question_processed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "answer_src_count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 10, name: "answer_src_sum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "similarity", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 12, name: "has_childs", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<AgQuestionModel>): AgQuestionModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.agQuestionId = 0;
        message.agQuestionParentId = 0;
        message.agPollId = 0;
        message.agQuestionTypeId = 0;
        message.agQuestionIdx = 0;
        message.agQuestionTitle = "";
        message.agQuestionStitle = "";
        message.agQuestionProcessed = false;
        message.answerSrcCount = 0;
        message.answerSrcSum = 0;
        message.similarity = 0;
        message.hasChilds = false;
        if (value !== undefined)
            reflectionMergePartial<AgQuestionModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgQuestionModel): AgQuestionModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ag_question_id */ 1:
                    message.agQuestionId = reader.int64().toNumber();
                    break;
                case /* int64 ag_question_parent_id */ 2:
                    message.agQuestionParentId = reader.int64().toNumber();
                    break;
                case /* int64 ag_poll_id */ 3:
                    message.agPollId = reader.int64().toNumber();
                    break;
                case /* int64 ag_question_type_id */ 4:
                    message.agQuestionTypeId = reader.int64().toNumber();
                    break;
                case /* int64 ag_question_idx */ 5:
                    message.agQuestionIdx = reader.int64().toNumber();
                    break;
                case /* string ag_question_title */ 6:
                    message.agQuestionTitle = reader.string();
                    break;
                case /* string ag_question_stitle */ 7:
                    message.agQuestionStitle = reader.string();
                    break;
                case /* bool ag_question_processed */ 8:
                    message.agQuestionProcessed = reader.bool();
                    break;
                case /* int64 answer_src_count */ 9:
                    message.answerSrcCount = reader.int64().toNumber();
                    break;
                case /* int64 answer_src_sum */ 10:
                    message.answerSrcSum = reader.int64().toNumber();
                    break;
                case /* double similarity */ 11:
                    message.similarity = reader.double();
                    break;
                case /* bool has_childs */ 12:
                    message.hasChilds = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgQuestionModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ag_question_id = 1; */
        if (message.agQuestionId !== 0)
            writer.tag(1, WireType.Varint).int64(message.agQuestionId);
        /* int64 ag_question_parent_id = 2; */
        if (message.agQuestionParentId !== 0)
            writer.tag(2, WireType.Varint).int64(message.agQuestionParentId);
        /* int64 ag_poll_id = 3; */
        if (message.agPollId !== 0)
            writer.tag(3, WireType.Varint).int64(message.agPollId);
        /* int64 ag_question_type_id = 4; */
        if (message.agQuestionTypeId !== 0)
            writer.tag(4, WireType.Varint).int64(message.agQuestionTypeId);
        /* int64 ag_question_idx = 5; */
        if (message.agQuestionIdx !== 0)
            writer.tag(5, WireType.Varint).int64(message.agQuestionIdx);
        /* string ag_question_title = 6; */
        if (message.agQuestionTitle !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.agQuestionTitle);
        /* string ag_question_stitle = 7; */
        if (message.agQuestionStitle !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.agQuestionStitle);
        /* bool ag_question_processed = 8; */
        if (message.agQuestionProcessed !== false)
            writer.tag(8, WireType.Varint).bool(message.agQuestionProcessed);
        /* int64 answer_src_count = 9; */
        if (message.answerSrcCount !== 0)
            writer.tag(9, WireType.Varint).int64(message.answerSrcCount);
        /* int64 answer_src_sum = 10; */
        if (message.answerSrcSum !== 0)
            writer.tag(10, WireType.Varint).int64(message.answerSrcSum);
        /* double similarity = 11; */
        if (message.similarity !== 0)
            writer.tag(11, WireType.Bit64).double(message.similarity);
        /* bool has_childs = 12; */
        if (message.hasChilds !== false)
            writer.tag(12, WireType.Varint).bool(message.hasChilds);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.AgQuestionModel
 */
export const AgQuestionModel = new AgQuestionModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAgQuestionRequest$Type extends MessageType<ListAgQuestionRequest> {
    constructor() {
        super("proto.nigmamsg.ListAgQuestionRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination },
            { no: 4, name: "ag_poll_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "is_worked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ListAgQuestionRequest>): ListAgQuestionRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.agPollId = 0;
        message.isWorked = false;
        if (value !== undefined)
            reflectionMergePartial<ListAgQuestionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAgQuestionRequest): ListAgQuestionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                case /* int64 ag_poll_id */ 4:
                    message.agPollId = reader.int64().toNumber();
                    break;
                case /* bool is_worked */ 5:
                    message.isWorked = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAgQuestionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int64 ag_poll_id = 4; */
        if (message.agPollId !== 0)
            writer.tag(4, WireType.Varint).int64(message.agPollId);
        /* bool is_worked = 5; */
        if (message.isWorked !== false)
            writer.tag(5, WireType.Varint).bool(message.isWorked);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListAgQuestionRequest
 */
export const ListAgQuestionRequest = new ListAgQuestionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAgQuestionResponse$Type extends MessageType<ListAgQuestionResponse> {
    constructor() {
        super("proto.nigmamsg.ListAgQuestionResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgQuestionModel },
            { no: 2, name: "params", kind: "message", T: () => ListAgQuestionRequest }
        ]);
    }
    create(value?: PartialMessage<ListAgQuestionResponse>): ListAgQuestionResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListAgQuestionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAgQuestionResponse): ListAgQuestionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.AgQuestionModel items */ 1:
                    message.items.push(AgQuestionModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListAgQuestionRequest params */ 2:
                    message.params = ListAgQuestionRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAgQuestionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.AgQuestionModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            AgQuestionModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListAgQuestionRequest params = 2; */
        if (message.params)
            ListAgQuestionRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListAgQuestionResponse
 */
export const ListAgQuestionResponse = new ListAgQuestionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAgQuestionChildsRequest$Type extends MessageType<ListAgQuestionChildsRequest> {
    constructor() {
        super("proto.nigmamsg.ListAgQuestionChildsRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination },
            { no: 4, name: "ag_question_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListAgQuestionChildsRequest>): ListAgQuestionChildsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.agQuestionId = 0;
        if (value !== undefined)
            reflectionMergePartial<ListAgQuestionChildsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAgQuestionChildsRequest): ListAgQuestionChildsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                case /* int64 ag_question_id */ 4:
                    message.agQuestionId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAgQuestionChildsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int64 ag_question_id = 4; */
        if (message.agQuestionId !== 0)
            writer.tag(4, WireType.Varint).int64(message.agQuestionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListAgQuestionChildsRequest
 */
export const ListAgQuestionChildsRequest = new ListAgQuestionChildsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAgQuestionChildsResponse$Type extends MessageType<ListAgQuestionChildsResponse> {
    constructor() {
        super("proto.nigmamsg.ListAgQuestionChildsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgQuestionModel },
            { no: 2, name: "params", kind: "message", T: () => ListAgQuestionChildsRequest }
        ]);
    }
    create(value?: PartialMessage<ListAgQuestionChildsResponse>): ListAgQuestionChildsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListAgQuestionChildsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAgQuestionChildsResponse): ListAgQuestionChildsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.AgQuestionModel items */ 1:
                    message.items.push(AgQuestionModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListAgQuestionChildsRequest params */ 2:
                    message.params = ListAgQuestionChildsRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAgQuestionChildsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.AgQuestionModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            AgQuestionModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListAgQuestionChildsRequest params = 2; */
        if (message.params)
            ListAgQuestionChildsRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListAgQuestionChildsResponse
 */
export const ListAgQuestionChildsResponse = new ListAgQuestionChildsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReduceAgQuestionRequest$Type extends MessageType<ReduceAgQuestionRequest> {
    constructor() {
        super("proto.nigmamsg.ReduceAgQuestionRequest", [
            { no: 1, name: "ag_question_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "reduced_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ReduceAgQuestionRequest>): ReduceAgQuestionRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.agQuestionId = 0;
        message.reducedIds = [];
        if (value !== undefined)
            reflectionMergePartial<ReduceAgQuestionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReduceAgQuestionRequest): ReduceAgQuestionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ag_question_id */ 1:
                    message.agQuestionId = reader.int64().toNumber();
                    break;
                case /* repeated int64 reduced_ids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.reducedIds.push(reader.int64().toNumber());
                    else
                        message.reducedIds.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReduceAgQuestionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ag_question_id = 1; */
        if (message.agQuestionId !== 0)
            writer.tag(1, WireType.Varint).int64(message.agQuestionId);
        /* repeated int64 reduced_ids = 2; */
        if (message.reducedIds.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.reducedIds.length; i++)
                writer.int64(message.reducedIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ReduceAgQuestionRequest
 */
export const ReduceAgQuestionRequest = new ReduceAgQuestionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReduceAgQuestionResponse$Type extends MessageType<ReduceAgQuestionResponse> {
    constructor() {
        super("proto.nigmamsg.ReduceAgQuestionResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ReduceAgQuestionResponse>): ReduceAgQuestionResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.success = false;
        if (value !== undefined)
            reflectionMergePartial<ReduceAgQuestionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReduceAgQuestionResponse): ReduceAgQuestionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReduceAgQuestionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ReduceAgQuestionResponse
 */
export const ReduceAgQuestionResponse = new ReduceAgQuestionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SeparateAgQuestionRequest$Type extends MessageType<SeparateAgQuestionRequest> {
    constructor() {
        super("proto.nigmamsg.SeparateAgQuestionRequest", [
            { no: 2, name: "separated_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SeparateAgQuestionRequest>): SeparateAgQuestionRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.separatedIds = [];
        if (value !== undefined)
            reflectionMergePartial<SeparateAgQuestionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SeparateAgQuestionRequest): SeparateAgQuestionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 separated_ids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.separatedIds.push(reader.int64().toNumber());
                    else
                        message.separatedIds.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SeparateAgQuestionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 separated_ids = 2; */
        if (message.separatedIds.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.separatedIds.length; i++)
                writer.int64(message.separatedIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.SeparateAgQuestionRequest
 */
export const SeparateAgQuestionRequest = new SeparateAgQuestionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SeparateAgQuestionResponse$Type extends MessageType<SeparateAgQuestionResponse> {
    constructor() {
        super("proto.nigmamsg.SeparateAgQuestionResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SeparateAgQuestionResponse>): SeparateAgQuestionResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.success = false;
        if (value !== undefined)
            reflectionMergePartial<SeparateAgQuestionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SeparateAgQuestionResponse): SeparateAgQuestionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SeparateAgQuestionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.SeparateAgQuestionResponse
 */
export const SeparateAgQuestionResponse = new SeparateAgQuestionResponse$Type();
/**
 * @generated ServiceType for protobuf service proto.nigmamsg.AgQuestionService
 */
export const AgQuestionService = new ServiceType("proto.nigmamsg.AgQuestionService", [
    { name: "ListAgQuestion", options: {}, I: ListAgQuestionRequest, O: ListAgQuestionResponse },
    { name: "ListAgQuestionChilds", options: {}, I: ListAgQuestionChildsRequest, O: ListAgQuestionChildsResponse },
    { name: "ReduceAgQuestion", options: {}, I: ReduceAgQuestionRequest, O: ReduceAgQuestionResponse },
    { name: "SeparateAgQuestion", options: {}, I: SeparateAgQuestionRequest, O: SeparateAgQuestionResponse }
]);
