// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "class_not_tag.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { ClassNotTagService } from "./class_not_tag";
import type { SaveClassNotTagResponse } from "./class_not_tag";
import type { SaveClassNotTagRequest } from "./class_not_tag";
import type { DeleteClassNotTagResponse } from "./class_not_tag";
import type { DeleteClassNotTagRequest } from "./class_not_tag";
import type { UpdateClassNotTagResponse } from "./class_not_tag";
import type { UpdateClassNotTagRequest } from "./class_not_tag";
import type { CreateClassNotTagResponse } from "./class_not_tag";
import type { CreateClassNotTagRequest } from "./class_not_tag";
import type { ItemClassNotTagResponse } from "./class_not_tag";
import type { ItemClassNotTagRequest } from "./class_not_tag";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListClassNotTagResponse } from "./class_not_tag";
import type { ListClassNotTagRequest } from "./class_not_tag";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.ClassNotTagService
 */
export interface IClassNotTagServiceClient {
    /**
     * @generated from protobuf rpc: ListClassNotTag(proto.nigmamsg.ListClassNotTagRequest) returns (proto.nigmamsg.ListClassNotTagResponse);
     */
    listClassNotTag(input: ListClassNotTagRequest, options?: RpcOptions): UnaryCall<ListClassNotTagRequest, ListClassNotTagResponse>;
    /**
     * @generated from protobuf rpc: ItemClassNotTag(proto.nigmamsg.ItemClassNotTagRequest) returns (proto.nigmamsg.ItemClassNotTagResponse);
     */
    itemClassNotTag(input: ItemClassNotTagRequest, options?: RpcOptions): UnaryCall<ItemClassNotTagRequest, ItemClassNotTagResponse>;
    /**
     * @generated from protobuf rpc: CreateClassNotTag(proto.nigmamsg.CreateClassNotTagRequest) returns (proto.nigmamsg.CreateClassNotTagResponse);
     */
    createClassNotTag(input: CreateClassNotTagRequest, options?: RpcOptions): UnaryCall<CreateClassNotTagRequest, CreateClassNotTagResponse>;
    /**
     * @generated from protobuf rpc: UpdateClassNotTag(proto.nigmamsg.UpdateClassNotTagRequest) returns (proto.nigmamsg.UpdateClassNotTagResponse);
     */
    updateClassNotTag(input: UpdateClassNotTagRequest, options?: RpcOptions): UnaryCall<UpdateClassNotTagRequest, UpdateClassNotTagResponse>;
    /**
     * @generated from protobuf rpc: DeleteClassNotTag(proto.nigmamsg.DeleteClassNotTagRequest) returns (proto.nigmamsg.DeleteClassNotTagResponse);
     */
    deleteClassNotTag(input: DeleteClassNotTagRequest, options?: RpcOptions): UnaryCall<DeleteClassNotTagRequest, DeleteClassNotTagResponse>;
    /**
     * @generated from protobuf rpc: SaveClassNotTag(proto.nigmamsg.SaveClassNotTagRequest) returns (proto.nigmamsg.SaveClassNotTagResponse);
     */
    saveClassNotTag(input: SaveClassNotTagRequest, options?: RpcOptions): UnaryCall<SaveClassNotTagRequest, SaveClassNotTagResponse>;
}
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.ClassNotTagService
 */
export class ClassNotTagServiceClient implements IClassNotTagServiceClient, ServiceInfo {
    typeName = ClassNotTagService.typeName;
    methods = ClassNotTagService.methods;
    options = ClassNotTagService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ListClassNotTag(proto.nigmamsg.ListClassNotTagRequest) returns (proto.nigmamsg.ListClassNotTagResponse);
     */
    listClassNotTag(input: ListClassNotTagRequest, options?: RpcOptions): UnaryCall<ListClassNotTagRequest, ListClassNotTagResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListClassNotTagRequest, ListClassNotTagResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ItemClassNotTag(proto.nigmamsg.ItemClassNotTagRequest) returns (proto.nigmamsg.ItemClassNotTagResponse);
     */
    itemClassNotTag(input: ItemClassNotTagRequest, options?: RpcOptions): UnaryCall<ItemClassNotTagRequest, ItemClassNotTagResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ItemClassNotTagRequest, ItemClassNotTagResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateClassNotTag(proto.nigmamsg.CreateClassNotTagRequest) returns (proto.nigmamsg.CreateClassNotTagResponse);
     */
    createClassNotTag(input: CreateClassNotTagRequest, options?: RpcOptions): UnaryCall<CreateClassNotTagRequest, CreateClassNotTagResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateClassNotTagRequest, CreateClassNotTagResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateClassNotTag(proto.nigmamsg.UpdateClassNotTagRequest) returns (proto.nigmamsg.UpdateClassNotTagResponse);
     */
    updateClassNotTag(input: UpdateClassNotTagRequest, options?: RpcOptions): UnaryCall<UpdateClassNotTagRequest, UpdateClassNotTagResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateClassNotTagRequest, UpdateClassNotTagResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteClassNotTag(proto.nigmamsg.DeleteClassNotTagRequest) returns (proto.nigmamsg.DeleteClassNotTagResponse);
     */
    deleteClassNotTag(input: DeleteClassNotTagRequest, options?: RpcOptions): UnaryCall<DeleteClassNotTagRequest, DeleteClassNotTagResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteClassNotTagRequest, DeleteClassNotTagResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SaveClassNotTag(proto.nigmamsg.SaveClassNotTagRequest) returns (proto.nigmamsg.SaveClassNotTagResponse);
     */
    saveClassNotTag(input: SaveClassNotTagRequest, options?: RpcOptions): UnaryCall<SaveClassNotTagRequest, SaveClassNotTagResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<SaveClassNotTagRequest, SaveClassNotTagResponse>("unary", this._transport, method, opt, input);
    }
}
