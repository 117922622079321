import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

import { computed, watch, ref } from 'vue'
import { useRoute } from 'vue-router'

type BreadCrumbs = {
  to: string,
  text: string,
  icon: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Breadcrumbs',
  setup(__props) {

const route = useRoute()

const breadCrumbs = computed(() => {
  if (typeof route.meta.breadCrumb === 'function') {
    return route.meta.breadCrumb.call(this, route)
  }
  return route.meta.breadCrumb
})

const breadCrumbsList = ref<BreadCrumbs[]>()

const projects = [
  {
    name: "ng",
    title: "НГ"
  },
  {
    name: "ag",
    title: "АГ"
  },
  {
    name: "gi",
    title: "ГИ"
  },
  {
    name: "gz",
    title: "ГЗ"
  },
  {
    name: "ed",
    title: "ЭД"
  }
];

const isReportPage = () => {
  const currentReportId = projects.findIndex((el) => el.name === route.query.report_type)
  if(route.query.report_type && breadCrumbsList.value){
    breadCrumbsList.value[2].text = 'Отчет по проекту ' + projects[currentReportId].title
  }
}

watch(breadCrumbs, (newValue, oldValue) => {
  breadCrumbsList.value = newValue
  isReportPage()
})

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_breadcrumbs_el = _resolveComponent("q-breadcrumbs-el")!
  const _component_q_breadcrumbs = _resolveComponent("q-breadcrumbs")!

  return (_openBlock(), _createBlock(_component_q_breadcrumbs, {
    class: "row q-px-md q-pt-md text-grey-6",
    "active-color": "blue-6"
  }, {
    separator: _withCtx(() => [
      _createVNode(_component_q_icon, {
        size: "1.5em",
        name: "chevron_right",
        color: "grey-5"
      })
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(breadCrumbsList.value, (item, index) => {
        return (_openBlock(), _createBlock(_component_q_breadcrumbs_el, {
          key: index,
          to: item.to,
          label: item.text,
          icon: item.icon,
          replace: ""
        }, null, 8, ["to", "label", "icon"]))
      }), 128))
    ]),
    _: 1
  }))
}
}

})