// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "ag_answer_class.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { AgAnswerClassService } from "./ag_answer_class";
import type { RemoveAgAnswerClassResponse } from "./ag_answer_class";
import type { RemoveAgAnswerClassRequest } from "./ag_answer_class";
import type { SaveAgAnswerClassResponse } from "./ag_answer_class";
import type { SaveAgAnswerClassRequest } from "./ag_answer_class";
import type { DeleteAgAnswerClassResponse } from "./ag_answer_class";
import type { DeleteAgAnswerClassRequest } from "./ag_answer_class";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { CreateAgAnswerClassResponse } from "./ag_answer_class";
import type { CreateAgAnswerClassRequest } from "./ag_answer_class";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.AgAnswerClassService
 */
export interface IAgAnswerClassServiceClient {
    /**
     * @generated from protobuf rpc: CreateAgAnswerClass(proto.nigmamsg.CreateAgAnswerClassRequest) returns (proto.nigmamsg.CreateAgAnswerClassResponse);
     */
    createAgAnswerClass(input: CreateAgAnswerClassRequest, options?: RpcOptions): UnaryCall<CreateAgAnswerClassRequest, CreateAgAnswerClassResponse>;
    /**
     * @generated from protobuf rpc: DeleteAgAnswerClass(proto.nigmamsg.DeleteAgAnswerClassRequest) returns (proto.nigmamsg.DeleteAgAnswerClassResponse);
     */
    deleteAgAnswerClass(input: DeleteAgAnswerClassRequest, options?: RpcOptions): UnaryCall<DeleteAgAnswerClassRequest, DeleteAgAnswerClassResponse>;
    /**
     * @generated from protobuf rpc: SaveAgAnswerClass(proto.nigmamsg.SaveAgAnswerClassRequest) returns (proto.nigmamsg.SaveAgAnswerClassResponse);
     */
    saveAgAnswerClass(input: SaveAgAnswerClassRequest, options?: RpcOptions): UnaryCall<SaveAgAnswerClassRequest, SaveAgAnswerClassResponse>;
    /**
     * @generated from protobuf rpc: RemoveAgAnswerClass(proto.nigmamsg.RemoveAgAnswerClassRequest) returns (proto.nigmamsg.RemoveAgAnswerClassResponse);
     */
    removeAgAnswerClass(input: RemoveAgAnswerClassRequest, options?: RpcOptions): UnaryCall<RemoveAgAnswerClassRequest, RemoveAgAnswerClassResponse>;
}
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.AgAnswerClassService
 */
export class AgAnswerClassServiceClient implements IAgAnswerClassServiceClient, ServiceInfo {
    typeName = AgAnswerClassService.typeName;
    methods = AgAnswerClassService.methods;
    options = AgAnswerClassService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: CreateAgAnswerClass(proto.nigmamsg.CreateAgAnswerClassRequest) returns (proto.nigmamsg.CreateAgAnswerClassResponse);
     */
    createAgAnswerClass(input: CreateAgAnswerClassRequest, options?: RpcOptions): UnaryCall<CreateAgAnswerClassRequest, CreateAgAnswerClassResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateAgAnswerClassRequest, CreateAgAnswerClassResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteAgAnswerClass(proto.nigmamsg.DeleteAgAnswerClassRequest) returns (proto.nigmamsg.DeleteAgAnswerClassResponse);
     */
    deleteAgAnswerClass(input: DeleteAgAnswerClassRequest, options?: RpcOptions): UnaryCall<DeleteAgAnswerClassRequest, DeleteAgAnswerClassResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteAgAnswerClassRequest, DeleteAgAnswerClassResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SaveAgAnswerClass(proto.nigmamsg.SaveAgAnswerClassRequest) returns (proto.nigmamsg.SaveAgAnswerClassResponse);
     */
    saveAgAnswerClass(input: SaveAgAnswerClassRequest, options?: RpcOptions): UnaryCall<SaveAgAnswerClassRequest, SaveAgAnswerClassResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<SaveAgAnswerClassRequest, SaveAgAnswerClassResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveAgAnswerClass(proto.nigmamsg.RemoveAgAnswerClassRequest) returns (proto.nigmamsg.RemoveAgAnswerClassResponse);
     */
    removeAgAnswerClass(input: RemoveAgAnswerClassRequest, options?: RpcOptions): UnaryCall<RemoveAgAnswerClassRequest, RemoveAgAnswerClassResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<RemoveAgAnswerClassRequest, RemoveAgAnswerClassResponse>("unary", this._transport, method, opt, input);
    }
}
