// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "class_group.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { ClassGroupService } from "./class_group";
import type { DeleteClassGroupResponse } from "./class_group";
import type { DeleteClassGroupRequest } from "./class_group";
import type { UpdateClassGroupResponse } from "./class_group";
import type { UpdateClassGroupRequest } from "./class_group";
import type { CreateClassGroupResponse } from "./class_group";
import type { CreateClassGroupRequest } from "./class_group";
import type { ListClassGroupAccountResponse } from "./class_group";
import type { ListClassGroupAccountRequest } from "./class_group";
import type { ItemClassGroupResponse } from "./class_group";
import type { ItemClassGroupRequest } from "./class_group";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListClassGroupResponse } from "./class_group";
import type { ListClassGroupRequest } from "./class_group";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.ClassGroupService
 */
export interface IClassGroupServiceClient {
    /**
     * @generated from protobuf rpc: ListClassGroup(proto.nigmamsg.ListClassGroupRequest) returns (proto.nigmamsg.ListClassGroupResponse);
     */
    listClassGroup(input: ListClassGroupRequest, options?: RpcOptions): UnaryCall<ListClassGroupRequest, ListClassGroupResponse>;
    /**
     * @generated from protobuf rpc: ItemClassGroup(proto.nigmamsg.ItemClassGroupRequest) returns (proto.nigmamsg.ItemClassGroupResponse);
     */
    itemClassGroup(input: ItemClassGroupRequest, options?: RpcOptions): UnaryCall<ItemClassGroupRequest, ItemClassGroupResponse>;
    /**
     * @generated from protobuf rpc: ListClassGroupAccount(proto.nigmamsg.ListClassGroupAccountRequest) returns (proto.nigmamsg.ListClassGroupAccountResponse);
     */
    listClassGroupAccount(input: ListClassGroupAccountRequest, options?: RpcOptions): UnaryCall<ListClassGroupAccountRequest, ListClassGroupAccountResponse>;
    /**
     * @generated from protobuf rpc: CreateClassGroup(proto.nigmamsg.CreateClassGroupRequest) returns (proto.nigmamsg.CreateClassGroupResponse);
     */
    createClassGroup(input: CreateClassGroupRequest, options?: RpcOptions): UnaryCall<CreateClassGroupRequest, CreateClassGroupResponse>;
    /**
     * @generated from protobuf rpc: UpdateClassGroup(proto.nigmamsg.UpdateClassGroupRequest) returns (proto.nigmamsg.UpdateClassGroupResponse);
     */
    updateClassGroup(input: UpdateClassGroupRequest, options?: RpcOptions): UnaryCall<UpdateClassGroupRequest, UpdateClassGroupResponse>;
    /**
     * @generated from protobuf rpc: DeleteClassGroup(proto.nigmamsg.DeleteClassGroupRequest) returns (proto.nigmamsg.DeleteClassGroupResponse);
     */
    deleteClassGroup(input: DeleteClassGroupRequest, options?: RpcOptions): UnaryCall<DeleteClassGroupRequest, DeleteClassGroupResponse>;
}
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.ClassGroupService
 */
export class ClassGroupServiceClient implements IClassGroupServiceClient, ServiceInfo {
    typeName = ClassGroupService.typeName;
    methods = ClassGroupService.methods;
    options = ClassGroupService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ListClassGroup(proto.nigmamsg.ListClassGroupRequest) returns (proto.nigmamsg.ListClassGroupResponse);
     */
    listClassGroup(input: ListClassGroupRequest, options?: RpcOptions): UnaryCall<ListClassGroupRequest, ListClassGroupResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListClassGroupRequest, ListClassGroupResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ItemClassGroup(proto.nigmamsg.ItemClassGroupRequest) returns (proto.nigmamsg.ItemClassGroupResponse);
     */
    itemClassGroup(input: ItemClassGroupRequest, options?: RpcOptions): UnaryCall<ItemClassGroupRequest, ItemClassGroupResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ItemClassGroupRequest, ItemClassGroupResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListClassGroupAccount(proto.nigmamsg.ListClassGroupAccountRequest) returns (proto.nigmamsg.ListClassGroupAccountResponse);
     */
    listClassGroupAccount(input: ListClassGroupAccountRequest, options?: RpcOptions): UnaryCall<ListClassGroupAccountRequest, ListClassGroupAccountResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListClassGroupAccountRequest, ListClassGroupAccountResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateClassGroup(proto.nigmamsg.CreateClassGroupRequest) returns (proto.nigmamsg.CreateClassGroupResponse);
     */
    createClassGroup(input: CreateClassGroupRequest, options?: RpcOptions): UnaryCall<CreateClassGroupRequest, CreateClassGroupResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateClassGroupRequest, CreateClassGroupResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateClassGroup(proto.nigmamsg.UpdateClassGroupRequest) returns (proto.nigmamsg.UpdateClassGroupResponse);
     */
    updateClassGroup(input: UpdateClassGroupRequest, options?: RpcOptions): UnaryCall<UpdateClassGroupRequest, UpdateClassGroupResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateClassGroupRequest, UpdateClassGroupResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteClassGroup(proto.nigmamsg.DeleteClassGroupRequest) returns (proto.nigmamsg.DeleteClassGroupResponse);
     */
    deleteClassGroup(input: DeleteClassGroupRequest, options?: RpcOptions): UnaryCall<DeleteClassGroupRequest, DeleteClassGroupResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteClassGroupRequest, DeleteClassGroupResponse>("unary", this._transport, method, opt, input);
    }
}
