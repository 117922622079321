import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, unref as _unref, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "row col-12 q-mt-md" }
const _hoisted_2 = {
  class: "col-xl-4 col-lg-4 q-ml-sm",
  style: {"padding-right":"5px"}
}
const _hoisted_3 = {
  class: "col-xl-1 col-lg-2",
  style: {"padding-left":"5px","height":"100%"}
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "text-h6" }
const _hoisted_9 = { class: "text-weight-bold" }

import {onMounted, ref} from 'vue'
import store from "@/store";
import type {QTableColumn} from "quasar";
import date from 'quasar/src/utils/date/date.js';import dom from 'quasar/src/utils/dom/dom.js';;
import PollsFilters from "@/components/modules/polls/components/agMessages/listPoll/PollsFilters.vue";
import {useAgPollApi} from "@/components/modules/polls/services/useAgPollApi";
import {AgPollModel} from "proto/nigma-message-proto/polls/ag_poll";
import {Filter} from "proto/nigma-common-proto/filter";
import {useTextHighlighter} from "@/composables/useTextHighlighter";
import QuestionCombining from "@/components/modules/polls/components/agMessages/listPoll/QuestionCombining.vue";

import style = dom.style;

export default /*@__PURE__*/_defineComponent({
  __name: 'LoadPolls',
  setup(__props) {

const { listAgPollRequest } = useAgPollApi()
const { markedFindValue } = useTextHighlighter()

const loadPollsItems = ref<AgPollModel[]>([])
const loadPollsCols = ref<QTableColumn[]>([
  {
    label: 'ID',
    name: 'ag_poll_id',
    field: 'agPollId',
    sortable: true,
    align: 'center',
    type: 'number',
    headerStyle: 'font-weight: 700',
  },
  {
    label: 'Название',
    name: 'ag_poll_title',
    field: 'agPollTitle',
    sortable: true,
    align: 'left',
    style: 'min-width: 600px; max-width: 600px',
    headerStyle: 'font-weight: 700',
  },
  {
    label: 'Количество участников',
    name: 'ag_poll_user_count',
    field: 'agPollUserCount',
    sortable: true,
    align: 'center',
    type: 'number',
    headerStyle: 'font-weight: 700',
  },
  {
    label: 'Всего ответов',
    name: 'ag_poll_total_answers',
    field: 'agPollTotalAnswers',
    sortable: true,
    align: 'center',
    type: 'number',
    headerStyle: 'font-weight: 700',
  },
  {
    label: 'Открытые ответы',
    name: 'ag_poll_open_answers',
    field: 'agPollOpenAnswers',
    sortable: true,
    align: 'center',
    headerStyle: 'font-weight: 700',
  },
  // {
  //   label: 'Загруженные ответы',
  //   name: 'poll_loaded_answers',
  //   field: 'loaded_answers',
  //   sortable: true,
  //   align: 'center',
  //   headerStyle: 'font-weight: 700',
  // },
  {
    label: 'Дата начала',
    name: 'ag_poll_date_begin',
    field: 'agPollDateBegin',
    sortable: true,
    align: 'center',
    headerStyle: 'font-weight: 700',
  },
  {
    label: 'Дата окончания',
    name: 'ag_poll_date_end',
    field: 'agPollDateEnd',
    sortable: true,
    align: 'center',
    headerStyle: 'font-weight: 700',
  },
  {
    label: 'Дата актуализации данных',
    name: 'ag_poll_date_update',
    field: 'agPollDateUpdate',
    sortable: true,
    align: 'center',
    headerStyle: 'font-weight: 700',
  },
  {
    label: 'Группа опросов',
    name: 'ag_poll_group',
    field: 'agPollGroup',
    sortable: true,
    align: 'center',
    headerStyle: 'font-weight: 700',
  },
  {
    label: 'Описание',
    name: 'ag_poll_notes',
    field: 'agPollNotes',
    sortable: true,
    align: 'center',
    headerStyle: 'font-weight: 700',
  },
  // {
  //   label: 'Статус',
  //   name: 'poll_flag',
  //   field: 'poll_flag',
  //   align: 'center',
  //   headerStyle: 'font-weight: 700',
  // },
])
const loadPollsSelected = ref<AgPollModel[]>([])
const loadPollsPagination = ref({
  sortBy: 'ag_poll_total_answers',
  descending: true,
  page: 1,
  rowsPerPage: 25,
  rowsNumber: 0,
})
const search = ref('')
const markedFind = ref<string[]>([])
const sideFilters = ref<Filter>({
  items: []
})
const filtersRef = ref<InstanceType<typeof HTMLDivElement>>()
const sideFilterLabel = ref('ФИЛЬТРЫ')
const pollLoadRef = ref<InstanceType<typeof HTMLDivElement>>()
const rootRef = ref<InstanceType<typeof HTMLDivElement>>()
// const statusFlag = ref(false)
const questionModal = ref(false)

// const loadPollsRows = computed(() => {
//   const rawList = store.getters.getListPollLoadItems
//   const filteredList = []
//
//   if (rawList) {
//     for (const i of rawList) {
//       filteredList.push({
//         poll_id: i.getPollId(),
//         title: i.getPollTitle(),
//         user_count: i.getPollUserCount().toLocaleString(),
//         total_answers: i.getPollTotalAnswers().toLocaleString(),
//         open_answers: i.getPollOpenAnswers().toLocaleString(),
//         loaded_answers: i.getPollLoadedAnswers().toLocaleString(),
//         date_begin: date.formatDate(i.getPollDateBegin().getSeconds() * 1000, 'DD.MM.YYYY'),
//         date_end: date.formatDate(i.getPollDateEnd().getSeconds() * 1000, 'DD.MM.YYYY'),
//         date_update:
//             i.getPollDateUpdate().getSeconds()
//                 ? date.formatDate(i.getPollDateUpdate().getSeconds() * 1000, 'DD.MM.YYYY')
//                 : 'Опрос не загружен',
//         poll_group: i.getPollGroup(),
//         poll_note: i.getPollNote(),
//         poll_flag: i.getPollLoadingFlag(),
//       })
//     }
//   }
//
//   return filteredList
// })

const loadListPollLoad = (isResetPagination: boolean) => {
  if (isResetPagination) {
    loadPollsPagination.value.page = 1
    loadPollsPagination.value.rowsNumber = 0
  }

  listAgPollRequest({
    filter: sideFilters.value,
    sort: {
      name: loadPollsPagination.value.sortBy,
      exp: loadPollsPagination.value.descending ? 'DESC' : 'ASC',
    },
    pagination: {
      page: loadPollsPagination.value.page,
      limit: loadPollsPagination.value.rowsPerPage,
      pages: 0,
      cnt: 0,
    }
  })
      .then(res => {
        loadPollsPagination.value.rowsNumber = res.params.pagination.cnt
        loadPollsItems.value = res.items
      })
}
const loadPollsTableRequest = (props: any) => {
  loadPollsPagination.value = props.pagination
  loadListPollLoad(false)
}
// const loadPolls = () => {
//   store.dispatch('loadPolls', {
//     poll_id: loadPollsSelected.value[0].agPollId
//   })
//       .then(() => {
//         loadListPollLoad(true)
//         loadPollsSelected.value = []
//       })
//       .then(() => {
//         checkLoadStatus(true)
//       })
// }
const findAnswersResult = () => {
  markedFind.value = []
  if (search.value.length) {
    markedFind.value.push(search.value)
  }

  if (sideFilters.value.items.length || sideFilters.value.items.length === 1) {
    sideFilters.value.items[0].value = search.value
    sideFilters.value.items[0].or.items[0].value = search.value
  } else {
    if (search.value !== '') {
      sideFilters.value.items = [
        {
          fieldName: 'ap.ag_poll_title',
          operand: 'ilike',
          value: search.value,
          or: {
            items: [
              {
                fieldName: 'ap.ag_poll_id::text',
                operand: 'ilike',
                value: search.value
              }
            ]
          }
        }
      ]
    }
  }


  // Подгрузка ответов учитывая поиск
  loadListPollLoad(true) // true - переключаем на первую страницу
}
const sideFiltersFn = (filters: any) => {
  sideFilters.value = filters

  loadListPollLoad(false)
}
const toggleFilter = () => {
  let toggle = 1

  return function () {
    toggle ^= 1
    if (!toggle) {
      sideFilterLabel.value = 'Скрыть фильтры'
    } else {
      sideFilterLabel.value = 'Фильтры'
    }
    const filtersWidth = style(filtersRef.value, 'width')

    if (!toggle) {
      pollLoadRef.value.style.paddingRight = filtersWidth
      filtersRef.value.style.transform = 'translateX(0)'
      rootRef.value.style.overflowY = 'visible'
      rootRef.value.style.overflowX = 'visible'
    } else {
      pollLoadRef.value.style.paddingRight = '0px'
      filtersRef.value.style.transform = 'translateX(100%)'
      rootRef.value.style.overflowX = 'hidden'
      rootRef.value.style.overflowY = 'hidden'
    }
  }
}
const toggleSideFilters = toggleFilter()
// const checkLoadStatus = (isLoading: boolean) => {
//   let timeout
//
//   if (timeout) clearTimeout(timeout)
//
//   timeout = setTimeout(() => {
//     store
//         .dispatch('checkLoadStatus')
//         .then((res) => {
//           console.log('LOAD POLLS STATUS', res.getIsloading())
//           statusFlag.value = true
//
//           if (!res.getIsloading()) {
//             loadListPollLoad(false)
//
//             if (isLoading) {
//               $q.notify({
//                 type: 'positive',
//                 message: 'Опрос успешно загружен'
//               })
//             }
//
//             statusFlag.value = false
//           }
//
//           if (statusFlag.value) {
//             checkLoadStatus(true)
//           }
//         })
//   }, 5000)
// }

const questionsRef = ref()

// const getQuestions = () => {
//   questionModal.value = true
//
//   store
//       .dispatch('getQuestionItems', {
//         poll_id: props.pollId,
//         pagination: {
//           page: 1,
//           limit: 100,
//           pages: 0,
//           cnt: 0,
//         },
//       })
//       .then(res => {
//         console.log(res)
//
//       })
// }

const getQuestions = () => {
  questionModal.value = true
}

const clearItem = () => store.dispatch('setCurrentQuestion', null)

const loadData = () => {
  loadListPollLoad(true)
  // checkLoadStatus(false)
}

onMounted(() => {
  loadData()
})

return (_ctx: any,_cache: any) => {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", {
    class: "col-12 q-pa-md relative-position",
    ref_key: "rootRef",
    ref: rootRef,
    style: {"overflow-x":"hidden"}
  }, [
    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "text-h4" }, "Загрузка (обновление) данных", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_q_btn, {
        label: "Объединить",
        color: "secondary",
        style: {"height":"56px"},
        onClick: getQuestions,
        disable: !loadPollsSelected.value.length
      }, {
        default: _withCtx(() => [
          (!loadPollsSelected.value.length)
            ? (_openBlock(), _createBlock(_component_q_tooltip, {
                key: 0,
                class: "text-subtitle1"
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" Выберите опрос ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (loadPollsSelected.value.length)
            ? (_openBlock(), _createBlock(_component_q_tooltip, {
                key: 1,
                class: "text-subtitle1"
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" Объединить вопросы для опроса ")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["disable"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_q_input, {
          modelValue: search.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
          filled: "",
          label: "Поиск по ID или названию",
          onKeydown: _withKeys(_withModifiers(findAnswersResult, ["prevent"]), ["enter"])
        }, null, 8, ["modelValue", "onKeydown"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_q_btn, {
          style: {"padding":"10px","margin-top":"6px","margin-right":"1px"},
          "background-color": "gray-12",
          icon: "mdi-magnify",
          onClick: findAnswersResult
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tooltip, { class: "text-subtitle1" }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("Найти опросы")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_q_btn, {
        label: sideFilterLabel.value,
        color: "secondary",
        class: "q-ml-auto",
        onClick: _unref(toggleSideFilters)
      }, null, 8, ["label", "onClick"])
    ]),
    _createElementVNode("div", {
      ref_key: "pollLoadRef",
      ref: pollLoadRef,
      style: {"transition":"all 0.3s ease-out"}
    }, [
      _createVNode(_component_q_table, {
        class: "q-mt-md sticky-header-table-answer shadow-4",
        columns: loadPollsCols.value,
        rows: loadPollsItems.value,
        "row-key": "agPollId",
        selection: "single",
        selected: loadPollsSelected.value,
        "onUpdate:selected": _cache[1] || (_cache[1] = ($event: any) => ((loadPollsSelected).value = $event)),
        pagination: loadPollsPagination.value,
        "onUpdate:pagination": _cache[2] || (_cache[2] = ($event: any) => ((loadPollsPagination).value = $event)),
        onRequest: loadPollsTableRequest,
        style: _normalizeStyle(loadPollsItems.value.length ? 'height: 73vh' : null),
        "binary-state-sort": "",
        "rows-per-page-options": [10, 25, 50, 100]
      }, {
        body: _withCtx((props) => [
          _createVNode(_component_q_tr, {
            props: props,
            class: _normalizeClass(props.row.poll_flag ? 'bg-grey-5' : null)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_td, { style: {"position":"relative"} }, {
                default: _withCtx(() => [
                  (!props.row.poll_flag)
                    ? (_openBlock(), _createBlock(_component_q_checkbox, {
                        key: 0,
                        modelValue: props.selected,
                        "onUpdate:modelValue": ($event: any) => ((props.selected) = $event),
                        disable: props.row.poll_flag || props.row.open_answers == 0,
                        style: {"position":"relative"}
                      }, {
                        default: _withCtx(() => [
                          (props.row.poll_flag)
                            ? (_openBlock(), _createBlock(_component_q_tooltip, {
                                key: 0,
                                class: "text-subtitle1"
                              }, {
                                default: _withCtx(() => _cache[8] || (_cache[8] = [
                                  _createTextVNode(" Опрос загружается ")
                                ])),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (props.row.open_answers == 0)
                            ? (_openBlock(), _createBlock(_component_q_tooltip, {
                                key: 1,
                                class: "text-subtitle1"
                              }, {
                                default: _withCtx(() => _cache[9] || (_cache[9] = [
                                  _createTextVNode(" Невозможно загрузить опрос, т.к. количество открытых ответов равно нулю ")
                                ])),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["modelValue", "onUpdate:modelValue", "disable"]))
                    : (_openBlock(), _createBlock(_component_q_inner_loading, {
                        key: 1,
                        showing: props.row.poll_flag,
                        color: "grey-7"
                      }, null, 8, ["showing"]))
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_q_td, {
                props: props,
                key: "ag_poll_id"
              }, {
                default: _withCtx(() => [
                  (markedFind.value.length)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        innerHTML: _unref(markedFindValue)(props.row.agPollId.toString(), markedFind.value)
                      }, null, 8, _hoisted_4))
                    : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(props.row.agPollId.toString()), 1))
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                props: props,
                key: "ag_poll_title",
                class: "ellipsis",
                title: props.row.agPollTitle
              }, {
                default: _withCtx(() => [
                  (markedFind.value.length)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        innerHTML: _unref(markedFindValue)(props.row.agPollTitle, markedFind.value)
                      }, null, 8, _hoisted_6))
                    : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(props.row.agPollTitle), 1))
                ]),
                _: 2
              }, 1032, ["props", "title"]),
              _createVNode(_component_q_td, {
                props: props,
                key: "ag_poll_user_count"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.agPollUserCount), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                props: props,
                key: "ag_poll_total_answers"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.agPollTotalAnswers), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                props: props,
                key: "ag_poll_open_answers"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.agPollOpenAnswers), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                props: props,
                key: "ag_poll_date_begin"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(date).formatDate(props.row.agPollDateBegin, 'DD.MM.YYYY')), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                props: props,
                key: "ag_poll_date_end"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(date).formatDate(props.row.agPollDateEnd, 'DD.MM.YYYY')), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                props: props,
                key: "ag_poll_date_update"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(date).formatDate(props.row.agPollDateUpdate, 'DD.MM.YYYY')), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                props: props,
                key: "ag_poll_group"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.agPollGroup), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                props: props,
                key: "ag_poll_notes"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.agPollNotes), 1)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            _: 2
          }, 1032, ["props", "class"])
        ]),
        pagination: _withCtx((scope) => [
          _createTextVNode(_toDisplayString((scope.pagination.rowsPerPage * scope.pagination.page - scope.pagination.rowsPerPage + 1).toLocaleString()) + " - " + _toDisplayString(scope.pagination.rowsPerPage * scope.pagination.page > scope.pagination.rowsNumber
            ? scope.pagination.rowsNumber.toLocaleString()
            : (scope.pagination.rowsPerPage * scope.pagination.page).toLocaleString()) + " из " + _toDisplayString(scope.pagination.rowsNumber.toLocaleString()) + " ", 1),
          (scope.pagesNumber > 2)
            ? (_openBlock(), _createBlock(_component_q_btn, {
                key: 0,
                icon: "first_page",
                color: "grey-8",
                round: "",
                dense: "",
                flat: "",
                disable: scope.isFirstPage,
                onClick: scope.firstPage,
                class: "q-ml-md"
              }, null, 8, ["disable", "onClick"]))
            : _createCommentVNode("", true),
          _createVNode(_component_q_btn, {
            icon: "chevron_left",
            color: "grey-8",
            round: "",
            dense: "",
            flat: "",
            disable: scope.isFirstPage,
            onClick: scope.prevPage
          }, null, 8, ["disable", "onClick"]),
          _createVNode(_component_q_btn, {
            icon: "chevron_right",
            color: "grey-8",
            round: "",
            dense: "",
            flat: "",
            disable: scope.isLastPage,
            onClick: scope.nextPage
          }, null, 8, ["disable", "onClick"]),
          (scope.pagesNumber > 2)
            ? (_openBlock(), _createBlock(_component_q_btn, {
                key: 1,
                icon: "last_page",
                color: "grey-8",
                round: "",
                dense: "",
                flat: "",
                disable: scope.isLastPage,
                onClick: scope.lastPage
              }, null, 8, ["disable", "onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["columns", "rows", "selected", "pagination", "style"])
    ], 512),
    _createElementVNode("div", {
      ref_key: "filtersRef",
      ref: filtersRef,
      class: "polls-filters"
    }, [
      _createVNode(PollsFilters, { onOnFilters: sideFiltersFn })
    ], 512),
    _createVNode(_component_q_dialog, {
      modelValue: questionModal.value,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((questionModal).value = $event)),
      onHide: clearItem
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_card, {
          class: "q-pa-lg relative-position",
          style: {"max-width":"99%","width":"100%"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _cache[10] || (_cache[10] = _createTextVNode("Объединение вопросов по опросу: ")),
              _createElementVNode("span", _hoisted_9, "\"" + _toDisplayString(loadPollsSelected.value[0].agPollTitle) + "\"", 1)
            ]),
            _createVNode(QuestionCombining, {
              "poll-id": loadPollsSelected.value[0].agPollId,
              ref_key: "questionsRef",
              ref: questionsRef
            }, null, 8, ["poll-id"]),
            _createVNode(_component_q_inner_loading, {
              showing: _ctx.isQuestionBusy,
              color: "grey"
            }, null, 8, ["showing"]),
            _createVNode(_component_q_btn, {
              rounded: "",
              icon: "close",
              color: "red",
              dense: "",
              class: "absolute",
              style: {"right":"15px","top":"15px"},
              onClick: _cache[3] || (_cache[3] = ($event: any) => (questionModal.value = false))
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 512))
}
}

})