import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "metric-input-dropdown" }

import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import MetricChoise from '@/components/MetricsChoice.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'MetricSelect',
  setup(__props) {

const metricInputFilter = ref('')
const isDropdownOpen = ref(false)
const dropdownRef = ref()

onClickOutside(dropdownRef, (e: any) => {
  e.target.closest('.metric-input') ? null : showDropDown(false)
})

const showDropDown = (val: boolean) => (isDropdownOpen.value = val)

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createElementBlock("div", {
    class: "metric-input col",
    ref_key: "dropdownRef",
    ref: dropdownRef,
    style: {"max-width":"356px","width":"100%"}
  }, [
    _createVNode(_component_q_input, {
      modelValue: metricInputFilter.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((metricInputFilter).value = $event)),
      filled: "",
      label: 'Метрики',
      clearable: "",
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (showDropDown(true)))
    }, {
      append: _withCtx(() => [
        _createVNode(_component_q_icon, {
          name: isDropdownOpen.value ? 'fas fa-caret-up' : 'fas fa-caret-down'
        }, null, 8, ["name"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createVNode(MetricChoise)
    ], 512), [
      [_vShow, isDropdownOpen.value]
    ])
  ], 512))
}
}

})