import script from "./ClsEdit.vue?vue&type=script&setup=true&lang=ts"
export * from "./ClsEdit.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QCheckbox,QBtn});
