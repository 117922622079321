import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { style: {"margin":"10px"} }
const _hoisted_2 = {
  class: "row",
  style: {"height":"100%"}
}
const _hoisted_3 = {
  class: "col-12",
  style: {"height":"100%","overflow":"hidden"}
}
const _hoisted_4 = { class: "col-4" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "row justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_ProjectAdd = _resolveComponent("ProjectAdd")!
  const _component_ProjectEdit = _resolveComponent("ProjectEdit")!
  const _component_ProjectDelete = _resolveComponent("ProjectDelete")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-h4 q-pb-md" }, "Справочник проектов", -1)),
    _createVNode(_component_q_card, { style: {"padding":"10px","height":"80vh","box-shadow":"none"} }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_q_table, {
              flat: "",
              style: {"height":"100%","overflow":"auto"},
              rows: _ctx.getProjectsItems,
              columns: _ctx.columns,
              "row-key": "id",
              loading: _ctx.isProjectsBusy,
              pagination: _ctx.projectPagination,
              "onUpdate:pagination": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.projectPagination) = $event)),
              "rows-per-page-options": _ctx.rowsPerPageProjectOptions,
              onRequest: _ctx.onRequestProject
            }, {
              top: _withCtx(() => [
                ('admin editor'.includes(_ctx.userRole))
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 0,
                      color: "primary",
                      disable: _ctx.isProjectsBusy,
                      label: "Добавить",
                      icon: "mdi-plus",
                      onClick: _ctx.addProject
                    }, null, 8, ["disable", "onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_q_space),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    modelValue: _ctx.filter,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event)),
                    filled: "",
                    onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onInputSearchControl($event)))
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_q_icon, { name: "mdi-magnify" })
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ])
              ]),
              "body-cell-short_name": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    (_ctx.search.length)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          innerHTML: _ctx.strMarkRedPlus(_ctx.search, props.value)
                        }, null, 8, _hoisted_5))
                      : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(props.value), 1))
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-name": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    (_ctx.search.length)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          innerHTML: _ctx.strMarkRedPlus(_ctx.search, props.value)
                        }, null, 8, _hoisted_7))
                      : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(props.value), 1))
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "body-cell-action": _withCtx((props) => [
                _createVNode(_component_q_td, { props: props }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_9, [
                      ('admin editor'.includes(_ctx.userRole))
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 0,
                            flat: "",
                            dense: "",
                            round: "",
                            icon: "mdi-dots-vertical"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_menu, { "auto-close": "" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_list, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_q_item, {
                                        clickable: "",
                                        onClick: ($event: any) => (_ctx.actionRow('edit', props.value))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_item_section, null, {
                                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                                              _createTextVNode("Редактировать")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"]),
                                      _createVNode(_component_q_item, {
                                        clickable: "",
                                        onClick: ($event: any) => (_ctx.actionRow('delete', props.value))
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_q_item_section, null, {
                                            default: _withCtx(() => _cache[5] || (_cache[5] = [
                                              _createTextVNode("Удалить")
                                            ])),
                                            _: 1
                                          })
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"])
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              "no-data": _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("div", { class: "col-12 text-center" }, "Нет данных", -1)
              ])),
              _: 1
            }, 8, ["rows", "columns", "loading", "pagination", "rows-per-page-options", "onRequest"])
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_q_dialog, {
      modelValue: _ctx.isActiveModal,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isActiveModal) = $event)),
      persistent: ""
    }, {
      default: _withCtx(() => [
        (_ctx.isActiveModalAdd)
          ? (_openBlock(), _createBlock(_component_ProjectAdd, {
              key: 0,
              onAfterAction: _ctx.afterAction,
              onHideModal: _ctx.hideModal
            }, null, 8, ["onAfterAction", "onHideModal"]))
          : _createCommentVNode("", true),
        (_ctx.isActiveModalEdit)
          ? (_openBlock(), _createBlock(_component_ProjectEdit, {
              key: 1,
              projectId: _ctx.selectedProjectId,
              onAfterAction: _ctx.afterAction,
              onHideModal: _ctx.hideModal
            }, null, 8, ["projectId", "onAfterAction", "onHideModal"]))
          : _createCommentVNode("", true),
        (_ctx.isActiveModalDelete)
          ? (_openBlock(), _createBlock(_component_ProjectDelete, {
              key: 2,
              projectId: _ctx.selectedProjectId,
              onAfterAction: _ctx.afterAction,
              onHideModal: _ctx.hideModal
            }, null, 8, ["projectId", "onAfterAction", "onHideModal"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}