// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "proto/nigma-common-proto/filter.proto" (package "proto", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * Элемент фильтра
 *
 * @generated from protobuf message proto.FilterItem
 */
export interface FilterItem {
    /**
     * @generated from protobuf field: string field_name = 1;
     */
    fieldName: string;
    /**
     * @generated from protobuf field: string operand = 2;
     */
    operand: string;
    /**
     * @generated from protobuf field: string value = 3;
     */
    value: string;
    /**
     * @generated from protobuf field: optional proto.Filter or = 4;
     */
    or?: Filter;
}
/**
 * Фильтр
 *
 * @generated from protobuf message proto.Filter
 */
export interface Filter {
    /**
     * @generated from protobuf field: repeated proto.FilterItem items = 1;
     */
    items: FilterItem[];
}
/**
 * Правило сортировки
 *
 * @generated from protobuf message proto.Sort
 */
export interface Sort {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string exp = 2;
     */
    exp: string;
}
/**
 * Правила пагинации
 *
 * @generated from protobuf message proto.Pagination
 */
export interface Pagination {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 limit = 2;
     */
    limit: number;
    /**
     * @generated from protobuf field: int64 pages = 3;
     */
    pages: number;
    /**
     * @generated from protobuf field: int64 cnt = 4;
     */
    cnt: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class FilterItem$Type extends MessageType<FilterItem> {
    constructor() {
        super("proto.FilterItem", [
            { no: 1, name: "field_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "operand", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "or", kind: "message", T: () => Filter }
        ]);
    }
    create(value?: PartialMessage<FilterItem>): FilterItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.fieldName = "";
        message.operand = "";
        message.value = "";
        if (value !== undefined)
            reflectionMergePartial<FilterItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FilterItem): FilterItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string field_name */ 1:
                    message.fieldName = reader.string();
                    break;
                case /* string operand */ 2:
                    message.operand = reader.string();
                    break;
                case /* string value */ 3:
                    message.value = reader.string();
                    break;
                case /* optional proto.Filter or */ 4:
                    message.or = Filter.internalBinaryRead(reader, reader.uint32(), options, message.or);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FilterItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string field_name = 1; */
        if (message.fieldName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.fieldName);
        /* string operand = 2; */
        if (message.operand !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.operand);
        /* string value = 3; */
        if (message.value !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.value);
        /* optional proto.Filter or = 4; */
        if (message.or)
            Filter.internalBinaryWrite(message.or, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.FilterItem
 */
export const FilterItem = new FilterItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Filter$Type extends MessageType<Filter> {
    constructor() {
        super("proto.Filter", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FilterItem }
        ]);
    }
    create(value?: PartialMessage<Filter>): Filter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<Filter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Filter): Filter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.FilterItem items */ 1:
                    message.items.push(FilterItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Filter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.FilterItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            FilterItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.Filter
 */
export const Filter = new Filter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Sort$Type extends MessageType<Sort> {
    constructor() {
        super("proto.Sort", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "exp", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Sort>): Sort {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.exp = "";
        if (value !== undefined)
            reflectionMergePartial<Sort>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Sort): Sort {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string exp */ 2:
                    message.exp = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Sort, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string exp = 2; */
        if (message.exp !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.exp);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.Sort
 */
export const Sort = new Sort$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Pagination$Type extends MessageType<Pagination> {
    constructor() {
        super("proto.Pagination", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "limit", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "pages", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "cnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<Pagination>): Pagination {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.limit = 0;
        message.pages = 0;
        message.cnt = 0;
        if (value !== undefined)
            reflectionMergePartial<Pagination>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Pagination): Pagination {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 limit */ 2:
                    message.limit = reader.int64().toNumber();
                    break;
                case /* int64 pages */ 3:
                    message.pages = reader.int64().toNumber();
                    break;
                case /* int64 cnt */ 4:
                    message.cnt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Pagination, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 limit = 2; */
        if (message.limit !== 0)
            writer.tag(2, WireType.Varint).int64(message.limit);
        /* int64 pages = 3; */
        if (message.pages !== 0)
            writer.tag(3, WireType.Varint).int64(message.pages);
        /* int64 cnt = 4; */
        if (message.cnt !== 0)
            writer.tag(4, WireType.Varint).int64(message.cnt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.Pagination
 */
export const Pagination = new Pagination$Type();
