import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import store from '@/store'
import { ref, onMounted, computed, watchEffect } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'CoreAnalyze',
  setup(__props) {

const chartOptions = ref({
  chart: {
    type: 'bar',
  },
  colors: ['#FFB1C1', '#9AD0F5'],
  title: { text: '' },
  xAxis: {
    visible: false,
    // categories: ['2020/21', '2019/20', '2018/19', '2017/18', '2016/17'],
  },
  yAxis: {
    visible: false,
    title: {
      text: '',
    },
  },
  tooltip: {
    headerFormat: '',
  },
  legend: {
    enabled: false,
    reversed: false,
    align: 'left',
  },
  plotOptions: {
    series: {
      stacking: 'normal',
      dataLabels: {
        enabled: true,
      },
    },
  },
  series: [],
}) as any

// const getProjectIds = computed(() => store.getters.getSelectedProjectItems)
const isActiveCoreBusy = computed(() => store.getters.isActiveCoreBusy)
// const date = computed(() => store.getters.getDateForEventList)
// const settings = computed(() => store.getters.getModalSettings)
// const checkedMetrics = computed(() => store.getters.getCheckedMetrics)

watchEffect(() => {
  let coreActiveValue = store.getters.getActiveCoreValues
  if (coreActiveValue && coreActiveValue.getCntShare()) {
    let cntShare = coreActiveValue.getCntShare()
    let cntOther = coreActiveValue.getCntOther()
    let prcShare = Math.round((cntShare / (cntShare + cntOther)) * 100)
    let prcOther = Math.round((cntOther / (cntShare + cntOther)) * 100)

    chartOptions.value.series = [
      {
        dataLabels: [
          { format: '{point.y}' },
          { format: '{point.prc}%', verticalAlign: 'bottom', y: -30 },
        ],
        name: 'Ядро',
        data: [{ y: cntShare, prc: prcShare }],
      },
      {
        dataLabels: [
          { format: '{point.y}' },
          { format: '{point.prc}%', verticalAlign: 'bottom', y: -30 },
        ],
        name: 'Другие',
        data: [{ y: cntOther, prc: prcOther }],
      },
    ]
  } else {
    chartOptions.value.series = []
  }
})

// onMounted(() => {
//   store
//     .dispatch('getActiveCore', {
//       date_from: date.value.dateFrom || '2012-01-01',
//       date_to: date.value.dateTo || new Date().toJSON().slice(0, 10),
//       project_ids: getProjectIds.value || [1, 2, 3, 4, 5, 6],
//       value: settings.value.sumActivity || 0.8,
//       from_ids: checkedMetrics.value.from || [1, 2, 3, 4],
//       to_ids: checkedMetrics.value.to || [5, 6, 7, 8],
//       from_name: checkedMetrics.value.fromName || 'Активность',
//       to_name: checkedMetrics.value.toName || 'Лояльность',
//       add_param: settings.value.addParameters || false,
//     })
//     .catch(() => store.commit('setIsActiveCoreBusy', false))
// })

return (_ctx: any,_cache: any) => {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_highcharts = _resolveComponent("highcharts")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_q_card_section, {
          horizontal: "",
          style: {"height":"278px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_card_section, {
              style: {"width":"280px"},
              class: "column justify-center items-center"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("div", { class: "column" }, [
                  _createElementVNode("span", { style: {"margin-bottom":"10px"} }, "Легенда"),
                  _createElementVNode("div", {
                    class: "colors column",
                    style: {"row-gap":"5px"}
                  }, [
                    _createElementVNode("div", { class: "core row" }, [
                      _createElementVNode("div", { class: "color" }),
                      _createElementVNode("div", { class: "text" }, "Ядро")
                    ]),
                    _createElementVNode("div", { class: "else row" }, [
                      _createElementVNode("div", { class: "color" }),
                      _createElementVNode("div", { class: "text" }, "Другие")
                    ])
                  ])
                ], -1)
              ])),
              _: 1
            }),
            _createVNode(_component_highcharts, {
              class: "hc",
              options: chartOptions.value,
              ref: "chart"
            }, null, 8, ["options"]),
            _createVNode(_component_q_inner_loading, {
              showing: isActiveCoreBusy.value,
              color: "grey"
            }, null, 8, ["showing"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})