import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, unref as _unref } from "vue"

const _hoisted_1 = { class: "sql-builder__constructor--input" }
const _hoisted_2 = { class: "sql-builder__constructor--input" }
const _hoisted_3 = {
  tabindex: "0",
  class: "select no-outline full-width"
}
const _hoisted_4 = { class: "sql-builder__constructor--input" }
const _hoisted_5 = { class: "sql-builder__constructor--input selected" }
const _hoisted_6 = { class: "sql-builder__constructor--input" }
const _hoisted_7 = { class: "sql-builder__constructor--input" }
const _hoisted_8 = { class: "row items-center justify-end" }
const _hoisted_9 = { class: "sql-builder__constructor--input" }
const _hoisted_10 = {
  key: 0,
  class: "sql-builder__constructor--input"
}
const _hoisted_11 = { class: "sql-builder__constructor--input" }

import { ref } from 'vue'
import type { СonstructorForm, FlowSource, BuilderTab } from '../../views/sql-builder/SqlQueryBuilder.vue'
import { SourceTreeChildren } from '../../services/api/SqlBuilderApi';
import draggable from 'vuedraggable'

type Emits = {
    deleteCol: [id: number],
    setSource: [source: SourceTreeChildren, id: number]
}

type Change = {
    moved: {
        element: СonstructorForm,
        newIndex: number,
        oldIndex: number
    }
}



export default /*@__PURE__*/_defineComponent({
  __name: 'FormQuery',
  props: {
    "query": { required: true },
    "queryModifiers": {},
    "flow": { required: true },
    "flowModifiers": {},
    "activeTab": { required: true },
    "activeTabModifiers": {},
    "grouping": { type: Boolean },
    "groupingModifiers": {},
  },
  emits: /*@__PURE__*/_mergeModels(["deleteCol", "setSource"], ["update:query", "update:flow", "update:activeTab", "update:grouping"]),
  setup(__props, { emit: __emit }) {

//import { useSortable } from '@vueuse/integrations/useSortable'
const query = _useModel<СonstructorForm[]>(__props, 'query')
const flow = _useModel<FlowSource[]>(__props, 'flow')
const activeTab = _useModel<BuilderTab>(__props, 'activeTab')
const grouping = _useModel<boolean>(__props, 'grouping')
const emit = __emit

const checkSelected = (row: СonstructorForm) => {
    if (!row.selected) row.group = ''
    if (row.selected) row.group = 'group'
}

// const changeLogicalOperator = (row: СonstructorForm) => {
//     row.logicalOperator === '-' ? row.logicalOperator = 'И' :
//         row.logicalOperator === 'И' ? row.logicalOperator = 'ИЛИ' : row.logicalOperator = '-'
// }

const checkElementPosition = (row: Change) => {
    const item = row.moved.element
    if (activeTab.value.query.indexOf(item) + 1 === activeTab.value.query.length) {
        item.logicalOperator = '-'
    }
}

const sortType = ref([
    {
        label: 'Не сортировать',
        value: 'unset'
    },
    {
        label: 'По возрастанию',
        value: 'asc'
    },
    {
        label: 'По убыванию',
        value: 'desc'
    },
])

const groupType = ref([
    {
        label: 'Группировать',
        value: 'group'
    },
    {
        label: 'count',
        value: 'count'
    },
    {
        label: 'max',
        value: 'max'
    },
    {
        label: 'min',
        value: 'min'
    },
    {
        label: 'sum',
        value: 'sum'
    },
    {
        label: 'avg',
        value: 'avg'
    },
    {
        label: 'distinct count',
        value: 'distinct'
    },
    {
        label: 'Условие',
        value: '',
        inactive: true
    }
])

const constructorOperand = ref([
    {
        label: 'Равно',
        value: '='
    },
    {
        label: 'Меньше',
        value: '<'
    },
    {
        label: 'Больше',
        value: '>'
    },
    {
        label: 'Не равно',
        value: '<>'
    },
    {
        label: 'in',
        value: 'in'
    },
    {
        label: 'like',
        value: 'like'
    },
    {
        label: 'ilike',
        value: 'ilike'
    }
])


return (_ctx: any,_cache: any) => {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tree = _resolveComponent("q-tree")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_field = _resolveComponent("q-field")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (query.value.length > 0)
    ? (_openBlock(), _createBlock(_unref(draggable), {
        key: 0,
        modelValue: query.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((query).value = $event)),
        "item-key": "id",
        onChange: checkElementPosition
      }, {
        item: _withCtx(({ element }) => [
          _createElementVNode("div", {
            class: _normalizeClass(["sql-builder__constructor--col", query.value[query.value.indexOf(element) + 1] ? 'logical-operator' : ''])
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_q_input, {
                modelValue: element.tableName,
                "onUpdate:modelValue": ($event: any) => ((element.tableName) = $event),
                type: "text",
                label: "Название таблицы",
                dense: "",
                outlined: "",
                disable: "",
                readonly: ""
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_q_field, {
                modelValue: element.label,
                "onUpdate:modelValue": ($event: any) => ((element.label) = $event),
                label: "Название поля",
                outlined: "",
                dense: ""
              }, {
                control: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(element.label), 1)
                ]),
                append: _withCtx(() => [
                  _createVNode(_component_q_icon, { name: "expand_more" })
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_q_menu, {
                    fit: "",
                    "auto-close": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_tree, {
                        nodes: flow.value,
                        "node-key": "id",
                        class: "sql-builder__constructor--child-tree"
                      }, {
                        "header-parent": _withCtx((prop) => [
                          _createTextVNode(_toDisplayString(prop.node.data.label), 1)
                        ]),
                        "default-header": _withCtx((prop) => [
                          _createVNode(_component_q_btn, {
                            dense: "",
                            "no-caps": "",
                            onClick: ($event: any) => (emit('setSource', prop.node, query.value.indexOf(element))),
                            flat: "",
                            label: prop.node.label
                          }, null, 8, ["onClick", "label"])
                        ]),
                        _: 2
                      }, 1032, ["nodes"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_q_input, {
                modelValue: element.type,
                "onUpdate:modelValue": ($event: any) => ((element.type) = $event),
                type: "text",
                label: "Тип",
                dense: "",
                outlined: "",
                disable: "",
                readonly: ""
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_q_checkbox, {
                size: "xs",
                onClick: ($event: any) => (checkSelected(element)),
                modelValue: element.selected,
                "onUpdate:modelValue": ($event: any) => ((element.selected) = $event),
                label: "Отобразить в результате",
                color: "primary"
              }, null, 8, ["onClick", "modelValue", "onUpdate:modelValue"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_q_select, {
                "emit-value": "",
                "map-options": "",
                options: constructorOperand.value,
                modelValue: element.operand,
                "onUpdate:modelValue": ($event: any) => ((element.operand) = $event),
                label: "Операнд",
                dense: "",
                outlined: "",
                clearable: ""
              }, null, 8, ["options", "modelValue", "onUpdate:modelValue"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              (element.type === 'Nullable(Date32)' || element.type.includes('DateTime'))
                ? (_openBlock(), _createBlock(_component_q_input, {
                    key: 0,
                    modelValue: element.value,
                    "onUpdate:modelValue": ($event: any) => ((element.value) = $event),
                    mask: "date",
                    dense: "",
                    outlined: ""
                  }, {
                    append: _withCtx(() => [
                      _createVNode(_component_q_icon, {
                        name: "event",
                        class: "cursor-pointer"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_popup_proxy, {
                            cover: "",
                            "transition-show": "scale",
                            "transition-hide": "scale"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_date, {
                                modelValue: element.value,
                                "onUpdate:modelValue": ($event: any) => ((element.value) = $event)
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_8, [
                                    _withDirectives(_createVNode(_component_q_btn, {
                                      label: "Закрыть",
                                      color: "primary",
                                      flat: ""
                                    }, null, 512), [
                                      [_directive_close_popup]
                                    ])
                                  ])
                                ]),
                                _: 2
                              }, 1032, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue"]))
                : (_openBlock(), _createBlock(_component_q_input, {
                    key: 1,
                    type: "text",
                    modelValue: element.value,
                    "onUpdate:modelValue": ($event: any) => ((element.value) = $event),
                    label: "Значение",
                    dense: "",
                    outlined: ""
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_q_select, {
                "emit-value": "",
                "map-options": "",
                options: sortType.value,
                modelValue: element.sort,
                "onUpdate:modelValue": ($event: any) => ((element.sort) = $event),
                label: "Сортировка",
                dense: "",
                outlined: ""
              }, null, 8, ["options", "modelValue", "onUpdate:modelValue"])
            ]),
            (grouping.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(_component_q_select, {
                    disable: !element.selected,
                    "emit-value": "",
                    "map-options": "",
                    options: groupType.value,
                    modelValue: element.group,
                    "onUpdate:modelValue": ($event: any) => ((element.group) = $event),
                    "option-disable": "inactive",
                    dense: "",
                    outlined: ""
                  }, null, 8, ["disable", "options", "modelValue", "onUpdate:modelValue"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_q_btn, {
                flat: "",
                round: "",
                icon: "delete",
                onClick: ($event: any) => (emit('deleteCol', query.value.indexOf(element)))
              }, null, 8, ["onClick"])
            ])
          ], 2)
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : _createCommentVNode("", true)
}
}

})