import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative-position" }

import store from '@/store'
import { computed, toRaw } from 'vue'
import * as XLSX from 'xlsx'


export default /*@__PURE__*/_defineComponent({
  __name: 'ExportCityEarned',
  props: {
  splitAction: Array,
  dateMin: String,
  dateMax: String,
  grouping: String,
  descending: Boolean,
},
  setup(__props) {

const props = __props

const getExportTable = computed(() => {
  const items = store.getters.getExportEarnedPointsTable
  const filteredList = []
  console.log('%c[DEBUG]:DEBUG THIS', 'color:violet', store.getters.getExportEarnedPointsTable)
  if (items && items.items) {
    for (const i of items.items) {
      filteredList.push({
        'SSO ID': i.getSsoId(),
        'Название проекта': i.getProjectName(),
        'Действие': i.getActivText(),
        'Текст начисления': i.getDescription(),
        'Дата транзакций': i.getTransactionDt().split('-').reverse().join('.').toLocaleString(),
        'Количество баллов': i.getValue().toLocaleString(),
      })
    }
  }

  return filteredList
})

const groupRows = computed(() => {
  const items = store.getters.getEarnedPointsGroupTable
  const filteredList = []

  if (items && items.items) {
    for (const i of items.items) {
      if (props.grouping === 'sso_id') {
        filteredList.push({
          'SSO ID': i.getSsoIdCount(),
          'Количество проектов': Number(i.getProjectCount()).toLocaleString(),
          'Количество типов действий': i.getActivCount().toLocaleString(),
          'Количество наименований активностей': Number(i.getDescriptionCount()).toLocaleString(),
          'Количество дат транзакций': i.getTransactionDtCount().split('-').reverse().join('.').toLocaleString(),
          'Количество баллов': i.getValue().toLocaleString(),
        })
      }

      if (props.grouping === 'project_id') {
        filteredList.push({
          'Количество пользователей': Number(i.getSsoIdCount()).toLocaleString(),
          'Название проекта': i.getProjectCount(),
          'Количество типов действий': i.getActivCount().toLocaleString(),
          'Количество наименований активностей': Number(i.getDescriptionCount()).toLocaleString(),
          'Количество дат транзакций': i.getTransactionDtCount().split('-').reverse().join('.').toLocaleString(),
          'Количество баллов': i.getValue().toLocaleString(),
        })
      }

      if (props.grouping === 'activ_id') {
        filteredList.push({
          'Количество пользователей': Number(i.getSsoIdCount()).toLocaleString(),
          'Количество проектов': Number(i.getProjectCount()).toLocaleString(),
          'Действие': i.getActivCount().toLocaleString(),
          'Количество наименований активностей': Number(i.getDescriptionCount()).toLocaleString(),
          'Количество дат транзакций': i.getTransactionDtCount().split('-').reverse().join('.').toLocaleString(),
          'Количество баллов': i.getValue().toLocaleString(),
        })
      }

      if (props.grouping === 'transaction_dt') {
        filteredList.push(
          {
            'Количество пользователей': Number(i.getSsoIdCount()).toLocaleString(),
            'Количество проектов': Number(i.getProjectCount()).toLocaleString(),
            'Количество типов действий': i.getActivCount().toLocaleString(),
            'Количество наименований активностей': Number(i.getDescriptionCount()).toLocaleString(),
            'Дата транзакции': i.getTransactionDtCount().split('-').reverse().join('.').toLocaleString(),
            'Количество баллов': i.getValue().toLocaleString(),
          }
        )
      }
    }
  }

  return filteredList
})

const metricValues = computed(() => {
  const metricList = store.getters.getMetricChecked

  return toRaw(metricList)
})

const isExport = computed(() => store.getters.isExportEarnedPointsTable)
const isTableLoad = computed(() => store.getters.isEarnedPointsTable)

const loadGroupedData = (isCsv?: boolean) => {
  return store
    .dispatch('getEarnedPointsGroupTable', {
      filter: [],
      sort: {
        name: 'transaction_dt_count',
        exp: props.descending ? 'DESC' : 'ASC'
      },
      pagination: {
        page: 1,
        limit: isCsv ? 250000 : 150000,
        pages: 0,
        cnt: 0
      },
      activity: metricValues.value['Активность'],
      loyalty: metricValues.value['Лояльность'],
      useful: metricValues.value['Полезность'],
      interest: metricValues.value['Заинтересованность'],
      activ_ids: props.splitAction,
      date_begin: props.dateMin,
      date_end: props.dateMax,
      group_by: props.grouping,
    })
}
const loadData = (isCsv: boolean) => {
  return store
    .dispatch('getExportEarnedPointsTable', {
      pagination: {
        page: 1,
        limit: isCsv ? 250000 : 150000,
        pages: 0,
        cnt: 0,
      },
      sort: {
        name: 'project_name',
        exp: 'asc',
      },
      activity: metricValues.value['Активность'],
      loyalty: metricValues.value['Лояльность'],
      useful: metricValues.value['Полезность'],
      interest: metricValues.value['Заинтересованность'],
      activ_ids: props.splitAction,
      date_begin: props.dateMin,
      date_end: props.dateMax,
    })
}

const exportData = async (type: string) => {
  let filename = ''
  if (type == 'xlsx') {
    if (props.grouping !== 'no_group') await loadGroupedData(false);
    else await loadData(false);

    filename = 'Таблица начислений.xlsx'
  } else {
    if (props.grouping !== 'no_group') await loadGroupedData(true);
    else await loadData(true);

    filename = 'Таблица начислений.csv'
  }
  let ws = props.grouping !== 'no_group' ? XLSX.utils.json_to_sheet(groupRows.value)
    : XLSX.utils.json_to_sheet(getExportTable.value)
  let wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Таблица начислений')
  XLSX.writeFile(wb, filename)
}

return (_ctx: any,_cache: any) => {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_btn_dropdown = _resolveComponent("q-btn-dropdown")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_btn_dropdown, {
      label: "Экспорт",
      color: "secondary",
      stretch: "",
      style: {"height":"56px"},
      disable: isTableLoad.value
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_list, null, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createBlock(_component_q_item, {
              clickable: "",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (exportData('xlsx')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_label, null, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("Excel")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })), [
              [_directive_close_popup]
            ]),
            _withDirectives((_openBlock(), _createBlock(_component_q_item, {
              clickable: "",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (exportData('csv')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_label, null, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("CSV")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })), [
              [_directive_close_popup]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["disable"]),
    _createVNode(_component_q_inner_loading, {
      showing: isExport.value,
      color: "grey"
    }, null, 8, ["showing"])
  ]))
}
}

})