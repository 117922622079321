import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "save__head" }
const _hoisted_2 = { class: "save__buttons" }

import { toRefs } from 'vue'
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';;
import {defineEmits} from "vue/dist/vue";
import { SqlBuilderApi, QbuilderItem } from '../../services/api/SqlBuilderApi';
import { SourceApi } from '../../services/api/SourceApi';
import { BuilderTab } from '../../views/sql-builder/SqlQueryBuilder.vue'

type Props = {
  reqItem: QbuilderItem,
  activeTab: BuilderTab,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SaveModal',
  props: {
    reqItem: {},
    activeTab: {}
  },
  emits: ['getQbuilderList','notify','getSourceList'],
  setup(__props: any, { emit: __emit }) {

const sqlBuilderApi = new SqlBuilderApi();
const sourceApi = new SourceApi()

const $q = useQuasar()

const props = __props

const {
  reqItem,
  activeTab
} = toRefs(props)


const emit = __emit

const saveAsQuery = () => {
  $q.loading.show()
  sqlBuilderApi.createQbuilder(reqItem?.value).then(() => {
    emit('notify','Запрос успешно сохранен','positive')
    $q.loading.hide()
    activeTab.value.showSave = false
  });
}

const updateQuery = () => {
  $q.loading.show()
  if(reqItem) reqItem.value.id = +activeTab?.value.uid
  sqlBuilderApi.updateQbuilder(reqItem?.value).then(() => {
    emit('notify','Запрос успешно сохранен','positive')
    $q.loading.hide()
    activeTab.value.showSave = false
  });
}

const getSqlQuery = () => {
  sqlBuilderApi.getSqlRequest(reqItem?.value).then((res) => {
    saveAsSource(res.getSqlQuery())
    $q.loading.hide()
  })
}

const saveAsSource = (queryText: string) => {
  const item = {
    sourceId: reqItem?.value.sourceId ?? 0,
    sourceSchema: reqItem?.value.from?.schema ?? '',
    sourceTable: reqItem?.value.from?.table ?? '',
    sourceFieldsChecksum: reqItem?.value.from?.checkSum ?? '',
    sourceDescription: reqItem?.value.name ?? '',
  }
  $q.loading.show()
  sourceApi.createSourceFromQuery(item,queryText).then(() => {
    emit('getSourceList')
    emit('notify','Источник данных успешно сохранен','positive')
    activeTab.value.showSave = false
  });
}


return (_ctx: any,_cache: any) => {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!

  return (_openBlock(), _createBlock(_component_q_card_section, { class: "save" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[5] || (_cache[5] = _createElementVNode("h3", null, "Сохранить как?", -1)),
        _createVNode(_component_q_checkbox, {
          modelValue: _unref(activeTab).exectAll,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(activeTab).exectAll) = $event)),
          label: "Сделать доступным для всех пользователей"
        }, null, 8, ["modelValue"]),
        _createElementVNode("div", _hoisted_2, [
          (!_unref(reqItem).isSaved)
            ? (_openBlock(), _createBlock(_component_q_btn, {
                key: 0,
                onClick: saveAsQuery,
                "no-caps": "",
                color: "primary"
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Сохранить как запрос")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_unref(reqItem).isSaved)
            ? (_openBlock(), _createBlock(_component_q_btn, {
                key: 1,
                onClick: updateQuery,
                "no-caps": "",
                color: "primary"
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Сохранить запрос")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_unref(reqItem).isSaved)
            ? (_openBlock(), _createBlock(_component_q_btn, {
                key: 2,
                onClick: saveAsQuery,
                "no-caps": "",
                color: "primary"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Сохранить как новый запрос")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_q_btn, {
            onClick: getSqlQuery,
            "no-caps": "",
            color: "primary"
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Сохранить как источник данных")
            ])),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})