import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }

import store from '@/store'
import { computed, toRaw } from 'vue'
import * as XLSX from 'xlsx'


export default /*@__PURE__*/_defineComponent({
  __name: 'ExportButtonRegistration',
  props: {
  selAct: {
    type: Array,
    default: () => [],
  } as any,
  allChk: {
    type: Boolean,
    default: false,
  } as any,
  dateMin: {
    type: String,
    default: '2012-01-01',
  } as any,
  dateMax: {
    type: String,
    default: '2024-01-01',
  },
},
  setup(__props) {

const getExportTable = computed(() => {
  const rawAccountsItems = store.getters.getExportCount

  let rows: any = []
  if (rawAccountsItems !== undefined) {
    for (let accountItem of rawAccountsItems) {
      rows.push({
        Действие:
          accountItem.getActivTextTape() +
            ' (' +
            accountItem.getProjectShortName() +
            ')' ?? 'Не задано',
        Совершено: accountItem.getActivityCount().toLocaleString(),
        'Уникальных пользователей': accountItem
          .getActivityUniq()
          .toLocaleString(),
      })
    }
  }
  return rows
})

const metricValues = computed(() => {
  const metricList = store.getters.getMetricChecked

  return toRaw(metricList)
})

const loadData = () => {
  return store.dispatch('getExportCount', {
    filter: {},
    activity: metricValues.value['Активность'],
    loyalty: metricValues.value['Лояльность'],
    useful: metricValues.value['Полезность'],
    interest: metricValues.value['Заинтересованность'],
    sort: {},
    activ_ids: props.selAct,
    all_active: props.allChk,
    date_begin: props.dateMin,
    date_end: props.dateMax,
  })
}

const props = __props

const exportData = async (type: string) => {
  await loadData()

  let filename = ''
  if (type == 'xlsx') {
    filename = 'Таблица совершенных действий.xlsx'
  } else {
    filename = 'Таблица совершенных действий.csv'
  }
  let ws = XLSX.utils.json_to_sheet(getExportTable.value)
  let wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Таблица совершенных действий')
  XLSX.writeFile(wb, filename)
}

return (_ctx: any,_cache: any) => {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_btn_dropdown = _resolveComponent("q-btn-dropdown")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_btn_dropdown, {
      label: "Экспорт",
      color: "secondary",
      stretch: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_list, null, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createBlock(_component_q_item, {
              clickable: "",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (exportData('xlsx')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_label, null, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("Excel")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })), [
              [_directive_close_popup]
            ]),
            _withDirectives((_openBlock(), _createBlock(_component_q_item, {
              clickable: "",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (exportData('csv')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_label, null, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("CSV")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })), [
              [_directive_close_popup]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})