import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, unref as _unref } from "vue"

const _hoisted_1 = { style: {"padding":"5px 24px"} }
const _hoisted_2 = {
  class: "row items-stretch",
  style: {"height":"100%"}
}
const _hoisted_3 = { class: "col-5" }
const _hoisted_4 = { class: "col-12 q-pr-lg" }
const _hoisted_5 = { class: "relative-position" }
const _hoisted_6 = { class: "relative-position" }
const _hoisted_7 = { class: "col-12 q-pr-lg q-mt-lg" }
const _hoisted_8 = {
  class: "col-12 bg-white text-black shadow-4 relative-position",
  style: {"height":"59vh","overflow":"hidden"}
}
const _hoisted_9 = {
  class: "row cls-answers__actions items-center",
  style: {"min-height":"40px"}
}
const _hoisted_10 = { class: "col-lg-5 col-xl-7 cls-answers__input q-pa-sm" }
const _hoisted_11 = {
  key: 0,
  class: "row col-xl-2 col-lg-3 justify-end"
}
const _hoisted_12 = {
  style: {"width":"100%","height":"44vh","overflow":"auto"},
  class: "custom-tree q-pa-sm"
}
const _hoisted_13 = { class: "row items-center" }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = { key: 1 }
const _hoisted_16 = {
  class: "col-7 shadow-4 bg-white text-black",
  style: {"overflow":"hidden"}
}
const _hoisted_17 = {
  key: 0,
  class: "text-h6 q-pa-sm bg-white text-black"
}
const _hoisted_18 = { class: "text-weight-bold" }
const _hoisted_19 = {
  key: 1,
  class: "text-h6 q-pa-sm bg-white text-black"
}

import store from "@/store";
// import * as XLSX from 'xlsx'
import { computed, onMounted, ref } from "vue";
import ClassAdd from "../../../components/class/ClassAdd.vue";
import ClassEdit from "../../../components/class/ClassEdit.vue";
import ClassDelete from "../../../components/class/ClassDelete.vue";
import GeneralWordFormSettings from "@/components/modules/polls/views/answers/GeneralWordFormSettings.vue";
// import dateMixin from "../../../../../../mixins/main"
// import {onClickOutside} from "@vueuse/core";
import { useClassTagApi } from "@/components/modules/polls/services/useClassTagApi";
import { useAgPollApi } from "@/components/modules/polls/services/useAgPollApi";
import { useAgQuestionApi } from "@/components/modules/polls/services/useAgQuestionApi";
import { useClassifierApi } from "@/components/modules/polls/services/useClassifierApi";
import { useClassApi } from "@/components/modules/polls/services/useClassApi";
import { TreeNode, useTreeConverter } from "@/composables/useTreeConverter";
import { ClassModel } from "proto/nigma-message-proto/polls/class";
import { ClassifierModel } from "proto/nigma-message-proto/polls/classifier";
import { ClassTagModel } from "proto/nigma-message-proto/polls/class_tag";
import { AgPollModel } from "proto/nigma-message-proto/polls/ag_poll";
import { AgQuestionModel } from "proto/nigma-message-proto/polls/ag_question";
import AgClassificationMessages from "@/components/modules/polls/components/agMessages/classification/AgClassificationMessages.vue";
import SourceTable from "@/components/modules/polls/components/SourceTable.vue";
import { AgAnswerGrpModel } from "proto/nigma-message-proto/polls/ag_answer_grp";
import { useTextHighlighter } from "@/composables/useTextHighlighter";
import { useClassNotTagApi } from "@/components/modules/polls/services/useClassNotTagApi";
import { ClassNotTagModel } from "proto/nigma-message-proto/polls/class_not_tag";

// type PollGroup = {
//   id: number,
//   title: string,
//   count: number,
// }


export default /*@__PURE__*/_defineComponent({
  __name: 'AgClassification',
  setup(__props) {

const { listClassRequest } = useClassApi();
const { listClassTagRequest } = useClassTagApi();
const { listClassNotTag } = useClassNotTagApi();
const { listClassifierRequest } = useClassifierApi();
const { listAgPollRequest } = useAgPollApi();
const { listAgQuestionRequest } = useAgQuestionApi();
const { treeConverter, getNodeByKey } = useTreeConverter();
const { markedFindValue } = useTextHighlighter();

const convertedClassItems = ref<TreeNode<ClassModel>[]>([]);
const currentNode = ref<TreeNode<ClassModel> | undefined>();
const classTreeLoader = ref(false);

const classifier = ref<ClassifierModel | undefined>();
const classifierOptions = ref<ClassifierModel[]>([]);
const classifierSearchOptions = ref<ClassifierModel[]>([]);

const classTags = ref<ClassTagModel[]>([]);
const classNotTags = ref<ClassNotTagModel[]>([]);

const agQuestionLoader = ref(false);

const isActiveModal = ref(false);
const isActiveModalWordForm = ref(false);
const isActiveModalSource = ref(false);
const isActiveModalAdd = ref(false);
const isActiveModalEdit = ref(false);
const isActiveModalDelete = ref(false);
const currentCountAnswer = ref<AgAnswerGrpModel>();
const isFullCount = ref(false);

// const pollGroupOptions = ref<PollGroup[]>([])
// const pollGroupVOptions = ref<PollGroup[]>([])

const poll = ref<AgPollModel | undefined>();
const pollOptions = ref<AgPollModel[]>([]);
const pollSearchOptions = ref<AgPollModel[]>([]);
const agPollLoader = ref(false);

const question = ref<AgQuestionModel | undefined>();
const questionOptions = ref<AgQuestionModel[]>([]);
const questionSearchOptions = ref<AgQuestionModel[]>([]);

const filter = ref("");
// const formFilter = ref([])
const search = ref("");
const oldSearch = ref("");
let searchTimeout = setTimeout(() => {}, 500) as ReturnType<typeof setTimeout>;
const minSearchSymbol = ref(3);
const selectedNode = ref("");
// const groupInput = ref('')
// const isDropdownOpen = ref(false)
// const pollGroupNodes = ref([]) as any
// const pollGroupTickedNodes = ref<number[]>([])
const currentTab = ref("");
const clsAnswerPanel = ref<InstanceType<typeof AgClassificationMessages>>();
// const pollsGroupRef = ref()

const userRole = computed(() => store.getters.getUserAccess);

// const makeWordForm = () => {
//   hideModal()
//   isActiveModalWordForm.value = true
//   isActiveModal.value = true
// }

// const makeQuestionExcelForm = () => {
//   store
//       .dispatch('getAnswerGrpClsQuestionFormItems', {
//         filter: formFilter.value,
//         classifier_id: classifier.value.id,
//         question_id: question.value.id,
//       })
//       .then(
//           (resolve) => {
//             if (resolve && resolve.length) {
//               // Маппинг данных
//               const data = resolve.map((item: any) => {
//                 return {
//                   'Классификатор': item.getClassifierName(),
//                   'Группа(категория) опросов': item.getPollGroupTitle(),
//                   'Опрос': item.getPollTitle(),
//                   'Вопрос': item.getQuestionStitle(),
//                   'Количество голосов включая дочерние': item.getClassAnswerGrpSum(),
//                   'Количество голосов': item.getClassAnswerGrpCount(),
//                   'Класс (группа)': item.getClassName(),
//                   'Описание класса (группы)': item.getClassDescr(),
//                   'Примечания для класса (группы)': item.getClassNotes(),
//                 }
//               })
//
//               const ws = XLSX.utils.json_to_sheet(data)
//
//               // Подгоняем ширину столбцов под контент
//               const colLengths = Object.keys(data[0]).map((k) => k.toString().length)
//               for (const d of data) {
//                 Object.values(d).forEach((element: any, index) => {
//                   const length = element.toString().length
//                   if (colLengths[index] < length) {
//                     colLengths[index] = length
//                   }
//                 })
//               }
//               ws["!cols"] = colLengths.map((l) => {
//                 return {
//                   wch: l,
//                 }
//               })
//
//               const wb = XLSX.utils.book_new()
//               XLSX.utils.book_append_sheet(wb, ws, 'Классификация ответов')
//               XLSX.writeFile(wb, 'cls_answers_' + dateMixin.methods.getFormattedDateTimeUnderline(null) + '.xlsx')
//             } else {
//               console.log('Нет данных для вывода в файл!')
//             }
//           },
//           (reject) => {
//             console.log('getAnswerGrpClsQuestionFormItems reject', reject)
//           }
//       )
// }
//
// const makePollExcelForm = () => {
//   store
//       .dispatch('getAnswerGrpClsPollFormItems', {
//         filter: formFilter,
//         classifier_id: classifier.value.id,
//         poll_id: poll.value.id,
//       })
//       .then(
//           (resolve) => {
//             console.log('getAnswerGrpClsPollFormItems resolve', resolve)
//
//             if (resolve && resolve.length) {
//               // Маппинг данных
//               const data = resolve.map((item: any) => {
//                 return {
//                   'Классификатор': item.getClassifierName(),
//                   'Группа(категория) опросов': item.getPollGroupTitle(),
//                   'Опрос': item.getPollTitle(),
//                   'Количество голосов включая дочерние': item.getClassAnswerGrpSum(),
//                   'Количество голосов': item.getClassAnswerGrpCount(),
//                   'Класс (группа)': item.getClassName(),
//                   'Описание класса (группы)': item.getClassDescr(),
//                   'Примечания для класса (группы)': item.getClassNotes(),
//                 }
//               })
//
//               const ws = XLSX.utils.json_to_sheet(data)
//
//               // Подгоняем ширину столбцов под контент
//               const colLengths = Object.keys(data[0]).map((k) => k.toString().length)
//               for (const d of data) {
//                 Object.values(d).forEach((element: any, index) => {
//                   const length = element.toString().length
//                   if (colLengths[index] < length) {
//                     colLengths[index] = length
//                   }
//                 })
//               }
//               ws["!cols"] = colLengths.map((l) => {
//                 return {
//                   wch: l,
//                 }
//               })
//
//               const wb = XLSX.utils.book_new()
//               XLSX.utils.book_append_sheet(wb, ws, 'Классификация ответов')
//               XLSX.writeFile(wb, 'cls_answers_' + dateMixin.methods.getFormattedDateTimeUnderline(null) + '.xlsx')
//             } else {
//               console.log('Нет данных для вывода в файл!')
//             }
//           },
//           (reject) => {
//             console.log('getAnswerGrpClsPollFormItems reject', reject)
//           }
//       )
// }

const hideModal = () => {
  isActiveModal.value = false;
  isActiveModalSource.value = false;
  isActiveModalAdd.value = false;
  isActiveModalEdit.value = false;
  isActiveModalDelete.value = false;
  isActiveModalWordForm.value = false;
};

const onRowShowAnswerSource = (row: any, isFull: boolean) => {
  hideModal();
  currentCountAnswer.value = row;
  isFullCount.value = isFull;
  isActiveModalSource.value = true;
  isActiveModal.value = true;
};

const addClass = () => {
  hideModal();
  isActiveModalAdd.value = true;
  isActiveModal.value = true;
};

const editClass = (tabType: string) => {
  if (tabType === "main") currentTab.value = "main";
  else if (tabType === "tags") currentTab.value = "tags";
  else if (tabType === "notTags") currentTab.value = "not_tags";

  hideModal();
  isActiveModalEdit.value = true;
  isActiveModal.value = true;
};

const deleteClass = () => {
  hideModal();
  isActiveModalDelete.value = true;
  isActiveModal.value = true;
};

const afterAction = (setNode: any) => {
  reopenedTree(setNode, true); // true - перегружаем дерево с сервера
  hideModal();
};

const changeListClassifier = () => {
  initSearch();
  loadClassTreeItems("");
};

// const changeListPollGroup = () => {
//   loadPollItems()
// }

const changeListPoll = () => {
  initSearch();
  loadQuestionItems();
  changeListQuestion();
};

const changeListQuestion = () => {
  if (selectedNode.value) {
    if (clsAnswerPanel.value)
      clsAnswerPanel.value?.loadData(
        true,
        classTags.value,
        classNotTags.value,
        poll.value?.agPollId,
        question.value?.agQuestionId
      );
  }
};

const selectNode = () => {
  if (selectedNode.value)
    currentNode.value = getNodeByKey(
      "classId",
      Number(selectedNode.value),
      convertedClassItems.value
    );
  else currentNode.value = undefined;

  if (currentNode.value) {
    Promise.all([
      listClassTagRequest({
        filter: {
          items: [
            {
              fieldName: "class_id",
              operand: "=",
              value: currentNode.value?.classId.toString() || "0",
            },
          ],
        },
        sort: {
          name: "",
          exp: "",
        },
        pagination: {
          page: 1,
          pages: 0,
          limit: 100000,
          cnt: 0,
        },
      }),
      listClassNotTag({
        filter: {
          items: [
            {
              fieldName: "class_id",
              operand: "=",
              value: currentNode.value?.classId.toString() || "0",
            },
          ],
        },
        sort: {
          name: "",
          exp: "",
        },
        pagination: {
          page: 1,
          pages: 0,
          limit: 100000,
          cnt: 0,
        },
      }),
    ]).then(([tagsRes, notTagsRes]) => {
      classTags.value = tagsRes.items;
      classNotTags.value = notTagsRes.items;

      if (clsAnswerPanel.value)
        clsAnswerPanel.value?.loadData(
          true,
          classTags.value,
          classNotTags.value,
          poll.value?.agPollId,
          question.value?.agQuestionId
        );
    });
  }
};

const reopenedTree = (setNode: any, isReloadTree: any) =>
  isReloadTree ? loadClassTreeItems(setNode) : setTreeNode(setNode);

const setTreeNode = (setNode: string) => {
  if (setNode) {
    selectedNode.value = setNode;
    selectNode();

    currentNode.value = getNodeByKey(
      "classId",
      Number(selectedNode.value),
      convertedClassItems.value
    );

    // while (curNode && curNode.parent_id !== undefined) {
    //   curNode = JSON.parse(
    //       JSON.stringify(tree.getNodeByKey(curNode.parent_id))
    //   )
    //   if (curNode) {
    //     tree.setExpanded(curNode.id, true)
    //   }
    // }
  }
};

const initSearch = () => {
  if (searchTimeout) {
    clearTimeout(searchTimeout);
  }

  filter.value = "";
  search.value = "";
  oldSearch.value = "";
  searchTimeout = setTimeout(() => {}, 500);
};

const onInputSearchControl = (event: any) => {
  if (searchTimeout) {
    clearTimeout(searchTimeout);
  }
  console.log("onInputSearchControl event", event);
  searchTimeout = setTimeout(() => {
    const searchStr: string = event.target.value;

    if (searchStr.length >= minSearchSymbol.value) {
      // Устанавливаем строку поиска
      search.value = searchStr.toLowerCase();
      //this.loadClassTreeItems(null) // Временно убран серверный поиск !!!
    } else {
      clearSearch(oldSearch.value.length >= minSearchSymbol.value);
    }
    oldSearch.value = searchStr;
  }, 500);
};

const clearSearch = (isReload = true) => {
  search.value = "";
  if (isReload) {
    //this.loadClassTreeItems(null)// Временно убран серверный поиск !!!
  }
};

const loadClassTreeItems = (setNode: string) => {
  classTreeLoader.value = true;

  listClassRequest({
    filter: {
      items: [
        {
          fieldName: "classifier_id",
          operand: "=",
          value: classifier.value?.classifierId.toString() || "0",
        },
      ],
    },
    sort: {
      name: "",
      exp: "",
    },
    pagination: {
      page: 1,
      pages: 0,
      limit: 10000,
      cnt: 0,
    },
  })
    .then((res) => {
      convertedClassItems.value = treeConverter(res.items, {
        id: "classId",
        parentId: "classParentId",
      });
      if (setNode) setTreeNode(setNode);
    })
    .finally(() => (classTreeLoader.value = false));
};

const loadClassifierItems = () => {
  listClassifierRequest({
    filter: {
      items: [],
    },
    sort: {
      name: "",
      exp: "",
    },
    pagination: {
      page: 1,
      pages: 0,
      limit: 10000,
      cnt: 0,
    },
  }).then((res) => {
    classifierOptions.value = res.items;
    classifierSearchOptions.value = classifierOptions.value;
    // changeListClassifier()
  });
};

const loadQuestionItems = () => {
  agQuestionLoader.value = true;

  listAgQuestionRequest({
    filter: {
      items: [],
    },
    sort: {
      name: "aq.answer_src_count",
      exp: "desc",
    },
    pagination: {
      page: 1,
      limit: 100000,
      pages: 0,
      cnt: 0,
    },
    agPollId: poll.value?.agPollId || 0,
    isWorked: false,
  })
    .then((res) => {
      questionOptions.value = res.items.map((item) => {
        return {
          agQuestionId: item.agQuestionId,
          agQuestionParentId: item.agQuestionParentId,
          agPollId: item.agPollId,
          agQuestionTypeId: item.agQuestionTypeId,
          agQuestionIdx: item.agQuestionIdx,
          agQuestionTitle: item.agQuestionTitle,
          agQuestionStitle: `(${item.answerSrcSum}) ${item.agQuestionStitle}`,
          agQuestionProcessed: item.agQuestionProcessed,
          answerSrcCount: item.answerSrcCount,
          answerSrcSum: item.answerSrcSum,
          similarity: item.similarity,
          hasChilds: item.hasChilds,
        };
      });
      questionOptions.value.unshift({
        agQuestionId: 0,
        agQuestionParentId: 0,
        agPollId: 0,
        agQuestionTypeId: 0,
        agQuestionIdx: 0,
        agQuestionTitle: "",
        agQuestionStitle: "Все вопросы",
        agQuestionProcessed: false,
        answerSrcCount: 0,
        answerSrcSum: 0,
        similarity: 0,
        hasChilds: false,
      });
      question.value = questionOptions.value[0];
      questionSearchOptions.value = questionOptions.value;
    })
    .finally(() => (agQuestionLoader.value = false));
};

const loadPollItems = () => {
  // if (pollGroupTickedNodes.value.length) {
  //   groupInput.value = 'Выбрано групп (' + pollGroupTickedNodes.value.length + ')'
  // } else {
  //   groupInput.value = ''
  // }

  // let tempFilter = {
  //   field_name: 'p.poll_group_id',
  //   operand: '>',
  //   value: 0,
  //   or: [],
  // } as any

  // pollGroupTickedNodes.value.forEach((el: any) => {
  //   tempFilter.or.push({
  //     field_name: 'p.poll_group_id',
  //     operand: '=',
  //     value: el,
  //   })
  // })

  agPollLoader.value = true;

  listAgPollRequest({
    filter: {
      items: [],
    },
    sort: {
      name: "ag_poll_id",
      exp: "asc",
    },
    pagination: {
      page: 1,
      limit: 100000,
      pages: 0,
      cnt: 0,
    },
  })
    .then((res) => {
      pollOptions.value = res.items.map((item) => {
        return {
          agPollId: item.agPollId,
          agPollGroup: item.agPollGroup,
          agPollTitle: `${item.agPollId} | ${item.agPollTitle}`,
          agPollDateBegin: item.agPollDateBegin,
          agPollDateEnd: item.agPollDateEnd,
          agPollNotes: item.agPollNotes,
          agPollUserCount: item.agPollUserCount,
          agPollTotalAnswers: item.agPollTotalAnswers,
          agPollOpenAnswers: item.agPollOpenAnswers,
          agPollDateUpdate: item.agPollDateUpdate,
          agPollTarget: item.agPollTarget,
        };
      });
      pollOptions.value.unshift({
        agPollId: 0,
        agPollGroup: "",
        agPollTitle: "Все опросы",
        agPollDateBegin: "",
        agPollDateEnd: "",
        agPollNotes: "",
        agPollUserCount: 0,
        agPollTotalAnswers: 0,
        agPollOpenAnswers: 0,
        agPollDateUpdate: "",
        agPollTarget: "",
      });
      poll.value = pollOptions.value[0];
      pollSearchOptions.value = pollOptions.value;

      loadQuestionItems();
    })
    .finally(() => (agPollLoader.value = false));
};

// const loadPollGroupItems = () => {
//   store
//       .dispatch('getPollGroupItems')
//       .then(res => {
//         let nodes = [
//           {
//             label: 'Все группы опросов',
//             children: []
//           }
//         ] as any
//
//         pollGroupOptions.value = []
//         pollGroupVOptions.value = []
//
//         for (let grpItem of res.items) {
//           nodes[0].children.push({
//             label: '(' + grpItem.getPollCount() + ') ' + grpItem.getPollGroupTitle(),
//             value: grpItem.getPollGroupId()
//           })
//
//           pollGroupTickedNodes.value.push(grpItem.getPollGroupId())
//
//           pollGroupOptions.value.push({
//             id: grpItem.getPollGroupId(),
//             title: '(' + grpItem.getPollCount() + ') ' + grpItem.getPollGroupTitle(),
//             count: grpItem.getPollCount(),
//           })
//         }
//
//         pollGroupVOptions.value = pollGroupOptions.value
//
//         changeListPollGroup()
//
//         pollGroupNodes.value = nodes
//         loadPollItems()
//       })
// }

const filterClassifierFn = (val: string, update: Function) => {
  update(() => {
    if (val === "") {
      classifierSearchOptions.value = classifierOptions.value;
    } else {
      const needle = val.toLowerCase();

      classifierSearchOptions.value = classifierOptions.value.filter(
        (v) => v.classifierName.toLowerCase().indexOf(needle) > -1
      );
    }
  });
};

const filterPollFn = (val: string, update: Function) => {
  update(() => {
    if (val === "") {
      pollSearchOptions.value = pollOptions.value;
    } else {
      const needle = val.toLowerCase();

      pollSearchOptions.value = pollOptions.value.filter(
        (v) => v.agPollTitle.toLowerCase().indexOf(needle) > -1
      );
    }
  });
};

const filterQuestionFn = (val: string, update: Function) => {
  update(() => {
    if (val === "") {
      questionSearchOptions.value = questionOptions.value;
    } else {
      const needle = val.toLowerCase();

      questionSearchOptions.value = questionOptions.value.filter(
        (v) => v.agQuestionStitle.toLowerCase().indexOf(needle) > -1
      );
    }
  });
};

const loadData = () => {
  loadClassifierItems();
  loadPollItems();
  // loadPollGroupItems()
};

// onClickOutside(pollsGroupRef, () => {
//   isDropdownOpen.value = false
// })

onMounted(() => {
  loadData();
});

return (_ctx: any,_cache: any) => {
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tree = _resolveComponent("q-tree")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[19] || (_cache[19] = _createElementVNode("div", { class: "text-h4 q-mt-sm q-mb-sm" }, "Классификация", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createVNode(_component_q_select, {
              filled: "",
              modelValue: classifier.value,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((classifier).value = $event)),
                changeListClassifier
              ],
              "use-input": "",
              "hide-selected": "",
              "fill-input": "",
              "input-debounce": "300",
              options: classifierSearchOptions.value,
              "option-value": "classifierId",
              "option-label": "classifierName",
              "options-dense": "",
              "options-html": "",
              label: 
                classifier.value?.classifierId === 0
                  ? 'Выберите классификатор'
                  : 'Классификатор'
              ,
              onFilter: filterClassifierFn
            }, {
              "no-option": _withCtx(() => [
                _createVNode(_component_q_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_section, { class: "text-grey" }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode(" Не найдено ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              option: _withCtx((scope) => [
                _createVNode(_component_q_item, _normalizeProps(_guardReactiveProps(scope.itemProps)), {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_section, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_label, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_icon, {
                              size: "20px",
                              name: 
                          scope.opt.classifierMultiple
                            ? 'mdi-email-multiple-outline'
                            : 'mdi-email-outline'
                        
                            }, null, 8, ["name"]),
                            _createTextVNode(" " + _toDisplayString(scope.opt.classifierName), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1040)
              ]),
              _: 1
            }, 8, ["modelValue", "options", "label"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_q_select, {
              class: "relative-position",
              style: {"margin-top":"15px"},
              filled: "",
              modelValue: poll.value,
              "onUpdate:modelValue": [
                _cache[1] || (_cache[1] = ($event: any) => ((poll).value = $event)),
                changeListPoll
              ],
              "use-input": "",
              "hide-selected": "",
              "fill-input": "",
              "input-debounce": "300",
              options: pollSearchOptions.value,
              "option-value": "agPollId",
              "option-label": "agPollTitle",
              "options-dense": "",
              "options-html": "",
              label: poll.value?.agPollId === 0 ? 'Выберите опрос' : 'Опрос',
              onFilter: filterPollFn
            }, {
              "no-option": _withCtx(() => [
                _createVNode(_component_q_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_section, { class: "text-grey" }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode(" Не найдено ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue", "options", "label"]),
            _createVNode(_component_q_inner_loading, {
              showing: agPollLoader.value,
              color: "grey"
            }, null, 8, ["showing"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_q_select, {
              style: {"margin-top":"15px"},
              filled: "",
              modelValue: question.value,
              "onUpdate:modelValue": [
                _cache[2] || (_cache[2] = ($event: any) => ((question).value = $event)),
                changeListQuestion
              ],
              "use-input": "",
              "hide-selected": "",
              "fill-input": "",
              "input-debounce": "300",
              options: questionSearchOptions.value,
              "option-value": "agQuestionId",
              "option-label": "agQuestionStitle",
              "options-dense": "",
              "options-html": "",
              label: 
                question.value?.agQuestionId === 0 ? 'Выберите вопрос' : 'Вопрос'
              ,
              onFilter: filterQuestionFn,
              disable: poll.value?.agPollId === 0
            }, {
              "no-option": _withCtx(() => [
                _createVNode(_component_q_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_section, { class: "text-grey" }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode(" Не найдено ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue", "options", "label", "disable"]),
            (poll.value?.agPollId === 0)
              ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createElementVNode("div", { class: "text-subtitle2" }, "Выберите опрос", -1)
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_q_inner_loading, {
              showing: agQuestionLoader.value,
              color: "grey"
            }, null, 8, ["showing"])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _cache[17] || (_cache[17] = _createElementVNode("div", { class: "text-h6 text-weight-bold q-pa-sm" }, "Классы", -1)),
            _createVNode(_component_q_separator),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_q_input, {
                  style: {"margin":"1px 1px"},
                  filled: "",
                  disable: classTreeLoader.value,
                  modelValue: filter.value,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((filter).value = $event)),
                  onKeyup: _cache[4] || (_cache[4] = ($event: any) => (onInputSearchControl($event))),
                  label: "Поиск по классам"
                }, {
                  prepend: _withCtx(() => [
                    _createVNode(_component_q_icon, { name: "mdi-magnify" })
                  ]),
                  _: 1
                }, 8, ["disable", "modelValue"])
              ]),
              ('admin editor'.includes(userRole.value))
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createVNode(_component_q_btn, {
                      label: "ДЕЙСТВИЯ",
                      color: "primary",
                      style: {"height":"56px"},
                      disable: classifier.value?.classifierId === 0
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_menu, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_list, null, {
                              default: _withCtx(() => [
                                _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                  dense: "",
                                  clickable: "",
                                  onClick: addClass
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_item_section, { avatar: "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_icon, { name: "mdi-plus" })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_q_item_section, null, {
                                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                                        _createTextVNode("Добавить класс")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })), [
                                  [_directive_close_popup]
                                ]),
                                _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                  dense: "",
                                  clickable: "",
                                  disable: classTreeLoader.value || !selectedNode.value,
                                  onClick: _cache[5] || (_cache[5] = ($event: any) => (editClass('main')))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_item_section, { avatar: "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_icon, { name: "mdi-pencil" })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_q_item_section, null, {
                                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                                        _createTextVNode("Редактировать класс")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["disable"])), [
                                  [_directive_close_popup]
                                ]),
                                _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                  dense: "",
                                  clickable: "",
                                  disable: 
                          currentNode.value?.children.length !== 0 || !selectedNode.value
                        ,
                                  onClick: deleteClass
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_item_section, { avatar: "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_icon, { name: "mdi-close" })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_q_item_section, null, {
                                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                                        _createTextVNode("Удалить класс")
                                      ])),
                                      _: 1
                                    }),
                                    (currentNode.value?.children.length)
                                      ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                                          default: _withCtx(() => _cache[15] || (_cache[15] = [
                                            _createElementVNode("div", { class: "text-subtitle2" }, " Нельзя удалить класс, имеющий дочерние элементы ", -1)
                                          ])),
                                          _: 1
                                        }))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                }, 8, ["disable"])), [
                                  [_directive_close_popup]
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        (classifier.value?.classifierId === 0)
                          ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                              default: _withCtx(() => _cache[16] || (_cache[16] = [
                                _createElementVNode("div", { class: "text-subtitle1" }, "Выберите классификатор", -1)
                              ])),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["disable"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_q_tree, {
                nodes: convertedClassItems.value,
                "node-key": "classId",
                "label-key": "className",
                filter: search.value,
                selected: selectedNode.value,
                "onUpdate:selected": [
                  _cache[6] || (_cache[6] = ($event: any) => ((selectedNode).value = $event)),
                  selectNode
                ],
                "selected-color": "primary"
              }, {
                "default-header": _withCtx((prop) => [
                  _createElementVNode("div", _hoisted_13, [
                    (search.value.length > 0)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          innerHTML: _unref(markedFindValue)(prop.node.className, [], search.value)
                        }, null, 8, _hoisted_14))
                      : (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(prop.node.className), 1))
                  ])
                ]),
                _: 1
              }, 8, ["nodes", "filter", "selected"])
            ]),
            _createVNode(_component_q_inner_loading, {
              showing: classTreeLoader.value,
              color: "grey"
            }, null, 8, ["showing"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", null, [
          (classifier.value?.classifierName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _cache[18] || (_cache[18] = _createTextVNode(" Классификация по ")),
                _createElementVNode("span", _hoisted_18, "\"" + _toDisplayString(classifier.value?.classifierName) + "\"", 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_19, " Классификация ")),
          _createVNode(_component_q_separator),
          _createVNode(AgClassificationMessages, {
            ref_key: "clsAnswerPanel",
            ref: clsAnswerPanel,
            onShowSource: onRowShowAnswerSource,
            onEditClass: editClass,
            "current-node": currentNode.value,
            "ag-poll-id": poll.value?.agPollId,
            "ag-question-id": question.value?.agQuestionId
          }, null, 8, ["current-node", "ag-poll-id", "ag-question-id"])
        ])
      ])
    ]),
    _createVNode(_component_q_dialog, {
      modelValue: isActiveModal.value,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((isActiveModal).value = $event)),
      persistent: ""
    }, {
      default: _withCtx(() => [
        (isActiveModalWordForm.value)
          ? (_openBlock(), _createBlock(GeneralWordFormSettings, {
              key: 0,
              poll: poll.value,
              classifier: classifier.value,
              onHideModal: hideModal
            }, null, 8, ["poll", "classifier"]))
          : _createCommentVNode("", true),
        (isActiveModalSource.value)
          ? (_openBlock(), _createBlock(SourceTable, {
              key: 1,
              message: currentCountAnswer.value,
              isFull: isFullCount.value,
              onHideModal: hideModal,
              type: "ag"
            }, null, 8, ["message", "isFull"]))
          : _createCommentVNode("", true),
        (isActiveModalAdd.value)
          ? (_openBlock(), _createBlock(ClassAdd, {
              key: 2,
              "cls-id": classifier.value?.classifierId,
              onAfterAction: afterAction,
              onHideModal: hideModal,
              "current-node": currentNode.value
            }, null, 8, ["cls-id", "current-node"]))
          : _createCommentVNode("", true),
        (isActiveModalEdit.value)
          ? (_openBlock(), _createBlock(ClassEdit, {
              key: 3,
              "cls-id": classifier.value?.classifierId,
              onAfterAction: afterAction,
              onHideModal: hideModal,
              "curr-tab": currentTab.value,
              "current-node": currentNode.value,
              "current-tags": classTags.value,
              "current-not-tags": classNotTags.value
            }, null, 8, ["cls-id", "curr-tab", "current-node", "current-tags", "current-not-tags"]))
          : _createCommentVNode("", true),
        (isActiveModalDelete.value)
          ? (_openBlock(), _createBlock(ClassDelete, {
              key: 4,
              onAfterAction: afterAction,
              onHideModal: hideModal,
              "current-node": currentNode.value
            }, null, 8, ["current-node"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})