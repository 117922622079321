import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row q-mb-lg" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "text-h6" }

import { computed } from 'vue'
import store from '@/store'
import { useRouter } from 'vue-router'
import ActivityModalExport from "@/components/modules/intersection/components/Actions/ActivityModalExport.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ActivityModal',
  props: ['pagination', 'filter', 'intersectionNames'],
  emits: ['request', 'personalListAct'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit

const router = useRouter()

const columns: any = [
  {
    name: 'ssoid',
    label: 'SSO ID',
    align: 'left',
    field: 'ssoid',
  },
]

const rows = computed(() => {
  let rawList: any = store.getters.getActivityModal
  let filteredList: any = []

  for (let i of rawList) {
    filteredList.push({
      ssoid: i.getSsoId(),
      guid: i.getAccountGuid(),
    })
  }

  return filteredList
})

const filter = computed(() => props.filter)
const pagination = computed(() => props.pagination)
const isAccountListBusy = computed(() => store.getters.isActivityModal)
const intersectionNames = computed(() => props.intersectionNames)

const onRequest = (props: any) => {
  emit('request', props.pagination)
}

const openUserProfile = (__: any, row: any) => {
  const routeData = router.resolve({
    path: '/user-profile/' + row.guid,
  })
  window.open(routeData.href, '_blank')
}

return (_ctx: any,_cache: any) => {
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_card = _resolveComponent("q-card")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_q_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_q_card_section, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-h6" }, "Активное ядро", -1)),
              _createVNode(_component_q_space),
              _withDirectives(_createVNode(_component_q_btn, {
                icon: "close",
                flat: "",
                round: "",
                dense: ""
              }, null, 512), [
                [_directive_close_popup]
              ])
            ]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(intersectionNames.value.toString()), 1),
              _createVNode(_component_q_space),
              _createVNode(_component_q_btn, {
                label: "Открыть в ЦП",
                color: "primary",
                class: "q-mr-md",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('personalListAct')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_tooltip, {
                    anchor: "top middle",
                    self: "bottom middle",
                    offset: [10, 10]
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createElementVNode("p", {
                        style: {"font-size":"16px"},
                        class: "action-tooltip"
                      }, "Будут переданы первые 300 000 пользователей", -1)
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(ActivityModalExport, {
                filter: filter.value,
                names: intersectionNames.value
              }, null, 8, ["filter", "names"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_q_card_section, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_table, {
              class: "accTable",
              rows: rows.value,
              columns: columns,
              "row-key": "name",
              flat: "",
              pagination: pagination.value,
              "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => ((pagination).value = $event)),
              loading: isAccountListBusy.value,
              onRequest: onRequest,
              "rows-per-page-options": [5, 10, 15, 50],
              onRowClick: openUserProfile
            }, null, 8, ["rows", "pagination", "loading"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})