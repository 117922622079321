// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "class.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { ClassService } from "./class";
import type { DeleteClassResponse } from "./class";
import type { DeleteClassRequest } from "./class";
import type { UpdateClassResponse } from "./class";
import type { UpdateClassRequest } from "./class";
import type { CreateClassResponse } from "./class";
import type { CreateClassRequest } from "./class";
import type { ItemClassResponse } from "./class";
import type { ItemClassRequest } from "./class";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListClassResponse } from "./class";
import type { ListClassRequest } from "./class";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.ClassService
 */
export interface IClassServiceClient {
    /**
     * @generated from protobuf rpc: ListClass(proto.nigmamsg.ListClassRequest) returns (proto.nigmamsg.ListClassResponse);
     */
    listClass(input: ListClassRequest, options?: RpcOptions): UnaryCall<ListClassRequest, ListClassResponse>;
    /**
     * @generated from protobuf rpc: ItemClass(proto.nigmamsg.ItemClassRequest) returns (proto.nigmamsg.ItemClassResponse);
     */
    itemClass(input: ItemClassRequest, options?: RpcOptions): UnaryCall<ItemClassRequest, ItemClassResponse>;
    /**
     * @generated from protobuf rpc: CreateClass(proto.nigmamsg.CreateClassRequest) returns (proto.nigmamsg.CreateClassResponse);
     */
    createClass(input: CreateClassRequest, options?: RpcOptions): UnaryCall<CreateClassRequest, CreateClassResponse>;
    /**
     * @generated from protobuf rpc: UpdateClass(proto.nigmamsg.UpdateClassRequest) returns (proto.nigmamsg.UpdateClassResponse);
     */
    updateClass(input: UpdateClassRequest, options?: RpcOptions): UnaryCall<UpdateClassRequest, UpdateClassResponse>;
    /**
     * @generated from protobuf rpc: DeleteClass(proto.nigmamsg.DeleteClassRequest) returns (proto.nigmamsg.DeleteClassResponse);
     */
    deleteClass(input: DeleteClassRequest, options?: RpcOptions): UnaryCall<DeleteClassRequest, DeleteClassResponse>;
}
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.ClassService
 */
export class ClassServiceClient implements IClassServiceClient, ServiceInfo {
    typeName = ClassService.typeName;
    methods = ClassService.methods;
    options = ClassService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ListClass(proto.nigmamsg.ListClassRequest) returns (proto.nigmamsg.ListClassResponse);
     */
    listClass(input: ListClassRequest, options?: RpcOptions): UnaryCall<ListClassRequest, ListClassResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListClassRequest, ListClassResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ItemClass(proto.nigmamsg.ItemClassRequest) returns (proto.nigmamsg.ItemClassResponse);
     */
    itemClass(input: ItemClassRequest, options?: RpcOptions): UnaryCall<ItemClassRequest, ItemClassResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ItemClassRequest, ItemClassResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateClass(proto.nigmamsg.CreateClassRequest) returns (proto.nigmamsg.CreateClassResponse);
     */
    createClass(input: CreateClassRequest, options?: RpcOptions): UnaryCall<CreateClassRequest, CreateClassResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateClassRequest, CreateClassResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateClass(proto.nigmamsg.UpdateClassRequest) returns (proto.nigmamsg.UpdateClassResponse);
     */
    updateClass(input: UpdateClassRequest, options?: RpcOptions): UnaryCall<UpdateClassRequest, UpdateClassResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateClassRequest, UpdateClassResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteClass(proto.nigmamsg.DeleteClassRequest) returns (proto.nigmamsg.DeleteClassResponse);
     */
    deleteClass(input: DeleteClassRequest, options?: RpcOptions): UnaryCall<DeleteClassRequest, DeleteClassResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteClassRequest, DeleteClassResponse>("unary", this._transport, method, opt, input);
    }
}
