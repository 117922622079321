import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_DataLoading = _resolveComponent("DataLoading")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    style: {"width":"1024px","max-width":"80vw"},
    class: "q-pa-md"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_label, { class: "text-h5" }, {
                default: _withCtx(() => [
                  _cache[0] || (_cache[0] = _createElementVNode("p", null, "Удаление слова", -1)),
                  _createElementVNode("p", null, "«" + _toDisplayString(_ctx.item.text) + "»", 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_separator),
      (!_ctx.isWordsBusy)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_q_card_section, null, {
              default: _withCtx(() => [
                (!_ctx.item.hasChilds)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_2, "Вы действительно хотите удалить слово - ?"))
                  : (_openBlock(), _createElementBlock("p", _hoisted_3, "Нельзя удалить основное слово, имеющее привязанные слова"))
              ]),
              _: 1
            }),
            _createVNode(_component_q_separator),
            (!_ctx.item.hasChilds)
              ? (_openBlock(), _createBlock(_component_q_card_actions, {
                  key: 0,
                  align: "right"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_btn, {
                      color: "primary",
                      onClick: _ctx.actualDelete
                    }, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode(" Удалить ")
                      ])),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_q_btn, {
                      color: "primary",
                      onClick: _ctx.closeWordDelete
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode(" Отмена ")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_q_card_actions, {
                  key: 1,
                  align: "center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_btn, {
                      color: "primary",
                      onClick: _ctx.closeWordDelete
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("   Ок   ")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_DataLoading, { msgLoading: "Удаление" })
          ]))
    ]),
    _: 1
  }))
}