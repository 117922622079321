import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, withKeys as _withKeys, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "q-pa-md col-12 q-gutter-md",
  style: {"height":"100%"}
}
const _hoisted_2 = {
  class: "row",
  style: {"width":"100%","height":"40px"}
}
const _hoisted_3 = {
  class: "col-1",
  style: {"height":"40px"}
}
const _hoisted_4 = {
  class: "row",
  style: {"width":"100%","height":"40px","overflow":"hidden"}
}
const _hoisted_5 = {
  class: "col-12",
  style: {"height":"40px","overflow":"auto"}
}
const _hoisted_6 = {
  class: "col-12 relative-position",
  style: {"width":"100%","height":"calc(100% - 143px)","margin":"10px 0px 0px 10px"}
}
const _hoisted_7 = {
  class: "row items-center",
  style: {"width":"100%","height":"65px","margin-top":"5px"}
}
const _hoisted_8 = { class: "col-6" }
const _hoisted_9 = {
  class: "col-2",
  style: {"height":"100%"}
}
const _hoisted_10 = {
  class: "row justify-start items-center",
  style: {"height":"100%"}
}
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { key: 1 }

import {computed, onMounted, ref} from 'vue'
// import ExportMessages from "@/components/modules/polls/views/answers/ExportMessages.vue";
import type {QTableColumn} from "quasar";
import date from 'quasar/src/utils/date/date.js';;
import {useRouter} from "vue-router";
import {Filter} from "proto/nigma-common-proto/filter";
import {useMessageSrcApi} from "@/components/modules/polls/services/useMessageSrcApi";
import {MessageGrpModel} from "proto/nigma-message-proto/polls/message_grp";
import {MessageSrcModel} from "proto/nigma-message-proto/polls/message_src";
import {useTextHighlighter} from "@/composables/useTextHighlighter";
import {AgAnswerGrpModel} from "proto/nigma-message-proto/polls/ag_answer_grp";
import {useAgAnswerSrcApi} from "@/components/modules/polls/services/useAgAnswerSrcApi";
import store from "@/store";

interface SourceTableProps {
  message: MessageGrpModel | AgAnswerGrpModel,
  isFull: boolean,
  type: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SourceTable',
  props: {
    message: {},
    isFull: { type: Boolean },
    type: {}
  },
  emits: ['hideModal'],
  setup(__props: any, { emit: __emit }) {

const { listMessageSrcSourceRequest, personalListMessageSrcSourceRequest } = useMessageSrcApi()
const { listAgAnswerSrcSourceRequest, personalListAgAnswerSrcSourceRequest } = useAgAnswerSrcApi()
const { markedFindValue } = useTextHighlighter()

const router = useRouter()

const props = __props
const emit = __emit

const messagesSourceItems = ref<MessageSrcModel[]>([])
const columnsSource = ref<QTableColumn[]>([
  {
    name: 'sourceId',
    field: 'sourceId',
    label: 'Код',
    align: 'center',
  },
  {
    name: 'ssoId',
    field: 'ssoId',
    label: 'Пользователь',
    align: 'left',
  },
  {
    name: 'messageText',
    field: 'messageText',
    label: 'Ответ',
    align: 'left',
  },
  {
    name: 'projectName',
    field: 'projectName',
    label: 'Проект',
    align: 'left'
  },
  {
    name: 'messageTypeText',
    field: 'messageTypeText',
    label: 'Действие',
    align: 'left'
  },
  {
    name: 'messageTime',
    field: 'messageTime',
    label: 'Время',
    align: 'center',
  },
])
const messagesTablePagination = ref({
  sortBy: 'msrc.message_text',
  descending: false,
  page: 1,
  rowsPerPage: 25,
  rowsNumber: 0,
})
const rowsPerPageSourceOptions = [10, 25, 50, 100]
const markedFind = ref<string[]>([])
const search = ref('')
const sourceFilter = ref<Filter>({
  items: []
})
const sourceMessagesLoader = ref(false)
const personalLoader = ref(false)

const userId = computed(() => store.getters.getUserId)

const openUserProfile = (profile: string) => {
  const routeData = router.resolve({
    path: '/user-profile/' + profile,
  })

  window.open(routeData.href, '_blank')
}

const closeTableMessageSource = () => emit('hideModal')

const onRequestSource = (props) => {
  messagesTablePagination.value = props.pagination
  loadSourceMessages(false)
}

const findMessages = () => {
  markedFind.value = []
  sourceFilter.value.items = []

  if (search.value.length) {
    sourceFilter.value.items = [
      {
        fieldName: 'msrc.message_text',
        operand: 'ilike',
        value: search.value,
        or: {
          items: [
            {
              fieldName: 'a.sso_id::d_char64',
              operand: 'ilike',
              value: search.value,
            },
          ]
        }
      },
    ]

    markedFind.value.push(search.value)
  }

  loadSourceMessages(true)
}

const loadSourceMessages = (isResetPagination: boolean) => {
  sourceMessagesLoader.value = true

  if (isResetPagination) {
    messagesTablePagination.value.page = 1
    messagesTablePagination.value.rowsNumber = 0
  }

  messagesTablePagination.value.sortBy = 'msrc.message_text'
  messagesTablePagination.value.descending = false

  if (props.type === 'ag') {
    listAgAnswerSrcSourceRequest({
      filter: sourceFilter.value,
      sort: {
        name: messagesTablePagination.value.sortBy,
        exp: messagesTablePagination.value.descending ? 'DESC' : 'ASC',
      },
      pagination: {
        page:  messagesTablePagination.value.page,
        limit: messagesTablePagination.value.rowsPerPage,
        pages: 0,
        cnt: 0,
      },
      agAnswerGrpId: props.message.agAnswerGrpId,
      messageGrpId: props.message.messageGrpId,
      hasFull: props.isFull,
    })
        .then(res => {
          messagesTablePagination.value.rowsNumber = res.params.pagination.cnt
          messagesSourceItems.value = res.items
        })
        .finally(() => sourceMessagesLoader.value = false)
  } else {
    listMessageSrcSourceRequest({
      filter: sourceFilter.value,
      sort: {
        name: messagesTablePagination.value.sortBy,
        exp: messagesTablePagination.value.descending ? 'DESC' : 'ASC',
      },
      pagination: {
        page:  messagesTablePagination.value.page,
        limit: messagesTablePagination.value.rowsPerPage,
        pages: 0,
        cnt: 0,
      },
      messageGrpId: props.message.messageGrpId,
      hasFull: props.isFull,
    })
        .then(res => {
          messagesTablePagination.value.rowsNumber = res.params.pagination.cnt
          messagesSourceItems.value = res.items
        })
        .finally(() => sourceMessagesLoader.value = false)
  }
}

const loadPersonalSourceMessages = () => {
  personalLoader.value = true

  if (props.type === 'ag') {
    personalListAgAnswerSrcSourceRequest({
      filter: sourceFilter.value,
      sort: {
        name: '',
        exp: '',
      },
      agAnswerGrpId: props.message.agAnswerGrpId,
      messageGrpId: props.message.messageGrpId,
      hasFull: props.isFull,
      userId: userId.value,
    })
        .then(() => {
          closeTableMessageSource()

          router.push({
            name: 'UserProjects',
            query: {
              data: 'personal'
            }
          })
        })
        .finally(() => personalLoader.value = false)
  } else {
    personalListMessageSrcSourceRequest({
      filter: sourceFilter.value,
      sort: {
        name: '',
        exp: '',
      },
      messageGrpId: props.message.messageGrpId,
      hasFull: props.isFull,
      userId: userId.value,
    })
        .then(() => {
          closeTableMessageSource()

          router.push({
            name: 'UserProjects',
            query: {
              data: 'personal'
            }
          })
        })
        .finally(() => personalLoader.value = false)
  }
}

const loadData = () => {
  markedFind.value = []
  search.value = ''

  loadSourceMessages(true)
}

onMounted(() => loadData())

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    style: {"width":"1280px","max-width":"80vw","height":"840px","max-height":"80vh","box-shadow":"none"},
    class: "q-pa-md"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_q_card_section, { style: {"width":"100%","height":"80px","margin":"10px 0px 0px 10px","padding":"0px"} }, {
          default: _withCtx(() => [
            _createVNode(_component_q_item_label, { style: {"width":"100%","height":"80px","margin":"0px","padding":"0px"} }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _cache[2] || (_cache[2] = _createElementVNode("div", {
                    class: "col-11",
                    style: {"height":"40px","text-align":"center","font-size":"20px"}
                  }, " Исходные ответы ", -1)),
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_q_btn, {
                      icon: "close",
                      flat: "",
                      round: "",
                      dense: "",
                      style: {"float":"right"},
                      onClick: closeTableMessageSource
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("span", null, "(" + _toDisplayString(_ctx.isFull ? _ctx.message.messageGrpSum : _ctx.message.messageGrpCount) + ")", 1),
                    _createTextVNode(" " + _toDisplayString(_ctx.message.messageGrpText), 1)
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_q_separator, { style: {"width":"100%","margin":"5px 0px 5px 10px"} }),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_q_table, {
            style: {"height":"100%","overflow":"auto"},
            class: "sticky-header-table-answer",
            flat: "",
            dense: "",
            rows: messagesSourceItems.value,
            columns: columnsSource.value,
            "row-key": "sourceId",
            pagination: messagesTablePagination.value,
            "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => ((messagesTablePagination).value = $event)),
            "rows-per-page-options": rowsPerPageSourceOptions,
            onRequest: onRequestSource
          }, {
            top: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_q_input, {
                    modelValue: search.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
                    label: "Текст для поиска",
                    onKeydown: _withKeys(_withModifiers(findMessages, ["prevent"]), ["enter"])
                  }, null, 8, ["modelValue", "onKeydown"])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_q_btn, {
                      style: {"margin":"0px 15px"},
                      "background-color": "gray-12",
                      icon: "mdi-magnify",
                      onClick: findMessages
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_tooltip, null, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createTextVNode("Найти ответы")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createVNode(_component_q_btn, {
                  class: "q-ml-auto q-mr-md",
                  style: {"height":"40px"},
                  label: "Открыть в ЦП",
                  color: "primary",
                  onClick: loadPersonalSourceMessages
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_tooltip, {
                      anchor: "top middle",
                      self: "bottom middle",
                      offset: [10, 10]
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createElementVNode("p", {
                          style: {"font-size":"16px"},
                          class: "action-tooltip"
                        }, "Будут переданы первые 300 000 пользователей", -1)
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ])
            ]),
            "body-cell-sourceId": _withCtx((props) => [
              _createVNode(_component_q_td, {
                props: props,
                style: {"width":"60px"}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(props.value), 1)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-ssoId": _withCtx((props) => [
              _createVNode(_component_q_td, {
                props: props,
                onClick: ($event: any) => (openUserProfile(props.row.accountGuid)),
                title: "Перейти в профиль",
                style: {"width":"280px","cursor":"pointer"}
              }, {
                default: _withCtx(() => [
                  (markedFind.value.length)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        innerHTML: _unref(markedFindValue)(props.value, markedFind.value)
                      }, null, 8, _hoisted_11))
                    : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(props.value), 1))
                ]),
                _: 2
              }, 1032, ["props", "onClick"])
            ]),
            "body-cell-messageText": _withCtx((props) => [
              _createVNode(_component_q_td, { props: props }, {
                default: _withCtx(() => [
                  (markedFind.value.length)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        innerHTML: _unref(markedFindValue)(props.value, markedFind.value)
                      }, null, 8, _hoisted_13))
                    : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(props.value), 1))
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            "body-cell-messageTime": _withCtx((props) => [
              _createVNode(_component_q_td, {
                props: props,
                style: {"width":"160px"}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_unref(date).formatDate(props.value, 'DD.MM.YYYY HH:mm')), 1)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            pagination: _withCtx((scope) => [
              _createTextVNode(_toDisplayString((scope.pagination.rowsPerPage * scope.pagination.page - scope.pagination.rowsPerPage + 1).toLocaleString()) + " - " + _toDisplayString(scope.pagination.rowsPerPage * scope.pagination.page > scope.pagination.rowsNumber
              ? scope.pagination.rowsNumber.toLocaleString()
              : (scope.pagination.rowsPerPage * scope.pagination.page).toLocaleString()) + " из " + _toDisplayString(scope.pagination.rowsNumber.toLocaleString()) + " ", 1),
              (scope.pagesNumber > 2)
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 0,
                    icon: "first_page",
                    color: "grey-8",
                    round: "",
                    dense: "",
                    flat: "",
                    disable: scope.isFirstPage,
                    onClick: scope.firstPage,
                    class: "q-ml-md"
                  }, null, 8, ["disable", "onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_q_btn, {
                icon: "chevron_left",
                color: "grey-8",
                round: "",
                dense: "",
                flat: "",
                disable: scope.isFirstPage,
                onClick: scope.prevPage
              }, null, 8, ["disable", "onClick"]),
              _createVNode(_component_q_btn, {
                icon: "chevron_right",
                color: "grey-8",
                round: "",
                dense: "",
                flat: "",
                disable: scope.isLastPage,
                onClick: scope.nextPage
              }, null, 8, ["disable", "onClick"]),
              (scope.pagesNumber > 2)
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 1,
                    icon: "last_page",
                    color: "grey-8",
                    round: "",
                    dense: "",
                    flat: "",
                    disable: scope.isLastPage,
                    onClick: scope.lastPage
                  }, null, 8, ["disable", "onClick"]))
                : _createCommentVNode("", true)
            ]),
            "no-data": _withCtx(() => _cache[5] || (_cache[5] = [
              _createElementVNode("div", { class: "col-12 text-center" }, "Нет данных", -1)
            ])),
            _: 1
          }, 8, ["rows", "columns", "pagination"]),
          _createVNode(_component_q_inner_loading, {
            showing: sourceMessagesLoader.value || personalLoader.value,
            color: "grey",
            label: personalLoader.value ? 'Подождите, страница скоро откроется' : '',
            style: {"z-index":"100"}
          }, null, 8, ["showing", "label"])
        ]),
        _createVNode(_component_q_separator, { style: {"width":"100%","margin":"5px 0px 5px 10px"} }),
        _createVNode(_component_q_card_actions, { align: "right" }, {
          default: _withCtx(() => [
            _createVNode(_component_q_btn, {
              color: "primary",
              onClick: closeTableMessageSource
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" Закрыть ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}
}

})