import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "q-pa-md col-12 items-start q-gutter-md" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "self-center full-width no-outline" }
const _hoisted_5 = { class: "self-center full-width no-outline" }
const _hoisted_6 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_field = _resolveComponent("q-field")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_DataLoading = _resolveComponent("DataLoading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_q_card, null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_1, [
          _createVNode(_component_q_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_item_label, { class: "text-h5" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.node.text), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_q_separator),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_q_card, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  ('' !== _ctx.node.parentText)
                    ? (_openBlock(), _createBlock(_component_q_card_section, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_field, {
                            "model-value": _ctx.node.parent_id,
                            label: "Основное слово"
                          }, {
                            control: _withCtx(() => [
                              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.node.parentText), 1)
                            ]),
                            _: 1
                          }, 8, ["model-value"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (0 != _ctx.node.id)
                    ? (_openBlock(), _createBlock(_component_q_card_section, { key: 1 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_field, {
                            "model-value": _ctx.node.text,
                            label: "Текст слова"
                          }, {
                            control: _withCtx(() => [
                              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.node.text), 1)
                            ]),
                            _: 1
                          }, 8, ["model-value"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            })
          ])
        ], 512), [
          [_vShow, _ctx.isSelectedWordsNode]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_6, [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_label, { class: "text-h5 text-center" }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("Не выбран узел дерева")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ], 512), [
          [_vShow, !_ctx.isSelectedWordsNode]
        ])
      ]),
      _: 1
    }, 512), [
      [_vShow, !_ctx.isWordsBusy]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_DataLoading)
    ], 512), [
      [_vShow, _ctx.isWordsBusy]
    ])
  ], 64))
}