import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, unref as _unref, withKeys as _withKeys, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, isRef as _isRef, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { style: {"padding":"5px 24px"} }
const _hoisted_2 = {
  class: "row",
  style: {"max-height":"100%"}
}
const _hoisted_3 = {
  class: "row",
  style: {"width":"100%"}
}
const _hoisted_4 = { class: "col-5 q-pr-lg" }
const _hoisted_5 = { class: "relative-position" }
const _hoisted_6 = { class: "relative-position" }
const _hoisted_7 = { class: "bg-white col-7 shadow-4 text-black" }
const _hoisted_8 = { class: "col-12 q-pa-sm" }
const _hoisted_9 = ["title"]
const _hoisted_10 = { class: "text-subtitle1 text-weight-bold" }
const _hoisted_11 = {
  key: 1,
  class: "text-subtitle1"
}
const _hoisted_12 = {
  key: 0,
  class: "row justify-between q-pa-sm"
}
const _hoisted_13 = { class: "col-4" }
const _hoisted_14 = { class: "text-subtitle2" }
const _hoisted_15 = { class: "text-weight-bold" }
const _hoisted_16 = { class: "text-subtitle2" }
const _hoisted_17 = { class: "text-weight-bold" }
const _hoisted_18 = { class: "text-subtitle2" }
const _hoisted_19 = { class: "text-weight-bold" }
const _hoisted_20 = { class: "col-4" }
const _hoisted_21 = { class: "text-subtitle2" }
const _hoisted_22 = { class: "text-weight-bold" }
const _hoisted_23 = { class: "text-subtitle2" }
const _hoisted_24 = { class: "text-weight-bold" }
const _hoisted_25 = { class: "text-subtitle2" }
const _hoisted_26 = { class: "text-weight-bold" }
const _hoisted_27 = {
  class: "row text-black q-mt-lg",
  style: {"width":"100%"}
}
const _hoisted_28 = { class: "col-5 q-pr-lg text-black relative-position" }
const _hoisted_29 = { class: "col-12 shadow-4" }
const _hoisted_30 = { class: "col-12 bg-white" }
const _hoisted_31 = { class: "col-12" }
const _hoisted_32 = {
  class: "row",
  style: {"width":"100%"}
}
const _hoisted_33 = {
  class: "col-xl-5 col-lg-5",
  style: {"padding-right":"5px"}
}
const _hoisted_34 = { style: {"padding":"0 8px 0 5px","height":"100%"} }
const _hoisted_35 = {
  class: "col-xl-5 col-lg-4",
  style: {"padding-right":"0"}
}
const _hoisted_36 = { style: {"padding-left":"10px","height":"100%"} }
const _hoisted_37 = ["innerHTML"]
const _hoisted_38 = { key: 1 }
const _hoisted_39 = { key: 0 }
const _hoisted_40 = ["onClick"]
const _hoisted_41 = ["onClick"]
const _hoisted_42 = ["onClick"]
const _hoisted_43 = { class: "col-7 shadow-4 bg-white text-black relative-position" }
const _hoisted_44 = { style: {"overflow":"auto"} }

import store from "@/store";
// import * as XLSX from 'xlsx'
import { computed, onMounted, ref } from "vue";
import WordFormSettings from "../../answers/WordFormSettings.vue";
import type { QTableColumn } from "quasar";
import date from 'quasar/src/utils/date/date.js';;
import { Filter } from "proto/nigma-common-proto/filter";
import { useAgAnswerGrpApi } from "@/components/modules/polls/services/useAgAnswerGrpApi";
import { useAgQuestionApi } from "@/components/modules/polls/services/useAgQuestionApi";
import { useAgPollApi } from "@/components/modules/polls/services/useAgPollApi";
import { AgPollModel } from "proto/nigma-message-proto/polls/ag_poll";
import { AgQuestionModel } from "proto/nigma-message-proto/polls/ag_question";
import { useTextHighlighter } from "@/composables/useTextHighlighter";
import {
  AgAnswerGrpIdsModel,
  AgAnswerGrpModel,
} from "proto/nigma-message-proto/polls/ag_answer_grp";
import AgGeneralizationMessages from "@/components/modules/polls/components/agMessages/generalization/AgGeneralizationMessages.vue";
import MessageChilds from "@/components/modules/polls/components/MessageChilds.vue";
import SourceTable from "@/components/modules/polls/components/SourceTable.vue";
import { usePagination } from "@/composables/usePagination";


export default /*@__PURE__*/_defineComponent({
  __name: 'AgGeneralization',
  setup(__props) {

const { listAgAnswerGrpRequest, listAgAnswerGrpWithFindAnswerRequest } =
  useAgAnswerGrpApi();
const { listAgPollRequest } = useAgPollApi();
const { listAgQuestionRequest } = useAgQuestionApi();
const { markedFindValue } = useTextHighlighter();

const isActiveModal = ref(false);
const isActiveModalWordForm = ref(false);
const isActiveModalSource = ref(false);
const currentCountAnswer = ref(null);
const isFullCount = ref(false);
// const pollGroup = ref()
// const pollGroupOptions = ref([])
// const pollGroupSearchOptions = ref([])
const poll = ref<AgPollModel>();
const pollOptions = ref<AgPollModel[]>([]);
const pollSearchOptions = ref<AgPollModel[]>([]);
const question = ref<AgQuestionModel>();
const questionOptions = ref<AgQuestionModel[]>([]);
const questionSearchOptions = ref<AgQuestionModel[]>([]);
const columnsResult: QTableColumn[] = [
  {
    name: "messageGrpText",
    field: "messageGrpText",
    label: "Ответ",
    align: "left",
  },
  {
    name: "messageGrpSum",
    field: "messageGrpSum",
    label: "Количество голосов",
    align: "center",
  },
];
const columnsChild: QTableColumn[] = [
  {
    name: "messageGrpText",
    field: "messageGrpText",
    label: "Ответ",
    align: "left",
  },
  {
    name: "messageGrpCount",
    field: "messageGrpCount",
    label: "Количество голосов",
    align: "center",
  },
];
// const formFilter = ref([])
const resultFilter = ref<Filter>({
  items: [
    {
      fieldName: "ag.message_grp_text",
      operand: "ilike",
      value: "",
    },
  ],
});
// const filterProcessed = ref<FilterItem>({
//   fieldName: 'mg.answer_grp_processed',
//   operand: '=',
//   value: 'false',
// })
const { onTableRequest: resultRequest, pagination: resultPagination } =
  usePagination({
    sortBy: "message_grp_sum",
    descending: true,
    page: 1,
    rowsPerPage: 25,
    rowsNumber: 0,
  });
const rowsPerPageResultOptions = [10, 25, 50, 100];
const search = ref("");
const processedSign = ref<{ id: number; title: string }>();
const processedOptions = ref([
  { id: 0, title: "Все ответы" },
  { id: 1, title: "Только обработанные" },
  { id: 2, title: "Только необработанные" },
]);
const selectedResult = ref<AgAnswerGrpModel[]>([]);
const expandedResult = ref([]);
const markedFind = ref<string[]>([]);
const getTableRefVar = ref([]);
const tableChildRef = ref<InstanceType<typeof MessageChilds>>();
const agMessagePanel = ref();
const pollSelect = ref();
const questionSelect = ref();
const msgTableRows = ref<AgAnswerGrpModel[]>([]);
const msgTableLoader = ref(false);
const agPollLoader = ref(false);
const agQuestionLoader = ref(false);
// const abortListAnswer = ref<null | AbortController>(null);
// const abortListAnswerWithFind = ref<null | AbortController>(null);

const userRole = computed(() => store.getters.getUserAccess);

// const makeExcelForm = () => {
//   store
//       .dispatch('getAnswerGrpFormItems', {
//         filter: this.formFilter,
//         sort: {
//           name: 'answer_grp_sum',
//           exp: 'DESC',
//         },
//         question_id: this.question.id,
//       })
//       .then(
//           (resolve) => {
//             console.log('getAnswerGrpFormItems resolve', resolve)
//
//             if (resolve && resolve.length) {
//               // Маппинг данных
//               const data = resolve.map((item: any) => {
//                 return {
//                   'Группа(категория) опросов': item.getPollGroupTitle(),
//                   'Опрос': item.getPollTitle(),
//                   'Вопрос': item.getQuestionStitle(),
//                   'Количество голосов': item.getAnswerGrpSum(),
//                   'Ответы': item.getAnswerGrpText(),
//                 }
//               })
//
//               const ws = XLSX.utils.json_to_sheet(data)
//
//               // Подгоняем ширину столбцов под контент
//               const colLengths = Object.keys(data[0]).map((k) => k.toString().length)
//               for (const d of data) {
//                 Object.values(d).forEach((element: any, index) => {
//                   const length = element.toString().length
//                   if (colLengths[index] < length) {
//                     colLengths[index] = length
//                   }
//                 })
//               }
//               ws["!cols"] = colLengths.map((l) => {
//                 return {
//                   wch: l,
//                 }
//               })
//
//               const wb = XLSX.utils.book_new()
//               XLSX.utils.book_append_sheet(wb, ws, 'Обобщенные ответы')
//               XLSX.writeFile(wb, 'answers_' + this.getFormattedDateTimeUnderline(null) + '.xlsx')
//             } else {
//               console.log('Нет данных для вывода в файл!')
//             }
//           },
//           (reject) => {
//             console.log('getAnswerGrpFormItems reject', reject)
//           }
//       )
// }

// const makeWordForm = () => {
//   this.hideModal()
//   console.log('makeWordForm')
//   this.isActiveModalWordForm = true
//   this.isActiveModal = true
// }

const openChildTable = (props: any) => {
  setTimeout(() => {
    if (props) {
      props.__trClass = "";
    }
  }, 300);

  if (expandedResult.value[0] === props.row.messageGrpId) {
    expandedResult.value = [];
    getTableRefVar.value = [];
  } else {
    getTableRefVar.value = [];
    expandedResult.value = [props.row.messageGrpId];
  }
};

// const popupHidePollGroup = (evt: any) => {
//   console.log('popupHidePollGroup evt', evt)
//   const pollGroupSelect: any = this.$refs.pollGroupSelect
//   const modelValue: any = JSON.parse(
//       JSON.stringify(pollGroupSelect.modelValue)
//   )
//   this.pollGroupVOptions = this.pollGroupOptions
//   if (this.pollGroup.id == modelValue.id) {
//     this.changeListPollGroup(modelValue)
//   }
// }
//
// const filterPollGroup = (inputValue: string, doneFn: Function) => {
//   doneFn(
//       () => {
//         if (inputValue === '') {
//           this.pollGroupVOptions = this.pollGroupOptions
//         } else {
//           const needle = inputValue.toLowerCase()
//           const options = JSON.parse(
//               JSON.stringify(
//                   this.pollGroupOptions.filter(
//                       (v: any) => v.title.toLowerCase().indexOf(needle) > -1
//                   )
//               )
//           )
//           if (options && options.length) {
//             this.pollGroupVOptions = options.map((opt: any) => {
//               return {
//                 id: opt.id,
//                 title: this.strMarkRedPlus(inputValue, opt.title),
//                 count: opt.count,
//               }
//             })
//           } else {
//             this.pollGroupVOptions = []
//           }
//         }
//       },
//       (ref: any) => {
//         if (
//             inputValue !== '' &&
//             ref.options.length > 0 &&
//             ref.getOptionIndex() === -1
//         ) {
//           ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
//           ref.toggleOption(ref.options[ref.optionIndex], true) // toggle the focused option
//         }
//       }
//   )
// }

// const popupHidePoll = () => {
//   const modelValue: any = JSON.parse(JSON.stringify(pollSelect.value.modelValue))
//   pollSearchOptions.value = pollOptions.value
//
//   if (poll.value?.agPollId == modelValue.agPollId) {
//     changeListPoll(modelValue)
//   }
// }

const filterPoll = (inputValue: string, doneFn: Function) => {
  doneFn(
    () => {
      if (inputValue === "") {
        pollSearchOptions.value = pollOptions.value;
      } else {
        const needle = inputValue.toLowerCase();
        const options = pollOptions.value.filter(
          (v) => v.agPollTitle.toLowerCase().indexOf(needle) > -1
        );

        if (options && options.length) {
          pollSearchOptions.value = options.map((opt) => {
            return {
              agPollId: opt.agPollId,
              agPollGroup: opt.agPollGroup,
              agPollTitle: markedFindValue(opt.agPollTitle, [], inputValue),
              agPollDateBegin: opt.agPollDateBegin,
              agPollDateEnd: opt.agPollDateEnd,
              agPollNotes: opt.agPollNotes,
              agPollUserCount: opt.agPollUserCount,
              agPollTotalAnswers: opt.agPollTotalAnswers,
              agPollOpenAnswers: opt.agPollOpenAnswers,
              agPollDateUpdate: opt.agPollDateUpdate,
              agPollTarget: opt.agPollTarget,
            };
          });
        } else {
          pollSearchOptions.value = [];
        }
      }
    },
    (ref: any) => {
      if (
        inputValue !== "" &&
        ref.options.length > 0 &&
        ref.getOptionIndex() === -1
      ) {
        ref.moveOptionSelection(1, true);
        ref.toggleOption(ref.options[ref.optionIndex], true);
      }
    }
  );
};

// const popupHideQuestion = () => {
//   const modelValue: any = JSON.parse(
//       JSON.stringify(questionSelect.value.modelValue)
//   )
//   questionSearchOptions.value = questionOptions.value
//
//   if (question.value?.agQuestionId == modelValue.questionId) {
//     changeListQuestion(modelValue)
//   }
// }

const filterQuestion = (inputValue: string, doneFn: Function) => {
  doneFn(
    () => {
      if (inputValue === "") {
        questionSearchOptions.value = questionOptions.value;
      } else {
        const needle = inputValue.toLowerCase();
        const options = questionOptions.value.filter(
          (v) => v.agQuestionStitle.toLowerCase().indexOf(needle) > -1
        );

        if (options && options.length) {
          questionSearchOptions.value = options.map((opt) => {
            return {
              agQuestionId: opt.agQuestionId,
              agQuestionParentId: opt.agQuestionParentId,
              agPollId: opt.agPollId,
              agQuestionTypeId: opt.agQuestionTypeId,
              agQuestionIdx: opt.agQuestionIdx,
              agQuestionTitle: opt.agQuestionTitle,
              agQuestionStitle: markedFindValue(
                opt.agQuestionStitle,
                [],
                inputValue
              ),
              agQuestionProcessed: opt.agQuestionProcessed,
              answerSrcCount: opt.answerSrcCount,
              answerSrcSum: opt.answerSrcSum,
              similarity: opt.similarity,
              hasChilds: opt.hasChilds,
            };
          });
        } else {
          questionSearchOptions.value = [];
        }
      }
    },
    (ref: any) => {
      if (
        inputValue !== "" &&
        ref.options.length > 0 &&
        ref.getOptionIndex() === -1
      ) {
        ref.moveOptionSelection(1, true);
        ref.toggleOption(ref.options[ref.optionIndex], true);
      }
    }
  );
};

const changeProcessedSign = (event: any) => {
  const processed = JSON.parse(JSON.stringify(event));

  if (processed.id === 0) {
    resultFilter.value.items.pop();

    findAnswersResult();
  } else if (processed.id === 1) {
    resultFilter.value.items[1] = {
      fieldName: "ag.has_childs",
      operand: "=",
      value: "true",
    };

    findAnswersResult();
  } else if (processed.id === 2) {
    resultFilter.value.items[1] = {
      fieldName: "ag.has_childs",
      operand: "=",
      value: "false",
    };

    findAnswersResult();
  }
};

const afterAction = (item: AgAnswerGrpIdsModel, isSeparate?: boolean) => {
  search.value = "";
  expandedResult.value = [];
  msgTableLoader.value = true;

  if (processedSign.value && processedSign.value.id > 1) {
    processedSign.value = processedOptions.value[0];
    changeProcessedSign(processedSign.value);
  }

  if (isSeparate) {
    loadAnswerResultItems(false);
  } else {
    // if (abortListAnswerWithFind.value) {
    //   abortListAnswerWithFind.value.abort();
    // }

    // abortListAnswerWithFind.value = new AbortController();

    listAgAnswerGrpWithFindAnswerRequest(
      {
        filter: resultFilter.value,
        sort: {
          name: resultPagination.value.sortBy,
          exp: resultPagination.value.descending ? "DESC" : "ASC",
        },
        pagination: {
          page: 1,
          limit: resultPagination.value.rowsPerPage,
          pages: 0,
          cnt: 0,
        },
        agQuestionId: question.value?.agQuestionId || 0,
        agPollId: poll.value?.agPollId || 0,
        item: item,
      }
      // abortListAnswerWithFind.value
    ).then((res) => {
      if (res) {
        resultPagination.value.page = res.params?.pagination?.page || 1;
        resultPagination.value.rowsNumber = res.params?.pagination?.cnt || 0;
        msgTableRows.value = res.items;

        if (selectedResult.value[0])
          onRowClickAnswerResult({ row: selectedResult.value[0] }, false);

        msgTableLoader.value = false;
      }
    });
    // .catch((err) => {
    //   if (err.code === "CANCELLED") {
    //     abortListAnswerWithFind.value = null;

    //     return;
    //   }
    // });
  }
};

const onRowClickAnswerResult = (props: any, isResetSimilarity: boolean) => {
  selectedResult.value = [props.row];

  setTimeout(() => {
    if (props) {
      props.__trClass = "";
    }
  }, 300);

  if (agMessagePanel.value)
    agMessagePanel.value?.loadData(
      true,
      isResetSimilarity,
      selectedResult.value.length
        ? selectedResult.value[0]
        : {
            agAnswerGrpId: 0,
            agAnswerGrpParentId: 0,
            agAnswerId: 0,
            agQuestionId: 0,
            agPollId: 0,
            messageGrpId: 0,
            messageGrpParentId: 0,
            messageGrpText: "",
            messageGrpCount: 0,
            messageGrpProcessed: false,
            messageGrpSum: 0,
            similarity: 0,
            hasChilds: false,
          }
    );
};

const hideModal = () => {
  isActiveModal.value = false;
  isActiveModalWordForm.value = false;
  isActiveModalSource.value = false;
  currentCountAnswer.value = null;
  isFullCount.value = false;
};

const onRowShowAnswerSource = (row: any, isFull: boolean) => {
  hideModal();
  currentCountAnswer.value = Object.assign(row);
  isFullCount.value = isFull;
  isActiveModalSource.value = true;
  isActiveModal.value = true;
};

// const changeListPollGroup = (event: any) => {
//   console.log('changeListPollGroup event', event)
//   if (!event) {
//     const pollGroupSelect: any = this.$refs.pollGroupSelect
//     if (pollGroupSelect && this.pollGroup) {
//       pollGroupSelect.updateInputValue(this.pollGroup.title)
//     }
//   } else {
//     const title: string = event.title
//     if (
//         title.indexOf("<span style='color: red;'>") > -1 &&
//         title.indexOf('</b></span>') > -1
//     ) {
//       //this.pollGroupVOptions = this.pollGroupOptions
//       this.pollGroup = this.pollGroupOptions.find(
//           (opt: any) => event.id == opt.id
//       )
//     } else {
//       this.pollGroup = {
//         id: event.id,
//         title: event.title,
//         count: event.count,
//       }
//     }
//     this.loadPollItems()
//   }
// }

const changeListPoll = (event: AgPollModel) => {
  selectedResult.value = [];

  if (!event) {
    if (pollSelect.value && poll.value) {
      pollSelect.value.updateInputValue(poll.value?.agPollTitle);
    }
  } else {
    const title: string = event.agPollTitle;
    if (
      title.indexOf("<span style='color: red;'>") > -1 &&
      title.indexOf("</b></span>") > -1
    ) {
      poll.value = pollOptions.value.find(
        (opt) => event.agPollId === opt.agPollId
      );
    } else {
      poll.value = event;
    }

    loadQuestionItems();
  }
};

const changeListQuestion = (event: any) => {
  if (!event) {
    if (questionSelect.value && question.value) {
      questionSelect.value.updateInputValue(question.value?.agQuestionStitle);
    }
  } else {
    const stitle: string = event.agQuestionStitle;
    if (
      stitle.indexOf("<span style='color: red;'>") > -1 &&
      stitle.indexOf("</b></span>") > -1
    ) {
      question.value = questionOptions.value.find(
        (opt: any) => event.id == opt.id
      );
    } else {
      question.value = event;
    }

    loadAnswerResultItems(true);
  }
};

const findAnswersResult = () => {
  markedFind.value = [];

  resultFilter.value.items[0].value = search.value;

  if (search.value.length) {
    markedFind.value.push(search.value);
  }

  loadAnswerResultItems(true);
};

const loadAnswerResultItems = (isResetPagination: boolean) => {
  msgTableLoader.value = true;

  if (isResetPagination) {
    resultPagination.value.page = 1;
    resultPagination.value.rowsNumber = 0;
  }

  // if (abortListAnswer.value) {
  //   abortListAnswer.value.abort();
  // }

  // abortListAnswer.value = new AbortController();

  listAgAnswerGrpRequest(
    {
      filter: resultFilter.value,
      sort: {
        name: resultPagination.value.sortBy,
        exp: resultPagination.value.descending ? "DESC" : "ASC",
      },
      pagination: {
        page: resultPagination.value.page,
        limit: resultPagination.value.rowsPerPage,
        pages: 0,
        cnt: 0,
      },
      agQuestionId: question.value?.agQuestionId || 0,
      agPollId: poll.value?.agPollId || 0,
      isWorked: false,
    }
    // abortListAnswer.value
  ).then((res) => {
    if (res) {
      resultPagination.value.rowsNumber = res.params?.pagination?.cnt || 0;
      msgTableRows.value = res.items;

      onRowClickAnswerResult(
        {
          row: selectedResult.value.length
            ? selectedResult.value[0]
            : {
                agAnswerGrpId: 0,
                agAnswerGrpParentId: 0,
                agAnswerId: 0,
                agQuestionId: 0,
                agPollId: 0,
                messageGrpId: 0,
                messageGrpParentId: 0,
                messageGrpText: "",
                messageGrpCount: 0,
                messageGrpProcessed: false,
                messageGrpSum: 0,
                similarity: 0,
                hasChilds: false,
              },
        },
        false
      );

      msgTableLoader.value = false;
    }
  });
  // .catch((err) => {
  //   if (err.code === "CANCELLED") {
  //     abortListAnswer.value = null;

  //     return;
  //   }
  // });
};

const onRequestResult = resultRequest(() => loadAnswerResultItems(false));

// const clearQuestionItems = () => {
//   this.question = {
//     id: 0,
//     poll_id: 0,
//     type_id: 2,
//     idx: 0,
//     title: '',
//     stitle: '',
//     count: 0,
//   }
//   store.dispatch('setQuestionItems', [])
//   this.questionOptions = []
//   this.questionVOptions = []
// }

const loadQuestionItems = () => {
  agQuestionLoader.value = true;

  listAgQuestionRequest({
    filter: {
      items: [],
    },
    sort: {
      name: "aq.answer_src_count",
      exp: "desc",
    },
    pagination: {
      page: 1,
      limit: 100000,
      pages: 0,
      cnt: 0,
    },
    agPollId: poll.value?.agPollId || 0,
    isWorked: false,
  })
    .then((res) => {
      questionOptions.value = res.items.map((item) => {
        return {
          agQuestionId: item.agQuestionId,
          agQuestionParentId: item.agQuestionParentId,
          agPollId: item.agPollId,
          agQuestionTypeId: item.agQuestionTypeId,
          agQuestionIdx: item.agQuestionIdx,
          agQuestionTitle: item.agQuestionTitle,
          agQuestionStitle: `(${item.answerSrcSum}) ${item.agQuestionStitle}`,
          agQuestionProcessed: item.agQuestionProcessed,
          answerSrcCount: item.answerSrcCount,
          answerSrcSum: item.answerSrcSum,
          similarity: item.similarity,
          hasChilds: item.hasChilds,
        };
      });
      questionOptions.value.unshift({
        agQuestionId: 0,
        agQuestionParentId: 0,
        agPollId: 0,
        agQuestionTypeId: 0,
        agQuestionIdx: 0,
        agQuestionTitle: "",
        agQuestionStitle: "Все вопросы",
        agQuestionProcessed: false,
        answerSrcCount: 0,
        answerSrcSum: 0,
        similarity: 0,
        hasChilds: false,
      });
      question.value = questionOptions.value[0];
      questionSearchOptions.value = questionOptions.value;
    })
    .finally(() => {
      loadAnswerResultItems(true);
      agQuestionLoader.value = false;
    });
};

// const clearPollItems = () => {
//   this.clearQuestionItems()
//   this.poll = {
//     id: 0,
//     group_id: 0,
//     title: '',
//     origin_title: '',
//     date_begin: '',
//     date_end: '',
//     notes: '',
//     target: '',
//     points: 0,
//     total_answers: 0,
//     open_answers: 0,
//     loaded_answers: 0,
//     loading_flag: false,
//     date_update: '',
//     user_count: 0,
//   }
//   store.dispatch('setPollItems', [])
//   this.pollOptions = []
//   this.pollVOptions = []
// }

const loadPollItems = () => {
  //   let tempFilter: FilterItem = {
  //   fieldName: 'p.poll_group_id',
  //   operand: '>',
  //   value: '0',
  //   or: {
  //     items: []
  //   },
  // }

  // this.pollGroupTickedNodes.forEach((el: any) => {
  //   tempFilter.or?.items.push({
  //     fieldName: 'p.poll_group_id',
  //     operand: '=',
  //     value: el,
  //   })
  // })

  agPollLoader.value = true;

  listAgPollRequest({
    filter: {
      items: [],
    },
    sort: {
      name: "ag_poll_id",
      exp: "asc",
    },
    pagination: {
      page: 1,
      limit: 100000,
      pages: 0,
      cnt: 0,
    },
  })
    .then((res) => {
      pollOptions.value = res.items.map((item) => {
        return {
          agPollId: item.agPollId,
          agPollGroup: item.agPollGroup,
          agPollTitle: `${item.agPollId} | ${item.agPollTitle}`,
          agPollDateBegin: item.agPollDateBegin,
          agPollDateEnd: item.agPollDateEnd,
          agPollNotes: item.agPollNotes,
          agPollUserCount: item.agPollUserCount,
          agPollTotalAnswers: item.agPollTotalAnswers,
          agPollOpenAnswers: item.agPollOpenAnswers,
          agPollDateUpdate: item.agPollDateUpdate,
          agPollTarget: item.agPollTarget,
        };
      });
      pollOptions.value.unshift({
        agPollId: 0,
        agPollGroup: "",
        agPollTitle: "Все опросы",
        agPollDateBegin: "",
        agPollDateEnd: "",
        agPollNotes: "",
        agPollUserCount: 0,
        agPollTotalAnswers: 0,
        agPollOpenAnswers: 0,
        agPollDateUpdate: "",
        agPollTarget: "",
      });
      poll.value = pollOptions.value[0];
      pollSearchOptions.value = pollOptions.value;

      loadQuestionItems();
      // changeListPoll(pollSearchOptions.value[0])
    })
    .finally(() => (agPollLoader.value = false));
};

// const clearPollGroupItems = () => {
//   this.clearPollItems()
//   this.pollGroup = {
//     id: 0,
//     title: '',
//     count: 0,
//   }
//   store.dispatch('setPollGroupItems', [])
//   pollGroupOptions.value = []
//   poll.value = []
// }

// const loadPollGroupItems = () => {
//   store.dispatch('getPollGroupItems').then(
//       (resolve: any) => {
//         console.log('getPollGroupItems resolve', resolve)
//         if (resolve && resolve.items && resolve.items.length) {
//           let nodes = [
//             {
//               label: 'Все группы опросов',
//               children: []
//             }
//           ] as any
//
//           this.pollGroupOptions = []
//           this.pollGroupVOptions = []
//           for (let grpItem of resolve.items) {
//             nodes[0].children.push({
//               label: '(' + grpItem.getPollCount() + ') ' + grpItem.getPollGroupTitle(),
//               value: grpItem.getPollGroupId()
//             })
//
//             this.pollGroupTickedNodes.push(grpItem.getPollGroupId())
//
//             this.pollGroupOptions.push({
//               id: grpItem.getPollGroupId(),
//               title: '(' + grpItem.getPollCount() + ') ' + grpItem.getPollGroupTitle(),
//               count: grpItem.getPollCount(),
//             })
//           }
//           this.pollGroupVOptions = this.pollGroupOptions
//           this.changeListPollGroup(this.pollGroupVOptions[0])
//           this.pollGroupNodes = nodes
//         } else {
//           this.clearPollGroupItems()
//         }
//       },
//       (reject) => {
//         console.log('getPollGroupItems reject', reject)
//         this.clearPollGroupItems()
//       }
//   )
// }

const separateTableChild = () => tableChildRef.value?.separateChild();

const afterSeparate = (item: AgAnswerGrpIdsModel) => {
  afterAction(item, true);
  getTableRefVar.value = [];
};

const checkChildLength = (childLength: any) => {
  getTableRefVar.value = childLength;
};

const loadData = () => {
  hideModal();
  processedSign.value = processedOptions.value[0];
  loadPollItems();
  loadAnswerResultItems(true);
  // loadPollGroupItems()
};

// const clickOutside = () => {
//   const elem = document.querySelector('.project-select-tree') as any
//   return this.isDropdownOpen = elem.contains(e.target) && this.isDropdownOpen
// }
//
// const clickChecker = () => {
//   this.isDropdownOpen = !this.isDropdownOpen
//   document.addEventListener('click', this.clickOutside)
// }

onMounted(loadData);

return (_ctx: any,_cache: any) => {
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[30] || (_cache[30] = _createElementVNode("div", { class: "text-h4 q-mt-sm q-mb-sm" }, "Обобщение", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_q_select, {
              style: {"margin-top":"15px"},
              ref_key: "pollSelect",
              ref: pollSelect,
              modelValue: poll.value,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((poll).value = $event)),
                _cache[1] || (_cache[1] = ($event: any) => (changeListPoll($event)))
              ],
              "use-input": "",
              "hide-selected": "",
              "fill-input": "",
              filled: "",
              "input-debounce": "300",
              options: pollSearchOptions.value,
              "option-value": "agPollId",
              "option-label": "agPollTitle",
              "options-dense": "",
              "options-html": "",
              label: "Опрос",
              onFilter: filterPoll
            }, {
              "no-option": _withCtx(() => [
                _createVNode(_component_q_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_section, { class: "text-grey" }, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode(" Не найдено ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue", "options"]),
            _createVNode(_component_q_inner_loading, {
              showing: agPollLoader.value,
              color: "grey"
            }, null, 8, ["showing"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_q_select, {
              style: {"margin-top":"15px"},
              ref_key: "questionSelect",
              ref: questionSelect,
              modelValue: question.value,
              "onUpdate:modelValue": [
                _cache[2] || (_cache[2] = ($event: any) => ((question).value = $event)),
                _cache[3] || (_cache[3] = ($event: any) => (changeListQuestion($event)))
              ],
              "use-input": "",
              "hide-selected": "",
              "fill-input": "",
              filled: "",
              "input-debounce": "300",
              options: questionSearchOptions.value,
              "option-value": "agQuestionId",
              "option-label": "agQuestionStitle",
              "options-dense": "",
              "options-html": "",
              label: "Вопрос",
              onFilter: filterQuestion,
              disable: poll.value?.agPollId === 0
            }, {
              "no-option": _withCtx(() => [
                _createVNode(_component_q_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_section, { class: "text-grey" }, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createTextVNode(" Не найдено ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue", "options", "disable"]),
            (poll.value?.agPollId === 0)
              ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createElementVNode("div", { class: "text-subtitle2" }, "Выберите опрос", -1)
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_q_inner_loading, {
              showing: agQuestionLoader.value,
              color: "grey"
            }, null, 8, ["showing"])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[21] || (_cache[21] = _createElementVNode("div", { class: "text-h6 text-weight-bold q-pa-sm" }, " Информация об опросе ", -1)),
          _createVNode(_component_q_separator),
          _createElementVNode("div", _hoisted_8, [
            (poll.value?.agPollId !== 0)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "text-subtitle1 ellipsis",
                  title: poll.value?.agPollTitle
                }, [
                  _cache[14] || (_cache[14] = _createTextVNode(" Название опроса: ")),
                  _createElementVNode("span", _hoisted_10, _toDisplayString(poll.value?.agPollTitle), 1)
                ], 8, _hoisted_9))
              : (_openBlock(), _createElementBlock("div", _hoisted_11, "Выбраны все опросы"))
          ]),
          (poll.value?.agPollId !== 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _cache[15] || (_cache[15] = _createTextVNode(" Количество участников: ")),
                    _createElementVNode("span", _hoisted_15, _toDisplayString(poll.value?.agPollUserCount.toLocaleString()), 1)
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _cache[16] || (_cache[16] = _createTextVNode(" Всего ответов: ")),
                    _createElementVNode("span", _hoisted_17, _toDisplayString(poll.value?.agPollTotalAnswers.toLocaleString()), 1)
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _cache[17] || (_cache[17] = _createTextVNode(" Открытых ответов: ")),
                    _createElementVNode("span", _hoisted_19, _toDisplayString(poll.value?.agPollOpenAnswers.toLocaleString()), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _cache[18] || (_cache[18] = _createTextVNode(" Дата начала: ")),
                    _createElementVNode("span", _hoisted_22, _toDisplayString(poll.value?.agPollDateBegin.split("-").reverse().join(".")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _cache[19] || (_cache[19] = _createTextVNode(" Дата окончания: ")),
                    _createElementVNode("span", _hoisted_24, _toDisplayString(poll.value?.agPollDateEnd.split("-").reverse().join(".")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _cache[20] || (_cache[20] = _createTextVNode(" Дата актуализации данных: ")),
                    _createElementVNode("span", _hoisted_26, _toDisplayString(_unref(date).formatDate(poll.value?.agPollDateUpdate * 1000, "DD.MM.YYYY")), 1)
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("div", _hoisted_28, [
          _createElementVNode("div", _hoisted_29, [
            _cache[28] || (_cache[28] = _createElementVNode("div", { class: "text-h6 bg-white text-black text-weight-bold q-pa-sm" }, " Ответы ", -1)),
            _createVNode(_component_q_separator),
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("div", _hoisted_31, [
                _createVNode(_component_q_table, {
                  class: "sticky-header-table-answer",
                  flat: "",
                  dense: "",
                  rows: msgTableRows.value,
                  columns: columnsResult,
                  "row-key": "messageGrpId",
                  "wrap-cells": "",
                  selection: "single",
                  selected: selectedResult.value,
                  "onUpdate:selected": _cache[7] || (_cache[7] = ($event: any) => ((selectedResult).value = $event)),
                  pagination: _unref(resultPagination),
                  "onUpdate:pagination": _cache[8] || (_cache[8] = ($event: any) => (_isRef(resultPagination) ? (resultPagination).value = $event : null)),
                  "rows-per-page-options": rowsPerPageResultOptions,
                  expanded: expandedResult.value,
                  "onUpdate:expanded": _cache[9] || (_cache[9] = ($event: any) => ((expandedResult).value = $event)),
                  onRequest: _unref(onRequestResult),
                  style: _normalizeStyle(msgTableRows.value.length ? 'height: 51vh' : null)
                }, {
                  top: _withCtx(() => [
                    _createElementVNode("div", _hoisted_32, [
                      _createElementVNode("div", _hoisted_33, [
                        _createVNode(_component_q_input, {
                          modelValue: search.value,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((search).value = $event)),
                          filled: "",
                          label: "Текст для поиска",
                          onKeypress: _withKeys(findAnswersResult, ["enter"])
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_34, [
                        _createVNode(_component_q_btn, {
                          style: {"padding":"10px","margin-top":"6px","margin-right":"1px"},
                          "background-color": "gray-12",
                          icon: "mdi-magnify",
                          onClick: findAnswersResult
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_tooltip, null, {
                              default: _withCtx(() => _cache[22] || (_cache[22] = [
                                _createTextVNode("Найти ответы")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", _hoisted_35, [
                        _createVNode(_component_q_select, {
                          modelValue: processedSign.value,
                          "onUpdate:modelValue": [
                            _cache[5] || (_cache[5] = ($event: any) => ((processedSign).value = $event)),
                            _cache[6] || (_cache[6] = ($event: any) => (changeProcessedSign($event)))
                          ],
                          options: processedOptions.value,
                          filled: "",
                          "option-value": "id",
                          "option-label": "title",
                          label: "Признак обработки ответа",
                          style: {"white-space":"nowrap"}
                        }, null, 8, ["modelValue", "options"])
                      ]),
                      _createElementVNode("div", _hoisted_36, [
                        ('admin editor'.includes(userRole.value))
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 0,
                              style: {"padding":"10px","margin-top":"6px","margin-right":"1px"},
                              "background-color": "gray-12",
                              icon: "mdi-pen-remove",
                              onClick: separateTableChild,
                              disable: 
                            msgTableRows.value.length === 0 ||
                            getTableRefVar.value.length === 0
                          
                            }, {
                              default: _withCtx(() => [
                                (
                              msgTableRows.value.length !== 0 &&
                              getTableRefVar.value.length !== 0
                            )
                                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                                      default: _withCtx(() => _cache[23] || (_cache[23] = [
                                        _createTextVNode("Разделить ответы")
                                      ])),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true),
                                (msgTableRows.value.length === 0)
                                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                                      default: _withCtx(() => _cache[24] || (_cache[24] = [
                                        _createTextVNode("Количество ответов должно быть > 0")
                                      ])),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true),
                                (
                              msgTableRows.value.length > 0 &&
                              getTableRefVar.value.length === 0
                            )
                                  ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 2 }, {
                                      default: _withCtx(() => _cache[25] || (_cache[25] = [
                                        _createTextVNode("Необходимо выбрать хотя бы 1 обобщенный ответ")
                                      ])),
                                      _: 1
                                    }))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }, 8, ["disable"]))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]),
                  header: _withCtx((props) => [
                    _createVNode(_component_q_tr, { props: props }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_th, { "auto-width": "" }),
                        _createVNode(_component_q_th, {
                          key: "messageGrpText",
                          props: props,
                          class: "text-left"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(props.cols[0].label), 1)
                          ]),
                          _: 2
                        }, 1032, ["props"]),
                        _createVNode(_component_q_th, {
                          key: "messageGrpSum",
                          props: props,
                          class: "text-center",
                          style: {"width":"120px"}
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(props.cols[1].label), 1)
                          ]),
                          _: 2
                        }, 1032, ["props"])
                      ]),
                      _: 2
                    }, 1032, ["props"])
                  ]),
                  body: _withCtx((props) => [
                    (_openBlock(), _createBlock(_component_q_tr, {
                      key: 
                        props.rowIndex +
                        '###primary###' +
                        props.row.messageGrpId
                      ,
                      props: props,
                      class: _normalizeClass(props.row.hasChilds ? 'answer-processed' : '')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_td, null, {
                          default: _withCtx(() => [
                            _withDirectives(_createVNode(_component_q_btn, {
                              size: "sm",
                              flat: "",
                              rounded: "",
                              dense: "",
                              icon: 
                            props.expand
                              ? 'fas fa-caret-down'
                              : 'fas fa-caret-right'
                          ,
                              onClick: ($event: any) => (openChildTable(props))
                            }, null, 8, ["icon", "onClick"]), [
                              [_vShow, props.row.hasChilds]
                            ])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_q_td, {
                          onClick: ($event: any) => (onRowClickAnswerResult(props, true))
                        }, {
                          default: _withCtx(() => [
                            (markedFind.value.length)
                              ? (_openBlock(), _createElementBlock("span", {
                                  key: 0,
                                  innerHTML: 
                            _unref(markedFindValue)(
                              props.row.messageGrpText,
                              markedFind.value
                            )
                          
                                }, null, 8, _hoisted_37))
                              : (_openBlock(), _createElementBlock("span", _hoisted_38, _toDisplayString(props.row.messageGrpText), 1))
                          ]),
                          _: 2
                        }, 1032, ["onClick"]),
                        _createVNode(_component_q_td, {
                          class: "text-center",
                          style: {"width":"120px"}
                        }, {
                          default: _withCtx(() => [
                            (props.row.hasChilds)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_39, [
                                  _createElementVNode("span", {
                                    style: {"cursor":"pointer"},
                                    title: "Исходные ответы",
                                    onClick: ($event: any) => (onRowShowAnswerSource(props.row, true))
                                  }, _toDisplayString(props.row.messageGrpSum.toLocaleString()), 9, _hoisted_40),
                                  _cache[26] || (_cache[26] = _createTextVNode(" | ")),
                                  _createElementVNode("span", {
                                    style: {"cursor":"pointer"},
                                    title: "Исходные ответы",
                                    onClick: ($event: any) => (onRowShowAnswerSource(props.row, false))
                                  }, _toDisplayString(props.row.messageGrpCount.toLocaleString()), 9, _hoisted_41)
                                ]))
                              : (_openBlock(), _createElementBlock("span", {
                                  key: 1,
                                  style: {"cursor":"pointer"},
                                  title: "Исходные ответы",
                                  onClick: ($event: any) => (onRowShowAnswerSource(props.row, false))
                                }, _toDisplayString(props.row.messageGrpCount.toLocaleString()), 9, _hoisted_42))
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["props", "class"])),
                    (props.expand)
                      ? (_openBlock(), _createBlock(_component_q_tr, {
                          key: 
                        props.rowIndex +
                        '###secondary###' +
                        props.row.messageGrpId
                      ,
                          props: props
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_td, {
                              colspan: "100%",
                              style: {"padding-right":"0","padding-top":"0","padding-left":"1.7rem"}
                            }, {
                              default: _withCtx(() => [
                                (props.expand)
                                  ? (_openBlock(), _createBlock(MessageChilds, {
                                      key: 0,
                                      ref_key: "tableChildRef",
                                      ref: tableChildRef,
                                      "table-key": 
                            props.rowIndex +
                            '###expanded###' +
                            props.row.messageGrpId
                          ,
                                      columns: columnsChild,
                                      "ag-poll-id": poll.value?.agPollId || 0,
                                      "ag-question-id": question.value?.agQuestionId || 0,
                                      "current-item": props.row,
                                      type: "ag",
                                      onShowSource: onRowShowAnswerSource,
                                      onAfterSeparate: afterSeparate,
                                      onCheckChild: checkChildLength
                                    }, null, 8, ["table-key", "ag-poll-id", "ag-question-id", "current-item"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["props"]))
                      : _createCommentVNode("", true)
                  ]),
                  pagination: _withCtx((scope) => [
                    _createTextVNode(_toDisplayString((
                        scope.pagination.rowsPerPage * scope.pagination.page -
                        scope.pagination.rowsPerPage +
                        1
                      ).toLocaleString()) + " - " + _toDisplayString(scope.pagination.rowsPerPage * scope.pagination.page >
                      scope.pagination.rowsNumber
                        ? scope.pagination.rowsNumber.toLocaleString()
                        : (
                            scope.pagination.rowsPerPage * scope.pagination.page
                          ).toLocaleString()) + " из " + _toDisplayString(scope.pagination.rowsNumber.toLocaleString()) + " ", 1),
                    (scope.pagesNumber > 2)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          icon: "first_page",
                          color: "grey-8",
                          round: "",
                          dense: "",
                          flat: "",
                          disable: scope.isFirstPage,
                          onClick: scope.firstPage,
                          class: "q-ml-md"
                        }, null, 8, ["disable", "onClick"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_q_btn, {
                      icon: "chevron_left",
                      color: "grey-8",
                      round: "",
                      dense: "",
                      flat: "",
                      disable: scope.isFirstPage,
                      onClick: scope.prevPage
                    }, null, 8, ["disable", "onClick"]),
                    _createVNode(_component_q_btn, {
                      icon: "chevron_right",
                      color: "grey-8",
                      round: "",
                      dense: "",
                      flat: "",
                      disable: scope.isLastPage,
                      onClick: scope.nextPage
                    }, null, 8, ["disable", "onClick"]),
                    (scope.pagesNumber > 2)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 1,
                          icon: "last_page",
                          color: "grey-8",
                          round: "",
                          dense: "",
                          flat: "",
                          disable: scope.isLastPage,
                          onClick: scope.lastPage
                        }, null, 8, ["disable", "onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  "no-data": _withCtx(() => _cache[27] || (_cache[27] = [
                    _createElementVNode("div", { class: "col-12 text-center text-h4" }, "Нет данных", -1)
                  ])),
                  _: 1
                }, 8, ["rows", "selected", "pagination", "expanded", "onRequest", "style"])
              ])
            ])
          ]),
          _createVNode(_component_q_inner_loading, {
            showing: msgTableLoader.value,
            color: "grey",
            style: {"z-index":"100"}
          }, null, 8, ["showing"])
        ]),
        _createElementVNode("div", _hoisted_43, [
          _createElementVNode("div", _hoisted_44, [
            _cache[29] || (_cache[29] = _createElementVNode("div", { class: "text-h6 text-weight-bold q-pa-sm" }, " Поиск схожих вариантов ", -1)),
            _createVNode(_component_q_separator),
            _createVNode(AgGeneralizationMessages, {
              ref_key: "agMessagePanel",
              ref: agMessagePanel,
              onAfterAction: afterAction,
              onShowSource: onRowShowAnswerSource,
              "ag-poll-id": poll.value?.agPollId || 0,
              "ag-question-id": question.value?.agQuestionId || 0,
              "action-disabler": msgTableLoader.value
            }, null, 8, ["ag-poll-id", "ag-question-id", "action-disabler"]),
            _createVNode(_component_q_inner_loading, {
              showing: false,
              color: "grey",
              style: {"z-index":"100"}
            })
          ])
        ])
      ])
    ]),
    _createVNode(_component_q_dialog, {
      modelValue: isActiveModal.value,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((isActiveModal).value = $event)),
      persistent: ""
    }, {
      default: _withCtx(() => [
        (isActiveModalWordForm.value)
          ? (_openBlock(), _createBlock(WordFormSettings, {
              key: 0,
              poll: poll.value,
              onHideModal: hideModal
            }, null, 8, ["poll"]))
          : _createCommentVNode("", true),
        (isActiveModalSource.value)
          ? (_openBlock(), _createBlock(SourceTable, {
              key: 1,
              message: currentCountAnswer.value,
              isFull: isFullCount.value,
              type: "ag",
              onHideModal: hideModal
            }, null, 8, ["message", "isFull"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})