import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

import { ref, computed } from 'vue'
import { onClickOutside } from '@vueuse/core'


export default /*@__PURE__*/_defineComponent({
  __name: 'GroupSelectFilterArea',
  props: {
  label: {
    type: String,
    default: 'Фильтры',
  },
  rootLabel: {
    type: String,
    default: 'Выбрать все',
  },
  options: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: true,
  },
  nodeKey: {
    type: String,
    default: 'label'
  }
},
  emits: ['on-selected', 'blur'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props

const emit = __emit

const filterInput = ref('')
const inputLabel = ref(props.label)
const isDropdownOpen = ref(false)
const selectedNodes = ref([])
const filterRef = ref()

const rootLabel = computed(() => props.rootLabel)
const nodes = computed(() => {
  let options: any = props.options

  const treeNodes: any = [
    {
      label: rootLabel.value,
      children: [],
    },
  ]

  for (let i in options) {
    if (options[i].value) {
      treeNodes[0].children.push({
        label: options[i].label,
        value: options[i].value
      })
    } else {
      treeNodes[0].children.push({
        label: options[i],
      })
    }
  }
  return treeNodes
})

const onSelected = (items: any) => {
  emit('on-selected', [
    { items, label: props.label },
    filterRef.value.firstChild,
  ])

  if (items.length) {
    inputLabel.value = 'Выбрано фильтров (' + items.length + ')'
  } else {
    inputLabel.value = props.label
  }
}

const emitBlur = (e: any) => {
  emit('blur', e)
}

const showDropDown = (value: boolean) => {
  isDropdownOpen.value = value
}

onClickOutside(filterRef, () => {
  showDropDown(false)
})

const reset = () => {
  selectedNodes.value = []
  inputLabel.value = props.label
}

__expose({ reset })

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_tree = _resolveComponent("q-tree")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", {
    class: "filter-select-tree col",
    ref_key: "filterRef",
    ref: filterRef
  }, [
    _createVNode(_component_q_input, {
      modelValue: filterInput.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((filterInput).value = $event)),
      filled: "",
      label: inputLabel.value,
      "hide-bottom-space": "",
      clearable: "",
      onFocus: _cache[2] || (_cache[2] = ($event: any) => (showDropDown(true))),
      onBlur: _cache[3] || (_cache[3] = ($event: any) => (emitBlur($event)))
    }, {
      append: _withCtx(() => [
        _createVNode(_component_q_icon, {
          name: isDropdownOpen.value ? 'fas fa-caret-up' : 'fas fa-caret-down',
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (
            isDropdownOpen.value ? showDropDown(false) : showDropDown(true)
          ), ["self"]))
        }, null, 8, ["name"])
      ]),
      _: 1
    }, 8, ["modelValue", "label"]),
    (isDropdownOpen.value)
      ? (_openBlock(), _createElementBlock("div", _mergeProps({
          key: 0,
          class: "select-tree-dropdown"
        }, _ctx.$attrs), [
          (nodes.value[0].children?.length)
            ? (_openBlock(), _createBlock(_component_q_tree, {
                key: 0,
                nodes: nodes.value,
                "node-key": __props.nodeKey,
                "tick-strategy": "leaf",
                "default-expand-all": "",
                ticked: selectedNodes.value,
                "onUpdate:ticked": [
                  _cache[4] || (_cache[4] = ($event: any) => ((selectedNodes).value = $event)),
                  onSelected
                ],
                filter: filterInput.value
              }, null, 8, ["nodes", "node-key", "ticked", "filter"]))
            : (_openBlock(), _createBlock(_component_q_card, {
                key: 1,
                style: {"height":"30px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_inner_loading, {
                    showing: __props.loading,
                    color: "grey",
                    size: "16px"
                  }, null, 8, ["showing"])
                ]),
                _: 1
              }))
        ], 16))
      : _createCommentVNode("", true)
  ], 512))
}
}

})