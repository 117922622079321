// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "ag_question.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { AgQuestionService } from "./ag_question";
import type { SeparateAgQuestionResponse } from "./ag_question";
import type { SeparateAgQuestionRequest } from "./ag_question";
import type { ReduceAgQuestionResponse } from "./ag_question";
import type { ReduceAgQuestionRequest } from "./ag_question";
import type { ListAgQuestionChildsResponse } from "./ag_question";
import type { ListAgQuestionChildsRequest } from "./ag_question";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { ListAgQuestionResponse } from "./ag_question";
import type { ListAgQuestionRequest } from "./ag_question";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.AgQuestionService
 */
export interface IAgQuestionServiceClient {
    /**
     * @generated from protobuf rpc: ListAgQuestion(proto.nigmamsg.ListAgQuestionRequest) returns (proto.nigmamsg.ListAgQuestionResponse);
     */
    listAgQuestion(input: ListAgQuestionRequest, options?: RpcOptions): UnaryCall<ListAgQuestionRequest, ListAgQuestionResponse>;
    /**
     * @generated from protobuf rpc: ListAgQuestionChilds(proto.nigmamsg.ListAgQuestionChildsRequest) returns (proto.nigmamsg.ListAgQuestionChildsResponse);
     */
    listAgQuestionChilds(input: ListAgQuestionChildsRequest, options?: RpcOptions): UnaryCall<ListAgQuestionChildsRequest, ListAgQuestionChildsResponse>;
    /**
     * @generated from protobuf rpc: ReduceAgQuestion(proto.nigmamsg.ReduceAgQuestionRequest) returns (proto.nigmamsg.ReduceAgQuestionResponse);
     */
    reduceAgQuestion(input: ReduceAgQuestionRequest, options?: RpcOptions): UnaryCall<ReduceAgQuestionRequest, ReduceAgQuestionResponse>;
    /**
     * @generated from protobuf rpc: SeparateAgQuestion(proto.nigmamsg.SeparateAgQuestionRequest) returns (proto.nigmamsg.SeparateAgQuestionResponse);
     */
    separateAgQuestion(input: SeparateAgQuestionRequest, options?: RpcOptions): UnaryCall<SeparateAgQuestionRequest, SeparateAgQuestionResponse>;
}
/**
 * Сервис
 *
 * @generated from protobuf service proto.nigmamsg.AgQuestionService
 */
export class AgQuestionServiceClient implements IAgQuestionServiceClient, ServiceInfo {
    typeName = AgQuestionService.typeName;
    methods = AgQuestionService.methods;
    options = AgQuestionService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ListAgQuestion(proto.nigmamsg.ListAgQuestionRequest) returns (proto.nigmamsg.ListAgQuestionResponse);
     */
    listAgQuestion(input: ListAgQuestionRequest, options?: RpcOptions): UnaryCall<ListAgQuestionRequest, ListAgQuestionResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListAgQuestionRequest, ListAgQuestionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListAgQuestionChilds(proto.nigmamsg.ListAgQuestionChildsRequest) returns (proto.nigmamsg.ListAgQuestionChildsResponse);
     */
    listAgQuestionChilds(input: ListAgQuestionChildsRequest, options?: RpcOptions): UnaryCall<ListAgQuestionChildsRequest, ListAgQuestionChildsResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListAgQuestionChildsRequest, ListAgQuestionChildsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReduceAgQuestion(proto.nigmamsg.ReduceAgQuestionRequest) returns (proto.nigmamsg.ReduceAgQuestionResponse);
     */
    reduceAgQuestion(input: ReduceAgQuestionRequest, options?: RpcOptions): UnaryCall<ReduceAgQuestionRequest, ReduceAgQuestionResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReduceAgQuestionRequest, ReduceAgQuestionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SeparateAgQuestion(proto.nigmamsg.SeparateAgQuestionRequest) returns (proto.nigmamsg.SeparateAgQuestionResponse);
     */
    separateAgQuestion(input: SeparateAgQuestionRequest, options?: RpcOptions): UnaryCall<SeparateAgQuestionRequest, SeparateAgQuestionResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<SeparateAgQuestionRequest, SeparateAgQuestionResponse>("unary", this._transport, method, opt, input);
    }
}
