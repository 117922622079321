const onlyNumbers = (currentElement) => {
    currentElement.value = currentElement.value.replace(/[^0-9\-,]/g, '')

    if (currentElement.value > 0) {
        currentElement.value = 0
    }
}

export default {
    mounted(el) {
        const currElement = el.querySelector('input')

        currElement.addEventListener('keyup', () => onlyNumbers(currElement))
    },

    updated(el, binding) {
        const currElement = el.querySelector('input')

        binding.value = currElement.value
    },

    unmounted(el) {
        const currElement = el.querySelector('input')

        currElement.removeEventListener('keyup', () => onlyNumbers(currElement))
    }
}