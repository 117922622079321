// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "message_class.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * Модель данных
 *
 * @generated from protobuf message proto.nigmamsg.MessageClassModel
 */
export interface MessageClassModel {
    /**
     * @generated from protobuf field: int64 class_id = 1;
     */
    classId: number;
    /**
     * @generated from protobuf field: int64 message_grp_id = 2;
     */
    messageGrpId: number;
}
/**
 * Входящий запрос для создания элемента
 *
 * @generated from protobuf message proto.nigmamsg.CreateMessageClassRequest
 */
export interface CreateMessageClassRequest {
    /**
     * @generated from protobuf field: proto.nigmamsg.MessageClassModel item = 1;
     */
    item?: MessageClassModel;
}
/**
 * Ответ сервера для создания элемента
 *
 * @generated from protobuf message proto.nigmamsg.CreateMessageClassResponse
 */
export interface CreateMessageClassResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
}
/**
 * Входящий запрос для удаления элемента
 *
 * @generated from protobuf message proto.nigmamsg.DeleteMessageClassRequest
 */
export interface DeleteMessageClassRequest {
    /**
     * @generated from protobuf field: proto.nigmamsg.MessageClassModel item = 1;
     */
    item?: MessageClassModel;
}
/**
 * Ответ сервера для удаления элемента
 *
 * @generated from protobuf message proto.nigmamsg.DeleteMessageClassResponse
 */
export interface DeleteMessageClassResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
}
/**
 * Входящий запрос для сохранения элементов
 *
 * @generated from protobuf message proto.nigmamsg.SaveMessageClassRequest
 */
export interface SaveMessageClassRequest {
    /**
     * @generated from protobuf field: int64 class_id = 1;
     */
    classId: number;
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.MessageClassModel items = 2;
     */
    items: MessageClassModel[];
}
/**
 * Ответ сервера для сохранения элементов
 *
 * @generated from protobuf message proto.nigmamsg.SaveMessageClassResponse
 */
export interface SaveMessageClassResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
}
/**
 * Входящий запрос для удаления элементов
 *
 * @generated from protobuf message proto.nigmamsg.RemoveMessageClassRequest
 */
export interface RemoveMessageClassRequest {
    /**
     * @generated from protobuf field: int64 class_id = 1;
     */
    classId: number;
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.MessageClassModel items = 2;
     */
    items: MessageClassModel[];
}
/**
 * Ответ сервера для удаления элементаов
 *
 * @generated from protobuf message proto.nigmamsg.RemoveMessageClassResponse
 */
export interface RemoveMessageClassResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class MessageClassModel$Type extends MessageType<MessageClassModel> {
    constructor() {
        super("proto.nigmamsg.MessageClassModel", [
            { no: 1, name: "class_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "message_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MessageClassModel>): MessageClassModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classId = 0;
        message.messageGrpId = 0;
        if (value !== undefined)
            reflectionMergePartial<MessageClassModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MessageClassModel): MessageClassModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_id */ 1:
                    message.classId = reader.int64().toNumber();
                    break;
                case /* int64 message_grp_id */ 2:
                    message.messageGrpId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MessageClassModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_id = 1; */
        if (message.classId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classId);
        /* int64 message_grp_id = 2; */
        if (message.messageGrpId !== 0)
            writer.tag(2, WireType.Varint).int64(message.messageGrpId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.MessageClassModel
 */
export const MessageClassModel = new MessageClassModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateMessageClassRequest$Type extends MessageType<CreateMessageClassRequest> {
    constructor() {
        super("proto.nigmamsg.CreateMessageClassRequest", [
            { no: 1, name: "item", kind: "message", T: () => MessageClassModel }
        ]);
    }
    create(value?: PartialMessage<CreateMessageClassRequest>): CreateMessageClassRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateMessageClassRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateMessageClassRequest): CreateMessageClassRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.MessageClassModel item */ 1:
                    message.item = MessageClassModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateMessageClassRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.MessageClassModel item = 1; */
        if (message.item)
            MessageClassModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.CreateMessageClassRequest
 */
export const CreateMessageClassRequest = new CreateMessageClassRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateMessageClassResponse$Type extends MessageType<CreateMessageClassResponse> {
    constructor() {
        super("proto.nigmamsg.CreateMessageClassResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CreateMessageClassResponse>): CreateMessageClassResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.success = false;
        if (value !== undefined)
            reflectionMergePartial<CreateMessageClassResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateMessageClassResponse): CreateMessageClassResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateMessageClassResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.CreateMessageClassResponse
 */
export const CreateMessageClassResponse = new CreateMessageClassResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteMessageClassRequest$Type extends MessageType<DeleteMessageClassRequest> {
    constructor() {
        super("proto.nigmamsg.DeleteMessageClassRequest", [
            { no: 1, name: "item", kind: "message", T: () => MessageClassModel }
        ]);
    }
    create(value?: PartialMessage<DeleteMessageClassRequest>): DeleteMessageClassRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DeleteMessageClassRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteMessageClassRequest): DeleteMessageClassRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.MessageClassModel item */ 1:
                    message.item = MessageClassModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteMessageClassRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.MessageClassModel item = 1; */
        if (message.item)
            MessageClassModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.DeleteMessageClassRequest
 */
export const DeleteMessageClassRequest = new DeleteMessageClassRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteMessageClassResponse$Type extends MessageType<DeleteMessageClassResponse> {
    constructor() {
        super("proto.nigmamsg.DeleteMessageClassResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteMessageClassResponse>): DeleteMessageClassResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.success = false;
        if (value !== undefined)
            reflectionMergePartial<DeleteMessageClassResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteMessageClassResponse): DeleteMessageClassResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteMessageClassResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.DeleteMessageClassResponse
 */
export const DeleteMessageClassResponse = new DeleteMessageClassResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMessageClassRequest$Type extends MessageType<SaveMessageClassRequest> {
    constructor() {
        super("proto.nigmamsg.SaveMessageClassRequest", [
            { no: 1, name: "class_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MessageClassModel }
        ]);
    }
    create(value?: PartialMessage<SaveMessageClassRequest>): SaveMessageClassRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classId = 0;
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<SaveMessageClassRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMessageClassRequest): SaveMessageClassRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_id */ 1:
                    message.classId = reader.int64().toNumber();
                    break;
                case /* repeated proto.nigmamsg.MessageClassModel items */ 2:
                    message.items.push(MessageClassModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveMessageClassRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_id = 1; */
        if (message.classId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classId);
        /* repeated proto.nigmamsg.MessageClassModel items = 2; */
        for (let i = 0; i < message.items.length; i++)
            MessageClassModel.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.SaveMessageClassRequest
 */
export const SaveMessageClassRequest = new SaveMessageClassRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMessageClassResponse$Type extends MessageType<SaveMessageClassResponse> {
    constructor() {
        super("proto.nigmamsg.SaveMessageClassResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SaveMessageClassResponse>): SaveMessageClassResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.success = false;
        if (value !== undefined)
            reflectionMergePartial<SaveMessageClassResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMessageClassResponse): SaveMessageClassResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveMessageClassResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.SaveMessageClassResponse
 */
export const SaveMessageClassResponse = new SaveMessageClassResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveMessageClassRequest$Type extends MessageType<RemoveMessageClassRequest> {
    constructor() {
        super("proto.nigmamsg.RemoveMessageClassRequest", [
            { no: 1, name: "class_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MessageClassModel }
        ]);
    }
    create(value?: PartialMessage<RemoveMessageClassRequest>): RemoveMessageClassRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classId = 0;
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<RemoveMessageClassRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveMessageClassRequest): RemoveMessageClassRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_id */ 1:
                    message.classId = reader.int64().toNumber();
                    break;
                case /* repeated proto.nigmamsg.MessageClassModel items */ 2:
                    message.items.push(MessageClassModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveMessageClassRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_id = 1; */
        if (message.classId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classId);
        /* repeated proto.nigmamsg.MessageClassModel items = 2; */
        for (let i = 0; i < message.items.length; i++)
            MessageClassModel.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.RemoveMessageClassRequest
 */
export const RemoveMessageClassRequest = new RemoveMessageClassRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveMessageClassResponse$Type extends MessageType<RemoveMessageClassResponse> {
    constructor() {
        super("proto.nigmamsg.RemoveMessageClassResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<RemoveMessageClassResponse>): RemoveMessageClassResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.success = false;
        if (value !== undefined)
            reflectionMergePartial<RemoveMessageClassResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveMessageClassResponse): RemoveMessageClassResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveMessageClassResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.RemoveMessageClassResponse
 */
export const RemoveMessageClassResponse = new RemoveMessageClassResponse$Type();
/**
 * @generated ServiceType for protobuf service proto.nigmamsg.MessageClassService
 */
export const MessageClassService = new ServiceType("proto.nigmamsg.MessageClassService", [
    { name: "CreateMessageClass", options: {}, I: CreateMessageClassRequest, O: CreateMessageClassResponse },
    { name: "DeleteMessageClass", options: {}, I: DeleteMessageClassRequest, O: DeleteMessageClassResponse },
    { name: "SaveMessageClass", options: {}, I: SaveMessageClassRequest, O: SaveMessageClassResponse },
    { name: "RemoveMessageClass", options: {}, I: RemoveMessageClassRequest, O: RemoveMessageClassResponse }
]);
