import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row q-mt-xl q-mb-md justify-end" }
const _hoisted_2 = { style: {"width":"100%","position":"relative"} }
const _hoisted_3 = {
  style: {"width":"100%","position":"relative"},
  class: "q-mt-xl"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RegistrationProjects = _resolveComponent("RegistrationProjects")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_highcharts = _resolveComponent("highcharts")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_RegistrationProjects, {
        style: {"max-width":"254px"},
        class: "q-mr-md"
      }),
      _createElementVNode("div", null, [
        _createVNode(_component_q_btn, {
          color: "primary",
          style: {"height":"56px"},
          label: "Рассчитать",
          onClick: _ctx.applyRegistration,
          disable: this.selectedNews.length < 2 && this.selectedNews.length !== 0
        }, null, 8, ["onClick", "disable"]),
        (this.selectedNews.length < 2 && this.selectedNews.length !== 0)
          ? (_openBlock(), _createBlock(_component_q_tooltip, {
              key: 0,
              anchor: "top middle",
              self: "bottom middle",
              offset: [10, 10]
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("p", { class: "action-tooltip" }, "Выберите минимум 2 проекта.", -1)
              ])),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_highcharts, {
        class: "hc",
        options: _ctx.chartOptions,
        ref: "chart"
      }, null, 8, ["options"]),
      _createVNode(_component_q_inner_loading, {
        showing: _ctx.isRegistrationList,
        color: "grey"
      }, null, 8, ["showing"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_highcharts, {
        class: "hc",
        options: _ctx.chartOptions2,
        ref: "chart"
      }, null, 8, ["options"]),
      _createVNode(_component_q_inner_loading, {
        showing: _ctx.isRegistrationList,
        color: "grey"
      }, null, 8, ["showing"])
    ])
  ], 64))
}