import { RouteRecordRaw } from 'vue-router'

const routes:RouteRecordRaw[] = [
  {
    path: '/recommend-system/preparing-difficult-tasks',
    name: 'PrepDifficultTasks',
    component: () => import('../views/PrepDifficultTasks.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Рекомендательная система', to: { name: 'RecSystem' } },
        { text: 'Сервис подготовки сложных заданий' }
      ],
    },
  },
  {
    path: '/recommend-system/user-groups-scenarios',
    name: 'UserGroupScenarios',
    component: () => import('../views/UserGroupScenarios.vue'),
    meta: {
      breadCrumb: [
        { text: 'Главная', to: { name: 'HomePage' }, icon: 'home' },
        { text: 'Рекомендательная система', to: { name: 'RecSystem' } },
        { text: 'Сценарии для групп пользователей' }
      ],
    },
  },

]

export default {
    routes
}
