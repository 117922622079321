import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row q-pa-md overflow-auto full-height" }
const _hoisted_2 = { class: "col-12 q-pb-lg" }
const _hoisted_3 = { class: "row q-pb-lg" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col q-pr-md" }
const _hoisted_7 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UsersProjectsChart = _resolveComponent("UsersProjectsChart")!
  const _component_ActivityPercentageChart = _resolveComponent("ActivityPercentageChart")!
  const _component_TopActivitiesChart = _resolveComponent("TopActivitiesChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-subtitle1 q-pb-xs" }, " Социально-демографические показатели ", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_UsersProjectsChart)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_ActivityPercentageChart)
            ])
          ]),
          _createVNode(_component_TopActivitiesChart)
        ])
      ])
    ])
  ]))
}