// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "ag_answer_src.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Pagination } from "./proto/nigma-common-proto/filter";
import { Sort } from "./proto/nigma-common-proto/filter";
import { Filter } from "./proto/nigma-common-proto/filter";
/**
 * Модель данных групп классов
 *
 * @generated from protobuf message proto.nigmamsg.AgAnswerSrcClassGroupModel
 */
export interface AgAnswerSrcClassGroupModel {
    /**
     * @generated from protobuf field: int64 class_group_id = 1;
     */
    classGroupId: number;
    /**
     * @generated from protobuf field: string class_group_name = 2;
     */
    classGroupName: string;
    /**
     * @generated from protobuf field: string class_group_alias = 3;
     */
    classGroupAlias: string;
    /**
     * @generated from protobuf field: string class_group_color = 4;
     */
    classGroupColor: string;
    /**
     * @generated from protobuf field: double class_group_ratio = 5;
     */
    classGroupRatio: number;
}
/**
 * Модель данных для связи с ag_answers_grp
 *
 * @generated from protobuf message proto.nigmamsg.AgAnswerSrcLinkedModel
 */
export interface AgAnswerSrcLinkedModel {
    /**
     * @generated from protobuf field: int64 ag_answer_grp_id = 1;
     */
    agAnswerGrpId: number;
    /**
     * @generated from protobuf field: int64 ag_answer_grp_parent_id = 2;
     */
    agAnswerGrpParentId: number;
    /**
     * @generated from protobuf field: int64 message_grp_id = 3;
     */
    messageGrpId: number;
    /**
     * @generated from protobuf field: int64 message_grp_parent_id = 4;
     */
    messageGrpParentId: number;
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.AgAnswerSrcClassGroupModel class_group = 5;
     */
    classGroup: AgAnswerSrcClassGroupModel[];
}
/**
 * Модель данных
 * Префикс для фильтров asrc.
 *
 * @generated from protobuf message proto.nigmamsg.AgAnswerSrcModel
 */
export interface AgAnswerSrcModel {
    /**
     * @generated from protobuf field: int64 source_id = 1;
     */
    sourceId: number;
    /**
     * @generated from protobuf field: int64 project_id = 2;
     */
    projectId: number; // Префикс для фильтров mt.
    /**
     * @generated from protobuf field: int64 message_type_id = 3;
     */
    messageTypeId: number;
    /**
     * @generated from protobuf field: string account_guid = 4;
     */
    accountGuid: string; // Префикс для фильтров msrc.
    /**
     * @generated from protobuf field: string message_text = 5;
     */
    messageText: string; // Префикс для фильтров msrc.
    /**
     * @generated from protobuf field: string message_time = 6;
     */
    messageTime: string; // Префикс для фильтров msrc.
    /**
     * @generated from protobuf field: string message_descr = 7;
     */
    messageDescr: string; // Префикс для фильтров msrc.
    /**
     * @generated from protobuf field: string project_name = 8;
     */
    projectName: string; // Префикс для фильтров p.
    /**
     * @generated from protobuf field: string project_short_name = 9;
     */
    projectShortName: string; // Префикс для фильтров p.
    /**
     * @generated from protobuf field: string message_type_text = 10;
     */
    messageTypeText: string; // Префикс для фильтров mt.
    /**
     * @generated from protobuf field: string sso_id = 11;
     */
    ssoId: string; // Префикс для фильтров a.
    /**
     * @generated from protobuf field: int64 ag_answer_id = 12;
     */
    agAnswerId: number;
    /**
     * @generated from protobuf field: string ag_answer_title = 13;
     */
    agAnswerTitle: string; // Префикс для фильтров aa.
    /**
     * @generated from protobuf field: int64 ag_question_id = 14;
     */
    agQuestionId: number; // Префикс для фильтров aq.
    /**
     * @generated from protobuf field: string ag_question_title = 15;
     */
    agQuestionTitle: string; // Префикс для фильтров aq.
    /**
     * @generated from protobuf field: string ag_question_stitle = 16;
     */
    agQuestionStitle: string; // Префикс для фильтров aq.
    /**
     * @generated from protobuf field: int64 ag_poll_id = 17;
     */
    agPollId: number;
    /**
     * @generated from protobuf field: string ag_poll_title = 18;
     */
    agPollTitle: string; // Префикс для фильтров ap.
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.AgAnswerSrcLinkedModel ag_answer_grp = 19;
     */
    agAnswerGrp: AgAnswerSrcLinkedModel[];
}
/**
 * Входящий запрос для получения списка данных
 *
 * @generated from protobuf message proto.nigmamsg.ListAgAnswerSrcRequest
 */
export interface ListAgAnswerSrcRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
}
/**
 * Ответ сервера для получения списка данных
 *
 * @generated from protobuf message proto.nigmamsg.ListAgAnswerSrcResponse
 */
export interface ListAgAnswerSrcResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.AgAnswerSrcModel items = 1;
     */
    items: AgAnswerSrcModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListAgAnswerSrcRequest params = 2;
     */
    params?: ListAgAnswerSrcRequest;
}
/**
 * Входящий запрос для получения списка данных для модалки
 *
 * @generated from protobuf message proto.nigmamsg.ListAgAnswerSrcSourceRequest
 */
export interface ListAgAnswerSrcSourceRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
    /**
     * @generated from protobuf field: int64 ag_answer_grp_id = 4;
     */
    agAnswerGrpId: number;
    /**
     * @generated from protobuf field: int64 message_grp_id = 5;
     */
    messageGrpId: number;
    /**
     * @generated from protobuf field: bool has_full = 6;
     */
    hasFull: boolean;
}
/**
 * Ответ сервера для получения списка данных для модалки
 *
 * @generated from protobuf message proto.nigmamsg.ListAgAnswerSrcSourceResponse
 */
export interface ListAgAnswerSrcSourceResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.AgAnswerSrcModel items = 1;
     */
    items: AgAnswerSrcModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListAgAnswerSrcSourceRequest params = 2;
     */
    params?: ListAgAnswerSrcSourceRequest;
}
/**
 * Входящий запрос для получения исходных ответов по классу и диапазону времени
 *
 * @generated from protobuf message proto.nigmamsg.ListAgAnswerSrcInClassRequest
 */
export interface ListAgAnswerSrcInClassRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
    /**
     * @generated from protobuf field: string class_name = 4;
     */
    className: string;
}
/**
 * Ответ сервера для получения исходных ответов по классу и диапазону времени
 *
 * @generated from protobuf message proto.nigmamsg.ListAgAnswerSrcInClassResponse
 */
export interface ListAgAnswerSrcInClassResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.AgAnswerSrcModel items = 1;
     */
    items: AgAnswerSrcModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListAgAnswerSrcInClassRequest params = 2;
     */
    params?: ListAgAnswerSrcInClassRequest;
}
/**
 * @generated from protobuf message proto.nigmamsg.PersonalListAgAnswerSrcRequest
 */
export interface PersonalListAgAnswerSrcRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: int64 user_id = 6;
     */
    userId: number;
}
/**
 * @generated from protobuf message proto.nigmamsg.PersonalListAgAnswerSrcResponse
 */
export interface PersonalListAgAnswerSrcResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
}
/**
 * @generated from protobuf message proto.nigmamsg.PersonalListAgAnswerSrcSourceRequest
 */
export interface PersonalListAgAnswerSrcSourceRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: int64 ag_answer_grp_id = 3;
     */
    agAnswerGrpId: number;
    /**
     * @generated from protobuf field: int64 message_grp_id = 4;
     */
    messageGrpId: number;
    /**
     * @generated from protobuf field: bool has_full = 5;
     */
    hasFull: boolean;
    /**
     * @generated from protobuf field: int64 user_id = 6;
     */
    userId: number;
}
/**
 * @generated from protobuf message proto.nigmamsg.PersonalListAgAnswerSrcSourceResponse
 */
export interface PersonalListAgAnswerSrcSourceResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class AgAnswerSrcClassGroupModel$Type extends MessageType<AgAnswerSrcClassGroupModel> {
    constructor() {
        super("proto.nigmamsg.AgAnswerSrcClassGroupModel", [
            { no: 1, name: "class_group_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "class_group_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "class_group_alias", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "class_group_color", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "class_group_ratio", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<AgAnswerSrcClassGroupModel>): AgAnswerSrcClassGroupModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classGroupId = 0;
        message.classGroupName = "";
        message.classGroupAlias = "";
        message.classGroupColor = "";
        message.classGroupRatio = 0;
        if (value !== undefined)
            reflectionMergePartial<AgAnswerSrcClassGroupModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgAnswerSrcClassGroupModel): AgAnswerSrcClassGroupModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_group_id */ 1:
                    message.classGroupId = reader.int64().toNumber();
                    break;
                case /* string class_group_name */ 2:
                    message.classGroupName = reader.string();
                    break;
                case /* string class_group_alias */ 3:
                    message.classGroupAlias = reader.string();
                    break;
                case /* string class_group_color */ 4:
                    message.classGroupColor = reader.string();
                    break;
                case /* double class_group_ratio */ 5:
                    message.classGroupRatio = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgAnswerSrcClassGroupModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_group_id = 1; */
        if (message.classGroupId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classGroupId);
        /* string class_group_name = 2; */
        if (message.classGroupName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.classGroupName);
        /* string class_group_alias = 3; */
        if (message.classGroupAlias !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.classGroupAlias);
        /* string class_group_color = 4; */
        if (message.classGroupColor !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.classGroupColor);
        /* double class_group_ratio = 5; */
        if (message.classGroupRatio !== 0)
            writer.tag(5, WireType.Bit64).double(message.classGroupRatio);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.AgAnswerSrcClassGroupModel
 */
export const AgAnswerSrcClassGroupModel = new AgAnswerSrcClassGroupModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgAnswerSrcLinkedModel$Type extends MessageType<AgAnswerSrcLinkedModel> {
    constructor() {
        super("proto.nigmamsg.AgAnswerSrcLinkedModel", [
            { no: 1, name: "ag_answer_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "ag_answer_grp_parent_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "message_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "message_grp_parent_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "class_group", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgAnswerSrcClassGroupModel }
        ]);
    }
    create(value?: PartialMessage<AgAnswerSrcLinkedModel>): AgAnswerSrcLinkedModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.agAnswerGrpId = 0;
        message.agAnswerGrpParentId = 0;
        message.messageGrpId = 0;
        message.messageGrpParentId = 0;
        message.classGroup = [];
        if (value !== undefined)
            reflectionMergePartial<AgAnswerSrcLinkedModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgAnswerSrcLinkedModel): AgAnswerSrcLinkedModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ag_answer_grp_id */ 1:
                    message.agAnswerGrpId = reader.int64().toNumber();
                    break;
                case /* int64 ag_answer_grp_parent_id */ 2:
                    message.agAnswerGrpParentId = reader.int64().toNumber();
                    break;
                case /* int64 message_grp_id */ 3:
                    message.messageGrpId = reader.int64().toNumber();
                    break;
                case /* int64 message_grp_parent_id */ 4:
                    message.messageGrpParentId = reader.int64().toNumber();
                    break;
                case /* repeated proto.nigmamsg.AgAnswerSrcClassGroupModel class_group */ 5:
                    message.classGroup.push(AgAnswerSrcClassGroupModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgAnswerSrcLinkedModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ag_answer_grp_id = 1; */
        if (message.agAnswerGrpId !== 0)
            writer.tag(1, WireType.Varint).int64(message.agAnswerGrpId);
        /* int64 ag_answer_grp_parent_id = 2; */
        if (message.agAnswerGrpParentId !== 0)
            writer.tag(2, WireType.Varint).int64(message.agAnswerGrpParentId);
        /* int64 message_grp_id = 3; */
        if (message.messageGrpId !== 0)
            writer.tag(3, WireType.Varint).int64(message.messageGrpId);
        /* int64 message_grp_parent_id = 4; */
        if (message.messageGrpParentId !== 0)
            writer.tag(4, WireType.Varint).int64(message.messageGrpParentId);
        /* repeated proto.nigmamsg.AgAnswerSrcClassGroupModel class_group = 5; */
        for (let i = 0; i < message.classGroup.length; i++)
            AgAnswerSrcClassGroupModel.internalBinaryWrite(message.classGroup[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.AgAnswerSrcLinkedModel
 */
export const AgAnswerSrcLinkedModel = new AgAnswerSrcLinkedModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgAnswerSrcModel$Type extends MessageType<AgAnswerSrcModel> {
    constructor() {
        super("proto.nigmamsg.AgAnswerSrcModel", [
            { no: 1, name: "source_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "project_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "message_type_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "account_guid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "message_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "message_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "message_descr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "project_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "project_short_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "message_type_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "sso_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "ag_answer_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 13, name: "ag_answer_title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "ag_question_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 15, name: "ag_question_title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "ag_question_stitle", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "ag_poll_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 18, name: "ag_poll_title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "ag_answer_grp", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgAnswerSrcLinkedModel }
        ]);
    }
    create(value?: PartialMessage<AgAnswerSrcModel>): AgAnswerSrcModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.sourceId = 0;
        message.projectId = 0;
        message.messageTypeId = 0;
        message.accountGuid = "";
        message.messageText = "";
        message.messageTime = "";
        message.messageDescr = "";
        message.projectName = "";
        message.projectShortName = "";
        message.messageTypeText = "";
        message.ssoId = "";
        message.agAnswerId = 0;
        message.agAnswerTitle = "";
        message.agQuestionId = 0;
        message.agQuestionTitle = "";
        message.agQuestionStitle = "";
        message.agPollId = 0;
        message.agPollTitle = "";
        message.agAnswerGrp = [];
        if (value !== undefined)
            reflectionMergePartial<AgAnswerSrcModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgAnswerSrcModel): AgAnswerSrcModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 source_id */ 1:
                    message.sourceId = reader.int64().toNumber();
                    break;
                case /* int64 project_id */ 2:
                    message.projectId = reader.int64().toNumber();
                    break;
                case /* int64 message_type_id */ 3:
                    message.messageTypeId = reader.int64().toNumber();
                    break;
                case /* string account_guid */ 4:
                    message.accountGuid = reader.string();
                    break;
                case /* string message_text */ 5:
                    message.messageText = reader.string();
                    break;
                case /* string message_time */ 6:
                    message.messageTime = reader.string();
                    break;
                case /* string message_descr */ 7:
                    message.messageDescr = reader.string();
                    break;
                case /* string project_name */ 8:
                    message.projectName = reader.string();
                    break;
                case /* string project_short_name */ 9:
                    message.projectShortName = reader.string();
                    break;
                case /* string message_type_text */ 10:
                    message.messageTypeText = reader.string();
                    break;
                case /* string sso_id */ 11:
                    message.ssoId = reader.string();
                    break;
                case /* int64 ag_answer_id */ 12:
                    message.agAnswerId = reader.int64().toNumber();
                    break;
                case /* string ag_answer_title */ 13:
                    message.agAnswerTitle = reader.string();
                    break;
                case /* int64 ag_question_id */ 14:
                    message.agQuestionId = reader.int64().toNumber();
                    break;
                case /* string ag_question_title */ 15:
                    message.agQuestionTitle = reader.string();
                    break;
                case /* string ag_question_stitle */ 16:
                    message.agQuestionStitle = reader.string();
                    break;
                case /* int64 ag_poll_id */ 17:
                    message.agPollId = reader.int64().toNumber();
                    break;
                case /* string ag_poll_title */ 18:
                    message.agPollTitle = reader.string();
                    break;
                case /* repeated proto.nigmamsg.AgAnswerSrcLinkedModel ag_answer_grp */ 19:
                    message.agAnswerGrp.push(AgAnswerSrcLinkedModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgAnswerSrcModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 source_id = 1; */
        if (message.sourceId !== 0)
            writer.tag(1, WireType.Varint).int64(message.sourceId);
        /* int64 project_id = 2; */
        if (message.projectId !== 0)
            writer.tag(2, WireType.Varint).int64(message.projectId);
        /* int64 message_type_id = 3; */
        if (message.messageTypeId !== 0)
            writer.tag(3, WireType.Varint).int64(message.messageTypeId);
        /* string account_guid = 4; */
        if (message.accountGuid !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.accountGuid);
        /* string message_text = 5; */
        if (message.messageText !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.messageText);
        /* string message_time = 6; */
        if (message.messageTime !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.messageTime);
        /* string message_descr = 7; */
        if (message.messageDescr !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.messageDescr);
        /* string project_name = 8; */
        if (message.projectName !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.projectName);
        /* string project_short_name = 9; */
        if (message.projectShortName !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.projectShortName);
        /* string message_type_text = 10; */
        if (message.messageTypeText !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.messageTypeText);
        /* string sso_id = 11; */
        if (message.ssoId !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.ssoId);
        /* int64 ag_answer_id = 12; */
        if (message.agAnswerId !== 0)
            writer.tag(12, WireType.Varint).int64(message.agAnswerId);
        /* string ag_answer_title = 13; */
        if (message.agAnswerTitle !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.agAnswerTitle);
        /* int64 ag_question_id = 14; */
        if (message.agQuestionId !== 0)
            writer.tag(14, WireType.Varint).int64(message.agQuestionId);
        /* string ag_question_title = 15; */
        if (message.agQuestionTitle !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.agQuestionTitle);
        /* string ag_question_stitle = 16; */
        if (message.agQuestionStitle !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.agQuestionStitle);
        /* int64 ag_poll_id = 17; */
        if (message.agPollId !== 0)
            writer.tag(17, WireType.Varint).int64(message.agPollId);
        /* string ag_poll_title = 18; */
        if (message.agPollTitle !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.agPollTitle);
        /* repeated proto.nigmamsg.AgAnswerSrcLinkedModel ag_answer_grp = 19; */
        for (let i = 0; i < message.agAnswerGrp.length; i++)
            AgAnswerSrcLinkedModel.internalBinaryWrite(message.agAnswerGrp[i], writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.AgAnswerSrcModel
 */
export const AgAnswerSrcModel = new AgAnswerSrcModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAgAnswerSrcRequest$Type extends MessageType<ListAgAnswerSrcRequest> {
    constructor() {
        super("proto.nigmamsg.ListAgAnswerSrcRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination }
        ]);
    }
    create(value?: PartialMessage<ListAgAnswerSrcRequest>): ListAgAnswerSrcRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ListAgAnswerSrcRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAgAnswerSrcRequest): ListAgAnswerSrcRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAgAnswerSrcRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListAgAnswerSrcRequest
 */
export const ListAgAnswerSrcRequest = new ListAgAnswerSrcRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAgAnswerSrcResponse$Type extends MessageType<ListAgAnswerSrcResponse> {
    constructor() {
        super("proto.nigmamsg.ListAgAnswerSrcResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgAnswerSrcModel },
            { no: 2, name: "params", kind: "message", T: () => ListAgAnswerSrcRequest }
        ]);
    }
    create(value?: PartialMessage<ListAgAnswerSrcResponse>): ListAgAnswerSrcResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListAgAnswerSrcResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAgAnswerSrcResponse): ListAgAnswerSrcResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.AgAnswerSrcModel items */ 1:
                    message.items.push(AgAnswerSrcModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListAgAnswerSrcRequest params */ 2:
                    message.params = ListAgAnswerSrcRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAgAnswerSrcResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.AgAnswerSrcModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            AgAnswerSrcModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListAgAnswerSrcRequest params = 2; */
        if (message.params)
            ListAgAnswerSrcRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListAgAnswerSrcResponse
 */
export const ListAgAnswerSrcResponse = new ListAgAnswerSrcResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAgAnswerSrcSourceRequest$Type extends MessageType<ListAgAnswerSrcSourceRequest> {
    constructor() {
        super("proto.nigmamsg.ListAgAnswerSrcSourceRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination },
            { no: 4, name: "ag_answer_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "message_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "has_full", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ListAgAnswerSrcSourceRequest>): ListAgAnswerSrcSourceRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.agAnswerGrpId = 0;
        message.messageGrpId = 0;
        message.hasFull = false;
        if (value !== undefined)
            reflectionMergePartial<ListAgAnswerSrcSourceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAgAnswerSrcSourceRequest): ListAgAnswerSrcSourceRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                case /* int64 ag_answer_grp_id */ 4:
                    message.agAnswerGrpId = reader.int64().toNumber();
                    break;
                case /* int64 message_grp_id */ 5:
                    message.messageGrpId = reader.int64().toNumber();
                    break;
                case /* bool has_full */ 6:
                    message.hasFull = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAgAnswerSrcSourceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int64 ag_answer_grp_id = 4; */
        if (message.agAnswerGrpId !== 0)
            writer.tag(4, WireType.Varint).int64(message.agAnswerGrpId);
        /* int64 message_grp_id = 5; */
        if (message.messageGrpId !== 0)
            writer.tag(5, WireType.Varint).int64(message.messageGrpId);
        /* bool has_full = 6; */
        if (message.hasFull !== false)
            writer.tag(6, WireType.Varint).bool(message.hasFull);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListAgAnswerSrcSourceRequest
 */
export const ListAgAnswerSrcSourceRequest = new ListAgAnswerSrcSourceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAgAnswerSrcSourceResponse$Type extends MessageType<ListAgAnswerSrcSourceResponse> {
    constructor() {
        super("proto.nigmamsg.ListAgAnswerSrcSourceResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgAnswerSrcModel },
            { no: 2, name: "params", kind: "message", T: () => ListAgAnswerSrcSourceRequest }
        ]);
    }
    create(value?: PartialMessage<ListAgAnswerSrcSourceResponse>): ListAgAnswerSrcSourceResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListAgAnswerSrcSourceResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAgAnswerSrcSourceResponse): ListAgAnswerSrcSourceResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.AgAnswerSrcModel items */ 1:
                    message.items.push(AgAnswerSrcModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListAgAnswerSrcSourceRequest params */ 2:
                    message.params = ListAgAnswerSrcSourceRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAgAnswerSrcSourceResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.AgAnswerSrcModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            AgAnswerSrcModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListAgAnswerSrcSourceRequest params = 2; */
        if (message.params)
            ListAgAnswerSrcSourceRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListAgAnswerSrcSourceResponse
 */
export const ListAgAnswerSrcSourceResponse = new ListAgAnswerSrcSourceResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAgAnswerSrcInClassRequest$Type extends MessageType<ListAgAnswerSrcInClassRequest> {
    constructor() {
        super("proto.nigmamsg.ListAgAnswerSrcInClassRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination },
            { no: 4, name: "class_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListAgAnswerSrcInClassRequest>): ListAgAnswerSrcInClassRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.className = "";
        if (value !== undefined)
            reflectionMergePartial<ListAgAnswerSrcInClassRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAgAnswerSrcInClassRequest): ListAgAnswerSrcInClassRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                case /* string class_name */ 4:
                    message.className = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAgAnswerSrcInClassRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string class_name = 4; */
        if (message.className !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.className);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListAgAnswerSrcInClassRequest
 */
export const ListAgAnswerSrcInClassRequest = new ListAgAnswerSrcInClassRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAgAnswerSrcInClassResponse$Type extends MessageType<ListAgAnswerSrcInClassResponse> {
    constructor() {
        super("proto.nigmamsg.ListAgAnswerSrcInClassResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgAnswerSrcModel },
            { no: 2, name: "params", kind: "message", T: () => ListAgAnswerSrcInClassRequest }
        ]);
    }
    create(value?: PartialMessage<ListAgAnswerSrcInClassResponse>): ListAgAnswerSrcInClassResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListAgAnswerSrcInClassResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAgAnswerSrcInClassResponse): ListAgAnswerSrcInClassResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.AgAnswerSrcModel items */ 1:
                    message.items.push(AgAnswerSrcModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListAgAnswerSrcInClassRequest params */ 2:
                    message.params = ListAgAnswerSrcInClassRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAgAnswerSrcInClassResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.AgAnswerSrcModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            AgAnswerSrcModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListAgAnswerSrcInClassRequest params = 2; */
        if (message.params)
            ListAgAnswerSrcInClassRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListAgAnswerSrcInClassResponse
 */
export const ListAgAnswerSrcInClassResponse = new ListAgAnswerSrcInClassResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PersonalListAgAnswerSrcRequest$Type extends MessageType<PersonalListAgAnswerSrcRequest> {
    constructor() {
        super("proto.nigmamsg.PersonalListAgAnswerSrcRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 6, name: "user_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<PersonalListAgAnswerSrcRequest>): PersonalListAgAnswerSrcRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userId = 0;
        if (value !== undefined)
            reflectionMergePartial<PersonalListAgAnswerSrcRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PersonalListAgAnswerSrcRequest): PersonalListAgAnswerSrcRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* int64 user_id */ 6:
                    message.userId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PersonalListAgAnswerSrcRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int64 user_id = 6; */
        if (message.userId !== 0)
            writer.tag(6, WireType.Varint).int64(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.PersonalListAgAnswerSrcRequest
 */
export const PersonalListAgAnswerSrcRequest = new PersonalListAgAnswerSrcRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PersonalListAgAnswerSrcResponse$Type extends MessageType<PersonalListAgAnswerSrcResponse> {
    constructor() {
        super("proto.nigmamsg.PersonalListAgAnswerSrcResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<PersonalListAgAnswerSrcResponse>): PersonalListAgAnswerSrcResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.success = false;
        if (value !== undefined)
            reflectionMergePartial<PersonalListAgAnswerSrcResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PersonalListAgAnswerSrcResponse): PersonalListAgAnswerSrcResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PersonalListAgAnswerSrcResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.PersonalListAgAnswerSrcResponse
 */
export const PersonalListAgAnswerSrcResponse = new PersonalListAgAnswerSrcResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PersonalListAgAnswerSrcSourceRequest$Type extends MessageType<PersonalListAgAnswerSrcSourceRequest> {
    constructor() {
        super("proto.nigmamsg.PersonalListAgAnswerSrcSourceRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "ag_answer_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "message_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "has_full", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "user_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<PersonalListAgAnswerSrcSourceRequest>): PersonalListAgAnswerSrcSourceRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.agAnswerGrpId = 0;
        message.messageGrpId = 0;
        message.hasFull = false;
        message.userId = 0;
        if (value !== undefined)
            reflectionMergePartial<PersonalListAgAnswerSrcSourceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PersonalListAgAnswerSrcSourceRequest): PersonalListAgAnswerSrcSourceRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* int64 ag_answer_grp_id */ 3:
                    message.agAnswerGrpId = reader.int64().toNumber();
                    break;
                case /* int64 message_grp_id */ 4:
                    message.messageGrpId = reader.int64().toNumber();
                    break;
                case /* bool has_full */ 5:
                    message.hasFull = reader.bool();
                    break;
                case /* int64 user_id */ 6:
                    message.userId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PersonalListAgAnswerSrcSourceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int64 ag_answer_grp_id = 3; */
        if (message.agAnswerGrpId !== 0)
            writer.tag(3, WireType.Varint).int64(message.agAnswerGrpId);
        /* int64 message_grp_id = 4; */
        if (message.messageGrpId !== 0)
            writer.tag(4, WireType.Varint).int64(message.messageGrpId);
        /* bool has_full = 5; */
        if (message.hasFull !== false)
            writer.tag(5, WireType.Varint).bool(message.hasFull);
        /* int64 user_id = 6; */
        if (message.userId !== 0)
            writer.tag(6, WireType.Varint).int64(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.PersonalListAgAnswerSrcSourceRequest
 */
export const PersonalListAgAnswerSrcSourceRequest = new PersonalListAgAnswerSrcSourceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PersonalListAgAnswerSrcSourceResponse$Type extends MessageType<PersonalListAgAnswerSrcSourceResponse> {
    constructor() {
        super("proto.nigmamsg.PersonalListAgAnswerSrcSourceResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<PersonalListAgAnswerSrcSourceResponse>): PersonalListAgAnswerSrcSourceResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.success = false;
        if (value !== undefined)
            reflectionMergePartial<PersonalListAgAnswerSrcSourceResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PersonalListAgAnswerSrcSourceResponse): PersonalListAgAnswerSrcSourceResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PersonalListAgAnswerSrcSourceResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.PersonalListAgAnswerSrcSourceResponse
 */
export const PersonalListAgAnswerSrcSourceResponse = new PersonalListAgAnswerSrcSourceResponse$Type();
/**
 * @generated ServiceType for protobuf service proto.nigmamsg.AgAnswerSrcService
 */
export const AgAnswerSrcService = new ServiceType("proto.nigmamsg.AgAnswerSrcService", [
    { name: "ListAgAnswerSrc", options: {}, I: ListAgAnswerSrcRequest, O: ListAgAnswerSrcResponse },
    { name: "ListAgAnswerSrcSource", options: {}, I: ListAgAnswerSrcSourceRequest, O: ListAgAnswerSrcSourceResponse },
    { name: "ListAgAnswerSrcInClass", options: {}, I: ListAgAnswerSrcInClassRequest, O: ListAgAnswerSrcInClassResponse },
    { name: "PersonalListAgAnswerSrc", options: {}, I: PersonalListAgAnswerSrcRequest, O: PersonalListAgAnswerSrcResponse },
    { name: "PersonalListAgAnswerSrcSource", options: {}, I: PersonalListAgAnswerSrcSourceRequest, O: PersonalListAgAnswerSrcSourceResponse }
]);
