import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, isRef as _isRef, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "action-table" }
const _hoisted_2 = { class: "column" }
const _hoisted_3 = { class: "column" }
const _hoisted_4 = { class: "row q-mb-md" }
const _hoisted_5 = { class: "relative-position" }

import { computed, ref, toRaw } from "vue";
import store from "@/store";
import ExportButtonAll from "@/components/modules/accounts/components/ExportButton/ExportButtonAll.vue";
import ExportButtonUniq from "@/components/modules/accounts/components/ExportButton/ExportButtonUniq.vue";
import { useRouter } from "vue-router";
import type { QTable, QTableColumn } from "quasar";
import { usePagination } from "@/composables/usePagination";

interface ActionTableProps {
  allCheck: boolean;
  fullVal: number;
  uniqVal: number;
  dateMin: string;
  dateMax: string;
}

type TableProp = {
  action_id: string;
  all: string;
  k: number;
  unique: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'ActionTable',
  props: {
    allCheck: { type: Boolean },
    fullVal: {},
    uniqVal: {},
    dateMin: {},
    dateMax: {}
  },
  emits: ["tableAll", "tableUniq"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const router = useRouter();

const allProp = ref<TableProp>();
const uniqProp = ref<TableProp>();
const alert = ref(false);
const uniq = ref(false);
const { onTableRequest: requestAll, pagination: allPagination } = usePagination(
  {
    page: 1,
    rowsPerPage: 5,
    rowsNumber: 0,
    descending: true,
    sortBy: "",
  }
);
const { onTableRequest: requestUniq, pagination: uniqPagination } =
  usePagination({
    page: 1,
    rowsPerPage: 5,
    rowsNumber: 0,
    descending: true,
    sortBy: "",
  });
const loader = ref(false);

const actionCols: QTableColumn[] = [
  {
    name: "action",
    label: "Действие",
    field: "action_id",
    align: "left",
  },
  {
    name: "all",
    label: "Совершено",
    field: "all",
    align: "left",
  },
  {
    name: "unique",
    label: "Уникальных пользователей",
    field: "unique",
    align: "left",
  },
];

const modalCols: QTableColumn[] = [
  {
    name: "sso_id",
    label: "SSO ID",
    field: "id",
    align: "left",
  },
  {
    name: "project",
    label: "Проект",
    field: "project",
    align: "left",
  },
  {
    name: "action",
    label: "Действие",
    field: "action",
    align: "left",
  },
  {
    name: "date",
    label: "Дата",
    field: "date",
    align: "left",
  },
];

const uniqueCols = ref<QTableColumn[]>([]);

const actionRows = computed(() => {
  const rows = store.getters.getActiveTable;
  const filteredList = [];

  if (rows) {
    for (let i of rows) {
      filteredList.push({
        action_id: i.getActivTextTape() + " (" + i.getProjectShortName() + ")",
        all: i.getActivityCount().toLocaleString(),
        unique: i.getActivityUniq().toLocaleString(),
        k: i.getActivId(),
      });
    }
  }

  return filteredList;
});

const modalRows = computed(() => {
  const rows = store.getters.getActiveAccount;
  const filteredList = [];
  let counter = 0;

  if (rows) {
    for (let i of rows) {
      filteredList.push({
        id: i.getSsoId(),
        project: i.getProjectName(),
        action: i.getActivComment()
          ? `${i.getActivTextTape()}: "${i.getActivComment()}"`
          : i.getActivTextTape(),
        date: i.getActivDate().split("-").reverse().join("."),
        row_id: counter,
      });

      counter++;
    }
  }

  return filteredList;
});

const uniqueRows = computed(() => {
  const rows = store.getters.getUniqAccount;
  const filteredList = [];
  let counter = 0;

  if (rows) {
    for (let i of rows) {
      let uniqArr = JSON.parse(i.getActivYes());
      uniqArr = uniqArr[0];

      filteredList.push({
        id_uniq: i.getSsoId(),
        action_uniq: uniqArr[1],
        colos: uniqArr[0],
        row_id: counter,
      });

      counter++;
    }
  }

  return filteredList;
});

const metricValues = computed(() => {
  const metricList = store.getters.getMetricChecked;

  return toRaw(metricList);
});

const isActiveTable = computed(() => store.getters.isActiveTable);
const isActiveAccounts = computed(() => store.getters.isActiveAccount);
const isUniqueAccounts = computed(() => store.getters.isUniqAccount);

const loadModal = (idsKey: number) => {
  store
    .dispatch("getActiveAccount", {
      filter: {},
      sort: {},
      pagination: {
        page: allPagination.value.page,
        limit: allPagination.value.rowsPerPage,
        pages: 0,
        cnt: 0,
      },
      activity: metricValues.value["Активность"],
      loyalty: metricValues.value["Лояльность"],
      useful: metricValues.value["Полезность"],
      interest: metricValues.value["Заинтересованность"],
      activ_ids: [idsKey],
      all_active: props.allCheck,
      date_begin: props.dateMin,
      date_end: props.dateMax,
    })
    .then(
      ({ params }) =>
        (allPagination.value.rowsNumber = params?.getPagination()?.getCnt())
    );
};

const loadUnique = (value: TableProp) => {
  uniqueCols.value = [
    {
      name: "sso_id_uniq",
      label: "SSO ID",
      field: "id_uniq",
      align: "left",
    },
    {
      name: "action_uniq",
      label: value?.action_id || "0",
      field: "action_uniq",
      align: "left",
    },
  ];

  store
    .dispatch("getUniqAccount", {
      filter: {},
      sort: {},
      pagination: {
        page: uniqPagination.value.page,
        limit: uniqPagination.value.rowsPerPage,
        pages: 0,
        cnt: 0,
      },
      activity: metricValues.value["Активность"],
      loyalty: metricValues.value["Лояльность"],
      useful: metricValues.value["Полезность"],
      interest: metricValues.value["Заинтересованность"],
      activ_ids: [value.k] || [],
      all_active: props.allCheck,
      date_begin: props.dateMin,
      date_end: props.dateMax,
    })
    .then(({ params }) => {
      uniqPagination.value.rowsNumber = params?.getPagination()?.getCnt();
    });
};

const loadPersonalUnique = (value: TableProp) => {
  loader.value = true;

  store
    .dispatch("getPersonalUniqAccount", {
      filter: {},
      activity: metricValues.value["Активность"],
      loyalty: metricValues.value["Лояльность"],
      useful: metricValues.value["Полезность"],
      interest: metricValues.value["Заинтересованность"],
      activ_ids: [value.k],
      all_active: props.allCheck,
      date_begin: props.dateMin,
      date_end: props.dateMax,
    })
    .then(() => {
      router.push({
        name: "UserProjects",
        query: {
          data: "personal",
        },
      });

      loader.value = false;
    });
};

const onRequestAll = requestAll(() => loadModal(allProp.value?.k || 0));
const onRequestUniq = requestUniq(() =>
  loadUnique(
    uniqProp.value || {
      action_id: "0",
      all: "0",
      k: 0,
      unique: "",
    }
  )
);

return (_ctx: any,_cache: any) => {
  const _component_q_spinner = _resolveComponent("q-spinner")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_tr = _resolveComponent("q-tr")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_table, {
      class: "action-table__field",
      columns: actionCols,
      rows: actionRows.value,
      loading: isActiveTable.value,
      "row-key": "action_id"
    }, {
      loading: _withCtx(() => [
        _createVNode(_component_q_inner_loading, {
          showing: true,
          style: {"z-index":"2"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_spinner, {
              color: "grey",
              size: "3em"
            })
          ]),
          _: 1
        })
      ]),
      "body-cell-all": _withCtx((props) => [
        _createVNode(_component_q_td, {
          onClick: ($event: any) => {
            alert.value = true;
            allProp.value = props.row;
            loadModal(props.row.k);
          },
          key: "action",
          props: props,
          style: {"cursor":"pointer"}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(props.row.all) + " ", 1),
            _createVNode(_component_q_dialog, {
              modelValue: alert.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((alert).value = $event)),
              "full-width": ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(ExportButtonAll, {
                    "date-min": _ctx.dateMin,
                    "date-max": _ctx.dateMax,
                    "all-chk": _ctx.allCheck,
                    "sel-act": [allProp.value?.k],
                    "from-single": true,
                    "action-name": allProp.value?.action_id,
                    style: {"height":"56px","margin-bottom":"16px"},
                    class: "justify-end self-end"
                  }, null, 8, ["date-min", "date-max", "all-chk", "sel-act", "action-name"]),
                  _createVNode(_component_q_table, {
                    class: "modal__table",
                    columns: modalCols,
                    rows: modalRows.value,
                    loading: isActiveAccounts.value,
                    "rows-per-page-options": [7, 10, 15, 25],
                    pagination: _unref(allPagination),
                    "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => (_isRef(allPagination) ? (allPagination).value = $event : null)),
                    onRequest: _unref(onRequestAll),
                    "row-key": "row_id",
                    "wrap-cells": ""
                  }, {
                    pagination: _withCtx((scope) => [
                      _createTextVNode(_toDisplayString((
                      scope.pagination.rowsPerPage * scope.pagination.page -
                      scope.pagination.rowsPerPage +
                      1
                    ).toLocaleString()) + " - " + _toDisplayString(scope.pagination.rowsPerPage * scope.pagination.page >
                    scope.pagination.rowsNumber
                      ? scope.pagination.rowsNumber.toLocaleString()
                      : (
                          scope.pagination.rowsPerPage * scope.pagination.page
                        ).toLocaleString()) + " из " + _toDisplayString(scope.pagination.rowsNumber.toLocaleString()) + " ", 1),
                      (scope.pagesNumber > 2)
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 0,
                            icon: "first_page",
                            color: "grey-8",
                            round: "",
                            dense: "",
                            flat: "",
                            disable: scope.isFirstPage,
                            onClick: scope.firstPage,
                            class: "q-ml-md"
                          }, null, 8, ["disable", "onClick"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_q_btn, {
                        icon: "chevron_left",
                        color: "grey-8",
                        round: "",
                        dense: "",
                        flat: "",
                        disable: scope.isFirstPage,
                        onClick: scope.prevPage
                      }, null, 8, ["disable", "onClick"]),
                      _createVNode(_component_q_btn, {
                        icon: "chevron_right",
                        color: "grey-8",
                        round: "",
                        dense: "",
                        flat: "",
                        disable: scope.isLastPage,
                        onClick: scope.nextPage
                      }, null, 8, ["disable", "onClick"]),
                      (scope.pagesNumber > 2)
                        ? (_openBlock(), _createBlock(_component_q_btn, {
                            key: 1,
                            icon: "last_page",
                            color: "grey-8",
                            round: "",
                            dense: "",
                            flat: "",
                            disable: scope.isLastPage,
                            onClick: scope.lastPage
                          }, null, 8, ["disable", "onClick"]))
                        : _createCommentVNode("", true)
                    ]),
                    loading: _withCtx(() => [
                      _createVNode(_component_q_inner_loading, {
                        showing: true,
                        style: {"z-index":"2"}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_q_spinner, {
                            color: "grey",
                            size: "3em"
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["rows", "loading", "pagination", "onRequest"])
                ])
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 2
        }, 1032, ["onClick", "props"])
      ]),
      "body-cell-unique": _withCtx((props) => [
        _createVNode(_component_q_td, {
          onClick: ($event: any) => {
            uniq.value = true;
            uniqProp.value = props.row;
            loadUnique(props.row);
          },
          key: "action",
          props: props,
          style: {"cursor":"pointer"}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(props.row.unique) + " ", 1),
            _createVNode(_component_q_dialog, {
              modelValue: uniq.value,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((uniq).value = $event))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_q_btn, {
                      label: "Открыть в ЦП",
                      color: "primary",
                      style: {"height":"56px"},
                      class: "q-ml-auto q-mr-md",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (uniqProp.value ? loadPersonalUnique(uniqProp.value) : null))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_tooltip, {
                          anchor: "top middle",
                          self: "bottom middle",
                          offset: [10, 10]
                        }, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createElementVNode("p", {
                              style: {"font-size":"16px"},
                              class: "action-tooltip"
                            }, " Будут переданы первые 300 000 пользователей ", -1)
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(ExportButtonUniq, {
                      "date-min": _ctx.dateMin,
                      "date-max": _ctx.dateMax,
                      "all-chk": _ctx.allCheck,
                      "sel-act": [uniqProp.value?.k],
                      "from-single": true,
                      "action-name": uniqProp.value?.action_id,
                      style: {"height":"56px"}
                    }, null, 8, ["date-min", "date-max", "all-chk", "sel-act", "action-name"])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_q_table, {
                      class: "modal__table",
                      columns: uniqueCols.value,
                      rows: uniqueRows.value,
                      loading: isUniqueAccounts.value,
                      "rows-per-page-options": [7, 10, 15, 25],
                      pagination: _unref(uniqPagination),
                      "onUpdate:pagination": _cache[3] || (_cache[3] = ($event: any) => (_isRef(uniqPagination) ? (uniqPagination).value = $event : null)),
                      onRequest: _unref(onRequestUniq),
                      style: {"width":"100%"},
                      "row-key": "row_id",
                      "wrap-cells": ""
                    }, {
                      pagination: _withCtx((scope) => [
                        _createTextVNode(_toDisplayString((
                        scope.pagination.rowsPerPage * scope.pagination.page -
                        scope.pagination.rowsPerPage +
                        1
                      ).toLocaleString()) + " - " + _toDisplayString(scope.pagination.rowsPerPage * scope.pagination.page >
                      scope.pagination.rowsNumber
                        ? scope.pagination.rowsNumber.toLocaleString()
                        : (
                            scope.pagination.rowsPerPage * scope.pagination.page
                          ).toLocaleString()) + " из " + _toDisplayString(scope.pagination.rowsNumber.toLocaleString()) + " ", 1),
                        (scope.pagesNumber > 2)
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 0,
                              icon: "first_page",
                              color: "grey-8",
                              round: "",
                              dense: "",
                              flat: "",
                              disable: scope.isFirstPage,
                              onClick: scope.firstPage,
                              class: "q-ml-md"
                            }, null, 8, ["disable", "onClick"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_q_btn, {
                          icon: "chevron_left",
                          color: "grey-8",
                          round: "",
                          dense: "",
                          flat: "",
                          disable: scope.isFirstPage,
                          onClick: scope.prevPage
                        }, null, 8, ["disable", "onClick"]),
                        _createVNode(_component_q_btn, {
                          icon: "chevron_right",
                          color: "grey-8",
                          round: "",
                          dense: "",
                          flat: "",
                          disable: scope.isLastPage,
                          onClick: scope.nextPage
                        }, null, 8, ["disable", "onClick"]),
                        (scope.pagesNumber > 2)
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 1,
                              icon: "last_page",
                              color: "grey-8",
                              round: "",
                              dense: "",
                              flat: "",
                              disable: scope.isLastPage,
                              onClick: scope.lastPage
                            }, null, 8, ["disable", "onClick"]))
                          : _createCommentVNode("", true)
                      ]),
                      loading: _withCtx(() => [
                        _createVNode(_component_q_inner_loading, {
                          showing: true,
                          style: {"z-index":"2"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_spinner, {
                              color: "grey",
                              size: "3em"
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["columns", "rows", "loading", "pagination", "onRequest"]),
                    _createVNode(_component_q_inner_loading, {
                      showing: loader.value,
                      color: "grey",
                      label: `Подождите, страница скоро откроется`,
                      style: {"z-index":"100"}
                    }, null, 8, ["showing"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 2
        }, 1032, ["onClick", "props"])
      ]),
      "bottom-row": _withCtx(() => [
        _createVNode(_component_q_tr, null, {
          default: _withCtx(() => [
            _createVNode(_component_q_td, null, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" Всего ")
              ])),
              _: 1
            }),
            _createVNode(_component_q_td, {
              onClick: _cache[5] || (_cache[5] = ($event: any) => (emit('tableAll'))),
              style: {"cursor":"pointer"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.fullVal.toLocaleString()), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_q_td, {
              onClick: _cache[6] || (_cache[6] = ($event: any) => (emit('tableUniq'))),
              style: {"cursor":"pointer"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.uniqVal.toLocaleString()), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["rows", "loading"])
  ]))
}
}

})