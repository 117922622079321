import script from "./UsersList.vue?vue&type=script&setup=true&lang=ts"
export * from "./UsersList.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QCard from 'quasar/src/components/card/QCard.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QTable,QBtn,QSpace,QInput,QIcon,QTooltip,QTd,QInnerLoading,QMenu,QList,QItem,QItemSection,QDialog});
