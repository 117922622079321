// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "ag_answer_grp.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Pagination } from "./proto/nigma-common-proto/filter";
import { Sort } from "./proto/nigma-common-proto/filter";
import { Filter } from "./proto/nigma-common-proto/filter";
/**
 * Модель данных
 * Префикс для фильтров ag.
 *
 * @generated from protobuf message proto.nigmamsg.AgAnswerGrpModel
 */
export interface AgAnswerGrpModel {
    /**
     * @generated from protobuf field: int64 ag_answer_grp_id = 1;
     */
    agAnswerGrpId: number;
    /**
     * @generated from protobuf field: int64 ag_answer_grp_parent_id = 2;
     */
    agAnswerGrpParentId: number;
    /**
     * @generated from protobuf field: int64 ag_answer_id = 3;
     */
    agAnswerId: number;
    /**
     * @generated from protobuf field: int64 ag_question_id = 4;
     */
    agQuestionId: number;
    /**
     * @generated from protobuf field: int64 ag_poll_id = 5;
     */
    agPollId: number;
    /**
     * @generated from protobuf field: int64 message_grp_id = 6;
     */
    messageGrpId: number;
    /**
     * @generated from protobuf field: int64 message_grp_parent_id = 7;
     */
    messageGrpParentId: number; // Префикс для фильтров mg.
    /**
     * @generated from protobuf field: string message_grp_text = 8;
     */
    messageGrpText: string;
    /**
     * @generated from protobuf field: int64 message_grp_count = 9;
     */
    messageGrpCount: number;
    /**
     * @generated from protobuf field: bool message_grp_processed = 10;
     */
    messageGrpProcessed: boolean;
    /**
     * @generated from protobuf field: int64 message_grp_sum = 11;
     */
    messageGrpSum: number;
    /**
     * @generated from protobuf field: double similarity = 12;
     */
    similarity: number; // Только сортировка, без префикса
    /**
     * @generated from protobuf field: bool has_childs = 13;
     */
    hasChilds: boolean;
}
/**
 * Модель данных для запросов
 *
 * @generated from protobuf message proto.nigmamsg.AgAnswerGrpIdsModel
 */
export interface AgAnswerGrpIdsModel {
    /**
     * @generated from protobuf field: int64 ag_answer_grp_id = 1;
     */
    agAnswerGrpId: number;
    /**
     * @generated from protobuf field: int64 ag_answer_id = 2;
     */
    agAnswerId: number;
    /**
     * @generated from protobuf field: int64 ag_poll_id = 3;
     */
    agPollId: number;
    /**
     * @generated from protobuf field: int64 message_grp_id = 4;
     */
    messageGrpId: number;
}
/**
 * Входящий запрос для получения корневых элементов (answers_grp_id === answers_grp_parent_id)
 *
 * @generated from protobuf message proto.nigmamsg.ListAgAnswerGrpRequest
 */
export interface ListAgAnswerGrpRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
    /**
     * @generated from protobuf field: int64 ag_question_id = 4;
     */
    agQuestionId: number;
    /**
     * @generated from protobuf field: int64 ag_poll_id = 5;
     */
    agPollId: number;
    /**
     * @generated from protobuf field: bool is_worked = 6;
     */
    isWorked: boolean;
}
/**
 * Ответ сервера для получения корневых элементов
 *
 * @generated from protobuf message proto.nigmamsg.ListAgAnswerGrpResponse
 */
export interface ListAgAnswerGrpResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.AgAnswerGrpModel items = 1;
     */
    items: AgAnswerGrpModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListAgAnswerGrpRequest params = 2;
     */
    params?: ListAgAnswerGrpRequest;
}
/**
 * Входящий запрос для получения корневых элементов (answers_grp_id === answers_grp_parent_id)
 * с поиском страницы для заданного ответа
 *
 * @generated from protobuf message proto.nigmamsg.ListAgAnswerGrpWithFindAnswerRequest
 */
export interface ListAgAnswerGrpWithFindAnswerRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
    /**
     * @generated from protobuf field: int64 ag_question_id = 4;
     */
    agQuestionId: number;
    /**
     * @generated from protobuf field: int64 ag_poll_id = 5;
     */
    agPollId: number;
    /**
     * @generated from protobuf field: proto.nigmamsg.AgAnswerGrpIdsModel item = 6;
     */
    item?: AgAnswerGrpIdsModel;
}
/**
 * Ответ сервера для получения корневых элементов
 *
 * @generated from protobuf message proto.nigmamsg.ListAgAnswerGrpWithFindAnswerResponse
 */
export interface ListAgAnswerGrpWithFindAnswerResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.AgAnswerGrpModel items = 1;
     */
    items: AgAnswerGrpModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListAgAnswerGrpWithFindAnswerRequest params = 2;
     */
    params?: ListAgAnswerGrpWithFindAnswerRequest;
}
/**
 * Входящий запрос для получения дочерних(только листовых) элементов
 *
 * @generated from protobuf message proto.nigmamsg.ListAgAnswerGrpChildsRequest
 */
export interface ListAgAnswerGrpChildsRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
    /**
     * @generated from protobuf field: int64 ag_question_id = 4;
     */
    agQuestionId: number;
    /**
     * @generated from protobuf field: int64 ag_poll_id = 5;
     */
    agPollId: number;
    /**
     * @generated from protobuf field: proto.nigmamsg.AgAnswerGrpIdsModel item = 6;
     */
    item?: AgAnswerGrpIdsModel;
}
/**
 * Ответ сервера для получения дочерних элементов
 *
 * @generated from protobuf message proto.nigmamsg.ListAgAnswerGrpChildsResponse
 */
export interface ListAgAnswerGrpChildsResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.AgAnswerGrpModel items = 1;
     */
    items: AgAnswerGrpModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListAgAnswerGrpChildsRequest params = 2;
     */
    params?: ListAgAnswerGrpChildsRequest;
}
/**
 * Входящий запрос для сведения ответов к одному (reduce)
 *
 * @generated from protobuf message proto.nigmamsg.ReduceAgAnswerGrpRequest
 */
export interface ReduceAgAnswerGrpRequest {
    /**
     * @generated from protobuf field: int64 ag_question_id = 1;
     */
    agQuestionId: number;
    /**
     * @generated from protobuf field: int64 ag_poll_id = 2;
     */
    agPollId: number;
    /**
     * @generated from protobuf field: proto.nigmamsg.AgAnswerGrpIdsModel item = 3;
     */
    item?: AgAnswerGrpIdsModel;
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.AgAnswerGrpIdsModel reduced_ids = 4;
     */
    reducedIds: AgAnswerGrpIdsModel[];
}
/**
 * Ответ сервера после сведения ответов к одному (reduce)
 *
 * @generated from protobuf message proto.nigmamsg.ReduceAgAnswerGrpResponse
 */
export interface ReduceAgAnswerGrpResponse {
    /**
     * @generated from protobuf field: proto.nigmamsg.AgAnswerGrpIdsModel item = 1;
     */
    item?: AgAnswerGrpIdsModel;
}
/**
 * Входящий запрос для разобщения ответов (separate)
 *
 * @generated from protobuf message proto.nigmamsg.SeparateAgAnswerGrpRequest
 */
export interface SeparateAgAnswerGrpRequest {
    /**
     * @generated from protobuf field: repeated int64 separated_ids = 1;
     */
    separatedIds: number[]; // Массив из ag_answer_grp_id
}
/**
 * Ответ сервера после разобщения ответов (separate)
 *
 * @generated from protobuf message proto.nigmamsg.SeparateAgAnswerGrpResponse
 */
export interface SeparateAgAnswerGrpResponse {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class AgAnswerGrpModel$Type extends MessageType<AgAnswerGrpModel> {
    constructor() {
        super("proto.nigmamsg.AgAnswerGrpModel", [
            { no: 1, name: "ag_answer_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "ag_answer_grp_parent_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "ag_answer_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "ag_question_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "ag_poll_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "message_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "message_grp_parent_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "message_grp_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "message_grp_count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 10, name: "message_grp_processed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "message_grp_sum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "similarity", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 13, name: "has_childs", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<AgAnswerGrpModel>): AgAnswerGrpModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.agAnswerGrpId = 0;
        message.agAnswerGrpParentId = 0;
        message.agAnswerId = 0;
        message.agQuestionId = 0;
        message.agPollId = 0;
        message.messageGrpId = 0;
        message.messageGrpParentId = 0;
        message.messageGrpText = "";
        message.messageGrpCount = 0;
        message.messageGrpProcessed = false;
        message.messageGrpSum = 0;
        message.similarity = 0;
        message.hasChilds = false;
        if (value !== undefined)
            reflectionMergePartial<AgAnswerGrpModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgAnswerGrpModel): AgAnswerGrpModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ag_answer_grp_id */ 1:
                    message.agAnswerGrpId = reader.int64().toNumber();
                    break;
                case /* int64 ag_answer_grp_parent_id */ 2:
                    message.agAnswerGrpParentId = reader.int64().toNumber();
                    break;
                case /* int64 ag_answer_id */ 3:
                    message.agAnswerId = reader.int64().toNumber();
                    break;
                case /* int64 ag_question_id */ 4:
                    message.agQuestionId = reader.int64().toNumber();
                    break;
                case /* int64 ag_poll_id */ 5:
                    message.agPollId = reader.int64().toNumber();
                    break;
                case /* int64 message_grp_id */ 6:
                    message.messageGrpId = reader.int64().toNumber();
                    break;
                case /* int64 message_grp_parent_id */ 7:
                    message.messageGrpParentId = reader.int64().toNumber();
                    break;
                case /* string message_grp_text */ 8:
                    message.messageGrpText = reader.string();
                    break;
                case /* int64 message_grp_count */ 9:
                    message.messageGrpCount = reader.int64().toNumber();
                    break;
                case /* bool message_grp_processed */ 10:
                    message.messageGrpProcessed = reader.bool();
                    break;
                case /* int64 message_grp_sum */ 11:
                    message.messageGrpSum = reader.int64().toNumber();
                    break;
                case /* double similarity */ 12:
                    message.similarity = reader.double();
                    break;
                case /* bool has_childs */ 13:
                    message.hasChilds = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgAnswerGrpModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ag_answer_grp_id = 1; */
        if (message.agAnswerGrpId !== 0)
            writer.tag(1, WireType.Varint).int64(message.agAnswerGrpId);
        /* int64 ag_answer_grp_parent_id = 2; */
        if (message.agAnswerGrpParentId !== 0)
            writer.tag(2, WireType.Varint).int64(message.agAnswerGrpParentId);
        /* int64 ag_answer_id = 3; */
        if (message.agAnswerId !== 0)
            writer.tag(3, WireType.Varint).int64(message.agAnswerId);
        /* int64 ag_question_id = 4; */
        if (message.agQuestionId !== 0)
            writer.tag(4, WireType.Varint).int64(message.agQuestionId);
        /* int64 ag_poll_id = 5; */
        if (message.agPollId !== 0)
            writer.tag(5, WireType.Varint).int64(message.agPollId);
        /* int64 message_grp_id = 6; */
        if (message.messageGrpId !== 0)
            writer.tag(6, WireType.Varint).int64(message.messageGrpId);
        /* int64 message_grp_parent_id = 7; */
        if (message.messageGrpParentId !== 0)
            writer.tag(7, WireType.Varint).int64(message.messageGrpParentId);
        /* string message_grp_text = 8; */
        if (message.messageGrpText !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.messageGrpText);
        /* int64 message_grp_count = 9; */
        if (message.messageGrpCount !== 0)
            writer.tag(9, WireType.Varint).int64(message.messageGrpCount);
        /* bool message_grp_processed = 10; */
        if (message.messageGrpProcessed !== false)
            writer.tag(10, WireType.Varint).bool(message.messageGrpProcessed);
        /* int64 message_grp_sum = 11; */
        if (message.messageGrpSum !== 0)
            writer.tag(11, WireType.Varint).int64(message.messageGrpSum);
        /* double similarity = 12; */
        if (message.similarity !== 0)
            writer.tag(12, WireType.Bit64).double(message.similarity);
        /* bool has_childs = 13; */
        if (message.hasChilds !== false)
            writer.tag(13, WireType.Varint).bool(message.hasChilds);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.AgAnswerGrpModel
 */
export const AgAnswerGrpModel = new AgAnswerGrpModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AgAnswerGrpIdsModel$Type extends MessageType<AgAnswerGrpIdsModel> {
    constructor() {
        super("proto.nigmamsg.AgAnswerGrpIdsModel", [
            { no: 1, name: "ag_answer_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "ag_answer_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "ag_poll_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "message_grp_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<AgAnswerGrpIdsModel>): AgAnswerGrpIdsModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.agAnswerGrpId = 0;
        message.agAnswerId = 0;
        message.agPollId = 0;
        message.messageGrpId = 0;
        if (value !== undefined)
            reflectionMergePartial<AgAnswerGrpIdsModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AgAnswerGrpIdsModel): AgAnswerGrpIdsModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ag_answer_grp_id */ 1:
                    message.agAnswerGrpId = reader.int64().toNumber();
                    break;
                case /* int64 ag_answer_id */ 2:
                    message.agAnswerId = reader.int64().toNumber();
                    break;
                case /* int64 ag_poll_id */ 3:
                    message.agPollId = reader.int64().toNumber();
                    break;
                case /* int64 message_grp_id */ 4:
                    message.messageGrpId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AgAnswerGrpIdsModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ag_answer_grp_id = 1; */
        if (message.agAnswerGrpId !== 0)
            writer.tag(1, WireType.Varint).int64(message.agAnswerGrpId);
        /* int64 ag_answer_id = 2; */
        if (message.agAnswerId !== 0)
            writer.tag(2, WireType.Varint).int64(message.agAnswerId);
        /* int64 ag_poll_id = 3; */
        if (message.agPollId !== 0)
            writer.tag(3, WireType.Varint).int64(message.agPollId);
        /* int64 message_grp_id = 4; */
        if (message.messageGrpId !== 0)
            writer.tag(4, WireType.Varint).int64(message.messageGrpId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.AgAnswerGrpIdsModel
 */
export const AgAnswerGrpIdsModel = new AgAnswerGrpIdsModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAgAnswerGrpRequest$Type extends MessageType<ListAgAnswerGrpRequest> {
    constructor() {
        super("proto.nigmamsg.ListAgAnswerGrpRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination },
            { no: 4, name: "ag_question_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "ag_poll_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "is_worked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ListAgAnswerGrpRequest>): ListAgAnswerGrpRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.agQuestionId = 0;
        message.agPollId = 0;
        message.isWorked = false;
        if (value !== undefined)
            reflectionMergePartial<ListAgAnswerGrpRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAgAnswerGrpRequest): ListAgAnswerGrpRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                case /* int64 ag_question_id */ 4:
                    message.agQuestionId = reader.int64().toNumber();
                    break;
                case /* int64 ag_poll_id */ 5:
                    message.agPollId = reader.int64().toNumber();
                    break;
                case /* bool is_worked */ 6:
                    message.isWorked = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAgAnswerGrpRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int64 ag_question_id = 4; */
        if (message.agQuestionId !== 0)
            writer.tag(4, WireType.Varint).int64(message.agQuestionId);
        /* int64 ag_poll_id = 5; */
        if (message.agPollId !== 0)
            writer.tag(5, WireType.Varint).int64(message.agPollId);
        /* bool is_worked = 6; */
        if (message.isWorked !== false)
            writer.tag(6, WireType.Varint).bool(message.isWorked);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListAgAnswerGrpRequest
 */
export const ListAgAnswerGrpRequest = new ListAgAnswerGrpRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAgAnswerGrpResponse$Type extends MessageType<ListAgAnswerGrpResponse> {
    constructor() {
        super("proto.nigmamsg.ListAgAnswerGrpResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgAnswerGrpModel },
            { no: 2, name: "params", kind: "message", T: () => ListAgAnswerGrpRequest }
        ]);
    }
    create(value?: PartialMessage<ListAgAnswerGrpResponse>): ListAgAnswerGrpResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListAgAnswerGrpResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAgAnswerGrpResponse): ListAgAnswerGrpResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.AgAnswerGrpModel items */ 1:
                    message.items.push(AgAnswerGrpModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListAgAnswerGrpRequest params */ 2:
                    message.params = ListAgAnswerGrpRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAgAnswerGrpResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.AgAnswerGrpModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            AgAnswerGrpModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListAgAnswerGrpRequest params = 2; */
        if (message.params)
            ListAgAnswerGrpRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListAgAnswerGrpResponse
 */
export const ListAgAnswerGrpResponse = new ListAgAnswerGrpResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAgAnswerGrpWithFindAnswerRequest$Type extends MessageType<ListAgAnswerGrpWithFindAnswerRequest> {
    constructor() {
        super("proto.nigmamsg.ListAgAnswerGrpWithFindAnswerRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination },
            { no: 4, name: "ag_question_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "ag_poll_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "item", kind: "message", T: () => AgAnswerGrpIdsModel }
        ]);
    }
    create(value?: PartialMessage<ListAgAnswerGrpWithFindAnswerRequest>): ListAgAnswerGrpWithFindAnswerRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.agQuestionId = 0;
        message.agPollId = 0;
        if (value !== undefined)
            reflectionMergePartial<ListAgAnswerGrpWithFindAnswerRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAgAnswerGrpWithFindAnswerRequest): ListAgAnswerGrpWithFindAnswerRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                case /* int64 ag_question_id */ 4:
                    message.agQuestionId = reader.int64().toNumber();
                    break;
                case /* int64 ag_poll_id */ 5:
                    message.agPollId = reader.int64().toNumber();
                    break;
                case /* proto.nigmamsg.AgAnswerGrpIdsModel item */ 6:
                    message.item = AgAnswerGrpIdsModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAgAnswerGrpWithFindAnswerRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int64 ag_question_id = 4; */
        if (message.agQuestionId !== 0)
            writer.tag(4, WireType.Varint).int64(message.agQuestionId);
        /* int64 ag_poll_id = 5; */
        if (message.agPollId !== 0)
            writer.tag(5, WireType.Varint).int64(message.agPollId);
        /* proto.nigmamsg.AgAnswerGrpIdsModel item = 6; */
        if (message.item)
            AgAnswerGrpIdsModel.internalBinaryWrite(message.item, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListAgAnswerGrpWithFindAnswerRequest
 */
export const ListAgAnswerGrpWithFindAnswerRequest = new ListAgAnswerGrpWithFindAnswerRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAgAnswerGrpWithFindAnswerResponse$Type extends MessageType<ListAgAnswerGrpWithFindAnswerResponse> {
    constructor() {
        super("proto.nigmamsg.ListAgAnswerGrpWithFindAnswerResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgAnswerGrpModel },
            { no: 2, name: "params", kind: "message", T: () => ListAgAnswerGrpWithFindAnswerRequest }
        ]);
    }
    create(value?: PartialMessage<ListAgAnswerGrpWithFindAnswerResponse>): ListAgAnswerGrpWithFindAnswerResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListAgAnswerGrpWithFindAnswerResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAgAnswerGrpWithFindAnswerResponse): ListAgAnswerGrpWithFindAnswerResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.AgAnswerGrpModel items */ 1:
                    message.items.push(AgAnswerGrpModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListAgAnswerGrpWithFindAnswerRequest params */ 2:
                    message.params = ListAgAnswerGrpWithFindAnswerRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAgAnswerGrpWithFindAnswerResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.AgAnswerGrpModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            AgAnswerGrpModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListAgAnswerGrpWithFindAnswerRequest params = 2; */
        if (message.params)
            ListAgAnswerGrpWithFindAnswerRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListAgAnswerGrpWithFindAnswerResponse
 */
export const ListAgAnswerGrpWithFindAnswerResponse = new ListAgAnswerGrpWithFindAnswerResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAgAnswerGrpChildsRequest$Type extends MessageType<ListAgAnswerGrpChildsRequest> {
    constructor() {
        super("proto.nigmamsg.ListAgAnswerGrpChildsRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination },
            { no: 4, name: "ag_question_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "ag_poll_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "item", kind: "message", T: () => AgAnswerGrpIdsModel }
        ]);
    }
    create(value?: PartialMessage<ListAgAnswerGrpChildsRequest>): ListAgAnswerGrpChildsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.agQuestionId = 0;
        message.agPollId = 0;
        if (value !== undefined)
            reflectionMergePartial<ListAgAnswerGrpChildsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAgAnswerGrpChildsRequest): ListAgAnswerGrpChildsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                case /* int64 ag_question_id */ 4:
                    message.agQuestionId = reader.int64().toNumber();
                    break;
                case /* int64 ag_poll_id */ 5:
                    message.agPollId = reader.int64().toNumber();
                    break;
                case /* proto.nigmamsg.AgAnswerGrpIdsModel item */ 6:
                    message.item = AgAnswerGrpIdsModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAgAnswerGrpChildsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int64 ag_question_id = 4; */
        if (message.agQuestionId !== 0)
            writer.tag(4, WireType.Varint).int64(message.agQuestionId);
        /* int64 ag_poll_id = 5; */
        if (message.agPollId !== 0)
            writer.tag(5, WireType.Varint).int64(message.agPollId);
        /* proto.nigmamsg.AgAnswerGrpIdsModel item = 6; */
        if (message.item)
            AgAnswerGrpIdsModel.internalBinaryWrite(message.item, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListAgAnswerGrpChildsRequest
 */
export const ListAgAnswerGrpChildsRequest = new ListAgAnswerGrpChildsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAgAnswerGrpChildsResponse$Type extends MessageType<ListAgAnswerGrpChildsResponse> {
    constructor() {
        super("proto.nigmamsg.ListAgAnswerGrpChildsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgAnswerGrpModel },
            { no: 2, name: "params", kind: "message", T: () => ListAgAnswerGrpChildsRequest }
        ]);
    }
    create(value?: PartialMessage<ListAgAnswerGrpChildsResponse>): ListAgAnswerGrpChildsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListAgAnswerGrpChildsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAgAnswerGrpChildsResponse): ListAgAnswerGrpChildsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.AgAnswerGrpModel items */ 1:
                    message.items.push(AgAnswerGrpModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListAgAnswerGrpChildsRequest params */ 2:
                    message.params = ListAgAnswerGrpChildsRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAgAnswerGrpChildsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.AgAnswerGrpModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            AgAnswerGrpModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListAgAnswerGrpChildsRequest params = 2; */
        if (message.params)
            ListAgAnswerGrpChildsRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListAgAnswerGrpChildsResponse
 */
export const ListAgAnswerGrpChildsResponse = new ListAgAnswerGrpChildsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReduceAgAnswerGrpRequest$Type extends MessageType<ReduceAgAnswerGrpRequest> {
    constructor() {
        super("proto.nigmamsg.ReduceAgAnswerGrpRequest", [
            { no: 1, name: "ag_question_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "ag_poll_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "item", kind: "message", T: () => AgAnswerGrpIdsModel },
            { no: 4, name: "reduced_ids", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AgAnswerGrpIdsModel }
        ]);
    }
    create(value?: PartialMessage<ReduceAgAnswerGrpRequest>): ReduceAgAnswerGrpRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.agQuestionId = 0;
        message.agPollId = 0;
        message.reducedIds = [];
        if (value !== undefined)
            reflectionMergePartial<ReduceAgAnswerGrpRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReduceAgAnswerGrpRequest): ReduceAgAnswerGrpRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ag_question_id */ 1:
                    message.agQuestionId = reader.int64().toNumber();
                    break;
                case /* int64 ag_poll_id */ 2:
                    message.agPollId = reader.int64().toNumber();
                    break;
                case /* proto.nigmamsg.AgAnswerGrpIdsModel item */ 3:
                    message.item = AgAnswerGrpIdsModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                case /* repeated proto.nigmamsg.AgAnswerGrpIdsModel reduced_ids */ 4:
                    message.reducedIds.push(AgAnswerGrpIdsModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReduceAgAnswerGrpRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ag_question_id = 1; */
        if (message.agQuestionId !== 0)
            writer.tag(1, WireType.Varint).int64(message.agQuestionId);
        /* int64 ag_poll_id = 2; */
        if (message.agPollId !== 0)
            writer.tag(2, WireType.Varint).int64(message.agPollId);
        /* proto.nigmamsg.AgAnswerGrpIdsModel item = 3; */
        if (message.item)
            AgAnswerGrpIdsModel.internalBinaryWrite(message.item, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated proto.nigmamsg.AgAnswerGrpIdsModel reduced_ids = 4; */
        for (let i = 0; i < message.reducedIds.length; i++)
            AgAnswerGrpIdsModel.internalBinaryWrite(message.reducedIds[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ReduceAgAnswerGrpRequest
 */
export const ReduceAgAnswerGrpRequest = new ReduceAgAnswerGrpRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReduceAgAnswerGrpResponse$Type extends MessageType<ReduceAgAnswerGrpResponse> {
    constructor() {
        super("proto.nigmamsg.ReduceAgAnswerGrpResponse", [
            { no: 1, name: "item", kind: "message", T: () => AgAnswerGrpIdsModel }
        ]);
    }
    create(value?: PartialMessage<ReduceAgAnswerGrpResponse>): ReduceAgAnswerGrpResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ReduceAgAnswerGrpResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReduceAgAnswerGrpResponse): ReduceAgAnswerGrpResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.AgAnswerGrpIdsModel item */ 1:
                    message.item = AgAnswerGrpIdsModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReduceAgAnswerGrpResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.AgAnswerGrpIdsModel item = 1; */
        if (message.item)
            AgAnswerGrpIdsModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ReduceAgAnswerGrpResponse
 */
export const ReduceAgAnswerGrpResponse = new ReduceAgAnswerGrpResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SeparateAgAnswerGrpRequest$Type extends MessageType<SeparateAgAnswerGrpRequest> {
    constructor() {
        super("proto.nigmamsg.SeparateAgAnswerGrpRequest", [
            { no: 1, name: "separated_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SeparateAgAnswerGrpRequest>): SeparateAgAnswerGrpRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.separatedIds = [];
        if (value !== undefined)
            reflectionMergePartial<SeparateAgAnswerGrpRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SeparateAgAnswerGrpRequest): SeparateAgAnswerGrpRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 separated_ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.separatedIds.push(reader.int64().toNumber());
                    else
                        message.separatedIds.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SeparateAgAnswerGrpRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 separated_ids = 1; */
        if (message.separatedIds.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.separatedIds.length; i++)
                writer.int64(message.separatedIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.SeparateAgAnswerGrpRequest
 */
export const SeparateAgAnswerGrpRequest = new SeparateAgAnswerGrpRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SeparateAgAnswerGrpResponse$Type extends MessageType<SeparateAgAnswerGrpResponse> {
    constructor() {
        super("proto.nigmamsg.SeparateAgAnswerGrpResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SeparateAgAnswerGrpResponse>): SeparateAgAnswerGrpResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.success = false;
        if (value !== undefined)
            reflectionMergePartial<SeparateAgAnswerGrpResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SeparateAgAnswerGrpResponse): SeparateAgAnswerGrpResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SeparateAgAnswerGrpResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.SeparateAgAnswerGrpResponse
 */
export const SeparateAgAnswerGrpResponse = new SeparateAgAnswerGrpResponse$Type();
/**
 * @generated ServiceType for protobuf service proto.nigmamsg.AgAnswerGrpService
 */
export const AgAnswerGrpService = new ServiceType("proto.nigmamsg.AgAnswerGrpService", [
    { name: "ListAgAnswerGrp", options: {}, I: ListAgAnswerGrpRequest, O: ListAgAnswerGrpResponse },
    { name: "ListAgAnswerGrpWithFindAnswer", options: {}, I: ListAgAnswerGrpWithFindAnswerRequest, O: ListAgAnswerGrpWithFindAnswerResponse },
    { name: "ListAgAnswerGrpChilds", options: {}, I: ListAgAnswerGrpChildsRequest, O: ListAgAnswerGrpChildsResponse },
    { name: "ReduceAgAnswerGrp", options: {}, I: ReduceAgAnswerGrpRequest, O: ReduceAgAnswerGrpResponse },
    { name: "SeparateAgAnswerGrp", options: {}, I: SeparateAgAnswerGrpRequest, O: SeparateAgAnswerGrpResponse }
]);
