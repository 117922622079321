import {useGrpcClientWithLogger} from "@/components/modules/rec-system/services/UserGroups/middleware";
import {AgAnswerClassServiceClient} from "proto/nigma-message-proto/polls/ag_answer_class.client";
import {
    CreateAgAnswerClassRequest,
    DeleteAgAnswerClassRequest,
    SaveAgAnswerClassRequest,
    RemoveAgAnswerClassRequest,
} from "proto/nigma-message-proto/polls/ag_answer_class"

export const useAgAnswerClassApi = () => {
    const client = useGrpcClientWithLogger().create(AgAnswerClassServiceClient, {baseUrl: window.VUE_APP_GATEWAY_URL})

    const metadata = () => {
        return {
            Authorization: localStorage.getItem('access-key') || ''
        }
    }

    return {
        createAgAnswerClassRequest: (request: CreateAgAnswerClassRequest) => {
            return client.createAgAnswerClass(request, {meta: metadata()}).response
        },
        deleteAgAnswerClassRequest: (request: DeleteAgAnswerClassRequest) => {
            return client.deleteAgAnswerClass(request, {meta: metadata()}).response
        },
        saveAgAnswerClassRequest: (request: SaveAgAnswerClassRequest) => {
            return client.saveAgAnswerClass(request, {meta: metadata()}).response
        },
        removeAgAnswerClassRequest: (request: RemoveAgAnswerClassRequest) => {
            return client.removeAgAnswerClass(request, {meta: metadata()}).response
        },
    }
}