import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pa-md row wrap justify-center items-center content-center" }
const _hoisted_2 = {
  id: "errMessage",
  class: "text-negative"
}
const _hoisted_3 = { class: "full-width row wrap justify-center items-center content-center" }

import {useRoute, useRouter} from "vue-router";
import {onMounted, ref} from "vue";
import store from "@/store";
import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';;


export default /*@__PURE__*/_defineComponent({
  __name: 'SetPassword',
  setup(__props) {

const router = useRouter()
const route = useRoute()
const $q = useQuasar()

const password = ref('')
const repassword = ref('')
const isPwd = ref(true)
const isRePwd = ref(true)

const onReset = () => {
  password.value = ''
  repassword.value = ''
}

const checkToken = async () => {
  await router.isReady()

  store
      .dispatch('checkToken', {
        token: route.query.token
      })
      .catch(() => {
        router.push('/')
            .then(() => {
              location.reload()
            })
      })
}

const onSubmit = () => {
  store
      .dispatch('confirmRegistration', {
        password: password.value,
        repassword: repassword.value,
        token: route.query.token,
      })
      .then(() => {
        $q.notify({
          type: 'positive',
          message: 'Пароль успешно задан.'
        })
      })
      .then(() => {
        setTimeout(() => {
          location.reload()
        }, 1500)
      })
      .catch(() => {
        $q.notify({
          color: 'red-5',
          textColor: 'white',
          icon: 'error',
          message: 'Пароли не совпадают, проверьте ваши данные.'
        })
      })
}

onMounted(() => {
  checkToken()
})

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-h6 col-12 text-center q-pa-md" }, "Установите пароль", -1)),
    _createVNode(_component_q_card, { class: "q-pa-md col-xs-12 col-sm-6 col-lg-4" }, {
      default: _withCtx(() => [
        _createVNode(_component_q_form, {
          onSubmit: _withModifiers(onSubmit, ["prevent"]),
          onReset: onReset,
          class: "q-gutter-md"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_input, {
              filled: "",
              modelValue: password.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
              label: "Пароль",
              type: isPwd.value ? 'password' : 'text',
              "lazy-rules": "",
              rules: [(val) => (val && val.length > 0) || 'Неправильный логин']
            }, {
              append: _withCtx(() => [
                _createVNode(_component_q_icon, {
                  name: isPwd.value ? 'visibility_off' : 'visibility',
                  class: "cursor-pointer",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (isPwd.value = !isPwd.value))
                }, null, 8, ["name"])
              ]),
              _: 1
            }, 8, ["modelValue", "type", "rules"]),
            _createVNode(_component_q_input, {
              modelValue: repassword.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((repassword).value = $event)),
              filled: "",
              type: isRePwd.value ? 'password' : 'text',
              label: "Повторите пароль"
            }, {
              append: _withCtx(() => [
                _createVNode(_component_q_icon, {
                  name: isRePwd.value ? 'visibility_off' : 'visibility',
                  class: "cursor-pointer",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (isRePwd.value = !isRePwd.value))
                }, null, 8, ["name"])
              ]),
              _: 1
            }, 8, ["modelValue", "type"]),
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.errMessage), 1),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_q_btn, {
                label: "Войти",
                type: "submit",
                color: "primary",
                "no-caps": "",
                unelevated: ""
              }),
              _createVNode(_component_q_btn, {
                label: "Сброс",
                type: "reset",
                color: "primary",
                flat: "",
                class: "q-ml-sm",
                "no-caps": "",
                unelevated: ""
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})