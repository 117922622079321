import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {onMounted, ref} from 'vue'
import {useClassApi} from "@/components/modules/polls/services/useClassApi";
import {ClassModel} from "proto/nigma-message-proto/polls/class";
import {ClassCrudProps} from "@/components/modules/polls/views/generalMessages/classification/MultipleClassification.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ClassDelete',
  props: {
    clsId: {},
    currentNode: {},
    currTab: {},
    currentTags: {},
    currentNotTags: {}
  },
  emits: ['afterAction', 'hideModal'],
  setup(__props: any, { emit: __emit }) {

const { deleteClassRequest } = useClassApi()

const props = __props
const emit = __emit

const classItem = ref<ClassModel>({
  classId: 0,
  classifierId: 0,
  classParentId: 0,
  classIdx: 1,
  className: '',
  classDescr: '',
  classNotes: '',
  classGroupId: 0,
  classGroupName: '',
  classGroupAlias: '',
  classGroupColor: '',
  classGroupRatio: 0,
})

const actualDelete = () => {
  deleteClassRequest({
    classId: classItem.value.classId
  })
      .then(() => {
        emit('afterAction', 0 !== classItem.value.classParentId ? classItem.value.classParentId : null, 'delete')
      })
}

const closeClassDelete = () => emit('hideModal')

const loadData = () => {
  if (props.currentNode) {
    classItem.value = {
      classId: props.currentNode.classId,
      classifierId: 0,
      classParentId: props.currentNode.classParentId,
      classIdx: 1,
      className: props.currentNode.className,
      classDescr: '',
      classNotes: '',
      classGroupId: 0,
      classGroupName: '',
      classGroupAlias: '',
      classGroupColor: '',
      classGroupRatio: 0,
    }
  } else {
    closeClassDelete()
  }
}

onMounted(() => loadData())

return (_ctx: any,_cache: any) => {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    style: {"width":"1024px","max-width":"80vw"},
    class: "q-pa-md"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_label, { class: "text-h5" }, {
                default: _withCtx(() => [
                  _cache[0] || (_cache[0] = _createElementVNode("p", null, "Удаление класса", -1)),
                  _createElementVNode("p", null, "«" + _toDisplayString(classItem.value.className) + "»", 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_separator),
      _createElementVNode("div", null, [
        _createVNode(_component_q_card_section, null, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("p", null, "Вы действительно хотите удалить класс - ?", -1)
          ])),
          _: 1
        }),
        _createVNode(_component_q_separator),
        _createVNode(_component_q_card_actions, { align: "right" }, {
          default: _withCtx(() => [
            _createVNode(_component_q_btn, {
              color: "primary",
              onClick: actualDelete
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" Удалить ")
              ])),
              _: 1
            }),
            _createVNode(_component_q_btn, {
              color: "primary",
              onClick: closeClassDelete
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Отмена ")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}
}

})