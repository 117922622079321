import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-loyalty" }
const _hoisted_2 = { class: "row q-mb-md" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = {
  class: "row q-mb-md items-center",
  style: {"gap":"15px"}
}

import ProjectSelect from '../components/Filters/ProjectsSelect.vue'
import DatePeriod from '../components/Filters/DatePeriod.vue'
import MetricSelect from '../components/UserLoyalty/Filters/MetricSelect.vue'
import SettingsModal from '../components/UserLoyalty/Filters/SettingsModal.vue'
import UserGroup from '../components/UserLoyalty/UserGroup/UserGroup.vue'
import UsersCoreSeg from '../components/UserLoyalty/Users/UsersCoreSeg.vue'

import store from '@/store'
import {computed, onMounted, ref, toRaw} from 'vue'
import SettingsModalNew from "@/components/modules/accounts/components/UserLoyalty/Filters/SettingsModalNew.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserLoyalty',
  setup(__props) {

const getProjectIds = computed(() => store.getters.getSelectedProjectItems)
const date = computed(() => store.getters.getDateForEventList)
const settings = computed(() => store.getters.getModalSettings)
const checkedMetrics = computed(() => store.getters.getCheckedMetrics)

const loadCore = () => {
  store
    .dispatch('getActiveCore', {
      date_from: date.value.dateFrom || '2012-01-01',
      date_to: date.value.dateTo || new Date().toJSON().slice(0, 10),
      project_ids:
        getProjectIds.value && getProjectIds.value.length
          ? toRaw(getProjectIds.value)
          : [1, 2, 3, 4, 5, 6],
      value: settings.value.sumActivity || 0.8,
      from_ids: checkedMetrics.value.from,
      to_ids: checkedMetrics.value.to,
      from_name: checkedMetrics.value.fromName,
      to_name: checkedMetrics.value.toName,
      add_param: settings.value.addParameters || false,
    })
    .catch(() => store.commit('setIsActiveCoreBusy', false))
}

const loadSegmentation = () => {
  store.dispatch('getSegmentationData', {
    project_ids:
      getProjectIds.value && getProjectIds.value.length
        ? toRaw(getProjectIds.value)
        : [1, 2, 3, 4, 5, 6],
    from_ids: checkedMetrics.value.from,
    to_ids: checkedMetrics.value.to,
    from_name: checkedMetrics.value.fromName,
    to_name: checkedMetrics.value.toName,
    date_from: date.value.dateFrom || '2012-01-01',
    date_to: date.value.dateTo || new Date().toJSON().slice(0, 10),
    add_param: settings.value.addParameters || false,
  })
}

const loadDBScan = () => {
  store.dispatch('getDbData', {
    project_ids:
        getProjectIds.value && getProjectIds.value.length
            ? toRaw(getProjectIds.value)
            : [1, 2, 3, 4, 5, 6],
    from_ids: checkedMetrics.value.from,
    to_ids: checkedMetrics.value.to,
    from_name: checkedMetrics.value.fromName,
    to_name: checkedMetrics.value.toName,
    date_from: date.value.dateFrom || '2012-01-01',
    date_to: date.value.dateTo || new Date().toJSON().slice(0, 10),
    add_param: settings.value.addParameters || false,
  })
}

const loadSegmentationSpends = () => {
  store.dispatch('getCountPointSegm', {
    date_from: date.value.dateFrom || '2012-01-01',
    date_to: date.value.dateTo || new Date().toJSON().slice(0, 10),
    project_ids:
      getProjectIds.value && getProjectIds.value.length
        ? toRaw(getProjectIds.value)
        : [1, 2, 3, 4, 5, 6],
    value: settings.value.lowEdgePoints || 0,
    percent: settings.value.orPrc || 0,
    from_ids: checkedMetrics.value.from,
    to_ids: checkedMetrics.value.to,
    from_name: checkedMetrics.value.fromName,
    to_name: checkedMetrics.value.toName,
    add_param: settings.value.addParameters || false,
  })
}

const applyPeriod = () => {
  console.log('SETTINGS ', settings.value)
  window.stop()
  setTimeout(() => {
    loadCore()
    loadSegmentation()
    loadDBScan()
    loadSegmentationSpends()
  }, 300)
}

const projectAssociation = ref({})

const getProjects = () => {
  store.dispatch('getProjectsItems', {
    filter: [
      {
        field_name: 'is_visible',
        operand: '=',
        value: 'true',
      }
    ],
    sort: {
      name: 'project_id',
      exp: 'asc',
    },
  })
      .then((res) => {
        const filteredAssocProjects = []

        for (const i of res.items) {
          filteredAssocProjects.push([i.getProjectName(), i.getProjectId()])
        }

        projectAssociation.value = Object.fromEntries(filteredAssocProjects)
      })
}

onMounted(() => {
  getProjects()
  store.dispatch('getMetricValueItems', {
    filter: [],
  })
})

return (_ctx: any,_cache: any) => {
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(ProjectSelect, {
        style: {"max-width":"356px","margin-right":"16px","width":"100%"},
        "project-association": projectAssociation.value
      }, null, 8, ["project-association"]),
      _createVNode(MetricSelect, { style: {"margin-right":"35px"} }),
      _createVNode(DatePeriod),
      _createVNode(_component_q_space),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_q_btn, {
          color: "primary",
          label: "Рассчитать",
          onClick: applyPeriod
        }),
        (!checkedMetrics.value.from || !checkedMetrics.value.to)
          ? (_openBlock(), _createBlock(_component_q_tooltip, {
              key: 0,
              class: "text-body1"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Выберите 2 метрики")
              ])),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(SettingsModal),
      _createVNode(SettingsModalNew)
    ]),
    _createVNode(UserGroup),
    _createVNode(UsersCoreSeg)
  ]))
}
}

})