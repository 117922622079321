import {useGrpcClientWithLogger} from "@/components/modules/rec-system/services/UserGroups/middleware";
import {ClassServiceClient} from "proto/nigma-message-proto/polls/class.client";
import {
    ListClassRequest,
    ItemClassRequest,
    CreateClassRequest,
    UpdateClassRequest,
    DeleteClassRequest,
} from "proto/nigma-message-proto/polls/class"

export const useClassApi = () => {
    const client = useGrpcClientWithLogger().create(ClassServiceClient, {baseUrl: window.VUE_APP_GATEWAY_URL})

    const metadata = () => {
        return {
            Authorization: localStorage.getItem('access-key') || ''
        }
    }

    return {
        listClassRequest: (request: ListClassRequest) => {
            return client.listClass(request, {meta: metadata()}).response
        },
        itemClassRequest: (request: ItemClassRequest) => {
            return client.itemClass(request, {meta: metadata()}).response
        },
        createClassRequest: (request: CreateClassRequest) => {
            return client.createClass(request, {meta: metadata()}).response
        },
        updateClassRequest: (request: UpdateClassRequest) => {
            return client.updateClass(request, {meta: metadata()}).response
        },
        deleteClassRequest: (request: DeleteClassRequest) => {
            return client.deleteClass(request, {meta: metadata()}).response
        }
    }
}