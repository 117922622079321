import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "q-pa-md col-12 q-gutter-md",
  style: {"height":"100%"}
}
const _hoisted_2 = {
  class: "row",
  style: {"width":"100% height: 40px"}
}
const _hoisted_3 = {
  class: "col-1",
  style: {"height":"40px"}
}
const _hoisted_4 = {
  class: "row",
  style: {"width":"100% height: 40px","overflow":"hidden"}
}
const _hoisted_5 = {
  class: "col-12",
  style: {"height":"40px","overflow":"auto"}
}
const _hoisted_6 = {
  class: "col-12 relative-position",
  style: {"width":"100%","height":"calc(100% - 143px)","margin":"10px 0px 0px 10px"}
}
const _hoisted_7 = {
  class: "row items-center",
  style: {"width":"100%","height":"65px","margin-top":"5px"}
}
const _hoisted_8 = { class: "col-6" }
const _hoisted_9 = {
  class: "col-2",
  style: {"height":"100%"}
}
const _hoisted_10 = {
  class: "row justify-start items-center",
  style: {"height":"100%"}
}
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_ExportAnswers = _resolveComponent("ExportAnswers")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_DataLoading = _resolveComponent("DataLoading")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    style: {"width":"1280px","max-width":"80vw","height":"840px","max-height":"80vh","box-shadow":"none"},
    class: "q-pa-md"
  }, {
    default: _withCtx(() => [
      (!_ctx.isAnswerSrcSourceBusy)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_q_card_section, { style: {"width":"100%","height":"80px","margin":"10px 0px 0px 10px","padding":"0px"} }, {
              default: _withCtx(() => [
                _createVNode(_component_q_item_label, { style: {"width":"100%","height":"80px","margin":"0px","padding":"0px"} }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _cache[2] || (_cache[2] = _createElementVNode("div", {
                        class: "col-11",
                        style: {"height":"40px","text-align":"center","font-size":"20px"}
                      }, " Исходные ответы ", -1)),
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_q_btn, {
                          icon: "close",
                          flat: "",
                          round: "",
                          dense: "",
                          style: {"float":"right"},
                          onClick: _ctx.closeTableAnswerSource
                        }, null, 8, ["onClick"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("span", null, "(" + _toDisplayString(_ctx.isFull ? _ctx.answer.sum : _ctx.answer.count) + ")", 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.answer.text), 1)
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_q_separator, { style: {"width":"100%","margin":"5px 0px 5px 10px"} }),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_q_table, {
                style: {"height":"100%","overflow":"auto"},
                class: "sticky-header-table-answer",
                flat: "",
                dense: "",
                rows: _ctx.getAnswerSrcSourceItems,
                columns: _ctx.columnsSource,
                "row-key": "id",
                loading: _ctx.isAnswerSrcSourceBusy,
                pagination: _ctx.sourcePagination,
                "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sourcePagination) = $event)),
                "rows-per-page-options": _ctx.rowsPerPageSourceOptions,
                onRequest: _ctx.onRequestSource
              }, {
                top: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_q_input, {
                        modelValue: _ctx.search,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                        label: "Текст для поиска",
                        onKeydown: _withKeys(_withModifiers(_ctx.findAnswers, ["prevent"]), ["enter"])
                      }, null, 8, ["modelValue", "onKeydown"])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createVNode(_component_q_btn, {
                          style: {"margin":"0px 15px"},
                          "background-color": "gray-12",
                          disable: _ctx.isAnswerSrcSourceBusy,
                          icon: "mdi-magnify",
                          onClick: _ctx.findAnswers
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_tooltip, null, {
                              default: _withCtx(() => _cache[3] || (_cache[3] = [
                                _createTextVNode("Найти ответы")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["disable", "onClick"])
                      ])
                    ]),
                    _createVNode(_component_q_btn, {
                      class: "q-ml-auto q-mr-md",
                      style: {"height":"40px"},
                      label: "Открыть в ЦП",
                      color: "primary",
                      onClick: _ctx.loadPersonalSourceAnswers
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_tooltip, {
                          anchor: "top middle",
                          self: "bottom middle",
                          offset: [10, 10]
                        }, {
                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                            _createElementVNode("p", {
                              style: {"font-size":"16px"},
                              class: "action-tooltip"
                            }, "Будут переданы первые 300 000 пользователей", -1)
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_ExportAnswers, {
                      style: {"height":"40px"},
                      answer_grp_id: {id: _ctx.answer.id, text: _ctx.answer.text},
                      filter: _ctx.sourceFilter,
                      sort: {name: _ctx.sourcePagination.sortBy, exp: _ctx.sourcePagination.descending},
                      is_full: _ctx.isFull
                    }, null, 8, ["answer_grp_id", "filter", "sort", "is_full"])
                  ])
                ]),
                "body-cell-id": _withCtx((props) => [
                  _createVNode(_component_q_td, {
                    props: props,
                    style: {"width":"60px"}
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(props.value), 1)
                    ]),
                    _: 2
                  }, 1032, ["props"])
                ]),
                "body-cell-sso_id": _withCtx((props) => [
                  _createVNode(_component_q_td, {
                    props: props,
                    onClick: ($event: any) => (_ctx.openUserProfile(props.row.guid)),
                    title: "Перейти в профиль",
                    style: {"width":"280px","cursor":"pointer"}
                  }, {
                    default: _withCtx(() => [
                      (_ctx.markedFind.length)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            innerHTML: _ctx.markedAll(props.value)
                          }, null, 8, _hoisted_11))
                        : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(props.value), 1))
                    ]),
                    _: 2
                  }, 1032, ["props", "onClick"])
                ]),
                "body-cell-text": _withCtx((props) => [
                  _createVNode(_component_q_td, { props: props }, {
                    default: _withCtx(() => [
                      (_ctx.markedFind.length)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            innerHTML: _ctx.markedAll(props.value)
                          }, null, 8, _hoisted_13))
                        : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(props.value), 1))
                    ]),
                    _: 2
                  }, 1032, ["props"])
                ]),
                "body-cell-time": _withCtx((props) => [
                  _createVNode(_component_q_td, {
                    props: props,
                    style: {"width":"160px"}
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.getFormattedDateTimeOriginFromTimestamp(props.value)), 1)
                    ]),
                    _: 2
                  }, 1032, ["props"])
                ]),
                "no-data": _withCtx(() => _cache[5] || (_cache[5] = [
                  _createElementVNode("div", { class: "col-12 text-center" }, "Нет данных", -1)
                ])),
                _: 1
              }, 8, ["rows", "columns", "loading", "pagination", "rows-per-page-options", "onRequest"]),
              _createVNode(_component_q_inner_loading, {
                showing: _ctx.isAnswerSrcSourceBusy,
                color: "grey",
                label: `Подождите, страница скоро откроется`,
                style: {"z-index":"100"}
              }, null, 8, ["showing"])
            ]),
            _createVNode(_component_q_separator, { style: {"width":"100%","margin":"5px 0px 5px 10px"} }),
            _createVNode(_component_q_card_actions, { align: "right" }, {
              default: _withCtx(() => [
                _createVNode(_component_q_btn, {
                  color: "primary",
                  onClick: _ctx.closeTableAnswerSource
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode(" Закрыть ")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createVNode(_component_DataLoading, { msgLoading: "Загрузка" })
          ]))
    ]),
    _: 1
  }))
}