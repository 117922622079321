import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withKeys as _withKeys, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, isRef as _isRef, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { style: {"padding":"5px 24px","max-height":"87vh"} }
const _hoisted_2 = {
  class: "row",
  style: {"max-height":"100%"}
}
const _hoisted_3 = { class: "col-5 q-pr-lg text-black relative-position" }
const _hoisted_4 = { class: "col-12 shadow-4" }
const _hoisted_5 = { class: "col-12 bg-white" }
const _hoisted_6 = {
  class: "row",
  style: {"width":"100%"}
}
const _hoisted_7 = {
  class: "col-xl-5 col-lg-5",
  style: {"padding-right":"5px"}
}
const _hoisted_8 = { style: {"padding":"0 8px 0 5px","height":"100%"} }
const _hoisted_9 = {
  class: "col-xl-5 col-lg-4",
  style: {"padding-right":"0"}
}
const _hoisted_10 = { style: {"padding-left":"10px","height":"100%"} }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "col-7 shadow-4 bg-white text-black relative-position" }

import store from "@/store";
import { computed, onMounted, ref } from "vue";
import MessageChilds from "@/components/modules/polls/components/MessageChilds.vue";
import { useMessageGrpApi } from "@/components/modules/polls/services/useMessageGrpApi";
import { MessageGrpModel } from "proto/nigma-message-proto/polls/message_grp";
import type { QTableColumn } from "quasar";
import { Filter } from "proto/nigma-common-proto/filter";
import MultipleGeneralizationMessages from "@/components/modules/polls/components/generalMessages/generalization/MultipleGeneralizationMessages.vue";
import { useTextHighlighter } from "@/composables/useTextHighlighter";
import SourceTable from "@/components/modules/polls/components/SourceTable.vue";
import { usePagination } from "@/composables/usePagination";

type Processed = {
  id: number;
  title: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'MultipleGeneralization',
  setup(__props) {

const { listMessageGrpRequest, listMessageGrpWithFindMessageRequest } =
  useMessageGrpApi();
const { markedFindValue } = useTextHighlighter();

const msgTableRows = ref<MessageGrpModel[]>([]);
const msgTableLoader = ref(false);
const workingMsgPanel =
  ref<InstanceType<typeof MultipleGeneralizationMessages>>();
const tableMessageRef = ref<InstanceType<typeof MessageChilds>>();

const isActiveModal = ref(false);
const isActiveModalWordForm = ref(false);
const isActiveModalSource = ref(false);
const currentCountMessage = ref<MessageGrpModel>();
const isFullCount = ref(false);
const columnsResult: QTableColumn[] = [
  {
    name: "messageGrpText",
    field: "messageGrpText",
    label: "Сообщение",
    align: "left",
  },
  {
    name: "messageGrpSum",
    field: "messageGrpSum",
    label: "Количество голосов",
    align: "center",
  },
];
const columnsChild: QTableColumn[] = [
  {
    field: "messageGrpText",
    name: "messageGrpText",
    label: "Ответ",
    align: "left",
  },
  {
    field: "messageGrpCount",
    name: "messageGrpCount",
    label: "Количество голосов",
    align: "center",
  },
];
const resultFilter = ref<Filter>({
  items: [
    {
      fieldName: "mg.message_grp_text",
      operand: "ilike",
      value: "",
    },
  ],
});
const { onTableRequest: onRequest, pagination: resultPagination } =
  usePagination({
    sortBy: "message_grp_sum",
    descending: true,
    page: 1,
    rowsPerPage: 25,
    rowsNumber: 0,
  });
const rowsPerPageResultOptions = [10, 25, 50, 100];
const search = ref("");
const processedSign = ref<Processed>();
const processedOptions = [
  { id: 0, title: "Все сообщения" },
  { id: 1, title: "Только обработанные" },
  { id: 2, title: "Только необработанные" },
];
const selectedResult = ref<MessageGrpModel[]>([]);
const expandedResult = ref<number[]>([]);
const markedFind = ref<string[]>([]);
const getTableRefVar = ref<MessageGrpModel[]>([]);

const userRole = computed(() => store.getters.getUserAccess);

const openChildTable = (props: any) => {
  setTimeout(() => {
    if (props) {
      props.__trClass = "";
    }
  }, 300);

  if (expandedResult.value[0] === props.row.messageGrpId) {
    expandedResult.value = [];
    getTableRefVar.value = [];
  } else {
    getTableRefVar.value = [];
    expandedResult.value = [props.row.messageGrpId];
  }
};

const changeProcessedSign = (event: Processed) => {
  const processed = JSON.parse(JSON.stringify(event));

  if (processed.id === 0) {
    resultFilter.value.items.pop();
  } else if (processed.id === 1) {
    resultFilter.value.items[1] = {
      fieldName: "message_grp_processed",
      operand: "=",
      value: "true",
    };
  } else if (processed.id === 2) {
    resultFilter.value.items[1] = {
      fieldName: "message_grp_processed",
      operand: "=",
      value: "false",
    };
  }

  findMessagesResult();
};

const afterAction = (setMessage: number) => {
  search.value = "";
  expandedResult.value = [];

  if (processedSign.value && processedSign.value.id > 1) {
    processedSign.value = processedOptions[0];
    changeProcessedSign(processedSign.value);
  }

  resultPagination.value.page = 1;
  resultPagination.value.rowsNumber = 0;

  msgTableLoader.value = true;

  listMessageGrpWithFindMessageRequest({
    filter: resultFilter.value,
    sort: {
      name: resultPagination.value.sortBy,
      exp: resultPagination.value.descending ? "DESC" : "ASC",
    },
    pagination: {
      page: resultPagination.value.page,
      limit: resultPagination.value.rowsPerPage,
      pages: 0,
      cnt: 0,
    },
    messageGrpId: setMessage,
  })
    .then((res) => {
      resultPagination.value.page = res.params?.pagination?.page || 1;
      resultPagination.value.rowsNumber = res.params?.pagination?.cnt || 0;
      msgTableRows.value = res.items;

      if (selectedResult.value[0])
        onRowClickMessageResult({ row: selectedResult.value[0] }, false);
    })
    .finally(() => (msgTableLoader.value = false));
};

const onRowClickMessageResult = (props: any, isResetSimilarity: boolean) => {
  selectedResult.value = [props.row];

  setTimeout(() => {
    if (props) {
      props.__trClass = "";
    }
  }, 300);

  if (workingMsgPanel.value && selectedResult.value.length) {
    workingMsgPanel.value?.loadData(
      true,
      isResetSimilarity,
      selectedResult.value[0]
    );
  }
};

const hideModal = () => {
  isActiveModal.value = false;
  isActiveModalWordForm.value = false;
  isActiveModalSource.value = false;
  currentCountMessage.value = undefined;
  isFullCount.value = false;
};

const onRowShowMessageSource = (row: MessageGrpModel, isFull: boolean) => {
  hideModal();

  currentCountMessage.value = Object.assign(row);
  isFullCount.value = isFull;
  isActiveModalSource.value = true;
  isActiveModal.value = true;
};

const findMessagesResult = () => {
  markedFind.value = [];

  resultFilter.value.items[0].value = search.value;

  if (search.value.length) {
    markedFind.value.push(search.value);
  }

  loadMessageResultItems(true);
};

const loadMessageResultItems = (isResetPagination: boolean) => {
  msgTableLoader.value = true;

  if (isResetPagination) {
    resultPagination.value.page = 1;
    resultPagination.value.rowsNumber = 0;
  }

  listMessageGrpRequest({
    filter: resultFilter.value,
    sort: {
      name: resultPagination.value.sortBy,
      exp: resultPagination.value.descending ? "DESC" : "ASC",
    },
    pagination: {
      page: resultPagination.value.page,
      limit: resultPagination.value.rowsPerPage,
      pages: 0,
      cnt: 0,
    },
    isWorked: false,
  })
    .then((res) => {
      resultPagination.value.rowsNumber = res.params?.pagination?.cnt || 0;
      msgTableRows.value = res.items;
    })
    .finally(() => (msgTableLoader.value = false));
};

const onRequestResult = onRequest(() => loadMessageResultItems(false));

const separateTableChild = () => tableMessageRef.value?.separateChild();

const afterSeparate = () => {
  afterAction(expandedResult.value[0]);
  getTableRefVar.value = [];
};

const checkChildLength = (childLength: any) => {
  getTableRefVar.value = childLength;
};

const loadData = () => {
  hideModal();
  processedSign.value = processedOptions[0];
  loadMessageResultItems(true);
};

onMounted(loadData);

return (_ctx: any,_cache: any) => {
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "text-h4 q-mt-sm q-mb-sm" }, "Обобщение", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "text-h6 bg-white text-black text-weight-bold q-pa-sm" }, " Сообщения ", -1)),
          _createVNode(_component_q_separator),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_q_table, {
              style: _normalizeStyle([{"height":"100%","overflow":"auto"}, msgTableRows.value.length ? 'height: 73vh' : null]),
              class: "sticky-header-table-answer",
              flat: "",
              dense: "",
              rows: msgTableRows.value,
              columns: columnsResult,
              "row-key": "messageGrpId",
              "wrap-cells": "",
              selection: "single",
              selected: selectedResult.value,
              "onUpdate:selected": _cache[3] || (_cache[3] = ($event: any) => ((selectedResult).value = $event)),
              pagination: _unref(resultPagination),
              "onUpdate:pagination": _cache[4] || (_cache[4] = ($event: any) => (_isRef(resultPagination) ? (resultPagination).value = $event : null)),
              "rows-per-page-options": rowsPerPageResultOptions,
              expanded: expandedResult.value,
              "onUpdate:expanded": _cache[5] || (_cache[5] = ($event: any) => ((expandedResult).value = $event)),
              onRequest: _unref(onRequestResult)
            }, {
              top: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_q_input, {
                      filled: "",
                      modelValue: search.value,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((search).value = $event)),
                      label: "Текст для поиска",
                      onKeypress: _withKeys(findMessagesResult, ["enter"])
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_q_btn, {
                      style: {"padding":"10px","margin-top":"6px","margin-right":"1px"},
                      "background-color": "gray-12",
                      icon: "mdi-magnify",
                      onClick: findMessagesResult
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_tooltip, null, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode("Найти сообщения")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_q_select, {
                      filled: "",
                      modelValue: processedSign.value,
                      "onUpdate:modelValue": [
                        _cache[1] || (_cache[1] = ($event: any) => ((processedSign).value = $event)),
                        _cache[2] || (_cache[2] = ($event: any) => (changeProcessedSign($event)))
                      ],
                      options: processedOptions,
                      "option-value": "id",
                      "option-label": "title",
                      label: "Признак обработки ответа"
                    }, null, 8, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    ('admin editor'.includes(userRole.value))
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          style: {"padding":"10px","margin-top":"6px","margin-right":"1px"},
                          "background-color": "gray-12",
                          icon: "mdi-pen-remove",
                          onClick: separateTableChild,
                          disable: getTableRefVar.value.length === 0
                        }, {
                          default: _withCtx(() => [
                            (getTableRefVar.value.length !== 0)
                              ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 0 }, {
                                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                                    _createTextVNode("Разделить сообщения")
                                  ])),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            (getTableRefVar.value.length === 0)
                              ? (_openBlock(), _createBlock(_component_q_tooltip, { key: 1 }, {
                                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                                    _createTextVNode("Необходимо выбрать хотя бы 1 обобщенный ответ")
                                  ])),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["disable"]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              header: _withCtx((props) => [
                _createVNode(_component_q_tr, { props: props }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_th, { "auto-width": "" }),
                    _createVNode(_component_q_th, {
                      key: "messageGrpText",
                      props: props,
                      class: "text-left"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.cols[0].label), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_th, {
                      key: "messageGrpSum",
                      props: props,
                      class: "text-center",
                      style: {"width":"120px"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.cols[1].label), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"])
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              body: _withCtx((props) => [
                (_openBlock(), _createBlock(_component_q_tr, {
                  key: 
                    props.rowIndex + '###primary###' + props.row.messageGrpId
                  ,
                  props: props,
                  class: _normalizeClass(
                    props.row.messageGrpProcessed ? 'message-processed' : ''
                  )
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_td, null, {
                      default: _withCtx(() => [
                        _withDirectives(_createVNode(_component_q_btn, {
                          size: "sm",
                          flat: "",
                          rounded: "",
                          dense: "",
                          icon: 
                        props.expand
                          ? 'fas fa-caret-down'
                          : 'fas fa-caret-right'
                      ,
                          onClick: ($event: any) => (openChildTable(props))
                        }, null, 8, ["icon", "onClick"]), [
                          [_vShow, props.row.hasChilds]
                        ])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_q_td, {
                      onClick: ($event: any) => (onRowClickMessageResult(props, true))
                    }, {
                      default: _withCtx(() => [
                        (markedFind.value.length)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              innerHTML: 
                        _unref(markedFindValue)(props.row.messageGrpText, markedFind.value)
                      
                            }, null, 8, _hoisted_11))
                          : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(props.row.messageGrpText), 1))
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_q_td, {
                      class: "text-center",
                      style: {"width":"120px"}
                    }, {
                      default: _withCtx(() => [
                        (props.row.hasChilds)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                              _createElementVNode("span", {
                                style: {"cursor":"pointer"},
                                title: "Исходные сообщения",
                                onClick: ($event: any) => (onRowShowMessageSource(props.row, true))
                              }, _toDisplayString(props.row.messageGrpSum.toLocaleString()), 9, _hoisted_14),
                              _cache[10] || (_cache[10] = _createTextVNode(" | ")),
                              _createElementVNode("span", {
                                style: {"cursor":"pointer"},
                                title: "Исходные сообщения",
                                onClick: ($event: any) => (onRowShowMessageSource(props.row, false))
                              }, _toDisplayString(props.row.messageGrpCount.toLocaleString()), 9, _hoisted_15)
                            ]))
                          : (_openBlock(), _createElementBlock("span", {
                              key: 1,
                              style: {"cursor":"pointer"},
                              title: "Исходные сообщения",
                              onClick: ($event: any) => (onRowShowMessageSource(props.row, false))
                            }, _toDisplayString(props.row.messageGrpCount.toLocaleString()), 9, _hoisted_16))
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["props", "class"])),
                (props.expand)
                  ? (_openBlock(), _createBlock(_component_q_tr, {
                      key: 
                    props.rowIndex + '###secondary###' + props.row.messageGrpId
                  ,
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_td, {
                          colspan: "100%",
                          style: {"padding-right":"0","padding-top":"0","padding-left":"1.7rem"}
                        }, {
                          default: _withCtx(() => [
                            (props.expand)
                              ? (_openBlock(), _createBlock(MessageChilds, {
                                  key: 0,
                                  "table-key": 
                        props.rowIndex +
                        '###expanded###' +
                        props.row.messageGrpId
                      ,
                                  columns: columnsChild,
                                  type: "all",
                                  "current-item": props.row,
                                  onShowSource: onRowShowMessageSource,
                                  onAfterSeparate: afterSeparate,
                                  onCheckChild: checkChildLength,
                                  ref_key: "tableMessageRef",
                                  ref: tableMessageRef
                                }, null, 8, ["table-key", "current-item"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true)
              ]),
              pagination: _withCtx((scope) => [
                _createTextVNode(_toDisplayString((
                    scope.pagination.rowsPerPage * scope.pagination.page -
                    scope.pagination.rowsPerPage +
                    1
                  ).toLocaleString()) + " - " + _toDisplayString(scope.pagination.rowsPerPage * scope.pagination.page >
                  scope.pagination.rowsNumber
                    ? scope.pagination.rowsNumber.toLocaleString()
                    : (
                        scope.pagination.rowsPerPage * scope.pagination.page
                      ).toLocaleString()) + " из " + _toDisplayString(scope.pagination.rowsNumber.toLocaleString()) + " ", 1),
                (scope.pagesNumber > 2)
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 0,
                      icon: "first_page",
                      color: "grey-8",
                      round: "",
                      dense: "",
                      flat: "",
                      disable: scope.isFirstPage,
                      onClick: scope.firstPage,
                      class: "q-ml-md"
                    }, null, 8, ["disable", "onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_q_btn, {
                  icon: "chevron_left",
                  color: "grey-8",
                  round: "",
                  dense: "",
                  flat: "",
                  disable: scope.isFirstPage,
                  onClick: scope.prevPage
                }, null, 8, ["disable", "onClick"]),
                _createVNode(_component_q_btn, {
                  icon: "chevron_right",
                  color: "grey-8",
                  round: "",
                  dense: "",
                  flat: "",
                  disable: scope.isLastPage,
                  onClick: scope.nextPage
                }, null, 8, ["disable", "onClick"]),
                (scope.pagesNumber > 2)
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 1,
                      icon: "last_page",
                      color: "grey-8",
                      round: "",
                      dense: "",
                      flat: "",
                      disable: scope.isLastPage,
                      onClick: scope.lastPage
                    }, null, 8, ["disable", "onClick"]))
                  : _createCommentVNode("", true)
              ]),
              "no-data": _withCtx(() => _cache[11] || (_cache[11] = [
                _createElementVNode("div", { class: "col-12 text-center text-h4" }, "Нет данных", -1)
              ])),
              _: 1
            }, 8, ["rows", "selected", "pagination", "expanded", "onRequest", "style"])
          ])
        ]),
        _createVNode(_component_q_inner_loading, {
          color: "grey",
          style: {"z-index":"100"},
          showing: msgTableLoader.value
        }, null, 8, ["showing"])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "text-h6 text-weight-bold q-pa-sm" }, " Поиск схожих вариантов ", -1)),
        _createVNode(_component_q_separator),
        _createVNode(MultipleGeneralizationMessages, {
          ref_key: "workingMsgPanel",
          ref: workingMsgPanel,
          onAfterAction: afterAction,
          onShowSource: onRowShowMessageSource
        }, null, 512),
        _createVNode(_component_q_inner_loading, {
          color: "grey",
          style: {"z-index":"100"}
        })
      ])
    ]),
    _createVNode(_component_q_dialog, {
      modelValue: isActiveModal.value,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((isActiveModal).value = $event)),
      persistent: ""
    }, {
      default: _withCtx(() => [
        (isActiveModalSource.value)
          ? (_openBlock(), _createBlock(SourceTable, {
              key: 0,
              message: currentCountMessage.value,
              isFull: isFullCount.value,
              type: "all",
              onHideModal: hideModal
            }, null, 8, ["message", "isFull"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})