import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {onMounted, ref} from 'vue'
import {
  ClassNotTagsCrudProps,
} from "@/components/modules/polls/views/generalMessages/classification/MultipleClassification.vue";
import {ClassNotTagModel} from "proto/nigma-message-proto/polls/class_not_tag";


export default /*@__PURE__*/_defineComponent({
  __name: 'NotTagEdit',
  props: {
    notTagItem: {}
  },
  emits: ['afterEdit', 'hideModal'],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

const classNotTagItem = ref<ClassNotTagModel & { index: number }>({
  classId: 0,
  notTagName: '',
  notTagDescr: '',
  index: 0,
})

const closeTagEdit = () => emit('hideModal')

const saveTag = () => emit('afterEdit', classNotTagItem.value)

const loadData = () => {
  classNotTagItem.value = {
    classId: props.notTagItem.classId,
    notTagName: props.notTagItem.notTagName,
    notTagDescr: props.notTagItem.notTagDescr,
    index: props.notTagItem.index,
  }
}

onMounted(() => loadData())

return (_ctx: any,_cache: any) => {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, {
    style: {"width":"1024px","max-width":"80vw","max-height":"80vh"},
    class: "q-pa-md"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_item_label, { class: "text-h5" }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Редактирование тэга")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_q_separator),
      _createVNode(_component_q_form, {
        onSubmit: _withModifiers(saveTag, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_input, {
                modelValue: classNotTagItem.value.notTagName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((classNotTagItem.value.notTagName) = $event)),
                label: "Наименование"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_input, {
                type: "textarea",
                modelValue: classNotTagItem.value.notTagDescr,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((classNotTagItem.value.notTagDescr) = $event)),
                label: "Описание"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_separator),
          _createVNode(_component_q_card_actions, { align: "right" }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                type: "submit",
                color: "primary"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" Сохранить ")
                ])),
                _: 1
              }),
              _createVNode(_component_q_btn, {
                color: "primary",
                onClick: closeTagEdit
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" Отмена ")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})