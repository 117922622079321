// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "class_tag.proto" (package "proto.nigmamsg", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Pagination } from "./proto/nigma-common-proto/filter";
import { Sort } from "./proto/nigma-common-proto/filter";
import { Filter } from "./proto/nigma-common-proto/filter";
/**
 * Модель данных
 * Без префиксов для фильтров
 *
 * @generated from protobuf message proto.nigmamsg.ClassTagModel
 */
export interface ClassTagModel {
    /**
     * @generated from protobuf field: int64 class_id = 1;
     */
    classId: number;
    /**
     * @generated from protobuf field: string tag_name = 2;
     */
    tagName: string;
    /**
     * @generated from protobuf field: string tag_descr = 3;
     */
    tagDescr: string;
}
/**
 * Входящий запрос для получения списка данных
 *
 * @generated from protobuf message proto.nigmamsg.ListClassTagRequest
 */
export interface ListClassTagRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
    /**
     * @generated from protobuf field: proto.Sort sort = 2;
     */
    sort?: Sort;
    /**
     * @generated from protobuf field: proto.Pagination pagination = 3;
     */
    pagination?: Pagination;
}
/**
 * Ответ сервера для получения списка данных
 *
 * @generated from protobuf message proto.nigmamsg.ListClassTagResponse
 */
export interface ListClassTagResponse {
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.ClassTagModel items = 1;
     */
    items: ClassTagModel[];
    /**
     * @generated from protobuf field: proto.nigmamsg.ListClassTagRequest params = 2;
     */
    params?: ListClassTagRequest;
}
/**
 * Входящий запрос для получения элемента
 *
 * @generated from protobuf message proto.nigmamsg.ItemClassTagRequest
 */
export interface ItemClassTagRequest {
    /**
     * @generated from protobuf field: proto.Filter filter = 1;
     */
    filter?: Filter;
}
/**
 * Ответ сервера для получения элемента
 *
 * @generated from protobuf message proto.nigmamsg.ItemClassTagResponse
 */
export interface ItemClassTagResponse {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassTagModel item = 1;
     */
    item?: ClassTagModel;
}
/**
 * Входящий запрос для создания элемента
 *
 * @generated from protobuf message proto.nigmamsg.CreateClassTagRequest
 */
export interface CreateClassTagRequest {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassTagModel item = 1;
     */
    item?: ClassTagModel;
}
/**
 * Ответ сервера для создания элемента
 *
 * @generated from protobuf message proto.nigmamsg.CreateClassTagResponse
 */
export interface CreateClassTagResponse {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassTagModel item = 1;
     */
    item?: ClassTagModel;
}
/**
 * Входящий запрос для обновления элемента
 *
 * @generated from protobuf message proto.nigmamsg.UpdateClassTagRequest
 */
export interface UpdateClassTagRequest {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassTagModel item = 1;
     */
    item?: ClassTagModel;
}
/**
 * Ответ сервера для обновления элемента
 *
 * @generated from protobuf message proto.nigmamsg.UpdateClassTagResponse
 */
export interface UpdateClassTagResponse {
    /**
     * @generated from protobuf field: proto.nigmamsg.ClassTagModel item = 1;
     */
    item?: ClassTagModel;
}
/**
 * Входящий запрос для удаления элемента
 *
 * @generated from protobuf message proto.nigmamsg.DeleteClassTagRequest
 */
export interface DeleteClassTagRequest {
    /**
     * @generated from protobuf field: int64 class_id = 1;
     */
    classId: number;
    /**
     * @generated from protobuf field: string tag_name = 2;
     */
    tagName: string;
}
/**
 * Ответ сервера для удаления элемента
 *
 * @generated from protobuf message proto.nigmamsg.DeleteClassTagResponse
 */
export interface DeleteClassTagResponse {
    /**
     * @generated from protobuf field: int64 class_id = 1;
     */
    classId: number;
    /**
     * @generated from protobuf field: string tag_name = 2;
     */
    tagName: string;
}
/**
 * Входящий запрос для сохранения элементов
 *
 * @generated from protobuf message proto.nigmamsg.SaveClassTagRequest
 */
export interface SaveClassTagRequest {
    /**
     * @generated from protobuf field: int64 class_id = 1;
     */
    classId: number;
    /**
     * @generated from protobuf field: repeated proto.nigmamsg.ClassTagModel items = 2;
     */
    items: ClassTagModel[];
}
/**
 * Ответ сервера для сохранения элементаов
 *
 * @generated from protobuf message proto.nigmamsg.SaveClassTagResponse
 */
export interface SaveClassTagResponse {
    /**
     * @generated from protobuf field: int64 class_id = 1;
     */
    classId: number;
}
// @generated message type with reflection information, may provide speed optimized methods
class ClassTagModel$Type extends MessageType<ClassTagModel> {
    constructor() {
        super("proto.nigmamsg.ClassTagModel", [
            { no: 1, name: "class_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "tag_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tag_descr", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ClassTagModel>): ClassTagModel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classId = 0;
        message.tagName = "";
        message.tagDescr = "";
        if (value !== undefined)
            reflectionMergePartial<ClassTagModel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClassTagModel): ClassTagModel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_id */ 1:
                    message.classId = reader.int64().toNumber();
                    break;
                case /* string tag_name */ 2:
                    message.tagName = reader.string();
                    break;
                case /* string tag_descr */ 3:
                    message.tagDescr = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ClassTagModel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_id = 1; */
        if (message.classId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classId);
        /* string tag_name = 2; */
        if (message.tagName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tagName);
        /* string tag_descr = 3; */
        if (message.tagDescr !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tagDescr);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ClassTagModel
 */
export const ClassTagModel = new ClassTagModel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListClassTagRequest$Type extends MessageType<ListClassTagRequest> {
    constructor() {
        super("proto.nigmamsg.ListClassTagRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter },
            { no: 2, name: "sort", kind: "message", T: () => Sort },
            { no: 3, name: "pagination", kind: "message", T: () => Pagination }
        ]);
    }
    create(value?: PartialMessage<ListClassTagRequest>): ListClassTagRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ListClassTagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListClassTagRequest): ListClassTagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* proto.Sort sort */ 2:
                    message.sort = Sort.internalBinaryRead(reader, reader.uint32(), options, message.sort);
                    break;
                case /* proto.Pagination pagination */ 3:
                    message.pagination = Pagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListClassTagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.Sort sort = 2; */
        if (message.sort)
            Sort.internalBinaryWrite(message.sort, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* proto.Pagination pagination = 3; */
        if (message.pagination)
            Pagination.internalBinaryWrite(message.pagination, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListClassTagRequest
 */
export const ListClassTagRequest = new ListClassTagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListClassTagResponse$Type extends MessageType<ListClassTagResponse> {
    constructor() {
        super("proto.nigmamsg.ListClassTagResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ClassTagModel },
            { no: 2, name: "params", kind: "message", T: () => ListClassTagRequest }
        ]);
    }
    create(value?: PartialMessage<ListClassTagResponse>): ListClassTagResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListClassTagResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListClassTagResponse): ListClassTagResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated proto.nigmamsg.ClassTagModel items */ 1:
                    message.items.push(ClassTagModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* proto.nigmamsg.ListClassTagRequest params */ 2:
                    message.params = ListClassTagRequest.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListClassTagResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated proto.nigmamsg.ClassTagModel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            ClassTagModel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* proto.nigmamsg.ListClassTagRequest params = 2; */
        if (message.params)
            ListClassTagRequest.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ListClassTagResponse
 */
export const ListClassTagResponse = new ListClassTagResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemClassTagRequest$Type extends MessageType<ItemClassTagRequest> {
    constructor() {
        super("proto.nigmamsg.ItemClassTagRequest", [
            { no: 1, name: "filter", kind: "message", T: () => Filter }
        ]);
    }
    create(value?: PartialMessage<ItemClassTagRequest>): ItemClassTagRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ItemClassTagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemClassTagRequest): ItemClassTagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.Filter filter */ 1:
                    message.filter = Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ItemClassTagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.Filter filter = 1; */
        if (message.filter)
            Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ItemClassTagRequest
 */
export const ItemClassTagRequest = new ItemClassTagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemClassTagResponse$Type extends MessageType<ItemClassTagResponse> {
    constructor() {
        super("proto.nigmamsg.ItemClassTagResponse", [
            { no: 1, name: "item", kind: "message", T: () => ClassTagModel }
        ]);
    }
    create(value?: PartialMessage<ItemClassTagResponse>): ItemClassTagResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ItemClassTagResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemClassTagResponse): ItemClassTagResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassTagModel item */ 1:
                    message.item = ClassTagModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ItemClassTagResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassTagModel item = 1; */
        if (message.item)
            ClassTagModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.ItemClassTagResponse
 */
export const ItemClassTagResponse = new ItemClassTagResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateClassTagRequest$Type extends MessageType<CreateClassTagRequest> {
    constructor() {
        super("proto.nigmamsg.CreateClassTagRequest", [
            { no: 1, name: "item", kind: "message", T: () => ClassTagModel }
        ]);
    }
    create(value?: PartialMessage<CreateClassTagRequest>): CreateClassTagRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateClassTagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateClassTagRequest): CreateClassTagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassTagModel item */ 1:
                    message.item = ClassTagModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateClassTagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassTagModel item = 1; */
        if (message.item)
            ClassTagModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.CreateClassTagRequest
 */
export const CreateClassTagRequest = new CreateClassTagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateClassTagResponse$Type extends MessageType<CreateClassTagResponse> {
    constructor() {
        super("proto.nigmamsg.CreateClassTagResponse", [
            { no: 1, name: "item", kind: "message", T: () => ClassTagModel }
        ]);
    }
    create(value?: PartialMessage<CreateClassTagResponse>): CreateClassTagResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateClassTagResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateClassTagResponse): CreateClassTagResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassTagModel item */ 1:
                    message.item = ClassTagModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateClassTagResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassTagModel item = 1; */
        if (message.item)
            ClassTagModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.CreateClassTagResponse
 */
export const CreateClassTagResponse = new CreateClassTagResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateClassTagRequest$Type extends MessageType<UpdateClassTagRequest> {
    constructor() {
        super("proto.nigmamsg.UpdateClassTagRequest", [
            { no: 1, name: "item", kind: "message", T: () => ClassTagModel }
        ]);
    }
    create(value?: PartialMessage<UpdateClassTagRequest>): UpdateClassTagRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateClassTagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateClassTagRequest): UpdateClassTagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassTagModel item */ 1:
                    message.item = ClassTagModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateClassTagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassTagModel item = 1; */
        if (message.item)
            ClassTagModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.UpdateClassTagRequest
 */
export const UpdateClassTagRequest = new UpdateClassTagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateClassTagResponse$Type extends MessageType<UpdateClassTagResponse> {
    constructor() {
        super("proto.nigmamsg.UpdateClassTagResponse", [
            { no: 1, name: "item", kind: "message", T: () => ClassTagModel }
        ]);
    }
    create(value?: PartialMessage<UpdateClassTagResponse>): UpdateClassTagResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateClassTagResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateClassTagResponse): UpdateClassTagResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* proto.nigmamsg.ClassTagModel item */ 1:
                    message.item = ClassTagModel.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateClassTagResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* proto.nigmamsg.ClassTagModel item = 1; */
        if (message.item)
            ClassTagModel.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.UpdateClassTagResponse
 */
export const UpdateClassTagResponse = new UpdateClassTagResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteClassTagRequest$Type extends MessageType<DeleteClassTagRequest> {
    constructor() {
        super("proto.nigmamsg.DeleteClassTagRequest", [
            { no: 1, name: "class_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "tag_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteClassTagRequest>): DeleteClassTagRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classId = 0;
        message.tagName = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteClassTagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteClassTagRequest): DeleteClassTagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_id */ 1:
                    message.classId = reader.int64().toNumber();
                    break;
                case /* string tag_name */ 2:
                    message.tagName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteClassTagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_id = 1; */
        if (message.classId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classId);
        /* string tag_name = 2; */
        if (message.tagName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tagName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.DeleteClassTagRequest
 */
export const DeleteClassTagRequest = new DeleteClassTagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteClassTagResponse$Type extends MessageType<DeleteClassTagResponse> {
    constructor() {
        super("proto.nigmamsg.DeleteClassTagResponse", [
            { no: 1, name: "class_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "tag_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteClassTagResponse>): DeleteClassTagResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classId = 0;
        message.tagName = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteClassTagResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteClassTagResponse): DeleteClassTagResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_id */ 1:
                    message.classId = reader.int64().toNumber();
                    break;
                case /* string tag_name */ 2:
                    message.tagName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteClassTagResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_id = 1; */
        if (message.classId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classId);
        /* string tag_name = 2; */
        if (message.tagName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tagName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.DeleteClassTagResponse
 */
export const DeleteClassTagResponse = new DeleteClassTagResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveClassTagRequest$Type extends MessageType<SaveClassTagRequest> {
    constructor() {
        super("proto.nigmamsg.SaveClassTagRequest", [
            { no: 1, name: "class_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ClassTagModel }
        ]);
    }
    create(value?: PartialMessage<SaveClassTagRequest>): SaveClassTagRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classId = 0;
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<SaveClassTagRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveClassTagRequest): SaveClassTagRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_id */ 1:
                    message.classId = reader.int64().toNumber();
                    break;
                case /* repeated proto.nigmamsg.ClassTagModel items */ 2:
                    message.items.push(ClassTagModel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveClassTagRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_id = 1; */
        if (message.classId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classId);
        /* repeated proto.nigmamsg.ClassTagModel items = 2; */
        for (let i = 0; i < message.items.length; i++)
            ClassTagModel.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.SaveClassTagRequest
 */
export const SaveClassTagRequest = new SaveClassTagRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveClassTagResponse$Type extends MessageType<SaveClassTagResponse> {
    constructor() {
        super("proto.nigmamsg.SaveClassTagResponse", [
            { no: 1, name: "class_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SaveClassTagResponse>): SaveClassTagResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.classId = 0;
        if (value !== undefined)
            reflectionMergePartial<SaveClassTagResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveClassTagResponse): SaveClassTagResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 class_id */ 1:
                    message.classId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveClassTagResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 class_id = 1; */
        if (message.classId !== 0)
            writer.tag(1, WireType.Varint).int64(message.classId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message proto.nigmamsg.SaveClassTagResponse
 */
export const SaveClassTagResponse = new SaveClassTagResponse$Type();
/**
 * @generated ServiceType for protobuf service proto.nigmamsg.ClassTagService
 */
export const ClassTagService = new ServiceType("proto.nigmamsg.ClassTagService", [
    { name: "ListClassTag", options: {}, I: ListClassTagRequest, O: ListClassTagResponse },
    { name: "ItemClassTag", options: {}, I: ItemClassTagRequest, O: ItemClassTagResponse },
    { name: "CreateClassTag", options: {}, I: CreateClassTagRequest, O: CreateClassTagResponse },
    { name: "UpdateClassTag", options: {}, I: UpdateClassTagRequest, O: UpdateClassTagResponse },
    { name: "DeleteClassTag", options: {}, I: DeleteClassTagRequest, O: DeleteClassTagResponse },
    { name: "SaveClassTag", options: {}, I: SaveClassTagRequest, O: SaveClassTagResponse }
]);
