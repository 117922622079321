import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_2 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_3 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_4 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_5 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_6 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_7 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_8 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_9 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_10 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_11 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_12 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_13 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_14 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_15 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_16 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_17 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_18 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_19 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_20 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_21 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_22 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_23 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_24 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_25 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_26 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_27 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_28 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_29 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_30 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_31 = {
  class: "row items-center",
  style: {"gap":"10px"}
}
const _hoisted_32 = {
  class: "row items-center",
  style: {"gap":"10px"}
}

import {ref} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SettingsModalMetrics',
  props: {
  projectType: {
    default: 'Активный гражданин'
  }
},
  setup(__props) {

const props = __props

const tab = ref('Активность')

//АГ Активность
const sumActivityAnswers = ref(0.8)
const sumActivityIncludes = ref(0.4)
const sumActivityPulse = ref(0.4)
const sumActivityNews = ref(0.6)
const sumActivityOpenAnswers = ref(0.8)
const availableActivity = ref(0.4)
const frequencyActivity = ref(2)

//АГ Лояльность
const availableLoyaltyAnswers = ref(0.5)
const availableLoyaltyPulse = ref(0.5)
const availableLoyaltyNews = ref(0.5)
const availableLoyaltyOpenAnswers = ref(0.6)
const availableLoyaltyAnswersVictory = ref(0.8)
const typeLoyaltyUser = ref(0.9)
const openLoyaltyProfile = ref(0.3)
const aboutLoyaltyCount = ref(0.2)

//АГ Полезность
const sumUsefulLikeAll = ref(0.8)
const sumUsefulOpenAnswers = ref(0.4)

//АГ Заинтересованность
const availableInterestAnswers = ref(0.6)
const availableInterestPulse = ref(0.6)
const availableInterestNews = ref(0.6)
const frequencyInterest = ref(1.4)

//ГЗ Активность
const sumGZActivityPoints = ref(0.8)
const sumGZActivitySituationTasks = ref(0.4)
const sumGZActivityNonSituationTasks = ref(0.4)
const frequencyGZActivityTasks = ref(0.6)

//ГЗ Лояльность
const sumGZLoyaltyPoints = ref(0.8)
const sumGZLoyaltySituationTasks = ref(0.8)
const sumGZLoyaltyNonSituationTasks = ref(0.8)

//ГЗ Полезность
const sumGZUsefulAnswers = ref(0.8)
const sumGZUsefulOpenAnswers = ref(0.4)

//ГЗ Заинтересованность
const sumGZInterestSituationTasks = ref(0.8)
const sumGZInterestNonSituationTasks = ref(0.8)

return (_ctx: any,_cache: any) => {
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_tabs, {
      modelValue: tab.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((tab).value = $event)),
      "inline-label": "",
      "active-color": "primary",
      class: "text-grey-8"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_q_tab, {
          name: "Активность",
          label: "Активность",
          style: {"width":"100%"}
        }),
        _createVNode(_component_q_tab, {
          name: "Лояльность",
          label: "Лояльность",
          style: {"width":"100%"}
        }),
        _createVNode(_component_q_tab, {
          name: "Полезность",
          label: "Полезность",
          style: {"width":"100%"}
        }),
        _createVNode(_component_q_tab, {
          name: "Заинтересованность",
          label: "Заинтересованность",
          style: {"width":"100%"}
        })
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (props.projectType === 'Активный гражданин')
      ? (_openBlock(), _createBlock(_component_q_tab_panels, {
          key: 0,
          modelValue: tab.value,
          "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((tab).value = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tab_panel, {
              name: "Активность",
              class: "column items-start",
              style: {"gap":"10px"}
            }, {
              default: _withCtx(() => [
                _cache[42] || (_cache[42] = _createElementVNode("div", { class: "text-h6" }, "Показатель", -1)),
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: sumActivityAnswers.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((sumActivityAnswers).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[35] || (_cache[35] = _createElementVNode("span", { class: "text-subtitle1" }, "Сумма ответов на вопросы", -1))
                ]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: sumActivityIncludes.value,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((sumActivityIncludes).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[36] || (_cache[36] = _createElementVNode("span", { class: "text-subtitle1" }, "Сумма участий в голосованиях", -1))
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: sumActivityPulse.value,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((sumActivityPulse).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[37] || (_cache[37] = _createElementVNode("span", { class: "text-subtitle1" }, "Сумма оценок пульса", -1))
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: sumActivityNews.value,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((sumActivityNews).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[38] || (_cache[38] = _createElementVNode("span", { class: "text-subtitle1" }, "Сумма оценок новинок", -1))
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: sumActivityOpenAnswers.value,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((sumActivityOpenAnswers).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[39] || (_cache[39] = _createElementVNode("span", { class: "text-subtitle1" }, "Сумма открытых ответов", -1))
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: availableActivity.value,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((availableActivity).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[40] || (_cache[40] = _createElementVNode("span", { class: "text-subtitle1" }, "Наличие ответов на вопросы в викторинах", -1))
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: frequencyActivity.value,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((frequencyActivity).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[41] || (_cache[41] = _createElementVNode("span", { class: "text-subtitle1" }, "Частота активности", -1))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_q_tab_panel, {
              name: "Лояльность",
              class: "column items-start",
              style: {"gap":"10px"}
            }, {
              default: _withCtx(() => [
                _cache[51] || (_cache[51] = _createElementVNode("div", { class: "text-h6" }, "Показатель", -1)),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: availableLoyaltyAnswers.value,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((availableLoyaltyAnswers).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[43] || (_cache[43] = _createElementVNode("span", { class: "text-subtitle1" }, "Наличие ответов на вопросы", -1))
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: availableLoyaltyPulse.value,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((availableLoyaltyPulse).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[44] || (_cache[44] = _createElementVNode("span", { class: "text-subtitle1" }, "Наличие оценок пульса", -1))
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: availableLoyaltyNews.value,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((availableLoyaltyNews).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[45] || (_cache[45] = _createElementVNode("span", { class: "text-subtitle1" }, "Наличие оценок новинок", -1))
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: availableLoyaltyOpenAnswers.value,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((availableLoyaltyOpenAnswers).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[46] || (_cache[46] = _createElementVNode("span", { class: "text-subtitle1" }, "Наличие открытых ответов", -1))
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: availableLoyaltyAnswersVictory.value,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((availableLoyaltyAnswersVictory).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[47] || (_cache[47] = _createElementVNode("span", { class: "text-subtitle1" }, "Наличие ответов на вопросы в викторинах", -1))
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: typeLoyaltyUser.value,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((typeLoyaltyUser).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[48] || (_cache[48] = _createElementVNode("span", { class: "text-subtitle1" }, "Тип учётной записи", -1))
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: openLoyaltyProfile.value,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((openLoyaltyProfile).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[49] || (_cache[49] = _createElementVNode("span", { class: "text-subtitle1" }, "Наличие открытого профиля", -1))
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: aboutLoyaltyCount.value,
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((aboutLoyaltyCount).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[50] || (_cache[50] = _createElementVNode("span", { class: "text-subtitle1" }, "Количество заполненных полей о себе", -1))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_q_tab_panel, {
              name: "Полезность",
              class: "column items-start",
              style: {"gap":"10px"}
            }, {
              default: _withCtx(() => [
                _cache[54] || (_cache[54] = _createElementVNode("div", { class: "text-h6" }, "Показатель", -1)),
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: sumUsefulLikeAll.value,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((sumUsefulLikeAll).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[52] || (_cache[52] = _createElementVNode("span", { class: "text-subtitle1" }, "Сумма ответов соответствующих большинству", -1))
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: sumUsefulOpenAnswers.value,
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((sumUsefulOpenAnswers).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[53] || (_cache[53] = _createElementVNode("span", { class: "text-subtitle1" }, "Сумма открытых ответов", -1))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_q_tab_panel, {
              name: "Заинтересованность",
              class: "column items-start",
              style: {"gap":"10px"}
            }, {
              default: _withCtx(() => [
                _cache[59] || (_cache[59] = _createElementVNode("div", { class: "text-h6" }, "Показатель", -1)),
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: availableInterestAnswers.value,
                    "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((availableInterestAnswers).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[55] || (_cache[55] = _createElementVNode("span", { class: "text-subtitle1" }, "Наличие ответов на вопросы", -1))
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: availableInterestPulse.value,
                    "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((availableInterestPulse).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[56] || (_cache[56] = _createElementVNode("span", { class: "text-subtitle1" }, "Наличие оценок пульса", -1))
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: availableInterestNews.value,
                    "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((availableInterestNews).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[57] || (_cache[57] = _createElementVNode("span", { class: "text-subtitle1" }, "Наличие оценок новинок", -1))
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: frequencyInterest.value,
                    "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((frequencyInterest).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[58] || (_cache[58] = _createElementVNode("span", { class: "text-subtitle1" }, "Частота активности", -1))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (props.projectType === 'Город заданий')
      ? (_openBlock(), _createBlock(_component_q_tab_panels, {
          key: 1,
          modelValue: tab.value,
          "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((tab).value = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tab_panel, {
              name: "Активность",
              class: "column items-start",
              style: {"gap":"10px"}
            }, {
              default: _withCtx(() => [
                _cache[64] || (_cache[64] = _createElementVNode("div", { class: "text-h6" }, "Показатель", -1)),
                _createElementVNode("div", _hoisted_22, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: sumGZActivityPoints.value,
                    "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((sumGZActivityPoints).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[60] || (_cache[60] = _createElementVNode("span", { class: "text-subtitle1" }, "Сумма баллов", -1))
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: sumGZActivitySituationTasks.value,
                    "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((sumGZActivitySituationTasks).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[61] || (_cache[61] = _createElementVNode("span", { class: "text-subtitle1" }, "Сумма выполненных ситуативных заданий", -1))
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: sumGZActivityNonSituationTasks.value,
                    "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((sumGZActivityNonSituationTasks).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[62] || (_cache[62] = _createElementVNode("span", { class: "text-subtitle1" }, "Сумма выполненных неситуативных заданий", -1))
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: frequencyGZActivityTasks.value,
                    "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((frequencyGZActivityTasks).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[63] || (_cache[63] = _createElementVNode("span", { class: "text-subtitle1" }, "Частота выполнения заданий", -1))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_q_tab_panel, {
              name: "Лояльность",
              class: "column items-start",
              style: {"gap":"10px"}
            }, {
              default: _withCtx(() => [
                _cache[68] || (_cache[68] = _createElementVNode("div", { class: "text-h6" }, "Показатель", -1)),
                _createElementVNode("div", _hoisted_26, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: sumGZLoyaltyPoints.value,
                    "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((sumGZLoyaltyPoints).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[65] || (_cache[65] = _createElementVNode("span", { class: "text-subtitle1" }, "Сумма баллов", -1))
                ]),
                _createElementVNode("div", _hoisted_27, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: sumGZLoyaltySituationTasks.value,
                    "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((sumGZLoyaltySituationTasks).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[66] || (_cache[66] = _createElementVNode("span", { class: "text-subtitle1" }, "Сумма выполненных ситуативных заданий", -1))
                ]),
                _createElementVNode("div", _hoisted_28, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: sumGZLoyaltyNonSituationTasks.value,
                    "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((sumGZLoyaltyNonSituationTasks).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[67] || (_cache[67] = _createElementVNode("span", { class: "text-subtitle1" }, "Сумма выполненных неситуативных заданий", -1))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_q_tab_panel, {
              name: "Полезность",
              class: "column items-start",
              style: {"gap":"10px"}
            }, {
              default: _withCtx(() => [
                _cache[71] || (_cache[71] = _createElementVNode("div", { class: "text-h6" }, "Показатель", -1)),
                _createElementVNode("div", _hoisted_29, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: sumGZUsefulAnswers.value,
                    "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((sumGZUsefulAnswers).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[69] || (_cache[69] = _createElementVNode("span", { class: "text-subtitle1" }, "Сумма ответов соответствующих большинству", -1))
                ]),
                _createElementVNode("div", _hoisted_30, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: sumGZUsefulOpenAnswers.value,
                    "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((sumGZUsefulOpenAnswers).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[70] || (_cache[70] = _createElementVNode("span", { class: "text-subtitle1" }, "Сумма открытых ответов", -1))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_q_tab_panel, {
              name: "Заинтересованность",
              class: "column items-start",
              style: {"gap":"10px"}
            }, {
              default: _withCtx(() => [
                _cache[74] || (_cache[74] = _createElementVNode("div", { class: "text-h6" }, "Показатель", -1)),
                _createElementVNode("div", _hoisted_31, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: sumGZInterestSituationTasks.value,
                    "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((sumGZInterestSituationTasks).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[72] || (_cache[72] = _createElementVNode("span", { class: "text-subtitle1" }, "Сумма выполненных ситуативных заданий", -1))
                ]),
                _createElementVNode("div", _hoisted_32, [
                  _createVNode(_component_q_input, {
                    dense: "",
                    class: "input-custom",
                    outlined: "",
                    type: "number",
                    modelValue: sumGZInterestNonSituationTasks.value,
                    "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((sumGZInterestNonSituationTasks).value = $event))
                  }, null, 8, ["modelValue"]),
                  _cache[73] || (_cache[73] = _createElementVNode("span", { class: "text-subtitle1" }, "Сумма выполненных неситуативных заданий", -1))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})