import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, withModifiers as _withModifiers, createSlots as _createSlots, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../../../../assets/img/split.svg'


const _hoisted_1 = {
  class: "aside-filters__points column",
  style: {"gap":"8px","margin-bottom":"10px"}
}
const _hoisted_2 = {
  class: "aside-filters__activity column",
  style: {"gap":"8px","margin-bottom":"10px"}
}
const _hoisted_3 = {
  class: "aside-filters__activity-first row",
  style: {"gap":"4px","position":"relative"}
}
const _hoisted_4 = { class: "row items-center justify-end" }
const _hoisted_5 = { class: "row items-center justify-end" }
const _hoisted_6 = {
  class: "aside-filters__activity-first row",
  style: {"gap":"4px","position":"relative"}
}
const _hoisted_7 = { class: "row items-center justify-end" }
const _hoisted_8 = { class: "row items-center justify-end" }

import { ref, onMounted, computed } from 'vue'
import date from 'quasar/src/utils/date/date.js';import QInput from 'quasar/src/components/input/QInput.js';import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import { onClickOutside } from '@vueuse/core'
import {Filter} from "proto/nigma-common-proto/filter";


export default /*@__PURE__*/_defineComponent({
  __name: 'PollsFilters',
  emits: ['on-filters'],
  setup(__props, { emit: __emit }) {

onMounted(() => {
  computedFilters.value
})

const emit = __emit
const floatingBtn = ref<InstanceType<typeof HTMLDivElement>>()
const filtersRoot = ref<InstanceType<typeof HTMLDivElement>>()

const userCountRef = ref<InstanceType<typeof HTMLDivElement>>()
const userCountFrom = ref<number | null>(null)
const userCountTo = ref<number | null>(null)

// const loadedAnswersRef = ref<InstanceType<typeof HTMLDivElement>>()
// const loadedAnswersFrom = ref<number | null>(null)
// const loadedAnswersTo = ref<number | null>(null)

const totalAnswersRef = ref<InstanceType<typeof HTMLDivElement>>()
const totalAnswersFrom = ref<number | null>(null)
const totalAnswersTo = ref<number | null>(null)

const openAnswersRef = ref<InstanceType<typeof HTMLDivElement>>()
const openAnswersFrom = ref<number | null>(null)
const openAnswersTo = ref<number | null>(null)

const maxDateLimit = date.formatDate(new Date(new Date().getFullYear(), 11, 31).valueOf() , 'YYYY/MM')
const dateFromRef = ref<InstanceType<typeof QInput>>()
const dateFrom = ref('')
const dateTo = ref('')
const qDateProxy1 = ref<InstanceType<typeof QPopupProxy>>()
const qDateProxy2 = ref<InstanceType<typeof QPopupProxy>>()

const dateFromLast = ref('')
const dateToLast = ref('')
const qDateProxyLast1: any = ref()
const qDateProxyLast2: any = ref()

const computedFilters = computed<Filter>(() => {
  const filters: Filter = {items: []}

  if (userCountTo.value && userCountTo.value > 0) {
    filters.items.push(
        {
          fieldName: 'ap.ag_poll_user_count',
          operand: '>=',
          value: userCountFrom.value ? userCountFrom.value.toString() : '0',
        },
        {
          fieldName: 'ap.ag_poll_user_count',
          operand: '<=',
          value: userCountTo.value ? userCountTo.value.toString() : '0',
        }
    )
  } else {
    filters.items.push({
      fieldName: 'ap.ag_poll_user_count',
      operand: '>=',
      value: userCountFrom.value ? userCountFrom.value.toString() : '0',
    })
  }

  // if (loadedAnswersTo.value && loadedAnswersTo.value > 0) {
  //   filters.items.push(
  //       {
  //         fieldName: 'ap.poll_loaded_answers',
  //         operand: '>=',
  //         value: loadedAnswersFrom.value ? unref(loadedAnswersFrom.toString()) : '0',
  //       },
  //       {
  //         fieldName: 'ap.poll_loaded_answers',
  //         operand: '<=',
  //         value: loadedAnswersTo.value ? unref(loadedAnswersTo.toString()) : '0',
  //       }
  //   )
  // } else {
  //   filters.items.push({
  //     field_name: 'ap.poll_loaded_answers',
  //     operand: '>=',
  //     value: loadedAnswersFrom.value ? unref(loadedAnswersFrom) : 0,
  //   })
  // }

  if (totalAnswersTo.value && totalAnswersTo.value > 0) {
    filters.items.push(
        {
          fieldName: 'ap.ag_poll_total_answers',
          operand: '>=',
          value: totalAnswersFrom.value ? totalAnswersFrom.value.toString() : '0',
        },
        {
          fieldName: 'ap.ag_poll_total_answers',
          operand: '<=',
          value: totalAnswersTo.value ? totalAnswersTo.value.toString() : '0',
        }
    )
  } else {
    filters.items.push({
      fieldName: 'ap.ag_poll_total_answers',
      operand: '>=',
      value: totalAnswersFrom.value ? totalAnswersFrom.value.toString() : '0',
    })
  }

  if (openAnswersTo.value && openAnswersTo.value > 0) {
    filters.items.push(
        {
          fieldName: 'ap.ag_poll_open_answers',
          operand: '>=',
          value: openAnswersFrom.value ? openAnswersFrom.value.toString() : '0',
        },
        {
          fieldName: 'ap.ag_poll_open_answers',
          operand: '<=',
          value: openAnswersTo.value ? openAnswersTo.value.toString() : '0',
        }
    )
  } else {
    filters.items.push({
      fieldName: 'ap.ag_poll_open_answers',
      operand: '>=',
      value: openAnswersFrom.value ? openAnswersFrom.value.toString() : '0',
    })
  }

  if (dateFrom.value) {
    filters.items.push({
      fieldName: 'ap.ag_poll_date_begin',
      operand: '>=',
      value: dateFrom.value,
    })
  }

  if (dateTo.value) {
    filters.items.push({
      fieldName: 'ap.ag_poll_date_end',
      operand: '<=',
      value: dateTo.value,
    })
  }

  if (dateFromLast.value) {
    filters.items.push({
      fieldName: 'ap.ag_poll_date_update',
      operand: '>=',
      value: dateFromLast.value,
    })
  }

  if (dateToLast.value) {
    filters.items.push({
      fieldName: 'ap.ag_poll_date_update',
      operand: '<=',
      value: dateToLast.value,
    })
  }

  // filters.items.unshift({
  //   fieldName: 'ap.ag_poll_title',
  //   operand: 'ilike',
  //   value: '',
  //   or: {
  //     items: [
  //       {
  //         fieldName: 'ap.ag_poll_id::text',
  //         operand: 'ilike',
  //         value: ''
  //       }
  //     ]
  //   }
  // })

  return filters
})

const asideBtn = (e: any) => {
  let el = e?.srcElement || e?.parentElement
  // console.log('EVENT')
  // console.dir(e)
  if (floatingBtn.value.style.opacity == 1) {
    floatingBtn.value.style.opacity = '0'
    floatingBtn.value.style.visibility = 'hidden'
  }
  const arrStops = [
    'filter-select-tree col',
    'aside-filters__points-earned row',
    'aside-filters__points-spent row',
    'aside-filters__points-current row',
    'aside-filters__activity-count row',
    'aside-filters__activity-first row',
    'aside-filters__area column',
  ]

  while (el.parentElement && !arrStops.includes(el.className)) {
    el = el.parentElement
  }

  el.firstChild.after(floatingBtn.value)
  setTimeout(() => {
    floatingBtn.value.style.opacity = '1'
    floatingBtn.value.style.visibility = 'visible'
  }, 300)
  // console.log('ELEMENT', el)
}

onClickOutside(filtersRoot, () => {
  // e.target.closest('.filter-select-tree') ? null : showDropDown(false)
  floatingBtn.value.style.opacity = '0'
  floatingBtn.value.style.visibility = 'hidden'
})

const applyFilters = () => {
  emit('on-filters', computedFilters.value)
  floatingBtn.value.style.opacity = '0'
}

const clearDate = (ref, type?: string) => {
  asideBtn(ref?.$el)

  if (!type) {
    ref?.hide()
  } else {
    if (type === 'from') dateFrom.value = ''
    if (type === 'to') dateTo.value = ''
    if (type === 'fromLast') dateFromLast.value = ''
    if (type === 'toLast') dateToLast.value = ''
  }
}

const resetFields = () => {
  userCountFrom.value = null
  userCountTo.value = null
  // loadedAnswersFrom.value = null
  // loadedAnswersTo.value = null

  totalAnswersFrom.value = null
  totalAnswersTo.value = null

  openAnswersFrom.value = null
  openAnswersTo.value = null

  dateFrom.value = ''
  dateTo.value = ''

  dateFromLast.value = ''
  dateToLast.value = ''

  applyFilters()
}

return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      ref_key: "filtersRoot",
      ref: filtersRoot,
      class: "aside-filters"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _cache[37] || (_cache[37] = _createElementVNode("span", { class: "text-weight-bold" }, "Количество участников", -1)),
        _createElementVNode("div", {
          class: "aside-filters__points-earned row",
          style: {"gap":"4px","position":"relative"},
          ref_key: "userCountRef",
          ref: userCountRef
        }, [
          _createVNode(_unref(QInput), {
            clearable: "",
            modelValue: userCountFrom.value,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((userCountFrom).value = $event)),
              _cache[1] || (_cache[1] = ($event: any) => (asideBtn(userCountRef.value?.firstChild)))
            ],
            outlined: "",
            filled: "",
            class: "col",
            label: "От"
          }, null, 8, ["modelValue"]),
          _cache[34] || (_cache[34] = _createElementVNode("img", {
            class: "col-shrink",
            src: _imports_0,
            alt: ""
          }, null, -1)),
          _createVNode(_unref(QInput), {
            clearable: "",
            class: "col",
            modelValue: userCountTo.value,
            "onUpdate:modelValue": [
              _cache[2] || (_cache[2] = ($event: any) => ((userCountTo).value = $event)),
              _cache[3] || (_cache[3] = ($event: any) => (asideBtn(userCountRef.value?.firstChild)))
            ],
            outlined: "",
            filled: "",
            label: "До"
          }, null, 8, ["modelValue"])
        ], 512),
        _cache[38] || (_cache[38] = _createElementVNode("span", { class: "text-weight-bold" }, "Всего ответов", -1)),
        _createElementVNode("div", {
          class: "aside-filters__points-spent row",
          style: {"gap":"4px","position":"relative"},
          ref_key: "totalAnswersRef",
          ref: totalAnswersRef
        }, [
          _createVNode(_unref(QInput), {
            clearable: "",
            modelValue: totalAnswersFrom.value,
            "onUpdate:modelValue": [
              _cache[4] || (_cache[4] = ($event: any) => ((totalAnswersFrom).value = $event)),
              _cache[5] || (_cache[5] = ($event: any) => (asideBtn(totalAnswersRef.value?.firstChild)))
            ],
            outlined: "",
            filled: "",
            class: "col",
            label: "От"
          }, null, 8, ["modelValue"]),
          _cache[35] || (_cache[35] = _createElementVNode("img", {
            class: "col-shrink",
            src: _imports_0,
            alt: ""
          }, null, -1)),
          _createVNode(_unref(QInput), {
            clearable: "",
            class: "col",
            modelValue: totalAnswersTo.value,
            "onUpdate:modelValue": [
              _cache[6] || (_cache[6] = ($event: any) => ((totalAnswersTo).value = $event)),
              _cache[7] || (_cache[7] = ($event: any) => (asideBtn(totalAnswersRef.value?.firstChild)))
            ],
            outlined: "",
            filled: "",
            label: "До"
          }, null, 8, ["modelValue"])
        ], 512),
        _cache[39] || (_cache[39] = _createElementVNode("span", { class: "text-weight-bold" }, "Открытые ответы", -1)),
        _createElementVNode("div", {
          class: "aside-filters__points-current row",
          style: {"gap":"4px","position":"relative"},
          ref_key: "openAnswersRef",
          ref: openAnswersRef
        }, [
          _createVNode(_unref(QInput), {
            clearable: "",
            modelValue: openAnswersFrom.value,
            "onUpdate:modelValue": [
              _cache[8] || (_cache[8] = ($event: any) => ((openAnswersFrom).value = $event)),
              _cache[9] || (_cache[9] = ($event: any) => (asideBtn(openAnswersRef.value?.firstChild)))
            ],
            outlined: "",
            filled: "",
            class: "col",
            label: "От"
          }, null, 8, ["modelValue"]),
          _cache[36] || (_cache[36] = _createElementVNode("img", {
            class: "col-shrink",
            src: _imports_0,
            alt: ""
          }, null, -1)),
          _createVNode(_unref(QInput), {
            clearable: "",
            class: "col",
            modelValue: openAnswersTo.value,
            "onUpdate:modelValue": [
              _cache[10] || (_cache[10] = ($event: any) => ((openAnswersTo).value = $event)),
              _cache[11] || (_cache[11] = ($event: any) => (asideBtn(openAnswersRef.value?.firstChild)))
            ],
            outlined: "",
            filled: "",
            label: "До"
          }, null, 8, ["modelValue"])
        ], 512)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _cache[42] || (_cache[42] = _createElementVNode("span", { class: "text-weight-bold" }, "Даты проведения опроса", -1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(QInput), {
            clearable: "",
            filled: "",
            modelValue: dateFrom.value,
            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((dateFrom).value = $event)),
            label: "Дата начала",
            "hide-bottom-space": "",
            "hide-hint": "",
            mask: "####-##-##",
            readonly: "",
            style: {"width":"170px","cursor":"pointer"},
            "input-style": "cursor:pointer",
            onClick: _cache[16] || (_cache[16] = ($event: any) => (qDateProxy1.value?.show())),
            class: "col",
            ref_key: "dateFromRef",
            ref: dateFromRef
          }, _createSlots({
            prepend: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: "event",
                class: "cursor-pointer"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(QPopupProxy), {
                    cover: "",
                    "transition-show": "scale",
                    "transition-hide": "scale",
                    ref_key: "qDateProxy1",
                    ref: qDateProxy1
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_date, {
                        modelValue: dateFrom.value,
                        "onUpdate:modelValue": [
                          _cache[12] || (_cache[12] = ($event: any) => ((dateFrom).value = $event)),
                          _cache[13] || (_cache[13] = ($event: any) => (clearDate(qDateProxy1.value)))
                        ],
                        mask: "YYYY-MM-DD",
                        "navigation-min-year-month": "1970/01",
                        "navigation-max-year-month": _unref(maxDateLimit),
                        "no-unset": ""
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_4, [
                            _withDirectives(_createVNode(_component_q_btn, {
                              label: "Close",
                              color: "primary",
                              flat: ""
                            }, null, 512), [
                              [_directive_close_popup]
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "navigation-max-year-month"])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              })
            ]),
            _: 2
          }, [
            (dateFrom.value)
              ? {
                  name: "append",
                  fn: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      name: "cancel",
                      onClick: _cache[14] || (_cache[14] = _withModifiers(($event: any) => (clearDate(qDateProxy1.value, 'from')), ["stop","prevent"]))
                    })
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["modelValue"]),
          _cache[40] || (_cache[40] = _createElementVNode("img", {
            class: "col-shrink",
            src: _imports_0,
            alt: ""
          }, null, -1)),
          _createVNode(_unref(QInput), {
            clearable: "",
            filled: "",
            modelValue: dateTo.value,
            "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((dateTo).value = $event)),
            label: "Дата окончания",
            "hide-bottom-space": "",
            "hide-hint": "",
            mask: "####-##-##",
            readonly: "",
            style: {"width":"170px","cursor":"pointer"},
            "input-style": "cursor:pointer",
            onClick: _cache[21] || (_cache[21] = ($event: any) => (qDateProxy2.value?.show())),
            class: "col",
            onBlur: _cache[22] || (_cache[22] = ($event: any) => (asideBtn($event)))
          }, _createSlots({
            prepend: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: "event",
                class: "cursor-pointer"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(QPopupProxy), {
                    cover: "",
                    "transition-show": "scale",
                    "transition-hide": "scale",
                    ref_key: "qDateProxy2",
                    ref: qDateProxy2
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_date, {
                        modelValue: dateTo.value,
                        "onUpdate:modelValue": [
                          _cache[17] || (_cache[17] = ($event: any) => ((dateTo).value = $event)),
                          _cache[18] || (_cache[18] = ($event: any) => (clearDate(qDateProxy2.value)))
                        ],
                        mask: "YYYY-MM-DD",
                        "navigation-min-year-month": "1970/01",
                        "navigation-max-year-month": _unref(maxDateLimit),
                        "no-unset": ""
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, [
                            _withDirectives(_createVNode(_component_q_btn, {
                              label: "Close",
                              color: "primary",
                              flat: ""
                            }, null, 512), [
                              [_directive_close_popup]
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "navigation-max-year-month"])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              })
            ]),
            _: 2
          }, [
            (dateTo.value)
              ? {
                  name: "append",
                  fn: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      name: "cancel",
                      onClick: _cache[19] || (_cache[19] = _withModifiers(($event: any) => (clearDate(qDateProxy2.value, 'to')), ["stop","prevent"]))
                    })
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["modelValue"])
        ]),
        _cache[43] || (_cache[43] = _createElementVNode("span", { class: "text-weight-bold" }, "Дата актуализации данных", -1)),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_unref(QInput), {
            clearable: "",
            filled: "",
            modelValue: dateFromLast.value,
            "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((dateFromLast).value = $event)),
            label: "Дата начала",
            "hide-bottom-space": "",
            "hide-hint": "",
            mask: "####-##-##",
            readonly: "",
            style: {"width":"170px","cursor":"pointer"},
            "input-style": "cursor:pointer",
            onClick: _cache[27] || (_cache[27] = ($event: any) => (qDateProxyLast1.value.show())),
            class: "col"
          }, _createSlots({
            prepend: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: "event",
                class: "cursor-pointer"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(QPopupProxy), {
                    cover: "",
                    "transition-show": "scale",
                    "transition-hide": "scale",
                    ref_key: "qDateProxyLast1",
                    ref: qDateProxyLast1
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_date, {
                        modelValue: dateFromLast.value,
                        "onUpdate:modelValue": [
                          _cache[23] || (_cache[23] = ($event: any) => ((dateFromLast).value = $event)),
                          _cache[24] || (_cache[24] = ($event: any) => (clearDate(qDateProxyLast1.value)))
                        ],
                        mask: "YYYY-MM-DD",
                        "navigation-min-year-month": "1970/01",
                        "navigation-max-year-month": _unref(maxDateLimit),
                        "no-unset": ""
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_7, [
                            _withDirectives(_createVNode(_component_q_btn, {
                              label: "Close",
                              color: "primary",
                              flat: ""
                            }, null, 512), [
                              [_directive_close_popup]
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "navigation-max-year-month"])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              })
            ]),
            _: 2
          }, [
            (dateFromLast.value)
              ? {
                  name: "append",
                  fn: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      name: "cancel",
                      onClick: _cache[25] || (_cache[25] = _withModifiers(($event: any) => (clearDate(qDateProxyLast1.value, 'fromLast')), ["stop","prevent"]))
                    })
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["modelValue"]),
          _cache[41] || (_cache[41] = _createElementVNode("img", {
            class: "col-shrink",
            src: _imports_0,
            alt: ""
          }, null, -1)),
          _createVNode(_unref(QInput), {
            clearable: "",
            filled: "",
            modelValue: dateToLast.value,
            "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((dateToLast).value = $event)),
            label: "Дата окончания",
            "hide-bottom-space": "",
            "hide-hint": "",
            mask: "####-##-##",
            readonly: "",
            style: {"width":"170px","cursor":"pointer"},
            "input-style": "cursor:pointer",
            onClick: _cache[32] || (_cache[32] = ($event: any) => (qDateProxyLast2.value.show())),
            class: "col",
            onBlur: _cache[33] || (_cache[33] = ($event: any) => (asideBtn($event)))
          }, _createSlots({
            prepend: _withCtx(() => [
              _createVNode(_component_q_icon, {
                name: "event",
                class: "cursor-pointer"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(QPopupProxy), {
                    cover: "",
                    "transition-show": "scale",
                    "transition-hide": "scale",
                    ref_key: "qDateProxyLast2",
                    ref: qDateProxyLast2
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_date, {
                        modelValue: dateToLast.value,
                        "onUpdate:modelValue": [
                          _cache[28] || (_cache[28] = ($event: any) => ((dateToLast).value = $event)),
                          _cache[29] || (_cache[29] = ($event: any) => (clearDate(qDateProxyLast2.value)))
                        ],
                        mask: "YYYY-MM-DD",
                        "navigation-min-year-month": "1970/01",
                        "navigation-max-year-month": _unref(maxDateLimit),
                        "no-unset": ""
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_8, [
                            _withDirectives(_createVNode(_component_q_btn, {
                              label: "Close",
                              color: "primary",
                              flat: ""
                            }, null, 512), [
                              [_directive_close_popup]
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue", "navigation-max-year-month"])
                    ]),
                    _: 1
                  }, 512)
                ]),
                _: 1
              })
            ]),
            _: 2
          }, [
            (dateToLast.value)
              ? {
                  name: "append",
                  fn: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                      name: "cancel",
                      onClick: _cache[30] || (_cache[30] = _withModifiers(($event: any) => (clearDate(qDateProxyLast2.value, 'toLast')), ["stop","prevent"]))
                    })
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["modelValue"])
        ])
      ]),
      _createVNode(_component_q_btn, {
        color: "primary",
        label: "Применить фильтры",
        style: {"width":"100%","margin-bottom":"8px"},
        align: "left",
        onClick: applyFilters
      }),
      _createVNode(_component_q_btn, {
        color: "secondary",
        label: "Сбросить фильтры",
        style: {"width":"100%"},
        align: "left",
        onClick: resetFields
      })
    ], 512),
    _createElementVNode("div", {
      ref_key: "floatingBtn",
      ref: floatingBtn,
      class: "floating-confirm-btn"
    }, [
      _createVNode(_component_q_btn, {
        onClick: applyFilters,
        color: "primary",
        label: "Применить"
      })
    ], 512)
  ], 64))
}
}

})